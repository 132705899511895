import { useState, useCallback } from 'react';

interface IUsePaginationProps {
    step: number;
    initialMaxValue: number;
}

interface IUsePagination {
    visibleFromTo: IFromTo;
    changePageCallback: (nextPage: boolean) => void;
    changeVisibleFromToCallback: (props: IFromTo) => void;
    changeMaxValueCallback: (maxValue: number) => void;
}

export interface IFromTo {
    from: number;
    to: number;
}

const usePagination: (props: IUsePaginationProps) => IUsePagination = ({ step, initialMaxValue }) => {
    const [visibleFromTo, setVisibleFromTo] = useState<IFromTo>({ from: 0, to: step });
    const [maxValue, setMaxValue] = useState<number>(initialMaxValue);

    const changePageCallback = useCallback(
        (nextPage: boolean) => {
            if (nextPage) {
                setVisibleFromTo((visibleFromTo) => {
                    return {
                        from: visibleFromTo.from,
                        to: visibleFromTo.to + step > maxValue ? maxValue : visibleFromTo.to + step,
                    };
                });
            } else {
                setVisibleFromTo((visibleFromTo) => {
                    return {
                        from: visibleFromTo.from - step < 0 ? 0 : visibleFromTo.from - step,
                        to: visibleFromTo.to,
                    };
                });
            }
        },
        [step, maxValue]
    );

    const changeVisibleFromToCallback = useCallback((newDataLimit: IFromTo) => {
        setVisibleFromTo(newDataLimit);
    }, []);

    const changeMaxValueCallback = useCallback((newMaxValue: number) => {
        setMaxValue(newMaxValue);
    }, []);

    return {
        visibleFromTo,
        changePageCallback,
        changeVisibleFromToCallback: changeVisibleFromToCallback,
        changeMaxValueCallback: changeMaxValueCallback,
    };
};

export default usePagination;
