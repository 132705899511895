import { FC } from 'react';

interface IProps {
    width?: string;
    height?: string;
}

const DownloadBadge: FC<IProps> = ({ width, height }) => {
    return (
        <svg
            width={width || 24}
            height={height || 24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 2L11.8834 2.00673C11.386 2.06449 11 2.48716 11 3V12.585L7.70711 9.29289L7.6129 9.2097C7.22061 8.90468 6.65338 8.93241 6.29289 9.29289C5.90237 9.68342 5.90237 10.3166 6.29289 10.7071L11.2929 15.7071C11.3214 15.7356 11.3515 15.7623 11.3832 15.7872L11.4793 15.854L11.5768 15.9063L11.6583 15.9401L11.7665 15.9726L11.8819 15.9931L11.9406 15.9983L12.0593 15.9983L12.1485 15.989L12.266 15.9642L12.3713 15.9288L12.4841 15.8753L12.5953 15.8037L12.6631 15.7485L12.7071 15.7071L17.7071 10.7071L17.7903 10.6129C18.0953 10.2206 18.0676 9.65338 17.7071 9.29289L17.6129 9.2097C17.2206 8.90468 16.6534 8.93241 16.2929 9.29289L13 12.585V3L12.9933 2.88338C12.9355 2.38604 12.5128 2 12 2ZM21.9933 14.8834C21.9355 14.386 21.5128 14 21 14C20.4477 14 20 14.4477 20 15V19L19.9933 19.1166C19.9355 19.614 19.5128 20 19 20H5L4.88338 19.9933C4.38604 19.9355 4 19.5128 4 19V15L3.99327 14.8834C3.93551 14.386 3.51284 14 3 14C2.44772 14 2 14.4477 2 15V19L2.00509 19.1763C2.09634 20.7511 3.40232 22 5 22H19L19.1763 21.9949C20.7511 21.9037 22 20.5977 22 19V15L21.9933 14.8834Z"
                fill="#8C8C8C"
            />
        </svg>
    );
};

export default DownloadBadge;
