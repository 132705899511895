import { useQuery, UseQueryResult } from '@tanstack/react-query';
import useAuthParams from '../hooks/useAuthParams';
import { IAuthParams } from '../interfaces/IAuthParams';
import { getTokenAndHeaders } from './helpers/apiUtil';
import { apiInstance } from './helpers/apiInstance';
import { IAssessmentReportVM } from '../interfaces/views/IAssessmentReportVM';
import { IAssessmentReportItemVM } from '../interfaces/views/IAssessmentReportItemVM';
import { mapAssessmentReportByDate } from './helpers/mappers';
import { EApiQueryKey } from '../interfaces/enums/EApiQueryKey';

// GET INSIGHTS PERSONAL DATA
export const getAssessmentReportData = async (authParams: IAuthParams) => {
    const { headers } = await getTokenAndHeaders(authParams);
    const response = await apiInstance.get(`assessment/reports`, headers);
    return response.data.data;
};

export const useGetAssessmentReportDataQuery: () => UseQueryResult<IAssessmentReportVM[], Error> = () => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.ASSESSMENT_FETCH_REPORT],
        queryFn: async () => {
            return getAssessmentReportData(authParams);
        },
        refetchOnWindowFocus: false,
    });
};

// GET ASSESSMENT REPORT DATE
export const getAssessmentReportByDate = async (authParams: IAuthParams, date?: string) => {
    const { headers } = await getTokenAndHeaders(authParams);
    const response = await apiInstance.get(`assessment/reports/${date}`, headers);
    return mapAssessmentReportByDate(response.data);
};

export const useGetAssessmentReportByDateQuery: (date?: string) => UseQueryResult<IAssessmentReportItemVM[], Error> = (
    date
) => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.ASSESSMENT_FETCH_REPORT_BY_DATE],
        queryFn: async () => {
            return getAssessmentReportByDate(authParams, date);
        },
        refetchOnWindowFocus: false,
        enabled: false,
    });
};

// DOWNLOAD ASSESSMENT REPORT
export const downloadAssessmentReportByDate = async (authParams: IAuthParams, fileName?: string) => {
    if (!fileName) return;
    const { headers } = await getTokenAndHeaders(authParams);
    const response = await apiInstance.get(`assessment/reports/${fileName}`, {
        ...headers,
        responseType: 'blob',
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `assessment-report-${fileName}.csv`);
    document.body.appendChild(link);
    link.click();
};

export const useDownloadAssessmentReportByDateQuery: (fileName?: string) => UseQueryResult<any[], Error> = (
    fileName
) => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.ASSESSMENT_DOWNLOAD_REPORT_BY_DATE],
        queryFn: async () => {
            return downloadAssessmentReportByDate(authParams, fileName);
        },
        refetchOnWindowFocus: false,
        enabled: false,
    });
};

// GET ASSESSMENT BY SKILL
export const getAssessmentReportBySkillId = async (authParams: IAuthParams, skillId?: string) => {
    const { headers } = await getTokenAndHeaders(authParams);
    const response = await apiInstance.get(`assessment/reports/skill/${skillId}`, headers);
    return mapAssessmentReportByDate(response.data);
};

export const useGetAssessmentReportBySkillIdQuery: (
    skillId?: string
) => UseQueryResult<IAssessmentReportItemVM[], Error> = (skillId) => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.ASSESSMENT_FETCH_REPORT_BY_SKILL],
        queryFn: async () => {
            return getAssessmentReportBySkillId(authParams, skillId);
        },
        refetchOnWindowFocus: false,
        enabled: false,
    });
};
