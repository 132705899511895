import { Typography } from '@mui/material';
import styled from '../../../../../ui/theme/styled';

export const RootBox = styled(Typography, {
    shouldForwardProp: (prop) => prop !== 'isSelected',
})<{ isSelected: boolean }>(({ theme, isSelected }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: 10,
    fontSize: 12,
    lineHeight: '14px',
    letterSpacing: '0.5px',
    fontFamily: 'Open Sans',
    '&:hover': {
        cursor: 'pointer',
        backgroundColor: isSelected ? 'rgba(140, 140, 140, 1)' : theme.palette.background.default,
    },
    color: isSelected ? theme.palette.common.white : 'inherit',
    backgroundColor: isSelected ? 'rgba(140, 140, 140, 0.8)' : 'inherit',
    [theme.breakpoints.down('md')]: {
        fontSize: 10,
    },
}));
