import { CSSProperties, FC } from 'react';
import { ButtonRoot } from './Style';

interface IProps {
    clickHandler?: () => void;
    title: string;
    style?: CSSProperties;
    disabled?: boolean;
    type?: string;
}

const PrimaryButton: FC<IProps> = ({ clickHandler, title, style, disabled }) => {
    return (
        <ButtonRoot style={{ ...style }} onClick={clickHandler} disabled={!!disabled}>
            {title}
        </ButtonRoot>
    );
};

export default PrimaryButton;
