import { FC, useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { useTheme } from '@mui/system';
import { ESkillStatus } from '../../../interfaces/enums/ESkillStatus';
import { d3Star } from '../../../utils/d3-star';
import { detectBrowser } from '../../../utils/detectBrowser';
import { EBrowser } from '../../../interfaces/enums/EBrowser';
import { isElementInViewport } from '../../../utils/isElementInViewport';

interface IProps {
    currentValue: number;
    expectedValue?: number;
    dimension?: IDimension;
    isRatingMode?: boolean;
    isStarted?: boolean;
    skillStatus?: ESkillStatus;
    now?: boolean;
    index?: number;
    isAssigned?: boolean;
    noWaitingForAnimation?: boolean;
}

interface IDimension {
    width?: number;
    height?: number;
    margin?: {
        right?: number;
        left?: number;
        bottom?: number;
        top?: number;
    };
    starSize?: number;
    starX?: number;
    starY?: number;
}

const defaultDimension: IDimension = {
    width: 400,
    height: 300,
    margin: {
        right: 20,
        left: 40,
        bottom: 50,
        top: 20,
    },
    starSize: 26,
    starX: -5,
    starY: -7,
};

const maximumPoints = 135;

interface Datum {
    type: string;
    value: number;
    color: string;
}

enum DoughnutColors {
    UNASSIGNED = 'rgba(140, 140, 140, 1)',
    ASSIGNED_IN_PROGRESS_WAITING = 'rgba(255, 191, 68, 1)',
    ASSIGNED_IN_PROGRESS_POSITIVE = 'rgba(146, 185, 35, 1)',
    ASSIGNED_ACHIEVED_WAITING = 'rgba(255, 191, 68, 1))',
    ASSIGNED_ACHIEVED_POSITIVE = 'rgba(146, 185, 35, 1)',
    ASSIGNED_ACHIEVED_IN_DANGER = 'rgba(146, 185, 35, 1)',
    BEST_PRACTICE_UNASSIGNED = '#C8C8C8',
    BEST_PRACTICE_ASSIGNED_NOT_ACHIEVED = '#717171',
    BEST_PRACTICE_ASSIGNED_ACHIEVED = '#E5E5E5',
}

const ProgressDoughnutChart: FC<IProps> = ({
    currentValue,
    expectedValue,
    dimension,
    isRatingMode,
    isStarted,
    skillStatus,
    now,
    index,
    isAssigned,
    noWaitingForAnimation = false,
}) => {
    const graphBoxRef = useRef<any>(null);
    const idRef = useRef(Math.floor(Math.random() * 1000000).toString());
    const svgRef = useRef<any>(null);
    const currentArcRef = useRef<any>(null);
    const theme = useTheme();

    const [scrolling, setScrolling] = useState<boolean>(true);
    const [isRendered, setRendered] = useState<boolean>(false);

    useEffect(() => {
        const onScroll = (e: any) => {
            if (graphBoxRef.current) {
                // Only completely visible elements return true:
                var isVisible = isElementInViewport(graphBoxRef.current);

                // Partially visible elements return true:
                //isVisible = elemTop < window.innerHeight && elemBottom >= 0;
                const mainGElement = svgRef.current.select('#main-g');
                if (
                    (isVisible && mainGElement && currentArcRef.current && scrolling) ||
                    (noWaitingForAnimation && scrolling)
                ) {
                    const easeInverse = (ease: any) => {
                        return function (e: any) {
                            var min = 0,
                                max = 1;
                            while (max - min > 1e-3) {
                                var mid = (max + min) * 0.5;
                                let emid = ease(mid);
                                if (emid > e) {
                                    max = mid;
                                } else {
                                    min = mid;
                                }
                            }
                            return max;
                        };
                    };

                    var inverseCubic = easeInverse(d3.easeCubic);
                    var oneOver2Pi = 1.0 / (2 * Math.PI);
                    var total_msec = 1000;

                    let arcFillColor = theme.palette.common.white;
                    if (isRatingMode) {
                        if (skillStatus === ESkillStatus.ASSIGNED_IN_PROGRESS) {
                            if (expectedValue && currentValue < expectedValue) {
                                arcFillColor = DoughnutColors.ASSIGNED_IN_PROGRESS_WAITING;
                            } else {
                                arcFillColor = DoughnutColors.ASSIGNED_IN_PROGRESS_POSITIVE;
                            }
                        } else if (skillStatus === ESkillStatus.UNASSIGNED) arcFillColor = DoughnutColors.UNASSIGNED;
                        else if (skillStatus === ESkillStatus.ASSIGNED_ACHIEVED) {
                            if (expectedValue && currentValue < expectedValue) {
                                arcFillColor = DoughnutColors.ASSIGNED_ACHIEVED_WAITING;
                            } else {
                                arcFillColor = DoughnutColors.ASSIGNED_ACHIEVED_POSITIVE;
                            }
                        } else if (skillStatus === ESkillStatus.ASSIGNED_ACHIEVED_IN_DANGER)
                            arcFillColor = DoughnutColors.ASSIGNED_ACHIEVED_IN_DANGER;
                    }

                    const innerChartData = [
                        {
                            type: 'Current',
                            value: currentValue,
                            color: isAssigned ? arcFillColor : 'rgba(140, 140, 140, 1)',
                        },
                        {
                            type: 'Total',
                            value: maximumPoints - currentValue,
                            color: 'rgba(229, 229, 229, 1)',
                        },
                    ];

                    const currentPieData = d3
                        .pie<Datum>()
                        .sort(null)
                        .value((d) => d.value)(innerChartData);

                    const finalDimensions = {
                        ...defaultDimension,
                        ...dimension,
                        margin: {
                            ...defaultDimension.margin,
                            ...dimension?.margin,
                        },
                    };

                    let r1 = (0.4 * finalDimensions.height!) / 2;
                    let r2 = (0.85 * finalDimensions.height!) / 2;
                    if (!isRatingMode) {
                        r1 = (0.8 * finalDimensions.height!) / 2;
                        r2 = (0.95 * finalDimensions.height!) / 2;
                    }

                    const currentArc = d3
                        .arc<d3.PieArcDatum<Datum>>()
                        .innerRadius(r1 + 1)
                        .outerRadius(r2 - 1);

                    svgRef.current
                        .append('g')
                        .attr('class', 'donut-container')
                        .attr('transform', `translate(${finalDimensions.width! / 2}, ${finalDimensions.height! / 2})`)
                        .selectAll('path')
                        .data(currentPieData)
                        .join('path')
                        .style('stroke', theme.palette.common.black)
                        .style('stroke-width', 0)
                        .style('fill', (d: { data: { color: any } }) => d.data.color)
                        .attr('d', currentArc)
                        .attr('id', 'main-g')
                        .transition()
                        .ease(d3.easeLinear)
                        .delay(0)
                        .duration(function (d: { endAngle: number; startAngle: number }) {
                            if (!isRatingMode) return 0;
                            return (
                                total_msec *
                                (inverseCubic(d.endAngle * oneOver2Pi) - inverseCubic(d.startAngle * oneOver2Pi))
                            );
                        })
                        .attrTween('d', function (d: any) {
                            var start = { startAngle: 0, endAngle: 0 };
                            var interpolate = d3.interpolate(start, d);
                            return function (t: number) {
                                if (currentArcRef.current) return currentArcRef.current(interpolate(t)) + '';
                                return '';
                            };
                        });

                    const c1 = finalDimensions.width! / 2;
                    const c2 = finalDimensions.height! / 2;

                    if (isRatingMode && expectedValue && expectedValue > 0) {
                        svgRef.current
                            .append('line')
                            .attr('id', `line-${idRef.current}`)
                            .attr('x2', () => {
                                return (
                                    5 + c2 + r2 * Math.cos((expectedValue / maximumPoints) * 2 * Math.PI - Math.PI / 2)
                                );
                            })
                            .attr('y2', () => {
                                return c2 + r2 * Math.sin((expectedValue / maximumPoints) * 2 * Math.PI - Math.PI / 2);
                            })
                            .attr('x1', () => {
                                return (
                                    5 + c1 + r1 * Math.cos((expectedValue / maximumPoints) * 2 * Math.PI - Math.PI / 2)
                                );
                            })
                            .attr('y1', () => {
                                return c1 + r1 * Math.sin((expectedValue / maximumPoints) * 2 * Math.PI - Math.PI / 2);
                            })
                            .style('stroke-width', 1)
                            .style('stroke', theme.palette.common.black)
                            .style('fill', theme.palette.common.black)
                            .style('transform', 'translate(-5px, 0)')
                            .style('opacity', '0.9');
                    }
                    setScrolling(false);
                }
            }
        };
        window.addEventListener('scroll', onScroll);
        if (isRendered) onScroll(null);

        return () => window.removeEventListener('scroll', onScroll);
    }, [scrolling, isRendered, expectedValue, isAssigned, noWaitingForAnimation, index]);

    useEffect(() => {
        d3.selectAll(graphBoxRef.current).remove();
        d3.selectAll('#graph-svg' + idRef.current).remove();
        d3.selectAll(`#line-${idRef.current}`).remove();
        setScrolling(true);

        const finalDimensions = {
            ...defaultDimension,
            ...dimension,
            margin: {
                ...defaultDimension.margin,
                ...dimension?.margin,
            },
        };

        let fontSize = 14;
        if (finalDimensions.width! >= 100) fontSize = 24;
        if (finalDimensions.width! >= 200) fontSize = 40;

        let r1 = (0.4 * finalDimensions.height!) / 2;
        let r2 = (0.85 * finalDimensions.height!) / 2;
        if (!isRatingMode) {
            r1 = (0.8 * finalDimensions.height!) / 2;
            r2 = (0.95 * finalDimensions.height!) / 2;
        }

        let ratingCircleFillColor = theme.palette.common.white;
        if (isRatingMode) {
            if (skillStatus === ESkillStatus.ASSIGNED_ACHIEVED_IN_DANGER) ratingCircleFillColor = '#FFA133';
        }

        const browserType = detectBrowser();

        let svg = d3
            .select(graphBoxRef.current)
            .append('svg')
            // .style('padding-top', '10px')
            .attr('id', 'graph-svg' + idRef.current)
            .attr('width', finalDimensions.width! + finalDimensions.margin?.left! + finalDimensions.margin?.right!)
            .attr('height', finalDimensions.height! + finalDimensions.margin!.top! + finalDimensions.margin?.bottom!)
            .style('fill', 'rgba(229, 229, 229, 1)')
            .style(
                'filter',
                browserType !== EBrowser.FIREFOX && browserType !== EBrowser.SAFARI ? 'url(#drop-shadow)' : ''
            )
            .append('g')
            .attr('id', `doughnut-chart-${index ?? 0}`);

        svgRef.current = svg;

        let defs = svg.append('defs');
        let filter = defs.append('filter').attr('id', 'drop-shadow');
        filter.append('feGaussianBlur').attr('in', 'FillPaint').attr('stdDeviation', 3).attr('result', 'blur');
        filter
            .append('feOffset')
            .attr('blur', 3)
            .attr('in', 'blur')
            .attr('dx', 1)
            .attr('dy', 1)
            .attr('result', 'offsetBlur');
        filter.append('feFlood').attr('result', 'offsetColor').attr('floodOpacity', 0.5).attr('floodColor', '#F00');
        filter
            .append('feComposite')
            .attr('in', 'offsetColor')
            .attr('in2', 'offsetBlur')
            .attr('operator', 'in')
            .attr('result', 'offsetBlur');

        let feMerge = filter.append('feMerge');

        feMerge.append('feMergeNode').attr('in', 'offsetBlur');
        feMerge.append('feMergeNode').attr('in', 'SourceGraphic');

        let emptyChartColor = '#rgba(229, 229, 229)';
        if (!isRatingMode) {
            if (skillStatus === ESkillStatus.BEST_PRACTICE_UNASSIGNED) {
                emptyChartColor = DoughnutColors.BEST_PRACTICE_UNASSIGNED;
            } else if (skillStatus === ESkillStatus.BEST_PRACTICE_ASSIGNED_NOT_ACHIEVED)
                emptyChartColor = DoughnutColors.BEST_PRACTICE_ASSIGNED_NOT_ACHIEVED;
            else if (skillStatus === ESkillStatus.BEST_PRACTICE_ASSIGNED_ACHIEVED)
                emptyChartColor = DoughnutColors.BEST_PRACTICE_ASSIGNED_ACHIEVED;
        }

        const emptyChartData = [
            {
                type: 'Total',
                value: maximumPoints,
                color: emptyChartColor,
            },
        ];

        const emptyPieData = d3
            .pie<Datum>()
            .sort(null)
            .value((d) => d.value)(emptyChartData);

        const emptyArc = d3.arc<d3.PieArcDatum<Datum>>().innerRadius(r1).outerRadius(r2);

        svg.append('g')
            .attr('class', 'donut-container')
            .attr('transform', `translate(${finalDimensions.width! / 2}, ${finalDimensions.height! / 2})`)
            .selectAll('path')
            .data(emptyPieData)
            .join('path')
            .style('stroke', theme.palette.common.black)
            .style('stroke-width', isRatingMode ? 2 : 1)
            .attr('d', emptyArc)
            .style('fill', emptyChartColor);

        if (isRatingMode) {
            const currentArc = d3
                .arc<d3.PieArcDatum<Datum>>()
                .innerRadius(r1 + 1)
                .outerRadius(r2 - 1);

            currentArcRef.current = currentArc;

            if (isRatingMode && skillStatus !== undefined) {
                svg.selectAll('circle')
                    .data([1])
                    .enter()
                    .append('circle')
                    .style('stroke-width', 0)
                    .style('fill', ratingCircleFillColor)
                    .attr('id', 'colored-fill')
                    .attr('r', (0.4 * finalDimensions.height!) / 2 - 1)
                    .attr('cx', finalDimensions.width! / 2)
                    .attr('cy', finalDimensions.height! / 2);
            }
        }

        if (!isRatingMode) {
            const star = d3Star();

            let starColor = theme.palette.common.white;
            let backgroundColor = theme.palette.common.white;
            if (skillStatus === ESkillStatus.BEST_PRACTICE_UNASSIGNED) {
                starColor = '#C8C8C8';
                backgroundColor = 'rgba(229, 229, 229)';
            } else if (skillStatus === ESkillStatus.BEST_PRACTICE_ASSIGNED_NOT_ACHIEVED) {
                starColor = '#717171';
                backgroundColor = 'rgba(229, 229, 229)';
            } else if (skillStatus === ESkillStatus.BEST_PRACTICE_ASSIGNED_ACHIEVED) {
                starColor = '#E5E5E5';
                backgroundColor = theme.palette.primary.main;
            }

            const fillChartData = [
                {
                    type: 'Current',
                    value: 100,
                    color: backgroundColor,
                },
            ];

            const currentPieData = d3
                .pie<Datum>()
                .sort(null)
                .value((d) => d.value)(fillChartData);

            const currentArc = d3
                .arc<d3.PieArcDatum<Datum>>()
                .innerRadius(0)
                .outerRadius(r1 + 1);

            svg.append('g')
                .attr('class', 'donut-container')
                .attr('transform', `translate(${finalDimensions.width! / 2}, ${finalDimensions.height! / 2})`)
                .selectAll('path')
                .data(currentPieData)
                .join('path')
                .style('stroke', theme.palette.common.black)
                .style('stroke-width', 0)
                .style('fill', (d) => d.data.color)
                .attr('d', currentArc);

            star.x(finalDimensions?.starX!)
                .y(finalDimensions?.starY!)
                .value(1.0)
                .size(finalDimensions?.starSize!)
                .borderWidth(2)
                .borderColor(starColor)
                .starColor(starColor);

            svg.append('g')
                .attr(
                    'transform',
                    `translate(${
                        (finalDimensions.width! - finalDimensions.margin.left! - Math.round(fontSize / 3)) / 2
                    }, ${(finalDimensions.height! + finalDimensions.margin.top! + Math.round(fontSize / 8)) / 2})`
                )
                .call(star);
        } else {
            let translateLeftPX = 0;
            let translateTopPX = 0;
            let translateBaseLeftPX = finalDimensions.width! - finalDimensions.margin.left!;
            let translateBaseTopPX = finalDimensions.height! + finalDimensions.margin.top!;

            if (currentValue < 10) {
                translateLeftPX = translateBaseLeftPX + 2;
                translateTopPX = translateBaseTopPX + 1;
            } else if (currentValue >= 100) {
                translateLeftPX = translateBaseLeftPX - 15;
                translateTopPX = translateBaseTopPX + 1;
            } else {
                translateLeftPX = translateBaseLeftPX - fontSize / 2;
                translateTopPX = translateBaseTopPX + 1;
            }
            svg.append('g')
                .attr('transform', `translate(${translateLeftPX / 2}, ${translateTopPX / 2})`)
                .attr('font-size', fontSize)
                .attr('font-weight', browserType !== EBrowser.SAFARI ? 700 : 400)
                .attr('color', theme.palette.primary.main)
                .append('text')
                .text(currentValue !== undefined && currentValue !== null ? currentValue : '')
                .attr('fill', 'rgba(53, 41, 87, 1)');
        }
        setRendered(true);
    }, [currentValue, expectedValue, isRatingMode, now, skillStatus]);

    return <div ref={graphBoxRef} style={{ position: 'relative' }}></div>;
};

export default ProgressDoughnutChart;
