import { useEffect, useState } from 'react';

interface IWindowDimensions {
    width: number | null;
    height: number | null;
}

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}

const useWindowDimensions = () => {
    const defaultDim: IWindowDimensions = { width: null, height: null };
    const [windowDimensions, setWindowDimensions] = useState<{ width: number | null; height: number | null }>(
        defaultDim
    );

    useEffect(() => {
        setWindowDimensions(getWindowDimensions()); // Necessary to make sure dimensions are set upon initial load

        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
};

export default useWindowDimensions;
