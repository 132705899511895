import { Snackbar } from '@mui/material';
import { FC, useState, createContext, useEffect, useContext, forwardRef, useMemo, PropsWithChildren } from 'react';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Slide, { SlideProps } from '@mui/material/Slide';
import { Button } from '@material-ui/core';
import { styled } from '@mui/system';
import SuccessIcon from '../assets/icons/snackbar/SuccessIcon';
import ErrorIcon from '../assets/icons/snackbar/ErrorIcon';
import WarningIcon from '../assets/icons/snackbar/WarningIcon';

const MessageBox = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
}));

const StyledButton = styled(Button)(({ theme }) => ({
    marginLeft: 12,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.white,
}));

export interface IToastContext {
    toastMessage: IToastMessage | null;
    setToastMessage: (toastMessage: IToastMessage | null) => void;
}

const ToastContext = createContext<IToastContext>({} as IToastContext);

interface IProps {}

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

type TransitionProps = Omit<SlideProps, 'direction'>;

function TransitionLeft(props: TransitionProps) {
    return <Slide {...props} direction="left" />;
}

function TransitionUp(props: TransitionProps) {
    return <Slide {...props} direction="up" />;
}

function TransitionRight(props: TransitionProps) {
    return <Slide {...props} direction="right" />;
}

function TransitionDown(props: TransitionProps) {
    return <Slide {...props} direction="down" />;
}

export enum EToastSeverity {
    INFO = 'info',
    SUCCESS = 'success',
    ERROR = 'error',
    WARNING = 'warning',
}

export enum EToastSeverityColor {
    INFO = '#654EA3',
    SUCCESS = '#654EA3',
    ERROR = '#654EA3',
    WARNING = '#654EA3',
}

export enum ETransitionDirection {
    LEFT = 'left',
    RIGHT = 'right',
    UP = 'up',
    DOWN = 'down',
}

export interface IToastMessage {
    message: string;
    autoHideDuration?: number;
    isOpen: boolean;
    handleClose?: () => void;
    action?: JSX.Element;
    severity?: EToastSeverity;
    vertical?: 'top' | 'bottom';
    horizontal?: 'left' | 'center' | 'right';
    transition?: ETransitionDirection;
}

export const ToastProvider: FC<PropsWithChildren<IProps>> = ({ children }) => {
    const [toastMessage, setToastMessage] = useState<IToastMessage | null>(null);

    useEffect(() => {
        if (toastMessage) {
        }
    }, [toastMessage]);

    const toastContext: IToastContext = {
        toastMessage,
        setToastMessage,
    };

    const generateTransitionComponent = (transitionDirection: ETransitionDirection | undefined) => {
        switch (transitionDirection) {
            case ETransitionDirection.UP:
                return TransitionUp;
            case ETransitionDirection.DOWN:
                return TransitionDown;
            case ETransitionDirection.LEFT:
                return TransitionLeft;
            case ETransitionDirection.RIGHT:
                return TransitionRight;
        }
        return TransitionLeft;
    };

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        if (toastMessage)
            setToastMessage({
                ...toastMessage,
                isOpen: false,
            });
    };

    const snackbarMemo = useMemo(() => {
        let iconJsx = <></>;
        switch (toastMessage?.severity) {
            case EToastSeverity.SUCCESS:
                iconJsx = <SuccessIcon />;
                break;
            case EToastSeverity.ERROR:
                iconJsx = <ErrorIcon />;
                break;
            case EToastSeverity.INFO:
                iconJsx = <SuccessIcon />;
                break;
            case EToastSeverity.WARNING:
                iconJsx = <WarningIcon />;
                break;
        }
        return (
            <Snackbar
                open={toastMessage?.isOpen}
                autoHideDuration={toastMessage?.autoHideDuration || 5000}
                onClose={toastMessage?.handleClose || handleClose}
                message={toastMessage?.message}
                action={
                    toastMessage?.action || (
                        <Button color="inherit" size="small">
                            Close
                        </Button>
                    )
                }
                ContentProps={{
                    sx: {
                        background: '#FF0',
                    },
                }}
                anchorOrigin={
                    toastMessage?.vertical && toastMessage?.horizontal
                        ? {
                              vertical: toastMessage?.vertical,
                              horizontal: toastMessage?.horizontal,
                          }
                        : {
                              vertical: 'bottom',
                              horizontal: 'right',
                          }
                }
                TransitionComponent={generateTransitionComponent(toastMessage?.transition)}
            >
                <Alert
                    onClose={toastMessage?.handleClose}
                    severity={toastMessage?.severity || EToastSeverity.INFO}
                    icon={iconJsx}
                    sx={{
                        width: '100%',
                        color: 'white',
                        fontWeight: 400,
                        fontSize: '16px',
                        ...(toastMessage?.severity === EToastSeverity.ERROR && {
                            backgroundColor: EToastSeverityColor.ERROR,
                        }),
                        ...(toastMessage?.severity === EToastSeverity.WARNING && {
                            backgroundColor: EToastSeverityColor.WARNING,
                        }),
                        ...(toastMessage?.severity === EToastSeverity.INFO && {
                            backgroundColor: EToastSeverityColor.INFO,
                        }),
                        ...(toastMessage?.severity === EToastSeverity.SUCCESS && {
                            backgroundColor: EToastSeverityColor.SUCCESS,
                        }),
                    }}
                >
                    <MessageBox>
                        {toastMessage?.message}{' '}
                        <StyledButton onClick={toastMessage?.handleClose || handleClose} color="inherit" size="small">
                            Close
                        </StyledButton>
                    </MessageBox>
                </Alert>
            </Snackbar>
        );
    }, [toastMessage]);

    return (
        <ToastContext.Provider value={toastContext}>
            {children}
            {snackbarMemo}
        </ToastContext.Provider>
    );
};
export const useToastContextStateValue: () => IToastContext = () => useContext(ToastContext);

export default ToastContext;
