import React, { useEffect, useState, useCallback } from 'react';

interface IUseExpandProps {
    defaultExpanded: boolean;
}

const useExpand = ({ defaultExpanded }: IUseExpandProps) => {
    const [isExpanded, setExpanded] = useState(false);

    useEffect(() => {
        setExpanded(defaultExpanded);
    }, [defaultExpanded, setExpanded]);

    const expandCallback = useCallback(
        (e?: React.MouseEvent) => {
            setExpanded(true);
            e?.stopPropagation();
        },
        [setExpanded]
    );

    const collapseCallback = useCallback(
        (e?: React.MouseEvent) => {
            setExpanded(false);
            e?.stopPropagation();
        },
        [setExpanded]
    );

    return {
        isExpanded,
        setExpanded,
        expand: expandCallback,
        collapse: collapseCallback,
    };
};

export default useExpand;
