import { Order } from '../hooks/useTable';

const sortPossibleUndefinedStrings = (aString: string | undefined, bString: string | undefined, order: Order) => {
    if (aString === undefined) {
        aString = '';
    }
    if (bString === undefined) {
        bString = '';
    }
    return order === 'asc' ? aString.localeCompare(bString) : bString.localeCompare(aString);
};

export default sortPossibleUndefinedStrings;
