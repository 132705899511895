const DateIcon = () => {
    return (
        <svg width="15" height="15" viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.3333 2.66669H2.66667C1.74619 2.66669 1 3.41288 1 4.33335V16C1 16.9205 1.74619 17.6667 2.66667 17.6667H14.3333C15.2538 17.6667 16 16.9205 16 16V4.33335C16 3.41288 15.2538 2.66669 14.3333 2.66669Z"
                stroke="black"
                strokeOpacity="0.8"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.8333 1V4.33333"
                stroke="black"
                strokeOpacity="0.8"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5.16669 1V4.33333"
                stroke="black"
                strokeOpacity="0.8"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M1 7.66669H16"
                stroke="black"
                strokeOpacity="0.8"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default DateIcon;
