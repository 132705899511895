import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const RootBox = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
});

export const RootInnerBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.common.white,
    maxWidth: '1060px',
    borderRadius: theme.borderRadius?.outside?.small,
}));

export const TitleBox = styled('div')(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: 'auto 200px',
    padding: '16px 24px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
    '& div:last-child': {
        display: 'flex',
        justifySelf: 'flex-end',
    },
}));

export const TitleTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.gray.dark,
    [theme.breakpoints.down('lg')]: {
        fontWeight: 500,
        fontSize: '14px',
    },
}));

export const ContentBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
}));

export const ContentItemsBox = styled('div', {
    shouldForwardProp: (prop) => prop !== 'isEmpty',
})<{ isEmpty?: boolean }>(({ theme, isEmpty = false }) => ({
    display: 'grid',
    gridTemplateColumns: isEmpty ? 'auto' : 'repeat(4, 1fr)',
    gridTemplateRows: 'repeat(3, minmax(0,1fr))',
    gap: 23,
    padding: '32px',
    height: '100%',
    minHeight: '769px',
    borderRadius: '30px',
    [theme.breakpoints.down('lg')]: {
        gridTemplateColumns: isEmpty ? 'auto' : 'repeat(3, 1fr)',
    },
    [theme.breakpoints.down(720)]: {
        gridTemplateColumns: isEmpty ? 'auto' : 'repeat(2, 1fr)',
    },
    [theme.breakpoints.down('md')]: {
        gridTemplateColumns: '1fr',
    },
}));

export const PaginationBox = styled('div')({
    display: 'flex',
    justifyContent: 'flex-end',
});
