import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getTokenAndHeaders } from './helpers/apiUtil';
import useAuthParams from '../hooks/useAuthParams';
import { IAuthParams } from '../interfaces/IAuthParams';
import { apiInstance } from './helpers/apiInstance';
import { IActivityFeedVM } from '../interfaces/views/IActivityFeedVM';
import { mapActivityFeedData } from './helpers/mappers';
import { IHighlightVM } from '../interfaces/views/IHighlightVM';
import { EHighlightType } from '../interfaces/enums/EHighlightType';
import { EApiQueryKey } from '../interfaces/enums/EApiQueryKey';

// GET ACTIVITY FEED
export const getUserActivityFeed = async (authParams: IAuthParams) => {
    const { headers } = await getTokenAndHeaders(authParams);
    const {
        data: { data },
    } = await apiInstance.get(`feed/user`, headers);
    return mapActivityFeedData(data);
};

export const useGetUserActivityFeedQuery: () => UseQueryResult<IActivityFeedVM[], Error> = () => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.ACTIVITY_FEED_FETCH_USER_ACTIVITY],
        queryFn: async () => {
            return getUserActivityFeed(authParams);
        },
        refetchOnWindowFocus: false,
    });
};

// GET HIGHLIGHTS
export const getHighlights = async (authParams: IAuthParams) => {
    // const { headers } = await getTokenAndHeaders(authParams);
    // const {
    //     data: { data },
    // } = await apiInstance.get(`highlights`, headers);
    // return mapHighlightsData(data);
    return [
        {
            type: EHighlightType.CLAIM_BADGE,
        },
        {
            type: EHighlightType.ACHIEVEMENT_EARNED,
        },
        {
            type: EHighlightType.ENGAGEMENT_POINTS_EARNED,
        },
        {
            type: EHighlightType.ENGAGEMENT_POINTS_EARNED,
        },
    ];
};

export const useGetHighlightsQuery: () => UseQueryResult<IHighlightVM[], Error> = () => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.ACTIVITY_FEED_FETCH_HIGHLIGHTS],
        queryFn: async () => {
            return getHighlights(authParams);
        },
        refetchOnWindowFocus: false,
    });
};
