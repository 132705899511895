import { FC } from 'react';

interface IProps {
    width?: string;
    height?: string;
}

const SuccessIcon: FC<IProps> = ({ width, height }) => {
    return (
        <svg
            width={width || '23'}
            height={height || '23'}
            viewBox="0 0 23 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="Group 119">
                <circle id="Ellipse 4" cx="11.5" cy="11.5" r="10.5" fill="white" stroke="#90AE0F" strokeWidth="2" />
                <path
                    id="Vector"
                    d="M16.7272 7.31824L8.68529 15.6819L6.27271 13.1753"
                    stroke="#90AE0F"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </svg>
    );
};

export default SuccessIcon;
