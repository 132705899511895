import styled from '../../theme/styled';

export const RootBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    padding: '20px 0',
    [theme.breakpoints.down('lg')]: {
        padding: '0 0 20px 0',
    },
}));

export const ChartBox = styled('div')({
    width: '100%',
});
