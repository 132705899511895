import { createContext, FC, useContext, PropsWithChildren, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
    useGetTenantPreferencesQuery,
    usePostUsersCurrentPreferences,
    usePostUsersTenantPreferences
} from '../services/UserQueryService';
import { ITenantPreferencesDTO } from '../interfaces/dtos/ITenantPreferencesDTO';
import { IUserPreferenceRequestDTO } from '../interfaces/dtos/IUserPreferenceRequestDTO';
import { ITenantPreferenceRequestDTO } from '../interfaces/dtos/ITenantPreferencesRequestDTO';
import { EToastSeverity, useToastContextStateValue } from './ToastContext';
import { useUserAuthStateValue } from './UserAuthContext';
import { routes } from '../pages/routes';
import { useCrumbsStateValue } from './CrumbsContext';
import { useGetTenantLicenseSettingsQuery, useGetTenantStatusQuery } from '../services/TenantQueryService';
import { ITenantStatusVM } from '../interfaces/views/ITenantStatusVM';
import { ITenantLicenseSettingsVM } from '../interfaces/views/ITenantLicenseSettingsVM';
import { isInTeams } from '../utils/isInTeams';

export interface ISettingsContext {
    tenantSettings?: ITenantPreferencesDTO;
    updateUsersCurrentPreferences: (usersCurrentPreferences: IUserPreferenceRequestDTO, callback?: () => void) => void;
    updateUsersTenantPreferences: (usersTenantPreferences: ITenantPreferenceRequestDTO, callback?: () => void) => void;
    isUpdateActionTriggered: boolean;
    tenantStatus?: ITenantStatusVM;
    isFetchTenantStatusLoading?: boolean;
    licensesSettingsData?: ITenantLicenseSettingsVM;
    isFetchTenantLicensesSettingsLoading?: boolean;
    isTenantSettingsLoading: boolean;
    isFetchTenantStatusError: boolean;
    isFetchTenantLicensesError: boolean;
    isFetchTenantSettingsError: boolean;
    reetchLicensesSettings: () => void;
    refetchTenantStatus: () => void;
    refetchTenantSettings: () => void;
}

export const SettingsContext = createContext<ISettingsContext>({} as ISettingsContext);

interface IProps {}

export const SettingsProvider: FC<PropsWithChildren<IProps>> = ({ children }) => {
    const [tenantSettingsData, setTenantSettingsData] = useState<ITenantPreferencesDTO>();
    const { mutateAsync: mutatePostUsersCurrentPreferencesAsync, isPending: isUpdatePersonalSettingsRequestSent } =
        usePostUsersCurrentPreferences();
    const {
        data: fetchedTenantSettingsData,
        isLoading: isTenantSettingsLoading,
        refetch: refetchTenantSettings,
        isError: isFetchTenantSettingsError
    } = useGetTenantPreferencesQuery();
    const { mutateAsync: mutatePostUsersTenantPreferencesAsync, isPending: isUpdateOrganizationSettingsRequestSent } =
        usePostUsersTenantPreferences();
    const {
        data: fetchedTenantStatusData,
        isLoading: isFetchTenantStatusLoading,
        isError: isFetchTenantStatusError,
        refetch: refetchTenantStatus
    } = useGetTenantStatusQuery();
    const {
        data: fetchedLicensesSettingsData,
        isLoading: isFetchTenantLicensesSettingsLoading,
        isError: isFetchTenantLicensesError,
        refetch: reetchLicensesSettings
    } = useGetTenantLicenseSettingsQuery();
    const { setToastMessage } = useToastContextStateValue();
    const { refetchCurrentUserData } = useUserAuthStateValue();
    const { startNewCrumbs } = useCrumbsStateValue();
    const { t } = useTranslation();

    useEffect(() => {
        if (isInTeams()) {
            startNewCrumbs({
                name: 'Skills',
                pathname: routes.SKILLS
            });
        }
    }, []);

    useEffect(() => {
        setTenantSettingsData(fetchedTenantSettingsData);
    }, [fetchedTenantSettingsData]);

    const updateUsersCurrentPreferences = async (
        usersCurrentPreferences: IUserPreferenceRequestDTO,
        callback?: () => void
    ) => {
        if (callback) callback();
        await mutatePostUsersCurrentPreferencesAsync(usersCurrentPreferences);
        setToastMessage({
            isOpen: true,
            message: t('success.settings.updateSuccess'),
            severity: EToastSeverity.SUCCESS
        });
        refetchCurrentUserData();
    };

    const updateUsersTenantPreferences = async (
        usersTenantPreferences: ITenantPreferenceRequestDTO,
        callback?: () => void
    ) => {
        if (callback) callback();
        const response: ITenantPreferencesDTO = await mutatePostUsersTenantPreferencesAsync(usersTenantPreferences);
        await refetchCurrentUserData();
        setTenantSettingsData(response);
        setToastMessage({
            isOpen: true,
            message: t('success.settings.updateSuccess'),
            severity: EToastSeverity.SUCCESS
        });
    };

    const settingsState: ISettingsContext = {
        tenantSettings: tenantSettingsData,
        updateUsersCurrentPreferences,
        updateUsersTenantPreferences,
        isUpdateActionTriggered: isUpdateOrganizationSettingsRequestSent || isUpdatePersonalSettingsRequestSent,
        tenantStatus: fetchedTenantStatusData,
        isFetchTenantStatusLoading,
        licensesSettingsData: fetchedLicensesSettingsData,
        isFetchTenantLicensesSettingsLoading,
        isTenantSettingsLoading,
        isFetchTenantLicensesError,
        isFetchTenantStatusError,
        reetchLicensesSettings,
        refetchTenantStatus,
        refetchTenantSettings,
        isFetchTenantSettingsError
    };

    return <SettingsContext.Provider value={settingsState}>{children}</SettingsContext.Provider>;
};

export const useSettingsStateValue: () => ISettingsContext = () => useContext(SettingsContext);
