import { FC } from 'react';
import { KeyBox, RootBox, ValueBox, ValueLabelSpan, ValueSuffixSpan } from './Style';

interface IProps {
    keyLabel: string;
    valueLabel: string | number;
    isEmphasized?: boolean;
    valueSuffix?: string;
    secondaryMode?: boolean;
}

const KeyValueInfoBox: FC<IProps> = ({ keyLabel, valueLabel, isEmphasized, valueSuffix, secondaryMode = false }) => {
    return (
        <RootBox isemphasized={isEmphasized ? 1 : 0}>
            <KeyBox secondarymode={secondaryMode ? 1 : 0} isemphasized={isEmphasized ? 1 : 0}>
                {keyLabel}
            </KeyBox>
            <ValueBox secondarymode={secondaryMode ? 1 : 0} isemphasized={isEmphasized ? 1 : 0}>
                <ValueLabelSpan secondarymode={secondaryMode ? 1 : 0}>{valueLabel}</ValueLabelSpan>
                {valueSuffix && <ValueSuffixSpan secondarymode={secondaryMode ? 1 : 0}>{valueSuffix}</ValueSuffixSpan>}
            </ValueBox>
        </RootBox>
    );
};

export default KeyValueInfoBox;
