import { FC, useMemo } from 'react';
import { ERankingMedal } from '../../../../interfaces/enums/ERankingMedal';
import {
    CurrentTypography,
    InfoBox,
    InfoTypeTypography,
    NATypography,
    PercentageTypography,
    RankBox,
    RankPositionBox,
    TotalTypography,
    ValueBox,
} from './Style';
import GoldMedal from '../../../../assets/icons/GoldMedal';
import SilverMedal from '../../../../assets/icons/SilverMedal';
import BronzeMedal from '../../../../assets/icons/BronzeMedal';
import { ERankType } from '../../../../interfaces/enums/ERankType';

interface IProps {
    rank?: number;
    total?: number;
    medal: ERankingMedal;
    type: ERankType;
    isPercentageMode?: boolean;
}

const Rank: FC<IProps> = ({ rank, total, medal, type, isPercentageMode = false }) => {
    const medalMemo = useMemo(() => {
        switch (medal) {
            case ERankingMedal.GOLD:
                return <GoldMedal />;
            case ERankingMedal.SILVER:
                return <SilverMedal />;
            case ERankingMedal.BRONZE:
                return <BronzeMedal />;
            default:
                return <GoldMedal />;
        }
    }, [medal]);

    const rankValueMemo = useMemo(() => {
        if (isPercentageMode) {
            if (!rank) {
                return (
                    <RankPositionBox>
                        <NATypography variant="button">N/A</NATypography>
                    </RankPositionBox>
                );
            }
            return <PercentageTypography variant="subtitle1">{`Top ${rank}%`}</PercentageTypography>;
        }
        if (!rank) {
            return (
                <RankPositionBox>
                    <NATypography variant="button">N/A</NATypography>
                </RankPositionBox>
            );
        }
        return (
            <RankPositionBox>
                <CurrentTypography variant="subtitle1">{`#${rank}`}</CurrentTypography>
                <TotalTypography>{`/${total}`}</TotalTypography>
            </RankPositionBox>
        );
    }, [rank, total, isPercentageMode]);

    const labelMemo = useMemo(() => {
        switch (type) {
            case ERankType.SKILLS:
                return 'Skills Maintained';
            case ERankType.BADGES_CLAIMED:
                return 'Badges Claimed';
            case ERankType.ACHIEVEMENTS:
                return 'Achievements Earned';
            case ERankType.ENGAGEMENT_POINTS:
                return 'Enagement Points Earned';
            case ERankType.BADGES_SHARED:
                return 'Badges Shared';
        }
    }, [type]);

    return (
        <RankBox>
            <ValueBox>
                {medalMemo}
                {rankValueMemo}
            </ValueBox>
            <InfoBox>
                <InfoTypeTypography>{labelMemo}</InfoTypeTypography>
            </InfoBox>
        </RankBox>
    );
};

export default Rank;
