import { format } from 'date-fns';

export const isGraphMonthLabelMatch: (a: Date, b: string | Date) => boolean = (a, b) => {
    const month = format(a, 'MMM');
    if (typeof b === 'string') return month.toLowerCase() === b.toLowerCase();
    else {
        const bString = format(b, 'MMM');
        return month.toLowerCase() === bString.toLowerCase();
    }
};
