import styled from '../../../../ui/theme/styled';

export const RootBox = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
});

export const BadgeForm = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    width: '60%',
    padding: '14px 0',
});

export const DescriptionBox = styled('div')({
    fontFamily: 'Open Sans',
    fontSize: '12px',
    lineHeight: '20px',
    letterSpacing: '0.5px',
    color: 'rgba(0, 0, 0, 0.64)',
});

export const SubmitBox = styled('div')({
    display: 'flex',
    justifyContent: 'flex-end',
});

export const StyledForm = styled('form')({
    width: '100%',
    maxWidth: '1060px',
});

export const NotificationsWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
});

export const BadgesWrapper = styled('div')({});
