import { Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

export const RootBox = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
});

export const RootInnerBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '20px',
    width: '1080px',
    paddingLeft: '20px',
    paddingRight: '20px',
    [theme.breakpoints.down('lg')]: {
        width: '100%',
        marginLeft: 'unset',
    },
}));

export const RootPaper = styled(Paper)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.08), 0px 0.5px 1.5px rgba(0, 0, 0, 0.08)',
    borderRadius: theme.borderRadius?.outside?.small,
}));

export const PaperHeaderBox = styled('div')(({ theme }) => ({
    padding: '8px 25px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.16)',
    fontSize: 18,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down('lg')]: {
        padding: '4px 25px',
    },
}));

export const InfoBox = styled('div')({
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    height: '40px',
});

export const TitleSpan = styled('span')(({ theme }) => ({
    fontFamily: 'Ubuntu',
    fontSize: 18,
    lineHeight: '22px',
    color: 'rgba(0,0,0,0.88)',
    [theme.breakpoints.down('lg')]: {
        fontSize: '14px',
        color: 'rgba(0, 0, 0, 0.8)',
    },
}));

export const CategoryWrapper = styled('div', {
    shouldForwardProp: (prop) => prop !== 'isContentLoading',
})<{ isContentLoading?: boolean }>(({ theme, isContentLoading = false }) => ({
    display: 'grid',
    gap: '27px',
    gridTemplateColumns: isContentLoading ? 'auto' : 'repeat(auto-fill, 180px)',
    justifyContent: 'center',
    width: '100%',
    padding: '33px',
    [theme.breakpoints.down('lg')]: {
        padding: '16px',
    },
}));
