import { debounce } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

export interface IKeyWords {
    keyWords?: string;
}

interface IFilterSearchProps<TE extends IKeyWords> {
    data: TE[];
    dataSerachablePropertyName: string;
    onChangeCallback?: (newFilteredData: TE[]) => TE[];
    setDataCallback: (newData: TE[]) => void;
}

interface IFilterSearchReturn<TE extends IKeyWords> {
    data: TE[];
    searchText: string;
    setSearchText: (searchText: string) => void;
    instantFilterByText: (text: string, currentSkills: TE[]) => TE[];
}

function useFilterSearch<TE extends IKeyWords>({
    data,
    onChangeCallback,
    dataSerachablePropertyName,
    setDataCallback,
}: IFilterSearchProps<TE>): IFilterSearchReturn<TE> {
    const [searchText, setSearchText] = useState<string>('');

    const handleSearchChangeDebounceMemo = useMemo(() => {
        const handleSearchChangeDebounce = debounce((text, currentSkills: TE[], runAnythingChange?: boolean) => {
            let newData = currentSkills.filter(
                (skill) =>
                    (skill[dataSerachablePropertyName as keyof TE] as unknown as string)
                        .toLowerCase()
                        .trim()
                        .includes(text.toLowerCase().trim()) ||
                    (skill.keyWords &&
                        skill
                            ?.keyWords!.split(',')
                            .filter((keyword: string) =>
                                keyword.trim().toLowerCase().includes(text.trim().toLowerCase())
                            ).length > 0)
            );
            if (runAnythingChange && onChangeCallback) newData = onChangeCallback(newData);
            setDataCallback(newData);
        }, 800);
        return handleSearchChangeDebounce;
    }, []);

    const instantFilterByText = (text: string, currentSkills: TE[]) => {
        let newSkills = currentSkills.filter((skill) => {
            return (
                (skill[dataSerachablePropertyName as keyof TE] as unknown as string)
                    .toLowerCase()
                    .trim()
                    .includes(text.toLowerCase().trim()) ||
                (skill.keyWords &&
                    skill
                        ?.keyWords!.split(',')
                        .filter((keyword: string) => keyword.trim().toLowerCase().includes(text.trim().toLowerCase())))
            );
        });
        return newSkills;
    };

    useEffect(() => {
        return () => {
            handleSearchChangeDebounceMemo.cancel();
        };
    });

    useEffect(() => {
        handleSearchChangeDebounceMemo(searchText, data, true);
    }, [searchText, data]);

    return {
        data,
        searchText,
        setSearchText,
        instantFilterByText,
    };
}

export default useFilterSearch;
