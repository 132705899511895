import { FC, PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { CircleWrapper, Dot, MasterSpan, RootBox } from './Style';

interface IProps {
    numberOfDots: number;
    numberOfInProgressDots: number;
    numberOfAchievedDots: number;
    numberOfNeedAttentionDots: number;
    isMaster?: boolean;
    isAssigned?: boolean;
    index?: number;
}

const DottedCircle: FC<PropsWithChildren<IProps>> = ({
    numberOfDots,
    numberOfInProgressDots,
    numberOfAchievedDots,
    numberOfNeedAttentionDots,
    isMaster = false,
    isAssigned = false,
    children,
    index,
}) => {
    const [oddArray, setOddArray] = useState<number[]>([]);
    const [evenArray, setEvenArray] = useState<number[]>([]);

    useEffect(() => {
        const oddArray: number[] = [];
        const evenArray: number[] = [];
        [...Array(numberOfDots).keys()].forEach((i) => {
            if (i % 2 === 1) oddArray.push(i);
            else evenArray.push(i);
        });
        setOddArray(oddArray.reverse());
        setEvenArray(evenArray);
    }, [numberOfDots]);

    const dotsMemo = useMemo(() => {
        return [...Array(numberOfDots).keys()].map((i) => {
            let dotColor = 'rgba(170, 182, 192, 1)';
            const oddItem = oddArray.findIndex((o) => o === i);
            const evenItem = evenArray.findIndex((o) => o === i);

            if (
                oddItem > -1 &&
                oddItem !== undefined &&
                oddItem < numberOfInProgressDots + numberOfAchievedDots + numberOfNeedAttentionDots
            ) {
                dotColor = '#ED8000';
            } else {
                if (i < numberOfInProgressDots + numberOfAchievedDots + numberOfNeedAttentionDots) {
                    if (
                        evenItem > -1 &&
                        evenItem !== undefined &&
                        evenItem + oddArray.length <
                            numberOfInProgressDots + numberOfAchievedDots + numberOfNeedAttentionDots
                    ) {
                        dotColor = '#ED8000';
                    }
                }
            }

            if (oddItem > -1 && oddItem !== undefined && oddItem < numberOfInProgressDots + numberOfAchievedDots) {
                dotColor = 'rgba(255, 191, 68, 1)';
            } else {
                if (i < numberOfInProgressDots + numberOfAchievedDots) {
                    if (
                        evenItem > -1 &&
                        evenItem !== undefined &&
                        evenItem + oddArray.length < numberOfInProgressDots + numberOfAchievedDots
                    ) {
                        dotColor = 'rgba(255, 191, 68, 1)';
                    }
                }
            }

            if (oddItem > -1 && oddItem !== undefined && oddItem < numberOfAchievedDots) {
                dotColor = 'rgba(146, 185, 35, 1)';
            } else {
                if (i < numberOfInProgressDots + numberOfAchievedDots) {
                    if (evenItem > -1 && evenItem !== undefined && evenItem + oddArray.length < numberOfAchievedDots) {
                        dotColor = 'rgba(146, 185, 35, 1)';
                    }
                }
            }

            return (
                <Dot
                    key={i}
                    offsetDeg={numberOfDots % 2 === 1 ? 0 : 10}
                    orderNumber={i}
                    dotFilledColor={dotColor}
                ></Dot>
            );
        });
    }, [numberOfDots, oddArray, evenArray, numberOfAchievedDots, numberOfInProgressDots]);

    return (
        <RootBox
            className={index !== undefined && index >= 0 ? `dotted-circles-wrapper-${index}` : `dotted-circles-wrapper`}
        >
            <CircleWrapper>
                {isAssigned && dotsMemo}
                {children}
            </CircleWrapper>
            <MasterSpan>{isMaster && 'MASTER'}</MasterSpan>
        </RootBox>
    );
};

export default DottedCircle;
