import React, { ChangeEvent, FC, PropsWithChildren, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, useTheme } from '@mui/material';
import { format } from 'date-fns';
import { useNavigate } from 'react-router';
import GraphCard from '../../../components/graph/graph-card/GraphCard';
import { EGraphCardSelect } from '../../../../../interfaces/enums/EGraphCardSelect';
import Table from '../../../../../ui/general/table/Table';
import { IInsightsPersonalSkillVM } from '../../../../../interfaces/views/IInsightsPersonalSkillVM';
import { ITableHeadCell } from '../../../../../interfaces/ITableHeaderCell';
import { useInsightsPersonalSkillsStateValue } from '../../../../../contexts/InsightsPersonalSkillsContext';
import HeartIcon from '../../../../../assets/icons/HeartIcon';
import ProgressDoughnutChart from '../../../../../ui/general/progress-doughnut-chart/ProgressDoughnutChart';
import { ESkillStatus } from '../../../../../interfaces/enums/ESkillStatus';
import {
    FullWidthBox,
    RootBox,
    SearchInputWrapper,
    StyledSearchInput,
    TableItemCountBox,
    TableItemCountTypography,
    TableLink,
} from '../../../Style';
import useInsightsTimelineOption from '../../../../../hooks/useInsightsTimelineOption';
import { IActivityGoal } from '../../../../../interfaces/IActivityGoal';
import { Order } from '../../../../../hooks/useTable';
import { sortNumber } from '../../../../../utils/sortNumber';
import sortPossibleUndefinedStrings from '../../../../../utils/sortPossibleUndefinedStrings';
import { formatDate } from '../../../../../utils/dateUtil';
import { ESkillLevel } from '../../../../../interfaces/enums/ESkillLevel';
import { ECompetenceState } from '../../../../../interfaces/enums/ECompetenceState';
import { DoughnutBox } from './Style';
import { useCrumbsStateValue } from '../../../../../contexts/CrumbsContext';
import { makeSkillRoute, routes } from '../../../../../pages/routes';
import { useInsightsStateValue } from '../../../../../contexts/InsightsContext';
import { EInsightsMode } from '../../../../../interfaces/enums/EInsightsMode';
import { isInTeams } from '../../../../../utils/isInTeams';
import { accessibilityEnterKeyCallback } from '../../../../../utils/accessibilityUtils';

interface ITableHeader {
    doughnutChart: string;
    skillName: string;
    assignmentDate: string;
    isFavorite: boolean;
    pointsFromLastWeek: number;
    pointsFromTarget: number;
    skillScore: number;
    skillTarget: number;
    activityGoal: string;
}

const PersonalSkillsTab: FC<PropsWithChildren> = () => {
    const { t } = useTranslation();
    const { data, scoreLines, changeScoreLinesInterval, isLoading, searchText, setSearchText, isError } =
        useInsightsPersonalSkillsStateValue();
    const { activeTimelineOption, handleTimelineChange } = useInsightsTimelineOption({
        changedTimelineOptionCallback: changeScoreLinesInterval,
        initialTimelineOptionValue: EGraphCardSelect.MONTH_4,
    });
    const headerOverviewRef = useRef([
        {
            colSpan: 4,
            label: '',
        },
        {
            colSpan: 2,
            label: 'Points',
        },
    ]);
    const { appendCrumb, setCrumbs } = useCrumbsStateValue();
    const navigate = useNavigate();
    const { mode } = useInsightsStateValue();
    const theme = useTheme();
    const isMdDown = useMediaQuery(theme.breakpoints.down('md'));

    const headCells: ITableHeadCell<ITableHeader>[] = [
        {
            id: 'doughnutChart',
            label: '',
            customRender: (_, obj: IInsightsPersonalSkillVM) => {
                let skillState: ECompetenceState | undefined;
                if (obj.level === ESkillLevel.PRODUCER) {
                    skillState = obj.producerLevelState;
                } else if (obj.level === ESkillLevel.MASTER) {
                    skillState = obj.masterLevelState;
                }

                let skillStatus: ESkillStatus | undefined;
                if (skillState === ECompetenceState.IN_PROGRESS) skillStatus = ESkillStatus.ASSIGNED_IN_PROGRESS;
                else if (skillState === ECompetenceState.NEEDS_ATTENTITON)
                    skillStatus = ESkillStatus.ASSIGNED_ACHIEVED_IN_DANGER;
                else if (skillState === ECompetenceState.MAINTAIN) skillStatus = ESkillStatus.ASSIGNED_ACHIEVED;
                return (
                    <DoughnutBox>
                        <ProgressDoughnutChart
                            currentValue={obj.skillScore}
                            expectedValue={obj.skillTarget}
                            isRatingMode
                            isAssigned={!!obj.assignmentDate}
                            noWaitingForAnimation
                            skillStatus={skillStatus}
                            dimension={{
                                width: 80,
                                height: 80,
                                margin: {
                                    bottom: 5,
                                    left: 10,
                                    right: 10,
                                    top: 7,
                                },
                            }}
                        />
                    </DoughnutBox>
                );
            },
            customSort: (a: IInsightsPersonalSkillVM, b: IInsightsPersonalSkillVM, order: Order) => {
                return sortNumber(a.skillScore, b.skillScore, order);
            },
        },
        {
            id: 'skillName',
            label: 'Skill Name',
            minWidth: '130px',
        },
        {
            id: 'isFavorite',
            disablePadding: true,
            label: <HeartIcon liked hoverEffect={false} />,
            align: 'center',
            customRender: (value) => {
                return <HeartIcon liked={value} />;
            },
        },
        {
            id: 'assignmentDate',
            label: 'Assigned',
            minWidth: '120px',
            customRender: (value) => {
                return formatDate(value);
            },
        },
        {
            id: 'pointsFromLastWeek',
            label: 'Change From Last Week',
            numeric: true,
        },
        {
            id: 'pointsFromTarget',
            label: 'From Target',
            numeric: true,
        },
        {
            id: 'activityGoal',
            label: 'Goal to Maximize Your Points',
            customSort: (a: IInsightsPersonalSkillVM, b: IInsightsPersonalSkillVM, order: Order) => {
                return sortPossibleUndefinedStrings(a.activityGoal?.text, b.activityGoal?.text, order);
            },
            customRender: (value: IActivityGoal, obj: IInsightsPersonalSkillVM) => {
                if (value && mode === EInsightsMode.STANDARD) {
                    const skillRoute = makeSkillRoute(obj.id, { activityId: value.id });
                    const onSkillLinkClick = (e: React.MouseEvent | React.KeyboardEvent) => {
                        e.stopPropagation();
                        appendCrumb({
                            name: value.text || 'Skill',
                            pathname: skillRoute,
                        });
                        navigate(skillRoute);
                    };
                    return (
                        <TableLink
                            tabIndex={0}
                            onKeyDown={(e: React.KeyboardEvent) =>
                                accessibilityEnterKeyCallback(e, () => onSkillLinkClick(e))
                            }
                            variant={isMdDown ? 'caption' : 'subtitle2'}
                            onClick={onSkillLinkClick}
                        >
                            {value.text}
                        </TableLink>
                    );
                }
                return value?.text || '';
            },
        },
    ];

    const customToolbar = useMemo(() => {
        return (
            <TableItemCountBox>
                {data && (
                    <TableItemCountTypography variant="caption">{`${data.length} Skills`}</TableItemCountTypography>
                )}
            </TableItemCountBox>
        );
    }, [data]);

    const scoreLinesValuesMemo = useMemo(() => {
        if (isLoading || !scoreLines) return undefined;
        if (activeTimelineOption === EGraphCardSelect.MONTH_4) {
            return scoreLines?.map((scoreLine) => {
                return {
                    ...scoreLine,
                    scores: scoreLine.scores,
                };
            });
        } else if (activeTimelineOption === EGraphCardSelect.YEAR_WITH_WEEKS) {
            return scoreLines;
        } else return scoreLines;
    }, [scoreLines, activeTimelineOption, isLoading]);

    const xAxisMemo = useMemo(() => {
        if (isLoading || !scoreLines) return undefined;
        if (scoreLines?.length > 0) {
            if (activeTimelineOption === EGraphCardSelect.MONTH_4) {
                return scoreLines[0].scores.map((scoreItem) => format(scoreItem.date as Date, 'MM/d/yy'));
            } else if (activeTimelineOption === EGraphCardSelect.YEAR_WITH_WEEKS) {
                return scoreLines[0].scores.map((scoreItem) => format(scoreItem.date as Date, 'MM/d/yy'));
            }
        }
        return [];
    }, [scoreLines, activeTimelineOption, isLoading]);

    const handleTableRowClick = (id: string, obj: IInsightsPersonalSkillVM) => {
        const pathname = makeSkillRoute(obj.skillId);
        if (isInTeams()) {
            setCrumbs([
                {
                    name: 'Insights',
                    pathname: routes.INSIGHTS_PERSONAL_SKILLS,
                },
                {
                    pathname,
                    name: obj.skillName,
                },
            ]);
        } else {
            appendCrumb({
                pathname,
                name: obj.skillName,
            });
        }
        navigate(pathname);
    };

    return (
        <RootBox>
            <GraphCard
                scoreLines={scoreLinesValuesMemo}
                xAxis={xAxisMemo}
                handleTimelineChange={handleTimelineChange}
                translations={{ graphCardTitle: t('insights.personal.skills.graphCardTitle') }}
                isDataEmpty={scoreLinesValuesMemo && scoreLinesValuesMemo.length === 0}
                isLoading={isLoading || scoreLinesValuesMemo === undefined}
            />
            <SearchInputWrapper className="search-box">
                <StyledSearchInput
                    placeholder="Search Skills"
                    value={searchText || ''}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        if (setSearchText) setSearchText(e.target.value);
                    }}
                />
            </SearchInputWrapper>
            <FullWidthBox>
                <Table<IInsightsPersonalSkillVM, ITableHeader>
                    headCells={headCells}
                    data={data}
                    propertyKeys={headCells.map((headCell) => {
                        return headCell.id;
                    })}
                    tableTitlePlural=""
                    isFilterControlVisible={false}
                    isFilterDrawerOpen={false}
                    isLoading={isLoading}
                    isError={isError}
                    customToolbar={customToolbar}
                    headerOverviewConfig={headerOverviewRef.current}
                    initialOrderBy="skillName"
                    onRowClick={mode === EInsightsMode.STANDARD ? handleTableRowClick : undefined}
                    customMediaColumnName="doughnutChart"
                    customMediaSecondaryColumns={['isFavorite']}
                />
            </FullWidthBox>
        </RootBox>
    );
};

export default PersonalSkillsTab;
