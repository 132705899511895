import { useMutation, UseMutationResult, useQuery, UseQueryResult } from '@tanstack/react-query';
import { getTokenAndHeaders } from './helpers/apiUtil';
import useAuthParams from '../hooks/useAuthParams';
import { IAuthParams } from '../interfaces/IAuthParams';
import { apiInstance } from './helpers/apiInstance';
import { IMotwVM } from '../interfaces/views/IMotwVM';
import { mapMOTWs } from './helpers/mappers';
import { EApiQueryKey } from '../interfaces/enums/EApiQueryKey';

export interface IPostMotwLaunchedRequest {
    motwId: string;
}

// GET MOTW
export const getMOTW = async (authParams: IAuthParams) => {
    const { headers } = await getTokenAndHeaders(authParams);
    const {
        data: { data },
    } = await apiInstance.get(`motw`, headers);
    return mapMOTWs(data);
};

export const useGetMOTWQuery: () => UseQueryResult<IMotwVM[], Error> = () => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.MOTW_FETCH],
        queryFn: async () => {
            return getMOTW(authParams);
        },
        refetchOnWindowFocus: false,
    });
};

// POST MOTW LAUNCHED
export const postMotwLaunched: (
    variables: IPostMotwLaunchedRequest & { authParams: IAuthParams }
) => Promise<undefined> = async (variables) => {
    const { authParams, motwId } = variables;
    const { headers } = await getTokenAndHeaders(authParams);

    const {
        data: { data },
    } = await apiInstance.post(`motw/${motwId}/launched`, undefined, headers);
    return data;
};

export const usePostMotwLaunched: () => UseMutationResult<undefined, Error, IPostMotwLaunchedRequest> = () => {
    const authParams = useAuthParams();
    return useMutation({
        mutationFn: (variables: IPostMotwLaunchedRequest) => {
            return postMotwLaunched({ ...variables, authParams });
        },
    });
};
