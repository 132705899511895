import { Select } from '@mui/material';
import styled from '../../theme/styled';

export const StyledSelect = styled(Select)(({ theme }) => ({
    display: 'flex',
    gap: '32px',
    boxShadow: 'none',
    backgroundColor: `${theme.palette.common.white} !important`,
    '.MuiSelect-select': {
        height: '36px !important',
        backgroundColor: `${theme.palette.common.white} !important`,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        width: '100%',
        display: 'block !important',
        [theme.breakpoints.down('md')]: {
            fontSize: '12px',
        },
    },
    '& .MuiSelect-outlined.MuiInputBase-input': {
        [theme.breakpoints.down('md')]: {
            fontSize: '14px',
        },
    },
    '& .MuiSvgIcon-root': {
        position: 'absolute',
        right: 0,
        pointerEvents: 'none',
    },
    '.MuiSelect-icon': {
        top: 0,
        height: 36,
        position: 'absolute',
        right: 10,
        pointerEvents: 'none',
        transform: 'rotate(180deg)',
    },
    '.MuiSelect-iconOpen': {
        transform: 'rotate(0deg)',
    },
    '.MuiOutlinedInput-notchedOutline': { border: 0 },
}));
