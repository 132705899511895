import { IAuthParams } from '../interfaces/IAuthParams';
import useAuthParams from '../hooks/useAuthParams';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { getTokenAndHeaders } from './helpers/apiUtil';
import { apiInstance } from './helpers/apiInstance';
import { mapShowcaseBadges } from './helpers/mappers';
import { IGetShowcaseBadgeResponseVM } from '../interfaces/views/IGetShowcaseBadgeResponseVM';
import { EApiQueryKey } from '../interfaces/enums/EApiQueryKey';

// GET SHOWCASE BADGES
export const getShowcaseBadgesQuery = async (authParams: IAuthParams) => {
    const { headers } = await getTokenAndHeaders(authParams);
    const {
        data: { data },
    } = await apiInstance.get(`showcase/badges`, headers);
    return mapShowcaseBadges(data);
};

export const useGetShowcaseBadgesQuery: () => UseQueryResult<IGetShowcaseBadgeResponseVM, Error> = () => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.SHOWCASE_GET_BADGES],
        queryFn: async () => {
            return getShowcaseBadgesQuery(authParams);
        },
        refetchOnWindowFocus: false,
    });
};
