import { FC } from 'react';

interface IProps {
    isDisabled?: boolean;
}

const QuestionIconSVG: FC<IProps> = ({ isDisabled = false }) => {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22 10.9999C22 4.92492 17.0746 -0.00012207 11 -0.00012207C4.92472 -0.00012207 0 4.92459 0 10.9999C0 17.0752 4.92472 21.9999 11 21.9999C17.0746 21.9999 22 17.0748 22 10.9999ZM2 10.9999C2 6.02916 6.02928 1.99988 11 1.99988C15.9701 1.99988 20 6.02952 20 10.9999C20 15.9702 15.9701 19.9999 11 19.9999C6.02928 19.9999 2 15.9706 2 10.9999ZM9.0331 8.33181C9.39975 7.28987 10.5418 6.74247 11.5839 7.10918C12.3851 7.39113 12.9211 8.14923 12.9198 8.99831C12.9198 9.47015 12.553 9.95924 11.8651 10.4178C11.5882 10.6024 11.2855 10.7639 10.9824 10.8986C10.8029 10.9783 10.6684 11.0296 10.6036 11.0512C10.0796 11.2258 9.79647 11.7922 9.97112 12.3161C10.1458 12.84 10.7121 13.1232 11.236 12.9486C11.6881 12.7979 12.3256 12.5145 12.9745 12.0819C14.1616 11.2905 14.9198 10.2796 14.9198 8.99988C14.9225 7.30252 13.8505 5.78662 12.2478 5.22258C10.1637 4.48924 7.87981 5.584 7.1465 7.66794C6.96317 8.18891 7.23689 8.75986 7.75786 8.94318C8.27884 9.1265 8.84978 8.85279 9.0331 8.33181ZM10.29 16.7094C10.48 16.8994 10.73 16.9994 11 16.9994C11.27 16.9994 11.52 16.8994 11.71 16.7094C11.9 16.5204 12 16.2704 12 15.9994C12 15.7394 11.89 15.4794 11.71 15.2894C11.34 14.9204 10.67 14.9204 10.29 15.2894C10.11 15.4794 10 15.7394 10 15.9994C10 16.2704 10.1 16.5204 10.29 16.7094Z"
                fill="#8C8C8C"
                opacity={isDisabled ? 0.3 : 1}
            />
        </svg>
    );
};

export default QuestionIconSVG;
