import { FC, useEffect } from 'react';
import { Outlet } from 'react-router';
import { useTranslation } from 'react-i18next';
import { ITab, useTabsStateValue } from '../../contexts/TabsContext';
import { useUserAuthStateValue } from '../../contexts/UserAuthContext';
import { isUserAllowedToAccess } from '../../pages/routeRules';
import { ERoute } from '../../interfaces/enums/ERoute';
import { routes } from '../../pages/routes';
import usePageName from '../../hooks/usePageName';

const Admin: FC = () => {
    const { currentUserData } = useUserAuthStateValue();
    const { setTabs } = useTabsStateValue();
    const { t } = useTranslation();
    usePageName({ pageName: t('pages.admin') });

    useEffect(() => {
        if (!currentUserData) {
            setTabs([]);
            return;
        }
        const tabs: ITab[] = [
            {
                id: 1,
                title: 'Licenses',
                fullTitle: 'Licenses',
                path: routes.ADMIN_LICENSES,
                isUserAllowedToAccess: isUserAllowedToAccess(
                    ERoute.ADMIN_LICENSES,
                    currentUserData.roles,
                    currentUserData.permissions
                ),
            },
            {
                id: 2,
                title: 'Roles',
                fullTitle: 'Roles',
                path: routes.ADMIN_ROLES,
                isUserAllowedToAccess: isUserAllowedToAccess(
                    ERoute.ADMIN_ROLES,
                    currentUserData.roles,
                    currentUserData.permissions
                ),
            },
            {
                id: 3,
                title: 'History',
                fullTitle: 'History',
                path: [routes.ADMIN_HISTORY, routes.ADMIN_HISTORY_DETAILS],
                isUserAllowedToAccess: isUserAllowedToAccess(
                    ERoute.ADMIN_HISTORY,
                    currentUserData.roles,
                    currentUserData.permissions
                ),
            },
        ];
        setTabs(tabs.filter((tab) => tab.isUserAllowedToAccess));
        return () => {
            setTabs([]);
        };
    }, [currentUserData]);

    return <Outlet />;
};

export default Admin;
