import { styled } from '@mui/material/styles';

export const RootBox = styled('div', {
    shouldForwardProp: (prop) => prop !== 'isActive' && prop !== 'isClickable',
})<{ isActive: boolean; isClickable: boolean }>(({ theme: { palette, borderRadius }, isActive, isClickable }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    backgroundColor: palette.common.white,
    minWidth: '100px',
    height: '120px',
    flex: 1,
    boxShadow: '0px 2.5px 8px rgba(0, 0, 0, 0.08), 0px 1px 4px rgba(0, 0, 0, 0.08)',
    borderRadius: borderRadius?.actions?.large,
    border: isActive ? `1px solid ${palette.primary.main}` : `1px solid ${palette.common.white}`,
    cursor: isClickable ? 'pointer' : 'inherit',
    '&:hover': {
        backgroundColor: isActive || !isClickable ? palette.common.white : palette.background.default,
        border:
            isActive && isClickable ? `1px solid ${palette.primary.main}` : `1px solid ${palette.background.default}`,
    },
}));

export const IconNumberWrapper = styled('div')({
    display: 'flex',
    width: '100%',
    gap: '12px',
    alignItems: 'center',
    padding: '10px 20px 0 17px',
    justifyContent: 'flex-start',
});

export const LabelBox = styled('div', {
    shouldForwardProp: (prop) => prop !== 'paddingBottom',
})<{ paddingBottom?: string }>(({ theme, paddingBottom }) => ({
    textTransform: 'uppercase',
    fontWeight: 500,
    fontFamily: 'Ubuntu',
    fontSize: 12,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    padding: '0px 10px 0 15px',
    lineHeight: '16px',
    letterSpacing: '1.25px',
    minHeight: 40,
    color: theme.palette.common.black,
    paddingBottom: paddingBottom ? paddingBottom : 'unset',
}));

export const CircleSpan = styled('span', {
    shouldForwardProp: (prop) => prop !== 'color',
})<{ color?: string }>(({ color }) => ({
    backgroundColor: color,
    width: '12px',
    height: '12px',
    borderRadius: '6px',
}));

export const ValueSpan = styled('span')({
    fontSize: 32,
    fontWeight: 500,
    fontFamily: 'Ubuntu',
    letterSpacing: '0.25px',
    lineHeight: '40px',
    color: 'rgba(0, 0, 0, 0.8)',
});
