import { FC, useEffect, useMemo, KeyboardEvent } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Skeleton, Typography, useMediaQuery, useTheme } from '@mui/material';
import {
    ContentBox,
    RootBox,
    TitleBox,
    TitleTypography,
    ContentWrapper,
    TitleViewAllWrapper,
    LoadingBox,
    ViewAllLink
} from './Style';
import NoDataCard from '../../../../ui/cards/no-data-card/NoDataCard';
import PrevNextItem from '../../../../ui/general/prev-next-item/PrevNextItem';
import usePrevNextVisible from '../../../../hooks/usePrevNextVisible';
import ShowcaseBadge from '../showcase-badge/ShowcaseBadge';
import { IShowcaseBadgeCard } from '../../../../interfaces/IShowcaseBadgeCard';
import { makeBadgesAllByCategoryRoute } from '../../../../pages/routes';
import { useCrumbsStateValue } from '../../../../contexts/CrumbsContext';
import Loading from '../../../../ui/general/loading/Loading';
import SomethingWentWrongCard from '../../../../ui/cards/something-went-wrong-card/SomethingWentWrongCard';
import { accessibilityEnterSpaceKeyCallback } from '../../../../utils/accessibilityUtils';
import { isInTeams } from '../../../../utils/isInTeams';

interface IProps {
    badgeGroups: IShowcaseBadgeCard[];
    isLoading: boolean;
    isError: boolean;
    errorCallback: () => void;
    isMaxSize: boolean;
}

const ShowcaseBadgeCard: FC<IProps> = ({ isLoading = false, badgeGroups, isError, errorCallback, isMaxSize }) => {
    const { t } = useTranslation();
    const { changeHandler, visibleIndex, changeVisibleIndex } = usePrevNextVisible({ step: 1 });
    const navigate = useNavigate();
    const { appendCrumb, setCrumbs } = useCrumbsStateValue();
    const theme = useTheme();
    const is800Down = useMediaQuery(theme.breakpoints.down(800));

    useEffect(() => {
        if (badgeGroups) {
            const firstNonEmptyBadgeCategoryIndex = badgeGroups.findIndex(
                (badgeGroup) => badgeGroup.badgeList && badgeGroup.badgeList.length > 0
            );
            if (firstNonEmptyBadgeCategoryIndex !== -1) {
                changeVisibleIndex(firstNonEmptyBadgeCategoryIndex);
            } else {
                changeVisibleIndex(0);
            }
        }
    }, [badgeGroups]);

    const contentMemo = useMemo(() => {
        if (isError) {
            return <SomethingWentWrongCard boxStyle={{ boxShadow: 'none' }} actionCallback={errorCallback} />;
        }
        if (isLoading)
            return (
                <LoadingBox>
                    <Loading />
                </LoadingBox>
            );
        if (
            (!isLoading && badgeGroups?.length === 0) ||
            (badgeGroups && badgeGroups[visibleIndex] && badgeGroups[visibleIndex].badgeList?.length === 0)
        ) {
            return <NoDataCard textStyle={{ fontSize: 14 }} message={t('showcase.home.noBadges')} noBorderShadow />;
        }
        return badgeGroups[visibleIndex].badgeList?.slice(0, isMaxSize && !is800Down ? 4 : 3).map((badge, index) => {
            return (
                <ShowcaseBadge
                    key={badge.id}
                    state={badgeGroups[visibleIndex].state}
                    badgeImage={badge.image}
                    claimedDate={badge.claimedDate}
                    verifiedDate={badge.verifiedDate}
                    inProgressSkillStates={badge.inProgressSkillStates}
                    badgeId={badge.id.toString()}
                    title={badge.title}
                    level={badge.level}
                    outcomeId={badge.outcomeId}
                    userBadgeUrl={badge.userBadgeUrl}
                    index={index}
                />
            );
        });
    }, [isLoading, badgeGroups, visibleIndex, isError, errorCallback, isMaxSize, is800Down]);

    const viewAllBadgesClickHandler = () => {
        if (isLoading) return;
        const route = makeBadgesAllByCategoryRoute(badgeGroups[visibleIndex].badgeCategory as string);
        if (isInTeams()) {
            setCrumbs([
                {
                    name: 'Showcase',
                    pathname: '/showcase'
                },
                {
                    name: 'Badges',
                    pathname: route
                }
            ]);
        } else {
            appendCrumb({
                name: 'Badges',
                pathname: route
            });
        }
        navigate(route);
    };

    const titleMemo = useMemo(() => {
        if (isError) {
            return <TitleTypography variant='subtitle1'>{t('showcase.home.badges')}</TitleTypography>;
        }
        if (isLoading) {
            return (
                <>
                    <Skeleton width='30%' height='26px' />
                    <Skeleton width='30%' height='26px' />
                </>
            );
        }
        if (badgeGroups[visibleIndex]) {
            return (
                <>
                    <TitleViewAllWrapper>
                        <TitleTypography variant='subtitle1'>{badgeGroups[visibleIndex].title}</TitleTypography>
                        {(badgeGroups[visibleIndex].badgeList?.length || 0) > 0 && (
                            <Typography variant='overline'>
                                <ViewAllLink
                                    isDisabled={isLoading}
                                    href='#'
                                    role='button'
                                    onClick={viewAllBadgesClickHandler}
                                    onKeyDown={(e: KeyboardEvent<any>) =>
                                        accessibilityEnterSpaceKeyCallback(e, viewAllBadgesClickHandler)
                                    }
                                >
                                    {t('showcase.home.viewAllBadges')}
                                </ViewAllLink>
                            </Typography>
                        )}
                    </TitleViewAllWrapper>
                    <PrevNextItem
                        isNextDisabled={!badgeGroups || visibleIndex + 1 >= badgeGroups.length}
                        isPreviousDisabled={visibleIndex <= 0}
                        changeHandler={changeHandler}
                        infoJsx={
                            badgeGroups && badgeGroups.length > 0 ? `${visibleIndex + 1} / ${badgeGroups?.length}` : ''
                        }
                    />
                </>
            );
        }
        return <></>;
    }, [isLoading, badgeGroups, visibleIndex, isError]);

    return (
        <RootBox>
            <TitleBox id='showcase-badges-card-header'>{titleMemo}</TitleBox>
            <ContentWrapper>
                <ContentBox
                    isMaxSize={isMaxSize}
                    isEmpty={
                        !badgeGroups ||
                        badgeGroups.length === 0 ||
                        badgeGroups[visibleIndex].badgeList?.length === 0 ||
                        isLoading ||
                        isError
                    }
                >
                    {contentMemo}
                </ContentBox>
            </ContentWrapper>
        </RootBox>
    );
};

export default ShowcaseBadgeCard;
