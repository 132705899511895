import { FC } from 'react';

const SkillBestPractice: FC = () => {
    return (
        <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_1336_7706)">
                <circle cx="36" cy="32" r="32" fill="#717171" />
                <circle cx="36" cy="32" r="31" stroke="black" strokeWidth="2" />
            </g>
            <circle cx="36" cy="32" r="27" fill="#E5E5E5" />
            <path
                d="M36 18L40.326 26.764L50 28.178L43 34.996L44.652 44.628L36 40.078L27.348 44.628L29 34.996L22 28.178L31.674 26.764L36 18Z"
                fill="#717171"
            />
            <defs>
                <filter
                    id="filter0_d_1336_7706"
                    x="0"
                    y="0"
                    width="72"
                    height="72"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1336_7706" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1336_7706" result="shape" />
                </filter>
            </defs>
        </svg>
    );
};

export default SkillBestPractice;
