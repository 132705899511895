import { FC, PropsWithChildren, useEffect, useMemo, useRef } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/system';
import NCheckbox from '../../../../ui/form/n-checkbox/NCheckbox';
import BasicCard, { EStatus } from '../../../../ui/cards/basic-card/BasicCard';
import {
    InnerOptionsBox,
    RootBox,
    StyledForm,
    SubmitBox,
    ManagerWrapper,
    NotificationsWrapper,
    LeaderboardWrapper,
    CollectorStatusWrapper,
    LicenseUtilizationWrapper,
    RootColumn,
    LicenseUtilizationInfoItemsWrapper,
    CollectorStatusInfoItemsWrapper,
    TenantStatusBox,
    TenantStatusTitleTypography,
    TenantStatusContentTypography,
} from './Style';
import { useSettingsStateValue } from '../../../../contexts/SettingsContext';
import PrimaryButton from '../../../../ui/buttons/primary-button/PrimaryButton';
import { ITenantPreferenceRequestDTO } from '../../../../interfaces/dtos/ITenantPreferencesRequestDTO';
import SettingInfoItem from '../../setting-info-item/SettingInfoItem';
import ProgressBar from '../../../../ui/general/progress-bar/ProgressBar';
import { formatDate } from '../../../../utils/dateUtil';
import { EAnonymizedReportStatus } from '../../../../interfaces/enums/EAnonymizedReportStatus';
import { ETenantStatusColor } from '../../../../interfaces/enums/ETenantStatusColor';
import SomethingWentWrongCard from '../../../../ui/cards/something-went-wrong-card/SomethingWentWrongCard';

interface IProps {}

const OrganizationalSettingsTab: FC<PropsWithChildren<IProps>> = () => {
    const {
        control,
        setValue,
        reset,
        formState: { isDirty },
        handleSubmit,
    } = useForm();
    const watchEnableManagerCanSeeTeamMembersInsights = useWatch({
        name: 'enableManagerCanSeeTeamMembersInsights',
        control: control,
    });
    const { tenantSettings } = useSettingsStateValue();
    const formRef = useRef<HTMLFormElement>(null);
    const {
        updateUsersTenantPreferences,
        isUpdateActionTriggered,
        licensesSettingsData,
        tenantStatus,
        isFetchTenantLicensesSettingsLoading,
        isFetchTenantStatusLoading,
        isTenantSettingsLoading,
        isFetchTenantLicensesError,
        isFetchTenantStatusError,
        isFetchTenantSettingsError,
        refetchTenantStatus,
        reetchLicensesSettings,
        refetchTenantSettings,
    } = useSettingsStateValue();
    const { t } = useTranslation();
    const { palette } = useTheme();

    useEffect(() => {
        if (tenantSettings) {
            reset();
            Object.entries(tenantSettings).forEach(([key, value]) => {
                setValue(key, value);
            });
        }
    }, [tenantSettings]);

    const handleUpdateSettings = (data: any) => {
        const submitData = data as ITenantPreferenceRequestDTO;
        if (!watchEnableManagerCanSeeTeamMembersInsights)
            submitData.enableManagerCanAssignOutcomesToTeamMembers = false;
        updateUsersTenantPreferences(submitData, () => reset({}, { keepValues: true }));
    };

    const tenantStatusOverallMemo = useMemo(() => {
        switch (tenantStatus?.overallState) {
            case ETenantStatusColor.GREEN:
                return EStatus.SUCCESS;
            case ETenantStatusColor.RED:
                return EStatus.ERROR;
            case ETenantStatusColor.YELLOW:
                return EStatus.WARNING;
        }
        return undefined;
    }, [tenantStatus]);

    const tenantStatusCardContentMemo = useMemo(() => {
        if (tenantStatus)
            return (
                <>
                    <CollectorStatusInfoItemsWrapper>
                        <SettingInfoItem
                            status={tenantStatus.anonymizedReportsState}
                            label={t('settings.organization.anonimyzedReports')}
                            value={
                                tenantStatus?.anonymizedReports === EAnonymizedReportStatus.ON
                                    ? t('settings.organization.anonymizedReportsOn')
                                    : t('settings.organization.anonymizedReportsOff')
                            }
                        />
                        <SettingInfoItem
                            status={tenantStatus.lastScoreDateState}
                            label={t('settings.organization.lastScoreDate')}
                            value={formatDate(tenantStatus?.lastScoreDate)}
                        />
                        <SettingInfoItem
                            status={tenantStatus.usersScoredState}
                            label={t('settings.organization.usersScored')}
                            value={`${tenantStatus?.actualNumUsersScored}/${
                                tenantStatus?.actualNumUsersScored + tenantStatus?.possibleNumUsersScored
                            }`}
                        />
                    </CollectorStatusInfoItemsWrapper>
                    <TenantStatusBox>
                        {!isFetchTenantStatusError && (
                            <>
                                <TenantStatusTitleTypography variant="overline">
                                    {t('settings.organization.recommendation')}
                                </TenantStatusTitleTypography>
                                <TenantStatusContentTypography variant="caption">
                                    Your tenant is operating normally. No action needed. Your tenant is operating
                                    normally. No action needed.
                                </TenantStatusContentTypography>
                            </>
                        )}
                    </TenantStatusBox>
                </>
            );
        return <></>;
    }, [tenantStatus]);

    const licenseUtilizationCardContentMemo = useMemo(() => {
        if (licensesSettingsData)
            return (
                <LicenseUtilizationInfoItemsWrapper>
                    <SettingInfoItem
                        label={t('settings.organization.m365TenantLicenses')}
                        value={'' + licensesSettingsData.m365Licenses}
                    />
                    <SettingInfoItem
                        label={t('settings.organization.licensesAllocated')}
                        value={'' + licensesSettingsData.nuliaLicensesAllocated}
                    />
                    <SettingInfoItem
                        label={t('settings.organization.licensesAssigned')}
                        value={'' + licensesSettingsData.nuliaLicensesAssigned}
                    />
                    <SettingInfoItem
                        label={t('settings.organization.licensesUnassiged')}
                        value={'' + licensesSettingsData.nuliaLicensesAvailable}
                    />
                    <SettingInfoItem
                        tooltip="Nulia Engagement Score"
                        label={t('settings.organization.engagementScore')}
                        value={'' + licensesSettingsData.nuliaEngagementScore}
                    />
                    <ProgressBar
                        value={licensesSettingsData.nuliaEngagementScore}
                        maximum={100}
                        color={palette.primary.main}
                    />
                </LicenseUtilizationInfoItemsWrapper>
            );
        return <></>;
    }, [licensesSettingsData]);

    const mainSettingsMemo = useMemo(() => {
        if (isFetchTenantSettingsError) {
            return (
                <BasicCard isLoading={false} title={t('settings.organization.title')}>
                    <SomethingWentWrongCard boxStyle={{ boxShadow: 'none' }} actionCallback={refetchTenantSettings} />
                </BasicCard>
            );
        }
        return (
            <>
                <NotificationsWrapper id="settings-notifications-cards-box">
                    <BasicCard
                        containerId="email-notifications-cards-box"
                        isLoading={isTenantSettingsLoading}
                        title={t('settings.organization.emailNotificationPreference')}
                    >
                        <Controller
                            name={'enableOnboardingEmails'}
                            control={control}
                            render={({ field }) => (
                                <NCheckbox
                                    isChecked={field.value}
                                    label={t('settings.organization.sendOnboardingEmails')}
                                    field={field}
                                    setValue={setValue}
                                />
                            )}
                        />
                        <Controller
                            name={'enableWeeklyEmails'}
                            control={control}
                            render={({ field }) => (
                                <NCheckbox
                                    isChecked={field.value}
                                    label={t('settings.organization.sendWeeklyEmails')}
                                    field={field}
                                    setValue={setValue}
                                />
                            )}
                        />
                        <Controller
                            name={'enableAllEmails'}
                            control={control}
                            render={({ field }) => (
                                <NCheckbox
                                    isChecked={field.value}
                                    label={t('settings.organization.sendOtherEmails')}
                                    field={field}
                                    setValue={setValue}
                                />
                            )}
                        />
                    </BasicCard>
                    <BasicCard
                        isLoading={isTenantSettingsLoading}
                        containerId="teams-notifications-box"
                        title={t('settings.organization.teamsNotificationPreference')}
                    >
                        <Controller
                            name={'enableOutcomeAssignedNotification'}
                            control={control}
                            render={({ field }) => (
                                <NCheckbox
                                    isChecked={field.value}
                                    label={t('settings.organization.sendOutcomeAssignedNotifications')}
                                    field={field}
                                    setValue={setValue}
                                />
                            )}
                        />
                        <Controller
                            name={'enableBadgeEligibiltyNotification'}
                            control={control}
                            render={({ field }) => (
                                <NCheckbox
                                    isChecked={field.value}
                                    label={t('settings.organization.sendBadgeEligibilityNotifications')}
                                    field={field}
                                    setValue={setValue}
                                />
                            )}
                        />
                        <Controller
                            name={'enableWeeklyUpdateAvailableNotification'}
                            control={control}
                            render={({ field }) => (
                                <NCheckbox
                                    isChecked={field.value}
                                    label={t('settings.organization.sendWeeklyUpdateAvailableNotifications')}
                                    field={field}
                                    setValue={setValue}
                                />
                            )}
                        />
                    </BasicCard>
                </NotificationsWrapper>
                <ManagerWrapper>
                    <BasicCard
                        containerId="settings-manager-cards-box"
                        isLoading={isTenantSettingsLoading}
                        title={t('settings.organization.managerSettings')}
                    >
                        <Controller
                            name={'enableManagerCanSeeTeamMembersInsights'}
                            control={control}
                            render={({ field }) => (
                                <NCheckbox
                                    isChecked={field.value}
                                    label={t('settings.organization.managerCanSeeTeamMemberInsights')}
                                    field={field}
                                    setValue={setValue}
                                />
                            )}
                        />
                        <InnerOptionsBox>
                            <Controller
                                name={'enableManagerCanAssignOutcomesToTeamMembers'}
                                control={control}
                                render={({ field }) => (
                                    <NCheckbox
                                        isChecked={watchEnableManagerCanSeeTeamMembersInsights ? field.value : false}
                                        isDisabled={!watchEnableManagerCanSeeTeamMembersInsights}
                                        label={t('settings.organization.managerCanAssignOutcomesToTeamMembers')}
                                        field={field}
                                        setValue={setValue}
                                    />
                                )}
                            />
                        </InnerOptionsBox>
                    </BasicCard>
                </ManagerWrapper>
                <LeaderboardWrapper>
                    <BasicCard
                        containerId="leaderboard-box"
                        isLoading={isTenantSettingsLoading}
                        title={t('settings.organization.leaderboardSettings')}
                    >
                        <Controller
                            name={'enableLeaderboard'}
                            control={control}
                            render={({ field }) => (
                                <NCheckbox
                                    isChecked={field.value}
                                    label={t('settings.organization.showLeaderboardToAllUsers')}
                                    field={field}
                                    setValue={setValue}
                                />
                            )}
                        />
                    </BasicCard>
                </LeaderboardWrapper>
            </>
        );
    }, [isTenantSettingsLoading, isFetchTenantSettingsError, watchEnableManagerCanSeeTeamMembersInsights]);

    return (
        <StyledForm ref={formRef} onSubmit={handleSubmit(handleUpdateSettings)}>
            <SubmitBox>
                <PrimaryButton
                    disabled={!isDirty || isUpdateActionTriggered}
                    title="Submit"
                    clickHandler={() => {
                        formRef?.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
                    }}
                />
            </SubmitBox>
            <RootBox>
                <RootColumn>{mainSettingsMemo}</RootColumn>
                <RootColumn>
                    <CollectorStatusWrapper>
                        <BasicCard
                            containerId="collector-status-cards-box"
                            isLoading={isFetchTenantStatusLoading}
                            status={tenantStatusOverallMemo}
                            isError={isFetchTenantStatusError}
                            refetch={refetchTenantStatus}
                            title={t('settings.organization.collectorStatus')}
                        >
                            {tenantStatusCardContentMemo}
                        </BasicCard>
                    </CollectorStatusWrapper>
                    <LicenseUtilizationWrapper>
                        <BasicCard
                            containerId="license-utilization-cards-box"
                            isLoading={isFetchTenantLicensesSettingsLoading}
                            isError={isFetchTenantLicensesError}
                            refetch={reetchLicensesSettings}
                            title={t('settings.organization.licenseUtilization')}
                        >
                            {licenseUtilizationCardContentMemo}
                        </BasicCard>
                    </LicenseUtilizationWrapper>
                </RootColumn>
            </RootBox>
        </StyledForm>
    );
};

export default OrganizationalSettingsTab;
