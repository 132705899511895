import { isInTeams } from '../utils/isInTeams';
import { useNavigationStateValue } from '../contexts/NavigationContext';
import styled from '../ui/theme/styled';
import { Outlet } from 'react-router';
import NavigationSidemenu from '../ui/navigation/navigation-sidemenu/NavigationSidemenu';
import PageHeader from '../ui/general/page-header/PageHeader';
import { useTabsStateValue } from '../contexts/TabsContext';
import { ShepherdTour } from 'react-shepherd';
import useWizardConfig from '../hooks/useWizardConfig';

export const SidemenuControlBox = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 56,
    height: 40,
    cursor: 'pointer',
    left: 0,
    top: 0,
    backgroundColor: 'blue',
});

export const RootBox = styled('div', {
    shouldForwardProp: (prop) => prop !== 'applyPadding' && prop !== 'isOpen',
})<{ applyPadding: boolean; isOpen: boolean }>(({ theme, applyPadding, isOpen }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    position: 'relative',
    paddingBottom: applyPadding ? '20px' : 'unset',
    [theme.breakpoints.down('lg')]: {
        width: !isOpen ? '100%' : '100%',
    },
}));

export const OutletWrapper = styled('div', {
    shouldForwardProp: (prop) => prop !== 'isOpen',
})<{ isOpen: boolean }>(({ isOpen }) => ({
    marginLeft: isOpen ? '110px' : '0px',
    width: isOpen ? 'calc(100% - 110px)' : '100%',
    display: 'flex',
    justifyContent: 'center',
}));

export const HeaderWrapper = styled('div', {
    shouldForwardProp: (prop) => prop !== 'isOpen',
})<{ isOpen: boolean }>(({ isOpen }) => ({
    width: isOpen ? 'calc(100% - 110px)' : '100%',
    marginLeft: isOpen ? '110px' : '0px',
}));

const MainLayout = () => {
    const { showNav } = useNavigationStateValue();
    const { changeSearchText, searchText } = useTabsStateValue();
    const isTeams = isInTeams();
    const { config, tourOptions } = useWizardConfig();

    return (
        <>
            {/* @ts-ignore */}
            <ShepherdTour steps={config} tourOptions={tourOptions}>
                <RootBox applyPadding={true} isOpen={showNav && !isTeams}>
                    <NavigationSidemenu />
                    <HeaderWrapper isOpen={showNav && !isTeams}>
                        <PageHeader onSearchInputValueChange={changeSearchText} searchInputValue={searchText} />
                    </HeaderWrapper>
                    <OutletWrapper isOpen={showNav && !isTeams}>
                        <Outlet />
                    </OutletWrapper>
                </RootBox>
            </ShepherdTour>
        </>
    );
};

export default MainLayout;
