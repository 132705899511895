import { FC } from 'react';

const BadgeNeedsAttention: FC = () => {
    return (
        <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 5.55899L9.84252 0L20 5.55899V16.4516L9.84252 21.9355L0 16.4516V5.55899Z" fill="#5A4691" />
            <path
                d="M2.58081 6.83166L9.88332 2.58067L17.4195 6.83166V15.1613L9.88332 19.3549L2.58081 15.1613V6.83166Z"
                stroke="#F0966A"
                strokeWidth="2"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 14C11.6569 14 13 12.6569 13 11C13 9.34315 11.6569 8 10 8C8.34315 8 7 9.34315 7 11C7 12.6569 8.34315 14 10 14Z"
                fill="#ED8000"
            />
        </svg>
    );
};

export default BadgeNeedsAttention;
