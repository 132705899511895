import { addYears, isAfter, isBefore, subWeeks } from 'date-fns';
import { IScoreDot, IScoreLine } from '../interfaces/IScoreLine';
import { compareAsc } from 'date-fns';

const expandDatesToLastYearPerWeek: (scoreLine: IScoreLine) => IScoreLine = (scoreLine) => {
    let lastDate: Date | string | undefined;
    let firstDate: Date | string | undefined;
    scoreLine.scores.forEach((a) => {
        if (!lastDate || isAfter(a.date as Date, lastDate as Date)) {
            lastDate = a.date;
        }
        if (!firstDate || isBefore(a.date as Date, firstDate as Date)) {
            firstDate = a.date;
        }
    });
    if (!lastDate || !firstDate) return scoreLine;
    let counterDate = new Date((lastDate as Date).getTime());
    const newScores: IScoreDot[] = [];

    while (addYears(counterDate as Date, 1) > lastDate) {
        const existingScore = scoreLine.scores.find((d) => {
            return compareAsc(d.date as Date, counterDate) === 0;
        });
        newScores.unshift({
            date: new Date((counterDate as Date).getTime()),
            value: existingScore ? existingScore.value : undefined,
        });
        counterDate = subWeeks(counterDate as Date, 1);
    }
    scoreLine.scores = newScores;
    if (scoreLine.scores) scoreLine.scores = scoreLine?.scores.slice(-52);
    return scoreLine;
};

export default expandDatesToLastYearPerWeek;
