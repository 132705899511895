import { createContext, FC, useContext, PropsWithChildren, useState, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import useDropdown from '../hooks/useDropdown';
import { SelectChangeEvent } from '@mui/material';
import { useGetShowcaseBadgesQuery } from '../services/ShowcaseQueryService';
import { IGetShowcaseBadgeResponseVM } from '../interfaces/views/IGetShowcaseBadgeResponseVM';
import { IShowcaseBadgeCard } from '../interfaces/IShowcaseBadgeCard';
import { EShowcaseBadgeStatus } from '../interfaces/enums/EShowcaseBadgeStatus';
import { EShowcaseBadgeCategory } from '../interfaces/enums/EShowcaseBadgeCategory';
import usePrevNextVisible from '../hooks/usePrevNextVisible';
import { makeBadgesAllByCategoryRoute, makeAchievementsAllByCategoryRoute } from '../pages/routes';
import { useGetAchievementsQuery } from '../services/AchievementQueryService';
import { IAchievementOverviewGroupVM } from '../interfaces/views/IAchievementOverviewGroupVM';
import { EAchievementState } from '../interfaces/enums/EAchievementState';
import { useGetUserRankingsQuery } from '../services/UserQueryService';
import { IUserRankingsVM } from '../interfaces/views/IUserRankingsVM';
import { usePostClaimMasterBadge, usePostClaimProducerBadge } from '../services/BadgeQueryService';
import { IBadge } from '../interfaces/IBadge';
import { usePostDownloadBadge } from '../services/LicenseQueryService';
import { downloadFile } from '../utils/downloadFile';
import { EShowcaseBadgeLevel } from '../interfaces/enums/EShowcaseBadgeLevel';
import { useUserAuthStateValue } from './UserAuthContext';
import { ETenantPreference } from '../interfaces/enums/ETenantPreference';
import { EToastSeverity, useToastContextStateValue } from './ToastContext';

export interface IShowcaseContext {
    showcaseBadgeList?: IGetShowcaseBadgeResponseVM;
    showcaseBadgeCardData: IShowcaseBadgeCard[];
    achievementGroupList?: IAchievementOverviewGroupVM[];
    allBadgesCategory?: EShowcaseBadgeCategory | null;
    allBadgesCategoryData?: IShowcaseBadgeCard | null;
    allAchievementsState?: EAchievementState | null;
    allAchievementsStateData?: IAchievementOverviewGroupVM | null;
    handleAchievementTypeFilterDropdownChange: (event: SelectChangeEvent<unknown>) => void;
    changeAchievementTypeFilterDropdownValue: (option: string) => void;
    selectedAchievementTypeFilterValue?: string;
    visibleBadgesIndex: number;
    changeVisibleBadgesIndex: (index: number) => void;
    changeVisibleBadgesIndexHandler: (next: boolean) => void;
    visibleAchievementsIndex: number;
    changeVisibleAchievementsIndex: (index: number) => void;
    changeVisibleAchievementsIndexHandler: (next: boolean) => void;
    isBadgesDataLoading: boolean;
    isAchievementsDataLoading: boolean;
    isRankingsDataLoading: boolean;
    rankingsData?: IUserRankingsVM[];
    claimBadgeHandler: (outcomeId: string, level: EShowcaseBadgeLevel) => Promise<IBadge | undefined>;
    downloadBadgeHandler: (outcomeId: number, level: string, title?: string) => Promise<void>;
    isDownloadBadgeDataLoading: boolean;
    isPostClaimBadgeLoading: boolean;
    isRankingsDataFetchingError: boolean;
    refetchRankingsCallback: () => void;
    isAchievementsDataFetchingError: boolean;
    isBadgesDataFetchingError: boolean;
    refetchBadgesCallback: () => void;
    refetchAchievementsCallback: () => void;
    enableLeaderboard: boolean;
}

export const ShowcaseContext = createContext<IShowcaseContext>({} as IShowcaseContext);

interface IProps {}

export const ShowcaseProvider: FC<PropsWithChildren<IProps>> = ({ children }) => {
    const {
        handleFilterDropdownChange: handleAchievementTypeFilterDropdownChange,
        value: selectedAchievementTypeFilterValue,
        changeFilterDropdownValue: changeAchievementTypeFilterDropdownValue,
    } = useDropdown({
        initialValue: 'All',
    });
    const { currentUserData } = useUserAuthStateValue();
    const {
        data: showcaseBadgesFetchedData,
        refetch: refetchShowcaseBadgesQuery,
        isLoading: isShowcaseBadgesLoading,
        isError: isBadgesDataFetchingError,
    } = useGetShowcaseBadgesQuery();
    const [showcaseBadgesData, setShowcaseBadgesData] = useState<IShowcaseBadgeCard[]>([]);

    // Data used to track selected achievement group on Achievements Preview page
    const [allAchievementsState, setAllAchievementsState] = useState<EAchievementState | undefined | null>();
    const [allAchievementsStateData, setAllAchievementsStateData] = useState<
        IAchievementOverviewGroupVM | undefined | null
    >();
    // --------------------

    // Data used to track selected badge category on All Badges per category page
    const [allBadgesCategory, setAllBadgesCategory] = useState<EShowcaseBadgeCategory | undefined | null>();
    const [allBadgesCategoryData, setAllBadgesCategoryData] = useState<IShowcaseBadgeCard | undefined | null>();
    // --------------------

    const params = useParams<{ category?: string; showcaseAchievementState?: string }>();
    const {
        changeVisibleIndex: changeVisibleBadgesIndex,
        changeHandler: changeVisibleBadgesIndexHandler,
        visibleIndex: visibleBadgesIndex,
    } = usePrevNextVisible({
        step: 1,
    });
    const {
        changeVisibleIndex: changeVisibleAchievementsIndex,
        changeHandler: changeVisibleAchievementsIndexHandler,
        visibleIndex: visibleAchievementsIndex,
    } = usePrevNextVisible({
        step: 1,
    });
    const navigate = useNavigate();
    const { mutateAsync: mutatePostClaimProducerBadgeAsync, isPending: isPostClaimProducerBadgeLoading } =
        usePostClaimProducerBadge();
    const { mutateAsync: mutatePostClaimMasterBadgeAsync, isPending: isPostClaimMasterBadgeLoading } =
        usePostClaimMasterBadge();
    const { mutateAsync: mutatePostDownloadBadgeAsync, isPending: isDownloadBadgeActive } = usePostDownloadBadge();
    const {
        data: achievementsReviewFetchedData,
        isLoading: isAchievementsLoading,
        refetch: refetchAchievements,
        isError: isAchievementsDataFetchingError,
    } = useGetAchievementsQuery();
    const {
        data: rankingsFetchedData,
        isLoading: isRankingsLoading,
        isError: isRankingsDataFetchingError,
        refetch: refetchUserRankings,
    } = useGetUserRankingsQuery();
    const { setToastMessage } = useToastContextStateValue();
    const { t } = useTranslation();
    const { refetchCurrentUserData } = useUserAuthStateValue();

    useEffect(() => {
        refetchCurrentUserData();
    }, []);

    const enableLeaderboard = useMemo(() => {
        return !!currentUserData?.tenantPreferences.includes(ETenantPreference.ENABLE_LEADERBOARD);
    }, [currentUserData]);

    useEffect(() => {
        if (enableLeaderboard && !rankingsFetchedData) {
            refetchUserRankings();
        }
    }, [enableLeaderboard]);

    useEffect(() => {
        if (!showcaseBadgesFetchedData && !isShowcaseBadgesLoading) {
            setAllBadgesCategory(undefined);
            setAllBadgesCategoryData(undefined);
            setShowcaseBadgesData([]);
            refetchShowcaseBadgesQuery();
        }
        if (params.category) {
            const selectedCategoryEnumValue = params.category as EShowcaseBadgeCategory;
            const showcaseBadgeGroup = showcaseBadgesData.find(
                (badgeGroup) => badgeGroup.badgeCategory === selectedCategoryEnumValue
            );
            if (showcaseBadgeGroup) {
                const selectedCategoryEnumIndex = Object.values(EShowcaseBadgeCategory).findIndex(
                    (enumValue) => enumValue === params.category
                );
                changeVisibleBadgesIndex(selectedCategoryEnumIndex);
                setAllBadgesCategoryData(showcaseBadgeGroup);
                setAllBadgesCategory(params.category as EShowcaseBadgeCategory);
            } else {
                changeVisibleBadgesIndex(1);
                setAllBadgesCategoryData(null);
                setAllBadgesCategory(null);
            }
        } else if (params.showcaseAchievementState && (showcaseBadgesFetchedData || !isAchievementsLoading)) {
            const selectedGroupValue = params.showcaseAchievementState;
            const showcaseAchievementState = achievementsReviewFetchedData?.find((achievementGroup) => {
                if (selectedGroupValue === 'not-started')
                    return achievementGroup.state === EAchievementState.NOT_STARTED;
                if (selectedGroupValue === 'awarded') return achievementGroup.state === EAchievementState.AWARDED;
                if (selectedGroupValue === 'in-progress')
                    return achievementGroup.state === EAchievementState.IN_PROGRESS;
                return false;
            });
            if (showcaseAchievementState) {
                const selectedStateEnumIndex = Object.values(EAchievementState)
                    .filter((state) => {
                        return achievementsReviewFetchedData?.find((achievement) => achievement.state === state);
                    })
                    .findIndex((enumValue) => {
                        if (selectedGroupValue === 'not-started') return enumValue === EAchievementState.NOT_STARTED;
                        if (selectedGroupValue === 'awarded') return enumValue === EAchievementState.AWARDED;
                        if (selectedGroupValue === 'in-progress') return enumValue === EAchievementState.IN_PROGRESS;
                        return false;
                    });
                if (selectedStateEnumIndex !== -1) {
                    changeVisibleAchievementsIndex(selectedStateEnumIndex);
                    setAllAchievementsStateData(showcaseAchievementState);
                    setAllAchievementsState(params.showcaseAchievementState as EAchievementState);
                } else {
                    changeVisibleAchievementsIndex(1);
                    setAllAchievementsStateData(null);
                    setAllAchievementsState(null);
                }
            } else {
                changeVisibleAchievementsIndex(1);
                setAllAchievementsStateData(null);
                setAllAchievementsState(null);
            }
        } else {
            setAllBadgesCategory(undefined);
            setAllBadgesCategoryData(undefined);
            setAllAchievementsStateData(undefined);
            setAllAchievementsState(undefined);
        }
    }, [params, showcaseBadgesFetchedData, showcaseBadgesData, isAchievementsLoading]);

    useEffect(() => {
        if (allBadgesCategoryData) {
            const selectedCategoryEnumValue = Object.values(EShowcaseBadgeCategory)[
                visibleBadgesIndex
            ] as EShowcaseBadgeCategory;
            const showcaseBadgeGroup = showcaseBadgesData.find(
                (badgeGroup) => badgeGroup.badgeCategory === selectedCategoryEnumValue
            );
            if (showcaseBadgeGroup) {
                navigate(makeBadgesAllByCategoryRoute(selectedCategoryEnumValue), { replace: true });
                setAllBadgesCategory(selectedCategoryEnumValue);
                setAllBadgesCategoryData(showcaseBadgeGroup);
            }
        }
    }, [visibleBadgesIndex]);

    useEffect(() => {
        if (allAchievementsStateData) {
            const selectedStateEnumValue = Object.values(EAchievementState).filter((state) => {
                return achievementsReviewFetchedData?.find((group) => group.state === state);
            })[visibleAchievementsIndex] as EAchievementState;
            const showcaseAchievementGroup = achievementsReviewFetchedData
                ?.filter((achievementGroup) => {
                    return achievementsReviewFetchedData?.find(
                        (achievement) => achievement.state === achievementGroup.state
                    );
                })
                .find((achievementGroup) => achievementGroup.state === selectedStateEnumValue);
            if (showcaseAchievementGroup) {
                setAllAchievementsState(selectedStateEnumValue);
                setAllAchievementsStateData(showcaseAchievementGroup);
                navigate(makeAchievementsAllByCategoryRoute(selectedStateEnumValue), { replace: true });
            }
        }
    }, [visibleAchievementsIndex, achievementsReviewFetchedData]);

    const claimBadgeHandler = async (outcomeId: string, level: EShowcaseBadgeLevel) => {
        if (level === EShowcaseBadgeLevel.PRODUCER) {
            try {
                const responseBadge = await mutatePostClaimProducerBadgeAsync({
                    outcomeId,
                });
                setToastMessage({
                    isOpen: true,
                    message: t('success.badge.successProducerBadgeClaim'),
                    severity: EToastSeverity.SUCCESS,
                });
                refetchShowcaseBadgesQuery();
                return responseBadge;
            } catch (err) {
                setToastMessage({
                    isOpen: true,
                    message: t('errors.badge.errorProducerBadgeClaim'),
                    severity: EToastSeverity.ERROR,
                });
            }
        } else if (level === EShowcaseBadgeLevel.MASTER) {
            try {
                const responseBadge = await mutatePostClaimMasterBadgeAsync({
                    outcomeId,
                });
                setToastMessage({
                    isOpen: true,
                    message: t('success.badge.successMasterBadgeClaim'),
                    severity: EToastSeverity.SUCCESS,
                });
                refetchShowcaseBadgesQuery();
                return responseBadge;
            } catch (err) {
                setToastMessage({
                    isOpen: true,
                    message: t('errors.badge.errorMasterBadgeClaim'),
                    severity: EToastSeverity.ERROR,
                });
            }
        }
        return undefined;
    };

    const downloadBadgeHandler = async (outcomeId: number, level: string, title?: string) => {
        if (outcomeId && level && !isDownloadBadgeActive) {
            const { url } = await mutatePostDownloadBadgeAsync({ outcomeId, level });
            if (url) {
                const fileName = `${title} Badge`;
                await downloadFile(url, `${fileName}.png`);
            }
        }
    };

    useEffect(() => {
        if (!showcaseBadgesFetchedData) {
            setShowcaseBadgesData([]);
        }
        setShowcaseBadgesData([
            {
                title: t('showcase.home.claimableBadges'),
                badgeList: showcaseBadgesFetchedData?.claimableBadges,
                state: EShowcaseBadgeStatus.CLAIMABLE,
                badgeCategory: EShowcaseBadgeCategory.CLAIMABLE,
            },
            {
                title: t('showcase.home.claimedBadges'),
                badgeList: showcaseBadgesFetchedData?.claimedBadges,
                state: EShowcaseBadgeStatus.CLAIMED,
                badgeCategory: EShowcaseBadgeCategory.CLAIMED,
            },
            {
                title: t('showcase.home.inProgressBadges'),
                badgeList: showcaseBadgesFetchedData?.inProgressBadges,
                state: EShowcaseBadgeStatus.IN_PROGRESS,
                badgeCategory: EShowcaseBadgeCategory.IN_PROGRESS,
            },
            {
                title: t('showcase.home.needAttentionBadges'),
                badgeList: showcaseBadgesFetchedData?.needsAttentionBadges,
                state: EShowcaseBadgeStatus.NEED_ATTENTION,
                badgeCategory: EShowcaseBadgeCategory.NEEDS_ATENTION,
            },
            {
                title: t('showcase.home.notSharedBadges'),
                badgeList: showcaseBadgesFetchedData?.notSharedBadges,
                state: EShowcaseBadgeStatus.NOT_SHARED,
                badgeCategory: EShowcaseBadgeCategory.NOT_SHARED,
            },
        ]);
    }, [showcaseBadgesFetchedData]);

    const showcaseContext: IShowcaseContext = {
        showcaseBadgeList: showcaseBadgesFetchedData,
        showcaseBadgeCardData: showcaseBadgesData,
        achievementGroupList: achievementsReviewFetchedData,
        allBadgesCategory,
        allBadgesCategoryData,
        allAchievementsState,
        allAchievementsStateData,
        selectedAchievementTypeFilterValue,
        handleAchievementTypeFilterDropdownChange,
        changeAchievementTypeFilterDropdownValue,
        visibleBadgesIndex,
        changeVisibleBadgesIndex,
        changeVisibleBadgesIndexHandler,
        visibleAchievementsIndex,
        changeVisibleAchievementsIndex,
        changeVisibleAchievementsIndexHandler,
        isBadgesDataLoading: isShowcaseBadgesLoading,
        isAchievementsDataLoading: isAchievementsLoading,
        isRankingsDataLoading: isRankingsLoading,
        rankingsData: rankingsFetchedData,
        downloadBadgeHandler,
        claimBadgeHandler,
        isDownloadBadgeDataLoading: isDownloadBadgeActive,
        isPostClaimBadgeLoading: isPostClaimMasterBadgeLoading || isPostClaimProducerBadgeLoading,
        isRankingsDataFetchingError: isRankingsDataFetchingError,
        isAchievementsDataFetchingError,
        isBadgesDataFetchingError,
        refetchRankingsCallback: refetchUserRankings,
        refetchBadgesCallback: refetchShowcaseBadgesQuery,
        refetchAchievementsCallback: refetchAchievements,
        enableLeaderboard,
    };

    return <ShowcaseContext.Provider value={showcaseContext}>{children}</ShowcaseContext.Provider>;
};

export const useShowcaseStateValue: () => IShowcaseContext = () => useContext(ShowcaseContext);
