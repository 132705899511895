import { FC } from 'react';
import { IOutcomeSkill } from '../../../interfaces/IOutcomeSkill';
import Loading from '../loading/Loading';
import { ESkillLevel } from '../../../interfaces/enums/ESkillLevel';
import { progressDoughnutChartType } from '../../../utils/progressDoughnutChartType';
import { useOutcomeStateValue } from '../../../contexts/OutcomeContext';
import { RootBox } from './Style';
import OutcomeSkillCard from '../../../ui/cards/outcome-skill-card/OutcomeSkillCard';

interface IProps {
    skills?: IOutcomeSkill[];
    skillClickHandler: (skillId: string) => void;
    isLoading: boolean;
}

const SkillList: FC<IProps> = ({ skills, skillClickHandler, isLoading }) => {
    const { toggleFavoriteSkill } = useOutcomeStateValue();
    return (
        <RootBox className="skill-card-list">
            {isLoading ? (
                <Loading />
            ) : (
                skills?.map((skill, i) => {
                    return (
                        <OutcomeSkillCard
                            key={i}
                            index={i}
                            id={skill.id}
                            name={skill.title}
                            isAssigned={skill.assignment.level !== ESkillLevel.NONE}
                            appIcons={skill.apps}
                            skillClickHandler={skillClickHandler}
                            isUserFocus={!!skill.isUserFavorite}
                            isMasterLevelRequested={!!skill.isCompanyFocus}
                            description={skill.description}
                            assignment={skill.assignment}
                            progressDoughnutChartType={progressDoughnutChartType(skill.assignment, skill.skillType)}
                            level={skill.assignment.level}
                            score={skill.score}
                            skillType={skill.skillType}
                            isUserFavoriteControlDisabled={skill.assignment.level === ESkillLevel.NONE}
                            toggleFavoriteCallback={toggleFavoriteSkill}
                            maxLevel={skill.maxLevel}
                        />
                    );
                })
            )}
        </RootBox>
    );
};

export default SkillList;
