import { FC } from 'react';

const MasterLevelRequired: FC = () => {
    return (
        <svg width="29" height="30" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.58081 5.83168L8.88332 1.58069L16.4195 5.83168V14.1613L8.88332 18.3549L1.58081 14.1613V5.83168Z"
                stroke="#349FEE"
                strokeWidth="2"
            />
            <path d="M9 6V10" stroke="#349FEE" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9 14H9.01" stroke="#349FEE" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export default MasterLevelRequired;
