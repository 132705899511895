import { FC } from 'react';

interface IProps {
    color?: string;
}

const RefreshIcon: FC<IProps> = ({ color }) => {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_3450_22157)">
                <path
                    d="M13.4167 2.33334V5.83334H9.91669"
                    stroke={color || '#654EA3'}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M0.583313 11.6667V8.16666H4.08331"
                    stroke={color || '#654EA3'}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M2.04748 5.24999C2.34333 4.41395 2.84614 3.66648 3.509 3.07732C4.17186 2.48816 4.97317 2.07652 5.83815 1.88081C6.70313 1.6851 7.60359 1.71169 8.45551 1.95811C9.30743 2.20453 10.083 2.66274 10.71 3.28999L13.4166 5.83333M0.583313 8.16666L3.28998 10.71C3.91692 11.3372 4.69253 11.7955 5.54445 12.0419C6.39637 12.2883 7.29683 12.3149 8.16181 12.1192C9.02679 11.9235 9.8281 11.5118 10.491 10.9227C11.1538 10.3335 11.6566 9.58604 11.9525 8.74999"
                    stroke={color || '#654EA3'}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_3450_22157">
                    <rect width="14" height="14" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default RefreshIcon;
