import { FC, useMemo, useRef, useState, KeyboardEvent, useCallback } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { parse } from 'date-fns';
import { TableWrapper, TableFiltersWrapper } from './Style';
import GraphCard from '../../../components/graph/graph-card/GraphCard';
import Table from '../../../../../ui/general/table/Table';
import { EGraphCardSelect } from '../../../../../interfaces/enums/EGraphCardSelect';
import { useInsightsOrganizationEngagementStateValue } from '../../../../../contexts/InsightsOrganizationEngagementContext';
import { ITableHeadCell } from '../../../../../interfaces/ITableHeaderCell';
import {
    FiltersWrapper,
    GraphCardToolbarFormControl,
    GraphCardToolbarFormControlLabel,
    GraphCardToolbarRadio,
    GraphCardToolbarRadioGroup,
    GraphCardToolbarValueBox,
    RootBox,
    StyledSearchInput,
    SearchInputWrapper,
} from '../../../../../pages/insights/Style';
import CheckboxUncheckedIcon from '../../../../../assets/icons/CheckboxUncheckedIcon';
import CheckboxCheckedIcon from '../../../../../assets/icons/CheckboxCheckedIcon';
import { IInsightsOrganizationEngagementVM } from '../../../../../interfaces/views/IInsightsOrganizationEngagementVM';
import { FiltersProvider } from '../../../../../contexts/FiltersContext';
import Filters from '../../../../../ui/filters/filters/Filters';
import useInsightsTimelineOption from '../../../../../hooks/useInsightsTimelineOption';
import { makeInsightsOrganizationalUsersEngagement } from '../../../../../pages/routes';
import { EInsightsMode } from '../../../../../interfaces/enums/EInsightsMode';
import { useInsightsStateValue } from '../../../../../contexts/InsightsContext';
import { useCrumbsStateValue } from '../../../../../contexts/CrumbsContext';
import BarChart from '../../../../../ui/general/bar-chart/BarChart';
import { formatDate } from '../../../../../utils/dateUtil';
import { EAxisType } from '../../../../../interfaces/enums/EAxisType';

interface ILoginTableHeader {
    image: string;
    displayName: string;
    department: string;
    region: string;
    loginCount: number;
    lastLogin: string;
}

interface IEngagementTableHeader {
    image: string;
    displayName: string;
    department: string;
    region: string;
    pointsThisMonth: number;
    pointsLastMonth: number;
    pointsTotal: number;
}

enum EGraphOptionValue {
    LOGINS = 'Logins',
    ENGAGEMENT_POINTS = 'Engagementpoints',
}

interface EGraphOption {
    value: EGraphOptionValue;
    label: string;
}

const OrganizationalEngagementTab: FC = () => {
    const { t } = useTranslation();
    const {
        latestDateData,
        searchText,
        setSearchText,
        onFilterValueChange,
        filterCategories,
        isEngagementDataLoading,
        engagementGraphData,
        loggedInGraphData,
        changeScoreLinesInterval,
    } = useInsightsOrganizationEngagementStateValue();
    const { handleTimelineChange, activeTimelineOption } = useInsightsTimelineOption({
        changedTimelineOptionCallback: changeScoreLinesInterval,
        initialTimelineOptionValue: EGraphCardSelect.MONTH_4,
    });
    const [selectedGraphOption, setSelectedGraphOption] = useState<EGraphOptionValue>(
        EGraphOptionValue.ENGAGEMENT_POINTS
    );
    const graphOptionsRef = useRef<EGraphOption[]>([
        { value: EGraphOptionValue.ENGAGEMENT_POINTS, label: 'Engagement Points' },
        { value: EGraphOptionValue.LOGINS, label: 'Times Logged In' },
    ]);
    const theme = useTheme();
    const isLgDown = useMediaQuery(theme.breakpoints.down('lg'));
    const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate();
    const { setUserName, changeMode } = useInsightsStateValue();
    const { appendCrumb } = useCrumbsStateValue();

    const loginHeadCells: ITableHeadCell<ILoginTableHeader>[] = [
        {
            id: 'image',
            label: '',
            disableSort: true,
        },
        {
            id: 'displayName',
            label: 'Display Name',
            minWidth: '130px',
        },
        {
            id: 'department',
            label: 'Department',
        },
        {
            id: 'region',
            label: 'Region',
            align: 'center',
        },
        {
            id: 'loginCount',
            label: '# of Logins',
            align: 'center',
            minWidth: '110px',
        },
        {
            id: 'lastLogin',
            label: 'Last Login',
            minWidth: '120px',
            customRender: (value: string, obj) => {
                let date;
                if (value && value !== '') {
                    date = parse(value, 'M/dd/yyyy', new Date());
                    return formatDate(date);
                }
                return '';
            },
        },
    ];

    const headerOverviewRef = useRef([
        {
            colSpan: 4,
            label: '',
        },
        {
            colSpan: 3,
            label: 'Points',
        },
    ]);
    const engagementTableHeaders: ITableHeadCell<IEngagementTableHeader>[] = [
        {
            id: 'image',
            label: '',
            disableSort: true,
        },
        {
            id: 'displayName',
            label: 'Display Name',
            minWidth: '130px',
        },
        {
            id: 'department',
            label: 'Department',
        },
        {
            id: 'region',
            label: 'Region',
            align: 'center',
        },
        {
            id: 'pointsThisMonth',
            label: 'This Month',
            align: 'center',
            minWidth: '110px',
        },
        {
            id: 'pointsLastMonth',
            label: 'Last Month',
            align: 'center',
            minWidth: '110px',
        },
        {
            id: 'pointsTotal',
            label: 'Total',
            align: 'center',
        },
    ];

    const onTableRowClick = useCallback((_: any, row: IInsightsOrganizationEngagementVM) => {
        setUserName(row.displayName);
        const route = makeInsightsOrganizationalUsersEngagement(row.userId);
        changeMode(EInsightsMode.ORG_PERSONAL);
        appendCrumb({
            name: '',
            pathname: route,
            callback: () => {
                changeMode(EInsightsMode.STANDARD);
            },
        });
        navigate(route);
    }, []);

    const tableMemo = useMemo(() => {
        switch (selectedGraphOption) {
            case EGraphOptionValue.ENGAGEMENT_POINTS:
                return (
                    <Table<IInsightsOrganizationEngagementVM, IEngagementTableHeader>
                        key={EGraphOptionValue.ENGAGEMENT_POINTS}
                        headCells={engagementTableHeaders}
                        data={latestDateData}
                        propertyKeys={engagementTableHeaders.map((headCell) => {
                            return headCell.id;
                        })}
                        tableTitlePlural=""
                        isFilterControlVisible={false}
                        isFilterDrawerOpen={false}
                        isLoading={isEngagementDataLoading}
                        isImageColumnPresent
                        imageColumnName="userId"
                        onRowClick={onTableRowClick}
                        initialOrderBy="displayName"
                        userImageInfoColumn="displayName"
                        userImageInfoColumnPosition={isMdDown ? 'bottom' : 'right'}
                        headerOverviewConfig={headerOverviewRef.current}
                    />
                );
            case EGraphOptionValue.LOGINS:
                return (
                    <Table<IInsightsOrganizationEngagementVM, ILoginTableHeader>
                        key={EGraphOptionValue.LOGINS}
                        headCells={loginHeadCells}
                        data={latestDateData}
                        propertyKeys={loginHeadCells.map((headCell) => {
                            return headCell.id;
                        })}
                        tableTitlePlural=""
                        isFilterControlVisible={false}
                        isFilterDrawerOpen={false}
                        isLoading={isEngagementDataLoading}
                        isImageColumnPresent
                        imageColumnName="userId"
                        onRowClick={onTableRowClick}
                        initialOrderBy="displayName"
                        userImageInfoColumn="displayName"
                        userImageInfoColumnPosition={isMdDown ? 'bottom' : 'right'}
                    />
                );
            default:
                return <></>;
        }
    }, [selectedGraphOption, latestDateData, isEngagementDataLoading]);

    const onRadioOptionKeyDown = (e: KeyboardEvent<HTMLButtonElement>) => {
        if (e.key === 'Enter') {
            handleRadioButtonChange(e as unknown as React.ChangeEvent<HTMLInputElement>);
        }
    };

    const handleRadioButtonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = (event.target as HTMLInputElement).value;
        setSelectedGraphOption(value as EGraphOptionValue);
    };

    const customGraphCardToolbar = useMemo(() => {
        return (
            <GraphCardToolbarFormControl id="tab-mode">
                <GraphCardToolbarRadioGroup value={selectedGraphOption} name={`controlled-radio-buttons-group`}>
                    {graphOptionsRef.current.map((option) => {
                        return (
                            <GraphCardToolbarValueBox key={option.value} variant="body2">
                                <GraphCardToolbarFormControlLabel
                                    value={option.value}
                                    control={
                                        <GraphCardToolbarRadio
                                            onChange={handleRadioButtonChange}
                                            icon={<CheckboxUncheckedIcon />}
                                            checkedIcon={<CheckboxCheckedIcon />}
                                            onKeyDown={onRadioOptionKeyDown}
                                            tabIndex={0}
                                            checked={selectedGraphOption === option.value}
                                        />
                                    }
                                    label={option.label}
                                    color="primary"
                                />
                            </GraphCardToolbarValueBox>
                        );
                    })}
                </GraphCardToolbarRadioGroup>
            </GraphCardToolbarFormControl>
        );
    }, [selectedGraphOption]);

    const filtersMemo = useMemo(() => {
        return (
            <Filters
                isDisabled={false}
                handleFilterValueChange={onFilterValueChange}
                categories={filterCategories}
                isQuickFiltersSelected={false}
                dropdownMode
            />
        );
    }, [onFilterValueChange, filterCategories]);

    const createChildren = (dimension: any, isDataEmpty: boolean, isLoading: boolean, isNavVisible: boolean) => {
        const data =
            selectedGraphOption === EGraphOptionValue.ENGAGEMENT_POINTS ? engagementGraphData : loggedInGraphData;
        return (
            <BarChart
                dimension={dimension}
                chartId="bar-chart"
                data={data}
                xAxisType={
                    activeTimelineOption === EGraphCardSelect.YEAR_WITH_WEEKS
                        ? EAxisType.MONTHLY_WITH_WEEKS
                        : EAxisType.LINEAR
                }
                isLoading={isLoading}
                isDataEmpty={!isLoading && data?.length === 0}
            />
        );
    };

    return (
        <RootBox>
            <FiltersProvider categories={filterCategories}>
                <GraphCard
                    customToolbar={customGraphCardToolbar}
                    handleTimelineChange={handleTimelineChange}
                    translations={{ graphCardTitle: t('insights.personal.engagement.graphCardTitle') }}
                    isLoading={isEngagementDataLoading}
                >
                    {createChildren}
                </GraphCard>
                <SearchInputWrapper className="search-box">
                    <StyledSearchInput
                        placeholder="Search Users"
                        value={searchText}
                        onChange={(e) => {
                            setSearchText(e.target.value);
                        }}
                        selectProps={{ disabled: isEngagementDataLoading }}
                    ></StyledSearchInput>
                </SearchInputWrapper>
                <TableFiltersWrapper>
                    <TableWrapper>{tableMemo}</TableWrapper>
                    {!isLgDown && <FiltersWrapper>{filtersMemo}</FiltersWrapper>}
                </TableFiltersWrapper>
            </FiltersProvider>
        </RootBox>
    );
};

export default OrganizationalEngagementTab;
