import { ETenantHistoryType } from '../interfaces/enums/ETenantHistoryType';

export const convertHistoryTypeToStringValues: (historyItemType: ETenantHistoryType) => string = (historyItemType) => {
    switch (historyItemType) {
        case ETenantHistoryType.LICENSE_ASSIGN:
            return 'Assign License';
        case ETenantHistoryType.LICENSE_UNASSIGN:
            return 'Unassign License';
        case ETenantHistoryType.OUTCOME_ASSIGN:
            return 'Assign Outcome';
        case ETenantHistoryType.OUTCOME_UNASSIGN:
            return 'Unassign Outcome';
        case ETenantHistoryType.ROLE_ASSIGN:
            return 'Assign Role';
        case ETenantHistoryType.ROLE_UNASSIGN:
            return 'Unassign Role';
        case ETenantHistoryType.SETTING_OFF:
            return 'Turn On Setting';
        case ETenantHistoryType.SETTING_ON:
            return 'Turn Off Setting';
        case ETenantHistoryType.OUTCOME_FOCUS_ON:
            return 'Turn On Outcome Focus';
        case ETenantHistoryType.OUTCOME_FOCUS_OFF:
            return 'Turn Off Outcome Focus';
        case ETenantHistoryType.ROLE_UNASSIGN_ALL:
            return 'Unassign All Roles';

        default:
            return '';
    }
};
