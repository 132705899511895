import { FC, useState, useMemo } from 'react';
import { RootInnerBox, StyledMain, AssignmentWrapper, AssignBox, UnassignBox, ValueSpan } from './Style';
import PrimaryButton from '../../../../../ui/buttons/primary-button/PrimaryButton';
import { useAdminOutcomesStateValue } from '../../../../../contexts/AdminOutcomesContext';
import { useNavigate } from 'react-router';
import { routes } from '../../../../../pages/routes';

const Assign: FC = () => {
    const {
        assignOutcomesCallback,
        unassignOutcomesCallback,
        selectedOutcomesList,
        selectedUsersList,
        isPostBulkAssignOutcomeSuccess,
        isPostBulkAssignOutcomeLoading,
        setSuccessAssignAction,
    } = useAdminOutcomesStateValue();

    const navigate = useNavigate();
    const [isAssignActionType, setAssignActionType] = useState<boolean | undefined>(undefined);

    const showProgressCallback = () => {
        navigate(routes.HISTORY_OUTCOMES);
    };

    const assignOutcomes = async () => {
        setAssignActionType(true);
        if (!(await assignOutcomesCallback())) {
            setAssignActionType(undefined);
            setSuccessAssignAction(false);
        } else {
            setSuccessAssignAction(true);
        }
    };

    const unassignOutcomes = async () => {
        setAssignActionType(false);
        if (!(await unassignOutcomesCallback())) {
            setAssignActionType(undefined);
            setSuccessAssignAction(false);
        } else {
            setSuccessAssignAction(true);
        }
    };

    const assignActionButtonMemo = useMemo(() => {
        if (isPostBulkAssignOutcomeSuccess && isAssignActionType) {
            return <PrimaryButton title="See Progress" clickHandler={showProgressCallback} />;
        } else if (isAssignActionType !== false) {
            return (
                <PrimaryButton title="Assign" clickHandler={assignOutcomes} disabled={isPostBulkAssignOutcomeLoading} />
            );
        }
        return <></>;
    }, [isPostBulkAssignOutcomeSuccess, isPostBulkAssignOutcomeLoading, isAssignActionType]);

    const unassignActionButtonMemo = useMemo(() => {
        if (isPostBulkAssignOutcomeSuccess && isAssignActionType === false) {
            return <PrimaryButton title="See Progress" clickHandler={showProgressCallback} />;
        } else if (isAssignActionType !== true) {
            return (
                <PrimaryButton
                    title="Unassign"
                    clickHandler={unassignOutcomes}
                    disabled={isPostBulkAssignOutcomeLoading}
                />
            );
        }
        return <></>;
    }, [isPostBulkAssignOutcomeSuccess, isPostBulkAssignOutcomeLoading, isAssignActionType]);

    return (
        <StyledMain>
            <RootInnerBox>
                <AssignmentWrapper>
                    <AssignBox>
                        <span>
                            <ValueSpan>{selectedOutcomesList.length}</ValueSpan>Outcomes will be assigned to
                            <ValueSpan>{selectedUsersList.length}</ValueSpan>Users
                        </span>
                        {assignActionButtonMemo}
                    </AssignBox>
                    <UnassignBox>
                        <span>
                            <ValueSpan>{selectedOutcomesList.length}</ValueSpan>Outcomes will be unassigned from
                            <ValueSpan>{selectedUsersList.length}</ValueSpan>Users
                        </span>
                        {unassignActionButtonMemo}
                    </UnassignBox>
                </AssignmentWrapper>
            </RootInnerBox>
        </StyledMain>
    );
};

export default Assign;
