import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const RootBox = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
});

export const RootInnerBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    width: '1080px',
    borderRadius: theme.borderRadius?.outside?.small,
    [theme.breakpoints.down('lg')]: {
        width: '100%',
        padding: '0 20px',
    },
}));

export const ContentWrapper = styled('div')(({ theme }) => ({
    padding: '32px 46px',
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.borderRadius?.outside?.small,
}));

export const HeaderBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '40px',
    backgroundColor: theme.palette.common.white,
}));

export const ContentBox = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
}));

export const TitleTypography = styled(Typography)({});

export const DescriptionTypography = styled(Typography)({
    marginBottom: '30px',
});

export const AchievementImg = styled('img', {
    shouldForwardProp: (prop) => prop !== 'isOpacityPresent',
})<{ isOpacityPresent: boolean }>(({ isOpacityPresent }) => ({
    width: '100px',
    height: '100px',
    opacity: isOpacityPresent ? 0.3 : 1,
}));

export const ProgressTypography = styled(Typography, {
    shouldForwardProp: (prop) => prop !== 'isOpacityPresent',
})<{ isOpacityPresent: boolean }>(({ theme, isOpacityPresent }) => ({
    color: theme.palette.primary.main,
    paddingLeft: '14px',
    opacity: isOpacityPresent ? 0.3 : 1,
    fontWeight: 500,
    fontFamily: 'Ubuntu',
    [theme.breakpoints.down('lg')]: {
        fontSize: '12px',
        paddingLeft: 0,
    },
}));

export const EarnedDateTypography = styled(Typography, {
    shouldForwardProp: (prop) => prop !== 'isOpacityPresent',
})<{ isOpacityPresent: boolean }>(({ theme, isOpacityPresent }) => ({
    opacity: isOpacityPresent ? 0.3 : 1,
    [theme.breakpoints.down('lg')]: {
        fontSize: '12px',
        paddingLeft: 0,
    },
}));

export const EngagementPointsTypography = styled(Typography, {
    shouldForwardProp: (prop) => prop !== 'isOpacityPresent',
})<{ isOpacityPresent: boolean }>(({ theme, isOpacityPresent }) => ({
    paddingLeft: '14px',
    opacity: isOpacityPresent ? 0.3 : 1,
    [theme.breakpoints.down('lg')]: {
        fontSize: '12px',
        paddingLeft: 0,
    },
}));
