import { styled } from '@mui/system';
import { Button } from '@material-ui/core';

export const NavigationLinkItemContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
    },
}));

export const Selector = styled('div')(({ theme }) => ({
    borderRadius: 4,
    backgroundColor: theme.palette.primary.main,
    position: 'absolute',
    width: 4,
    height: 56,
    left: 106,
    zIndex: 999999,
    [theme.breakpoints.down('sm')]: {
        bottom: '-50px',
        left: 0,
        width: 100,
        height: 4,
        position: 'relative',
    },
}));

export const StyledButton = styled(Button)(({ theme }) => ({
    width: '100%',
    height: '100%',
    minHeight: 62,
    borderRadius: 4,
    fontSize: 14,
    letterSpacing: 1.25,
    fontWeight: 600,
    fontFamily: 'Ubuntu',
    lineHeight: '16px',
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    backgroundColor: 'transparent',
    boxShadow: 'none',
    cursor: 'pointer',
    border: 0,
    outline: 0,
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
        color: theme.palette.text.primary,
    },
    '&:focus': {
        backgroundColor: 'rgba(101,78,163,0.08)',
    },
    '&:active': {
        color: theme.palette.primary.main,
    },
    [theme.breakpoints.down('sm')]: {
        minHeight: '54px',
    },
}));

export const TitleSpan = styled('span')({
    fontFamily: 'Ubuntu',
    fontWeight: 'bold',
    lineHeight: '20px',
    fontSize: 10,
    letterSpacing: '1.25px',
    textTransform: 'uppercase',
});

export const StyledButtonSpan = styled('span')({
    width: '100%',
    display: 'inherit',
    alignItems: 'inherit',
    justifyContent: 'inherit',
});

export const NavigationItemContent = styled('div', {
    shouldForwardProp: (prop) => prop !== 'isSelected',
})<{ isSelected: boolean }>(({ isSelected, theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    color: isSelected ? theme.palette.primary.main : theme.palette.text.secondary,
    [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexDirection: 'row',
        gap: '12px',
    },
}));
