import { ChangeEvent, FC, useMemo } from 'react';
import { useTheme } from '@mui/system';
import { format } from 'date-fns';
import { DisplayNameTypography, StatusCircle, StatusCircleWrapper } from './Style';
import GraphCard from '../../../../components/graph/graph-card/GraphCard';
import Table from '../../../../../../ui/general/table/Table';
import { EGraphCardSelect } from '../../../../../../interfaces/enums/EGraphCardSelect';
import { ITableHeadCell } from '../../../../../../interfaces/ITableHeaderCell';
import {
    StyledSearchInput,
    SearchInputWrapper as MainSearchInputWrapper,
} from '../../../../../../pages/insights/Style';
import useInsightsTimelineOption from '../../../../../../hooks/useInsightsTimelineOption';
import { useInsightsOrganizationalSkillStateValue } from '../../../../../../contexts/InsightsOrganizationalSkillContext';
import { RootBox } from '../Style';
import { IInsightsOrganizationSkillVM } from '../../../../../../interfaces/IInsightsOrganizationSkillVM';
import { useInsightsStateValue } from '../../../../../../contexts/InsightsContext';

interface ITableHeader {
    image: string;
    userName: string;
    department: string;
    region: string;
    userAchieved: boolean;
    producerAchieved: boolean;
    masterAchieved: boolean;
    skillScore: number;
}

const OrganizationalSkillDetailsTab: FC = () => {
    const { scoreLines, searchText, setSearchText, changeScoreLinesInterval, isSkillDataLoading, latestDateData } =
        useInsightsOrganizationalSkillStateValue();
    const { activeTimelineOption, handleTimelineChange } = useInsightsTimelineOption({
        changedTimelineOptionCallback: changeScoreLinesInterval,
        initialTimelineOptionValue: EGraphCardSelect.MONTH_4,
    });
    const { selectedSkillName } = useInsightsStateValue();
    const theme = useTheme();

    const headCells: ITableHeadCell<ITableHeader>[] = [
        {
            id: 'image',
            label: '',
            disableSort: true,
        },
        {
            id: 'userName',
            label: 'Display Name',
            minWidth: '130px',
            customRender: (value: string) => {
                return <DisplayNameTypography variant="subtitle2">{value}</DisplayNameTypography>;
            },
        },
        {
            id: 'department',
            label: 'Department',
        },
        {
            id: 'region',
            label: 'Region',
            align: 'center',
        },
        {
            id: 'userAchieved',
            label: 'User Achieved',
            disablePadding: true,
            align: 'center',
            customRender: (value: number) => {
                if (!value) return <></>;
                return (
                    <StatusCircleWrapper>
                        <StatusCircle color={theme.palette.levels.master} />
                    </StatusCircleWrapper>
                );
            },
        },
        {
            id: 'producerAchieved',
            label: 'Producer Achieved',
            disablePadding: true,
            align: 'center',
            customRender: (value: number) => {
                if (!value) return <></>;
                return (
                    <StatusCircleWrapper>
                        <StatusCircle color={theme.palette.status.attained} />
                    </StatusCircleWrapper>
                );
            },
        },
        {
            id: 'masterAchieved',
            label: 'Master Achieved',
            disablePadding: true,
            align: 'center',
            customRender: (value: number) => {
                if (!value) return <></>;
                return (
                    <StatusCircleWrapper>
                        <StatusCircle color={theme.palette.status.attained} />
                    </StatusCircleWrapper>
                );
            },
        },
        {
            id: 'skillScore',
            label: 'Score',
        },
    ];

    const scoreLinesValuesMemo = useMemo(() => {
        if (isSkillDataLoading || !scoreLines) return undefined;
        if (activeTimelineOption === EGraphCardSelect.MONTH_4) {
            return scoreLines?.map((scoreLine) => {
                return {
                    ...scoreLine,
                    scores: scoreLine.scores.slice(-15),
                };
            });
        } else if (activeTimelineOption === EGraphCardSelect.YEAR_WITH_WEEKS) {
            return scoreLines;
        }
        return [];
    }, [scoreLines, activeTimelineOption, isSkillDataLoading]);

    const xAxisMemo = useMemo(() => {
        if (isSkillDataLoading || !scoreLines) return undefined;
        if (scoreLines?.length > 0) {
            if (activeTimelineOption === EGraphCardSelect.MONTH_4) {
                return scoreLines[0].scores.slice(-15).map((scoreItem) => format(scoreItem.date as Date, 'MM/d/yy'));
            } else if (activeTimelineOption === EGraphCardSelect.YEAR_WITH_WEEKS) {
                return scoreLines[0].scores.map((scoreItem) => format(scoreItem.date as Date, 'MM/d/yy'));
            }
        }
        return [];
    }, [scoreLines, activeTimelineOption, isSkillDataLoading]);

    return (
        <RootBox>
            <GraphCard
                scoreLines={scoreLinesValuesMemo}
                xAxis={xAxisMemo}
                handleTimelineChange={handleTimelineChange}
                translations={{ graphCardTitle: selectedSkillName }}
                isDataEmpty={scoreLinesValuesMemo && scoreLinesValuesMemo.length === 0}
                isLoading={isSkillDataLoading || scoreLinesValuesMemo === undefined}
            />
            <MainSearchInputWrapper className="search-box">
                <StyledSearchInput
                    placeholder="Search Users"
                    value={searchText || ''}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        if (setSearchText) setSearchText(e.target.value);
                    }}
                />
            </MainSearchInputWrapper>
            <Table<IInsightsOrganizationSkillVM, ITableHeader>
                headCells={headCells}
                data={latestDateData}
                propertyKeys={headCells.map((headCell) => {
                    return headCell.id;
                })}
                tableTitlePlural=""
                isFilterControlVisible={false}
                isFilterDrawerOpen={false}
                isLoading={isSkillDataLoading}
                isImageColumnPresent
                imageColumnName="userNuliaGuid"
                initialOrderBy="userName"
                userImageInfoColumn="userName"
            />
        </RootBox>
    );
};

export default OrganizationalSkillDetailsTab;
