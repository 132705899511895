import { Typography } from '@mui/material';
import styled from '../../../../ui/theme/styled';

export const GraphBox = styled('div')({
    width: '100%',

    '& path': {
        transition: 'opacity 0.5s ease-out',
    },
});

export const RootBox = styled('div')(({ theme }) => ({
    width: '100%',
    padding: '20px 0',
    [theme.breakpoints.down('lg')]: {
        padding: '0 0 20px 0',
    },
}));

export const ScoreLegendBox = styled('div', {
    shouldForwardProp: (prop) => prop !== 'isActive' && prop !== 'isDisabled',
})<{ isActive: boolean; isDisabled?: boolean }>(({ isActive, isDisabled, theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    opacity: isActive ? 1 : 0.3,
    '&:hover': {
        cursor: isDisabled === false && 'pointer !important',
    },
    [theme.breakpoints.down('lg')]: {
        gap: '10px',
    },
}));

export const ScoreLegendRootBox = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    gap: '30px',
    marginRight: '12px',
    [theme.breakpoints.down('lg')]: {
        padding: '0 10px',
        gap: '12px',
    },
}));

export const LegendCircle = styled('div', {
    shouldForwardProp: (prop) => prop !== 'color',
})<{ color: string }>(({ theme, color }) => ({
    backgroundColor: color,
    width: '22px',
    height: '22px',
    borderRadius: '11px',
    [theme.breakpoints.down('lg')]: {
        width: '16px',
        height: '16px',
    },
}));

export const LegendNameTypography = styled(Typography, {
    shouldForwardProp: (prop) => prop !== 'isDisabled',
})<{ isDisabled?: boolean }>(({ theme, isDisabled }) => ({
    color: theme.palette.gray.light,
    '&:hover': {
        cursor: isDisabled ? 'default' : 'pointer',
    },
    [theme.breakpoints.down('lg')]: {
        textTransform: 'unset',
        fontSize: '10px',
        lineHeight: '20px',
        fontWeight: 400,
        letterSpacing: '0.42px',
    },
}));

export const LegendLabelTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.gray.light,
    fontWeight: 500,
    letterSpacing: '1.25px',
    fontSize: '14px',
    fontFamily: 'Ubuntu',
    lineHeight: '16px',
    textTransform: 'uppercase',
}));
