import { styled } from '@mui/material';

export const RootContainer = styled('div')({
    borderRadius: '50%',
    height: 28,
    minHeight: 28,
    width: 28,
    minWidth: 28,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});
