import { FC, useMemo } from 'react';
import { Skeleton } from '@mui/material';
import Table from '../../ui/general/table/Table';
import { useAchievementsDetailStateValue } from '../../contexts/AchievementsDetailContext';
import {
    AchievementImg,
    ContentBox,
    DescriptionTypography,
    HeaderBox,
    ProgressTypography,
    RootBox,
    TitleTypography,
    RootInnerBox,
    ContentWrapper,
    EarnedDateTypography,
    EngagementPointsTypography,
} from './Style';
import { ITableHeadCell } from '../../interfaces/ITableHeaderCell';
import { formatDate } from '../../utils/dateUtil';
import { createUrl } from '../../utils/createUrl';
import { IAchievementVM } from '../../interfaces/views/IAchievementVM';
import { EAchievementState } from '../../interfaces/enums/EAchievementState';
import fetchImageErrorCallback from '../../utils/fetchImageErrorCallback';
import loadImageGraduallyHandler from '../../utils/loadImageGradually';

interface ITableHeader {
    imageUrl: string;
    currentProgress: string;
    awardedDate: string;
    engagementPoints: string;
}

const AchievementsDetail: FC = () => {
    const { achievementGroupDetail, isDataLoading } = useAchievementsDetailStateValue();

    const headCells: ITableHeadCell<ITableHeader>[] = [
        {
            id: 'imageUrl',
            label: '',
            customRender: (value: string, obj: IAchievementVM) => {
                if (!value || value === '') return <></>;
                return (
                    <AchievementImg
                        isOpacityPresent={obj.state === EAchievementState.NOT_STARTED}
                        src={createUrl(value)?.generatedUrl}
                        onError={fetchImageErrorCallback}
                        onLoad={loadImageGraduallyHandler}
                        alt={`${obj.engagementPoints} Engagement Points`}
                    />
                );
            },
            disableSort: true,
        },
        {
            id: 'currentProgress',
            label: 'Progress',
            customRender: (value: string, obj: IAchievementVM) => {
                return (
                    <ProgressTypography
                        isOpacityPresent={obj.state === EAchievementState.NOT_STARTED}
                    >{`${value}/${obj.target}`}</ProgressTypography>
                );
            },
            disableSort: true,
        },
        {
            id: 'awardedDate',
            label: 'Awarded Date',
            customRender: (value: Date, obj: IAchievementVM) => {
                return (
                    <EarnedDateTypography
                        variant="body2"
                        isOpacityPresent={obj.state === EAchievementState.NOT_STARTED}
                    >
                        {value ? formatDate(value) : 'N/A'}
                    </EarnedDateTypography>
                );
            },
            disableSort: true,
        },
        {
            id: 'engagementPoints',
            label: 'Engagement Points',
            align: 'center',
            disableSort: true,
            customRender: (value: number, obj: IAchievementVM) => {
                return (
                    <EngagementPointsTypography
                        variant="body2"
                        isOpacityPresent={obj.state === EAchievementState.NOT_STARTED}
                    >
                        {value}
                    </EngagementPointsTypography>
                );
            },
        },
    ];

    const titleMemo = useMemo(() => {
        if (isDataLoading) return <Skeleton variant="rectangular" width="30%" height="28px" />;
        return <TitleTypography variant="h5">{achievementGroupDetail?.title}</TitleTypography>;
    }, [isDataLoading, achievementGroupDetail]);

    const descriptionMemo = useMemo(() => {
        if (isDataLoading) return <Skeleton variant="rectangular" width="50%" height="24px" />;
        return <DescriptionTypography variant="body1">{achievementGroupDetail?.description}</DescriptionTypography>;
    }, [isDataLoading]);

    return (
        <RootBox>
            <RootInnerBox>
                <ContentWrapper>
                    <HeaderBox>
                        {titleMemo}
                        {descriptionMemo}
                    </HeaderBox>
                    <ContentBox>
                        <Table
                            isLoading={isDataLoading}
                            data={achievementGroupDetail?.achievements || []}
                            headCells={headCells}
                            propertyKeys={headCells.map((cell) => cell.id)}
                            tableCellStyle={{ border: 'none' }}
                            headerCellStyle={{ border: 'none' }}
                            isPaginationVisible={false}
                            customMediaColumnName="imageUrl"
                        />
                    </ContentBox>
                </ContentWrapper>
            </RootInnerBox>
        </RootBox>
    );
};

export default AchievementsDetail;
