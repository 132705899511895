import { FC, useCallback, useMemo, KeyboardEvent } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Drawer, useMediaQuery, useTheme } from '@mui/material';
import { CloseOutlined } from '@material-ui/icons';
import Loading from '../../ui/general/loading/Loading';
import NumberStatusBox from '../../ui/general/number-status-box/NumberStatusBox';
import UpArrowIcon from '../../assets/icons/UpArrowIcon';
import UpArrowFullIcon from '../../assets/icons/UpArrowFullIcon';
import Tooltip from '../../ui/general/tooltip/Tooltip';
import DownArrowFullIcon from '../../assets/icons/DownArrowFullIcon';
import Filters from '../../ui/filters/filters/Filters';
import { ESkillLevel } from '../../interfaces/enums/ESkillLevel';
import { useOutcomesStateValue } from '../../contexts/OutcomesContext';
import { EOrderDirection, EOutcomeSort } from '../../contexts/util/filterCategories';
import SomethingWentWrongCard from '../../ui/cards/something-went-wrong-card/SomethingWentWrongCard';
import { FiltersProvider } from '../../contexts/FiltersContext';
import OutcomeCard from '../../ui/cards/outcome-card/OutcomeCard';
import {
    SkillsStatusOverallWrapper,
    FilterBox,
    FilterLabel,
    FilterSelect,
    HeaderWrapper,
    MainContentBox,
    OrderSpan,
    RootInnerBox,
    SideBox,
    OutcomesCountBox,
    TitleBox,
    OutcomesListWrapper,
    OutcomeCardWrapper,
    TitleSpan,
    ContentHeaderBox,
    FilterIconSpan,
    RootBox,
    StyledPaper
} from './Style';
import NoDataCard from '../../ui/cards/no-data-card/NoDataCard';
import { EOutcomesStatuses } from '../../interfaces/enums/EOutcomesStatuses';
import { makeOutcomeRoute } from '../routes';
import RecommendationSimpleCard from '../../ui/cards/recommendation-simple-card/RecommendationSimpleCard';
import FilterIcon from '../../assets/icons/FilterIcon';
import { useCrumbsStateValue } from '../../contexts/CrumbsContext';
import useFilterDrawer from '../../hooks/useFilterDrawer';
import { CloseDrawerActionWrapper, CloseDrawerBox, FilterDrawerBox } from '../../css/CommonComponents';
import usePageName from '../../hooks/usePageName';

const Outcomes: FC = () => {
    const {
        outcomes,
        isOutcomesLoading,
        skillsStatusOverallInfo,
        activeOutcomesStatusOverallFilters,
        changeSkillStatusOverallActiveFilters,
        onFilterValueChange,
        setSortOrder,
        sortOrder,
        isError,
        refetchData,
        filterCategories,
        toggleAssignCallback,
        emptyQuickFilters,
        isFetchedDataProcessed,
        recommendations,
        isRecommendationsLoading,
        successRecommendationCallback
    } = useOutcomesStateValue();
    const { appendCrumb } = useCrumbsStateValue();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const theme = useTheme();
    const isLgDown = useMediaQuery(theme.breakpoints.down('lg'));
    const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
    const { isDrawerOpen, toggleFilterDrawer } = useFilterDrawer();
    usePageName({ pageName: 'Outcomes' });

    const onOutcomeClick = useCallback(
        (id: string) => {
            const pathname = makeOutcomeRoute(id);
            appendCrumb({
                name: outcomes.find((outcome) => outcome.id.toString() === id)?.title || 'Outcome',
                pathname: pathname
            });
            navigate(pathname);
        },
        [outcomes]
    );

    const skillsStatusOverallMemo = useMemo(() => {
        return (
            <SkillsStatusOverallWrapper isDisabled={isOutcomesLoading} className='outcome-filter-buttons'>
                {skillsStatusOverallInfo.map((status) => {
                    return (
                        <NumberStatusBox
                            key={status.key}
                            clickHandler={(key) => changeSkillStatusOverallActiveFilters(key as EOutcomesStatuses)}
                            elementId={status.key}
                            isActive={activeOutcomesStatusOverallFilters.includes(status.key)}
                            circleColor={status.circleColor}
                            label={status.label}
                            value={status.value}
                            icon={status.icon}
                            isLoading={status.isLoading}
                        />
                    );
                })}
            </SkillsStatusOverallWrapper>
        );
    }, [skillsStatusOverallInfo, activeOutcomesStatusOverallFilters, isOutcomesLoading]);

    const handleSkillFilterChange = (event: SelectChangeEvent<unknown>) => {
        const sortBy = event.target.value as EOutcomeSort;
        setSortOrder({
            sortBy,
            direction:
                sortBy === EOutcomeSort.SCORE || sortBy === EOutcomeSort.WEEKS_IN_PROGRESS
                    ? EOrderDirection.DESC
                    : sortOrder.direction
        });
    };

    const setAscedingFilterOrderWay = () => {
        setSortOrder({
            sortBy: sortOrder.sortBy,
            direction: EOrderDirection.ASC
        });
    };

    const setDescedingFilterOrderWay = () => {
        setSortOrder({
            sortBy: sortOrder.sortBy,
            direction: EOrderDirection.DESC
        });
    };

    const outcomesMemo = useMemo(() => {
        if (isOutcomesLoading) return <Loading />;
        if (isError) return <SomethingWentWrongCard actionCallback={refetchData}></SomethingWentWrongCard>;
        if (outcomes.length === 0 && isFetchedDataProcessed)
            return <NoDataCard message='No data that match your filter criteria' />;

        let container = document.querySelector('.outcomes-inner-box.not-empty');
        if (container) {
            (container as HTMLElement).style.transition = 'none';
            (container as HTMLElement).style.opacity = '0';
            container.classList.add('preAnimation');
            setTimeout(function () {
                if (container) {
                    (container as HTMLElement).style.transition = 'all 300ms';
                    (container as HTMLElement).style.opacity = '1';
                    container.classList.remove('preAnimation');
                }
            }, 100);
        }

        return (
            <OutcomeCardWrapper>
                {outcomes?.map((outcome, index) => {
                    return (
                        <OutcomeCard
                            index={index}
                            key={outcome.id}
                            name={outcome.title}
                            description={outcome.description}
                            appIcons={outcome.apps}
                            id={outcome.id}
                            outcomeClickHandler={onOutcomeClick}
                            isAssignedControlActive
                            assignment={outcome.assignment}
                            isAssigned={outcome.assignment.level !== ESkillLevel.NONE}
                            totalSkillNumber={outcome.userSkillStates?.length}
                            totalAchievedSkillNumber={outcome.numberOfAchievedSkills}
                            totalInProgressSkillNumber={outcome.numberOfInProgressSkills}
                            totalNeedAttentionSkillNumber={outcome.numberOfNeedAttentionSkills}
                            badges={[
                                {
                                    state: outcome.userLevelBadge?.state,
                                    level: ESkillLevel.USER
                                },
                                {
                                    state: outcome.producerLevelBadge?.state,
                                    level: ESkillLevel.PRODUCER
                                },
                                {
                                    state: outcome.masterLevelBadge?.state,
                                    level: ESkillLevel.MASTER
                                }
                            ]}
                            isMasterLevelRequested={outcome.masterLevelRequested}
                            isUserFocus={false}
                            isCompanyImportant={outcome.isCompanyFavorite}
                            translations={{
                                assignTooltipText: t('tooltips.addOutcome'),
                                unassignTooltipText: t('tooltips.removeOutcome')
                            }}
                            toggleAssignCallback={toggleAssignCallback}
                            outcomeImage={outcome.image}
                        ></OutcomeCard>
                    );
                })}
            </OutcomeCardWrapper>
        );
    }, [
        outcomes,
        isOutcomesLoading,
        isError,
        onOutcomeClick,
        refetchData,
        toggleAssignCallback,
        isFetchedDataProcessed
    ]);

    const filtersMemo = useMemo(() => {
        return (
            <Filters
                isDisabled={isOutcomesLoading}
                handleFilterValueChange={onFilterValueChange}
                emptyQuickFilters={emptyQuickFilters}
                categories={filterCategories}
                isQuickFiltersSelected={activeOutcomesStatusOverallFilters.length > 0}
            />
        );
    }, [
        isOutcomesLoading,
        onFilterValueChange,
        emptyQuickFilters,
        filterCategories,
        activeOutcomesStatusOverallFilters
    ]);

    const outcomeListTitleMemo = useMemo(() => {
        if (activeOutcomesStatusOverallFilters.includes(EOutcomesStatuses.ASSIGNED_OUTCOMES))
            return t('outcomes.statuses.assigned');
        if (activeOutcomesStatusOverallFilters.includes(EOutcomesStatuses.ATTAINED))
            return t('outcomes.statuses.attained');
        if (activeOutcomesStatusOverallFilters.includes(EOutcomesStatuses.NEED_ATTENTION))
            return t('outcomes.statuses.needAttention');
        if (activeOutcomesStatusOverallFilters.includes(EOutcomesStatuses.UNCLAIMED_BADGES))
            return t('outcomes.statuses.badgesReadyToClaim');
        if (activeOutcomesStatusOverallFilters.includes(EOutcomesStatuses.IN_PROGRESS))
            return t('outcomes.statuses.inProgress');
        return t('outcomes.outcomes');
    }, [activeOutcomesStatusOverallFilters]);

    const recommendationsMemo = useMemo(() => {
        return (
            <RecommendationSimpleCard
                cardItems={recommendations}
                isLoading={isRecommendationsLoading}
                successRecommendationCallback={successRecommendationCallback}
            />
        );
    }, [recommendations, isRecommendationsLoading]);

    const onToggleSortKeyDown = useCallback(
        (e: KeyboardEvent<HTMLDivElement>) => {
            if (e.key === 'Enter') {
                if (sortOrder.direction === EOrderDirection.ASC) setDescedingFilterOrderWay();
                else setAscedingFilterOrderWay();
                e.stopPropagation();
            }
        },
        [sortOrder]
    );

    return (
        <RootBox>
            <FiltersProvider categories={filterCategories}>
                <RootInnerBox>
                    {isLgDown && (
                        <Drawer anchor='right' open={isDrawerOpen} onClose={toggleFilterDrawer}>
                            <CloseDrawerBox>
                                <CloseDrawerActionWrapper onClick={toggleFilterDrawer}>
                                    <CloseOutlined />
                                </CloseDrawerActionWrapper>
                            </CloseDrawerBox>
                            <FilterDrawerBox>{filtersMemo}</FilterDrawerBox>
                        </Drawer>
                    )}
                    <>
                        <MainContentBox>
                            <ContentHeaderBox>
                                {skillsStatusOverallMemo}
                                {isLgDown && recommendationsMemo}
                            </ContentHeaderBox>
                            <StyledPaper elevation={1}>
                                <HeaderWrapper>
                                    <TitleBox variant='h6'>
                                        {isLgDown && (
                                            <Tooltip title='Filters'>
                                                <FilterIconSpan onClick={toggleFilterDrawer}>
                                                    <FilterIcon />
                                                </FilterIconSpan>
                                            </Tooltip>
                                        )}
                                        <TitleSpan>{outcomeListTitleMemo}</TitleSpan>
                                        <OutcomesCountBox variant='body2'>({outcomes?.length ?? 0})</OutcomesCountBox>
                                    </TitleBox>
                                    <FilterBox isDisabled={isOutcomesLoading}>
                                        {!isMdDown && <FilterLabel>{t('outcomes.sortBy')}</FilterLabel>}
                                        <FilterSelect
                                            IconComponent={UpArrowIcon}
                                            value={sortOrder.sortBy}
                                            onChange={handleSkillFilterChange}
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem value={EOutcomeSort.NAME}>{t('outcomes.filters.name')}</MenuItem>
                                            <MenuItem value={EOutcomeSort.SCORE}>
                                                {t('outcomes.filters.score')}
                                            </MenuItem>
                                            <MenuItem value={EOutcomeSort.ASSIGNED_DATE}>
                                                {t('outcomes.filters.assignedDate')}
                                            </MenuItem>
                                            <MenuItem value={EOutcomeSort.WEEKS_IN_PROGRESS}>
                                                {t('outcomes.filters.weeksInProgress')}
                                            </MenuItem>
                                        </FilterSelect>
                                        {sortOrder.direction === EOrderDirection.ASC ? (
                                            <Tooltip title={t('tooltips.showDescending')}>
                                                <OrderSpan
                                                    tabIndex={0}
                                                    onClick={setDescedingFilterOrderWay}
                                                    onKeyDown={onToggleSortKeyDown}
                                                >
                                                    <UpArrowFullIcon />
                                                </OrderSpan>
                                            </Tooltip>
                                        ) : (
                                            <Tooltip title={t('tooltips.showAscending')}>
                                                <OrderSpan
                                                    tabIndex={0}
                                                    onClick={setAscedingFilterOrderWay}
                                                    onKeyDown={onToggleSortKeyDown}
                                                >
                                                    <DownArrowFullIcon />
                                                </OrderSpan>
                                            </Tooltip>
                                        )}
                                    </FilterBox>
                                </HeaderWrapper>
                                <OutcomesListWrapper
                                    className={[
                                        'outcomes-inner-box',
                                        'outcome-card-wrapper',
                                        outcomes ? 'not-empty' : 'empty'
                                    ].join(' ')}
                                >
                                    {outcomesMemo}
                                </OutcomesListWrapper>
                            </StyledPaper>
                        </MainContentBox>
                        {!isLgDown && (
                            <SideBox>
                                {recommendationsMemo}
                                {filtersMemo}
                            </SideBox>
                        )}
                    </>
                </RootInnerBox>
            </FiltersProvider>
        </RootBox>
    );
};

export default Outcomes;
