export enum EApiQueryKey {
    // Achievements query service
    ACHIEVEMENTS_FETCH_BY_GROUP_ID = 'achievements.getAchievementsByGroupId',
    ACHIEVEMENTS_FETCH_ALL = 'achievements.getAchievements',

    // Activity Feed query service
    ACTIVITY_FEED_FETCH_HIGHLIGHTS = 'activityFeed.getHighlights',
    ACTIVITY_FEED_FETCH_USER_ACTIVITY = 'activityFeed.getUserActivityFeed',

    // Assessment query service
    ASSESSMENT_FETCH_REPORT = 'assessment.getAssessmentReportData',
    ASSESSMENT_FETCH_REPORT_BY_DATE = 'assessment.getAssessmentReportByDate',
    ASSESSMENT_DOWNLOAD_REPORT_BY_DATE = 'assessment.downloadAssessmentReportByDate',
    ASSESSMENT_FETCH_REPORT_BY_SKILL = 'assessment.getAssessmentReportBySkill',

    // How To's service
    HOW_TO_FETCH_CATEGORIES = 'howTo.getCategories',
    HOW_TO_FETCH_PLAYLIST_BY_CATEGORY = 'howTo.getPlaylistByCategory',
    HOW_TO_FETCH_PLAYLIST = 'howTo.getPlaylist',

    // Insights service
    INSIGHTS_FETCH_PERSONAL_DATA = 'insights.getInsightsPersonalData',
    INSIGHTS_FETCH_PERSONAL_SKILL = 'insights.getPersonalSkill',
    INSIGHTS_FETCH_PERSONAL_OUTCOMES = 'insights.getPersonalOutcomes',
    INSIGHTS_FETCH_PERSONAL_BADGES = 'insights.getPersonalBadges',
    INSIGHTS_FETCH_ORGANIZATION_USERS_COMPETENCE = 'insights.getOrganizationUsersCompetence',
    INSIGHTS_FETCH_ORGANIZATION_OUTCOMES = 'insights.getOrganizationOutcomes',
    INSIGHTS_FETCH_ORGANIZATION_ENGAGEMENT_POINTS = 'insights.getOrganizationEngagementPoints',
    INSIGHTS_FETCH_ORGANIZATION_BADGES = 'insights.getOrganizationBadges',
    INSIGHTS_FETCH_ORGANIZATION_OUTCOMES_USERS = 'insights.getOrganizationOutcomesUsers',
    INSIGHTS_FETCH_ORGANIZATION_OUTCOMES_SKILLS = 'insights.getOrganizationOutcomesSkills',
    INSIGHTS_FETCH_ORGANIZATION_SKILL = 'insights.getOrganizationSkill',
    INSIGHTS_FETCH_ORGANIZATION_USER_SUMMARIES = 'insights.getOrganizationUserSummaries',
    INSIGHTS_FETCH_ORGANIZATION_USER_BADGES = 'insights.getOrganizationUserBadges',
    INSIGHTS_FETCH_ORGANIZATION_USER_OUTCOMES = 'insights.getOrganizationUserOutcomes',
    INSIGHTS_FETCH_ORGANIZATION_USER_SKILLS = 'insights.getOrganizationUserSkills',
    INSIGHTS_FETCH_PERSONAL_ENGAGEMENT_POINTS = 'insights.getInsightsPersonalEngagementPoints',
    INSIGHTS_FETCH_PERSONAL_LOGGED_IN = 'insights.getInsightsPersonalLoggedIn',
    INSIGHTS_FETCH_ORGANIZATION_USER_ENGAGEMENT_POINTS = 'insights.getInsightsOrganizationUserEngagementPoints',
    INSIGHTS_FETCH_ORGANIZATION_USER_LOGGED_IN = 'insights.getInsightsOrganizationUserLoggedIn',
    INSIGHTS_FETCH_ORGANIZATION_CURRENT_BADGES = 'insights.getOrganizationCurrentBadges',
    INSIGHTS_FETCH_ORGANIZATION_USERS_FOR_BADGE = 'insights.getOrganizationalUsersForBadge',

    // Motw service
    MOTW_FETCH = 'motw.getMotw',

    // Recommendation service
    RECOMMENDATION_FETCH_BY_OUTCOME = 'recommendations.getUserRecommendationsByOutcome',

    // Showcase service
    SHOWCASE_GET_BADGES = 'showcase.getBadges',

    // Skill service
    SKILL_FETCH_USER_PROGRESS = 'skill.getUserSkillProgress',
    SKILL_FETCH_SNAPSHOT = 'skills.getUserSkillsSnapshot',
    SKILL_FETCH = 'skills.getUserSkill',
    SKILL_FETCH_ALL = 'skills.getAllSkills',

    // Tenant service
    TENANT_FETCH_HISTORY = 'tenant.getTenantHistories',
    TENANT_FETCH_LICENSED_USERS = 'tenant.getLicensedUsers',
    TENANT_FETCH_USERS = 'tenant.getAllUsers',
    TENANT_FETCH_OUTCOME_HISTORIES = 'tenant.getOutcomeTenantHistories',
    TENANT_FETCH_JOS_TASK_STATUS = 'tenant.getTenantJobTaskStatus',
    TENANT_FETCH_STATUS = 'tenant.getStatus',
    TENANT_FETCH_LICENSE_SETTINGS = 'tenant.getLicenseSettings',

    // User service
    USER_FETCH_RANKING = 'user.getRankings',
    USER_FETCH_IMAGE = 'users.getCurrentUserImage',
    USER_FETCH_TENANT_PREFERENCES = 'users.getTenantPreferences',
    USER_FETCH_CURRENT = 'users.getCurrentUser',

    // License service
    LICENSE_FETCH_COUNT = 'licenses.getLicensesCount',
    LICENSE_FETCH_USERS = 'licenses.getUsers',

    // Outcome service
    OUTCOME_FETCH_ALL_ADMIN = 'outcome.getAllAdminOutcomes',
    OUTCOME_FETCH_USE_SNAPSHOT = 'outcomes.getUserOutcomesSnapshot',
    OUTCOME_FETCH_ALL = 'outcome.getAllOutcomes',
    OUTCOME_FETCH = 'outcome.getOutcome',
}
