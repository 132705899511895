import { Typography } from '@mui/material';
import styled from '../../theme/styled';

export const MessageBox = styled(Typography)({
    textAlign: 'center',
});

export const Container = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    gap: '16px',
    boxShadow: '0px 2.5px 8px rgb(0 0 0 / 8%), 0px 1px 4px rgb(0 0 0 / 8%)',
    padding: '12px',
});

export const ActionBox = styled('div')({
    display: 'flex',
    justifyContent: 'center',
});
