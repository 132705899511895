import { createContext, FC, useCallback, useContext, useEffect, useState, PropsWithChildren, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import {
    useGetUserSkill,
    useGetUserSkillProgress,
    usePostAssignSkill,
    usePostFavoriteSkill,
    usePostMasterSkill,
} from '../services/SkillQueryService';
import { UserSkillProgressDTO } from '../interfaces/dtos/UserSkillProgressDTO';
import { IBehavior } from '../interfaces/IBehavior';
import { EBehaviorLevel } from '../interfaces/enums/EBehaviorLevel';
import { IActivity } from '../interfaces/IActivity';
import { ESkillLevel } from '../interfaces/enums/ESkillLevel';
import { ISingleUserSkillVM } from '../interfaces/views/ISingleUserSkillVM';
import { EAssignmentType } from '../interfaces/enums/EAssignmentType';
import { EToastSeverity, useToastContextStateValue } from './ToastContext';
import { ErrorDTO } from '../interfaces/dtos/ErrorDTO';
import { EMasterAction } from '../interfaces/enums/EMasterAction';
import { progressDoughnutChartType } from '../utils/progressDoughnutChartType';
import { usePostUserContentTrackingQuery } from '../services/UserQueryService';
import { EUserContentTrackingType } from '../interfaces/enums/EUserContentTrackingType';
import { useApiStateValue } from './ApiContext';
import { EApiQueryKey } from '../interfaces/enums/EApiQueryKey';

export interface ISkillContext {
    skillData?: ISingleUserSkillVM;
    isLoadingSkillData: boolean;
    refetchSkillData: any;
    userSkillProgressData?: UserSkillProgressDTO;
    isUserSkillProgressLoading: boolean;
    refetchUserSkillProgress: () => void;
    selectedBehavior: IBehavior | null;
    setSelectedBehavior: (selectedBehavior: IBehavior | null) => void;
    selectedBehaviorLevel: EBehaviorLevel | null;
    setSelectedBehaviorLevel: (behaviorLevel: EBehaviorLevel | null) => void;
    activities: IActivity[] | null;
    setActivities: (activities: IActivity[] | null) => void;
    toggleUserFavorite: () => void;
    toggleUserAssignment: (isAssign: boolean) => void;
    mutatePostFavoriteLoading: boolean;
    mutatePostAssignLoading: boolean;
    toggleUserMaster: (isMaster: boolean) => void;
    isErrorFetchUserSkillProgress: boolean;
    isErrorFetchUserSkillData: boolean;
    updateActivityLastTriedData: (activityId: string, lastTriedDate: Date) => void;
    totalNumberOfActivities: number;
    showAllActivitiesHandler: () => void;
    sendContentUsageRequest: (relatedId?: number) => Promise<void>;
}

export const SkillContext = createContext<ISkillContext>({} as ISkillContext);

interface IProps {}

export const SkillProvider: FC<PropsWithChildren<IProps>> = ({ children }) => {
    const params = useParams<{ id?: string }>();
    const {
        data: fetchedUserSkillData,
        isLoading: userSkillLoading,
        refetch: refetchGetUserSkill,
        isRefetching: isUserSkillDataRefetching,
        isError: isErrorFetchUserSkillData,
    } = useGetUserSkill(params?.id);
    const [userSkillData, setUserSkillData] = useState<ISingleUserSkillVM | undefined>(undefined);
    const {
        data: fetchedUserSkillProgressData,
        isLoading: userSkillProgressLoading,
        isRefetching: isUserSkillProgressRefetching,
        refetch: refetchUserSkillProgress,
        isError: isErrorFetchUserSkillProgress,
    } = useGetUserSkillProgress(params?.id);
    const [userSkillProgressData, setUserSkillProgressData] = useState<UserSkillProgressDTO | undefined>(undefined);
    const {
        mutateAsync: mutatePostAssignSkillAsync,
        isPending: mutatePostAssignLoading,
        error: errorPostAssignSkill,
    } = usePostAssignSkill();
    const {
        mutateAsync: mutatePostFavoriteAsync,
        isPending: mutatePostFavoriteLoading,
        error: errorPostFavoriteSkill,
    } = usePostFavoriteSkill();
    const [totalNumberOfActivities, setTotalNumberOfActivities] = useState<number>(0);
    const { setToastMessage } = useToastContextStateValue();
    const { mutateAsync: mutatePostMasterAsync, error: errorPostToggleMasterSkill } = usePostMasterSkill();
    const [searchParams] = useSearchParams();
    const paramRef = useRef<string | undefined>(undefined);
    const { t } = useTranslation();
    const { mutateAsync: mutatePostUserContentTrackingAsync } = usePostUserContentTrackingQuery();
    const { resetQuery } = useApiStateValue();

    useEffect(() => {
        const filterBehaviorQueryParam = searchParams.get('behavior');
        const filterActivityQueryParam = searchParams.get('activity');

        if (fetchedUserSkillData && filterBehaviorQueryParam) {
            try {
                const behaviorId = parseInt(filterBehaviorQueryParam);
                const selectBehaviorCallback = (behavior: IBehavior) => {
                    if (behavior.id === behaviorId) {
                        setSelectedBehavior(behavior);
                        const scrollElementInterval = setInterval(() => {
                            const element = document.getElementById(`${behavior.id}`);
                            if (element) {
                                window.scrollTo({
                                    top: element.offsetTop,
                                    behavior: 'smooth',
                                });
                                clearInterval(scrollElementInterval);
                            }
                        }, 400);
                        setTimeout(() => {
                            clearInterval(scrollElementInterval);
                        }, 5000);
                    }
                };
                if (fetchedUserSkillData?.userBehaviors.behaviors) {
                    fetchedUserSkillData?.userBehaviors.behaviors.find(selectBehaviorCallback);
                }
                if (fetchedUserSkillData?.producerBehaviors.behaviors) {
                    fetchedUserSkillData?.producerBehaviors.behaviors.find(selectBehaviorCallback);
                }
                if (fetchedUserSkillData?.masterBehaviors.behaviors) {
                    fetchedUserSkillData?.masterBehaviors.behaviors.find(selectBehaviorCallback);
                }
            } catch (e) {
                console.error(e);
            }
        }

        if (fetchedUserSkillData && filterActivityQueryParam) {
            try {
                const activityId = parseInt(filterActivityQueryParam);
                const selectBehaviorCallback = (behavior: IBehavior) => {
                    const activity = behavior.activities.find((activity) => activity.id === activityId);
                    if (activity) {
                        setSelectedBehavior(behavior);
                        const scrollElementInterval = setInterval(() => {
                            const element = document.getElementById(`activity-card-${activity.id}`);
                            if (element) {
                                window.scrollTo({
                                    top: element.offsetTop,
                                    behavior: 'smooth',
                                });
                                clearInterval(scrollElementInterval);
                            }
                        }, 400);
                        setTimeout(() => {
                            clearInterval(scrollElementInterval);
                        }, 5000);
                    }
                };
                if (fetchedUserSkillData?.userBehaviors.behaviors) {
                    fetchedUserSkillData?.userBehaviors.behaviors.find(selectBehaviorCallback);
                }
                if (fetchedUserSkillData?.producerBehaviors.behaviors) {
                    fetchedUserSkillData?.producerBehaviors.behaviors.find(selectBehaviorCallback);
                }
                if (fetchedUserSkillData?.masterBehaviors.behaviors) {
                    fetchedUserSkillData?.masterBehaviors.behaviors.find(selectBehaviorCallback);
                }
            } catch (e) {
                console.error(e);
            }
        }
    }, [fetchedUserSkillData, searchParams]);

    useEffect(() => {
        if (params?.id && params?.id !== paramRef.current) {
            resetQuery(EApiQueryKey.SKILL_FETCH);
            resetQuery(EApiQueryKey.SKILL_FETCH_USER_PROGRESS);
        }
    }, [params]);

    useEffect(() => {
        if (errorPostFavoriteSkill && errorPostFavoriteSkill instanceof Error) {
            const errorDTO = errorPostFavoriteSkill as ErrorDTO;
            if (errorDTO.response?.data?.code === 509) {
                setToastMessage({
                    isOpen: true,
                    message: t('errors.skill.errorFavoriteSkillLimit'),
                    severity: EToastSeverity.WARNING,
                });
            } else {
                setToastMessage({
                    isOpen: true,
                    message: t('errors.skill.errorFavoriteSkill'),
                    severity: EToastSeverity.ERROR,
                });
            }
        }
    }, [errorPostFavoriteSkill]);

    useEffect(() => {
        setUserSkillData(fetchedUserSkillData);
        let totalNumberOfActivities = 0;
        if (fetchedUserSkillData?.userBehaviors?.behaviors) {
            totalNumberOfActivities += fetchedUserSkillData.userBehaviors.behaviors.reduce((prev, curr) => {
                return prev + curr.activities.length;
            }, 0);
        }
        if (fetchedUserSkillData?.producerBehaviors?.behaviors)
            totalNumberOfActivities += fetchedUserSkillData.producerBehaviors.behaviors.reduce((prev, curr) => {
                return prev + curr.activities.length;
            }, 0);
        if (fetchedUserSkillData?.masterBehaviors?.behaviors)
            totalNumberOfActivities += fetchedUserSkillData.masterBehaviors.behaviors.reduce((prev, curr) => {
                return prev + curr.activities.length;
            }, 0);
        setTotalNumberOfActivities(totalNumberOfActivities);
    }, [fetchedUserSkillData]);

    useEffect(() => {
        setUserSkillProgressData(fetchedUserSkillProgressData);
    }, [fetchedUserSkillProgressData]);

    useEffect(() => {
        if (errorPostAssignSkill && errorPostAssignSkill instanceof Error) {
            const errorDTO = errorPostAssignSkill as ErrorDTO;
            if (errorDTO.response?.data?.code === 500) {
                setToastMessage({
                    isOpen: true,
                    message: t('errors.skill.errorAssignSkillPartOfAssignedOutcome'),
                    severity: EToastSeverity.ERROR,
                });
            } else {
                setToastMessage({
                    isOpen: true,
                    message: t('errors.skill.errorAssignSkill'),
                    severity: EToastSeverity.ERROR,
                });
            }
        }
    }, [errorPostAssignSkill]);

    useEffect(() => {
        if (errorPostToggleMasterSkill && errorPostToggleMasterSkill instanceof Error) {
            const errorDTO = errorPostToggleMasterSkill as ErrorDTO;
            if (errorDTO.response?.data?.code === 500) {
                setToastMessage({
                    isOpen: true,
                    message: t('errors.skill.errorToggleTargetLevelPartOfAssignedOutcome'),
                    severity: EToastSeverity.ERROR,
                });
            } else {
                setToastMessage({
                    isOpen: true,
                    message: t('errors.skill.errorToggleTargetLevel'),
                    severity: EToastSeverity.ERROR,
                });
            }
        }
    }, [errorPostToggleMasterSkill]);

    const toggleUserAssignment = useCallback(async (isAssign: boolean) => {
        if (params.id) {
            try {
                const responseData = await mutatePostAssignSkillAsync({
                    assignmentType: isAssign ? EAssignmentType.ASSIGN : EAssignmentType.UNASSIGN,
                    skillId: params.id,
                });
                setUserSkillData((data: ISingleUserSkillVM | undefined) => {
                    if (data) {
                        return {
                            ...data,
                            assignment: {
                                ...responseData,
                            },
                            isAssigned: responseData.level !== ESkillLevel.NONE,
                            isUserFavoriteControlDisabled: responseData.level === ESkillLevel.NONE,
                            progressDoughnutChartType: progressDoughnutChartType(responseData, data.skillType),
                        };
                    }
                    return data;
                });
                if (responseData.level === ESkillLevel.NONE)
                    setUserSkillProgressData((userSkillProgressData) => {
                        if (!userSkillProgressData) return userSkillProgressData;
                        return {
                            ...userSkillProgressData,
                            level: ESkillLevel.PRODUCER,
                        };
                    });
            } catch (err) {
                console.error(err);
            }
        }
    }, []);

    useEffect(() => {
        if (params?.id && paramRef.current !== params.id) {
            paramRef.current = params.id;
            refetchGetUserSkill();
            refetchUserSkillProgress();
        }
    }, [params]);
    const [selectedBehavior, setSelectedBehavior] = useState<IBehavior | null>(null);
    const [selectedBehaviorLevel, setSelectedBehaviorLevel] = useState<EBehaviorLevel | null>(null);
    const [activities, setActivities] = useState<IActivity[] | null>(null);

    useEffect(() => {
        //TODO Load activities from behaviors data
        if (!selectedBehavior) setActivities(null);
    }, [selectedBehavior]);

    const toggleUserFavorite = useCallback(async () => {
        if (params.id) {
            const data = await mutatePostFavoriteAsync({ skillId: params.id });
            setUserSkillData((userSkillData) => {
                if (userSkillData)
                    return {
                        ...userSkillData,
                        isUserFavorite: data.isFavorite,
                    };
                return userSkillData;
            });
        }
    }, [setUserSkillData]);

    const toggleUserMaster = useCallback(
        async (isMasterTarget: boolean) => {
            if (params.id && userSkillData?.id) {
                try {
                    await mutatePostMasterAsync({
                        skillId: userSkillData.id,
                        action: isMasterTarget ? EMasterAction.ENABLE_MASTER : EMasterAction.DISABLE_MASTER,
                    });
                    const newLevel = isMasterTarget ? ESkillLevel.MASTER : ESkillLevel.PRODUCER;
                    setUserSkillProgressData((userSkillProgressData) => {
                        if (!userSkillProgressData) return userSkillProgressData;
                        return {
                            ...userSkillProgressData,
                            level: newLevel,
                        };
                    });
                    setUserSkillData((userSkillData) => {
                        if (!userSkillData) return userSkillData;
                        return {
                            ...userSkillData,
                            assignment: {
                                ...userSkillData.assignment,
                                level: newLevel,
                            },
                            progressDoughnutChartType: progressDoughnutChartType(
                                { ...userSkillData.assignment, level: newLevel },
                                userSkillData.skillType
                            ),
                        };
                    });
                } catch (e) {
                    setToastMessage({
                        isOpen: true,
                        message: t('skill.errors.masterLevelGenericError'),
                        severity: EToastSeverity.ERROR,
                    });
                }
            }
        },
        [setUserSkillData, userSkillData]
    );

    const updateActivityLastTriedData = useCallback(
        (activityId: string, lastTriedData: Date) => {
            setUserSkillData((userSkillData) => {
                if (!userSkillData) return userSkillData;
                const behaviors = [userSkillData?.userBehaviors, userSkillData?.producerBehaviors];
                if (userSkillData?.masterBehaviors) behaviors.push(userSkillData.masterBehaviors);
                const [userBehaviors, producerBehaviors, masterBehaviors] = behaviors.map((behavior) => {
                    if (!behavior) return behavior;
                    if (behavior.behaviors) {
                        behavior.behaviors = behavior.behaviors.map((behavior) => {
                            behavior.activities = behavior.activities.map((activity) => {
                                if (activity.id.toString() === activityId)
                                    return {
                                        ...activity,
                                        lastLaunched: lastTriedData.toISOString(),
                                    };
                                return activity;
                            });
                            return behavior;
                        });
                    }
                    return behavior;
                });
                userSkillData.userBehaviors = {
                    ...userSkillData?.userBehaviors,
                    behaviors: userBehaviors?.behaviors,
                };
                userSkillData.producerBehaviors = {
                    ...userSkillData?.producerBehaviors,
                    behaviors: producerBehaviors?.behaviors,
                };
                userSkillData.masterBehaviors = {
                    ...userSkillData?.masterBehaviors,
                    behaviors: masterBehaviors?.behaviors,
                };
                return userSkillData;
            });
        },
        [activities]
    );

    const showAllActivitiesHandler = () => {
        setSelectedBehavior(null);
    };

    const sendContentUsageRequest: (relatedId?: number) => Promise<void> = async (relatedId) => {
        if (!relatedId) return;
        try {
            await mutatePostUserContentTrackingAsync({
                contentType: EUserContentTrackingType.SKILL_OVERVIEW_VIDEO,
                relatedId,
            });
        } catch (e) {
            console.error(e);
        }
    };

    const skillContext: ISkillContext = {
        skillData: userSkillData,
        isLoadingSkillData: userSkillLoading || isUserSkillDataRefetching,
        refetchSkillData: refetchGetUserSkill,
        userSkillProgressData: userSkillProgressData,
        isUserSkillProgressLoading: userSkillProgressLoading || isUserSkillProgressRefetching,
        refetchUserSkillProgress: refetchUserSkillProgress,
        selectedBehavior,
        setSelectedBehavior,
        selectedBehaviorLevel,
        setSelectedBehaviorLevel,
        activities,
        setActivities,
        toggleUserFavorite,
        toggleUserAssignment,
        mutatePostFavoriteLoading,
        mutatePostAssignLoading,
        toggleUserMaster,
        isErrorFetchUserSkillProgress,
        isErrorFetchUserSkillData,
        updateActivityLastTriedData,
        totalNumberOfActivities,
        showAllActivitiesHandler,
        sendContentUsageRequest,
    };

    return <SkillContext.Provider value={skillContext}>{children}</SkillContext.Provider>;
};

export const useSkillStateValue: () => ISkillContext = () => useContext(SkillContext);
