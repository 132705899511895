import { Typography } from '@mui/material';
import styled from '../../../../../ui/theme/styled';

export const TableWrapper = styled('div')({
    width: '100%',
});

export const ToolbarBox = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    width: '100%',
    padding: '14px 26px 14px 16px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.16)',
}));

export const ToolbarTitleNameTypography = styled(Typography)({
    fontSize: '18px',
    color: 'rgba(0, 0, 0, 0.88)',
    paddingRight: '8px',
});

export const ToolbarTitleValueTypography = styled(Typography)(({ theme }) => ({
    fontSize: '18px',
    color: theme.palette.primary.main,
}));
