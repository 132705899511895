import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

export const RootBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    '&:not(:last-child)': {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    background: 'linear-gradient(180deg, #FFFFFF 0%, #FAFAFA 100%), #FFFFFF',
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.08), 0px 0.5px 1.5px rgba(0, 0, 0, 0.08)',
    height: '100%',
    borderRadius: theme.borderRadius?.outside?.small,
}));

export const TitleBox = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    padding: '16px 24px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
}));

export const TitleTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.gray.dark,
    [theme.breakpoints.down('lg')]: {
        fontWeight: 500,
        fontSize: '14px',
    },
}));

export const ContentBox = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    gap: 23,
    padding: 20,
    height: '100%',
    [theme.breakpoints.down('lg')]: {
        alignItems: 'stretch',
    },
    [theme.breakpoints.down(840)]: {
        flexDirection: 'column',
    },
}));

export const RecommendationCardItemWrapper = styled('div', {
    shouldForwardProp: (prop) => prop !== 'isVisible',
})<{ isVisible: boolean }>(({ isVisible, theme }) => ({
    flexWrap: 'nowrap',
    flex: 1,
    display: isVisible ? 'flex' : 'none',
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.08), 0px 0.5px 1.5px rgba(0, 0, 0, 0.08)',
    [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
    },
    borderRadius: theme.borderRadius?.inside?.large,
}));
