import { Typography } from '@mui/material';
import { styled } from '@mui/system';

export const CategoryBox = styled('a')({
    display: 'grid',
    gridTemplateColumns: '196px 1fr',
    cursor: 'pointer',
    padding: '2px',
    '&:hover': {
        boxShadow: '0px 0px 6px #654ea3, 0px 0px 4px #654ea3',
    },
});

export const CategoryImage = styled('img')({
    width: '180px',
    height: '130px',
    visibility: 'hidden',
});

export const CategoryInfo = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
});

export const CategoryInfoTitleTypography = styled(Typography)({});

export const CategoryInfoDescriptionTypography = styled(Typography)({
    display: '-webkit-box',
    '-webkit-line-clamp': '3',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
});
