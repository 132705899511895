import { FC } from 'react';

const PipsUnasigned: FC = () => {
    return (
        <svg width="80" height="80" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M42 84C65.196 84 84 65.196 84 42C84 18.804 65.196 0 42 0C18.804 0 0 18.804 0 42C0 65.196 18.804 84 42 84Z"
                fill="white"
            />
            <g clipPath="url(#clip0_1421_6973)">
                <path
                    opacity="0.993"
                    d="M42.0822 83.7542C65.1876 83.7542 83.9183 65.0236 83.9183 41.9181C83.9183 18.8127 65.1876 0.0820312 42.0822 0.0820312C18.9768 0.0820312 0.246094 18.8127 0.246094 41.9181C0.246094 65.0236 18.9768 83.7542 42.0822 83.7542Z"
                    fill="url(#paint0_linear_1421_6973)"
                />
                <path
                    opacity="0.993"
                    d="M41.5905 77.1614C61.1203 77.1614 76.9524 61.3293 76.9524 41.7994C76.9524 22.2696 61.1203 6.4375 41.5905 6.4375C22.0606 6.4375 6.22852 22.2696 6.22852 41.7994C6.22852 61.3293 22.0606 77.1614 41.5905 77.1614Z"
                    fill="#579CE7"
                />
                <path
                    opacity="0.993"
                    d="M41.183 74.4949C59.1966 74.4949 73.7996 59.8919 73.7996 41.8783C73.7996 23.8647 59.1966 9.26172 41.183 9.26172C23.1693 9.26172 8.56641 23.8647 8.56641 41.8783C8.56641 59.8919 23.1693 74.4949 41.183 74.4949Z"
                    fill="#6FAEEE"
                    fillOpacity="0.9725"
                />
                <path
                    opacity="0.993"
                    d="M41.3037 70.3535C57.3259 70.3535 70.3144 57.365 70.3144 41.3428C70.3144 25.3206 57.3259 12.332 41.3037 12.332C25.2815 12.332 12.293 25.3206 12.293 41.3428C12.293 57.365 25.2815 70.3535 41.3037 70.3535Z"
                    fill="#98C5F3"
                />
                <path
                    opacity="0.993"
                    d="M41.427 67.4869C56.1819 67.4869 68.1431 55.5257 68.1431 40.7708C68.1431 26.0159 56.1819 14.0547 41.427 14.0547C26.6721 14.0547 14.7109 26.0159 14.7109 40.7708C14.7109 55.5257 26.6721 67.4869 41.427 67.4869Z"
                    fill="#B8D7F7"
                />
                <path
                    opacity="0.993"
                    d="M41.5137 65.3906C55.4083 65.3906 66.672 54.4137 66.672 40.873C66.672 27.3324 55.4083 16.3555 41.5137 16.3555C27.6192 16.3555 16.3555 27.3324 16.3555 40.873C16.3555 54.4137 27.6192 65.3906 41.5137 65.3906Z"
                    fill="white"
                />
                <path
                    opacity="0.993"
                    d="M34.636 63.5986C34.636 63.684 28.0581 65.478 27.9727 65.5207C27.8873 65.5207 21.3521 67.4855 21.3094 67.4001C21.2667 67.3574 22.9752 60.7368 22.9752 60.6513C22.9752 60.5659 24.5983 53.9453 24.6837 53.9453C24.7692 53.9453 29.6385 58.7292 29.6812 58.7719C29.6812 58.8574 34.636 63.5559 34.636 63.5986Z"
                    fill="white"
                />
                <path
                    opacity="0.993"
                    d="M30.5945 45.9351C32.7339 45.9351 34.4683 44.2007 34.4683 42.0613C34.4683 39.9219 32.7339 38.1875 30.5945 38.1875C28.4551 38.1875 26.7207 39.9219 26.7207 42.0613C26.7207 44.2007 28.4551 45.9351 30.5945 45.9351Z"
                    fill="#B8D7F7"
                />
                <path
                    opacity="0.993"
                    d="M40.9929 45.9351C43.1324 45.9351 44.8667 44.2007 44.8667 42.0613C44.8667 39.9219 43.1324 38.1875 40.9929 38.1875C38.8535 38.1875 37.1191 39.9219 37.1191 42.0613C37.1191 44.2007 38.8535 45.9351 40.9929 45.9351Z"
                    fill="#B8D7F7"
                />
                <path
                    opacity="0.993"
                    d="M51.4324 45.7671C53.5718 45.7671 55.3062 44.0327 55.3062 41.8933C55.3062 39.7539 53.5718 38.0195 51.4324 38.0195C49.2929 38.0195 47.5586 39.7539 47.5586 41.8933C47.5586 44.0327 49.2929 45.7671 51.4324 45.7671Z"
                    fill="#B8D7F7"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_1421_6973"
                    x1="32.5669"
                    y1="-20.6502"
                    x2="-20.7737"
                    y2="51.4584"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#0187EF" stopOpacity="0.985" />
                    <stop offset="1" stopColor="#654EA3" stopOpacity="0.9412" />
                </linearGradient>
                <clipPath id="clip0_1421_6973">
                    <rect width="84" height="84" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default PipsUnasigned;
