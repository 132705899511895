import { getTokenAndHeaders } from './helpers/apiUtil';
import useAuthParams from '../hooks/useAuthParams';
import { IAuthParams } from '../interfaces/IAuthParams';
import { apiInstance } from './helpers/apiInstance';
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { ISendFeedbackRequestDTO } from '../interfaces/dtos/ISendFeedbackRequestDTO';

// POST FEEDBACK
export const postSendFeedback: (
    variables: ISendFeedbackRequestDTO & { authParams: IAuthParams }
) => Promise<undefined> = async (variables) => {
    const { authParams, ...sendFeedbackRequestData } = variables;
    const { headers } = await getTokenAndHeaders(authParams);

    const {
        data: { data },
    } = await apiInstance.post(`feedback`, sendFeedbackRequestData, headers);
    return data;
};

export const usePostSendFeedback: () => UseMutationResult<undefined, Error, ISendFeedbackRequestDTO> = () => {
    const authParams = useAuthParams();
    return useMutation({
        mutationFn: (variables: ISendFeedbackRequestDTO) => {
            return postSendFeedback({ ...variables, authParams });
        },
    });
};
