import { useQuery, UseQueryResult } from '@tanstack/react-query';
import useAuthParams from '../hooks/useAuthParams';
import { IAuthParams } from '../interfaces/IAuthParams';
import { IInsightsPersonalSkillVM } from '../interfaces/views/IInsightsPersonalSkillVM';
import { IInsightsPersonalOutcomeVM } from '../interfaces/views/IInsightsPersonalOutcomeVM';
import { getTokenAndHeaders } from './helpers/apiUtil';
import { apiInstance } from './helpers/apiInstance';
import { IInsightsPersonalVM } from '../interfaces/views/IInsightsPersonalVM';
import {
    mapInsightsOrganizationalBadges,
    mapInsightsOrganizationalUserBadgesData,
    mapInsightsOrganizationalUserOutcomesData,
    mapInsightsOrganizationalUsersForBadges,
    mapInsightsOrganizationalUserSkillsData,
    mapInsightsOrganizationBadges,
    mapInsightsOrganizationEngagement,
    mapInsightsOrganizationOutcomesData,
    mapInsightsOrganizationOutcomesSkills,
    mapInsightsOrganizationOutcomesUsers,
    mapInsightsOrganizationSkillData,
    mapInsightsOrganizationUsersCompetenceData,
    mapInsightsPersonalData,
    mapInsightsPersonalEngagementPoints,
    mapInsightsPersonalLoggedIn,
    mapInsightsUserBadges,
    mapInsightsUserOutcomes,
    mapInsightsUserSkills,
} from './helpers/mappers';
import { IInsightsPersonalBadgeVM } from '../interfaces/views/IInsightsPersonalBadgeVM';
import { IInsightsOrganizationUsersCompetenceVM } from '../interfaces/views/IInsightsOrganizationUsersCompetenceVM';
import { IInsightsOrganizationEngagementVM } from '../interfaces/views/IInsightsOrganizationEngagementVM';
import { IInsightsOrganizationBadgesVM } from '../interfaces/views/IInsightsOrganizationBadgesVM';
import { IInsightsOrganizationOutcomeVM } from '../interfaces/views/IInsightsOrganizationOutcomeVM';
import { IInsightsOrganizationOutcomesUserVM } from '../interfaces/views/IInsightsOganizationOutcomesUserVM';
import { IInsightsOrganizationOutcomesSkillVM } from '../interfaces/views/IInsightsOrganizationOutcomesSkillVM';
import { IInsightsOrganizationalUserBadgeResponseVM } from '../interfaces/views/IInsightsOrganizationalUserBadgeVM';
import { IInsightsOrganizationUserSkillResponseVM } from '../interfaces/views/IInsightsOrganizationalUserSkillVM';
import { IInsightsOrganizationalUserOutcomeResponseVM } from '../interfaces/views/IInsightsOrganizationalUserOutcomeVM';
import {
    IInsightsPersonalEngagementPointResponseVM,
    IInsightsPersonalEngagementPointVM,
} from '../interfaces/views/IInsightsPersonalEngagementPointVM';
import { ILoggedInResponseVM, ILoggedInVM } from '../interfaces/views/ILoggedInVM';
import { IInsightsOrganizationSkillVM } from '../interfaces/IInsightsOrganizationSkillVM';
import { IOrganizationalBadgeVM } from '../interfaces/views/IOrganizationalBadgeVM';
import { IOrganizationalUserForBadgeVM } from '../interfaces/views/IOrganizationalUserForBadgeVM';
import { EApiQueryKey } from '../interfaces/enums/EApiQueryKey';

export interface IGetOutcomesUsersRequest {
    outcomeId: string;
}

export interface IGetOutcomesSkillsRequest {
    outcomeId: string;
}

// GET INSIGHTS PERSONAL DATA
export const getInsightsPersonalData = async (authParams: IAuthParams) => {
    const { headers } = await getTokenAndHeaders(authParams);
    const response = await apiInstance.get(`insights/userSummaries`, headers);
    return mapInsightsPersonalData(response.data);
};

export const useGetInsightsPersonalDataQuery: () => UseQueryResult<IInsightsPersonalVM[], Error> = () => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.INSIGHTS_FETCH_PERSONAL_DATA],
        queryFn: async () => {
            return getInsightsPersonalData(authParams);
        },
        refetchOnWindowFocus: false,
        enabled: false,
    });
};

// GET INSIGHTS PERSONAL SKILLS
export const getInsightsPersonalSkills = async (authParams: IAuthParams) => {
    const { headers } = await getTokenAndHeaders(authParams);
    const response = await apiInstance.get(`insights/userSkills`, headers);
    return mapInsightsUserSkills(response.data.data.userSkillInsights);
};

export const useGetInsightsPersonalSkillsQuery: () => UseQueryResult<IInsightsPersonalSkillVM[], Error> = () => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.INSIGHTS_FETCH_PERSONAL_SKILL],
        queryFn: async () => {
            return getInsightsPersonalSkills(authParams);
        },
        refetchOnWindowFocus: false,
        enabled: false,
    });
};

// GET INSIGHTS PERSONAL OUTCOMES
export const getInsightsPersonalOutcomes = async (authParams: IAuthParams) => {
    const { headers } = await getTokenAndHeaders(authParams);
    const { data } = await apiInstance.get(`insights/userOutcomes`, headers);
    return mapInsightsUserOutcomes(data.data.userOutcomeInsights);
};

export const useGetInsightsPersonalOutcomesQuery: () => UseQueryResult<IInsightsPersonalOutcomeVM[], Error> = () => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.INSIGHTS_FETCH_PERSONAL_OUTCOMES],
        queryFn: async () => {
            return getInsightsPersonalOutcomes(authParams);
        },
        refetchOnWindowFocus: false,
        enabled: false,
    });
};

// GET INSIGHTS PERSONAL BADGES
export const getInsightsPersonalBadges = async (authParams: IAuthParams) => {
    const { headers } = await getTokenAndHeaders(authParams);
    const { data } = await apiInstance.get(`insights/userBadges`, headers);
    return mapInsightsUserBadges(data.data.userBadgeInsights);
};

export const useGetInsightsPersonalBadgesQuery: () => UseQueryResult<IInsightsPersonalBadgeVM[], Error> = () => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.INSIGHTS_FETCH_PERSONAL_BADGES],
        queryFn: async () => {
            return getInsightsPersonalBadges(authParams);
        },
        refetchOnWindowFocus: false,
        enabled: false,
    });
};

// GET INSIGHTS ORGANIZATION USERS COMPETENCE
export const getInsightsOrganizationUsersCompetence = async (authParams: IAuthParams) => {
    const { headers } = await getTokenAndHeaders(authParams);
    const response = await apiInstance.get(`insights/organizationUserCompetence`, headers);
    return mapInsightsOrganizationUsersCompetenceData(response.data);
};

export const useGetInsightsOrganizationUsersCompetenceQuery: () => UseQueryResult<
    IInsightsOrganizationUsersCompetenceVM[],
    Error
> = () => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.INSIGHTS_FETCH_ORGANIZATION_USERS_COMPETENCE],
        queryFn: async () => {
            return getInsightsOrganizationUsersCompetence(authParams);
        },
        refetchOnWindowFocus: false,
    });
};

// GET INSIGHTS ORGANIZATION OUTCOMES
export const getInsightsOrganizationOutcomes = async (authParams: IAuthParams) => {
    const { headers } = await getTokenAndHeaders(authParams);
    const response = await apiInstance.get(`insights/organizationOutcomes`, headers);
    return mapInsightsOrganizationOutcomesData(response.data);
};

export const useGetInsightsOrganizationOutcomesQuery: () => UseQueryResult<
    IInsightsOrganizationOutcomeVM[],
    Error
> = () => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.INSIGHTS_FETCH_ORGANIZATION_OUTCOMES],
        queryFn: async () => {
            return getInsightsOrganizationOutcomes(authParams);
        },
        refetchOnWindowFocus: false,
    });
};

// GET INSIGHTS ORGANIZATION ENGAGEMENT POINTS
export const getInsightsOrganizationEngagementPoints = async (authParams: IAuthParams) => {
    const { headers } = await getTokenAndHeaders(authParams);
    const response = await apiInstance.get(`insights/organizationUserEngagement`, headers);
    return mapInsightsOrganizationEngagement(response.data);
};

export const useGetInsightsOrganizationEngagementPointsQuery: () => UseQueryResult<
    IInsightsOrganizationEngagementVM[],
    Error
> = () => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.INSIGHTS_FETCH_ORGANIZATION_ENGAGEMENT_POINTS],
        queryFn: async () => {
            return getInsightsOrganizationEngagementPoints(authParams);
        },
        refetchOnWindowFocus: false,
    });
};

// GET INSIGHTS ORGANIZATION BADGES
export const getInsightsOrganizationBadges = async (authParams: IAuthParams) => {
    const { headers } = await getTokenAndHeaders(authParams);
    const response = await apiInstance.get(`insights/organizationUserBadges`, headers);
    return mapInsightsOrganizationBadges(response.data);
};

export const useGetInsightsOrganizationBadgesQuery: () => UseQueryResult<
    IInsightsOrganizationBadgesVM[],
    Error
> = () => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.INSIGHTS_FETCH_ORGANIZATION_BADGES],
        queryFn: async () => {
            return getInsightsOrganizationBadges(authParams);
        },
        refetchOnWindowFocus: false,
    });
};

// GET INSIGHTS ORGANIZATION OUTCOMES USERS
export const getInsightsOrganizationOutcomesUsers = async (authParams: IAuthParams, outcomeId?: string) => {
    const { headers } = await getTokenAndHeaders(authParams);
    const response = await apiInstance.get(`insights/organizationOutcomeUsers/${outcomeId}`, headers);
    return mapInsightsOrganizationOutcomesUsers(response.data);
};

export const useGetInsightsOrganizationOutcomesUsersQuery: (
    outcomeId?: string
) => UseQueryResult<IInsightsOrganizationOutcomesUserVM[], Error> = (outcomeId) => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.INSIGHTS_FETCH_ORGANIZATION_OUTCOMES_USERS],
        queryFn: async () => {
            return getInsightsOrganizationOutcomesUsers(authParams, outcomeId);
        },
        refetchOnWindowFocus: false,
    });
};

// GET INSIGHTS ORGANIZATION OUTCOMES SKILLS
export const getInsightsOrganizationOutcomesSkills = async (authParams: IAuthParams, outcomeId?: string) => {
    const { headers } = await getTokenAndHeaders(authParams);
    const response = await apiInstance.get(`insights/organizationOutcomeSkills/${outcomeId}`, headers);
    return mapInsightsOrganizationOutcomesSkills(response.data);
};

export const useGetInsightsOrganizationOutcomesSkillsQuery: (
    outcomeId?: string
) => UseQueryResult<IInsightsOrganizationOutcomesSkillVM[], Error> = (outcomeId) => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.INSIGHTS_FETCH_ORGANIZATION_OUTCOMES_SKILLS],
        queryFn: async () => {
            return getInsightsOrganizationOutcomesSkills(authParams, outcomeId);
        },
        refetchOnWindowFocus: false,
    });
};

// GET INSIGHTS ORGANIZATION SKILL DETAILS
export const getInsightsOrganizationSkill = async (authParams: IAuthParams, outcomeId?: string, skillId?: string) => {
    const { headers } = await getTokenAndHeaders(authParams);
    const response = await apiInstance.get(`insights/organizationOutcomeSkills/${outcomeId}/skill/${skillId}`, headers);
    return mapInsightsOrganizationSkillData(response.data);
};

export const useGetInsightsOrganizationSkillQuery: (
    outcomeId?: string,
    skillId?: string
) => UseQueryResult<IInsightsOrganizationSkillVM[], Error> = (outcomeId, skillId) => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.INSIGHTS_FETCH_ORGANIZATION_SKILL],
        queryFn: async () => {
            return getInsightsOrganizationSkill(authParams, outcomeId, skillId);
        },
        refetchOnWindowFocus: false,
    });
};

// GET INSIGHTS ORGANIZATION USER SUMMARY
export const getInsightsOrganizationalUserSummaryData = async (authParams: IAuthParams, userId?: string) => {
    const { headers } = await getTokenAndHeaders(authParams);
    const response = await apiInstance.get(`insights/userSummaries/${userId}`, headers);
    return mapInsightsPersonalData(response.data);
};

export const useGetInsightsOrganizationaUserSummaryDataQuery: (
    userId?: string
) => UseQueryResult<IInsightsPersonalVM[], Error> = (userId) => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.INSIGHTS_FETCH_ORGANIZATION_USER_SUMMARIES],
        queryFn: async () => {
            return getInsightsOrganizationalUserSummaryData(authParams, userId);
        },
        refetchOnWindowFocus: false,
        enabled: false,
    });
};

// GET INSIGHTS ORGANIZATION USER BADGES
export const getInsightsOrganizationUserBadges = async (authParams: IAuthParams, userId?: string) => {
    const { headers } = await getTokenAndHeaders(authParams);
    const { data } = await apiInstance.get(`insights/userBadges/${userId}`, headers);
    return {
        userId: data.data.userId,
        userName: data.data.userName,
        data: mapInsightsOrganizationalUserBadgesData(data?.data?.userBadgeInsights),
    };
};

export const useGetInsightsOrganizationUserBadgesQuery: (
    userId?: string
) => UseQueryResult<IInsightsOrganizationalUserBadgeResponseVM, Error> = (userId) => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.INSIGHTS_FETCH_ORGANIZATION_USER_BADGES],
        queryFn: async () => {
            return getInsightsOrganizationUserBadges(authParams, userId);
        },
        refetchOnWindowFocus: false,
        enabled: false,
    });
};

// GET INSIGHTS ORGANIZATION USER OUTCOMES
export const getInsightsOrganizationUserOutcomes = async (authParams: IAuthParams, userId?: string) => {
    const { headers } = await getTokenAndHeaders(authParams);
    const { data } = await apiInstance.get(`insights/userOutcomes/${userId}`, headers);
    return {
        userId: data.data?.userId,
        userName: data?.data?.userName,
        data: mapInsightsOrganizationalUserOutcomesData(data.data?.userOutcomeInsights),
    };
};

export const useGetInsightsOrganizationUserOutcomesQuery: (
    userId?: string
) => UseQueryResult<IInsightsOrganizationalUserOutcomeResponseVM, Error> = (userId) => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.INSIGHTS_FETCH_ORGANIZATION_USER_OUTCOMES],
        queryFn: async () => {
            return getInsightsOrganizationUserOutcomes(authParams, userId);
        },
        refetchOnWindowFocus: false,
        enabled: false,
    });
};

// GET INSIGHTS ORGANIZATION USER SKILLS
export const getInsightsOrganizationUserSkills = async (authParams: IAuthParams, userId?: string) => {
    const { headers } = await getTokenAndHeaders(authParams);
    const response = await apiInstance.get(`insights/userSkills/${userId}`, headers);
    return {
        userId: response.data?.data.userId,
        userName: response.data?.data.userName,
        data: mapInsightsOrganizationalUserSkillsData(response.data?.data.userSkillInsights),
    };
};

export const useGetInsightsOrganizationUserSkillsQuery: (
    userId?: string
) => UseQueryResult<IInsightsOrganizationUserSkillResponseVM, Error> = (userId) => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.INSIGHTS_FETCH_ORGANIZATION_USER_SKILLS],
        queryFn: async () => {
            return getInsightsOrganizationUserSkills(authParams, userId);
        },
        refetchOnWindowFocus: false,
        enabled: false,
    });
};

// GET INSIGHTS PERSONAL ENGAGEMENT POINTS
export const getInsightsPersonalEngagementPoints = async (authParams: IAuthParams) => {
    const { headers } = await getTokenAndHeaders(authParams);
    const response = await apiInstance.get(`insights/userEngagementPoints`, headers);
    return mapInsightsPersonalEngagementPoints(response.data.data.userEngagementPointsInsights);
};

export const useGetInsightsPersonalEngagementPointsQuery: () => UseQueryResult<
    IInsightsPersonalEngagementPointVM[],
    Error
> = () => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.INSIGHTS_FETCH_PERSONAL_ENGAGEMENT_POINTS],
        queryFn: async () => {
            return getInsightsPersonalEngagementPoints(authParams);
        },
        refetchOnWindowFocus: false,
        enabled: false,
    });
};

// GET INSIGHTS PERSONAL LOGGED IN POINTS
export const getInsightsPersonalLoggedIn = async (authParams: IAuthParams) => {
    const { headers } = await getTokenAndHeaders(authParams);
    const response = await apiInstance.get(`insights/userLogins`, headers);
    return mapInsightsPersonalLoggedIn(response.data.data.userLoginInsights);
};

export const useGetInsightsPersonalLoggedInQuery: () => UseQueryResult<ILoggedInVM[], Error> = () => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.INSIGHTS_FETCH_PERSONAL_LOGGED_IN],
        queryFn: async () => {
            return getInsightsPersonalLoggedIn(authParams);
        },
        refetchOnWindowFocus: false,
        enabled: false,
    });
};

// GET INSIGHTS USER ENGAGEMENT POINTS
export const getInsightsUserEngagementPoints = async (authParams: IAuthParams, userId?: string) => {
    const { headers } = await getTokenAndHeaders(authParams);
    const { data } = await apiInstance.get(`insights/userEngagementPoints/${userId}`, headers);
    return {
        userName: data.data.userName,
        userId: data.data.userId,
        data: mapInsightsPersonalEngagementPoints(data.data.userEngagementPointsInsights),
    };
};

export const useGetInsightsUserEngagementPointsQuery: (
    userId?: string
) => UseQueryResult<IInsightsPersonalEngagementPointResponseVM, Error> = (userId) => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.INSIGHTS_FETCH_ORGANIZATION_USER_ENGAGEMENT_POINTS],
        queryFn: async () => {
            return getInsightsUserEngagementPoints(authParams, userId);
        },
        refetchOnWindowFocus: false,
        enabled: false,
    });
};

// GET INSIGHTS USER LOGGED IN POINTS
export const getInsightsUserLoggedIn = async (authParams: IAuthParams, userId?: string) => {
    const { headers } = await getTokenAndHeaders(authParams);
    const { data } = await apiInstance.get(`insights/userLogins/${userId}`, headers);
    return {
        userId: data.data.userId,
        userName: data.data.userName,
        data: mapInsightsPersonalLoggedIn(data.data.userLoginInsights),
    };
};

export const useGetInsightsUserLoggedInQuery: (userId?: string) => UseQueryResult<ILoggedInResponseVM, Error> = (
    userId
) => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.INSIGHTS_FETCH_ORGANIZATION_USER_LOGGED_IN],
        queryFn: async () => {
            return getInsightsUserLoggedIn(authParams, userId);
        },
        refetchOnWindowFocus: false,
        enabled: false,
    });
};

// GET INSIGHTS ORGANIZATIONAL BADGES
export const getInsightsOrganizationalBadges = async (authParams: IAuthParams) => {
    const { headers } = await getTokenAndHeaders(authParams);
    const { data } = await apiInstance.get(`/insights/organizationCurrentBadges`, headers);
    return mapInsightsOrganizationalBadges(data.data.badges);
};

export const useGetInsightsOrganizationalBadgesQuery: () => UseQueryResult<IOrganizationalBadgeVM[], Error> = () => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.INSIGHTS_FETCH_ORGANIZATION_CURRENT_BADGES],
        queryFn: async () => {
            return getInsightsOrganizationalBadges(authParams);
        },
        refetchOnWindowFocus: false,
    });
};

// GET INSIGHTS ORGANIZATIONAL BADGES
export const getInsightsOrganizationalUsersForBadge = async (
    authParams: IAuthParams,
    outcomeId?: string,
    level?: string
) => {
    const { headers } = await getTokenAndHeaders(authParams);
    const { data } = await apiInstance.get(`insights/organizationUsersForBadge/${outcomeId}/${level}`, headers);
    return mapInsightsOrganizationalUsersForBadges(data);
};

export const useGetInsightsOrganizationalUsersForBadgeQuery: (
    outcomeId?: string,
    level?: string
) => UseQueryResult<IOrganizationalUserForBadgeVM[], Error> = (outcomeId, level) => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.INSIGHTS_FETCH_ORGANIZATION_USERS_FOR_BADGE],
        queryFn: async () => {
            return getInsightsOrganizationalUsersForBadge(authParams, outcomeId, level);
        },
        refetchOnWindowFocus: false,
        enabled: false,
    });
};
