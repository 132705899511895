import { CSSProperties, FC, KeyboardEvent } from 'react';
import Loading from '../loading/Loading';
import { CircleSpan, IconNumberWrapper, LabelBox, RootBox, ValueSpan } from './Style';
import { ICrumb } from '../../../interfaces/ICrumb';

export interface INumberStatusBox {
    elementId: string;
    style?: CSSProperties;
    label: string;
    value?: number | string | null;
    icon?: React.ReactNode;
    circleColor?: string;
    clickHandler?: (elementId: string) => void;
    isActive?: boolean;
    isLoading?: boolean;
    isClickable?: boolean;
    labelPaddingBottom?: string;
    crumb?: ICrumb;
    labelStyle?: CSSProperties;
}

const NumberStatusBox: FC<INumberStatusBox> = ({
    label,
    value,
    icon,
    circleColor,
    style,
    elementId,
    clickHandler,
    isActive,
    isLoading,
    isClickable = true,
    labelPaddingBottom,
    labelStyle,
}) => {
    const handleClick = () => {
        if (clickHandler) {
            clickHandler(elementId);
        }
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter' && clickHandler) {
            clickHandler(elementId);
        }
    };

    return (
        <RootBox
            tabIndex={0}
            style={style}
            onKeyDown={handleKeyDown}
            onClick={handleClick}
            isActive={!!isActive}
            isClickable={isClickable}
            className="number-status-box-root"
        >
            <IconNumberWrapper>
                {icon ? icon : <CircleSpan color={circleColor}></CircleSpan>}
                <ValueSpan>
                    {isLoading ? (
                        <Loading style={{ width: '20px', height: '20px' }} boxStyle={{ height: 20 }} />
                    ) : value === null || value === undefined ? (
                        '-'
                    ) : (
                        value
                    )}
                </ValueSpan>
            </IconNumberWrapper>
            <LabelBox paddingBottom={labelPaddingBottom} style={labelStyle}>
                {label}
            </LabelBox>
        </RootBox>
    );
};

export default NumberStatusBox;
