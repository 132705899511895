import { SelectChangeEvent } from '@mui/material';
import { useEffect, useState } from 'react';

interface IDropdownReturn {
    value: string;
    handleFilterDropdownChange: (event: SelectChangeEvent<unknown>) => void;
    changeFilterDropdownValue: (option: string) => void;
}

interface IDropdownProps {
    initialValue?: string;
}

const useDropdown: (props: IDropdownProps) => IDropdownReturn = (props) => {
    const { initialValue } = props;
    const [value, setValue] = useState<string>('');

    useEffect(() => {
        if (initialValue) setValue(initialValue);
    }, [initialValue]);

    const handleFilterDropdownChange = (event: SelectChangeEvent<unknown>) => {
        const newValue = event.target.value as string;
        setValue(newValue);
    };

    const changeFilterDropdownValue = (option: string) => {
        setValue(option);
    };

    return {
        value,
        handleFilterDropdownChange,
        changeFilterDropdownValue,
    } as const;
};

export default useDropdown;
