import { Modal } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CloseOutlined } from '@material-ui/icons';
import { useNavigate } from 'react-router';
import { BadgeImg, StyledModalBox, ActionButtonsBox } from './Style';
import PrimaryButton from '../../../ui/buttons/primary-button/PrimaryButton';
import SecondaryButton from '../../../ui/buttons/secondary-button/SecondaryButton';
import { createUrl } from '../../../utils/createUrl';
import { ESkillLevel } from '../../../interfaces/enums/ESkillLevel';
import { makeBadgeSharingInstructionRoute } from '../../../pages/routes';
import { useCrumbsStateValue } from '../../../contexts/CrumbsContext';
import { EShowcaseBadgeLevel } from '../../../interfaces/enums/EShowcaseBadgeLevel';
import { ELinkedinRedirectUrl } from '../../../interfaces/enums/ELinkedinRedirectUrl';
import fetchImageErrorCallback from '../../../utils/fetchImageErrorCallback';

interface IProps {
    handleClose: () => void;
    badgeImage: string;
    level: ESkillLevel | EShowcaseBadgeLevel;
    outcomeId?: number;
    userBadgeUrl?: string;
    calledFrom: ELinkedinRedirectUrl;
}

const ShareBadgeModal: FC<IProps> = ({ handleClose, badgeImage, outcomeId, level, userBadgeUrl, calledFrom }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { appendCrumb } = useCrumbsStateValue();

    const imageLevelMemo = useMemo(() => {
        return createUrl(badgeImage)?.generatedUrl;
    }, [badgeImage]);

    const shareBadgeOnLinkedinClickHandler = useCallback(() => {
        let levelValue = 0;
        if (level === ESkillLevel.MASTER) levelValue = 3;
        else levelValue = 2;
        if (levelValue !== 0) {
            window.open(
                `https://www.linkedin.com/oauth/v2/authorization?response_type=code` +
                    `&client_id=${encodeURIComponent(process.env.REACT_APP_LINKEDIN_AUTH_CLIENT_ID as string)}` +
                    `&state=${levelValue}-${outcomeId}-${calledFrom}` +
                    `&redirect_uri=${encodeURIComponent(`${window.location.origin}/linkedin`)}` +
                    `&scope=${encodeURIComponent('r_liteprofile w_member_social')}`,
                '_self'
            );
            handleClose();
        }
    }, [outcomeId, level, calledFrom]);

    const addCertificationClickHandler = useCallback(() => {
        if (userBadgeUrl) {
            const instructionsUrl = makeBadgeSharingInstructionRoute(userBadgeUrl);
            appendCrumb({
                name: 'Outcome',
                pathname: instructionsUrl,
            });
            navigate(instructionsUrl);
        }
    }, [userBadgeUrl]);

    return (
        <div>
            <Modal
                open
                onClose={handleClose}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <StyledModalBox>
                    <CloseOutlined
                        style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '10px' }}
                        onClick={handleClose}
                    />
                    <BadgeImg src={imageLevelMemo} onError={fetchImageErrorCallback} />
                    <ActionButtonsBox>
                        <SecondaryButton onClick={shareBadgeOnLinkedinClickHandler} style={{ width: '100%' }}>
                            {t('components.shareBadgeModal.postOnLinkedin')}
                        </SecondaryButton>
                        <PrimaryButton
                            title={t('components.shareBadgeModal.addCertification')}
                            clickHandler={addCertificationClickHandler}
                        />
                    </ActionButtonsBox>
                </StyledModalBox>
            </Modal>
        </div>
    );
};

export default ShareBadgeModal;
