import { FC } from 'react';

interface IProps {
    width?: string;
    height?: string;
}

const GoldMedal: FC<IProps> = ({ width, height }) => {
    return (
        <svg
            width={width || '16'}
            height={height || '26'}
            viewBox="0 0 16 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1 19C1 22.86 4.14 26 8 26C11.86 26 15 22.86 15 19C15 15.14 11.86 12 8 12C4.14 12 1 15.14 1 19Z"
                fill="#EBA828"
            />
            <path
                d="M4.50104 0.5H0.501035C0.331035 0.5 0.171035 0.585 0.0810353 0.73C-0.0089647 0.87 -0.0239647 1.05 0.0460353 1.205L4.61604 11.255C4.84604 11.145 5.08604 11.045 5.33104 10.96L7.45104 6.285L4.95604 0.795C4.87604 0.615 4.69604 0.5 4.50104 0.5Z"
                fill="#349FEE"
            />
            <path
                d="M15.92 0.73C15.83 0.585 15.67 0.5 15.5 0.5H11.5C11.305 0.5 11.125 0.615 11.045 0.795L6.57495 10.63C7.03495 10.545 7.51495 10.5 7.99995 10.5C9.20995 10.5 10.355 10.77 11.385 11.255L15.955 1.205C16.025 1.05 16.01 0.87 15.92 0.73Z"
                fill="#FE6C6C"
            />
        </svg>
    );
};

export default GoldMedal;
