import { Typography } from '@mui/material';
import styled from '../../../../../ui/theme/styled';

export const RootBox = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
});

export const HeaderActionBox = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('lg')]: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
    },
    [theme.breakpoints.down('md')]: {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
    },
}));

export const FiltersBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flex: 5,
    gap: '48px',
    flexWrap: 'wrap',

    [theme.breakpoints.down('lg')]: {
        gap: '8px',
        flexDirection: 'column',

        '& .MuiFormControl-root': {
            width: '100%',
            maxWidth: 'unset !important',
        },
    },

    '& .MuiFormControl-root': {
        maxWidth: '200px',
    },
}));

export const DownloadReportBox = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    flex: 2,

    [theme.breakpoints.down('lg')]: {
        alignItems: 'center',
    },

    [theme.breakpoints.down('md')]: {
        justifyContent: 'flex-start',
    },

    '& .MuiButton-root': {
        maxHeight: '40px',
    },
}));

export const ScoreLegendBox = styled('div')({
    display: 'flex',
    flexDirection: 'column',
});

export const ScoreLegendItem = styled('div')({
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
});

export const ScoreLegendTypography = styled(Typography)({});

export const DotWrapper = styled('div')({
    display: 'flex',
});

export const Dot = styled('div')<{ big?: boolean; color?: string }>(({ theme, big = false, color }) => ({
    backgroundColor: color ? color : theme.palette.primary.main,
    height: big ? 10 : 2,
    width: big ? 10 : 2,
    borderRadius: big ? 1 : 0,
    marginRight: 1,
}));

export const AppsIconWrapper = styled('div')({
    display: 'flex',
    gap: '10px',
});

export const AppIconImg = styled('img')({
    width: 32,
    height: 32,
});
