import { createContext, FC, useContext, PropsWithChildren, useEffect, useState, useRef, useCallback } from 'react';
import { useTheme } from '@mui/material';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
    useGetInsightsOrganizationUserBadgesQuery,
    useGetInsightsPersonalBadgesQuery,
} from '../services/InsightsQueryService';
import useFilterSearch from '../hooks/useFilterSearch';
import { useInsightsPersonalStateValue } from './InsightsPersonalContext';
import { IScoreLine } from '../interfaces/IScoreLine';
import { EGraphCardSelect } from '../interfaces/enums/EGraphCardSelect';
import { IInsightsPersonalBadgeVM } from '../interfaces/views/IInsightsPersonalBadgeVM';
import { IInsightsPersonalBadgeGraphVM } from '../interfaces/views/IInsightsPersonalBadgeGraphVM';
import { ESkillLevel } from '../interfaces/enums/ESkillLevel';
import { EToastSeverity, useToastContextStateValue } from './ToastContext';
import { usePostClaimMasterBadge, usePostClaimProducerBadge } from '../services/BadgeQueryService';
import { usePostDownloadBadge } from '../services/LicenseQueryService';
import { downloadFile } from '../utils/downloadFile';
import { useInsightsStateValue } from './InsightsContext';
import { EInsightsMode } from '../interfaces/enums/EInsightsMode';
import expandDatesToLastYearPerWeek from '../utils/expandDatesToLastYearPerWeek';

export interface IInsightsPersonalBadgesContext {
    data?: IInsightsPersonalBadgeVM[];
    graphData: IInsightsPersonalBadgeGraphVM[];
    searchText: string;
    setSearchText: (searchText: string) => void;
    scoreLines?: IScoreLine[];
    changeScoreLinesInterval: (option: EGraphCardSelect) => void;
    claimClickHandler: (outcomeId: number, level: ESkillLevel) => void;
    isPostClaimProducerBadgeLoading: boolean;
    isPostClaimMasterBadgeLoading: boolean;
    handleDownloadBadgeClick: (item: IInsightsPersonalBadgeVM) => void;
    isLoading: boolean;
    isDownloadBadgeActive: boolean;
    isErrorFetchBadgeData: boolean;
}

export const InsightsPersonalBadgesContext = createContext<IInsightsPersonalBadgesContext>(
    {} as IInsightsPersonalBadgesContext
);

export const InsightsPersonalBadgesProvider: FC<PropsWithChildren> = ({ children }) => {
    const { mode } = useInsightsStateValue();
    const { id: paramsId } = useParams<{ id?: string }>();
    const {
        data: fetchedData,
        isLoading: isLoadingPersonalBadges,
        refetch: refetchPersonalBadges,
        isError: isErrorFetchPersonalBadgeData,
    } = useGetInsightsPersonalBadgesQuery();
    const [data, setData] = useState<IInsightsPersonalBadgeVM[]>([]);
    const [filteredData, setFilteredData] = useState<IInsightsPersonalBadgeVM[] | undefined>();
    const [graphData, setGraphData] = useState<IInsightsPersonalBadgeGraphVM[]>([]);
    const [scoreLines, setScoreLines] = useState<IScoreLine[] | undefined>();
    const scoreLinesAllOptionsRef = useRef<IScoreLine[][]>([]);
    const { getInsightsPersonalBadges, data: graphTotalData } = useInsightsPersonalStateValue();
    const { setToastMessage } = useToastContextStateValue();
    const {
        mutateAsync: mutatePostClaimProducerBadgeAsync,
        error: errorPostClaimProducerBadge,
        isPending: isPostClaimProducerBadgeLoading,
    } = usePostClaimProducerBadge();
    const {
        mutateAsync: mutatePostClaimMasterBadgeAsync,
        error: errorPostClaimMasterBadge,
        isPending: isPostClaimMasterBadgeLoading,
    } = usePostClaimMasterBadge();
    const { t } = useTranslation();
    const { mutateAsync: mutatePostDownloadBadgeAsync } = usePostDownloadBadge();
    const [isDownloadBadgeActive, setDownloadBadgeActive] = useState<boolean>(false);
    const {
        refetch: refetchOrganizationUserBadges,
        data: fetchedOrganizitonUserBadgeData,
        isLoading: isLoadingOrganizationUserBadges,
        isError: isErrorFetchOrganizationUserBadgeData,
    } = useGetInsightsOrganizationUserBadgesQuery(paramsId);
    const { setUserName } = useInsightsStateValue();
    const theme = useTheme();

    useEffect(() => {
        if (fetchedData) {
            setData(fetchedData);
            setFilteredData(fetchedData);
        }
    }, [fetchedData]);

    useEffect(() => {
        if (fetchedOrganizitonUserBadgeData && fetchedOrganizitonUserBadgeData.data) {
            setData(fetchedOrganizitonUserBadgeData.data);
            setFilteredData(fetchedOrganizitonUserBadgeData.data);
            setUserName(fetchedOrganizitonUserBadgeData.userName);
        }
    }, [fetchedOrganizitonUserBadgeData]);

    useEffect(() => {
        if (paramsId) {
            refetchOrganizationUserBadges();
        } else {
            refetchPersonalBadges();
        }
    }, [paramsId]);

    useEffect(() => {
        if (errorPostClaimProducerBadge) {
            setToastMessage({
                isOpen: true,
                message: t('errors.badge.errorProducerBadgeClaim'),
                severity: EToastSeverity.ERROR,
            });
        }
    }, [errorPostClaimProducerBadge]);

    useEffect(() => {
        if (errorPostClaimMasterBadge) {
            setToastMessage({
                isOpen: true,
                message: t('errors.badge.errorMasterBadgeClaim'),
                severity: EToastSeverity.ERROR,
            });
        }
    }, [errorPostClaimMasterBadge]);

    useEffect(() => {
        if (graphTotalData && graphTotalData.length > 0) {
            const data = getInsightsPersonalBadges();
            setGraphData(data);

            const sharedScoreLine: IScoreLine = {
                id: 'W1',
                name: 'Shared',
                color: '#349FEE',
                scores: [],
            };
            const claimedScoreLine: IScoreLine = {
                id: 'W2',
                name: 'Claimed',
                color: theme.palette.status.attained,
                scores: [],
            };
            const readyToClaimScoreLine: IScoreLine = {
                id: 'W3',
                name: 'Ready to Claim',
                color: '#5C4F9C',
                scores: [],
            };
            const needAttentionScoreLine: IScoreLine = {
                id: 'W4',
                name: 'Need Attention',
                color: theme.palette.status.needAttention,
                legendColor: '#ED8000',
                scores: [],
            };

            data.forEach((dataItem) => {
                sharedScoreLine.scores.push({
                    date: dataItem.date,
                    value: dataItem.badgesShared,
                });
                claimedScoreLine.scores.push({
                    date: dataItem.date,
                    value: dataItem.badgesClaimed,
                });
                readyToClaimScoreLine.scores.push({
                    date: dataItem.date,
                    value: dataItem.badgesReady,
                });
                needAttentionScoreLine.scores.push({
                    date: dataItem.date,
                    value: dataItem.badgesNeedAttention,
                });
            });
            const allIndividualScoreTimes = [
                expandDatesToLastYearPerWeek(sharedScoreLine),
                expandDatesToLastYearPerWeek(claimedScoreLine),
                expandDatesToLastYearPerWeek(readyToClaimScoreLine),
                expandDatesToLastYearPerWeek(needAttentionScoreLine),
            ];
            const scoreLines4Month = allIndividualScoreTimes.map((scoreTime) => {
                return {
                    ...scoreTime,
                    scores: scoreTime.scores.slice(-15),
                };
            });
            setScoreLines(scoreLines4Month);

            scoreLinesAllOptionsRef.current = [scoreLines4Month, allIndividualScoreTimes];
        } else {
            setScoreLines([]);
            scoreLinesAllOptionsRef.current = [];
        }
    }, [graphTotalData]);

    const { searchText, setSearchText } = useFilterSearch<IInsightsPersonalBadgeVM>({
        data: data,
        dataSerachablePropertyName: 'title',
        setDataCallback: setFilteredData,
    });

    const changeScoreLinesInterval = (option: EGraphCardSelect) => {
        if (option === EGraphCardSelect.MONTH_4) {
            setScoreLines(scoreLinesAllOptionsRef.current[0]);
        } else if (option === EGraphCardSelect.YEAR_WITH_WEEKS) {
            setScoreLines(scoreLinesAllOptionsRef.current[1]);
        }
    };

    const handleDownloadBadgeClick = async (item: IInsightsPersonalBadgeVM) => {
        try {
            setDownloadBadgeActive(true);
            const { url } = await mutatePostDownloadBadgeAsync({
                outcomeId: item.outcomeId,
                level: item.level.toString(),
            });
            if (url) {
                // TODO When level is provider from endpoint
                // const fileName = `${item?.title} - ${item.level} Badge`;
                const fileName = `${item?.title} Badge`;
                await downloadFile(url, `${fileName}.png`);
            }
        } finally {
            setDownloadBadgeActive(false);
        }
    };

    const claimClickHandler = useCallback(
        async (outcomeId: number, level: ESkillLevel) => {
            if (level === ESkillLevel.PRODUCER) {
                await mutatePostClaimProducerBadgeAsync({
                    outcomeId: outcomeId.toString(),
                });
                // setUserOutcomeData((userOutcomeData) => {
                //     if (!userOutcomeData) return userOutcomeData;
                //     return {
                //         ...userOutcomeData,
                //         producerLevelBadge: responseBadge,
                //     };
                // });
                setToastMessage({
                    isOpen: true,
                    message: t('success.badge.successProducerBadgeClaim'),
                    severity: EToastSeverity.SUCCESS,
                });
            } else if (level === ESkillLevel.MASTER) {
                await mutatePostClaimMasterBadgeAsync({
                    outcomeId: outcomeId.toString(),
                });
                // setUserOutcomeData((userOutcomeData) => {
                //     if (!userOutcomeData) return userOutcomeData;
                //     return {
                //         ...userOutcomeData,
                //         masterLevelBadge: responseBadge,
                //     };
                // });
                setToastMessage({
                    isOpen: true,
                    message: t('success.badge.successMasterBadgeClaim'),
                    severity: EToastSeverity.SUCCESS,
                });
            }
        },
        [data]
    );

    const insightsPersonalBadgesContext: IInsightsPersonalBadgesContext = {
        data: filteredData,
        graphData,
        searchText,
        setSearchText,
        scoreLines,
        changeScoreLinesInterval,
        claimClickHandler,
        isPostClaimMasterBadgeLoading,
        isPostClaimProducerBadgeLoading,
        handleDownloadBadgeClick,
        isLoading: mode === EInsightsMode.STANDARD ? isLoadingPersonalBadges : isLoadingOrganizationUserBadges,
        isDownloadBadgeActive,
        isErrorFetchBadgeData:
            mode === EInsightsMode.STANDARD ? isErrorFetchPersonalBadgeData : isErrorFetchOrganizationUserBadgeData,
    };

    return (
        <InsightsPersonalBadgesContext.Provider value={insightsPersonalBadgesContext}>
            {children}
        </InsightsPersonalBadgesContext.Provider>
    );
};

export const useInsightsPersonalBadgesStateValue: () => IInsightsPersonalBadgesContext = () =>
    useContext(InsightsPersonalBadgesContext);
