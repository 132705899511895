import { FC, useEffect, useMemo, useRef } from 'react';
import { scaleLinear } from 'd3-scale';
import * as d3 from 'd3';
import useWindowDimensions from '../../../hooks/useWindowDimensions';

interface IProps {
    value: number;
    maximum: number;
    dimension?: IDimension;
    color: string;
    config?: {
        showCurrentValueLabel?: boolean;
    };
}

interface IDimension {
    width?: number;
    height?: number;
    margin?: {
        right?: number;
        left?: number;
        bottom?: number;
        top?: number;
    };
}

const defaultDimension: IDimension = {
    width: 400,
    height: 300,
    margin: {
        right: 20,
        left: 40,
        bottom: 50,
        top: 40,
    },
};

const ProgressBar: FC<IProps> = ({ value, maximum, dimension, color, config }) => {
    const barHeight = 16;

    const svgRef = useRef<SVGSVGElement | null>(null);
    const windowDimensions = useWindowDimensions();

    useEffect(() => {
        if (!svgRef.current) return;
        const xScale = scaleLinear().domain([0, maximum]).range([0, svgRef.current?.clientWidth]);
        d3.selectAll('#bar-current-value')
            .transition()
            .delay(100)
            .duration(200 + value * 20)
            .attr('width', xScale(value));
        d3.selectAll('#bar-maximum-value').attr('width', svgRef.current.clientWidth);
        d3.selectAll('#text-current-value').attr('x', xScale(value));
        d3.selectAll('#text-maximum-value').attr('x', svgRef.current.clientWidth);
        d3.selectAll('#tick-rect-maximum-value').attr('x', svgRef.current.clientWidth);
    }, [windowDimensions, value]);

    const { width } = useMemo(() => {
        const { width, height, margin }: IDimension = {
            ...defaultDimension,
            ...dimension,
        };
        return {
            width,
            height,
            margin,
            finalWidth: width! - margin!.left! - margin!.right!,
            finalHeight: height! - margin!.top! - margin!.bottom!,
        };
    }, [dimension]);

    useEffect(() => {
        if (!svgRef.current) return;

        const xScale = scaleLinear().domain([0, maximum]).range([0, svgRef.current?.clientWidth]);

        d3.select(svgRef.current)
            .append('rect')
            .attr('class', 'bar-gray')
            .attr('id', 'bar-maximum-value')
            .attr('x', 0)
            .attr('y', 0)
            .attr('rx', 4)
            .attr('ry', 4)
            .attr('width', svgRef.current.clientWidth)
            .attr('height', barHeight)
            .attr('fill', '#D9D9D9');

        d3.select(svgRef.current)
            .append('rect')
            .attr('style', 'clip-path: inset(0px 0px 0px 0px)')
            .attr('id', 'bar-current-value')
            .attr('class', 'bar')
            .attr('x', 0)
            .attr('y', 0)
            .attr('rx', 2)
            .attr('ry', 4)
            .attr('height', barHeight)
            .attr('width', 0)
            .attr('fill', color);

        if (value > 0) {
            d3.select(svgRef.current)
                .append('rect')
                .attr('id', 'bar-helper-current-value')
                .attr('class', 'bar')
                .attr('x', 0)
                .attr('y', 0)
                .attr('width', 2)
                .attr('height', barHeight)
                .attr('fill', color);
        }

        d3.select(svgRef.current)
            .append('text')
            .attr('class', 'amount-minimum')
            .attr('id', 'text-minimum-value')
            .attr('x', 1)
            .attr('y', barHeight)
            .attr('dx', -10)
            .attr('dy', 20)
            .style('font-size', '10px')
            .style('font-family', 'Open Sans')
            .style('color', color)
            .style('letter-spacing', '0.42px')
            .style('transform', 'translate(8px, 0)')
            .text(0);

        if (config?.showCurrentValueLabel)
            d3.select(svgRef.current)
                .append('text')
                .attr('class', 'amount-current')
                .attr('id', 'text-current-value')
                .attr('x', xScale(value))
                .attr('y', barHeight)
                .attr('dx', -10)
                .attr('dy', 20)
                .style('font-size', '14px')
                .style('font-family', 'Open Sans')
                .style('color', color)
                .style('font-weight', 500)
                .style('letter-spacing', '1.25px')
                .text(value);

        d3.select(svgRef.current)
            .append('text')
            .attr('class', 'amount-maximum')
            .attr('id', 'text-maximum-value')
            .attr('x', svgRef.current.clientWidth)
            .attr('y', barHeight)
            .attr('dx', -10)
            .attr('dy', 20)
            .style('font-size', '10px')
            .style('font-family', 'Open Sans')
            .style('color', color)
            .style('letter-spacing', '0.42px')
            .style('transform', 'translate(-2px, 0)')
            .text(maximum);

        d3.select(svgRef.current)
            .append('rect')
            .attr('class', 'amount-minimum-tick-rect')
            .attr('id', 'tick-rect-minimum-value')
            .attr('x', 1)
            .attr('y', barHeight)
            .attr('dx', -10)
            .attr('dy', 20)
            .attr('width', 1)
            .attr('height', 5)
            .style('transform', 'translate(0px, 2px)');

        d3.select(svgRef.current)
            .append('rect')
            .attr('class', 'amount-maximum-tick-rect')
            .attr('id', 'tick-rect-maximum-value')
            .attr('x', svgRef.current.clientWidth)
            .attr('y', barHeight)
            .attr('dx', -10)
            .attr('dy', 20)
            .attr('width', 1)
            .attr('height', 5)
            .style('transform', 'translate(-2px, 2px)');

        if (config?.showCurrentValueLabel)
            d3.select(svgRef.current)
                .append('rect')
                .attr('class', 'amount-current-tick-rect')
                .attr('id', 'tick-rect-current-value')
                .attr('x', xScale(value))
                .attr('y', barHeight)
                .attr('dx', -10)
                .attr('dy', 20)
                .attr('width', 1)
                .attr('height', 5)
                .style('transform', 'translate(-2px, 2px)');
    }, [color, maximum, value, width, config]);

    return (
        <div>
            <svg ref={svgRef} width="100%" height={barHeight + 20} style={{ overflow: 'visible' }}></svg>
        </div>
    );
};

export default ProgressBar;
