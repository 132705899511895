export enum ETenantPreference {
    NONE = 0,
    ENABLE_ALL_EMAILS = 1,
    ENABLE_WEEKLY_EMAILS = 2,
    ENABLE_ONBOARDING_EMAILS = 4,
    ENABLE_OUTCOME_ASSIGNED_NOTIFICATION = 8,
    ENABLE_BADGE_ELIGIBILITY_NOTIFICATION = 16,
    ENABLE_WEEKLY_UPDATE_AVAILABLE_NOTIFICATION = 32,
    ENABLE_MANAGER_CAN_SEE_TEAM_MEMBERS_INSIGHTS = 64,
    ENABLE_MANAGER_CAN_ASSIGN_OUTCOMES_TO_TEAM_MEMBERS = 128,
    ENABLE_LEADERBOARD = 256,
}
