import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getTokenAndHeaders } from './helpers/apiUtil';
import useAuthParams from '../hooks/useAuthParams';
import { IAuthParams } from '../interfaces/IAuthParams';
import { apiInstance } from './helpers/apiInstance';
import { mapHowToCategories, mapHowToPlaylist } from './helpers/mappers';
import { IHowToCategoryVM } from '../interfaces/views/IHowToCategoryVM';
import { IHowTo } from '../interfaces/IHowTo';
import { IHowToPlaylistVM } from '../interfaces/views/IHowToPlaylistVM';
import { EApiQueryKey } from '../interfaces/enums/EApiQueryKey';

export interface IGetHowToCategoriesResponse {
    introduction: string;
    videoId: string;
    videoImage: string;
    videoUrl: string;
    categories: IHowToCategoryVM[];
}

// GET HOW TO CATEGORIES
export const getHowToCategories = async (authParams: IAuthParams) => {
    const { headers } = await getTokenAndHeaders(authParams);
    const {
        data: { data },
    } = await apiInstance.get(`howToCategories`, headers);
    return {
        ...data,
        categories: mapHowToCategories(data.categories),
    };
};

export const useGetHowToCategoriesQuery: () => UseQueryResult<IGetHowToCategoriesResponse, Error> = () => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.HOW_TO_FETCH_CATEGORIES],
        queryFn: async () => {
            return getHowToCategories(authParams);
        },
        refetchOnWindowFocus: false,
    });
};

// GET HOW TO PLAYLIST CATEGORIES
export const getHowToPlaylistCategories = async (authParams: IAuthParams, categoryId?: string) => {
    const { headers } = await getTokenAndHeaders(authParams);
    const {
        data: { data },
    } = await apiInstance.get(`howTo/playlist/category/${categoryId}`, headers);
    return data;
};

export const useGetHowToPlaylistCategoriesQuery: (categoryId?: string) => UseQueryResult<IHowTo[], Error> = (
    categoryId
) => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.HOW_TO_FETCH_PLAYLIST_BY_CATEGORY],
        queryFn: async () => {
            return getHowToPlaylistCategories(authParams, categoryId);
        },
        refetchOnWindowFocus: false,
        enabled: false,
    });
};

// GET HOW TO BY PLAYLIST
export const getHowToByPlaylist = async (authParams: IAuthParams, playlistId?: string) => {
    const { headers } = await getTokenAndHeaders(authParams);
    const {
        data: { data },
    } = await apiInstance.get(`howTo/playlist/${playlistId}`, headers);
    return mapHowToPlaylist(data);
};

export const useGetHowToPlaylistQuery: (playlistId?: string) => UseQueryResult<IHowToPlaylistVM[], Error> = (
    playlistId
) => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.HOW_TO_FETCH_PLAYLIST],
        queryFn: async () => {
            return getHowToByPlaylist(authParams, playlistId);
        },
        refetchOnWindowFocus: false,
        enabled: false,
    });
};
