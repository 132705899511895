import { FC, useRef, KeyboardEvent } from 'react';
import { useFocusEffect, useRovingTabIndex } from 'react-roving-tabindex';
import {
    CategoryImage,
    CategoryBox,
    CategoryInfo,
    CategoryInfoDescriptionTypography,
    CategoryInfoTitleTypography,
} from './Style';
import { createUrl } from '../../../../utils/createUrl';
import loadImageGraduallyHandler from '../../../../utils/loadImageGradually';
import { accessibilityEnterKeyCallback } from '../../../../utils/accessibilityUtils';

interface IProps {
    id: string;
    categoryId: string;
    title: string;
    description: string;
    image: string;
    itemClickHandler: (categoryId: string, id: string) => void;
}

const PlaylistItem: FC<IProps> = ({ id, categoryId, title, description, image, itemClickHandler }) => {
    const ref = useRef<HTMLAnchorElement>(null);
    const [tabIndex, focused, handleKeyDown, handleClick] = useRovingTabIndex(ref, false);

    useFocusEffect(focused, ref);
    const clickHandler = (categoryId: string, id: string) => {
        itemClickHandler(categoryId, id);
    };

    return (
        <CategoryBox
            ref={ref}
            key={id}
            tabIndex={tabIndex}
            onClick={() => {
                clickHandler(categoryId, id);
                handleClick();
            }}
            onKeyDown={(e: KeyboardEvent<any>) => {
                handleKeyDown(e);
                accessibilityEnterKeyCallback(e, () => itemClickHandler(categoryId, id));
            }}
        >
            <CategoryImage onLoad={loadImageGraduallyHandler} src={createUrl(image)?.generatedUrl} alt={title} />
            <CategoryInfo>
                <CategoryInfoTitleTypography variant="h6">{title}</CategoryInfoTitleTypography>
                <CategoryInfoDescriptionTypography variant="subtitle2">{description}</CategoryInfoDescriptionTypography>
            </CategoryInfo>
        </CategoryBox>
    );
};

export default PlaylistItem;
