import { useLayoutEffect, FC, PropsWithChildren } from 'react';
import { useLocation } from 'react-router';

const ScrollToTop: FC<PropsWithChildren> = ({ children }) => {
    const location = useLocation();

    useLayoutEffect(() => {
        document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);

    return <>{children}</>;
};

export default ScrollToTop;
