import { UseMutationResult, useMutation } from '@tanstack/react-query';
import useAuthParams from '../hooks/useAuthParams';
import { apiInstance } from './helpers/apiInstance';
import { getTokenAndHeaders } from './helpers/apiUtil';
import { IAuthParams } from '../interfaces/IAuthParams';
import { EAssignmentRoles } from '../interfaces/enums/EAssignmentRoles';

export interface IPostBulkAssignmentRolesRequest {
    targetUserIds: string[];
    roleChange: EAssignmentRoles;
}

// BULK ASSIGNMENT LICENSE
export const postBulkAssignmentRole: (
    variables: IPostBulkAssignmentRolesRequest & { authParams: IAuthParams }
) => Promise<undefined> = async (variables) => {
    const { authParams, targetUserIds, roleChange } = variables;
    const { headers } = await getTokenAndHeaders(authParams);

    await apiInstance.post(
        `/bulk/role`,
        {
            targetUserIds,
            roleChange,
        },
        headers
    );
    return undefined;
};

export const usePostBulkAssignmentRole: () => UseMutationResult<
    undefined,
    Error,
    IPostBulkAssignmentRolesRequest
> = () => {
    const authParams = useAuthParams();
    return useMutation({
        mutationFn: (variables: IPostBulkAssignmentRolesRequest) => {
            return postBulkAssignmentRole({ ...variables, authParams });
        },
    });
};
