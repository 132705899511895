import { CSSProperties, FC, MouseEvent } from 'react';
import { PrimaryStyledButton, StyledButton } from './Style';

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    id?: string;
    onClick?: ((event: MouseEvent) => void) | (() => void) | undefined;
    disabled?: boolean;
    key?: string;
    style?: CSSProperties;
    variant?: 'purple';
    tabIndex?: number;
    className?: string;
    mode?: 'primary' | undefined;
    children: React.ReactNode;
}

const TextButton: FC<IProps> = ({ id, onClick, disabled, key, style, children, tabIndex, mode }) => {
    return mode === 'primary' ? (
        <PrimaryStyledButton
            disabled={disabled ?? false}
            id={id}
            onClick={onClick}
            key={key}
            tabIndex={tabIndex}
            style={style}
            disableRipple
        >
            {children}
        </PrimaryStyledButton>
    ) : (
        <StyledButton
            disabled={disabled ?? false}
            id={id}
            onClick={onClick}
            key={key}
            tabIndex={tabIndex}
            style={style}
        >
            {children}
        </StyledButton>
    );
};

export default TextButton;
