import { FC, useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { useTheme } from '@mui/system';
import { LeftRatingBox, RightRatingBox, RootBox } from './Style';

export interface IChartDimension {
    height?: number;
    width?: number;
    margin?: {
        top?: number;
        right?: number;
        bottom?: number;
        left?: number;
    };
}

export enum EValueSidePosition {
    LEFT = 'left',
    RIGHT = 'right',
}

const defaultBottomFillColor = '#B1A6D0';
const defaultFrontFillColor = '#654ea3';

const defaultDimension: IChartDimension = {
    height: 90,
    width: 27,
    margin: {
        top: 5,
        right: 10,
        bottom: 5,
        left: 10,
    },
};

interface IProps {
    chartId: string;
    dimension?: IChartDimension;
    benchmarkRange: number[];
    rating: number;
    bottomFillColor?: string;
    frontFillColor?: string;
    valueSidePosition?: EValueSidePosition;
    valueSuffix?: string;
    maxValue?: number;
    dottedSeparator?: boolean;
    className?: string;
}

const ProgressGraph: FC<IProps> = ({
    dimension,
    chartId,
    rating,
    benchmarkRange,
    bottomFillColor,
    frontFillColor,
    valueSidePosition = EValueSidePosition.LEFT,
    valueSuffix,
    maxValue = 100,
    dottedSeparator = false,
    className,
}) => {
    const chRef = useRef(null);
    const theme = useTheme();

    useEffect(() => {
        d3.selectAll(chRef.current).remove();
        d3.selectAll(`#${chartId}`).remove();

        const bottomFillColorHex = bottomFillColor || defaultBottomFillColor;
        const frontFillColorHex = frontFillColor || defaultFrontFillColor;

        const data = [
            { name: '1', value: maxValue, color: bottomFillColorHex },
            { name: '2', value: rating, color: frontFillColorHex },
        ];

        let chartDimension = dimension || defaultDimension;
        var svg = d3
            .select(chRef.current)
            .append('svg')
            .attr('id', `${chartId}`)
            .attr('width', chartDimension.width! + chartDimension.margin!.left! + chartDimension.margin!.right!)
            .attr('height', chartDimension.height! + chartDimension.margin!.top! + chartDimension.margin!.bottom!)
            .style('transform', 'rotate(90deg) translateX(8px)')
            .append('g')
            .style('transform', 'translate(5px, 0)');

        var x = d3.scaleBand().domain(['key1']).range([0, chartDimension.width!]);
        svg.append('g')
            .attr('transform', 'translate(0,' + chartDimension.height + ')')
            .call(d3.axisBottom(x).tickSize(0))
            .style('display', 'none');

        var y = d3.scaleLinear().domain([0, maxValue]).range([chartDimension.height!, 0]);
        svg.append('g').call(d3.axisLeft(y)).style('display', 'none');

        var stackedData = d3.stack().keys(data.map((item) => item.name))(
            data.map((item) => {
                return {
                    [item.name]: item.value,
                };
            })
        );

        svg.selectAll('.tick').style('display', 'none');

        svg.append('g')
            .selectAll('g')
            .data(stackedData)
            .enter()
            .append('g')
            .attr('fill', function (d, index) {
                return data[index].color;
            })
            .selectAll('rect')
            .data(function (d) {
                return d;
            })
            .enter()
            .append('rect')
            .attr('y', function (d) {
                return y(d[1]);
            })
            .attr('height', function (d) {
                const y0 = y(d[0]);
                const y1 = y(d[1]);
                if (y0 && y1) return y0 - y1;
                return y0;
            })
            .attr('width', x.bandwidth());

        if (!!benchmarkRange[0])
            svg.append('g')
                .selectAll('g')
                .data(benchmarkRange)
                .enter()
                .append('line')
                .attr('x1', 0)
                .attr('y1', (d) => {
                    return (chartDimension.height! * (maxValue - d)) / maxValue;
                })
                .attr('x2', chartDimension.width! + 10)
                .attr('y2', (d) => {
                    return (chartDimension.height! * (maxValue - d)) / maxValue;
                })
                .style('stroke-width', 2)
                .style('stroke', theme.palette.common.black)
                .style('fill', 'none')
                .style('stroke-dasharray', dottedSeparator ? '3, 3' : 'none')
                .style('stroke-linecap', dottedSeparator ? 'none' : 'round')
                .style('transform', 'translate(-4px, 0)')
                .style('opacity', '0.25');
    }, [rating, benchmarkRange, dottedSeparator]);

    return (
        <RootBox className={className || 'progress-graph'} valueSidePosition={valueSidePosition}>
            {valueSidePosition === EValueSidePosition.LEFT && (
                <LeftRatingBox paddingRight={(dimension || defaultDimension).height! / 2 - 12}>
                    {rating}
                    {valueSuffix ?? ''}
                </LeftRatingBox>
            )}
            <div ref={chRef} />
            {valueSidePosition === EValueSidePosition.RIGHT && (
                <RightRatingBox paddingLeft={(dimension || defaultDimension).height! / 2}>
                    {rating}
                    {valueSuffix ?? ''}
                </RightRatingBox>
            )}
        </RootBox>
    );
};

export default ProgressGraph;
