import styled from '../theme/styled';

export const ControlsBox = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 24,
});

export const PagerBox = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 16,
});

export const ZoomBox = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});

export const DocumentBox = styled('div')({
    display: 'flex',
    justifyContent: 'center',
});

export const PaginationValueBox = styled('div')({
    marginRight: '8px !important',
});
