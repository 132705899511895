const loadImageGraduallyHandler = (e: React.SyntheticEvent) => {
    const delay = 50;
    const target = e.target as HTMLImageElement;
    let level = 0;
    target.style.visibility = 'visible';
    let step = function () {
        target.style.opacity = level.toString();
        if (level < 1) {
            level += 0.1;
            setTimeout(step, delay);
        }
    };
    step();
};

export default loadImageGraduallyHandler;
