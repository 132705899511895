import { FC } from 'react';
import PrimaryButton from '../../buttons/primary-button/PrimaryButton';
import { ActionBox, Container, MessageBox } from './Style';

interface IProps {
    actionCallback?: () => void;
    actionButtonTitle?: string;
    message?: string;
    boxStyle?: React.CSSProperties;
}

const SomethingWentWrongCard: FC<IProps> = ({
    actionCallback,
    actionButtonTitle = 'Try again',
    message = 'Something went wrong',
    boxStyle,
}) => {
    return (
        <Container style={boxStyle}>
            <MessageBox variant="h6">{message}</MessageBox>
            <ActionBox>
                <PrimaryButton title={actionButtonTitle} clickHandler={actionCallback} />
            </ActionBox>
        </Container>
    );
};

export default SomethingWentWrongCard;
