import styled from '../../theme/styled';
import { Typography } from '@mui/material';

export const RootBox = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: 16,
    border: '1px solid rgba(0, 0, 0, 0.16)',
    borderRadius: 4,
    gap: 4,
});

export const HeaderTitleBox = styled(Typography)({
    textTransform: 'uppercase',
    color: 'rgba(0, 0, 0, 0.64);',
});

export const HeaderTitleButtonBox = styled('div')({
    display: 'flex',
    alignItems: 'center',
    paddingTop: 12,
});

export const TitleTextBox = styled(Typography)({
    flex: 1,
    color: '#000000E0',
});

export const FooterBox = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: 20,
    fontSize: 12,
    fontFamily: 'Open Sans',
    letterSpacing: '0.5px',
});

export const SkillNameSpan = styled(Typography)(({ theme: { palette } }) => ({
    color: palette.primary.main,
    fontWeight: 700,
    cursor: 'pointer',
}));
