import { createContext, FC, useContext, useEffect, useState, PropsWithChildren } from 'react';
import {
    IUserImageResponse,
    useGetCurrentUserImageQuery,
    useGetCurrentUserQuery,
    usePostUserContentTrackingQuery
} from '../services/UserQueryService';
import { ICurrentUserVM } from '../interfaces/views/ICurrentUserVM';
import { EUserRoleImplementation } from '../interfaces/enums/EUserRoleImplementation';
import { ETenantPreference } from '../interfaces/enums/ETenantPreference';
import { EUserContentTrackingType } from '../interfaces/enums/EUserContentTrackingType';
import { INuliaLicenseStatus } from '../interfaces/INuliaLicenseStatus';
import InterestedInNuliaWorks from '../assets/InterestedInNuliaWorks.png';

export interface IUserAuthContext {
    userImage?: IUserImageResponse | null;
    currentUserData?: ICurrentUserVM & { roles: EUserRoleImplementation[] };
    refetchCurrentUserData: () => void;
    isTenantPreferenceActive: (tenantPreference: ETenantPreference) => boolean;
    sendContentUsageRequest: () => Promise<void>;
    isCurrentUserDataLoading?: boolean;
    nuliaLicenseStatus?: INuliaLicenseStatus;
}

export const UserAuthContext = createContext<IUserAuthContext>({} as IUserAuthContext);

interface IProps {}

export const UserAuthProvider: FC<PropsWithChildren<IProps>> = ({ children }) => {
    const [userImage, setUserImage] = useState<IUserImageResponse | undefined>(undefined);
    const {
        data: fetchedCurrentUserData,
        refetch: refetchCurrentUserData,
        isLoading: isCurrentUserDataLoading
    } = useGetCurrentUserQuery();
    const [currentUserData, setCurrentUserData] = useState<
        (ICurrentUserVM & { roles: EUserRoleImplementation[] }) | undefined
    >(undefined);
    const { mutateAsync: mutatePostUserContentTrackingAsync } = usePostUserContentTrackingQuery();
    const { data: currentUserImageData } = useGetCurrentUserImageQuery();
    const [nuliaLicenseStatus, setNuliaLicenseStatus] = useState<INuliaLicenseStatus | undefined>();

    useEffect(() => {
        setNuliaLicenseStatus({
            title: 'Interested in Nulia Works',
            image: <img src={InterestedInNuliaWorks} />,
            message:
                "We're excited to continue helping you reach your potential and unlock the value of Office 365 with the Nulia Works Teams app. To find out more, reach out to your manager or IT administrator​"
        });
    }, []);

    useEffect(() => {
        setUserImage(currentUserImageData);
    }, [currentUserImageData]);

    useEffect(() => {
        if (fetchedCurrentUserData) setCurrentUserData(fetchedCurrentUserData);
    }, [fetchedCurrentUserData]);

    const isTenantPreferenceActive = (tenantPreference: ETenantPreference) => {
        return currentUserData?.tenantPreferences.find((preference) => preference === tenantPreference) !== undefined;
    };

    const sendContentUsageRequest: () => Promise<void> = async () => {
        try {
            await mutatePostUserContentTrackingAsync({
                contentType: EUserContentTrackingType.HELP_WIZARD
            });
        } catch (e) {
            console.error(e);
        }
    };

    const userAuthContext: IUserAuthContext = {
        userImage,
        currentUserData,
        refetchCurrentUserData,
        isTenantPreferenceActive,
        sendContentUsageRequest,
        isCurrentUserDataLoading,
        nuliaLicenseStatus
    };

    return <UserAuthContext.Provider value={userAuthContext}>{children}</UserAuthContext.Provider>;
};

export const useUserAuthStateValue: () => IUserAuthContext = () => useContext(UserAuthContext);
