import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const RootBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    '&:not(:last-child)': {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    background: 'linear-gradient(180deg, #FFFFFF 0%, #FAFAFA 100%), #FFFFFF',
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.08), 0px 0.5px 1.5px rgba(0, 0, 0, 0.08)',
    height: '100%',
    borderRadius: theme.borderRadius?.outside?.small,
}));

export const TitleBox = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    padding: '16px 24px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
    borderTopLeftRadius: theme.borderRadius?.outside?.small,
    borderTopRightRadius: theme.borderRadius?.outside?.small,
    minHeight: '58.8px',
}));

export const TitleViewAllWrapper = styled('div')({});

export const ViewAllLink = styled('a', {
    shouldForwardProp: (prop) => prop !== 'isDisabled',
})<{ isDisabled?: boolean }>(({ theme, isDisabled = false }) => ({
    marginLeft: '10px',
    color: isDisabled ? theme.palette.action.disabled : theme.palette.primary.main,
    textTransform: 'uppercase',
    textDecoration: 'none',
    '&:hover': {
        textDecoration: isDisabled ? 'none' : 'underline',
        cursor: isDisabled ? 'not-allowed' : 'pointer',
    },
}));

export const TitleTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.gray.dark,
    [theme.breakpoints.down('lg')]: {
        fontWeight: 500,
        fontSize: '14px',
    },
}));

export const ContentWrapper = styled('div')({
    padding: '0px 4px',
    height: '100%',
});

export const ContentBox = styled('div', {
    shouldForwardProp: (prop) => prop !== 'isEmpty' && prop !== 'isMaxSize',
})<{ isEmpty?: boolean; isMaxSize?: boolean }>(({ theme, isEmpty = false, isMaxSize = false }) => ({
    display: isEmpty ? 'flex' : 'grid',
    gridTemplateColumns: isMaxSize
        ? 'minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)'
        : 'minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)',
    justifyContent: 'flex-start',
    flexWrap: 'nowrap',
    padding: 20,
    height: '100%',
    minHeight: '260px',
    overflowX: 'auto',
    [theme.breakpoints.down(600)]: {
        display: 'flex',
        flexDirection: 'column',
    },
    [theme.breakpoints.down(800)]: {
        gridTemplateColumns: 'minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)',
        justifyContent: 'flex-start',
    },
}));

export const LoadingBox = styled('div')({
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
});
