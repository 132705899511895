import { FC } from 'react';
import {
    RootBox,
    TextLogoBox,
    LogoBox,
    TextBox,
    TitleTypography,
    MessageTypography,
    ActionBox,
    BackgroundBox
} from './Style';
import PrimaryButton from '../../ui/buttons/primary-button/PrimaryButton';
import { useUserAuthStateValue } from '../../contexts/UserAuthContext';
import LoginIcon from '../../assets/icons/LoginIcon';

const NuliaLicenseStatus: FC = () => {
    const { nuliaLicenseStatus } = useUserAuthStateValue();

    return (
        <RootBox>
            <TextLogoBox>
                <LogoBox>
                    <LoginIcon />
                </LogoBox>
                <TextBox>
                    <TitleTypography>{nuliaLicenseStatus?.title}</TitleTypography>
                    {nuliaLicenseStatus?.message && (
                        <MessageTypography>{nuliaLicenseStatus?.message}</MessageTypography>
                    )}
                    {nuliaLicenseStatus?.actionTitle && (
                        <ActionBox>
                            <PrimaryButton
                                title={nuliaLicenseStatus?.actionTitle}
                                clickHandler={nuliaLicenseStatus?.onActionClick}
                            />
                        </ActionBox>
                    )}
                </TextBox>
            </TextLogoBox>
            <BackgroundBox></BackgroundBox>
        </RootBox>
    );
};

export default NuliaLicenseStatus;
