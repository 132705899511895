import { Typography } from '@mui/material';
import styled from '../../../../../ui/theme/styled';

export const RootBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 25px',
    gap: 20,
    border: '1px solid rgba(0, 0, 0, 0.16)',
    minHeight: 120,
    [theme.breakpoints.down('md')]: {
        height: 'unset',
    },
}));

export const FooterBox = styled('div')({
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
});

export const TitleSpan = styled(Typography)(({ theme }) => ({
    color: '#5C4F9C',
    [theme.breakpoints.down('md')]: {
        fontSize: '14px',
    },
}));

export const LastTriedSpan = styled('span')(({ theme }) => ({
    fontFamily: 'Ubuntu',
    fontWeight: 500,
    fontSize: 10,
    letterSpacing: '1.25px',
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
    marginRight: 18,
    color: theme.palette.text.secondary,
    gap: '2px',
}));
