import { FC, PropsWithChildren } from 'react';
import TextField from '@mui/material/TextField';

interface IProps {
    value: string;
    onChange: any;
    field: any;
    disabled?: boolean;
    placeholder?: string;
}

const NTextArea: FC<PropsWithChildren<IProps>> = ({ value, onChange, field, disabled = false, placeholder }) => {
    return (
        <TextField
            {...field}
            disabled={disabled}
            variant="standard"
            onChange={onChange}
            value={value || ''}
            multiline
            minRows={5}
            maxRows={20}
            placeholder={placeholder}
            sx={{ background: '#FAFAFA', padding: '8px' }}
        />
    );
};

export default NTextArea;
