import { FC } from 'react';
import { useNavigate } from 'react-router';
import { Typography } from '@mui/material';
import PrimaryButton from '../../../ui/buttons/primary-button/PrimaryButton';
import { ActionBox, ContentWrapper, DescriptionBox, MessageBox, MessageTypography, RootBox } from '../Style';
import { useCrumbsStateValue } from '../../../contexts/CrumbsContext';
import NavigationSidemenu from '../../../ui/navigation/navigation-sidemenu/NavigationSidemenu';

interface IProps {
    title?: string;
    description?: string;
}

const PageForbidden: FC<IProps> = ({ title, description }) => {
    const navigate = useNavigate();
    const { setCrumbs } = useCrumbsStateValue();

    const goBack = () => {
        navigate('/');
        setCrumbs([]);
    };

    return (
        <RootBox applyPadding isOpen>
            <NavigationSidemenu isAlwaysOpen />
            <ContentWrapper isOpen={false}>
                <MessageBox>
                    <MessageTypography variant="h4">{title || "You don't have access to this page."}</MessageTypography>
                </MessageBox>
                <DescriptionBox>
                    <Typography variant="body1">
                        {description || 'If you believe you should have access to this page, contact to your admin.'}
                    </Typography>
                </DescriptionBox>
                <ActionBox>
                    <PrimaryButton title="Back" clickHandler={goBack} />
                </ActionBox>
            </ContentWrapper>
        </RootBox>
    );
};

export default PageForbidden;
