import { Box } from '@mui/material';
import { fullRotateKeyFrame } from '../../../css/CommonComponents';
import styled from '../../theme/styled';

export const StyledModalBox = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    gap: '12px',
}));

export const BadgeImg = styled('img')({
    width: 140,
    '&:hover': {
        animation: `${fullRotateKeyFrame} 2s infinite ease-in-out`,
    },
});

export const ActionButtonsBox = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '12px',
});
