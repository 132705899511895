export enum EOutcomesStatuses {
    ASSIGNED_OUTCOMES = 'assignedOutcomes',
    CERTIFICATIONS_IN_DANGER = 'certificationsInDanger',
    CERTIFICATIONS_EXPIRED = 'certificationsExpired',
    UNCLAIMED_BADGES = 'unclaimedBadges',
    COMPANY_FOCUS = 'companyFocus',
    IN_PROGRESS = 'inProgress',
    NEED_ATTENTION = 'needsAttentionOutcomes',
    ATTAINED = 'attainedOutcomes',
}
