import { routes } from '../pages/routes';
import { ICrumb } from '../interfaces/ICrumb';
import { matchRoutes } from 'react-router';

export const appendInsightRoutes: (currentCrumbs: ICrumb[], insightsRoute: string, name: string) => ICrumb[] = (
    currentCrumbs,
    insightsRoute,
    name
) => {
    const newCrumbs = [...currentCrumbs];
    const lastCrumb = newCrumbs[currentCrumbs.length - 1];

    if (
        lastCrumb &&
        matchRoutes(
            [
                { path: routes.INSIGHTS_ORGANIZATIONAL_ASSESSMENT_DETAILS },
                { path: routes.INSIGHTS_ORGANIZATIONAL_OUTCOMES_DETAILS },
            ],
            lastCrumb.pathname
        ) &&
        (insightsRoute.startsWith(routes.INSIGHTS_PERSONAL) || insightsRoute.startsWith(routes.INSIGHTS_ORGANIZATIONAL))
    ) {
        return [...newCrumbs.slice(0, -2), { name, pathname: insightsRoute }];
    }
    if (
        lastCrumb &&
        (lastCrumb.pathname.startsWith(routes.INSIGHTS_PERSONAL) ||
            lastCrumb.pathname.startsWith(routes.INSIGHTS_ORGANIZATIONAL)) &&
        (insightsRoute.startsWith(routes.INSIGHTS_PERSONAL) || insightsRoute.startsWith(routes.INSIGHTS_ORGANIZATIONAL))
    ) {
        return [...newCrumbs.slice(0, -1), { name, pathname: insightsRoute }];
    }

    if (
        lastCrumb &&
        (lastCrumb.pathname.startsWith(routes.INSIGHTS_PERSONAL) ||
            lastCrumb.pathname.startsWith(routes.INSIGHTS_ORGANIZATIONAL)) &&
        matchRoutes(
            [
                { path: routes.INSIGHTS_ORGANIZATIONAL_USER_BADGES },
                { path: routes.INSIGHTS_ORGANIZATIONAL_USER_ENGAGEMENT },
                { path: routes.INSIGHTS_ORGANIZATIONAL_USER_ENGAGEMENT },
                { path: routes.INSIGHTS_ORGANIZATIONAL_USER_SKILLS },
            ],
            insightsRoute
        )
    ) {
        return [
            ...newCrumbs,
            {
                name,
                pathname: insightsRoute,
            },
        ];
    }

    if (
        lastCrumb &&
        matchRoutes(
            [
                { path: routes.INSIGHTS_ORGANIZATIONAL_USER_BADGES },
                { path: routes.INSIGHTS_ORGANIZATIONAL_USER_ENGAGEMENT },
                { path: routes.INSIGHTS_ORGANIZATIONAL_USER_OUTCOMES },
                { path: routes.INSIGHTS_ORGANIZATIONAL_USER_SKILLS },
                { path: routes.INSIGHTS_ORGANIZATIONAL_OUTCOMES_DETAILS },
            ],
            lastCrumb.pathname
        ) &&
        matchRoutes(
            [
                { path: routes.INSIGHTS_ORGANIZATIONAL_USER_BADGES },
                { path: routes.INSIGHTS_ORGANIZATIONAL_USER_ENGAGEMENT },
                { path: routes.INSIGHTS_ORGANIZATIONAL_USER_OUTCOMES },
                { path: routes.INSIGHTS_ORGANIZATIONAL_USER_SKILLS },
                { path: routes.INSIGHTS_ORGANIZATIONAL_OUTCOMES_DETAILS },
            ],
            insightsRoute
        )
    ) {
        return [
            ...newCrumbs.slice(0, -1),
            {
                name,
                pathname: insightsRoute,
            },
        ];
    }

    return newCrumbs;
};
