export enum ETenantHistoryType {
    UNKNOWN = 'Unknown',
    OUTCOME_ASSIGN = 'OutcomeAssign',
    OUTCOME_UNASSIGN = 'OutcomeUnassign',
    OUTCOME_FOCUS_ON = 'OutcomeFocusOn',
    OUTCOME_FOCUS_OFF = 'OutcomeFocusOff',
    LICENSE_ASSIGN = 'LicenseAssign',
    LICENSE_UNASSIGN = 'LicenseUnassign',
    ROLE_ASSIGN = 'RoleAssign',
    ROLE_UNASSIGN = 'RoleUnassign',
    ROLE_UNASSIGN_ALL = 'RoleUnassignAll',
    SETTING_ON = 'SettingOn',
    SETTING_OFF = 'SettingOff',
}
