import { Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const RootBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
}));

export const RootInnerBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '20px',
    width: '1080px',
    [theme.breakpoints.down('lg')]: {
        width: '100%',
        marginLeft: 'unset',
        paddingLeft: '20px',
        paddingRight: '20px'
    }
}));

export const RootPaper = styled(Paper)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    padding: '18px',
    borderRadius: theme.borderRadius?.outside?.small,
    [theme.breakpoints.down('lg')]: {
        width: '100%'
    }
}));

export const TitleTypography = styled(Typography)({
    paddingBottom: '18px',
    borderBottom: '1px solid #00000029',
    color: '#616063'
});

export const Header = styled('div')(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: '140px auto 140px',
    width: '100%',
    [theme.breakpoints.down('lg')]: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    [theme.breakpoints.down(770)]: {
        display: 'grid',
        gridTemplateRows: '1fr 1fr',
        gridTemplateColumns: 'auto auto',
        gap: '16px'
    }
}));

export const DropdownBox = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down(770)]: {
        gridArea: '2/ span 2',
        gap: '10px'
    }
}));

export const IframeWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    minHeight: 'calc(100vh - 115px)',
    padding: 32,
    width: 1040,
    [theme.breakpoints.down('lg')]: {
        width: '100%'
    },
    [theme.breakpoints.down(770)]: {
        padding: '20px 2px'
    },
    [theme.breakpoints.down('sm')]: {
        width: 330
    }
}));

export const StyledHTMLIframe = styled('iframe')({
    width: '1px',
    minWidth: '100%',
    flex: 1,
    border: 'none',
    height: 'auto'
});
