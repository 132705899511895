import styled from '../../../../../ui/theme/styled';

export const DoughnutBox = styled('div')({
    '& > div': {
        top: '6px',
    },
    '& svg': {
        paddingLeft: '8px',
    },
});
