import { KeyboardEvent } from 'react';

export const accessibilityEnterKeyCallback = (e: KeyboardEvent<any>, callback: () => void) => {
    if (e.key === 'Enter') {
        callback();
        e.stopPropagation();
        e.preventDefault();
    }
};

export const accessibilitySpaceKeyCallback = (e: KeyboardEvent<any>, callback: () => void) => {
    if (e.key === ' ') {
        callback();
        e.stopPropagation();
        e.preventDefault();
    }
};

export const accessibilityEnterSpaceKeyCallback = (e: KeyboardEvent<any>, callback: () => void) => {
    if (e.key === 'Enter') {
        callback();
        e.stopPropagation();
        e.preventDefault();
    } else if (e.key === ' ') {
        callback();
        e.stopPropagation();
        e.preventDefault();
    }
};

export const accessibilityEscKeyCallback = (e: KeyboardEvent<any>, callback: () => void) => {
    if (e.key === 'Escape') {
        callback();
        e.stopPropagation();
        e.preventDefault();
    }
};
