export const lineBreakSplitter: (line: string) => string[] = (line) => {
    if (Array.isArray(line)) {
        return line;
    }
    if ((line && line?.includes('<br/>')) || line?.includes('<br>')) {
        // Regex expression to search for <br/> and <br> in description
        const breakpoint = /\<br\/\>|\<br\>/;
        return line.split(breakpoint);
    }
    return [line];
};
