import { FC } from 'react';

const ToExpandIcon: FC = () => {
    return (
        <svg width="15" height="8" viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.98673 0.292893C1.57994 -0.0976311 0.920389 -0.0976311 0.513593 0.292893C0.106797 0.683418 0.106797 1.31658 0.513593 1.70711L6.76359 7.70711C7.17039 8.09763 7.82994 8.09763 8.23673 7.70711L14.4867 1.70711C14.8935 1.31658 14.8935 0.683418 14.4867 0.292893C14.0799 -0.0976311 13.4204 -0.0976311 13.0136 0.292893L7.50016 5.58579L1.98673 0.292893Z"
                fill="black"
                fillOpacity="0.4"
            />
        </svg>
    );
};

export default ToExpandIcon;
