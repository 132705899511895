import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { getTokenAndHeaders } from './helpers/apiUtil';
import useAuthParams from '../hooks/useAuthParams';
import { IAuthParams } from '../interfaces/IAuthParams';
import { apiInstance } from './helpers/apiInstance';
import { IBadge } from '../interfaces/IBadge';

export interface IPostClaimProducerBadgeRequest {
    outcomeId: string;
}

export interface IPostClaimProducerBadgeResponse extends IBadge {}

export interface IPostClaimMasterBadgeRequest {
    outcomeId: string;
}

export interface IPostShareBadgeOnLinkedinResponse {
    result: string;
}

export interface IPostShareBadgeOnLinkedinRequest {
    outcomeId: number;
    userToken: string;
    level: string;
}

export interface IPostClaimMasterBadgeResponse extends IBadge {}

// CLAIM PRODUCER BADGE
export const postClaimProducerBadge: (
    variables: IPostClaimProducerBadgeRequest & { authParams: IAuthParams }
) => Promise<IPostClaimProducerBadgeResponse> = async (variables) => {
    const { authParams, outcomeId } = variables;
    const { headers } = await getTokenAndHeaders(authParams);

    const {
        data: { data },
    } = await apiInstance.post(`outcomes/${outcomeId}/claim/producer`, undefined, headers);
    return data;
};

export const usePostClaimProducerBadge: () => UseMutationResult<
    IPostClaimProducerBadgeResponse,
    Error,
    IPostClaimProducerBadgeRequest
> = () => {
    const authParams = useAuthParams();
    return useMutation({
        mutationFn: (variables: IPostClaimProducerBadgeRequest) => {
            return postClaimProducerBadge({ ...variables, authParams });
        },
    });
};

// CLAIM MASTER BADGE
export const postClaimMasterBadge: (
    variables: IPostClaimMasterBadgeRequest & { authParams: IAuthParams }
) => Promise<IPostClaimMasterBadgeResponse> = async (variables) => {
    const { authParams, outcomeId } = variables;
    const { headers } = await getTokenAndHeaders(authParams);

    const {
        data: { data },
    } = await apiInstance.post(`outcomes/${outcomeId}/claim/master`, undefined, headers);
    return data;
};

export const usePostClaimMasterBadge: () => UseMutationResult<
    IPostClaimMasterBadgeResponse,
    Error,
    IPostClaimMasterBadgeRequest
> = () => {
    const authParams = useAuthParams();
    return useMutation({
        mutationFn: (variables: IPostClaimMasterBadgeRequest) => {
            return postClaimMasterBadge({ ...variables, authParams });
        },
    });
};

// POST BADGE ON LINKEDIN
export const postShareBadgeOnLinkedin: (
    variables: IPostShareBadgeOnLinkedinRequest & { authParams: IAuthParams }
) => Promise<IPostShareBadgeOnLinkedinResponse> = async (variables) => {
    const { authParams, outcomeId, userToken, level } = variables;
    const { headers } = await getTokenAndHeaders(authParams);

    const {
        data: { data },
    } = await apiInstance.post(
        `badges/postit`,
        {
            outcomeId,
            userToken,
            level,
        },
        headers
    );
    return data;
};

export const usePostShareBadgeOnLinkedin: () => UseMutationResult<
    IPostShareBadgeOnLinkedinResponse,
    Error,
    IPostShareBadgeOnLinkedinRequest
> = () => {
    const authParams = useAuthParams();
    return useMutation({
        mutationFn: (variables: IPostShareBadgeOnLinkedinRequest) => {
            return postShareBadgeOnLinkedin({ ...variables, authParams });
        },
    });
};
