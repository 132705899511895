import { FC } from 'react';

const AssessmentBenchmarkScoreLegendIcon: FC = () => {
    return (
        <svg width="10" height="2" viewBox="0 0 10 2" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="2" height="2" fill="#8C8C8C" />
            <rect x="4" width="2" height="2" fill="#8C8C8C" />
            <rect x="8" width="2" height="2" fill="#8C8C8C" />
        </svg>
    );
};

export default AssessmentBenchmarkScoreLegendIcon;
