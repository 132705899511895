export enum EUrlType {
    FULL_HTTP_URL = 'http',
    FULL_HTTPS_URL = 'https',
    RELATIVE_URL = 'nulia-works',
    CDN = 'nulia-cdn',
}

const generateFullUrl: (url: string) => string = (url: string) => {
    return url;
};

const generateRelativeUrl: (url: string) => string = (url: string) => {
    return `${window.location.origin}/${url.split('://')[1]}`;
};

const generateCDNUrl: (url: string) => string = (url: string) => {
    return process.env.REACT_APP_CDN_URL + url.split('://')[1];
};

export interface ICreateUrlResponse {
    type: EUrlType;
    generatedUrl: string;
    originalUrl: string;
    relativeUrl?: string;
}

export interface IFullVideoUrlsResponse {
    chaptersUrl: string;
    videoUrl: string;
    captionUrl: string;
}

const urlStrategies: { [key in EUrlType]: (url: string) => string } = {
    [EUrlType.FULL_HTTPS_URL]: generateFullUrl,
    [EUrlType.FULL_HTTP_URL]: generateFullUrl,
    [EUrlType.RELATIVE_URL]: generateRelativeUrl,
    [EUrlType.CDN]: generateCDNUrl,
};

export const createUrl: (url?: string) => ICreateUrlResponse | undefined = (url) => {
    if (!url) return undefined;
    const a = url.split('://')[0];
    if (!urlStrategies[a as EUrlType]) return undefined;
    return {
        type: a as EUrlType,
        generatedUrl: urlStrategies[a as EUrlType](url),
        originalUrl: url,
        relativeUrl: url.includes(`${EUrlType.RELATIVE_URL}://`)
            ? url.replace(`${EUrlType.RELATIVE_URL}:/`, '')
            : undefined,
    };
};

export const createVideoUrl: (url?: string) => ICreateUrlResponse | undefined = (url) => {
    if (!url) return undefined;
    const a = url.split('://')[0];
    if (!urlStrategies[a as EUrlType]) return undefined;

    let link = new URL(urlStrategies[a as EUrlType](url));
    link.searchParams.delete('chapters');

    return {
        type: a as EUrlType,
        generatedUrl: link.toString(),
        originalUrl: url,
        relativeUrl: url.includes(`${EUrlType.RELATIVE_URL}://`)
            ? url.replace(`${EUrlType.RELATIVE_URL}:/`, '')
            : undefined,
    };
};

export const createVideoSubtitleUrl: (url?: string) => ICreateUrlResponse | undefined = (url) => {
    if (!url) return undefined;
    const a = url.split('://')[0];
    if (!urlStrategies[a as EUrlType]) return undefined;

    let link = new URL(urlStrategies[a as EUrlType](url));
    link.searchParams.delete('chapters');

    return {
        type: a as EUrlType,
        generatedUrl: link.toString().substring(0, link.toString().lastIndexOf('.mp4')) + '.vtt',
        originalUrl: url,
        relativeUrl: url.includes(`${EUrlType.RELATIVE_URL}://`)
            ? url.replace(`${EUrlType.RELATIVE_URL}:/`, '')
            : undefined,
    };
};

export const createVideoChaptersUrl: (url?: string) => ICreateUrlResponse | undefined = (url) => {
    if (!url) return undefined;
    const a = url.split('://')[0];
    if (!urlStrategies[a as EUrlType]) return undefined;

    let link = new URL(urlStrategies[a as EUrlType](url));
    const chapterFileName = link.searchParams.get('chapters');
    link.searchParams.delete('chapters');

    return {
        type: a as EUrlType,
        generatedUrl: link.toString().substring(0, link.toString().lastIndexOf('/')) + '/' + chapterFileName,
        originalUrl: url,
        relativeUrl: url.includes(`${EUrlType.RELATIVE_URL}://`)
            ? url.replace(`${EUrlType.RELATIVE_URL}:/`, '')
            : undefined,
    };
};

export const createFullVideoUrls: (url?: string) => IFullVideoUrlsResponse | undefined = (url) => {
    if (!url) return undefined;
    const a = url.split('://')[0];
    if (!urlStrategies[a as EUrlType]) return undefined;

    let link = new URL(urlStrategies[a as EUrlType](url));
    const chapterFileName = link.searchParams.get('chapters');
    link.searchParams.delete('chapters');

    return {
        chaptersUrl: link.toString().substring(0, link.toString().lastIndexOf('/')) + '/' + chapterFileName,
        captionUrl: link.toString().substring(0, link.toString().lastIndexOf('.mp4')) + '.vtt',
        videoUrl: link.toString(),
    };
};
