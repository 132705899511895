import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '@mui/material';
import {
    RootBox,
    FooterBox,
    HeaderBox,
    ItemSpan,
    TitleBox,
    ItemTitleTypography,
    TitleTypography,
    HeaderRowBox,
    HeaderContentContainer,
} from './Style';
import { ITenantHistoryTargetVM } from '../../../../../../../interfaces/views/ITenantHistoryVM';
import { ETenantHistoryType } from '../../../../../../../interfaces/enums/ETenantHistoryType';
import Loading from '../../../../../../../ui/general/loading/Loading';
import { convertLogicalRolesToStringValues } from '../../../../../../../utils/convertLogicalRolesToStringValues';

interface IProps {
    status?: string;
    date: string;
    createdBy?: string;
    inProgressCount?: number;
    successCount?: number;
    failCount?: number;
    resultStatus?: string;
    type?: ETenantHistoryType;
    isLoading: boolean;
    targets?: ITenantHistoryTargetVM[];
}

const DetailsSummaryCard: FC<IProps> = ({
    type,
    date,
    createdBy,
    inProgressCount,
    successCount,
    failCount,
    isLoading,
    targets,
}) => {
    const { t } = useTranslation();

    const headerMemo = useMemo(() => {
        if (isLoading) return <Loading boxStyle={{ height: 'unset', paddingLeft: '80px' }} />;
        const skeletonJsx = <Skeleton width="30px" />;
        const dateJsx = (
            <ItemSpan>
                <ItemTitleTypography typography="button">{t('adminHistory.details.infoCard.date')}</ItemTitleTypography>
                {isLoading ? skeletonJsx : <ItemTitleTypography typography="body2">{date}</ItemTitleTypography>}
            </ItemSpan>
        );
        const createdByJsx = (
            <ItemSpan>
                <ItemTitleTypography typography="button">
                    {t('adminHistory.details.infoCard.createdBy')}
                </ItemTitleTypography>
                {isLoading ? skeletonJsx : <ItemTitleTypography typography="body2">{createdBy}</ItemTitleTypography>}
            </ItemSpan>
        );
        const inProgressJsx = (
            <ItemSpan>
                <ItemTitleTypography typography="button">
                    {t('adminHistory.details.infoCard.progress')}
                </ItemTitleTypography>
                {isLoading ? (
                    skeletonJsx
                ) : (
                    <ItemTitleTypography typography="body2">{inProgressCount}</ItemTitleTypography>
                )}
            </ItemSpan>
        );
        const successJsx = (
            <ItemSpan>
                <ItemTitleTypography typography="button">
                    {t('adminHistory.details.infoCard.success')}
                </ItemTitleTypography>
                {isLoading ? skeletonJsx : <ItemTitleTypography typography="body2">{successCount}</ItemTitleTypography>}
            </ItemSpan>
        );
        const failJsx = (
            <ItemSpan>
                <ItemTitleTypography typography="button">{t('adminHistory.details.infoCard.fail')}</ItemTitleTypography>
                {isLoading ? skeletonJsx : <ItemTitleTypography typography="body2">{failCount}</ItemTitleTypography>}
            </ItemSpan>
        );
        const resultJsx = (
            <ItemSpan>
                <ItemTitleTypography typography="button">
                    {t('adminHistory.details.infoCard.result')}
                </ItemTitleTypography>
                {isLoading ? skeletonJsx : <ItemTitleTypography typography="body2">{status}</ItemTitleTypography>}
            </ItemSpan>
        );
        switch (type) {
            case ETenantHistoryType.OUTCOME_ASSIGN:
            case ETenantHistoryType.OUTCOME_UNASSIGN:
                return (
                    <HeaderContentContainer>
                        <HeaderRowBox>
                            {dateJsx}
                            {createdByJsx}
                            {inProgressJsx}
                            {successJsx}
                            {failJsx}
                        </HeaderRowBox>
                        <HeaderRowBox>
                            <ItemTitleTypography typography="button">
                                {type === ETenantHistoryType.OUTCOME_ASSIGN &&
                                    t('adminHistory.details.infoCard.outcomesAssigned')}
                                {type === ETenantHistoryType.OUTCOME_UNASSIGN &&
                                    t('adminHistory.details.infoCard.outcomesRemoved')}
                            </ItemTitleTypography>
                            <ItemTitleTypography typography="body2">
                                {isLoading ? (
                                    skeletonJsx
                                ) : (
                                    <>
                                        {targets
                                            ?.map((target) => {
                                                return target.outcomeName;
                                            })
                                            .join(', ')}
                                    </>
                                )}
                            </ItemTitleTypography>
                        </HeaderRowBox>
                    </HeaderContentContainer>
                );
            case ETenantHistoryType.LICENSE_UNASSIGN:
            case ETenantHistoryType.LICENSE_ASSIGN:
                return (
                    <HeaderRowBox>
                        {dateJsx}
                        {createdByJsx}
                        {inProgressJsx}
                        {successJsx}
                        {failJsx}
                    </HeaderRowBox>
                );
            case ETenantHistoryType.SETTING_OFF:
            case ETenantHistoryType.SETTING_ON:
                return (
                    <HeaderContentContainer>
                        <HeaderRowBox>
                            {dateJsx}
                            {createdByJsx}
                            {resultJsx}
                        </HeaderRowBox>
                        <HeaderRowBox>
                            <ItemTitleTypography typography="button">
                                {t('adminHistory.details.infoCard.settingsChanged')}
                            </ItemTitleTypography>
                            <ItemTitleTypography typography="body2">
                                {targets && targets[0] && targets[0].setting}
                            </ItemTitleTypography>
                        </HeaderRowBox>
                    </HeaderContentContainer>
                );
            case ETenantHistoryType.ROLE_ASSIGN:
            case ETenantHistoryType.ROLE_UNASSIGN:
            case ETenantHistoryType.ROLE_UNASSIGN_ALL:
                return (
                    <HeaderContentContainer>
                        <HeaderRowBox>
                            {dateJsx}
                            {createdByJsx}
                            {inProgressJsx}
                            {successJsx}
                            {failJsx}
                        </HeaderRowBox>
                        <HeaderRowBox>
                            <ItemTitleTypography typography="button">
                                {type === ETenantHistoryType.ROLE_ASSIGN &&
                                    t('adminHistory.details.infoCard.roleAssigned')}
                                {(type === ETenantHistoryType.ROLE_UNASSIGN ||
                                    type === ETenantHistoryType.ROLE_UNASSIGN_ALL) &&
                                    t('adminHistory.details.infoCard.roleRemoved')}
                            </ItemTitleTypography>
                            <ItemTitleTypography typography="body2">
                                {type === ETenantHistoryType.ROLE_UNASSIGN_ALL && 'All Roles'}
                                {targets &&
                                    targets.length > 0 &&
                                    targets[0].userRole &&
                                    convertLogicalRolesToStringValues(targets[0].userRole)}
                            </ItemTitleTypography>
                        </HeaderRowBox>
                    </HeaderContentContainer>
                );
            default:
                return <></>;
        }
    }, [type, date, createdBy, inProgressCount, successCount, failCount, isLoading, status, targets]);

    const footerMemo = useMemo(() => {
        switch (type) {
            case ETenantHistoryType.OUTCOME_ASSIGN:
            case ETenantHistoryType.LICENSE_UNASSIGN:
            case ETenantHistoryType.ROLE_UNASSIGN:
            default:
                return <></>;
        }
    }, [type]);

    const titleMemo = useMemo(() => {
        if (!type) return <></>;
        switch (type) {
            case ETenantHistoryType.OUTCOME_ASSIGN:
                return t('adminHistory.details.title.assignOutcome');
            case ETenantHistoryType.OUTCOME_UNASSIGN:
                return t('adminHistory.details.title.removeOutcome');
            case ETenantHistoryType.LICENSE_ASSIGN:
                return t('adminHistory.details.title.assignLicense');
            case ETenantHistoryType.LICENSE_UNASSIGN:
                return t('adminHistory.details.title.removeLicense');
            case ETenantHistoryType.ROLE_ASSIGN:
                return t('adminHistory.details.title.assignRole');
            case ETenantHistoryType.ROLE_UNASSIGN:
                return t('adminHistory.details.title.removeRole');
            case ETenantHistoryType.OUTCOME_FOCUS_ON:
                return t('adminHistory.details.title.assignImportant');
            case ETenantHistoryType.OUTCOME_FOCUS_OFF:
                return t('adminHistory.details.title.removeImportant');
            case ETenantHistoryType.SETTING_ON:
            case ETenantHistoryType.SETTING_OFF:
                return t('adminHistory.details.title.changeSetting');
            default:
                return <></>;
        }
    }, [type, isLoading]);

    return (
        <RootBox>
            <TitleBox>
                <TitleTypography typography="overline">{titleMemo}</TitleTypography>
            </TitleBox>
            <HeaderBox>{headerMemo}</HeaderBox>
            <FooterBox>{footerMemo}</FooterBox>
        </RootBox>
    );
};

export default DetailsSummaryCard;
