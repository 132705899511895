import { FC, useCallback, useMemo } from 'react';
import {
    StyledMain,
    RootInnerBox,
    HeaderWrapper,
    AssignmentSpan,
    StyledTabs,
    HistoryWrapper,
    StyledTab,
} from './Style';
import TabPanel from '../../../ui/general/tab-panel/TabPanel';
import OutcomeSelection from './tabs/outcome-selection/OutcomeSelection';
import TextButton from '../../../ui/buttons/text-button/TextButton';
import { useAdminOutcomesStateValue } from '../../../contexts/AdminOutcomesContext';
import UserSelection from './tabs/user-selection/UserSelection';
import Assign from './tabs/assign/Assign';
import { useNavigate } from 'react-router';
import { routes } from '../../../pages/routes';
import { useCrumbsStateValue } from '../../../contexts/CrumbsContext';
import usePageName from '../../../hooks/usePageName';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const AdminOutcomes: FC = () => {
    const {
        activeStepIndex,
        changeActiveStepIndexTo,
        clearAssignment,
        selectedOutcomesList,
        selectedUsersList,
        isPostBulkAssignOutcomeSuccess,
        isPostBulkAssignOutcomeLoading,
        isSuccessAssignAction,
    } = useAdminOutcomesStateValue();
    const navigate = useNavigate();
    const { appendCrumb } = useCrumbsStateValue();
    usePageName({ pageName: 'Outcomes' });
    const theme = useTheme();
    const isMdDown = useMediaQuery(theme.breakpoints.down('md'));

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        changeActiveStepIndexTo(parseInt(newValue));
    };

    const viewOutcomeHistoryCallback = useCallback(() => {
        appendCrumb({
            name: 'Admin History',
            pathname: routes.HISTORY_OUTCOMES,
        });
        navigate(routes.HISTORY_OUTCOMES);
    }, []);

    const clearAssignmentHandler = () => {
        clearAssignment();
    };

    const newAssignmentHandler = () => {
        clearAssignment();
    };

    const tableMemo = useMemo(() => {
        return <UserSelection />;
    }, []);

    const outcomeAssignmentHistoryMemo = useMemo(() => {
        return (
            <HistoryWrapper>
                <TextButton
                    onClick={viewOutcomeHistoryCallback}
                    mode="primary"
                    style={{ paddingLeft: isMdDown ? 0 : '10px' }}
                >
                    VIEW OUTCOME ASSIGNMENT HISTORY
                </TextButton>
            </HistoryWrapper>
        );
    }, [viewOutcomeHistoryCallback]);

    return (
        <StyledMain>
            <RootInnerBox>
                <HeaderWrapper>
                    {isMdDown && outcomeAssignmentHistoryMemo}
                    <StyledTabs
                        id="organizational-outcomes-tabs"
                        value={activeStepIndex}
                        onChange={handleChange}
                        aria-label="organizational outcomes tabs"
                        tab={activeStepIndex}
                        // orientation={isLgDown ? 'vertical' : 'horizontal'}
                    >
                        <StyledTab
                            label="Outcome selection"
                            id="tab-0"
                            disabled={
                                isPostBulkAssignOutcomeLoading || (activeStepIndex === 2 && isSuccessAssignAction)
                            }
                        />
                        <StyledTab
                            label="User selection"
                            id="user-selection-tab"
                            disabled={
                                selectedOutcomesList?.length === 0 ||
                                isPostBulkAssignOutcomeLoading ||
                                (activeStepIndex === 2 && isSuccessAssignAction)
                            }
                        />
                        <StyledTab
                            label="Assign/Unassign"
                            id="assign-tab"
                            disabled={selectedUsersList?.length === 0 || selectedOutcomesList?.length === 0}
                        />
                        {isPostBulkAssignOutcomeSuccess ? (
                            <AssignmentSpan>
                                <TextButton
                                    style={{ whiteSpace: 'pre-wrap' }}
                                    onClick={newAssignmentHandler}
                                    mode="primary"
                                >
                                    New assignment
                                </TextButton>
                            </AssignmentSpan>
                        ) : (
                            selectedOutcomesList.length > 0 && (
                                <AssignmentSpan>
                                    <TextButton
                                        style={{ whiteSpace: 'pre-wrap' }}
                                        onClick={clearAssignmentHandler}
                                        mode="primary"
                                    >
                                        Clear assignment
                                    </TextButton>
                                </AssignmentSpan>
                            )
                        )}
                    </StyledTabs>
                    {activeStepIndex !== 2 && !isMdDown && outcomeAssignmentHistoryMemo}
                </HeaderWrapper>
                <TabPanel value={activeStepIndex} index={0}>
                    <OutcomeSelection />
                </TabPanel>
                <TabPanel value={activeStepIndex} index={1}>
                    {tableMemo}
                </TabPanel>
                <TabPanel value={activeStepIndex} index={2}>
                    <Assign />
                </TabPanel>
            </RootInnerBox>
        </StyledMain>
    );
};

export default AdminOutcomes;
