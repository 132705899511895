import styled from '../../../../../ui/theme/styled';

export const RootBox = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
});

export const BadgeImgWrapper = styled('div')(({ theme }) => ({
    [theme.breakpoints.down('lg')]: {
        minWidth: '180px',
    },
    [theme.breakpoints.down('md')]: {
        minWidth: '110px',
    },
}));

export const BadgeImg = styled('img')({
    width: '100px',
    height: '100px',
});
