import { styled } from '@mui/material/styles';
import { Paper, Select, Typography } from '@mui/material';

export const OutcomeCardWrapper = styled('div')({
    width: '100%',
});

export const OutcomesListWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'all 300ms',
    opacity: 1,
    minHeight: 140,
    paddingTop: 16,
    '&.preAnimation': {
        opacity: 0,
        maxHeight: 0,
    },
});

export const HeaderWrapper = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 16,
});

export const TitleBox = styled('span')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.main,
}));

export const CardTitleTypography = styled(Typography)(() => ({}));

export const SkillCountBox = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,
    marginLeft: '8px',
    paddingTop: '1px',
}));

export const FilterLabel = styled('span')({
    letterSpacing: '2px',
    textTransform: 'uppercase',
    fontFamily: 'Ubuntu',
    lineHeight: '16px',
    fontSize: '12px',
});

export const FilterSelect = styled(Select)(({ theme }) => ({
    width: '160px',
    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
    },
    [theme.breakpoints.down('md')]: {
        width: '87px',
        '& .MuiSelect-select': {
            fontSize: '12px !important',
        },
    },
}));

export const FilterBox = styled('div', {
    shouldForwardProp: (prop) => prop !== 'isDisabled',
})<{ isDisabled: boolean }>(({ isDisabled, theme }) => ({
    display: 'flex',
    gap: 12,
    alignItems: 'center',
    pointerEvents: isDisabled ? 'none' : 'unset',
    opacity: isDisabled ? 0.4 : 1,
    marginRight: 6,
    [theme.breakpoints.down('md')]: {
        marginRight: 0,
        gap: '2px',
    },
}));

export const OrderSpan = styled('span')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    padding: 4,
    borderRadius: 20,
    '&:hover': {
        backgroundColor: theme.palette.background.default,
    },
    '& svg': {
        [theme.breakpoints.down('md')]: {
            width: '20px',
            height: '20px',
        },
    },
}));

export const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: 16,
    flex: 11,
    height: 'fit-content',
    borderRadius: theme.borderRadius?.outside?.small,
}));

export const StyledMain = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
});

export const RootInnerBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '12px',
    width: 1080,
    [theme.breakpoints.down('lg')]: {
        width: '100%',
    },
}));

export const MainContentBox = styled('div')({
    display: 'flex',
});

export const SideBox = styled('div')(({ theme }) => ({
    flex: 4,
    display: 'flex',
    flexDirection: 'column',
    padding: '0 20px',
    gap: 32,
    [theme.breakpoints.down('md')]: {
        padding: 0,
    },
}));

export const SkillsStatusOverallWrapper = styled('div')<{
    isDisabled: boolean;
}>(({ isDisabled }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    gap: '10px',
    paddingBottom: 12,
    flex: 1,
    pointerEvents: isDisabled ? 'none' : 'unset',
    opacity: isDisabled ? 0.4 : 1,
}));

export const FiltersWrapper = styled('div')({
    flex: 100,
});
