import * as d3 from 'd3';

export const d3Star = () => {
    var size = 17,
        x = 0,
        y = 0,
        value = 1.0, //Range is 0.0 - 1.0
        borderWidth = 3,
        borderColor = 'black',
        starColor = '#FFB500',
        backgroundColor = 'white';

    function star(selection: any) {
        var line = d3
                .line<{ x: any; y: any }>()
                .x(function (d) {
                    return d.x;
                })
                .y(function (d) {
                    return d.y;
                }),
            rad = function (deg: number) {
                return (deg * Math.PI) / 180;
            },
            cos = function (deg: number) {
                return Math.cos(rad(deg));
            },
            sin = function (deg: number) {
                return Math.sin(rad(deg));
            },
            tan = function (deg: number) {
                return Math.tan(rad(deg));
            },
            n = size,
            m = n / 2,
            h = m * tan(36),
            k = h / sin(72),
            //(x, y) points at the leftmost point of the star, not the center
            coordinates = [
                { x: x, y: y + 1.3 },
                { x: x + k - 0.5, y: y - 0.5 },
                { x: x + m, y: y - h },
                { x: x + n - k + 0.5, y: y - 0.5 },
                { x: x + n, y: y + 1.3 },
                { x: x + n - k * cos(50), y: y + k * sin(44) },
                { x: x + n * cos(36), y: y + n * sin(36) },
                { x: x + m, y: y + h + 2.2 },
                { x: x + n - n * cos(36), y: y + n * sin(36) },
                { x: x + k * cos(50), y: y + k * sin(44) },
            ];

        //inside star
        selection.append('path').attr('d', line(coordinates)).style('strokeWidth', 0).style('fill', starColor);

        //Rect for clipping
        //In order to avoid potential ID duplicates for clipping, clip-path is not used here
        selection
            .append('rect')
            .attr('x', x + size * value)
            .attr('y', y - h)
            .attr('width', size - size * value)
            .attr('height', size)
            .style('fill', backgroundColor);

        //border of the star
        selection
            .append('path')
            .attr('d', line(coordinates) + 'Z')
            .style('strokeWidth', borderWidth)
            .style('fill', 'none')
            .style('stroke', borderColor);
    }

    star.x = function (val: number) {
        x = val;
        return star;
    };

    star.y = function (val: number) {
        y = val;
        return star;
    };

    star.size = function (val: number) {
        size = val;
        return star;
    };

    //Range is 0.0 - 1.0. 0.0 shows, for example, an empty star
    star.value = function (val: number) {
        value = val;
        return star;
    };

    star.backgroundColor = function (val: string) {
        backgroundColor = val;
        return star;
    };

    star.borderWidth = function (val: number) {
        borderWidth = val;
        return star;
    };

    star.borderColor = function (val: string) {
        borderColor = val;
        return star;
    };

    star.starColor = function (val: string) {
        starColor = val;
        return star;
    };

    return star;
};
