import { useMutation, UseMutationResult, useQuery, UseQueryResult } from '@tanstack/react-query';
import { getTokenAndHeaders } from './helpers/apiUtil';
import useAuthParams from '../hooks/useAuthParams';
import { IAuthParams } from '../interfaces/IAuthParams';
import { IAdUserVM } from '../interfaces/views/IAdUserVM';
import { mapAdUsers } from './helpers/mappers';
import { apiInstance } from './helpers/apiInstance';
import { ILicensesCountDTO } from '../interfaces/dtos/ILicensesCountDTO';
import { ENuliaLicenseType } from '../interfaces/enums/ENuliaLicenseType';
import { ELicenseAssignmentType } from '../interfaces/enums/ELicenseAssignmentType';
import { EApiQueryKey } from '../interfaces/enums/EApiQueryKey';

export interface IUserImageResponse {
    contentType: string;
    data: string;
    oid: string;
}

export interface IUserImageRequest {
    oids: string[];
}

export interface IPostAssignLicenseRequest {
    id: string;
}

export interface IPostRemoveLicenseRequest {
    id: string;
}

export interface IPostAssignLicenseResponse {
    platformId: string;
    nuliaLicenseType: ENuliaLicenseType;
}

export interface IPostRemoveLicenseResponse {
    platformId: string;
    nuliaLicenseType: ENuliaLicenseType;
}

export interface IPostBulkAssignmentLicenseResponse {}

export interface IPostBulkAssignmentLicenseRequest {
    targetUserIds: string[];
    assignmentType: ELicenseAssignmentType;
}

export interface IPostDownloadBadgeResponse {
    url: string;
}

export interface IPostDownloadBadgeRequest {
    outcomeId: number;
    level: string;
}

// GET AD USERS

export const getAdUsers = async (authParams: IAuthParams) => {
    const { headers } = await getTokenAndHeaders(authParams);
    const {
        data: { data },
    } = await apiInstance.get(`licenses/users`, headers);
    return mapAdUsers(data);
};

export const useGetAdUsersQuery: () => UseQueryResult<IAdUserVM[], Error> = () => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.LICENSE_FETCH_USERS],
        queryFn: async () => {
            return getAdUsers(authParams);
        },
        refetchOnWindowFocus: false,
    });
};

// GET LICENSES COUNT

export const getLicensesCount = async (authParams: IAuthParams) => {
    const { headers } = await getTokenAndHeaders(authParams);
    const {
        data: { data },
    } = await apiInstance.get(`licenses/count`, headers);
    return data;
};

export const useGetLicensesCountQuery: () => UseQueryResult<ILicensesCountDTO, Error> = () => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.LICENSE_FETCH_COUNT],
        queryFn: async () => {
            return getLicensesCount(authParams);
        },
        refetchOnWindowFocus: false,
    });
};

// ASSIGN LICENSE
export const postAssignLicense: (
    variables: IPostAssignLicenseRequest & { authParams: IAuthParams }
) => Promise<IPostAssignLicenseResponse> = async (variables) => {
    const { authParams, id } = variables;
    const { headers } = await getTokenAndHeaders(authParams);

    const {
        data: { data },
    } = await apiInstance.post(
        `licenses/assign`,
        {
            id,
        },
        headers
    );
    return data;
};

export const usePostAssignLicense: () => UseMutationResult<
    IPostAssignLicenseResponse,
    Error,
    IPostAssignLicenseRequest
> = () => {
    const authParams = useAuthParams();
    return useMutation({
        mutationFn: (variables: IPostAssignLicenseRequest) => {
            return postAssignLicense({ ...variables, authParams });
        },
    });
};

// REMOVE LICENSE
export const postRemoveLicense: (
    variables: IPostRemoveLicenseRequest & { authParams: IAuthParams }
) => Promise<IPostRemoveLicenseResponse> = async (variables) => {
    const { authParams, id } = variables;
    const { headers } = await getTokenAndHeaders(authParams);

    const {
        data: { data },
    } = await apiInstance.post(
        `licenses/remove`,
        {
            id,
        },
        headers
    );
    return data;
};

export const usePostRemoveLicense: () => UseMutationResult<
    IPostRemoveLicenseResponse,
    Error,
    IPostRemoveLicenseRequest
> = () => {
    const authParams = useAuthParams();
    return useMutation({
        mutationFn: (variables: IPostRemoveLicenseRequest) => {
            return postRemoveLicense({ ...variables, authParams });
        },
    });
};

// BULK ASSIGNMENT LICENSE
export const postBulkAssignmentLicense: (
    variables: IPostBulkAssignmentLicenseRequest & { authParams: IAuthParams }
) => Promise<undefined> = async (variables) => {
    const { authParams, targetUserIds, assignmentType } = variables;
    const { headers } = await getTokenAndHeaders(authParams);

    await apiInstance.post(
        `/bulk/license/assignment`,
        {
            targetUserIds,
            assignmentType,
        },
        headers
    );
    return undefined;
};

export const usePostBulkAssignmentLicense: () => UseMutationResult<
    undefined,
    Error,
    IPostBulkAssignmentLicenseRequest
> = () => {
    const authParams = useAuthParams();
    return useMutation({
        mutationFn: (variables: IPostBulkAssignmentLicenseRequest) => {
            return postBulkAssignmentLicense({ ...variables, authParams });
        },
    });
};

// DOWNLOAD BADGE
export const postDownloadBadge: (
    variables: IPostDownloadBadgeRequest & { authParams: IAuthParams }
) => Promise<IPostDownloadBadgeResponse> = async (variables) => {
    const { authParams, outcomeId, level } = variables;
    const { headers } = await getTokenAndHeaders(authParams);

    const {
        data: { data },
    } = await apiInstance.post(
        `badges/image`,
        {
            outcomeId,
            level,
        },
        headers
    );
    return data;
};

export const usePostDownloadBadge: () => UseMutationResult<
    IPostDownloadBadgeResponse,
    Error,
    IPostDownloadBadgeRequest
> = () => {
    const authParams = useAuthParams();
    return useMutation({
        mutationFn: (variables: IPostDownloadBadgeRequest) => {
            return postDownloadBadge({ ...variables, authParams });
        },
    });
};

export const downloadBadgeImage: (variables: { url: string }) => Promise<any> = async (variables) => {
    const { url } = variables;

    const response = await apiInstance.get(url, {
        headers: {
            'Content-Type': 'image/png',
        },
    });

    return response;
};
