import styled from '../theme/styled';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

interface ModalProps {
    x: string | number;
    y: string | number;
    width: string | number;
    height: string | number;
}

export const StyledDialog = styled(Dialog)<ModalProps>(({ x, y, width, height }) => ({
    'MuiBackdrop-root': {
        opacity: 0,
    },
    '.MuiDialog-container': {
        width: width || 'fit-content',
        height: height || 'fit-content',
        position: 'absolute',
        top: y,
        left: x,
    },
    '.MuiDialog-root': {
        display: 'flex',
        justifyContent: 'center',
    },
    root: {
        pointerEvents: 'none',
    },
    paper: {
        pointerEvents: 'auto',
    },
    scrollPaper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginRight: 20,
    },
}));

export const StyledDialogTitle = styled(DialogTitle)({
    cursor: 'move',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
});

export const StyledDialogContent = styled(DialogContent)({
    flexGrow: 1,
    border: 'none',
    margin: 0,
    padding: 0,
    overflow: 'hidden',
});

export const StyledIFrame = styled('iframe')({
    width: '100%',
    height: '100%',
    border: 0,
});

export const DialogActionBox = styled('div')({
    display: 'flex',
    justifyContent: 'flex-end',
    width: 70,
    gap: 12,
});
