import { FC, PropsWithChildren, useMemo, useRef, useState, KeyboardEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import GraphCard from '../../../../../pages/insights/components/graph/graph-card/GraphCard';
import Table from '../../../../../ui/general/table/Table';
import { useInsightsPersonalEngagementStateValue } from '../../../../../contexts/InsightsPersonalEngagementContext';
import { ITableHeadCell } from '../../../../../interfaces/ITableHeaderCell';
import {
    GraphCardToolbarFormControl,
    GraphCardToolbarFormControlLabel,
    GraphCardToolbarRadio,
    GraphCardToolbarRadioGroup,
    GraphCardToolbarValueBox,
    RootBox,
} from '../../../../../pages/insights/Style';
import { TableWrapper, ToolbarBox, ToolbarTitleNameTypography, ToolbarTitleValueTypography } from './Style';
import BarChart from '../../../../../ui/general/bar-chart/BarChart';
import CheckboxUncheckedIcon from '../../../../../assets/icons/CheckboxUncheckedIcon';
import CheckboxCheckedIcon from '../../../../../assets/icons/CheckboxCheckedIcon';
import useInsightsTimelineOption from '../../../../../hooks/useInsightsTimelineOption';
import { EGraphCardSelect } from '../../../../../interfaces/enums/EGraphCardSelect';
import { IInsightsPersonalEngagementPointVM } from '../../../../../interfaces/views/IInsightsPersonalEngagementPointVM';
import { ILoggedInVM } from '../../../../../interfaces/views/ILoggedInVM';
import { EAxisType } from '../../../../../interfaces/enums/EAxisType';
import { formatDate } from '../../../../../utils/dateUtil';

interface IEngagementTableHeader {
    date: string;
    action: string;
    points: boolean;
}

interface ILoggedInTableHeader {
    date: string;
    count: number;
}

enum EGraphOptionValue {
    ENGAGEMENT_POINTS = 'Engagementpoints',
    TIMES_LOGGED_IN = 'Logins',
}

interface EGraphOption {
    value: EGraphOptionValue;
    label: string;
}

const PersonalEngagementTab: FC<PropsWithChildren> = () => {
    const { t } = useTranslation();
    const {
        engagementData,
        loggedInData,
        changeScoreLinesInterval,
        engagementGraphData,
        loggedInGraphData,
        isEngagementDataLoading,
        isLoggedInDataLoading,
        isEngagementDataError,
        isLoggedInDataError,
    } = useInsightsPersonalEngagementStateValue();

    const { handleTimelineChange, activeTimelineOption } = useInsightsTimelineOption({
        changedTimelineOptionCallback: changeScoreLinesInterval,
        initialTimelineOptionValue: EGraphCardSelect.MONTH_4,
    });
    const [selectedGraphOption, setSelectedGraphOption] = useState<EGraphOptionValue>(
        EGraphOptionValue.ENGAGEMENT_POINTS
    );
    const graphOptionsRef = useRef<EGraphOption[]>([
        { value: EGraphOptionValue.ENGAGEMENT_POINTS, label: 'Engagement Points' },
        { value: EGraphOptionValue.TIMES_LOGGED_IN, label: 'Times Logged In' },
    ]);

    const engagementHeadCells: ITableHeadCell<IEngagementTableHeader>[] = [
        {
            id: 'date',
            label: 'Date',
            minWidth: '120px',
            customRender: (value) => {
                return formatDate(value);
            },
        },
        {
            id: 'action',
            label: 'Your Activity',
            minWidth: '130px',
        },
        {
            id: 'points',
            label: 'Points',
            align: 'center',
        },
    ];

    const loggedInHeadCells: ITableHeadCell<ILoggedInTableHeader>[] = [
        {
            id: 'date',
            label: 'Week of',
            maxWidth: '200px',
            customRender: (value: Date) => {
                if (value) return format(value, 'MMM do, yyyy');
                return <></>;
            },
        },
        {
            id: 'count',
            align: 'center',
            label: '# of Logins',
        },
    ];

    const toolbarEngagement = useMemo(() => {
        return (
            <ToolbarBox>
                <ToolbarTitleNameTypography variant="body1">
                    {t('insights.personal.engagement.engagementPointsTableToolbar')}
                </ToolbarTitleNameTypography>
                <ToolbarTitleValueTypography>
                    {engagementData?.reduce((acc, item) => acc + item.points, 0) || '0'}
                </ToolbarTitleValueTypography>
            </ToolbarBox>
        );
    }, [engagementData]);

    const toolbarLoggedIn = useMemo(() => {
        return (
            <ToolbarBox>
                <ToolbarTitleNameTypography variant="body1">
                    {t('insights.personal.engagement.loggedInTableToolbar')}
                </ToolbarTitleNameTypography>
                <ToolbarTitleValueTypography>
                    {loggedInData.reduce((acc, item) => acc + item.count, 0)}
                </ToolbarTitleValueTypography>
            </ToolbarBox>
        );
    }, [loggedInData]);

    const tableMemo = useMemo(() => {
        switch (selectedGraphOption) {
            case EGraphOptionValue.ENGAGEMENT_POINTS:
                return (
                    <Table<IInsightsPersonalEngagementPointVM, IEngagementTableHeader>
                        key={`${EGraphOptionValue.ENGAGEMENT_POINTS}-${isEngagementDataLoading}`}
                        headCells={engagementHeadCells}
                        data={engagementData}
                        propertyKeys={engagementHeadCells.map((headCell) => {
                            return headCell.id;
                        })}
                        tableTitlePlural=""
                        isFilterControlVisible={false}
                        isFilterDrawerOpen={false}
                        isLoading={isEngagementDataLoading}
                        isError={isEngagementDataError}
                        customToolbar={toolbarEngagement}
                        initialOrderBy="date"
                        initialOrderDirection="desc"
                        toolbarCellStyle={{ borderBottom: '1px solid rgba(0, 0, 0, 0.16)' }}
                    />
                );
            case EGraphOptionValue.TIMES_LOGGED_IN:
                return (
                    <Table<ILoggedInVM, ILoggedInTableHeader>
                        key={`${EGraphOptionValue.TIMES_LOGGED_IN}-${isLoggedInDataLoading}}`}
                        headCells={loggedInHeadCells}
                        data={loggedInData}
                        propertyKeys={loggedInHeadCells.map((headCell) => {
                            return headCell.id;
                        })}
                        tableTitlePlural=""
                        isFilterControlVisible={false}
                        isFilterDrawerOpen={false}
                        isLoading={isLoggedInDataLoading}
                        isError={isLoggedInDataError}
                        customToolbar={toolbarLoggedIn}
                        initialOrderBy="date"
                        toolbarCellStyle={{ borderBottom: '1px solid rgba(0, 0, 0, 0.16)' }}
                    />
                );
            default:
                return <></>;
        }
    }, [
        selectedGraphOption,
        engagementData,
        loggedInData,
        isEngagementDataError,
        isLoggedInDataError,
        isEngagementDataLoading,
        isLoggedInDataLoading,
    ]);

    const handleRadioButtonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = (event.target as HTMLInputElement).value;
        setSelectedGraphOption(value as EGraphOptionValue);
    };

    const onRadioOptionKeyDown = (e: KeyboardEvent<HTMLButtonElement>) => {
        if (e.key === 'Enter') {
            handleRadioButtonChange(e as unknown as React.ChangeEvent<HTMLInputElement>);
        }
    };

    const customGraphCardToolbar = useMemo(() => {
        return (
            <GraphCardToolbarFormControl>
                <GraphCardToolbarRadioGroup
                    value={selectedGraphOption}
                    id="tab-mode"
                    name={`controlled-radio-buttons-group`}
                >
                    {graphOptionsRef.current.map((option) => {
                        return (
                            <GraphCardToolbarValueBox key={option.value} variant="body2">
                                <GraphCardToolbarFormControlLabel
                                    value={option.value}
                                    control={
                                        <GraphCardToolbarRadio
                                            onChange={handleRadioButtonChange}
                                            icon={<CheckboxUncheckedIcon />}
                                            checkedIcon={<CheckboxCheckedIcon />}
                                            onKeyDown={onRadioOptionKeyDown}
                                            tabIndex={0}
                                            checked={selectedGraphOption === option.value}
                                        />
                                    }
                                    label={option.label}
                                    color="primary"
                                />
                            </GraphCardToolbarValueBox>
                        );
                    })}
                </GraphCardToolbarRadioGroup>
            </GraphCardToolbarFormControl>
        );
    }, [selectedGraphOption]);

    const createChildren = (dimension: any, isEmpty: boolean, isChartLoading: boolean) => {
        const data =
            selectedGraphOption === EGraphOptionValue.ENGAGEMENT_POINTS ? engagementGraphData : loggedInGraphData;
        const isLoading =
            selectedGraphOption === EGraphOptionValue.ENGAGEMENT_POINTS
                ? isEngagementDataLoading
                : isLoggedInDataLoading;
        return (
            <BarChart
                dimension={dimension}
                chartId="bar-chart"
                data={data}
                isDataEmpty={!isLoading && data?.length === 0}
                xAxisType={
                    activeTimelineOption === EGraphCardSelect.YEAR_WITH_WEEKS
                        ? EAxisType.MONTHLY_WITH_WEEKS
                        : EAxisType.LINEAR
                }
                isLoading={isChartLoading}
            />
        );
    };

    return (
        <RootBox>
            <GraphCard
                customToolbar={customGraphCardToolbar}
                handleTimelineChange={handleTimelineChange}
                translations={{ graphCardTitle: t('insights.organization.engagement.graphCardTitle') }}
                timelineFilterMenuOptions={[
                    {
                        key: EGraphCardSelect.MONTH_4,
                        name: '4-Months',
                    },
                    {
                        key: EGraphCardSelect.YEAR_WITH_WEEKS,
                        name: '1-Year',
                    },
                ]}
                isLoading={isEngagementDataLoading}
            >
                {createChildren}
            </GraphCard>
            <TableWrapper>{tableMemo}</TableWrapper>
        </RootBox>
    );
};

export default PersonalEngagementTab;
