import { routes } from '../pages/routes';
import { useState, useEffect, useContext } from 'react';
import ReactDOMServer from 'react-dom/server';
import { matchPath, useLocation } from 'react-router';
import 'shepherd.js/dist/css/shepherd.css';
import '../../dist/css/Shepherd.scss';
import {
    // CloseDrawerActionWrapper,
    // CloseDrawerBox,
    // FilterDrawerBox,
    ShepherdHeaderTypography,
    ShepherdContentBox,
    ShepherdTextBox,
    ShepherdList,
    ShepherdListItem,
    ShepherdVerticalCenteredBox,
    ShepherdImageBox
} from '../css/CommonComponents';
import SwitchLeftArrowIcon from '../assets/icons/SwitchLeftArrowIcon';
import SwitchRightArrowIcon from '../assets/icons/SwitchRightArrowIcon';
import { ShepherdTourContext } from 'react-shepherd';
import SkillNoTarget from '../assets/wizard/SkillNoTarget';
import SkillTargetNotAchieved from '../assets/wizard/SkillTargetNotAchieved';
import SkillTargetAchieved from '../assets/wizard/SkillTargetAchieved';
import SkillNeedAttention from '../assets/wizard/SkillNeedAttention';
import MasterLevelRequired from '../assets/wizard/MasterLevelRequired';
import PipsGeneral from '../assets/wizard/PipsGeneral';
import PipsMaster from '../assets/wizard/PipsMaster';
import PipsUnasigned from '../assets/wizard/PipsUnasigned';
import BadgeCanBeClaimed from '../assets/wizard/BadgeCanBeClaimed';
import BadgeNeedsAttention from '../assets/wizard/BadgeNeedsAttention';
import BadgeIsClaimedProducerMaster from '../assets/wizard/BadgeIsClaimedProducerMaster';
import ShareBadge from '../assets/wizard/ShareBadge';
import ClaimBadge from '../assets/wizard/ClaimBadge';
import DownloadBadge from '../assets/wizard/DownloadBadge';
import { useTheme } from '@mui/system';
import { useUserAuthStateValue } from '../contexts/UserAuthContext';
import { ETenantPreference } from '../interfaces/enums/ETenantPreference';
import SkillBestPractice from '../assets/wizard/SkillBestPractice';
import ClipboardCheck from '../assets/icons/ClipboardCheck';
import BadgeNotEarnedProducerMaster from '../assets/wizard/BadgeNotEarnedProducerMaster';

interface IUsePageHeader {
    config: IPageHeaderConfig;
    tourOptions: any;
    startTour: () => void;
    stopTour: () => void;
    isTourActive: boolean;
}

interface IPageHeaderConfig {
    wizardSteps?: any[];
}

export const tourOptions = {
    defaultStepOptions: {
        scrollTo: true,
        cancelIcon: {
            enabled: true
        },
        scrollToHandler: (e: any) => {
            if (e && e.scrollIntoView) e.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
        }
    },
    useModalOverlay: true
};

const basicWizardStepConfig = {
    classes: 'basicShepherd marginTopBig',
    highlightClass: 'highlight',
    cancelIcon: {
        enabled: true
    },
    canClickTOarget: false,
    scrollTo: { behavior: 'smooth', block: 'center' },
    modalOverlayOpeningRadius: 5
};

const generateTitle = (title: string) => {
    return ReactDOMServer.renderToStaticMarkup(
        <ShepherdHeaderTypography variant='overline'>{title}</ShepherdHeaderTypography>
    );
};

const useWizardConfig: () => IUsePageHeader = () => {
    const [config, setConfig] = useState<IPageHeaderConfig>({
        wizardSteps: []
    });
    const location = useLocation();
    const tour = useContext(ShepherdTourContext);
    const theme = useTheme();
    const { isTenantPreferenceActive } = useUserAuthStateValue();

    const startTour = () => {
        if (config.wizardSteps) {
            //@ts-ignore
            tour?.addSteps(config.wizardSteps);
            if (tour) {
                tour?.start();

                const modalOverlayHtmlCollection = document.getElementsByClassName('shepherd-modal-overlay-container');
                if (modalOverlayHtmlCollection && modalOverlayHtmlCollection.length > 0) {
                    modalOverlayHtmlCollection[0].addEventListener('click', (e) => {
                        tour?.cancel();
                        e.stopPropagation();
                        e.preventDefault();
                    });
                }
            }
        }
    };

    const stopTour = () => {
        //@ts-ignore
        if (tour) tour?.cancel();
    };

    const generateWizardButtons = (index: number, total: number) => {
        return [
            {
                classes: 'button sign-in-button',
                text: ReactDOMServer.renderToStaticMarkup(
                    <SwitchLeftArrowIcon height='22px' width='10px' isDisabled={index === 1} />
                ),
                type: 'back',
                action() {
                    if (index !== 1) {
                        // @ts-ignore
                        this.back();
                    }
                }
            },
            {
                text: ReactDOMServer.renderToStaticMarkup(
                    <span
                        style={{
                            color: theme.palette.primary.main,
                            position: 'relative',
                            bottom: '2px',
                            fontSize: '10px',
                            lineHeight: '20px',
                            letterSpacing: '0.42px',
                            fontFamily: 'Open Sans'
                        }}
                    >
                        {index}/{/* {tour.steps.findIndex((step) => step.id === tour.getCurrentStep()?.id) + 1}/ */}
                        {total}
                    </span>
                )
            },
            {
                classes: 'button sign-in-button',
                text: ReactDOMServer.renderToStaticMarkup(
                    <SwitchRightArrowIcon height='22px' width='10px' isDisabled={index === total} />
                ),
                type: 'next',
                action() {
                    if (index !== total) {
                        // EXAMPLE ROUTING
                        // navigate(
                        //     generatePath(routes.ADMIN_HISTORY_DETAILS, { id: '52c944e7-73cd-4ea6-aff9-822770d1cd33' })
                        // );
                        // @ts-ignore
                        this.next();
                    }
                }
            }
        ];
    };

    useEffect(() => {
        if (matchPath(routes.USER_OUTCOMES, location.pathname)) {
            setConfig({
                wizardSteps: [
                    {
                        ...basicWizardStepConfig,
                        id: 'intro',
                        attachTo: { element: '.outcome-filter-buttons', on: 'bottom' },
                        buttons: generateWizardButtons(1, 7),
                        title: generateTitle('Details about Your Outcomes'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>
                                        See a quick view of where you are with your Outcomes, and select any status to
                                        get a list of the Outcomes for that box
                                    </ShepherdTextBox>
                                    <ShepherdTextBox>
                                        You can see the full catalog of Nulia Works Outcomes by unselecting each Outcome
                                        status
                                    </ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    },
                    {
                        ...basicWizardStepConfig,
                        id: 'recommendations',
                        attachTo: { element: '#recommendations-box', on: 'bottom' },
                        buttons: generateWizardButtons(2, 7),
                        title: generateTitle('Discover More'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>
                                        See personalized recommendations related to your Outcomes
                                    </ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    },
                    {
                        ...basicWizardStepConfig,
                        id: 'outcomeIcon',
                        attachTo: { element: '.dotted-circles-wrapper-0', on: 'bottom' },
                        buttons: generateWizardButtons(3, 7),
                        title: generateTitle('Your Outcome Progress'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdList>
                                        <ShepherdListItem>
                                            <ShepherdVerticalCenteredBox>
                                                <ShepherdImageBox>
                                                    <PipsUnasigned />
                                                </ShepherdImageBox>
                                                <ShepherdTextBox>
                                                    Outcomes that you don't have assigned will not have any dots
                                                </ShepherdTextBox>
                                            </ShepherdVerticalCenteredBox>
                                        </ShepherdListItem>
                                        <ShepherdListItem>
                                            <ShepherdVerticalCenteredBox>
                                                <ShepherdImageBox>
                                                    <PipsGeneral />
                                                </ShepherdImageBox>
                                                <ShepherdTextBox>
                                                    The dots below an Outcome show how many Skills are included and how
                                                    you are progressing toward the Skills in that Outcome - Green
                                                    indicates you have attained the Skill, Yellow means it's in
                                                    progress, and Orange shows that it's in Need Attention
                                                </ShepherdTextBox>
                                            </ShepherdVerticalCenteredBox>
                                        </ShepherdListItem>
                                        <ShepherdListItem>
                                            <ShepherdVerticalCenteredBox>
                                                <ShepherdImageBox>
                                                    <PipsMaster />
                                                </ShepherdImageBox>
                                                <ShepherdTextBox>
                                                    If you have assigned an Outcome at Master level, you will see a
                                                    label beneath the dots
                                                </ShepherdTextBox>
                                            </ShepherdVerticalCenteredBox>
                                        </ShepherdListItem>
                                    </ShepherdList>
                                </ShepherdContentBox>
                            )
                        ]
                    },
                    {
                        ...basicWizardStepConfig,
                        id: 'outcomeIndicators',
                        attachTo: { element: '.org-priority-master-level-box', on: 'bottom' },
                        buttons: generateWizardButtons(4, 7),
                        title: generateTitle('Outcome Indicators'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdList>
                                        <ShepherdListItem>
                                            <ShepherdVerticalCenteredBox>
                                                <ShepherdImageBox>
                                                    <ClipboardCheck height={28} width={28} />
                                                </ShepherdImageBox>
                                                <ShepherdTextBox>
                                                    Outcomes identified by your organization as a priority have this
                                                    indicator
                                                </ShepherdTextBox>
                                            </ShepherdVerticalCenteredBox>
                                        </ShepherdListItem>
                                        <ShepherdListItem>
                                            <ShepherdVerticalCenteredBox>
                                                <ShepherdImageBox>
                                                    <MasterLevelRequired />
                                                </ShepherdImageBox>
                                                <ShepherdTextBox>
                                                    If you see this indicator, your organization requests that you
                                                    upgrade to Master level for this Outcome
                                                </ShepherdTextBox>
                                            </ShepherdVerticalCenteredBox>
                                        </ShepherdListItem>
                                    </ShepherdList>
                                </ShepherdContentBox>
                            )
                        ]
                    },
                    {
                        ...basicWizardStepConfig,
                        id: 'outcomeViewDescription',
                        attachTo: { element: '#description-control-0', on: 'bottom' },
                        buttons: generateWizardButtons(5, 7),
                        title: generateTitle('View Description'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdList>
                                        <ShepherdTextBox style={{ marginBottom: '20px' }}>
                                            Select View Description to discover more about an Outcome, including the
                                            status of your badges
                                        </ShepherdTextBox>
                                        <ShepherdListItem>
                                            <ShepherdVerticalCenteredBox>
                                                <ShepherdImageBox>
                                                    <BadgeNotEarnedProducerMaster />
                                                </ShepherdImageBox>
                                                <ShepherdTextBox>
                                                    If a badge is grayed out, it is not yet earned
                                                </ShepherdTextBox>
                                            </ShepherdVerticalCenteredBox>
                                        </ShepherdListItem>
                                        <ShepherdListItem>
                                            <ShepherdVerticalCenteredBox>
                                                <ShepherdImageBox>
                                                    <BadgeCanBeClaimed />
                                                </ShepherdImageBox>
                                                <ShepherdTextBox>
                                                    This Badge has been earned and is ready to be claimed
                                                </ShepherdTextBox>
                                            </ShepherdVerticalCenteredBox>
                                        </ShepherdListItem>
                                        <ShepherdListItem>
                                            <ShepherdVerticalCenteredBox>
                                                <ShepherdImageBox>
                                                    <BadgeIsClaimedProducerMaster />
                                                </ShepherdImageBox>
                                                <ShepherdTextBox>
                                                    This Badge has been claimed and is current
                                                </ShepherdTextBox>
                                            </ShepherdVerticalCenteredBox>
                                        </ShepherdListItem>
                                        <ShepherdListItem>
                                            <ShepherdVerticalCenteredBox>
                                                <ShepherdImageBox>
                                                    <BadgeNeedsAttention />
                                                </ShepherdImageBox>
                                                <ShepherdTextBox>
                                                    If you see this Badge symbol, the badge is in Need Attention and you
                                                    need to work on the Skills in that Outcome
                                                </ShepherdTextBox>
                                            </ShepherdVerticalCenteredBox>
                                        </ShepherdListItem>
                                    </ShepherdList>
                                </ShepherdContentBox>
                            )
                        ]
                    },
                    {
                        ...basicWizardStepConfig,
                        id: 'searchBox',
                        attachTo: { element: '.search-box', on: 'bottom' },
                        buttons: generateWizardButtons(6, 7),
                        title: generateTitle('Search Outcomes'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>Use keywords to quickly find Outcomes to explore</ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    },
                    {
                        ...basicWizardStepConfig,
                        id: 'scoring-box',
                        attachTo: { element: '#scoring-update-info', on: 'bottom' },
                        buttons: generateWizardButtons(7, 7),
                        title: generateTitle('Your Progress Update'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>Your progress is updated once a week</ShepherdTextBox>
                                    <ShepherdTextBox>
                                        Here you can see when your progress was last updated and when it will be updated
                                        next
                                    </ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    }
                ]
            });
        } else if (matchPath(routes.HOME, location.pathname) || matchPath('/', location.pathname)) {
            setConfig({
                wizardSteps: [
                    {
                        ...basicWizardStepConfig,
                        id: 'help',
                        attachTo: { element: '.wizard-help', on: 'bottom' },
                        buttons: generateWizardButtons(1, 6),
                        title: generateTitle('Discover more about Nulia Works'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>
                                        Select this icon at the top of a page to discover more about highlighted
                                        features included on that page
                                    </ShepherdTextBox>
                                    <ShepherdTextBox>
                                        To explore all of Nulia Works in one place, you can always check out the Nulia
                                        Works How To located on the How To page
                                    </ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    },
                    {
                        ...basicWizardStepConfig,
                        id: 'scoringUpdateInfo',
                        attachTo: { element: '#scoring-update-info', on: 'bottom' },
                        buttons: generateWizardButtons(2, 6),
                        title: generateTitle('Your Progress Update'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>Your progress is updated once a week</ShepherdTextBox>
                                    <ShepherdTextBox>
                                        Here you can see when your progress was last updated and when it will be updated
                                        next
                                    </ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    },
                    {
                        ...basicWizardStepConfig,
                        id: 'messagesOfTheWeek',
                        attachTo: { element: '#messages-of-the-week-box', on: 'bottom' },
                        buttons: generateWizardButtons(3, 6),
                        title: generateTitle('Your Nulia News'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>
                                        Catch up on the latest Microsoft updates, new Nulia Works catalog and features,
                                        helpful tips & tricks using Microsoft apps, and even some posts that are just
                                        for fun
                                    </ShepherdTextBox>
                                    <ShepherdTextBox>Use the arrows in any feature to see more</ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    },
                    {
                        ...basicWizardStepConfig,
                        id: 'snapshotSkillsOutcomes',
                        attachTo: { element: '#snapshot-skills-outcomes', on: 'bottom' },
                        buttons: generateWizardButtons(4, 6),
                        title: generateTitle('Snapshot of your Skills & Outcomes'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>
                                        See a quick snapshot of where you are with your Skills & Outcomes, and select
                                        any box to get more details
                                    </ShepherdTextBox>
                                    <ShepherdTextBox>
                                        Use the arrows to switch between your Skills and Outcomes
                                    </ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    },
                    {
                        ...basicWizardStepConfig,
                        id: 'weekGoals',
                        attachTo: { element: '#week-goals', on: 'bottom' },
                        buttons: generateWizardButtons(5, 6),
                        title: generateTitle('Your Goals for this Week'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>
                                        It's always good to know your next step. These are personalized recommendations
                                        for something new to try, Outcomes & Skills that you might enjoy, or Nulia Works
                                        can even suggest advancing to the next level
                                    </ShepherdTextBox>
                                    <ShepherdTextBox>Use the arrows in any feature to see more</ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    },
                    {
                        ...basicWizardStepConfig,
                        id: 'activityFeed',
                        attachTo: { element: '#activity-feed', on: 'bottom' },
                        buttons: generateWizardButtons(6, 6),
                        title: generateTitle('Your Nulia Works Activity'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>
                                        Check out what's been happening in your Nulia Works world, and use the filters
                                        if you only want to see a specific type of activity
                                    </ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    }
                ]
            });
        } else if (matchPath(routes.SKILLS, location.pathname)) {
            setConfig({
                wizardSteps: [
                    {
                        ...basicWizardStepConfig,
                        id: 'intro',
                        attachTo: { element: '.skill-filter-buttons', on: 'bottom' },
                        buttons: generateWizardButtons(1, 6),
                        title: generateTitle('Details about Your Skills'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>
                                        See a quick view of where you are with your Skills, and select any status to get
                                        a list of the Skills for that box
                                    </ShepherdTextBox>
                                    <ShepherdTextBox>
                                        You can see the full catalog of Nulia Works Skills by unselecting each Skill
                                        status
                                    </ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    },
                    {
                        ...basicWizardStepConfig,
                        id: 'recommendations',
                        attachTo: { element: '#recommendations-box', on: 'bottom' },
                        buttons: generateWizardButtons(2, 6),
                        title: generateTitle('Discover more'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>
                                        See personalized recommendations related to your Skills
                                    </ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    },
                    {
                        ...basicWizardStepConfig,
                        id: 'dottedCircles',
                        attachTo: { element: '.skill-image-wrapper-0', on: 'bottom' },
                        buttons: generateWizardButtons(3, 6),
                        title: generateTitle('Status of your Skills'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>
                                        Here you can see your progress toward your target level with the line, your
                                        score in the center, and the status for each of your Skills by color. Let's
                                        explore more...
                                    </ShepherdTextBox>
                                    <ShepherdList>
                                        <ShepherdListItem>
                                            <ShepherdVerticalCenteredBox>
                                                <ShepherdImageBox>
                                                    <SkillNoTarget />
                                                </ShepherdImageBox>
                                                <ShepherdTextBox>
                                                    Nulia Works shows your score towards Skills even if you haven't
                                                    assigned them at a target level yet
                                                </ShepherdTextBox>
                                            </ShepherdVerticalCenteredBox>
                                        </ShepherdListItem>
                                        <ShepherdListItem>
                                            <ShepherdVerticalCenteredBox>
                                                <ShepherdImageBox>
                                                    <SkillTargetNotAchieved />
                                                </ShepherdImageBox>
                                                <ShepherdTextBox>
                                                    While you are working towards your target, your progress is shown in
                                                    yellow
                                                </ShepherdTextBox>
                                            </ShepherdVerticalCenteredBox>
                                        </ShepherdListItem>
                                        <ShepherdListItem>
                                            <ShepherdVerticalCenteredBox>
                                                <ShepherdImageBox>
                                                    <SkillTargetAchieved />
                                                </ShepherdImageBox>
                                                <ShepherdTextBox>
                                                    When you attain or exceed your target, your progress is shown in
                                                    green
                                                </ShepherdTextBox>
                                            </ShepherdVerticalCenteredBox>
                                        </ShepherdListItem>
                                        <ShepherdListItem>
                                            <ShepherdVerticalCenteredBox>
                                                <ShepherdImageBox>
                                                    <SkillNeedAttention />
                                                </ShepherdImageBox>
                                                <ShepherdTextBox>
                                                    If you see the center score is orange, it is in Need Attention and
                                                    Nulia Works is letting you know that you may soon fall below your
                                                    target so make sure to keep using this Skill to maintain it
                                                </ShepherdTextBox>
                                            </ShepherdVerticalCenteredBox>
                                        </ShepherdListItem>
                                        <ShepherdListItem>
                                            <ShepherdVerticalCenteredBox>
                                                <ShepherdImageBox>
                                                    <SkillBestPractice />
                                                </ShepherdImageBox>
                                                <ShepherdTextBox>
                                                    These are bonus Skills that add supplemental information to enhance
                                                    some Outcomes, and don't have a score
                                                </ShepherdTextBox>
                                            </ShepherdVerticalCenteredBox>
                                        </ShepherdListItem>
                                    </ShepherdList>
                                </ShepherdContentBox>
                            )
                        ]
                    },
                    {
                        ...basicWizardStepConfig,
                        id: 'heartControl',
                        attachTo: { element: '.heart-control', on: 'bottom' },
                        buttons: generateWizardButtons(4, 6),
                        title: generateTitle('Select your Focus Skills'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>
                                        Select the heart to set the Skill to Focused which will prioritize your goals
                                        and help you attain these Skills faster
                                    </ShepherdTextBox>
                                    <ShepherdTextBox>
                                        You can select up to three Skills as Focused, and you can remove a Skill from
                                        Focused by selecting the heart again
                                    </ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    },
                    {
                        ...basicWizardStepConfig,
                        id: 'search-box',
                        attachTo: { element: '.search-box', on: 'bottom' },
                        buttons: generateWizardButtons(5, 6),
                        title: generateTitle('Search Skills'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>Use keywords to quickly find Skills to explore</ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    },
                    {
                        ...basicWizardStepConfig,
                        id: 'progress-update',
                        attachTo: { element: '#scoring-update-info', on: 'bottom' },
                        buttons: generateWizardButtons(6, 6),
                        title: generateTitle('Your Progress Update'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>Your progress is updated once a week</ShepherdTextBox>
                                    <ShepherdTextBox>
                                        Here you can see when your progress was last updated and when it will be updated
                                        next
                                    </ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    }
                ]
            });
        } else if (matchPath(routes.OUTCOME, location.pathname)) {
            setConfig({
                wizardSteps: [
                    {
                        ...basicWizardStepConfig,
                        id: 'intro',
                        attachTo: { element: '.dotted-circles-wrapper', on: 'bottom' },
                        buttons: generateWizardButtons(1, 7),
                        title: generateTitle('Your Outcome Progress'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdList>
                                        <ShepherdListItem>
                                            <ShepherdVerticalCenteredBox>
                                                <ShepherdImageBox>
                                                    <PipsUnasigned />
                                                </ShepherdImageBox>
                                                <ShepherdTextBox style={{ paddingBottom: '5px' }}>
                                                    Outcomes that you don't have assigned will not have any dots
                                                </ShepherdTextBox>
                                            </ShepherdVerticalCenteredBox>
                                        </ShepherdListItem>
                                        <ShepherdListItem>
                                            <ShepherdVerticalCenteredBox>
                                                <ShepherdImageBox>
                                                    <PipsGeneral />
                                                </ShepherdImageBox>
                                                <ShepherdTextBox>
                                                    The dots below an Outcome show how many Skills are included and how
                                                    you are progressing toward the Skills in that Outcome - Green
                                                    indicates you have attained the Skill, Yellow means it's in
                                                    progress, and Orange shows that it's in Need Attention
                                                </ShepherdTextBox>
                                            </ShepherdVerticalCenteredBox>
                                        </ShepherdListItem>
                                        <ShepherdListItem>
                                            <ShepherdVerticalCenteredBox>
                                                <ShepherdImageBox>
                                                    <PipsMaster />
                                                </ShepherdImageBox>
                                                <ShepherdTextBox style={{ paddingBottom: '20px' }}>
                                                    If you have assigned an Outcome at Master level, you will see a
                                                    label beneath the dots
                                                </ShepherdTextBox>
                                            </ShepherdVerticalCenteredBox>
                                        </ShepherdListItem>
                                    </ShepherdList>
                                </ShepherdContentBox>
                            )
                        ]
                    },
                    {
                        ...basicWizardStepConfig,
                        id: 'badges-1',
                        attachTo: { element: '.badge-list-header', on: 'bottom' },
                        buttons: generateWizardButtons(2, 7),
                        title: generateTitle('Digital Maturity Levels'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>
                                        When you start a new Outcome, you will automatically be assigned the Producer
                                        Level unless you or your manager choose a higher Digital Maturity Level
                                    </ShepherdTextBox>
                                    <ShepherdTextBox>
                                        The Activities for each Skill included in an Outcome are grouped into different
                                        levels – User, Producer, and Master
                                    </ShepherdTextBox>
                                    <ShepherdList>
                                        <ShepherdListItem>
                                            <ShepherdVerticalCenteredBox>
                                                <span>
                                                    <b>User</b> includes the basic skills to help you get started
                                                </span>
                                            </ShepherdVerticalCenteredBox>
                                        </ShepherdListItem>
                                        <ShepherdListItem>
                                            <ShepherdVerticalCenteredBox>
                                                <span>
                                                    <b>Producer</b> is where you really develop the skills to make
                                                    Microsoft 365 work for you
                                                </span>
                                            </ShepherdVerticalCenteredBox>
                                        </ShepherdListItem>
                                        <ShepherdListItem>
                                            <ShepherdVerticalCenteredBox>
                                                <span>
                                                    Go for <b>Master</b> to go above and beyond
                                                </span>
                                            </ShepherdVerticalCenteredBox>
                                        </ShepherdListItem>
                                    </ShepherdList>
                                    <ShepherdTextBox>
                                        As you attain the Skills for each level, you will earn a badge for that level
                                    </ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    },
                    {
                        ...basicWizardStepConfig,
                        id: 'badges-3',
                        attachTo: { element: '.badge-list-header', on: 'bottom' },
                        buttons: generateWizardButtons(3, 7),
                        title: generateTitle('View Description'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>
                                        Select View Description to discover more about an Outcome, including the status
                                        of your badges
                                    </ShepherdTextBox>
                                    <ShepherdList>
                                        <ShepherdListItem>
                                            <ShepherdVerticalCenteredBox>
                                                <ShepherdImageBox>
                                                    <BadgeNotEarnedProducerMaster />
                                                </ShepherdImageBox>
                                                <ShepherdTextBox>
                                                    If a badge is grayed out, it is not yet earned
                                                </ShepherdTextBox>
                                            </ShepherdVerticalCenteredBox>
                                        </ShepherdListItem>
                                        <ShepherdListItem>
                                            <ShepherdVerticalCenteredBox>
                                                <ShepherdImageBox>
                                                    <BadgeCanBeClaimed />
                                                </ShepherdImageBox>
                                                <ShepherdTextBox>
                                                    Badge has been earned and is ready to be claimed
                                                </ShepherdTextBox>
                                            </ShepherdVerticalCenteredBox>
                                        </ShepherdListItem>
                                        <ShepherdListItem>
                                            <ShepherdVerticalCenteredBox>
                                                <ShepherdImageBox>
                                                    <BadgeIsClaimedProducerMaster />
                                                </ShepherdImageBox>
                                                <ShepherdTextBox>Badge has been claimed and is current</ShepherdTextBox>
                                            </ShepherdVerticalCenteredBox>
                                        </ShepherdListItem>
                                        <ShepherdListItem>
                                            <ShepherdVerticalCenteredBox>
                                                <ShepherdImageBox>
                                                    <BadgeNeedsAttention />
                                                </ShepherdImageBox>
                                                <ShepherdTextBox>
                                                    If you see this, the badge is in Needs Attention and you need to
                                                    work on the Skills in that Outcome
                                                </ShepherdTextBox>
                                            </ShepherdVerticalCenteredBox>
                                        </ShepherdListItem>
                                    </ShepherdList>
                                </ShepherdContentBox>
                            )
                        ]
                    },
                    {
                        ...basicWizardStepConfig,
                        id: 'badges-4',
                        attachTo: { element: '#badge-card-content-box', on: 'bottom' },
                        buttons: generateWizardButtons(4, 7),
                        title: generateTitle('Claiming your badges'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdList>
                                        <ShepherdListItem>
                                            <ShepherdVerticalCenteredBox>
                                                <ClaimBadge />
                                                You can claim the Producer and Master badges you have earned by
                                                selecting this button, and User badges are awarded and don't need to be
                                                claimed
                                            </ShepherdVerticalCenteredBox>
                                        </ShepherdListItem>
                                        <ShepherdListItem>
                                            <ShepherdVerticalCenteredBox>
                                                <div
                                                    style={{ width: '74px', display: 'flex', justifyContent: 'center' }}
                                                >
                                                    <ShareBadge height='22px' width='22px' />
                                                </div>
                                                After claiming a badge, you can show it off on LinkedIn by selecting the
                                                Upload button
                                            </ShepherdVerticalCenteredBox>
                                        </ShepherdListItem>
                                        <ShepherdListItem>
                                            <ShepherdVerticalCenteredBox>
                                                <div
                                                    style={{ width: '88px', display: 'flex', justifyContent: 'center' }}
                                                >
                                                    <DownloadBadge height='22px' width='22px' />
                                                </div>
                                                Select the Download button if you would like a digital image of your
                                                badge that you can share anywhere
                                            </ShepherdVerticalCenteredBox>
                                        </ShepherdListItem>
                                    </ShepherdList>
                                </ShepherdContentBox>
                            )
                        ]
                    },
                    {
                        ...basicWizardStepConfig,
                        id: 'badges-5',
                        attachTo: { element: '#master-level-toggle', on: 'bottom' },
                        buttons: generateWizardButtons(5, 7),
                        title: generateTitle('Master Level'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>
                                        If a Master Level is available for this Outcome, you can set your target to
                                        Master using this toggle
                                    </ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    },
                    {
                        ...basicWizardStepConfig,
                        id: 'skills-list',
                        attachTo: { element: '.skill-list-wrapper .skill-card-0', on: 'bottom' },
                        buttons: generateWizardButtons(6, 7),
                        title: generateTitle('Skills for Your Outcome'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>
                                        Here you can see the Skills that make up an Outcome, your progress towards
                                        attaining those Skills, and can select a Skill to see more
                                    </ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    },
                    {
                        ...basicWizardStepConfig,
                        id: 'skills-list',
                        attachTo: { element: '#scoring-update-info', on: 'bottom' },
                        buttons: generateWizardButtons(7, 7),
                        title: generateTitle('Your Progress Update'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>Your progress is updated once a week</ShepherdTextBox>
                                    <ShepherdTextBox>
                                        Here you can see when your progress was last updated and when it will be updated
                                        next
                                    </ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    }
                ]
            });
        } else if (matchPath(routes.SETTINGS_PERSONAL, location.pathname)) {
            setConfig({
                wizardSteps: [
                    {
                        ...basicWizardStepConfig,
                        id: 'settings-notifications-cards-box',
                        attachTo: { element: '#email-notifications', on: 'bottom' },
                        buttons: generateWizardButtons(1, 3),
                        title: generateTitle('Email Notifications'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>
                                        Choose which notifications you would like to receive, however, you can't opt out
                                        of onboarding messages
                                    </ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    },
                    {
                        ...basicWizardStepConfig,
                        id: 'teams-notifications-cards-box',
                        attachTo: { element: '#teams-notifications', on: 'bottom' },
                        buttons: generateWizardButtons(2, 3),
                        title: generateTitle('Teams Notifications'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>
                                        As you work towards your digital skills development goals, you will be notified
                                        on your latest progress updates through the Teams Activity bell
                                    </ShepherdTextBox>
                                    <ShepherdTextBox>
                                        Note: The Nulia Works for Teams app needs to be installed in order to receive
                                        these notifications
                                    </ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    },
                    {
                        ...basicWizardStepConfig,
                        id: 'badge-preferences-cards-box',
                        attachTo: { element: '#badge-preferences', on: 'bottom' },
                        buttons: generateWizardButtons(3, 3),
                        title: generateTitle('Badge Preferences'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>
                                        By default your claimed badges are verified using your work email address, but
                                        if you prefer you can provide an alternative email address for badge
                                        verification
                                    </ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    }
                ]
            });
        } else if (matchPath(routes.SETTINGS_ORGANIZATIONAL, location.pathname)) {
            setConfig({
                wizardSteps: [
                    {
                        ...basicWizardStepConfig,
                        id: 'settings-notifications-cards-box',
                        attachTo: { element: '#email-notifications-cards-box', on: 'bottom' },
                        buttons: generateWizardButtons(1, 6),
                        title: generateTitle('Email Notifications'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>
                                        Organizational notification preferences can be set here but users can
                                        individually override these preferences
                                    </ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    },
                    {
                        ...basicWizardStepConfig,
                        id: 'teams-notifications-box',
                        attachTo: { element: '#teams-notifications-box', on: 'bottom' },
                        buttons: generateWizardButtons(2, 6),
                        title: generateTitle('Teams Notifications'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>
                                        Organizational notification preferences can be set here but users can
                                        individually override these preferences
                                    </ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    },
                    {
                        ...basicWizardStepConfig,
                        id: 'settings-manager-cards-box',
                        attachTo: { element: '#settings-manager-cards-box', on: 'bottom' },
                        buttons: generateWizardButtons(3, 6),
                        title: generateTitle('Manager Settings'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>
                                        Choose your organization's settings to allow Managers, as defined in your Active
                                        Directory, to be given the ability to view Insights for their departments, and
                                        the ability to assign Outcomes to individuals in their department. This setting
                                        is applied to the entire tenant
                                    </ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    },
                    {
                        ...basicWizardStepConfig,
                        id: 'leaderboard-box',
                        attachTo: { element: '#leaderboard-box', on: 'bottom' },
                        buttons: generateWizardButtons(4, 6),
                        title: generateTitle('Leaderboard'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>
                                        Choose whether your Organization will see Leaderboards on their Showcase Page.
                                        This setting is applied to the entire tenant
                                    </ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    },
                    {
                        ...basicWizardStepConfig,
                        id: 'collector-status-box',
                        attachTo: { element: '#collector-status-cards-box', on: 'bottom' },
                        buttons: generateWizardButtons(5, 6),
                        title: generateTitle('Collector Status'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>
                                        This is a quick snapshot of the status of the collectors being used by Nulia
                                        Works, if your indicator is yellow or red a message to contact your IT
                                        Department may appear
                                    </ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    },
                    {
                        ...basicWizardStepConfig,
                        id: 'license-utilization-box',
                        attachTo: { element: '#license-utilization-cards-box', on: 'bottom' },
                        buttons: generateWizardButtons(6, 6),
                        title: generateTitle('License Utilization'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>
                                        You can view a quick summary of your Nulia Works license status along with an
                                        engagement score for your organizaiton
                                    </ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    }
                ]
            });
        } else if (matchPath(routes.ADMIN_LICENSES, location.pathname)) {
            setConfig({
                wizardSteps: [
                    {
                        ...basicWizardStepConfig,
                        id: 'licenses-status-filter-buttons',
                        attachTo: { element: '.licenses-status-filter-buttons', on: 'bottom' },
                        buttons: generateWizardButtons(1, 2),
                        title: generateTitle('License Status'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>
                                        This indicator shows how many licenses you have available to assign to users
                                    </ShepherdTextBox>
                                    <ShepherdTextBox>
                                        Select request more licenses to send a message requesting more to your partner
                                    </ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    },
                    {
                        ...basicWizardStepConfig,
                        id: 'table-row-1',
                        attachTo: { element: '#table-row-0', on: 'bottom' },
                        buttons: generateWizardButtons(2, 2),
                        title: generateTitle('License Assignment'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>
                                        Select a user to assign them or unassign them a license
                                    </ShepherdTextBox>
                                    <ShepherdTextBox>
                                        Once you select a user a control will appear at the top of the user list which
                                        will allow you to assign or unassign their license
                                    </ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    }
                ]
            });
        } else if (matchPath(routes.ADMIN_ROLES, location.pathname)) {
            setConfig({
                wizardSteps: [
                    {
                        ...basicWizardStepConfig,
                        id: 'table-row',
                        attachTo: { element: '#table-row-0', on: 'bottom' },
                        buttons: generateWizardButtons(1, 2),
                        title: generateTitle('User Selector'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdList>
                                        <ShepherdTextBox>
                                            Select a user to assign or assign roles. Once you select a user a control
                                            will appear at the top of the user list which will allow you to assign or
                                            unassign their license. There are three role types:
                                        </ShepherdTextBox>
                                        <ShepherdListItem>
                                            <ShepherdVerticalCenteredBox>
                                                <span style={{ marginTop: '10px' }}>
                                                    <b>Global admins</b> can assign roles, assign licenses, and see
                                                    Insights for the organization
                                                </span>
                                            </ShepherdVerticalCenteredBox>
                                        </ShepherdListItem>
                                        <ShepherdListItem>
                                            <ShepherdVerticalCenteredBox>
                                                <span>
                                                    <b>License admins</b> can assign or remove Nulia Works licenses.
                                                    They can not see Insights or manage roles
                                                </span>
                                            </ShepherdVerticalCenteredBox>
                                        </ShepherdListItem>
                                        <ShepherdListItem>
                                            <ShepherdVerticalCenteredBox>
                                                <span>
                                                    <b>Report admins</b> can view Insights for the organization. They
                                                    can not manage roles or license assignment
                                                </span>
                                            </ShepherdVerticalCenteredBox>
                                        </ShepherdListItem>
                                    </ShepherdList>
                                </ShepherdContentBox>
                            )
                        ]
                    },
                    {
                        ...basicWizardStepConfig,
                        id: 'assignment-buttons',
                        attachTo: { element: '#role-assignment-buttons', on: 'bottom' },
                        buttons: generateWizardButtons(2, 2),
                        title: generateTitle('Role Types'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>
                                        To assign a role, click on the role type that appears here after you select one
                                        or more users. That role will be assigned to all selected users
                                    </ShepherdTextBox>
                                    <ShepherdTextBox>
                                        To remove roles for all selected users, choose the Remove Roles button. Note -
                                        You can not remove a single role. To do that, remove all roles, and add back in
                                        the ones you want to keep
                                    </ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    }
                ]
            });
        } else if (matchPath(routes.ADMIN_HISTORY, location.pathname)) {
            setConfig({
                wizardSteps: [
                    {
                        ...basicWizardStepConfig,
                        id: 'refresh-action-box',
                        attachTo: { element: '#refresh-action-box', on: 'bottom' },
                        buttons: generateWizardButtons(1, 2),
                        title: generateTitle('Action Refresh'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>
                                        Select the refresh button to see the latest changes on tasks in progress
                                    </ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    },
                    {
                        ...basicWizardStepConfig,
                        id: 'table-row-1',
                        attachTo: { element: '#table-row-0', on: 'bottom' },
                        buttons: generateWizardButtons(2, 2),
                        title: generateTitle('Action Detail'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>
                                        Select on any action to get more details about the selected item
                                    </ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    }
                ]
            });
        } else if (matchPath(routes.ADMIN_HISTORY_DETAILS, location.pathname)) {
            setConfig({
                wizardSteps: [
                    {
                        ...basicWizardStepConfig,
                        id: 'refresh',
                        attachTo: { element: '#refresh-action-box', on: 'bottom' },
                        buttons: generateWizardButtons(1, 2),
                        title: generateTitle('Refresh'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>
                                        To get the latest details on an action still in progress, press the refresh
                                        button
                                    </ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    },
                    {
                        ...basicWizardStepConfig,
                        id: 'task-status',
                        attachTo: { element: '.task-status-box', on: 'bottom' },
                        buttons: generateWizardButtons(2, 2),
                        title: generateTitle('Status'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>
                                        Mouse over a status indicator to see more information on the status.
                                    </ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    }
                ]
            });
        } else if (matchPath(routes.ADMIN_OUTCOMES, location.pathname)) {
            setConfig({
                wizardSteps: [
                    {
                        ...basicWizardStepConfig,
                        id: 'outcome-card-info-box',
                        attachTo: { element: '#outcome-card-info-box', on: 'bottom' },
                        buttons: generateWizardButtons(1, 4),
                        title: generateTitle('Outcome Information'),
                        modalOverlayOpeningPadding: 5,
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>
                                        Here you can see how many users have this Outcome assigned and get more
                                        information on the Outcome
                                    </ShepherdTextBox>
                                    <ShepherdList>
                                        <ShepherdListItem>
                                            <ShepherdVerticalCenteredBox>
                                                <ShepherdImageBox>
                                                    <ClipboardCheck width={32} height={32} />
                                                </ShepherdImageBox>
                                                <ShepherdTextBox>
                                                    You can see whether this Outcome is set as company focus, and change
                                                    that state by clicking it
                                                </ShepherdTextBox>
                                            </ShepherdVerticalCenteredBox>
                                        </ShepherdListItem>
                                    </ShepherdList>
                                </ShepherdContentBox>
                            )
                        ]
                    },
                    {
                        ...basicWizardStepConfig,
                        id: 'select-box-0',
                        attachTo: { element: '#select-box-0', on: 'bottom' },
                        buttons: generateWizardButtons(2, 4),
                        title: generateTitle('Assign Outcomes'),
                        modalOverlayOpeningPadding: 5,
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>
                                        To assign one or more Outcomes to a set of users, start by clicking the checkbox
                                        next to the Outcome(s) you want to assign
                                    </ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    },
                    {
                        ...basicWizardStepConfig,
                        id: 'organizational-outcomes-tabs',
                        attachTo: { element: '#user-selection-tab', on: 'bottom' },
                        buttons: generateWizardButtons(3, 4),
                        title: generateTitle('Select Users'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>
                                        After selecting the Outcome(s) to assign, use this panel to choose which users
                                        to assign the Outcome to
                                    </ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    },
                    {
                        ...basicWizardStepConfig,
                        id: 'assign-tab',
                        attachTo: { element: '#assign-tab', on: 'bottom' },
                        buttons: generateWizardButtons(4, 4),
                        title: generateTitle('Confirm Assignment'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>
                                        The last panel is used to confirm the assignment of Outcomes to the selected
                                        users
                                    </ShepherdTextBox>
                                    <ShepherdTextBox>
                                        Note that you can also unassign Outcomes using this flow. After choosing to
                                        assign or unassign, you will be given a link to the status of that action
                                    </ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    }
                ]
            });
        } else if (matchPath(routes.SKILL, location.pathname)) {
            setConfig({
                wizardSteps: [
                    {
                        ...basicWizardStepConfig,
                        id: 'doughnut',
                        attachTo: { element: '.skill-doughnut-wrapper', on: 'bottom' },
                        buttons: generateWizardButtons(1, 5),
                        title: generateTitle('Status of your Skills'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>
                                        Here you can see your progress toward your target level with the line, your
                                        score in the center, and the status for each of your Skills by color. Let's
                                        explore more...
                                    </ShepherdTextBox>
                                    <ShepherdList>
                                        <ShepherdListItem>
                                            <ShepherdVerticalCenteredBox>
                                                <ShepherdImageBox>
                                                    <SkillNoTarget />
                                                </ShepherdImageBox>
                                                <ShepherdTextBox>
                                                    Nulia Works shows your score towards Skills even if you haven't
                                                    assigned them at a target level yet
                                                </ShepherdTextBox>
                                            </ShepherdVerticalCenteredBox>
                                        </ShepherdListItem>
                                        <ShepherdListItem>
                                            <ShepherdVerticalCenteredBox>
                                                <ShepherdImageBox>
                                                    <SkillTargetNotAchieved />
                                                </ShepherdImageBox>
                                                <ShepherdTextBox>
                                                    While you are working towards your target, your progress is shown in
                                                    yellow
                                                </ShepherdTextBox>
                                            </ShepherdVerticalCenteredBox>
                                        </ShepherdListItem>
                                        <ShepherdListItem>
                                            <ShepherdVerticalCenteredBox>
                                                <ShepherdImageBox>
                                                    <SkillTargetAchieved />
                                                </ShepherdImageBox>
                                                <ShepherdTextBox>
                                                    When you attain or exceed your target, your progress is shown in
                                                    green
                                                </ShepherdTextBox>
                                            </ShepherdVerticalCenteredBox>
                                        </ShepherdListItem>
                                        <ShepherdListItem>
                                            <ShepherdVerticalCenteredBox>
                                                <ShepherdImageBox>
                                                    <SkillNeedAttention />
                                                </ShepherdImageBox>
                                                <ShepherdTextBox>
                                                    If you see the center score is orange, it is in Need Attention and
                                                    Nulia Works is letting you know that you may soon fall below your
                                                    target so make sure to keep using this Skill to maintain it
                                                </ShepherdTextBox>
                                            </ShepherdVerticalCenteredBox>
                                        </ShepherdListItem>
                                        <ShepherdListItem>
                                            <ShepherdVerticalCenteredBox>
                                                <ShepherdImageBox>
                                                    <SkillBestPractice />
                                                </ShepherdImageBox>
                                                <ShepherdTextBox>
                                                    These are bonus Skills that add supplemental information to enhance
                                                    some Outcomes, and don't have a score
                                                </ShepherdTextBox>
                                            </ShepherdVerticalCenteredBox>
                                        </ShepherdListItem>
                                    </ShepherdList>
                                </ShepherdContentBox>
                            )
                        ]
                    },
                    {
                        ...basicWizardStepConfig,
                        id: 'skill-graph',
                        attachTo: { element: '#skill-graph-wrapper', on: 'bottom' },
                        buttons: generateWizardButtons(2, 5),
                        title: generateTitle('Your Skill Progress'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>
                                        Here you can view your progress toward attaining your target level of a Skill
                                        over time, and the dotted line represents your trend over the next two weeks if
                                        your use of Microsoft 365 remains unchanged
                                    </ShepherdTextBox>
                                    <ShepherdTextBox>
                                        If the dotted line is heading below your target line, the Skill is in Need
                                        Attention and you should work on it to make sure you maintain this Skill
                                    </ShepherdTextBox>
                                    <ShepherdListItem>
                                        <ShepherdTextBox>
                                            <ShepherdVerticalCenteredBox>
                                                <ShepherdTextBox>
                                                    If you're ready for something new, set your target level to Master
                                                    with the toggle in the upper right
                                                </ShepherdTextBox>
                                            </ShepherdVerticalCenteredBox>
                                        </ShepherdTextBox>
                                    </ShepherdListItem>
                                </ShepherdContentBox>
                            )
                        ]
                    },
                    {
                        ...basicWizardStepConfig,
                        id: 'goals',
                        attachTo: { element: '#goals-wrapper > .behavior-level-0', on: 'bottom' },
                        buttons: generateWizardButtons(3, 5),
                        title: generateTitle('Goals'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>
                                        Goals are what you need to do to earn points toward each target level - User,
                                        Producer, and Master - to attain and maintain a Skill
                                    </ShepherdTextBox>
                                    <ShepherdTextBox>
                                        Each target shows the number of points you have earned and the total points
                                        possible and, as you click a target, the Activities list will filter to show the
                                        features you need to use to attain that target
                                    </ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    },
                    {
                        ...basicWizardStepConfig,
                        id: 'activities',
                        attachTo: { element: '.activities-box > .activity-card-0', on: 'bottom' },
                        buttons: generateWizardButtons(4, 5),
                        title: generateTitle('Activities'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>
                                        Activities are the recommended Microsoft 365 features and functions that will
                                        help you attain and maintain your Skills
                                    </ShepherdTextBox>
                                    <ShepherdTextBox>
                                        The Try It button opens a popup window with useful information that can help you
                                        learn how to use this feature, and you can even leave the window open while you
                                        try it in Microsoft 365
                                    </ShepherdTextBox>
                                    <ShepherdTextBox>
                                        You may also see a Watch or View button that indicates there is a required
                                        learning resource that you must view or watch to attain that Skill, and if you
                                        have completed it, the last date you opened that resource is shown right below
                                        the Activity
                                    </ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    },
                    {
                        ...basicWizardStepConfig,
                        id: 'scroting-update-info',
                        attachTo: { element: '#scoring-update-info', on: 'bottom' },
                        buttons: generateWizardButtons(5, 5),
                        title: generateTitle('Your Progress Update'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>Your progress is updated once a week</ShepherdTextBox>
                                    <ShepherdTextBox>
                                        Here you can see when your progress was last updated and when it will be updated
                                        next
                                    </ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    }
                ]
            });
        } else if (matchPath(routes.HISTORY_OUTCOMES, location.pathname)) {
            setConfig({
                wizardSteps: [
                    {
                        ...basicWizardStepConfig,
                        id: 'refresh',
                        attachTo: { element: '#refresh-action-box', on: 'bottom' },
                        buttons: generateWizardButtons(1, 2),
                        title: generateTitle('Action Refresh'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>
                                        Select the refresh button to see the latest changes on tasks in progress
                                    </ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    },
                    {
                        ...basicWizardStepConfig,
                        id: 'action-details',
                        attachTo: { element: '#table-row-1', on: 'bottom' },
                        buttons: generateWizardButtons(2, 2),
                        title: generateTitle('Action Detail'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>
                                        Select on any action to get more details about the selected item
                                    </ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    }
                ]
            });
        } else if (matchPath(routes.INSIGHTS_ORGANIZATIONAL_USERS, location.pathname)) {
            setConfig({
                wizardSteps: [
                    {
                        ...basicWizardStepConfig,
                        id: 'tabMode',
                        attachTo: { element: '#tab-mode', on: 'bottom' },
                        buttons: generateWizardButtons(1, 4),
                        title: generateTitle('Skills & Outcomes selector'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>Select this to switch between Skills & Outcomes</ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    },
                    {
                        ...basicWizardStepConfig,
                        id: 'graph-legend-box',
                        attachTo: { element: '#graph-legend-box', on: 'bottom' },
                        buttons: generateWizardButtons(2, 4),
                        title: generateTitle('Outcome & Skill status'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>
                                        Select a status to add or remove it to the progress chart
                                    </ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    },
                    {
                        ...basicWizardStepConfig,
                        id: 'search-box',
                        attachTo: { element: '.search-box', on: 'bottom' },
                        buttons: generateWizardButtons(3, 4),
                        title: generateTitle('User Search'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>
                                        Enter a user's name to display their progress over time{' '}
                                    </ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    },
                    {
                        ...basicWizardStepConfig,
                        id: 'table-box',
                        attachTo: { element: '.table-box', on: 'bottom' },
                        buttons: generateWizardButtons(4, 4),
                        title: generateTitle('List of Users'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>
                                        Here you see a list of users, and you can select a user to see their progress
                                        over time
                                    </ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    }
                ]
            });
        } else if (matchPath(routes.INSIGHTS_ORGANIZATIONAL_OUTCOMES, location.pathname)) {
            setConfig({
                wizardSteps: [
                    {
                        ...basicWizardStepConfig,
                        id: 'graph-legend-box',
                        attachTo: { element: '#graph-legend-box', on: 'bottom' },
                        buttons: generateWizardButtons(1, 2),
                        title: generateTitle('Outcome Status'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>
                                        Select a status to add or remove it to the progress chart
                                    </ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    },
                    {
                        ...basicWizardStepConfig,
                        id: 'table-row',
                        attachTo: { element: '#table-row-0', on: 'bottom' },
                        buttons: generateWizardButtons(2, 2),
                        title: generateTitle('Outcome Details'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>
                                        Select any Outcome to see detailed information about it{' '}
                                    </ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    }
                ]
            });
        } else if (
            matchPath(routes.INSIGHTS_ORGANIZATIONAL_OUTCOMES_DETAILS, location.pathname) &&
            location.hash === '#users'
        ) {
            setConfig({
                wizardSteps: [
                    {
                        ...basicWizardStepConfig,
                        id: 'outcome-name',
                        attachTo: { element: '#graph-card-title', on: 'bottom' },
                        buttons: generateWizardButtons(1, 2),
                        title: generateTitle('Outcome name'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>
                                        The name of the outcome that you’re seeing details on is shown here.
                                    </ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    },
                    {
                        ...basicWizardStepConfig,
                        id: 'tab-mode',
                        attachTo: { element: '#tab-mode', on: 'bottom' },
                        buttons: generateWizardButtons(2, 2),
                        title: generateTitle('User and skill selector'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>
                                        Use this to see either all users who are assigned this outcome or all the skills
                                        that are in this outcome
                                    </ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    }
                ]
            });
        } else if (
            matchPath(routes.INSIGHTS_ORGANIZATIONAL_OUTCOMES_DETAILS, location.pathname) &&
            location.hash === '#skills'
        ) {
            setConfig({
                wizardSteps: [
                    {
                        ...basicWizardStepConfig,
                        id: 'table-row',
                        attachTo: { element: '#table-row-0', on: 'bottom' },
                        buttons: generateWizardButtons(1, 1),
                        title: generateTitle('Skill details'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>
                                        Click on a skill to see progress for all users assigned the skill
                                    </ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    }
                ]
            });
        } else if (matchPath(routes.INSIGHTS_ORGANIZATIONAL_ENGAGEMENT, location.pathname)) {
            setConfig({
                wizardSteps: [
                    {
                        ...basicWizardStepConfig,
                        id: 'tab-mode',
                        attachTo: { element: '#tab-mode', on: 'bottom' },
                        buttons: generateWizardButtons(1, 2),
                        title: generateTitle('View selector'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>
                                        Select engagement points or times logged in to see your organizaton's engagement
                                        over time
                                    </ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    },
                    {
                        ...basicWizardStepConfig,
                        id: 'table-row',
                        attachTo: { element: '#table-row-0', on: 'bottom' },
                        buttons: generateWizardButtons(2, 2),
                        title: generateTitle('User Engagement Details'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>
                                        Select any user to see detailed information for that user{' '}
                                    </ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    }
                ]
            });
        } else if (matchPath({ path: routes.INSIGHTS_ORGANIZATIONAL_BADGES }, location.pathname)) {
            setConfig({
                wizardSteps: [
                    {
                        ...basicWizardStepConfig,
                        id: 'tab-mode',
                        attachTo: { element: '#tab-mode', on: 'bottom' },
                        buttons: generateWizardButtons(1, 3),
                        title: generateTitle('User & Badge selector'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>
                                        Select users to change the information featured in the table below to see more
                                        about users' badge status or select badges to see more about the number of users
                                        in each of the badge status
                                    </ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    },
                    {
                        ...basicWizardStepConfig,
                        id: 'graph-legend-box',
                        attachTo: { element: '#graph-legend-box', on: 'bottom' },
                        buttons: generateWizardButtons(2, 3),
                        title: generateTitle('User & Badge Status'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>
                                        Select a status to add or remove it to the progress chart{' '}
                                    </ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    },
                    {
                        ...basicWizardStepConfig,
                        id: 'table-row',
                        attachTo: { element: '#table-row-0', on: 'bottom' },
                        buttons: generateWizardButtons(3, 3),
                        title: generateTitle('User & Badge Deatils'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>
                                        Select any user or badge to see detailed information for it
                                    </ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    }
                ]
            });
        } else if (matchPath(routes.INSIGHTS_ORGANIZATIONAL_SINGLE_BADGE, location.pathname)) {
            setConfig({
                wizardSteps: [
                    {
                        ...basicWizardStepConfig,
                        id: 'graph-card-title',
                        attachTo: { element: '#graph-card-title', on: 'bottom' },
                        buttons: generateWizardButtons(1, 1),
                        title: generateTitle('Badge name'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>
                                        The name of the badge that you’re seeing details on is shown here
                                    </ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    }
                ]
            });
        } else if (matchPath(routes.INSIGHTS_PERSONAL_SKILLS, location.pathname)) {
            setConfig({
                wizardSteps: [
                    {
                        ...basicWizardStepConfig,
                        id: 'graph-legend-box',
                        attachTo: { element: '#graph-holder-box', on: 'bottom' },
                        buttons: generateWizardButtons(1, 3),
                        title: generateTitle('Progress of your Skills'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>
                                        Here you can see the progress you're making towards attaining your Skills over
                                        time
                                    </ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    },
                    {
                        ...basicWizardStepConfig,
                        id: 'graph-legend-box',
                        attachTo: { element: '#graph-legend-box', on: 'bottom' },
                        buttons: generateWizardButtons(2, 3),
                        title: generateTitle('Skill status'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>
                                        Select a status to add or remove it to your Skill progress chart
                                    </ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    },
                    {
                        ...basicWizardStepConfig,
                        id: 'table',
                        attachTo: { element: '.table-box', on: 'bottom' },
                        buttons: generateWizardButtons(3, 3),
                        title: generateTitle('Your assigned skills'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>
                                        See a list of the Skills that you or your manager have assigned to you, and
                                        select the columns to sort the list for that category
                                    </ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    }
                ]
            });
        } else if (matchPath(routes.SHOWCASE_HOME, location.pathname)) {
            const isLeaderboardEnabled = isTenantPreferenceActive(ETenantPreference.ENABLE_LEADERBOARD);
            const rankingsHelp = {
                ...basicWizardStepConfig,
                id: 'rankings',
                attachTo: { element: '#rankings-card-header', on: 'bottom' },
                buttons: generateWizardButtons(3, 4),
                title: generateTitle('Nulia Works Rankings'),
                text: [
                    ReactDOMServer.renderToStaticMarkup(
                        <ShepherdContentBox>
                            <ShepherdTextBox>
                                Rankings show your progress and accomplishments in Nulia Works compared with others in
                                your company and with all members of the NuliaVerse
                            </ShepherdTextBox>
                        </ShepherdContentBox>
                    )
                ]
            };
            let wizardSteps = [
                {
                    ...basicWizardStepConfig,
                    id: 'engagement-points',
                    attachTo: { element: '#engagement-points', on: 'bottom' },
                    buttons: generateWizardButtons(1, isLeaderboardEnabled ? 4 : 3),
                    title: generateTitle('Engagement Points'),
                    text: [
                        ReactDOMServer.renderToStaticMarkup(
                            <ShepherdContentBox>
                                <ShepherdTextBox>
                                    Here you can see the Engagement Points you have earned for regularly logging in to
                                    Nulia Works, achieving Skills, attaining Outcomes, and other activities in Nulia
                                    Works
                                </ShepherdTextBox>
                            </ShepherdContentBox>
                        )
                    ]
                },
                {
                    ...basicWizardStepConfig,
                    id: 'showcase-badges',
                    attachTo: { element: '#showcase-badges-card-header', on: 'bottom' },
                    buttons: generateWizardButtons(2, isLeaderboardEnabled ? 4 : 3),
                    title: generateTitle('Badges'),
                    text: [
                        ReactDOMServer.renderToStaticMarkup(
                            <ShepherdContentBox>
                                <ShepherdTextBox>
                                    As you achieve Outcomes, you’ll earn industry-recognized badges that certify you’ve
                                    demonstrated and continue to use the Skills in an Outcome
                                </ShepherdTextBox>
                                <ShepherdTextBox>
                                    The badges that you earn are yours to keep! Use the arrows to switch between the
                                    types of badges and select View All to see the badges for that type
                                </ShepherdTextBox>
                            </ShepherdContentBox>
                        )
                    ]
                },
                {
                    ...basicWizardStepConfig,
                    id: 'achievements',
                    attachTo: { element: '#achievements-card-header', on: 'bottom' },
                    buttons: isLeaderboardEnabled ? generateWizardButtons(4, 4) : generateWizardButtons(3, 3),
                    title: generateTitle('Achievements'),
                    text: [
                        ReactDOMServer.renderToStaticMarkup(
                            <ShepherdContentBox>
                                <ShepherdTextBox>
                                    Here you can see the Achievements that you've been awarded for smaller steps and
                                    some that are just a bit silly & fun
                                </ShepherdTextBox>
                                <ShepherdTextBox>
                                    Use the arrows to switch between achievements and select View All to see the
                                    achievements for that type
                                </ShepherdTextBox>
                            </ShepherdContentBox>
                        )
                    ]
                }
            ];
            if (isLeaderboardEnabled) {
                wizardSteps = [...wizardSteps.slice(0, 2), rankingsHelp, ...wizardSteps.slice(2)];
            }
            setConfig({
                wizardSteps
            });
        } else if (matchPath(routes.INSIGHTS_PERSONAL_OUTCOMES, location.pathname)) {
            setConfig({
                wizardSteps: [
                    {
                        ...basicWizardStepConfig,
                        id: 'graph-holder-box',
                        attachTo: { element: '#graph-holder-box', on: 'bottom' },
                        buttons: generateWizardButtons(1, 3),
                        title: generateTitle('Progress of your Outcomes'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>
                                        Here you can see the progress you're making towards attaining your Outcomes over
                                        time
                                    </ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    },
                    {
                        ...basicWizardStepConfig,
                        id: 'graph-legend-box',
                        attachTo: { element: '#graph-legend-box', on: 'bottom' },
                        buttons: generateWizardButtons(2, 3),
                        title: generateTitle('Outcome status'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>
                                        Select a status to add or remove it from your Outcome progress chart
                                    </ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    },
                    {
                        ...basicWizardStepConfig,
                        id: 'table-box',
                        attachTo: { element: '.table-box', on: 'bottom' },
                        buttons: generateWizardButtons(3, 3),
                        title: generateTitle('Your assigned Outcomes'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>
                                        See a list of the Outcomes that you or your manager have assigned to you, and
                                        select the columns to sort the list for that category
                                    </ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    }
                ]
            });
        } else if (matchPath(routes.INSIGHTS_PERSONAL_ENGAGEMENT, location.pathname)) {
            setConfig({
                wizardSteps: [
                    {
                        ...basicWizardStepConfig,
                        id: 'tab-mode',
                        attachTo: { element: '#tab-mode', on: 'bottom' },
                        buttons: generateWizardButtons(1, 2),
                        title: generateTitle('Your Engagement'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>Select a type of engagement to see more about it</ShepherdTextBox>
                                    <ShepherdTextBox>
                                        The information featured in the Activity Feed below it will change to show that
                                        type of engagement
                                    </ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    },
                    {
                        ...basicWizardStepConfig,
                        id: 'table-box',
                        attachTo: { element: '.table-box', on: 'bottom' },
                        buttons: generateWizardButtons(2, 2),
                        title: generateTitle('Your Nulia Works Activity'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>
                                        Check out how you've been engaging with your Nulia Works world
                                    </ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    }
                ]
            });
        } else if (matchPath(routes.INSIGHTS_PERSONAL_BADGES, location.pathname)) {
            setConfig({
                wizardSteps: [
                    {
                        ...basicWizardStepConfig,
                        id: 'graph-box',
                        attachTo: { element: '#graph-holder-box', on: 'bottom' },
                        buttons: generateWizardButtons(1, 3),
                        title: generateTitle('Progress of Your Badges'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>
                                        Here you can see the progress you're making towards earning badges over time
                                    </ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    },
                    {
                        ...basicWizardStepConfig,
                        id: 'graph-legend-box',
                        attachTo: { element: '#graph-legend-box', on: 'bottom' },
                        buttons: generateWizardButtons(2, 3),
                        title: generateTitle('Badge Status'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>
                                        Select a status to add or remove it to your badge progress chart
                                    </ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    },
                    {
                        ...basicWizardStepConfig,
                        id: 'table',
                        attachTo: { element: '.table-box', on: 'bottom' },
                        buttons: generateWizardButtons(3, 3),
                        title: generateTitle('Your Badges'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>
                                        See a list of the badges that you have earned, claimed, and those that might
                                        Need Attention and select the columns to sort the list for that category
                                    </ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    }
                ]
            });
        } else if (matchPath(routes.INSIGHTS_ORGANIZATIONAL_USER_OUTCOMES, location.pathname)) {
            setConfig({
                wizardSteps: [
                    {
                        ...basicWizardStepConfig,
                        id: 'master-level-required-span',
                        attachTo: { element: '#master-level-required-span', on: 'bottom' },
                        buttons: generateWizardButtons(1, 1),
                        title: generateTitle('Request Master Level'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>
                                        Click here to ask the user to assign master level to themselves
                                    </ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    }
                ]
            });
        } else if (matchPath(routes.INSIGHTS_ORGANIZATIONAL_ASSESSMENT, location.pathname)) {
            setConfig({
                wizardSteps: [
                    {
                        ...basicWizardStepConfig,
                        id: 'date-dropdown',
                        attachTo: { element: '#date-dropdown', on: 'bottom' },
                        buttons: generateWizardButtons(1, 7),
                        title: generateTitle('Date selector'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>
                                        Select the carat to chose reports for different weeks
                                    </ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    },
                    {
                        ...basicWizardStepConfig,
                        id: 'department-dropdown',
                        attachTo: { element: '#department-dropdown', on: 'bottom' },
                        buttons: generateWizardButtons(2, 7),
                        title: generateTitle('Department selector'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>
                                        Select different departments here, but you can only see reports for departments
                                        with 10 or more members
                                    </ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    },
                    {
                        ...basicWizardStepConfig,
                        id: 'user-dropdown',
                        attachTo: { element: '#user-dropdown', on: 'bottom' },
                        buttons: generateWizardButtons(3, 7),
                        title: generateTitle('User selector'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>
                                        This menu allows you to chose reports for all users, users with Nulia Works
                                        licenses, or only users without Nulia Works licenses
                                    </ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    },
                    {
                        ...basicWizardStepConfig,
                        id: 'score-progress',
                        attachTo: { element: '.score-column', on: 'bottom' },
                        buttons: generateWizardButtons(4, 7),
                        title: generateTitle('Skill score'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>
                                        This metric reflects your organization's average score for this skill compared
                                        to the average score across the entire Nulia Works population
                                    </ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    },
                    {
                        ...basicWizardStepConfig,
                        id: 'adoption-progress',
                        attachTo: { element: '.adoption-column', on: 'bottom' },
                        buttons: generateWizardButtons(5, 7),
                        title: generateTitle('Skill adoption'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>
                                        This metric reflects the percentage of people in your organization that have
                                        achieved user level for this Skill compared to the average adoption across the
                                        entire Nulia Works population
                                    </ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    },
                    {
                        ...basicWizardStepConfig,
                        id: 'skill-name',
                        attachTo: { element: '#table-row-0', on: 'bottom' },
                        buttons: generateWizardButtons(6, 7),
                        title: generateTitle('Skill details'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>
                                        Select on any skill to see your orgranizaton's skill score and adoption over
                                        time
                                    </ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    },
                    {
                        ...basicWizardStepConfig,
                        id: 'download-report-box',
                        attachTo: { element: '#download-report-box', on: 'bottom' },
                        buttons: generateWizardButtons(7, 7),
                        title: generateTitle('Report download'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>
                                        Here you can download an Excel version of the metrics featured on this page
                                    </ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    }
                ]
            });
        } else if (
            matchPath(routes.INSIGHTS_ORGANIZATIONAL_ASSESSMENT_DETAILS, location.pathname) &&
            (location.hash === '#score' || location.hash === '#adoption')
        ) {
            setConfig({
                wizardSteps: [
                    {
                        ...basicWizardStepConfig,
                        id: 'radio-selector',
                        attachTo: { element: '#tab-mode', on: 'bottom' },
                        buttons: generateWizardButtons(1, 3),
                        title: generateTitle('Score and adoption selector'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>
                                        Use this to toggle between score and adoption over time.
                                    </ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    },
                    {
                        ...basicWizardStepConfig,
                        id: 'department-dropdown',
                        attachTo: { element: '#department-dropdown', on: 'bottom' },
                        buttons: generateWizardButtons(2, 3),
                        title: generateTitle('Department selector'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>
                                        Use this to compare one department scores to the entire company’s.
                                    </ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    },
                    {
                        ...basicWizardStepConfig,
                        id: 'user-dropdown',
                        attachTo: { element: '#user-dropdown', on: 'bottom' },
                        buttons: generateWizardButtons(3, 3),
                        title: generateTitle('Users selector'),
                        text: [
                            ReactDOMServer.renderToStaticMarkup(
                                <ShepherdContentBox>
                                    <ShepherdTextBox>
                                        Use this to compare users with or without Nulia Works licenses to the entire
                                        company
                                    </ShepherdTextBox>
                                </ShepherdContentBox>
                            )
                        ]
                    }
                ]
            });
        } else {
            setConfig({ wizardSteps: undefined });
        }
    }, [location, tour]);

    return {
        config,
        tourOptions,
        startTour,
        stopTour,
        isTourActive: !!tour?.isActive
    };
};

export default useWizardConfig;
