import styled from '../../../../../ui/theme/styled';

export const RootBox = styled('div')<{ isemphasized: number }>(({ isemphasized, theme }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    minHeight: 23,
    lineHeight: '16px',
    fontSize: 16,
    backgroundColor: isemphasized ? theme.palette.primary.main : 'inherit',
    color: isemphasized && theme.palette.common.white,
    borderRadius: '10px',
    marginBottom: 8,
    [theme.breakpoints.down('lg')]: {
        fontSize: '14px',
    },
    [theme.breakpoints.down('md')]: {
        fontSize: '10px',
    },
}));

export const KeyBox = styled('div')<{ isemphasized: number; secondarymode: number }>(
    ({ isemphasized, theme, secondarymode }) => ({
        flex: 1,
        display: 'flex',
        justifyContent: 'flex-end',
        textAlign: 'right',
        alignItems: 'center',
        fontFamily: 'Open Sans',
        fontSize: 16,
        color: isemphasized
            ? theme.palette.common.white
            : secondarymode
            ? theme.palette.gray.light
            : theme.palette.gray.medium,
        backgroundColor: isemphasized ? theme.palette.primary.main : 'inherit',
        borderTopLeftRadius: '10px',
        borderBottomLeftRadius: '10px',
        [theme.breakpoints.down('lg')]: {
            fontSize: '14px',
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '10px',
        },
    })
);

export const ValueBox = styled('div')<{ isemphasized: number; secondarymode: number }>(
    ({ isemphasized, theme, secondarymode }) => ({
        flex: 1,
        marginLeft: 24,
        textAlign: 'left',
        display: 'flex',
        alignItems: 'center',
        color: isemphasized ? theme.palette.common.white : secondarymode ? theme.palette.gray.light : '#616063',
        fontWeight: 400,
        backgroundColor: isemphasized ? theme.palette.primary.main : 'inherit',
        borderTopRightRadius: '10px',
        borderBottomRightRadius: '10px',
        [theme.breakpoints.down('md')]: {
            marginLeft: '6px',
        },
    })
);

export const ValueSuffixSpan = styled('span')<{ secondarymode: number }>(({ secondarymode, theme }) => ({
    color: secondarymode ? theme.palette.gray.light : undefined,
}));

export const ValueLabelSpan = styled('span')<{ secondarymode: number }>(({ secondarymode, theme }) => ({
    minWidth: 40,
    paddingRight: 6,
    textAlign: 'end',
    color: secondarymode ? theme.palette.gray.light : undefined,
}));
