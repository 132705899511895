import { FC, useCallback, useEffect, useMemo, KeyboardEvent } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Skeleton, Typography } from '@mui/material';
import { ContentBox, LoadingBox, RootBox, TitleBox, TitleTypography, ViewAllLink } from './Style';
import Achievement from '../achievement/Achievement';
import PrevNextItem from '../../../../ui/general/prev-next-item/PrevNextItem';
import usePrevNextVisible from '../../../../hooks/usePrevNextVisible';
import { makeAchievementDetailsRoute, makeAchievementsAllByCategoryRoute } from '../../../../pages/routes';
import { useCrumbsStateValue } from '../../../../contexts/CrumbsContext';
import { IAchievementOverviewGroupVM } from '../../../../interfaces/views/IAchievementOverviewGroupVM';
import Loading from '../../../../ui/general/loading/Loading';
import NoDataCard from '../../../../ui/cards/no-data-card/NoDataCard';
import SomethingWentWrongCard from '../../../../ui/cards/something-went-wrong-card/SomethingWentWrongCard';
import { accessibilityEnterSpaceKeyCallback } from '../../../../utils/accessibilityUtils';
import { isInTeams } from '../../../../utils/isInTeams';

interface IProps {
    achievementGroupList?: IAchievementOverviewGroupVM[];
    isLoading: boolean;
    isError: boolean;
    errorCallback: () => void;
}

const AchievementCard: FC<IProps> = ({ achievementGroupList, isLoading = false, isError, errorCallback }) => {
    const { changeHandler, visibleIndex, changeVisibleIndex } = usePrevNextVisible({ step: 1 });
    const navigate = useNavigate();
    const { appendCrumb, setCrumbs } = useCrumbsStateValue();
    const { t } = useTranslation();

    useEffect(() => {
        if (achievementGroupList) {
            const firstNonEmptyAchievementGroupIndex = achievementGroupList.findIndex(
                (achievementGroup) => achievementGroup.achievements && achievementGroup.achievements.length > 0
            );
            if (firstNonEmptyAchievementGroupIndex !== -1) {
                changeVisibleIndex(firstNonEmptyAchievementGroupIndex);
            } else {
                changeVisibleIndex(0);
            }
        }
    }, [achievementGroupList]);

    const viewAllAchievementsClickHandler = () => {
        if (achievementGroupList && achievementGroupList[visibleIndex]) {
            const route = makeAchievementsAllByCategoryRoute(achievementGroupList[visibleIndex].state);

            if (isInTeams()) {
                setCrumbs([
                    {
                        name: 'Showcase',
                        pathname: '/showcase',
                    },
                    {
                        name: 'Achievements',
                        pathname: route,
                    },
                ]);
            } else {
                appendCrumb({
                    name: 'Achievements',
                    pathname: route,
                });
            }
            navigate(route);
        }
    };

    const onAchievementClick = useCallback((achievementGroupId: number) => {
        if (achievementGroupId) {
            const route = makeAchievementDetailsRoute(achievementGroupId.toString());
            appendCrumb({
                name: 'Achievements',
                pathname: route,
            });
            navigate(route);
        }
    }, []);

    const achievementMemo = useMemo(() => {
        if (isError) {
            return <SomethingWentWrongCard boxStyle={{ boxShadow: 'none' }} actionCallback={errorCallback} />;
        }
        if (isLoading)
            return (
                <LoadingBox>
                    <Loading />
                </LoadingBox>
            );
        if (!achievementGroupList || achievementGroupList?.length === 0) return <></>;
        const achievements = achievementGroupList[visibleIndex].achievements;
        if (achievements.length === 0) {
            return (
                <NoDataCard textStyle={{ fontSize: 14 }} message={t('showcase.home.noAchievements')} noBorderShadow />
            );
        }
        return achievements.slice(0, 3).map((achievement, index) => {
            return (
                <Achievement
                    key={`${achievement.groupId}-${index}`}
                    description={achievement.description}
                    title={achievement.title}
                    engagementPointsEarned={achievement.engagementPoints}
                    imageUrl={achievement.image}
                    valueTotal={achievement.target}
                    valueAchieved={achievement.currentProgress}
                    groupId={achievement.groupId}
                    state={achievement.state}
                    category={achievement.category}
                    clickHandler={onAchievementClick}
                />
            );
        });
    }, [achievementGroupList, visibleIndex, isLoading, isError, errorCallback]);

    const titleMemo = useMemo(() => {
        if (isError) {
            return <TitleTypography variant="subtitle1">{t('showcase.home.achievements')}</TitleTypography>;
        }
        if (isLoading)
            return (
                <>
                    <Skeleton width="30%" height="26px" />
                    <Skeleton width="100px" height="26px" />
                </>
            );
        return (
            <>
                <TitleTypography variant="subtitle1">
                    {achievementGroupList && achievementGroupList.length > 0 && (
                        <>
                            {`Achievements ${achievementGroupList[visibleIndex].title}`}
                            <Typography variant="overline">
                                <ViewAllLink
                                    tabIndex={0}
                                    onClick={viewAllAchievementsClickHandler}
                                    onKeyDown={(e: KeyboardEvent<any>) =>
                                        accessibilityEnterSpaceKeyCallback(e, viewAllAchievementsClickHandler)
                                    }
                                    isDisabled={isLoading}
                                >
                                    {t('showcase.home.viewAllAchievements')}
                                </ViewAllLink>
                            </Typography>
                        </>
                    )}
                </TitleTypography>
                <PrevNextItem
                    isNextDisabled={!achievementGroupList || visibleIndex + 1 >= achievementGroupList.length}
                    isPreviousDisabled={visibleIndex <= 0}
                    changeHandler={changeHandler}
                    infoJsx={
                        achievementGroupList && achievementGroupList.length > 0
                            ? `${visibleIndex + 1} / ${achievementGroupList?.length}`
                            : ''
                    }
                />
            </>
        );
    }, [isLoading, achievementGroupList, visibleIndex, isError]);

    return (
        <RootBox>
            <TitleBox id="achievements-card-header">{titleMemo}</TitleBox>
            <ContentBox>{achievementMemo}</ContentBox>
        </RootBox>
    );
};

export default AchievementCard;
