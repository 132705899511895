import { Typography } from '@mui/material';
import styled from '../../../../ui/theme/styled';

export const RootBox = styled('div')(({ theme }) => ({
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'minmax(100px, 1fr) minmax(100px, 1fr)',
    gap: '20px',
    [theme.breakpoints.down(900)]: {
        gridTemplateColumns: 'auto',
    },
}));

export const RootColumn = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
});

export const InnerOptionsBox = styled('div')({
    paddingLeft: 34,
});

export const StyledForm = styled('form')({
    width: '100%',
    maxWidth: '1060px',
});

export const SubmitBox = styled('div')({
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '16px',
});

export const NotificationsWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
});

export const ManagerWrapper = styled('div')({});

export const LeaderboardWrapper = styled('div')({});

export const CollectorStatusWrapper = styled('div')({});

export const LicenseUtilizationWrapper = styled('div')({});

export const CollectorStatusInfoItemsWrapper = styled('div')(({ theme }) => ({
    padding: '0 21px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    gap: '12px',
    [theme.breakpoints.down('lg')]: {
        padding: '0 8px',
    },
}));

export const LicenseUtilizationInfoItemsWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    gap: '12px',
});

export const TenantStatusBox = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    marginTop: '8px',
});

export const TenantStatusTitleTypography = styled(Typography)({
    fontSize: '10px',
    fontWeight: 500,
});

export const TenantStatusContentTypography = styled(Typography)({});
