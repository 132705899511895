import { useState, useCallback, useEffect } from 'react';

interface IUsePaginationProps {
    step: number;
}

interface IUsePagination {
    activePage: number;
    rowsPerPage: number;
    changeActivePageHandler: (event: React.MouseEvent | null, page: number) => void;
    changeRowsPerPageHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
    visibleFromTo: IFromTo;
    changeVisibleFromToCallback: (props: IFromTo) => void;
}

export interface IFromTo {
    from: number;
    to: number;
}

const useGridPagination: (props: IUsePaginationProps) => IUsePagination = ({ step }) => {
    const [visibleFromTo, setVisibleFromTo] = useState<IFromTo>({ from: 0, to: step });
    const [activePage, setActivePage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);

    useEffect(() => {
        setVisibleFromTo({
            from: rowsPerPage * activePage,
            to: rowsPerPage * (activePage + 1),
        });
    }, [activePage, rowsPerPage]);

    const changeVisibleFromToCallback = useCallback((newDataLimit: IFromTo) => {
        setVisibleFromTo(newDataLimit);
    }, []);

    const changeActivePageHandler = (event: React.MouseEvent | null, page: number) => {
        setActivePage(page);
    };

    const changeRowsPerPageHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        try {
            setRowsPerPage(parseInt(event.target.value));
        } catch (e) {
            console.error(e);
        }
    };

    return {
        activePage,
        rowsPerPage,
        changeActivePageHandler,
        changeRowsPerPageHandler,
        visibleFromTo,
        changeVisibleFromToCallback: changeVisibleFromToCallback,
    };
};

export default useGridPagination;
