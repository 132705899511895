import { useTabsStateValue } from '../contexts/TabsContext';
import { useEffect } from 'react';

interface IUsePageNameProps {
    pageName?: string;
}

const usePageName = ({ pageName }: IUsePageNameProps) => {
    const { setPageName } = useTabsStateValue();

    useEffect(() => {
        if (setPageName) setPageName(pageName);
    }, [pageName]);

    useEffect(() => {
        return () => {
            if (setPageName) setPageName(undefined);
        };
    }, []);
};

export default usePageName;
