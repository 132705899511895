import { FC, useCallback, useState, KeyboardEvent } from 'react';
import { To, useNavigate } from 'react-router';
import { useMediaQuery, useTheme } from '@mui/material';
import PrevNextItem from '../../general/prev-next-item/PrevNextItem';
import NumberStatusBox, { INumberStatusBox } from '../../general/number-status-box/NumberStatusBox';
import {
    SkillSnapshotCardRoot,
    TitleDescriptionBox,
    TitleTypography,
    DescriptionTypography,
    SkillSnapshotCardItemBox,
    TitleBox,
} from './Style';
import { accessibilityEnterKeyCallback } from '../../../utils/accessibilityUtils';

interface IProps {
    snapshotItems: INumberStatusBox[][];
    snapshotItemsTitle: string[];
    snapshotItemsDescription: string[];
    snapshotItemDescriptionUrl?: To[];
}

const SnapshotCard: FC<IProps> = ({
    snapshotItems,
    snapshotItemsTitle,
    snapshotItemsDescription,
    snapshotItemDescriptionUrl,
}) => {
    const [visibleIndex, setVisibleIndex] = useState<number>(0);
    const theme = useTheme();
    const isLgDown = useMediaQuery(theme.breakpoints.down('lg'));
    const changeVisibleSnapshot = useCallback((isNext: boolean) => {
        if (isNext) {
            setVisibleIndex((visibleIndex) => visibleIndex + 1);
        } else {
            setVisibleIndex((visibleIndex) => visibleIndex - 1);
        }
    }, []);
    const navigate = useNavigate();

    const onSnapshotDescriptionClick = () => {
        if (snapshotItemDescriptionUrl) {
            navigate(snapshotItemDescriptionUrl[visibleIndex]);
        }
    };

    return (
        <SkillSnapshotCardRoot>
            <TitleBox>
                <TitleDescriptionBox>
                    <TitleTypography variant={isLgDown ? 'subtitle2' : 'subtitle1'}>
                        {snapshotItemsTitle[visibleIndex]}
                    </TitleTypography>
                    <DescriptionTypography
                        isLink={!!snapshotItemDescriptionUrl}
                        variant="overline"
                        onClick={onSnapshotDescriptionClick}
                        tabIndex={0}
                        onKeyDown={(e: KeyboardEvent<any>) =>
                            accessibilityEnterKeyCallback(e, onSnapshotDescriptionClick)
                        }
                    >
                        {snapshotItemsDescription[visibleIndex]}
                    </DescriptionTypography>
                </TitleDescriptionBox>
                <PrevNextItem
                    isPreviousDisabled={visibleIndex === 0}
                    isNextDisabled={visibleIndex + 1 === snapshotItems.length}
                    changeHandler={changeVisibleSnapshot}
                    infoJsx={`${visibleIndex + 1} / ${2}`}
                />
            </TitleBox>
            <SkillSnapshotCardItemBox>
                {snapshotItems[visibleIndex].map((snapshotItem) => {
                    return (
                        <NumberStatusBox
                            key={snapshotItem.elementId}
                            {...snapshotItem}
                            style={{ height: '90px' }}
                            labelPaddingBottom="6px"
                        />
                    );
                })}
            </SkillSnapshotCardItemBox>
        </SkillSnapshotCardRoot>
    );
};

export default SnapshotCard;
