import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

export const RootBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    '&:not(:last-child)': {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    background: theme.palette.common.white,
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.08), 0px 0.5px 1.5px rgba(0, 0, 0, 0.08)',
    minHeight: '209px',
    borderRadius: theme.borderRadius?.outside?.large,
    [theme.breakpoints.down('lg')]: {
        minHeight: 155,
    },
}));

export const TitleBox = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    padding: '14px 16px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
}));

export const TitleTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.gray.dark,
}));

export const ContentBox = styled('div')({
    display: 'flex',
    flexWrap: 'nowrap',
    gap: 23,
    height: '100%',
});

export const RecommendationCardItemWrapper = styled('div', {
    shouldForwardProp: (prop) => prop !== 'isVisible',
})<{ isVisible: boolean }>(({ isVisible }) => ({
    flexWrap: 'nowrap',
    flex: 1,
    display: isVisible ? 'flex' : 'none',
}));
