import { FC } from 'react';
import Loading from '../loading/Loading';
import {
    LineHr,
    RootBox,
    SkillTitleBox,
    SkillTitleTypography,
    SkillItem,
    SkillItemsWrapper,
    AppBox,
    AppsWrapper,
    AppImg,
} from './Style';
import { IAppDTO } from '../../../interfaces/dtos/IAppDTO';
import { createUrl } from '../../../utils/createUrl';
import fetchImageErrorCallback from '../../../utils/fetchImageErrorCallback';
import SkillItemTitle from './SkillItemTitle';

interface IProps {
    skills?: { id: number; title: string; apps: IAppDTO[] }[];
    skillClickHandler: (skillId: number) => void;
    isLoading: boolean;
}

const BasicSkillList: FC<IProps> = ({ skills, skillClickHandler, isLoading }) => {
    return (
        <RootBox className="skill-card-list">
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <SkillTitleBox>
                        <SkillTitleTypography variant="overline">Skills</SkillTitleTypography>
                        <LineHr />
                    </SkillTitleBox>
                    <SkillItemsWrapper>
                        {skills?.map((skill, i) => {
                            // @ts-ignore
                            return (
                                <SkillItem key={i} className={`basic-skill-list-item-${skill.id}`}>
                                    <AppsWrapper>
                                        {skill.apps.map((app, index) => {
                                            return (
                                                <AppBox key={index}>
                                                    <AppImg
                                                        src={createUrl(app.icon)?.generatedUrl}
                                                        onError={fetchImageErrorCallback}
                                                    />
                                                </AppBox>
                                            );
                                        })}
                                    </AppsWrapper>
                                    <SkillItemTitle
                                        title={skill.title}
                                        skillId={skill.id}
                                        skillClickHandler={skillClickHandler}
                                    />
                                </SkillItem>
                            );
                        })}
                    </SkillItemsWrapper>
                </>
            )}
        </RootBox>
    );
};

export default BasicSkillList;
