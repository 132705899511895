import { ThemeOptions } from '@mui/material/styles';

const textPrimary = 'rgba(0, 0, 0, 0.80)';
const textSecondary = 'rgba(0, 0, 0, 0.64)';
// const textShadowed = '#8C8C8C';

const fonts = ['Ubuntu'];

export const themeConfig = (): ThemeOptions => {
    return {
        borderRadius: {
            inside: {
                small: '4px',
                large: '8px',
            },
            outside: {
                small: '4px',
                large: '8px',
            },
            actions: {
                small: '4px',
                large: '8px',
            },
        },
        breakpoints: {
            values: {
                xs: 0,
                sm: 360,
                md: 600,
                lg: 1200,
                xl: 1920,
            },
        },
        typography: {
            fontFamily: fonts.join(','),
            fontSize: 16,
            label: {
                fontFamily: 'Open Sans',
                fontWeight: 400,
                fontSize: '10px',
                lineHeight: '20px',
                color: textSecondary,
                letterSpacing: '0.416px',
            },
            button: {
                fontFamily: 'Ubuntu',
                fontSize: '14px',
                fontWeight: 500,
                fontStyle: 'normal',
                lineHeight: '16px',
                letterSpacing: '1.25px',
                color: textPrimary,
                margin: 0,
            },
            h1: {
                fontFamily: 'Ubuntu',
                fontSize: '96px',
                fontWeight: 400,
                fontStyle: 'normal',
                lineHeight: '104px',
                letterSpacing: '-1.5px',
                color: textPrimary,
                margin: 0,
            },
            h2: {
                fontFamily: 'Open Sans',
                fontSize: '22px',
                fontWeight: 600,
                fontStyle: 'normal',
                lineHeight: '64px',
                letterSpacing: -0.5,
                color: textPrimary,
                margin: 0,
            },
            h3: {
                fontFamily: 'Ubuntu',
                fontSize: '48px',
                fontWeight: 400,
                fontStyle: 'normal',
                lineHeight: '56px',
                color: textPrimary,
                margin: 0,
            },
            h4: {
                fontFamily: 'Ubuntu',
                fontSize: '32px',
                fontWeight: 500,
                fontStyle: 'normal',
                lineHeight: '40px',
                letterSpacing: '0.25px',
                color: textPrimary,
                margin: 0,
            },
            h5: {
                fontFamily: 'Ubuntu',
                fontSize: '24px',
                fontWeight: 500,
                fontStyle: 'normal',
                lineHeight: '28px',
                letterSpacing: 'normal',
                color: textPrimary,
                margin: 0,
            },
            h6: {
                fontFamily: 'Ubuntu',
                fontWeight: 400,
                fontStyle: 'normal',
                fontSize: '20px',
                lineHeight: '24px',
                letterSpacing: '0.25px',
                color: textPrimary,
                margin: 0,
            },
            body1: {
                fontFamily: 'Open Sans',
                fontSize: '16px',
                fontWeight: 400,
                fontStyle: 'normal',
                lineHeight: '24px',
                letterSpacing: 0,
                color: textSecondary,
                margin: 0,
            },
            body2: {
                fontFamily: 'Open Sans',
                fontSize: '14px',
                fontWeight: 400,
                fontStyle: 'normal',
                lineHeight: '21px',
                letterSpacing: '0.25px',
                color: textSecondary,
                margin: 0,
            },
            subtitle1: {
                fontFamily: 'Ubuntu',
                fontSize: '16px',
                fontWeight: 500,
                fontStyle: 'normal',
                lineHeight: '24px',
                letterSpacing: '0.15px',
                color: textSecondary,
                margin: 0,
            },
            subtitle2: {
                fontFamily: 'Ubuntu',
                fontSize: '14px',
                fontWeight: 400,
                fontStyle: 'normal',
                lineHeight: '24px',
                letterSpacing: 0.1,
                color: textSecondary,
                margin: 0,
            },
            overline: {
                fontFamily: 'Ubuntu',
                fontSize: '12px',
                fontWeight: 400,
                fontStyle: 'normal',
                lineHeight: '16px',
                letterSpacing: '2px',
                color: textSecondary,
                textTransform: 'uppercase',
                margin: 0,
            },
            caption: {
                fontFamily: 'Open Sans',
                fontSize: '12px',
                fontWeight: 400,
                fontStyle: 'normal',
                lineHeight: '20px',
                letterSpacing: '0.5px',
                color: textSecondary,
                margin: 0,
            },
        },
        palette: {
            common: {
                white: '#FFF',
                black: '#000',
            },
            background: {
                default: 'rgb(245,245,245)',
            },
            primary: {
                light: '#f3f1f8',
                main: '#654ea3',
                dark: '#45356f',
                contrastText: '#fff',
            },
            secondary: {
                light: '#f3f1f8',
                main: '#654ea3',
                dark: '#45356f',
                contrastText: '#fff',
            },
            text: {
                primary: '#000000CC',
                secondary: '#8C8C8C',
            },
            error: {
                main: '#AAA',
            },
            action: {
                disabled: 'rgba(0, 0, 0, 0.16)',
            },
            gray: {
                dark: 'rgba(0, 0, 0, 0.8)',
                medium: '#616063',
                light: '#8C8C8C',
            },
            progressIndicators: {
                increase: 'rgba(176, 203, 62, 1)',
                flat: 'rgba(255, 204, 85, 1)',
                decrease: 'rgba(254, 108, 108, 1)',
                unassigned: 'rgba(214, 214, 214, 1)',
                warning: 'rgba(255, 204, 85, 1)',
                inProgress: 'rgba(234, 224, 8, 1)',
            },
            progressPercentages: {
                low: 'rgba(2, 59, 198, 1)',
                medium: 'rgba(2, 120, 229, 1)',
                high: 'rgba(52, 159, 238, 1)',
            },
            levels: {
                user: 'rgba(255, 149, 94, 1)',
                producer: 'rgba(221, 104, 130, 1)',
                master: 'rgba(52, 159, 238, 1)',
            },
            status: {
                assigned: '#654EA3',
                attained: '#92B923',
                inProgress: '#FFCC55',
                needAttention: '#FFA133',
            },
        },
        transitions: {
            duration: {
                shortest: 150,
                shorter: 200,
                short: 250,
                // most basic recommended timing
                standard: 300,
                // this is to be used in complex animations
                complex: 375,
                // recommended when something is entering screen
                enteringScreen: 225,
                // recommended when something is leaving screen
                leavingScreen: 195,
            },
        },
        components: {
            // MuiCssBaseline: {
            //     styleOverrides: {
            //         body: {
            //             backgroundColor: '#AAA',
            //         },
            //     },
            // },
            MuiAlert: {
                styleOverrides: {
                    standardSuccess: {
                        backgroundColor: 'green',
                        color: 'white',
                    },
                    standardError: {
                        backgroundColor: 'red',
                        color: 'white',
                    },
                    standardWarning: {
                        backgroundColor: 'orange',
                        color: 'white',
                    },
                    standardInfo: {
                        backgroundColor: 'grey',
                        color: 'black',
                    },
                    root: {
                        info: {
                            backgroundColor: '#654ea3',
                        },
                        error: {
                            backgroundColor: '#FF0',
                        },
                    },
                    icon: {
                        display: 'flex',
                        alignItems: 'center',
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        backgroundColor: '#654ea3',
                    },
                },
            },
            MuiSwitch: {
                styleOverrides: {
                    root: {
                        '.MuiSwitch-track': {
                            backgroundColor: '#D6D6D6 !important',
                            opacity: '1 !important',
                        },
                        '& .Mui-checked + .MuiSwitch-track': {
                            backgroundColor: '#C1B8DA !important',
                            opacity: '1 !important',
                        },
                    },
                    colorSecondary: {
                        '&.Mui-disabled .MuiSwitch-thumb': {
                            backgroundColor: '#F4F4F4 !important',
                        },
                        '&.Mui-checked .MuiSwitch-thumb': {
                            backgroundColor: '#654ea3 !important',
                            // C1B8DA
                        },
                        '.MuiSwitch-thumb': {
                            backgroundColor: '#F4F4F4',
                        },
                    },
                    thumb: {
                        backgroundColor: '#654ea3',
                    },
                },
            },
            MuiSelect: {
                styleOverrides: {
                    select: {
                        color: 'rgba(0, 0, 0, 0.64)',
                        borderRadius: 4,
                        fontFamily: 'Open Sans',
                        fontSize: 16,
                        lineHeight: '24px',
                        height: 36,
                        boxSizing: 'border-box',
                        padding: '4px 10px',
                        display: 'flex',
                        alignItems: 'center',
                        textOverflow: 'elipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                    },
                    icon: {
                        top: '16px',
                    },
                    outlined: {
                        '&:focus': {
                            outline: 'auto rgba(0,0,0,0.56)',
                        },
                    },
                },
            },
            MuiMenuItem: {
                styleOverrides: {
                    root: {
                        '&$.Mui-selected': {
                            backgroundColor: '#654ea3',
                            color: '#FFF',
                            '&:hover': {
                                backgroundColor: '#654ea3',
                                color: '#FFF',
                            },
                        },
                    },
                },
            },
            MuiTable: {
                styleOverrides: {
                    root: {
                        '.MuiTableRow-root.MuiTableRow-hover:hover': {
                            backgroundColor: '#f3f1f8',
                        },
                    },
                },
            },
            MuiTypography: {
                // variants: [
                //   {
                //     props: { variant: 'label' },
                //     style: {
                //       textTransform: 'none',
                //       // border: `2px dashed grey${blue[500]}`,
                //     },
                //   },
                // ],
                defaultProps: {
                    variantMapping: {
                        h1: 'h1',
                        h2: 'h2',
                        h3: 'h3',
                        h4: 'h4',
                        h5: 'h5',
                        h6: 'h6',
                        subtitle1: 'span',
                        subtitle2: 'span',
                        body1: 'span',
                        body2: 'span',
                        label: 'span',
                        overline: 'span',
                        caption: 'span',
                    },
                },
            },
        },
    };
};
