import { FC } from 'react';

const BenchmarkRangeIcon: FC = () => {
    return (
        <svg width="20" height="13" viewBox="0 0 20 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="20" y1="0.5" x2="4.37114e-08" y2="0.500002" stroke="black" strokeDasharray="2 2" />
            <rect y="1" width="20" height="11" fill="black" fillOpacity="0.1" />
            <line x1="20" y1="12.5" x2="4.37114e-08" y2="12.5" stroke="black" strokeDasharray="2 2" />
        </svg>
    );
};

export default BenchmarkRangeIcon;
