import { EBrowser } from '../interfaces/enums/EBrowser';

export const detectBrowser: () => string | null = () => {
    let userAgent = navigator.userAgent;
    let browserName;

    if (userAgent.match(/chrome|chromium|crios/i)) {
        browserName = EBrowser.CHROME;
    } else if (userAgent.match(/firefox|fxios/i)) {
        browserName = EBrowser.FIREFOX;
    } else if (userAgent.match(/safari/i)) {
        browserName = EBrowser.SAFARI;
    } else if (userAgent.match(/opr\//i)) {
        browserName = EBrowser.OPERA;
    } else if (userAgent.match(/edg/i)) {
        browserName = EBrowser.EDGE;
    } else {
        browserName = null;
    }
    return browserName;
};
