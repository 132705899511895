import { Typography } from '@mui/material';
import { styled } from '@mui/system';

export const SettingItem = styled('div')(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: '3fr 2fr',
    gap: '20px',
    [theme.breakpoints.down('lg')]: {
        display: 'flex',
        gap: '2px',
    },
    [theme.breakpoints.down(800)]: {
        flexWrap: 'wrap',
    },
}));

export const SettingItemLabel = styled(Typography)(({ theme }) => ({
    minWidth: '160px',
    display: 'flex',
    alignItems: 'center',
    gap: '15px',
    [theme.breakpoints.down('lg')]: {
        gap: '8px',
        minWidth: '140px',
        flex: 2,
    },
    [theme.breakpoints.down(800)]: {
        gap: 0,
        '&:after': {
            content: '":"',
            paddingRight: '4px',
        },
    },
}));

export const SettingItemValue = styled(Typography)(({ theme }) => ({
    minWidth: '120px',
    '&:before': {
        content: '":"',
        paddingRight: '4px',
    },
    [theme.breakpoints.down('lg')]: {
        flex: 1,
    },
    [theme.breakpoints.down(800)]: {
        '&:before': {
            content: '""',
            paddingRight: '4px',
        },
    },
}));

export const Dot = styled('span', {
    shouldForwardProp: (prop) => prop !== 'color',
})<{ color: string }>(({ color, theme }) => ({
    width: '13px',
    height: '13px',
    borderRadius: '7px',
    backgroundColor: color,
    display: 'inline-block',
    [theme.breakpoints.down(800)]: {
        marginRight: '8px',
    },
}));
