import { EUserPermission } from '../interfaces/enums/EUserPermission';

export const convertPermissionsToEnumValues: (permissionsValue: number) => EUserPermission[] = (rolesValue) => {
    const userPermissions: EUserPermission[] = [];

    if (rolesValue & 1) userPermissions.push(EUserPermission.USER);
    if (rolesValue & 2) userPermissions.push(EUserPermission.CHAMPION_ADMINISTRATION);
    if (rolesValue & 4) userPermissions.push(EUserPermission.ASSESSMENT);
    if (rolesValue & 8) userPermissions.push(EUserPermission.TENANT_INSIGHTS);
    if (rolesValue & 16) userPermissions.push(EUserPermission.TENANT_OUTCOME_ASSIGNMENT);
    if (rolesValue & 32) userPermissions.push(EUserPermission.LICENSE_ASSIGNMENT);
    if (rolesValue & 64) userPermissions.push(EUserPermission.ROLE_ASSIGNMENT);
    if (rolesValue & 128) userPermissions.push(EUserPermission.OUTCOME_FAVORITED_FOR_TENANT);
    if (rolesValue & 256) userPermissions.push(EUserPermission.OUTCOME_MASTER_LEVEL_REQUESTED_FOR_TENANT);
    if (rolesValue & 512) userPermissions.push(EUserPermission.TENANT_PREFERENCES);
    if (rolesValue & 1024) userPermissions.push(EUserPermission.PARTNER_ADMINISTRATION);

    return userPermissions;
};
