import { IAssignmentDTO } from '../interfaces/dtos/IAssignmentDTO';
import { ESkillLevel } from '../interfaces/enums/ESkillLevel';

const calculateAssignmentState = (assignment: IAssignmentDTO) => {
    let state = undefined;
    if (assignment.level === ESkillLevel.PRODUCER) state = assignment.producerState;
    if (assignment.level === ESkillLevel.MASTER) state = assignment.masterState;
    return state;
};

export default calculateAssignmentState;
