import { Typography } from '@mui/material';
import styled from '../../../../../ui/theme/styled';

export const RootBox = styled('div')(({ theme }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '10px 10px 10px 20px',
    [theme.breakpoints.down('lg')]: {
        padding: 0,
    },
}));

export const PointsBox = styled('div')({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
});

export const DescriptionItemsBox = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
});

export const PointsCountBox = styled('div')({
    padding: '0 10px',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    paddingLeft: 18,
});

export const HrLine = styled('hr')({
    width: '100%',
    height: 1,
    border: 'none',
    background: 'rgba(0, 0, 0, 0.16)',
    marginTop: 8,
});

export const HeaderBox = styled(Typography)<{ isselected: number }>(({ theme, isselected }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 8,
    padding: '4px 2px',
    '&:hover': {
        cursor: 'pointer',
        backgroundColor: isselected ? 'rgba(140,140,140,1)' : theme.palette.background.default,
    },
    background: isselected && 'rgb(140,140,140,0.8)',
    color: isselected && theme.palette.common.white,
}));

export const TitleSpan = styled(Typography)<{ isselected: number }>(({ isselected, theme }) => ({
    fontSize: 18,
    fontWeight: 500,
    display: 'flex',
    flex: 1,
    color: isselected ? `${theme.palette.common.white} !important` : '#000000CC',
    paddingLeft: 8,
    fontFamily: 'Ubuntu',
    [theme.breakpoints.down('md')]: {
        fontSize: '14px',
    },
}));

export const PointsEarnedSpan = styled(Typography)<{ isselected: number }>(({ theme, isselected }) => ({
    width: 31,
    height: 31,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: isselected ? theme.palette.primary.main : theme.palette.common.white,
    backgroundColor: isselected ? theme.palette.common.white : theme.palette.primary.main,
    borderRadius: 18,
    padding: 14,
    marginRight: 4,
    textAlign: 'end',
    fontSize: 14,
    fontFamily: 'Ubuntu',
    fontWeight: 500,
    [theme.breakpoints.down('md')]: {
        fontSize: '12px',
    },
}));

export const PointsMaxSpan = styled(Typography)<{ isselected: number }>(({ isselected, theme }) => ({
    fontSize: 18,
    color: isselected ? theme.palette.common.white : '#8C8C8C',
    minWidth: 38,
    textAlign: 'end',
    fontFamily: 'Ubuntu',
    [theme.breakpoints.down('md')]: {
        fontSize: '12px',
        minWidth: 30,
    },
}));

export const YourPointsLabel = styled(Typography)<{ isselected: number }>(({ isselected, theme }) => ({
    fontSize: 11,
    color: isselected ? theme.palette.common.white : '#8C8C8C',
    textTransform: 'uppercase',
    fontWeight: 500,
    letterSpacing: '1.25px',
    lineHeight: '16px',
    fontFamily: 'Ubuntu',
    [theme.breakpoints.down('md')]: {
        fontSize: '10px',
    },
}));
