import { createContext, FC, useContext, PropsWithChildren, useEffect } from 'react';
import { useParams } from 'react-router';
import { useGetHowToPlaylistCategoriesQuery, useGetHowToPlaylistQuery } from '../services/HowToService';
import { IHowTo } from '../interfaces/IHowTo';
import { IHowToPlaylistVM } from '../interfaces/views/IHowToPlaylistVM';

interface IHowToPlaylistContext {
    howToList?: IHowTo[];
    howToPlaylist?: IHowToPlaylistVM[];
    refetchHowToPlaylist: () => void;
    isFetchPlaylistLoading: boolean;
}

interface IProps {}

const HowToPlaylistContext = createContext<IHowToPlaylistContext>({} as IHowToPlaylistContext);

export const HowToPlaylistProvider: FC<PropsWithChildren<IProps>> = ({ children }) => {
    const params = useParams();
    const { data: fetchedHowToData, refetch: refetchHowTo } = useGetHowToPlaylistCategoriesQuery(params?.categoryId);
    const {
        data: fetchedHowToPlaylistData,
        refetch: refetchHowToPlaylist,
        isLoading: isFetchPlaylistLoading,
    } = useGetHowToPlaylistQuery(params?.playlistId);

    useEffect(() => {
        if (params && params.categoryId && params.playlistId) {
            refetchHowTo();
            refetchHowToPlaylist();
        }
    }, [params]);

    const howToPlaylistState: IHowToPlaylistContext = {
        howToList: fetchedHowToData,
        howToPlaylist: fetchedHowToPlaylistData,
        refetchHowToPlaylist,
        isFetchPlaylistLoading,
    };

    return <HowToPlaylistContext.Provider value={howToPlaylistState}>{children}</HowToPlaylistContext.Provider>;
};

export const useHowToPlaylistStateValue: () => IHowToPlaylistContext = () => useContext(HowToPlaylistContext);

export default HowToPlaylistContext;
