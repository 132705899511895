import { CSSProperties, FC } from 'react';
import { Container, MessageBox } from './Style';

interface IProps {
    message?: string;
    textStyle?: CSSProperties;
    noBorderShadow?: boolean;
    boxStyle?: CSSProperties;
}

const NoDataCard: FC<IProps> = ({ message = 'No data', textStyle, noBorderShadow = false, boxStyle }) => {
    return (
        <Container noBorderShadow={noBorderShadow} style={boxStyle}>
            <MessageBox style={textStyle} variant="h6">
                {message}
            </MessageBox>
        </Container>
    );
};

export default NoDataCard;
