import { ETenantPreference } from '../interfaces/enums/ETenantPreference';

export const convertTenantPreferencesToEnumValues = (tenantPreferenceValue: number) => {
    const userPermissions: ETenantPreference[] = [];

    if (tenantPreferenceValue & 1) userPermissions.push(ETenantPreference.ENABLE_ALL_EMAILS);
    if (tenantPreferenceValue & 2) userPermissions.push(ETenantPreference.ENABLE_WEEKLY_EMAILS);
    if (tenantPreferenceValue & 4) userPermissions.push(ETenantPreference.ENABLE_ONBOARDING_EMAILS);
    if (tenantPreferenceValue & 8) userPermissions.push(ETenantPreference.ENABLE_OUTCOME_ASSIGNED_NOTIFICATION);
    if (tenantPreferenceValue & 16) userPermissions.push(ETenantPreference.ENABLE_BADGE_ELIGIBILITY_NOTIFICATION);
    if (tenantPreferenceValue & 32) userPermissions.push(ETenantPreference.ENABLE_WEEKLY_UPDATE_AVAILABLE_NOTIFICATION);
    if (tenantPreferenceValue & 64)
        userPermissions.push(ETenantPreference.ENABLE_MANAGER_CAN_SEE_TEAM_MEMBERS_INSIGHTS);
    if (tenantPreferenceValue & 128)
        userPermissions.push(ETenantPreference.ENABLE_MANAGER_CAN_ASSIGN_OUTCOMES_TO_TEAM_MEMBERS);
    if (tenantPreferenceValue & 256) userPermissions.push(ETenantPreference.ENABLE_LEADERBOARD);

    return userPermissions;
};
