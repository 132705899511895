import { MARKETING_PATH } from '../../utils/constants';
import styled from '../../ui/theme/styled';

export const RootContainer = styled('div')(({ theme }) => ({
    height: '100vh',
    backgroundColor: theme.palette.primary.main,
    backgroundImage: `url(${MARKETING_PATH}teams_no_access.png)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'right top',
    padding: 32,
    [theme.breakpoints.down('md')]: {
        backgroundImage: 'none',
    },
}));

export const Main = styled('main')({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
});

export const DescriptionText = styled('h2')(({ theme }) => ({
    color: theme.palette.common.white,
    marginBottom: 16,
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 400,
    marginTop: 0,
    fontFamily: 'Open Sans',
}));

export const TitleText = styled('h1')(({ theme }) => ({
    color: theme.palette.common.white,
    marginTop: 0,
    marginBottom: 16,
    fontSize: 32,
    fontFamily: 'Ubuntu',
    letterSpacing: '0.25px',
}));
