const Play = () => {
    return (
        <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="22" cy="22" r="20.5" fill="white" />
            <circle cx="22" cy="22" r="20.5" fill="#654EA3" fillOpacity="0.32" />
            <circle cx="22" cy="22" r="20.5" stroke="white" strokeWidth="3" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.3707 12.979C17.7606 12.5868 16.9583 13.0248 16.9583 13.7501V30.2501C16.9583 30.9753 17.7606 31.4133 18.3707 31.0212L31.204 22.7712C31.7653 22.4103 31.7653 21.5898 31.204 21.229L18.3707 12.979ZM29.0132 22.0001L18.7916 28.571V15.4291L29.0132 22.0001Z"
                fill="#654EA3"
            />
        </svg>
    );
};

export default Play;
