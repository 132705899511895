import { Table, TableContainer } from '@mui/material';
import styled from '../../../../../ui/theme/styled';

export const StyledMain = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
});

export const RootInnerBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: '12px',
    width: 1080,
    [theme.breakpoints.down('lg')]: {
        width: '100%',
    },
}));

export const StyledTableContainer = styled(TableContainer)({
    borderRadius: 4,
    flex: 11,
});

export const StyledTable = styled(Table)(({ theme }) => ({
    // minWidth: 750,
    backgroundColor: theme.palette.common.white,
}));

export const FiltersWrapper = styled('div')({
    flex: 4,
});
