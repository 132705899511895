import styled from '../../theme/styled';

export const RootBox = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    height: 36,
    borderTopLeftRadius: 18,
    borderBottomLeftRadius: 18,
    borderTopRightRadius: 18,
    borderBottomRightRadius: 18,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 10,
    paddingRight: 10,
    width: 225,
    '&:focus': {
        border: '2px solid green',
    },
}));

export const SearchIconBox = styled('div')({
    height: 24,
    marginRight: 8,
    color: 'rgba(0, 0, 0, 0.4)',
});

export const StyledSearchInput = styled('input')(({ theme }) => ({
    width: '100%',
    border: 0,
    margin: 0,
    padding: 0,
    fontFamily: 'Open Sans',
    fontSize: 16,
    letterSpacing: 0.5,
    lineHeight: '24px',
    color: theme.palette.gray.light,
    '&:focus': {
        outlineColor: 'rgba(0, 0, 0, 0.54)',
        outlineStyle: 'auto',
        outlineOffset: '4px',
    },
    '&::placeholder': {
        color: '#adadad',
    },
}));
