import { FC } from 'react';

interface IProps {
    width?: string;
    height?: string;
}

const ErrorIcon: FC<IProps> = ({ width, height }) => {
    return (
        <svg
            width={width || '23'}
            height={height || '23'}
            viewBox="0 0 23 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="Group 112">
                <path
                    id="Vector"
                    d="M7.153 1H15.847L22 7.153V15.847L15.847 22H7.153L1 15.847V7.153L7.153 1Z"
                    fill="white"
                    stroke="#B30000"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    id="Vector_2"
                    d="M14.6501 8.34998L8.3501 14.65"
                    stroke="#B30000"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    id="Vector_3"
                    d="M8.3501 8.34998L14.6501 14.65"
                    stroke="#B30000"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </svg>
    );
};

export default ErrorIcon;
