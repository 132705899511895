import { Typography } from '@mui/material';
import { styled } from '@mui/system';

export const RankBox = styled('div')(() => ({
    display: 'grid',
    gridTemplateColumns: 'minmax(40px, 140px) minmax(100px, 2fr)',
    width: '100%',
    height: '100%',
    backgroundColor: '#FAFAFA',
}));

export const ValueBox = styled('div', {
    shouldForwardProp: (prop) => prop !== 'isEmpty',
})<{ isEmpty?: boolean }>(({ isEmpty = false }) => ({
    display: 'grid',
    gridTemplateColumns: isEmpty ? 'auto' : '1fr 2fr',
    alignItems: 'center',
    justifyItems: 'center',
    height: '100%',
    borderRight: '1px solid #00000029',
}));

export const InfoBox = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '20px 0 20px 16px',
    flex: 3,
}));

export const InfoTypeTypography = styled(Typography)({
    fontFamily: 'Segoe UI',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '20px',
    color: '#000',
});

export const RankPositionBox = styled('div')({
    display: 'flex',
    width: '100%',
    marginTop: '4px',
});

export const PercentageTypography = styled(Typography)({
    marginTop: '2px',
    width: '100%',
});

export const CurrentTypography = styled(Typography)({
    fontWeight: 'bold',
    fontSize: '20px',
});

export const TotalTypography = styled(Typography)({
    fontSize: '10px',
    marginTop: '2px',
    color: '#C3BDD2',
});

export const NATypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.secondary,
}));
