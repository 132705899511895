import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const RootBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    background: 'linear-gradient(180deg, #FFFFFF 0%, #FAFAFA 100%), #FFFFFF',
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.08), 0px 0.5px 1.5px rgba(0, 0, 0, 0.08)',
    borderRadius: theme.borderRadius?.outside?.small,
}));

export const TitleBox = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    padding: '16px 24px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
}));

export const TitleTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.gray.dark,
    [theme.breakpoints.down('lg')]: {
        fontWeight: 500,
        fontSize: '14px',
    },
}));

export const ViewAllLink = styled('a', {
    shouldForwardProp: (prop) => prop !== 'isDisabled',
})<{ isDisabled?: boolean }>(({ theme, isDisabled = false }) => ({
    marginLeft: '10px',
    color: isDisabled ? theme.palette.action.disabled : theme.palette.primary.main,
    textTransform: 'uppercase',
    textDecoration: 'none',
    '&:hover': {
        textDecoration: isDisabled ? 'none' : 'underline',
        cursor: isDisabled ? 'not-allowed' : 'pointer',
    },
}));

export const ContentBox = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'stretch',
    flexWrap: 'nowrap',
    gap: 23,
    padding: '32px',
    height: '100%',
    minHeight: '200px',
    borderRadius: '30px',
    [theme.breakpoints.down('lg')]: {
        alignItems: 'stretch',
    },
    [theme.breakpoints.down(840)]: {
        flexDirection: 'column',
        alignItems: 'center',
    },
}));

export const LoadingBox = styled('div')({
    width: '100%',
});
