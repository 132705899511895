import { CSSProperties, FC, MouseEvent, PropsWithChildren } from 'react';
import { StyledButton } from './Style';

export interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    id?: string;
    onClick?: (event: MouseEvent) => void;
    type?: 'button' | 'reset' | 'submit';
    disabled?: boolean;
    title?: string;
    key?: string;
    style?: CSSProperties;
}

const SecondaryButton: FC<PropsWithChildren<IProps>> = ({
    id,
    onClick,
    type,
    disabled,
    title,
    key,
    style,
    children,
    tabIndex,
}) => {
    return (
        <StyledButton
            id={id}
            onClick={onClick}
            type={type}
            disabled={disabled}
            title={title}
            key={key}
            isdisabled={disabled ?? false}
            style={style}
            tabIndex={tabIndex}
        >
            {children}
        </StyledButton>
    );
};

export default SecondaryButton;
