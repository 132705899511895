import { FC, useMemo, useRef } from 'react';
import { FiltersWrapper, RootInnerBox, StyledMain, StyledTable, StyledTableContainer } from './Style';
import { ITableHeadCell } from '../../../../../interfaces/ITableHeaderCell';
import Table from '../../../../../ui/general/table/Table';
import { useMediaQuery, useTheme } from '@mui/material';
import Filters from '../../../../../ui/filters/filters/Filters';
import { useAdminOutcomesStateValue } from '../../../../../contexts/AdminOutcomesContext';
import { FiltersProvider } from '../../../../../contexts/FiltersContext';
import useFilterDrawer from '../../../../../hooks/useFilterDrawer';
import { ITenantAllUserVM } from '../../../../../interfaces/views/ITenantAllUserVM';

interface ITableHeader {
    image: string;
    name: string;
    department: string;
    country: string;
    sourceLicenseType: string;
}

const UserSelection: FC = () => {
    const theme = useTheme();
    const isLgDown = useMediaQuery(theme.breakpoints.down('lg'));
    const {
        onFilterValueChangeUsersTab,
        userSelectionFilterCategories,
        users,
        changeSelectedUsersList,
        refetchUserSelectionData,
        isLoadingFetchingUserSelectionData,
        isErrorFetchingUserSelectionData,
        selectedUsersList,
    } = useAdminOutcomesStateValue();
    const { toggleFilterDrawer, isDrawerOpen } = useFilterDrawer();
    const userSelectionPropertyKeys = useRef<string[]>(['image', 'name', 'department', 'country', 'sourceLicenseType']);
    const tableHeadCells = useRef<ITableHeadCell<ITableHeader>[]>([
        {
            id: 'image',
            numeric: false,
            disablePadding: true,
            label: '',
        },
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: 'Name',
        },
        {
            id: 'department',
            numeric: false,
            disablePadding: false,
            label: 'Department',
        },
        {
            id: 'country',
            numeric: false,
            disablePadding: false,
            label: 'Region',
        },
        {
            id: 'sourceLicenseType',
            numeric: false,
            disablePadding: false,
            label: 'License Type',
        },
    ]);

    const filtersMemo = useMemo(() => {
        return (
            <Filters
                isDisabled={false}
                handleFilterValueChange={onFilterValueChangeUsersTab}
                categories={userSelectionFilterCategories}
                isQuickFiltersSelected={false}
                dropdownMode
                // filterElements={skills}
            />
        );
    }, [onFilterValueChangeUsersTab, userSelectionFilterCategories]);

    return (
        <StyledMain>
            <FiltersProvider categories={userSelectionFilterCategories}>
                <RootInnerBox>
                    <StyledTableContainer>
                        <StyledTable aria-labelledby="tableTitle" size="medium">
                            <Table<ITenantAllUserVM, ITableHeader>
                                setSelectedCallback={changeSelectedUsersList}
                                headCells={tableHeadCells.current}
                                data={users}
                                propertyKeys={userSelectionPropertyKeys.current}
                                tableTitlePlural="Users"
                                isFilterControlVisible={isLgDown}
                                isFilterDrawerOpen={isDrawerOpen}
                                filterControlToggleHandler={toggleFilterDrawer}
                                filtersDrawerMemo={filtersMemo}
                                isMultipleSelectionAvailable
                                isError={isErrorFetchingUserSelectionData}
                                isLoading={isLoadingFetchingUserSelectionData}
                                refetchDataCallback={refetchUserSelectionData}
                                isImageColumnPresent
                                initialSelectedRows={selectedUsersList}
                                userImageInfoColumn="name"
                                userImageInfoColumnPosition="bottom"
                            />
                        </StyledTable>
                    </StyledTableContainer>
                    {!isLgDown && <FiltersWrapper>{filtersMemo}</FiltersWrapper>}
                </RootInnerBox>
            </FiltersProvider>
        </StyledMain>
    );
};

export default UserSelection;
