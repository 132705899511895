import { createContext, FC, useCallback, useContext, useState, useEffect, PropsWithChildren, useRef } from 'react';
import { useTheme } from '@mui/system';
import { useTranslation } from 'react-i18next';
import PlusIconSVG from '../assets/icons/PlusIcon';
import { IFilterCategories, IFilterCategoryValue } from '../ui/filters/filters/Filters';
import {
    licenseCategories,
    licenseDepartmentFilterCallback,
    licenseCountryFilterCallback,
    licenseTypesFilterCallback,
} from '../contexts/util/filterCategories';
import useFilterSearch from '../hooks/useFilterSearch';
import MinusIconSVG from '../assets/icons/MinusIcon';
import {
    useGetLicensesCountQuery,
    usePostAssignLicense,
    usePostBulkAssignmentLicense,
    usePostRemoveLicense,
} from '../services/LicenseQueryService';
import { ILicensesCountDTO } from '../interfaces/dtos/ILicensesCountDTO';
import { ENuliaLicenseType } from '../interfaces/enums/ENuliaLicenseType';
import { EToastSeverity, useToastContextStateValue } from './ToastContext';
import { ELicenseAssignmentType } from '../interfaces/enums/ELicenseAssignmentType';
import { IUserImageResponse, usePostUserImagesQuery } from '../services/UserQueryService';
import { useTabsStateValue } from './TabsContext';
import { useGetTenantAllUsersQuery } from '../services/TenantQueryService';
import { ITenantLicensedUserVM } from '../interfaces/views/ITenantLicensedUserVM';
import { ITenantAllUserVM } from '../interfaces/views/ITenantAllUserVM';

export interface ILicensesContext {
    licenses?: ITenantLicensedUserVM[];
    licensesStatusOverallInfo: (ILicenseStatusInfo | ILicenseStatusInfo[])[];
    isLicensesLoading: boolean;
    userImages: IUserImageResponse[];
    changeLicensesStatusOverallActiveFilters: (key: ELicensesStatuses) => void;
    activeLicensesStatusOverallFilters: ELicensesStatuses[];
    onFilterValueChange: (
        filterCategories: IFilterCategories[],
        activeFilters: string[],
        dontRunAnythingChange?: boolean
    ) => void;
    searchText: string;
    filterCategories: IFilterCategories[];
    licensesCount?: ILicensesCountDTO;
    assignLicense: (id: string) => Promise<boolean>;
    removeLicense: (id: string) => Promise<boolean>;
    bulkAssignmentLicenses: (
        // licenseIds: number[],
        targetUserIds: string[],
        assignmentType: ELicenseAssignmentType
    ) => Promise<boolean>;
    selectedLicensesList: string[];
    setSelectedLicensesList: (licensesList: string[]) => void;
    changeSelectedLicensesList: (selected: string[]) => void;
    isErrorFetchingData: boolean;
    refetchData: () => void;
    isLoadingFetchingData: boolean;
}

export const LicensesContext = createContext<ILicensesContext>({} as ILicensesContext);

export enum ELicensesStatuses {
    LICENSES_PURCHASED = 'licensesPurchased',
    LICENSES_AVAILABLE = 'licensesAvailable',
    USERS_WITHOUT_LICENSE = 'userWithoutALicense',
    USERS_WITH_LICENSE = 'userWithALicense',
}

interface ILicenseStatusInfo {
    key: ELicensesStatuses;
    label: string;
    value?: number | string | null;
    icon?: React.ReactNode;
    circleColor?: string;
    isLoading?: boolean;
    isClickable: boolean;
}

interface IProps {}

export const LicensesProvider: FC<PropsWithChildren<IProps>> = ({ children }) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const [licenses, setLicenses] = useState<ITenantAllUserVM[] | undefined>();
    const [filteredLicenses, setFilteredLicenses] = useState<ITenantAllUserVM[] | undefined>();
    const [licensesStatusOverallInfo, setLicensesStatusOverallInfo] = useState<
        (ILicenseStatusInfo | ILicenseStatusInfo[])[]
    >([
        [
            {
                key: ELicensesStatuses.LICENSES_PURCHASED,
                label: t('licenses.statuses.licensesPurchased'),
                circleColor: theme.palette.primary.main,
                value: null,
                isLoading: true,
                isClickable: false,
            },
            {
                key: ELicensesStatuses.LICENSES_AVAILABLE,
                label: t('licenses.statuses.licensesAvailable'),
                circleColor: '#B0CB3E',
                value: null,
                isLoading: true,
                isClickable: false,
            },
        ],
        {
            key: ELicensesStatuses.USERS_WITHOUT_LICENSE,
            label: t('licenses.statuses.userWithoutLicense'),
            value: null,
            isLoading: true,
            icon: <PlusIconSVG />,
            isClickable: true,
        },
        {
            key: ELicensesStatuses.USERS_WITH_LICENSE,
            label: t('licenses.statuses.userWithLicense'),
            value: null,
            isLoading: true,
            icon: <MinusIconSVG />,
            isClickable: true,
        },
    ]);
    const [isLicensesLoading] = useState<boolean>(false);
    const [activeLicensesStatusOverallFilters, setActiveLicensesStatusOverallFilters] = useState<ELicensesStatuses[]>(
        []
    );
    const [filterCategories, setFilterCategories] = useState<IFilterCategories[]>(licenseCategories);
    const {
        data: licensesData,
        isError: isErrorGetTenantUsers,
        refetch: refetchGetTenantUsers,
        isLoading: isLoadingGetTenantUsers,
    } = useGetTenantAllUsersQuery();
    const { data: licensesCountData, isError: isFetchLicensesCountDataError } = useGetLicensesCountQuery();
    const { mutateAsync: mutatePostRemoveLicenseAsync } = usePostRemoveLicense();
    const { mutateAsync: mutatePostAssignLicenseAsync } = usePostAssignLicense();
    const { mutateAsync: mutatePostBulkAssignmentLicense } = usePostBulkAssignmentLicense();
    const { setToastMessage } = useToastContextStateValue();
    const { mutateAsync: mutatePostUserImagesAsync, data: fetchedUserImagesData } = usePostUserImagesQuery();
    const [userImages, setUserImages] = useState<IUserImageResponse[]>([]);
    const [selectedLicensesList, setSelectedLicensesList] = useState<string[]>([]);
    const activeFiltersRef = useRef<string[]>([]);

    useEffect(() => {
        if (fetchedUserImagesData) {
            setUserImages(fetchedUserImagesData);
        }
    }, [fetchedUserImagesData]);

    const changeSelectedLicensesList = useCallback((licensesList: string[]) => {
        setSelectedLicensesList(licensesList);
    }, []);

    useEffect(() => {
        if (licensesData) {
            mutatePostUserImagesAsync({
                userIds: licensesData.map((license) => license.id),
            });
            setFilteredLicenses(licensesData);
            setLicenses(licensesData);
        }
    }, [licensesData]);

    useEffect(() => {
        if (isErrorGetTenantUsers) {
            setLicensesStatusOverallInfo((statusInfoItems) => {
                return statusInfoItems.map((statusInfo) => {
                    if (Array.isArray(statusInfo)) {
                        return statusInfo;
                    } else
                        return {
                            ...statusInfo,
                            isLoading: false,
                            value: null,
                        };
                });
            });
        }
    }, [isErrorGetTenantUsers]);

    useEffect(() => {
        if (isFetchLicensesCountDataError) {
            setLicensesStatusOverallInfo((statusInfoItems) => {
                return statusInfoItems.map((statusInfo) => {
                    if (Array.isArray(statusInfo)) {
                        return statusInfo.map((statusInfoInner) => {
                            if (statusInfoInner.key === ELicensesStatuses.LICENSES_AVAILABLE)
                                return {
                                    ...statusInfoInner,
                                    isLoading: false,
                                    value: null,
                                };
                            if (statusInfoInner.key === ELicensesStatuses.LICENSES_PURCHASED)
                                return {
                                    ...statusInfoInner,
                                    isLoading: false,
                                    value: null,
                                };
                            return statusInfoInner;
                        });
                    } else return statusInfo;
                });
            });
        }
    }, [isFetchLicensesCountDataError]);

    useEffect(() => {
        if (licensesCountData) {
            setLicensesStatusOverallInfo((statusInfoItems) => {
                return statusInfoItems.map((statusInfo) => {
                    if (Array.isArray(statusInfo)) {
                        return statusInfo.map((statusInfoInner) => {
                            if (statusInfoInner.key === ELicensesStatuses.LICENSES_AVAILABLE)
                                return {
                                    ...statusInfoInner,
                                    isLoading: false,
                                    value: licensesCountData.available,
                                };
                            if (statusInfoInner.key === ELicensesStatuses.LICENSES_PURCHASED)
                                return {
                                    ...statusInfoInner,
                                    isLoading: false,
                                    value: licensesCountData.total,
                                };
                            return statusInfoInner;
                        });
                    } else return statusInfo;
                });
            });
        }
    }, [licensesCountData]);

    const onAnythingChange = (licensesNeedToBeFiltered: ITenantAllUserVM[], runSearchText?: boolean) => {
        let newFilteredLicenses = [...licensesNeedToBeFiltered];
        newFilteredLicenses = filterLicensesByLicenseStatusOverall(newFilteredLicenses);
        if (runSearchText) {
            newFilteredLicenses = instantFilterByText(searchText, newFilteredLicenses);
            newFilteredLicenses = onFilterValueChange(filterCategories, undefined, true, newFilteredLicenses);
            setFilteredLicenses(newFilteredLicenses);
        } else {
            newFilteredLicenses = onFilterValueChange(filterCategories, undefined, true, newFilteredLicenses);
            setFilteredLicenses(newFilteredLicenses);
        }
        return newFilteredLicenses;
    };

    const assignLicenseCallback = useCallback(async (id: string) => {
        try {
            const responseData = await mutatePostAssignLicenseAsync({
                id,
            });
            setToastMessage({
                isOpen: true,
                message: t('success.license.successAssignLicense'),
                severity: EToastSeverity.SUCCESS,
            });
            setLicenses((licenses) => {
                return licenses?.map((license) => {
                    if (license.id === id)
                        return {
                            ...license,
                            nwLicenseType: responseData.nuliaLicenseType,
                        };
                    return license;
                });
            });
            return true;
        } catch (e) {
            setToastMessage({
                isOpen: true,
                message: t('errors.license.errorAssignLicense'),
                severity: EToastSeverity.ERROR,
            });
            return false;
        }
    }, []);

    const removeLicenseCallback = useCallback(async (id: string) => {
        try {
            const responseData = await mutatePostRemoveLicenseAsync({
                id,
            });
            setToastMessage({
                isOpen: true,
                message: t('success.license.successRemoveLicense'),
                severity: EToastSeverity.SUCCESS,
            });
            setLicenses((licenses) => {
                return licenses?.map((license) => {
                    if (license.id === id)
                        return {
                            ...license,
                            nwLicenseType: responseData.nuliaLicenseType,
                        };
                    return license;
                });
            });
            return true;
        } catch (e) {
            setToastMessage({
                isOpen: true,
                message: t('errors.license.errorRemoveLicense'),
                severity: EToastSeverity.ERROR,
            });
            return false;
        }
    }, []);

    const bulkAssignmentLicensesCallback = useCallback(
        async (targetUserIds: string[], assignmentType: ELicenseAssignmentType) => {
            try {
                if (targetUserIds.length > 1) {
                    await mutatePostBulkAssignmentLicense({
                        // licenseIds,
                        targetUserIds,
                        assignmentType,
                    });
                    if (assignmentType === ELicenseAssignmentType.ASSIGN)
                        setToastMessage({
                            isOpen: true,
                            message: t('success.license.successAssignLicenses'),
                            severity: EToastSeverity.SUCCESS,
                        });
                    else {
                        setToastMessage({
                            isOpen: true,
                            message: t('success.license.successRemoveLicenses'),
                            severity: EToastSeverity.SUCCESS,
                        });
                    }
                    refetchGetTenantUsers();
                    return true;
                } else {
                    if (assignmentType === ELicenseAssignmentType.ASSIGN) {
                        await assignLicenseCallback(targetUserIds[0]);
                        return true;
                    } else {
                        await removeLicenseCallback(targetUserIds[0]);
                        return true;
                    }
                }
            } catch (e) {
                setToastMessage({
                    isOpen: true,
                    message: t('errors.license.errorBulkAssignmentLicense'),
                    severity: EToastSeverity.ERROR,
                });
                return false;
            }
        },
        []
    );

    const filterLicensesByLicenseStatusOverall = (licenses: ITenantAllUserVM[]) => {
        if (licenses) {
            let newLicenses = [...licenses];
            activeLicensesStatusOverallFilters.forEach((statusFilter) => {
                switch (statusFilter) {
                    case ELicensesStatuses.USERS_WITHOUT_LICENSE:
                        newLicenses = newLicenses.filter((license) => license.nwLicenseType === 'None');
                        break;
                    case ELicensesStatuses.USERS_WITH_LICENSE:
                        newLicenses = newLicenses.filter((license) => license.nwLicenseType === 'Complete');
                        break;
                }
            });
            return newLicenses;
        }
        return licenses;
    };

    useEffect(() => {
        if (licenses) {
            setFilteredLicenses(licenses);
            calculateLicenseStatusOverallInfo(licenses);
            const departments = new Set<string>();
            licenses.forEach((license) => {
                if (license.department) departments.add(license.department);
            });
            const licenseTypes = new Set<string>();
            licenses.forEach((license) => {
                if (license.sourceLicenseType) licenseTypes.add(license.sourceLicenseType as unknown as string);
            });
            const regions = new Set<string>();
            licenses.forEach((license) => {
                regions.add(license.country);
            });

            filterCategories.forEach((filterCategory) => {
                if (filterCategory.radioGroupId === 'allDepartments') {
                    const categoryValues: IFilterCategoryValue[] = [];
                    departments.forEach((department) => {
                        categoryValues.push({
                            key: department,
                            name: department,
                            callback: licenseDepartmentFilterCallback,
                        });
                    });
                    filterCategory.values = categoryValues;
                }
                if (filterCategory.radioGroupId === 'allLicenseTypes') {
                    const categoryValues: IFilterCategoryValue[] = [];
                    licenseTypes.forEach((licenseType) => {
                        categoryValues.push({
                            key: licenseType,
                            name: licenseType,
                            callback: licenseTypesFilterCallback,
                        });
                    });
                    filterCategory.values = categoryValues;
                }
                if (filterCategory.radioGroupId === 'allRegions') {
                    const categoryValues: IFilterCategoryValue[] = [];
                    regions.forEach((region) => {
                        categoryValues.push({
                            key: region,
                            name: region,
                            callback: licenseCountryFilterCallback,
                        });
                    });
                    filterCategory.values = categoryValues;
                }
            });
            setFilterCategories([...filterCategories]);
        }
    }, [licenses]);

    const calculateLicenseStatusOverallInfo = (filterLicenses?: ITenantAllUserVM[]) => {
        const licensesToFilter = filterLicenses || licenses;
        if (licensesToFilter) {
            let numberOfUsersWithoutALicense: number = licensesToFilter.filter(
                (license) =>
                    license.nwLicenseType === ENuliaLicenseType.NONE ||
                    license.nwLicenseType === ENuliaLicenseType.UNKNOWN
            ).length;
            let numberOfUsersWithALicense: number = licensesToFilter.filter(
                (license) => license.nwLicenseType === ENuliaLicenseType.COMPLETE
            ).length;

            setLicensesStatusOverallInfo((currentLicensesStatusOverall) => {
                let newLicensesStatusOverall = [...currentLicensesStatusOverall];
                newLicensesStatusOverall = newLicensesStatusOverall.map((sso) => {
                    if (Array.isArray(sso)) {
                        let newMappedStatusArray = sso.map((item) => {
                            switch (item.key) {
                                case ELicensesStatuses.USERS_WITHOUT_LICENSE:
                                    item.value = numberOfUsersWithoutALicense;
                                    break;
                                case ELicensesStatuses.USERS_WITH_LICENSE:
                                    item.value = numberOfUsersWithALicense;
                                    break;
                            }
                            return {
                                ...item,
                                isLoading: false,
                            };
                        });
                        return newMappedStatusArray;
                    } else {
                        switch (sso.key) {
                            case ELicensesStatuses.USERS_WITHOUT_LICENSE:
                                sso.value = numberOfUsersWithoutALicense;
                                break;
                            case ELicensesStatuses.USERS_WITH_LICENSE:
                                sso.value = numberOfUsersWithALicense;
                                break;
                        }
                        return {
                            ...sso,
                            isLoading: false,
                        };
                    }
                });
                return newLicensesStatusOverall;
            });
        }
    };

    const { searchText, setSearchText, instantFilterByText } = useFilterSearch<ITenantAllUserVM>({
        data: licenses || [],
        dataSerachablePropertyName: 'name',
        onChangeCallback: onAnythingChange,
        setDataCallback: setFilteredLicenses,
    });

    const { searchText: headerInputSearchText } = useTabsStateValue();
    useEffect(() => {
        setSearchText(headerInputSearchText);
    }, [headerInputSearchText]);

    useEffect(() => {
        if (licenses) onAnythingChange(licenses, true);
    }, [activeLicensesStatusOverallFilters, filterCategories, licenses, headerInputSearchText]);

    const changeLicensesStatusOverallActiveFilters = useCallback(
        (key: ELicensesStatuses) => {
            setActiveLicensesStatusOverallFilters((activeStatusList) => {
                if (activeStatusList.includes(key)) {
                    return [];
                }
                return [key];
            });
        },
        [filteredLicenses, activeLicensesStatusOverallFilters]
    );

    const onFilterValueChange = (
        filterCategories: IFilterCategories[],
        newActiveFilters?: string[],
        dontRunAnythingChange?: boolean,
        licensesToFilter?: ITenantAllUserVM[] // use this array for filtering if not undefined, else use current filteredSkills from this context
    ) => {
        let newFilteredLicenses: ITenantAllUserVM[] = [];
        if (licensesToFilter) newFilteredLicenses = licensesToFilter;
        else newFilteredLicenses = licenses ? [...licenses] : [];
        let currentActiveFilters = activeFiltersRef.current;
        if (newActiveFilters) {
            currentActiveFilters = newActiveFilters;
            activeFiltersRef.current = newActiveFilters;
        }
        filterCategories.forEach((filterCategory) => {
            if (filterCategory.values) {
                filterCategory.values!.forEach((filterCategoryValue) => {
                    if (filterCategoryValue.callback && currentActiveFilters.includes(filterCategoryValue.key)) {
                        newFilteredLicenses = newFilteredLicenses.filter((license) => {
                            if (filterCategoryValue.callback) {
                                const isValid = filterCategoryValue.callback(
                                    license,
                                    filterCategoryValue.name,
                                    filterCategoryValue.key
                                );
                                return isValid;
                            }
                            return false;
                        });
                    }
                });
            }
        });
        if (!dontRunAnythingChange) onAnythingChange(newFilteredLicenses, true);
        return newFilteredLicenses;
    };

    const refetchLicensesDataCallback = useCallback(() => {
        refetchGetTenantUsers();
        setLicensesStatusOverallInfo((statusInfoItems) => {
            return statusInfoItems.map((statusInfo) => {
                if (Array.isArray(statusInfo)) {
                    return statusInfo;
                } else
                    return {
                        ...statusInfo,
                        isLoading: true,
                        value: null,
                    };
            });
        });
    }, []);

    const licensesContext: ILicensesContext = {
        licenses: filteredLicenses,
        licensesStatusOverallInfo,
        isLicensesLoading,
        userImages,
        changeLicensesStatusOverallActiveFilters,
        activeLicensesStatusOverallFilters,
        onFilterValueChange,
        searchText,
        filterCategories,
        licensesCount: licensesCountData,
        assignLicense: assignLicenseCallback,
        removeLicense: removeLicenseCallback,
        bulkAssignmentLicenses: bulkAssignmentLicensesCallback,
        selectedLicensesList,
        setSelectedLicensesList,
        changeSelectedLicensesList,
        isErrorFetchingData: isErrorGetTenantUsers,
        refetchData: refetchLicensesDataCallback,
        isLoadingFetchingData: isLoadingGetTenantUsers,
    };

    return <LicensesContext.Provider value={licensesContext}>{children}</LicensesContext.Provider>;
};

export const useLicensesStateValue: () => ILicensesContext = () => useContext(LicensesContext);
