import { useTheme } from '@mui/system';
import { FC, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigationStateValue } from '../../../contexts/NavigationContext';
import { useCrumbsStateValue } from '../../../contexts/CrumbsContext';
import {
    NavigationItemContent,
    NavigationLinkItemContainer,
    Selector,
    StyledButton,
    StyledButtonSpan,
    TitleSpan,
} from './Style';

interface IProps {
    id?: string;
    title: string;
    icon: JSX.Element;
    paths: string[];
    primaryPath: string;
}

const NavigationLinkItem: FC<IProps> = ({ id, title, icon, paths, primaryPath }) => {
    const [selected, setSelected] = useState<boolean>(false);
    const navigate = useNavigate();
    const location = useLocation();
    const { setShowNav } = useNavigationStateValue();
    const theme = useTheme();
    const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));
    const { startNewCrumbs } = useCrumbsStateValue();

    useEffect(() => {
        setSelected(
            paths.includes(location.pathname.split('/')[1]) ||
                paths.find((path) => {
                    const basePath = path.split('/')[1];
                    return basePath === location.pathname.split('/')[1];
                }) !== undefined
        );
    }, [paths, location.pathname]);

    const openLink = () => {
        if (!isLgUp) {
            setShowNav(false);
        }
        startNewCrumbs(
            {
                name: title,
                pathname: primaryPath,
            },
            true
        );
        navigate(primaryPath);
    };
    return (
        <NavigationLinkItemContainer key={id}>
            {selected && <Selector />}
            <StyledButton onClick={openLink} disableFocusRipple>
                <StyledButtonSpan>
                    <NavigationItemContent isSelected={selected}>
                        {icon}
                        <TitleSpan>{title}</TitleSpan>
                    </NavigationItemContent>
                </StyledButtonSpan>
            </StyledButton>
        </NavigationLinkItemContainer>
    );
};

export default NavigationLinkItem;
