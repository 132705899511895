import styled from '../../../../../../ui/theme/styled';

export const RootBox = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
});

export const HeaderActionBox = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
});

export const FiltersBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flex: 5,
    gap: '48px',
    flexWrap: 'wrap',

    [theme.breakpoints.down('lg')]: {
        gap: '8px',
    },

    '& .MuiFormControl-root': {
        maxWidth: '300px',
    },

    '& .MuiInputBase-formControl': {
        background: theme.palette.common.white,
    },
}));
