import { FC, PropsWithChildren, KeyboardEvent } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { UseFormSetValue } from 'react-hook-form';

interface IProps {
    label?: string;
    isChecked: boolean;
    onChange?: any;
    field?: any;
    isDisabled?: boolean;
    size?: string;
    setValue?: UseFormSetValue<any>;
}

const NCheckbox: FC<PropsWithChildren<IProps>> = ({
    label,
    size,
    isChecked,
    onChange,
    field,
    isDisabled = false,
    setValue,
}) => {
    const onCheckboxOptionKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            const target = e.target as HTMLInputElement;
            if (setValue)
                setValue(target.name, target.value == 'true' ? false : true, {
                    shouldValidate: true,
                    shouldDirty: true,
                });
        }
    };
    return (
        <FormControlLabel
            label={label}
            control={
                <Checkbox
                    style={{ height: size || 'inherit', width: size || 'inherit' }}
                    disabled={isDisabled}
                    onChange={onChange}
                    onKeyDown={onCheckboxOptionKeyDown}
                    {...field}
                    checked={!!isChecked}
                    value={field?.value}
                />
            }
        />
    );
};

export default NCheckbox;
