export enum EUserPermission {
    USER = 1,
    CHAMPION_ADMINISTRATION = 2,
    ASSESSMENT = 4,
    TENANT_INSIGHTS = 8,
    TENANT_OUTCOME_ASSIGNMENT = 16,
    LICENSE_ASSIGNMENT = 32,
    ROLE_ASSIGNMENT = 64,
    OUTCOME_FAVORITED_FOR_TENANT = 128,
    OUTCOME_MASTER_LEVEL_REQUESTED_FOR_TENANT = 256,
    TENANT_PREFERENCES = 512,
    PARTNER_ADMINISTRATION = 1024,
}
