import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

export const SkillSnapshotCardItemBox = styled('div')(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: '10px',
    padding: 12,
    [theme.breakpoints.down('lg')]: {
        gridTemplateColumns: '1fr 1fr 1fr 1fr',
    },
    [theme.breakpoints.down('md')]: {
        justifyItems: 'center',
        gridTemplateColumns: '1fr 1fr',
        '& > div': {
            width: '100%',
        },
    },
    [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: '1fr',
    },
}));

export const TitleBox = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 20px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
    minHeight: 57,
});

export const TitleDescriptionBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('lg')]: {
        display: 'flex',
        flexDirection: 'row',
        gap: '12px',
        alignItems: 'center',
    },
    [theme.breakpoints.down(400)]: {
        gap: 4,
    },
}));

export const TitleTypography = styled(Typography)(({ theme }) => ({
    color: 'rgba(0, 0, 0, 0.8)',
    [theme.breakpoints.down('lg')]: {
        fontWeight: 500,
    },
}));

export const DescriptionTypography = styled(Typography, {
    shouldForwardProp: (prop) => prop !== 'isLink',
})<{ isLink?: boolean }>(({ theme, isLink = false }) => ({
    color: theme.palette.primary.main,
    cursor: isLink ? 'pointer' : 'inherit',
    '&:hover': {
        textDecoration: isLink ? 'underline' : 'inherit',
    },
    [theme.breakpoints.down('lg')]: {
        fontWeight: 500,
        minHeight: '16px',
        display: 'flex',
        alignItems: 'flex-end',
        fontSize: '10px',
    },
}));

export const SkillSnapshotCardRoot = styled('div')(({ theme }) => ({
    background: theme.palette.common.white,
    borderRadius: theme.borderRadius?.outside?.small,
    height: '100%',
    filter: 'drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.08)) drop-shadow(0px 0.5px 1.5px rgba(0, 0, 0, 0.08))',
}));
