const HowToNavigationIcon = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="1.6"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-grid"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            role="img"
        >
            <title>How To</title>
            <rect x="9.181344" y="9.1506939" width="5.6717939" height="5.6104913" />
            <rect x="9.1506929" y="1.089391" width="5.6717939" height="5.6104913" />
            <rect x="9.181344" y="17.211998" width="5.6717939" height="5.6104913" />
        </svg>
    );
};

export default HowToNavigationIcon;
