import { FC } from 'react';

interface IProps {
    width?: string;
    height?: string;
}

const ShareBadge: FC<IProps> = ({ width, height }) => {
    return (
        <svg
            width={width || 24}
            height={height || 24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.70711 6.70711L11 4.41421V15C11 15.5523 11.4477 16 12 16C12.5523 16 13 15.5523 13 15V4.41421L15.2929 6.70711C15.6834 7.09763 16.3166 7.09763 16.7071 6.70711C17.0976 6.31658 17.0976 5.68342 16.7071 5.29289L12.7071 1.29289C12.3166 0.902369 11.6834 0.902369 11.2929 1.29289L7.29289 5.29289C6.90237 5.68342 6.90237 6.31658 7.29289 6.70711C7.68342 7.09763 8.31658 7.09763 8.70711 6.70711ZM4 11C3.44772 11 3 11.4477 3 12V20C3 21.6569 4.34315 23 6 23H18C19.6569 23 21 21.6569 21 20V12C21 11.4477 20.5523 11 20 11C19.4477 11 19 11.4477 19 12V20C19 20.5523 18.5523 21 18 21H6C5.44772 21 5 20.5523 5 20V12C5 11.4477 4.55228 11 4 11Z"
                fill="#8C8C8C"
            />
        </svg>
    );
};

export default ShareBadge;
