import React, { useEffect, useRef, KeyboardEvent } from 'react';
import { Drawer, TableBody, TableCell, TableFooter, TableRow, useMediaQuery, SelectChangeEvent } from '@mui/material';
import { useTheme } from '@mui/system';
import { CloseOutlined } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import useTable, { Order } from '../../../hooks/useTable';
import TableHead from './table-head/TableHead';
import { ITableHeadCell } from '../../../interfaces/ITableHeaderCell';
import {
    StyledTable,
    StyledTableCell,
    StyledTableContainer,
    StyledTableRow,
    TableStatusHeader,
    ToolbarTypography,
    TableColumnImg,
    TableToolbar,
    StyledNoBorderTableCell,
    LgDownTableCell,
    LgDownCellContentBox,
    LgDownCellItemSpan,
    LgDownTableCellCustomMediaBox,
    LgDownCellItemLabel,
    LgDownCellItemValue,
    LgDownTableColumnImg,
    LgDownUserInfoLabel,
    LgDownUserImageBox,
    DropdownLgDownBox,
    DropdownTypography,
    StyledTableSortLabel,
    StyledCheckbox,
    StyledCheckboxTableCell,
} from './Style';
import {
    CloseDrawerActionWrapper,
    CloseDrawerBox,
    FilterDrawerBox,
    FilterIconSpan,
    StyledTablePagination,
} from '../../../css/CommonComponents';
import Tooltip from '../tooltip/Tooltip';
import FilterIcon from '../../../assets/icons/FilterIcon';
import useFetchImages from '../../../hooks/useFetchImages';
import UserPlaceholderIcon from '../../../assets/icons/UserPlaceholderIcon';
import SomethingWentWrongCard from '../../../ui/cards/something-went-wrong-card/SomethingWentWrongCard';
import Loading from '../loading/Loading';
import NoDataCard from '../../../ui/cards/no-data-card/NoDataCard';
import Dropdown from '../dropdown/Dropdown';
import fetchImageErrorCallback from '../../../utils/fetchImageErrorCallback';
import loadImageGraduallyHandler from '../../../utils/loadImageGradually';
import { accessibilityEnterKeyCallback } from '../../../utils/accessibilityUtils';

interface IId {
    id: string | number;
}

interface EnhancedTableProps<ITableRow extends IId, ITableHeaderCell> {
    data?: ITableRow[];
    headCells: ITableHeadCell<ITableHeaderCell>[];
    propertyKeys: string[];
    tableTitlePlural?: string;
    isMultipleSelectionAvailable?: boolean;
    setSelectedCallback?: (selected: string[]) => void;
    isFilterControlVisible?: boolean;
    isFilterDrawerOpen?: boolean;
    filterControlToggleHandler?: (event: React.KeyboardEvent | React.MouseEvent) => void;
    filtersDrawerMemo?: React.ReactNode;
    onRowClick?: (id: string, obj: any) => void;
    customToolbar?: React.ReactNode;
    isImageColumnPresent?: boolean;
    imageColumnName?: string;
    isError?: boolean;
    refetchDataCallback?: () => void;
    isLoading?: boolean;
    headerOverviewConfig?: {
        colSpan: number;
        label: string;
    }[];
    initialSelectedRows?: ITableRow[];
    tableCellStyle?: React.CSSProperties;
    headerCellStyle?: React.CSSProperties;
    initialOrderBy?: string;
    initialOrderDirection?: Order;
    isPaginationVisible?: boolean;
    customMediaColumnName?: string;
    customMediaSecondaryColumns?: string[];
    userImageInfoColumn?: string;
    userImageInfoColumnPosition?: 'right' | 'bottom';
    toolbarCellStyle?: React.CSSProperties;
}

function Table<ITableRow extends IId, ITableHeaderCell>(props: EnhancedTableProps<ITableRow, ITableHeaderCell>) {
    const {
        data,
        headCells,
        propertyKeys,
        tableTitlePlural,
        setSelectedCallback,
        isFilterControlVisible,
        isFilterDrawerOpen,
        filterControlToggleHandler,
        filtersDrawerMemo,
        isMultipleSelectionAvailable = false,
        onRowClick,
        customToolbar,
        isImageColumnPresent = false,
        isError = false,
        refetchDataCallback,
        isLoading = false,
        imageColumnName,
        headerOverviewConfig,
        initialSelectedRows,
        tableCellStyle,
        initialOrderBy,
        initialOrderDirection,
        headerCellStyle,
        isPaginationVisible = true,
        customMediaColumnName,
        customMediaSecondaryColumns,
        userImageInfoColumn,
        userImageInfoColumnPosition,
        toolbarCellStyle,
    } = props;
    const {
        handleChangePage,
        handleChangeRowsPerPage,
        handleClick,
        handleRequestSort,
        handleSelectAllClick,
        isSelected,
        page,
        rowsPerPage,
        selected,
        order,
        orderBy,
        getComparator,
        stableSort,
        setSelected,
    } = useTable<ITableRow>({ data: data || [], initialOrderBy, initialOrderDirection });
    const theme = useTheme();
    const { images, changeImageIds } = useFetchImages();
    const pageRowsRef = useRef<[number, number]>([-1, -1]);
    const isInitailRenderRef = useRef<boolean>(true);
    const isLgDown = useMediaQuery(theme.breakpoints.down('lg'));
    const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
    const { t } = useTranslation();

    useEffect(() => {
        if (isImageColumnPresent) {
            if (
                data &&
                data.length > 0 &&
                (page !== pageRowsRef.current[0] || rowsPerPage !== pageRowsRef.current[1])
            ) {
                pageRowsRef.current = [page, rowsPerPage];
                const sortedData = stableSort(data, getComparator(order, orderBy));
                changeImageIds(
                    sortedData
                        .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                        //@ts-ignore
                        .map((row) => (imageColumnName ? row[imageColumnName].toString() : row.id.toString())),
                    sortedData
                        .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                        //@ts-ignore
                        .map((row) => (imageColumnName ? row[imageColumnName].toString() : row.id.toString())),
                    sortedData
                        .slice((page + 1) * rowsPerPage, (page + 2) * rowsPerPage)
                        //@ts-ignore
                        .map((row) => (imageColumnName ? row[imageColumnName].toString() : row.id.toString()))
                );
            }
        }
    }, [data, page, rowsPerPage, isImageColumnPresent]);

    useEffect(() => {
        if (setSelectedCallback && !isInitailRenderRef.current) {
            setSelectedCallback(selected);
        }
    }, [selected]);

    useEffect(() => {
        if (initialSelectedRows && isInitailRenderRef.current) {
            setSelected([...initialSelectedRows.map((d) => d.id.toString())]);
        }
        isInitailRenderRef.current = false;
    }, [initialSelectedRows]);

    const customSort = headCells.find((headCell) => {
        return headCell.id === orderBy && headCell.customSort;
    })?.customSort;

    return (
        <StyledTableContainer className="table-box">
            <>
                <TableToolbar>
                    {isFilterDrawerOpen && (
                        <Drawer anchor="right" open={isFilterDrawerOpen} onClose={filterControlToggleHandler}>
                            <CloseDrawerBox>
                                <CloseDrawerActionWrapper onClick={filterControlToggleHandler}>
                                    <CloseOutlined />
                                </CloseDrawerActionWrapper>
                            </CloseDrawerBox>
                            <FilterDrawerBox>{filtersDrawerMemo}</FilterDrawerBox>
                        </Drawer>
                    )}
                    {(isFilterControlVisible || isMultipleSelectionAvailable) && (
                        <TableStatusHeader>
                            {isFilterControlVisible && (
                                <Tooltip title={t('tooltips.filters')}>
                                    <FilterIconSpan
                                        onClick={filterControlToggleHandler}
                                        tabIndex={0}
                                        onKeyDown={(e: KeyboardEvent<any>) =>
                                            accessibilityEnterKeyCallback(e, () => {
                                                if (filterControlToggleHandler) filterControlToggleHandler(e);
                                            })
                                        }
                                    >
                                        <FilterIcon />
                                    </FilterIconSpan>
                                </Tooltip>
                            )}
                            {isMultipleSelectionAvailable && (
                                <ToolbarTypography color="inherit" variant="caption">
                                    {selected.length > 0 && data
                                        ? `${selected.length}/${data.length} ${tableTitlePlural || 'Items'} Selected`
                                        : `${data ? data.length : 0} ${tableTitlePlural || 'Items'}`}
                                </ToolbarTypography>
                            )}
                        </TableStatusHeader>
                    )}
                    {customToolbar}
                    {isLgDown && (
                        <DropdownLgDownBox style={toolbarCellStyle}>
                            {!isMdDown && <DropdownTypography variant="overline">Sort By:</DropdownTypography>}
                            <Dropdown
                                items={headCells
                                    .filter((cell) => cell.label !== '')
                                    .map((cell) => {
                                        return {
                                            name: cell.label as string,
                                            value: cell.id as string,
                                        };
                                    })}
                                value={orderBy}
                                handleChange={(e: SelectChangeEvent<unknown>) => {
                                    const v = e.target.value as string;
                                    handleRequestSort(v as keyof ITableRow);
                                }}
                            />
                            <Tooltip
                                title={order === 'asc' ? t('tooltips.showDescending') : t('tooltips.showAscending')}
                            >
                                <StyledTableSortLabel
                                    active={true}
                                    direction={order}
                                    tabIndex={0}
                                    onKeyDown={(e: KeyboardEvent<any>) =>
                                        accessibilityEnterKeyCallback(e, () =>
                                            handleRequestSort(orderBy as keyof ITableRow)
                                        )
                                    }
                                    onClick={(e: React.MouseEvent<unknown>) => {
                                        handleRequestSort(orderBy as keyof ITableRow);
                                    }}
                                />
                            </Tooltip>
                        </DropdownLgDownBox>
                    )}
                </TableToolbar>
                <StyledTable sx={{ backgroundColor: theme.palette.common.white }} size="medium">
                    {!isLgDown && (
                        <TableHead<ITableHeaderCell>
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            isDisabled={isLoading}
                            onSelectAllClick={handleSelectAllClick}
                            // @ts-ignore
                            onRequestSort={handleRequestSort}
                            rowCount={data?.length || 0}
                            headCells={headCells}
                            isMultipleSelectionAvailable={isMultipleSelectionAvailable}
                            headerOverviewConfig={headerOverviewConfig}
                            headerCellStyle={headerCellStyle}
                        />
                    )}
                    {!isLoading && !isError && !data && (
                        <TableBody>
                            <StyledTableRow isClickable={false}>
                                <StyledTableCell
                                    colSpan={isMultipleSelectionAvailable ? headCells.length + 1 : headCells.length}
                                >
                                    <NoDataCard />
                                </StyledTableCell>
                            </StyledTableRow>
                        </TableBody>
                    )}
                    {isLoading || isError ? (
                        <TableBody>
                            <StyledTableRow isClickable={false}>
                                <StyledTableCell
                                    colSpan={isMultipleSelectionAvailable ? headCells.length + 1 : headCells.length}
                                >
                                    {isLoading ? (
                                        <Loading />
                                    ) : (
                                        <SomethingWentWrongCard
                                            boxStyle={{ boxShadow: 'unset' }}
                                            actionCallback={refetchDataCallback}
                                        />
                                    )}
                                </StyledTableCell>
                            </StyledTableRow>
                        </TableBody>
                    ) : (
                        <>
                            <TableBody>
                                {(isLoading || isError || (data && data?.length > 0)) &&
                                    !isLgDown &&
                                    headCells.some((d) => d.customColumnLegend) && (
                                        <StyledTableRow isClickable={!!onRowClick}>
                                            {propertyKeys.map((propertyKey, index) => {
                                                const headCell: ITableHeadCell<ITableHeaderCell> | undefined =
                                                    // @ts-ignore
                                                    headCells.find((headCell) => headCell.id === propertyKey);
                                                if (headCell?.customColumnLegend) {
                                                    return (
                                                        <StyledNoBorderTableCell key={index} style={{ border: 'none' }}>
                                                            {headCell.customColumnLegend()}
                                                        </StyledNoBorderTableCell>
                                                    );
                                                }
                                                return <StyledNoBorderTableCell key={index} />;
                                            })}
                                        </StyledTableRow>
                                    )}
                                {isLgDown
                                    ? stableSort(data || [], customSort ? customSort : getComparator(order, orderBy))
                                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                          .map((row: ITableRow, rowIndex) => {
                                              const isItemSelected =
                                                  isSelected(row.id.toString()) ||
                                                  initialSelectedRows?.find(
                                                      (d) => row.id.toString() === d.id.toString()
                                                  );

                                              const customMediaHeadCell: ITableHeadCell<ITableHeaderCell> | undefined =
                                                  // @ts-ignore
                                                  headCells.find((headCell) => headCell.id === customMediaColumnName);

                                              const userImageHeadCell = headCells.find(
                                                  (headCell) => headCell.id === 'image'
                                              );
                                              let userImageLgDownColumn;

                                              if (userImageHeadCell) {
                                                  const image = images?.find((image) =>
                                                      imageColumnName
                                                          ? //@ts-ignore
                                                            row[imageColumnName] === image.id
                                                          : row.id === image.id
                                                  );

                                                  userImageLgDownColumn = (
                                                      <LgDownTableCell
                                                          padding="none"
                                                          align="center"
                                                          style={{
                                                              padding: userImageHeadCell?.disablePadding ? '0' : 'auto',
                                                              paddingTop: 15,
                                                              paddingRight: 10,
                                                          }}
                                                          key={row.id}
                                                      >
                                                          <LgDownUserImageBox orientation={userImageInfoColumnPosition}>
                                                              {image && image.data ? (
                                                                  <LgDownTableColumnImg
                                                                      src={`data:${image?.contentType};base64,${image?.data}`}
                                                                      alt={`row ${rowIndex}`}
                                                                      onError={fetchImageErrorCallback}
                                                                      onLoad={loadImageGraduallyHandler}
                                                                  />
                                                              ) : (
                                                                  <UserPlaceholderIcon width="64px" height="64px" />
                                                              )}
                                                              <LgDownUserInfoLabel
                                                                  orientation={userImageInfoColumnPosition}
                                                              >
                                                                  {/* @ts-ignore */}
                                                                  {row[userImageInfoColumn]}
                                                              </LgDownUserInfoLabel>
                                                          </LgDownUserImageBox>
                                                          <LgDownTableCellCustomMediaBox>
                                                              {customMediaSecondaryColumns?.map(
                                                                  (secondaryColumnKey, index) => {
                                                                      const headCell:
                                                                          | ITableHeadCell<ITableHeaderCell>
                                                                          | undefined =
                                                                          // @ts-ignore
                                                                          headCells.find(
                                                                              (headCell) =>
                                                                                  headCell.id === secondaryColumnKey
                                                                          );

                                                                      if (headCell && headCell.customRender) {
                                                                          return (
                                                                              <span key={index}>
                                                                                  {/* @ts-ignore */}
                                                                                  {headCell.customRender(
                                                                                      // @ts-ignore
                                                                                      row[secondaryColumnKey],
                                                                                      row
                                                                                  )}
                                                                              </span>
                                                                          );
                                                                      }
                                                                      return (
                                                                          <span key={index}>
                                                                              {/* @ts-ignore */}
                                                                              {row[customMediaColumnName]}
                                                                          </span>
                                                                      );
                                                                  }
                                                              )}
                                                          </LgDownTableCellCustomMediaBox>
                                                      </LgDownTableCell>
                                                  );
                                              }

                                              return (
                                                  <StyledTableRow
                                                      id={`table-row-${rowIndex}`}
                                                      hover
                                                      onClick={
                                                          onRowClick
                                                              ? (e) => {
                                                                    e.stopPropagation();
                                                                    onRowClick(row.id.toString(), row);
                                                                }
                                                              : (event) => {
                                                                    handleClick(event, row.id.toString());
                                                                }
                                                      }
                                                      role="checkbox"
                                                      tabIndex={-1}
                                                      key={row.id}
                                                      isClickable={!!onRowClick}
                                                  >
                                                      {isMultipleSelectionAvailable && (
                                                          <StyledCheckboxTableCell
                                                              style={tableCellStyle}
                                                              padding="checkbox"
                                                              key={`${row.id}-multiselect`}
                                                          >
                                                              <StyledCheckbox
                                                                  color="primary"
                                                                  checked={!!isItemSelected}
                                                                  inputProps={{
                                                                      'aria-label': `Checkbox for row ${rowIndex}`,
                                                                  }}
                                                              />
                                                          </StyledCheckboxTableCell>
                                                      )}

                                                      {isImageColumnPresent && userImageLgDownColumn}

                                                      {customMediaColumnName && (
                                                          <LgDownTableCell
                                                              padding="none"
                                                              align="center"
                                                              style={{
                                                                  padding: customMediaHeadCell?.disablePadding
                                                                      ? '0'
                                                                      : 'auto',
                                                                  paddingTop: 15,
                                                                  paddingRight: 10,
                                                              }}
                                                              key={`${row.id}-${customMediaColumnName}`}
                                                          >
                                                              <LgDownTableCellCustomMediaBox>
                                                                  {customMediaHeadCell?.customRender
                                                                      ? // @ts-ignore
                                                                        customMediaHeadCell?.customRender(
                                                                            // @ts-ignore
                                                                            row[customMediaColumnName],
                                                                            row
                                                                        )
                                                                      : // @ts-ignore
                                                                        row[customMediaColumnName]}
                                                              </LgDownTableCellCustomMediaBox>
                                                              <LgDownTableCellCustomMediaBox>
                                                                  {customMediaSecondaryColumns?.map(
                                                                      (secondaryColumnKey, index) => {
                                                                          const headCell:
                                                                              | ITableHeadCell<ITableHeaderCell>
                                                                              | undefined =
                                                                              // @ts-ignore
                                                                              headCells.find(
                                                                                  (headCell) =>
                                                                                      headCell.id === secondaryColumnKey
                                                                              );

                                                                          if (headCell && headCell.customRender) {
                                                                              return (
                                                                                  <span key={index}>
                                                                                      {/* @ts-ignore */}
                                                                                      {headCell.customRender(
                                                                                          // @ts-ignore
                                                                                          row[secondaryColumnKey],
                                                                                          row
                                                                                      )}
                                                                                  </span>
                                                                              );
                                                                          }
                                                                          return (
                                                                              <span key={index}>
                                                                                  {/* @ts-ignore */}
                                                                                  {row[customMediaColumnName]}
                                                                              </span>
                                                                          );
                                                                      }
                                                                  )}
                                                              </LgDownTableCellCustomMediaBox>
                                                          </LgDownTableCell>
                                                      )}

                                                      <LgDownTableCell
                                                          colSpan={
                                                              propertyKeys.filter(
                                                                  (propertyKey) =>
                                                                      propertyKey !== 'image' &&
                                                                      !customMediaSecondaryColumns?.includes(
                                                                          propertyKey
                                                                      )
                                                              ).length
                                                          }
                                                          key={`${row.id}-main`}
                                                      >
                                                          <LgDownCellContentBox>
                                                              {propertyKeys
                                                                  .filter(
                                                                      (propertyKey) =>
                                                                          propertyKey !== customMediaColumnName &&
                                                                          !customMediaSecondaryColumns?.includes(
                                                                              propertyKey
                                                                          ) &&
                                                                          propertyKey !== userImageInfoColumn
                                                                  )
                                                                  .map((propertyKey, index) => {
                                                                      const headCell:
                                                                          | ITableHeadCell<ITableHeaderCell>
                                                                          | undefined =
                                                                          // @ts-ignore
                                                                          headCells.find(
                                                                              (headCell) => headCell.id === propertyKey
                                                                          );

                                                                      if (
                                                                          headCell &&
                                                                          headCell.customRender &&
                                                                          headCell.id !== customMediaColumnName
                                                                      ) {
                                                                          return (
                                                                              <LgDownCellItemSpan
                                                                                  key={`${row.id}-${index}`}
                                                                              >
                                                                                  <LgDownCellItemLabel>
                                                                                      {headCell?.label}:
                                                                                  </LgDownCellItemLabel>
                                                                                  <LgDownCellItemValue
                                                                                      style={{
                                                                                          height:
                                                                                              headCell.height ||
                                                                                              'unset',
                                                                                      }}
                                                                                  >
                                                                                      {/* @ts-ignore */}
                                                                                      {headCell.customRender(
                                                                                          // @ts-ignore
                                                                                          row[propertyKey],
                                                                                          row
                                                                                      )}
                                                                                  </LgDownCellItemValue>
                                                                              </LgDownCellItemSpan>
                                                                          );
                                                                      }

                                                                      let lgDownColumnLabel;
                                                                      if (headCell?.label)
                                                                          lgDownColumnLabel = `${headCell?.label}:`;
                                                                      if (headCell?.customRender) {
                                                                          /* @ts-ignore */
                                                                          lgDownColumnLabel = `${headCell.customRender(
                                                                              // @ts-ignore
                                                                              row[propertyKey],
                                                                              row
                                                                          )}:`;
                                                                      }

                                                                      return (
                                                                          <LgDownCellItemSpan
                                                                              key={`${row.id}-${index}`}
                                                                          >
                                                                              <LgDownCellItemLabel>
                                                                                  {lgDownColumnLabel}
                                                                              </LgDownCellItemLabel>
                                                                              {/* @ts-ignore */}
                                                                              <LgDownCellItemValue
                                                                                  style={{
                                                                                      height:
                                                                                          headCell?.height || 'unset',
                                                                                  }}
                                                                              >
                                                                                  {/* @ts-ignore */}
                                                                                  {row[propertyKey]}
                                                                              </LgDownCellItemValue>
                                                                          </LgDownCellItemSpan>
                                                                      );
                                                                  })}
                                                          </LgDownCellContentBox>
                                                      </LgDownTableCell>
                                                  </StyledTableRow>
                                              );
                                          })
                                    : stableSort(data || [], customSort ? customSort : getComparator(order, orderBy))
                                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                          .map((row: ITableRow, rowIndex) => {
                                              const isItemSelected =
                                                  isSelected(row.id.toString()) ||
                                                  initialSelectedRows?.find(
                                                      (d) => row.id.toString() === d.id.toString()
                                                  );

                                              return (
                                                  <StyledTableRow
                                                      id={`table-row-${rowIndex}`}
                                                      hover
                                                      onClick={
                                                          onRowClick
                                                              ? (e) => {
                                                                    e.stopPropagation();
                                                                    onRowClick(row.id.toString(), row);
                                                                }
                                                              : (event) => {
                                                                    handleClick(event, row.id.toString());
                                                                }
                                                      }
                                                      role="checkbox"
                                                      tabIndex={-1}
                                                      key={row.id}
                                                      isClickable={!!onRowClick}
                                                  >
                                                      {isMultipleSelectionAvailable && (
                                                          <StyledTableCell style={tableCellStyle} padding="checkbox">
                                                              <StyledCheckbox
                                                                  color="primary"
                                                                  checked={!!isItemSelected}
                                                                  inputProps={{
                                                                      'aria-label': `Checkbox for row ${rowIndex}`,
                                                                  }}
                                                              />
                                                          </StyledTableCell>
                                                      )}
                                                      {propertyKeys.map((propertyKey, index) => {
                                                          const headCell: ITableHeadCell<ITableHeaderCell> | undefined =
                                                              // @ts-ignore
                                                              headCells.find((headCell) => headCell.id === propertyKey);
                                                          if (headCell && headCell.customRender) {
                                                              return (
                                                                  <TableCell
                                                                      style={tableCellStyle}
                                                                      key={`${row.id}-${index}`}
                                                                      align={
                                                                          headCell?.align || headCell?.numeric
                                                                              ? headCell.align || 'center'
                                                                              : 'left'
                                                                      }
                                                                  >
                                                                      {/* @ts-ignore */}
                                                                      {headCell.customRender(row[propertyKey], row)}
                                                                  </TableCell>
                                                              );
                                                          }

                                                          if (propertyKey === 'image') {
                                                              const image = images?.find((image) =>
                                                                  imageColumnName
                                                                      ? //@ts-ignore
                                                                        row[imageColumnName] === image.id
                                                                      : row.id === image.id
                                                              );
                                                              return (
                                                                  <StyledTableCell
                                                                      padding="none"
                                                                      align="center"
                                                                      style={{
                                                                          padding: headCell?.disablePadding
                                                                              ? '0'
                                                                              : 'auto',
                                                                          paddingTop: 15,
                                                                          paddingRight: 10,
                                                                          ...tableCellStyle,
                                                                      }}
                                                                      key={`${row.id}-${index}`}
                                                                  >
                                                                      {image && image.data ? (
                                                                          <TableColumnImg
                                                                              src={`data:${image?.contentType};base64,${image?.data}`}
                                                                              alt={`row ${rowIndex}`}
                                                                              onError={fetchImageErrorCallback}
                                                                              onLoad={loadImageGraduallyHandler}
                                                                          />
                                                                      ) : (
                                                                          <UserPlaceholderIcon
                                                                              width="32px"
                                                                              height="32px"
                                                                          />
                                                                      )}
                                                                  </StyledTableCell>
                                                              );
                                                          }

                                                          return (
                                                              <StyledTableCell
                                                                  key={`${row.id} - ${index}`}
                                                                  align={
                                                                      headCell?.align
                                                                          ? headCell.align
                                                                          : headCell?.numeric
                                                                          ? 'left'
                                                                          : 'left'
                                                                  }
                                                                  style={{
                                                                      paddingRight: '36px',
                                                                      paddingLeft: headCell?.numeric
                                                                          ? '32px'
                                                                          : undefined,
                                                                      ...tableCellStyle,
                                                                  }}
                                                              >
                                                                  {/* @ts-ignore */}
                                                                  {row[propertyKey]}
                                                              </StyledTableCell>
                                                          );
                                                      })}
                                                  </StyledTableRow>
                                              );
                                          })}
                            </TableBody>
                            {isPaginationVisible && (
                                <TableFooter>
                                    <TableRow>
                                        <StyledTablePagination
                                            rowsPerPageOptions={[10, 25, 100, 250, 1000]}
                                            count={data?.length || 0}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                        />
                                    </TableRow>
                                </TableFooter>
                            )}
                        </>
                    )}
                </StyledTable>
            </>
        </StyledTableContainer>
    );
}

export default Table;
