import { FC } from 'react';

const NuliaSmallIcon: FC = () => {
    return (
        <svg width="28" height="16" viewBox="0 0 28 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.62358 15.5556H11.5632V9.29867L15.5892 13.2849L15.6381 13.3185C17.3333 14.7987 18.9795 15.5219 20.691 15.5219C24.6028 15.5219 27.7812 12.2421 27.7812 8.2054C27.7812 4.1687 24.6028 0.888885 20.691 0.888885H19.0773V2.87359H20.691C23.5271 2.87359 25.8416 5.26197 25.8416 8.18858C25.8416 11.1152 23.5271 13.5036 20.691 13.5036C19.4685 13.5036 18.2297 12.9485 16.9095 11.8048L9.62358 4.58919V15.5556ZM7.30904 15.5387H8.59671V13.554H7.30904C4.47293 13.554 2.15839 11.1656 2.15839 8.23904C2.15839 5.31243 4.47293 2.92405 7.30904 2.90723C7.47204 2.90723 7.65133 2.92405 7.81433 2.94087C8.7271 3.04179 9.60728 3.37818 10.3571 3.95005L11.0253 4.55555L18.1319 11.317V0.905705H16.176V6.7421L12.3456 3.07543L11.6284 2.41947L11.5632 2.36901C10.5201 1.56167 9.29759 1.0739 8.00992 0.939344C7.99458 0.938289 7.97931 0.937233 7.96409 0.936182C7.73679 0.92048 7.52292 0.905705 7.30904 0.905705C3.39716 0.905705 0.21875 4.18552 0.21875 8.22222C0.21875 12.2589 3.39716 15.5387 7.30904 15.5387Z"
                fill="#654EA3"
            />
            <mask id="mask0_3556_23410" maskUnits="userSpaceOnUse" x="0" y="0" width="28" height="16">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.62358 15.5556H11.5632V9.29867L15.5892 13.2849L15.6381 13.3185C17.3333 14.7987 18.9795 15.5219 20.691 15.5219C24.6028 15.5219 27.7812 12.2421 27.7812 8.2054C27.7812 4.1687 24.6028 0.888885 20.691 0.888885H19.0773V2.87359H20.691C23.5271 2.87359 25.8416 5.26197 25.8416 8.18858C25.8416 11.1152 23.5271 13.5036 20.691 13.5036C19.4685 13.5036 18.2297 12.9485 16.9095 11.8048L9.62358 4.58919V15.5556ZM7.30904 15.5387H8.59671V13.554H7.30904C4.47293 13.554 2.15839 11.1656 2.15839 8.23904C2.15839 5.31243 4.47293 2.92405 7.30904 2.90723C7.47204 2.90723 7.65133 2.92405 7.81433 2.94087C8.7271 3.04179 9.60728 3.37818 10.3571 3.95005L11.0253 4.55555L18.1319 11.317V0.905705H16.176V6.7421L12.3456 3.07543L11.6284 2.41947L11.5632 2.36901C10.5201 1.56167 9.29759 1.0739 8.00992 0.939344C7.99458 0.938289 7.97931 0.937233 7.96409 0.936182C7.73679 0.92048 7.52292 0.905705 7.30904 0.905705C3.39716 0.905705 0.21875 4.18552 0.21875 8.22222C0.21875 12.2589 3.39716 15.5387 7.30904 15.5387Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0_3556_23410)"></g>
        </svg>
    );
};

export default NuliaSmallIcon;
