import { styled } from '@mui/system';

export const RootBox = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
});

export const RootInnerBox = styled('div', { shouldForwardProp: (prop) => prop !== 'isRankingPresent' })<{
    isRankingPresent: boolean;
}>(({ theme, isRankingPresent }) => ({
    display: 'grid',
    gridTemplateColumns: isRankingPresent ? 'minmax(0, 2fr) 1fr' : 'auto',
    gridTemplateRows: 'auto 1fr',
    gap: '16px',
    width: '1080px',
    paddingLeft: '20px',
    paddingRight: '20px',
    [theme.breakpoints.down('lg')]: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        margin: '0 20px',
    },
}));

export const AchievementWrapper = styled('div', {
    shouldForwardProp: (prop) => prop !== 'isFullWidth',
})<{ isFullWidth: boolean }>(({ isFullWidth }) => ({
    gridColumn: isFullWidth ? 'auto' : '1/3',
}));
