import React, { useState } from 'react';

interface IUsePopup {
    isDrawerOpen: boolean;
    setDrawerOpen: (isDrawerOpen: boolean) => void;
    toggleFilterDrawer: (event: React.KeyboardEvent | React.MouseEvent) => void;
}

const useFilterDrawer: () => IUsePopup = () => {
    const [isDrawerOpen, setDrawerOpen] = useState<boolean>(false);

    const toggleFilterDrawer = (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event &&
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }

        setDrawerOpen((drawerOpen) => !drawerOpen);
    };

    return {
        isDrawerOpen,
        setDrawerOpen,
        toggleFilterDrawer,
    };
};

export default useFilterDrawer;
