import styled from '../../../../../ui/theme/styled';

export const BadgeStateWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    width: '100%',
});

export const StateBox = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    width: '100%',
    [theme.breakpoints.down('lg')]: {
        justifyContent: 'flex-start',
    },
}));

export const ClaimBox = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('lg')]: {
        justifyContent: 'flex-start',
    },
}));

export const ImageBox = styled('div')({
    paddingRight: 12,
    minHeight: 100,
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
});

export const BadgeImg = styled('img')({
    maxWidth: 140,
});

export const DownloadBadgeBox = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    '&:hover svg path': {
        fill: `${theme.palette.primary.main} !important`,
    },
}));

export const ShareBadgeBox = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    '&:hover svg path': {
        fill: `${theme.palette.primary.main} !important`,
    },
}));

export const BadgeActionsBox = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    position: 'absolute',
    left: '6px',
    bottom: 0,
    width: 'calc(100% - 26px)',
});
