import { ChangeEvent, FC, PropsWithChildren, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { OutcomeNameTypography, RootBox } from './Style';
import GraphCard from '../../../components/graph/graph-card/GraphCard';
import Table from '../../../../../ui/general/table/Table';
import { EGraphCardSelect } from '../../../../../interfaces/enums/EGraphCardSelect';
import { ITableHeadCell } from '../../../../../interfaces/ITableHeaderCell';
import {
    EOutcomesInnerPageState,
    useInsightsOrganizationOutcomesStateValue,
} from '../../../../../contexts/InsightsOrganizationOutcomesContext';
import { createUrl } from '../../../../../utils/createUrl';
import { useCrumbsStateValue } from '../../../../../contexts/CrumbsContext';
import { makeInsightsOutcomeDetailsRoute } from '../../../../../pages/routes';
import ClipboardCheckIcon from '../../../../../assets/icons/ClipboardCheck';
import {
    SearchInputWrapper,
    StyledSearchInput,
    TableItemCountBox,
    TableItemCountTypography,
} from '../../../../../pages/insights/Style';
import useInsightsTimelineOption from '../../../../../hooks/useInsightsTimelineOption';
import { useInsightsStateValue } from '../../../../../contexts/InsightsContext';
import { EInsightsMode } from '../../../../../interfaces/enums/EInsightsMode';
import { IInsightsOrganizationOutcomeVM } from '../../../../../interfaces/views/IInsightsOrganizationOutcomeVM';
import fetchImageErrorCallback from '../../../../../utils/fetchImageErrorCallback';
import loadImageGraduallyHandler from '../../../../../utils/loadImageGradually';

interface ITableHeader {
    outcomeImage: string;
    outcomeName: string;
    important: boolean;
    assigned: string;
    userAchieved: string;
    inProgress: string;
    attained: string;
    needAttention: string;
}

const OrganizationalOutcomesTab: FC<PropsWithChildren> = () => {
    const { t } = useTranslation();
    const {
        scoreLines,
        latestDateData,
        changeScoreLinesInterval,
        setOutcomesInnerPage,
        isOutcomeDataLoading,
        searchText,
        setSearchText,
    } = useInsightsOrganizationOutcomesStateValue();
    const { activeTimelineOption, handleTimelineChange } = useInsightsTimelineOption({
        changedTimelineOptionCallback: changeScoreLinesInterval,
        initialTimelineOptionValue: EGraphCardSelect.MONTH_4,
    });
    const { appendCrumb } = useCrumbsStateValue();
    const navigate = useNavigate();
    const { changeMode, setSelectedOutcomeName } = useInsightsStateValue();

    const headCells: ITableHeadCell<ITableHeader>[] = [
        {
            id: 'outcomeImage',
            label: '',
            disableSort: true,
            customRender: (value) => {
                return (
                    <img
                        src={createUrl(value)?.generatedUrl}
                        width="84px"
                        height="84px"
                        onError={fetchImageErrorCallback}
                        onLoad={loadImageGraduallyHandler}
                    />
                );
            },
        },
        {
            id: 'outcomeName',
            label: 'Outcome Name',
            minWidth: '130px',
            customRender: (value: string) => {
                return <OutcomeNameTypography variant="subtitle2">{value}</OutcomeNameTypography>;
            },
        },
        {
            id: 'important',
            label: <ClipboardCheckIcon />,
            align: 'center',
            customRender: (value: boolean) => {
                if (value) return <ClipboardCheckIcon />;
                return <></>;
            },
        },
        {
            id: 'assigned',
            label: 'Assigned',
            disablePadding: true,
            align: 'center',
        },
        {
            id: 'userAchieved',
            label: 'User Achieved',
            disablePadding: true,
            align: 'center',
        },
        {
            id: 'inProgress',
            label: 'In Progress',
            disablePadding: true,
            align: 'center',
        },
        {
            id: 'attained',
            label: 'Attained',
            disablePadding: true,
            align: 'center',
        },
        {
            id: 'needAttention',
            label: 'Need Attention',
            disablePadding: true,
            align: 'center',
        },
    ];

    const customToolbar = useMemo(() => {
        return (
            <TableItemCountBox>
                <TableItemCountTypography variant="caption">{`${latestDateData.length} Outcomes`}</TableItemCountTypography>
            </TableItemCountBox>
        );
    }, [latestDateData]);

    const scoreLinesValuesMemo = useMemo(() => {
        if (isOutcomeDataLoading || !scoreLines) return undefined;
        if (activeTimelineOption === EGraphCardSelect.MONTH_4) {
            return scoreLines?.map((scoreLine) => {
                return {
                    ...scoreLine,
                    scores: scoreLine.scores.slice(-15),
                };
            });
        } else if (activeTimelineOption === EGraphCardSelect.YEAR_WITH_WEEKS) {
            return scoreLines;
        }
        return [];
    }, [scoreLines, activeTimelineOption, isOutcomeDataLoading]);

    const xAxisMemo = useMemo(() => {
        if (isOutcomeDataLoading || !scoreLines) return undefined;
        if (scoreLines?.length > 0) {
            if (activeTimelineOption === EGraphCardSelect.MONTH_4) {
                return scoreLines[0].scores.slice(-15).map((scoreItem) => format(scoreItem.date as Date, 'MM/d/yy'));
            } else if (activeTimelineOption === EGraphCardSelect.YEAR_WITH_WEEKS) {
                return scoreLines[0].scores.map((scoreItem) => format(scoreItem.date as Date, 'MM/d/yy'));
            }
        }
        return [];
    }, [scoreLines, activeTimelineOption, isOutcomeDataLoading]);

    const handleTableRowClick = (id: string, obj: IInsightsOrganizationOutcomeVM) => {
        setOutcomesInnerPage(EOutcomesInnerPageState.DETAILS);
        const outcomeDetailsRoute = makeInsightsOutcomeDetailsRoute(obj.outcomeId);
        changeMode(EInsightsMode.ORG_PERSONAL_OUTCOME_DETAILS);
        setSelectedOutcomeName(obj.outcomeName);
        appendCrumb({
            name: 'Outcomes Details',
            pathname: outcomeDetailsRoute,
            callback: () => {
                changeMode(EInsightsMode.STANDARD);
            },
        });
        navigate(outcomeDetailsRoute);
    };

    return (
        <RootBox>
            <GraphCard
                scoreLines={scoreLinesValuesMemo}
                xAxis={xAxisMemo}
                handleTimelineChange={handleTimelineChange}
                translations={{ graphCardTitle: t('insights.organization.outcomes.graphCardTitle') }}
                isDataEmpty={scoreLinesValuesMemo && scoreLinesValuesMemo.length === 0}
                isLoading={isOutcomeDataLoading || scoreLinesValuesMemo === undefined}
            />
            <SearchInputWrapper className="search-box">
                <StyledSearchInput
                    placeholder="Search Outcomes"
                    value={searchText || ''}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        if (setSearchText) setSearchText(e.target.value);
                    }}
                />
            </SearchInputWrapper>
            <Table<IInsightsOrganizationOutcomeVM, ITableHeader>
                headCells={headCells}
                data={latestDateData}
                propertyKeys={headCells.map((headCell) => {
                    return headCell.id;
                })}
                tableTitlePlural=""
                onRowClick={handleTableRowClick}
                isFilterControlVisible={false}
                isFilterDrawerOpen={false}
                isLoading={isOutcomeDataLoading}
                customToolbar={customToolbar}
                initialOrderBy="outcomeName"
                customMediaColumnName="outcomeImage"
                customMediaSecondaryColumns={['important']}
            />
        </RootBox>
    );
};

export default OrganizationalOutcomesTab;
