import type { DefaultLayoutIcons } from '@vidstack/react/player/layouts/default';
import Play from '../../../assets/icons/Play';
import { defaultLayoutIcons } from '@vidstack/react/player/layouts/default';

const vidstackIcons: DefaultLayoutIcons = {
    ...defaultLayoutIcons,
    PlayButton: {
        Play: Play,
        Pause: defaultLayoutIcons.PlayButton.Pause,
        Replay: defaultLayoutIcons.PlayButton.Replay,
    },
};

export default vidstackIcons;
