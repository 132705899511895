import { FC } from 'react';

const AssessmentBenchmarkAdoptionLegendIcon: FC = () => {
    return (
        <svg width="10" height="4" viewBox="0 0 10 4" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line y1="2" x2="10" y2="2" stroke="#616063" strokeWidth="2" />
            <line y1="3.5" x2="10" y2="3.5" stroke="#EAE8F0" />
            <line y1="0.5" x2="10" y2="0.5" stroke="#EAE8F0" />
        </svg>
    );
};

export default AssessmentBenchmarkAdoptionLegendIcon;
