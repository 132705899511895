import styled from '../../theme/styled';

export const CircleWrapper = styled('div')({
    width: 70,
    height: 80,
    borderRadius: '51%',
    position: 'relative',
});

export const Dot = styled('div')<{
    orderNumber: number;
    offsetDeg: number;
    dotFilledColor: string;
}>(({ orderNumber, offsetDeg, theme, dotFilledColor }) => ({
    background: dotFilledColor,
    width: '10px',
    height: '10px',
    borderRadius: '5px',
    transform: `rotate(${
        orderNumber % 2 ? Math.round(orderNumber / 2) * 20 - offsetDeg : -Math.round(orderNumber / 2) * 20 - offsetDeg
    }deg) translateY(45px) rotate(${-orderNumber * 45}deg)`,
    position: 'absolute',
    left: 30,
    top: 30,
}));

export const MasterSpan = styled('span')({
    fontFamily: 'Ubuntu',
    fontWeight: 500,
    fontSize: '10px',
    textTransform: 'uppercase',
    color: '#2C486E',
    letterSpacing: '1.25px',
    paddingLeft: '13px',
    paddingTop: '4px',
    lineHeight: '20px',
});

export const RootBox = styled('div')({
    display: 'flex',
    flexDirection: 'column',
});
