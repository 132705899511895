import styled from '../../theme/styled';

export const RootBox = styled('div')({
    display: 'flex',
    alignItems: 'center',
    height: '100%',
});

export const MainLineBox = styled('div')({
    display: 'flex',
    background: 'rgba(0, 0, 0, 0.16)',
    height: 4,
    borderRadius: 2,
    position: 'relative',
});
