import { FC, useMemo, useRef, useState } from 'react';
import { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { Input, useMediaQuery, useTheme } from '@mui/material';
import { t } from 'i18next';
import { IDropdownMenuItem } from '../../../ui/general/dropdown/Dropdown';
import ActivityFeedItem from './activity-feed-item/ActivityFeedItem';
import {
    RootBox,
    TitleBox,
    TitleTypography,
    ContentBox,
    StyledSelect,
    MenuItemTitleSpan,
    StyledMenuItem,
    StyledFormControl,
} from './Style';
import { IActivityFeedVM } from '../../../interfaces/views/IActivityFeedVM';
import Loading from '../../../ui/general/loading/Loading';
import NoDataCard from '../no-data-card/NoDataCard';
import UpArrowIcon from '../../../assets/icons/UpArrowIcon';
import SomethingWentWrongCard from '../something-went-wrong-card/SomethingWentWrongCard';

interface IProps {
    activityFeedData?: IActivityFeedVM[];
    isLoading: boolean;
    loadMoreHandler: () => void;
    selectedFilterValue: string[];
    setSelectedFilterValue: (newFilterValues: string[]) => void;
    visibleNumberOfItems: number;
    isError?: boolean;
    refetchCallback?: () => void;
}

enum EActivityFilter {
    ALL = 'All',
    SKILLS = 'Skills',
    OUTCOMES = 'Outcomes',
    BADGES = 'Badges',
    ACHIEVEMENTS = 'Achievements',
    // STICKERS = 'Stickers',
    MESSAGES = 'Messages',
    // ENGAGEMENTS_POINTS = 'EngagementsPoints',
}

const activityFilterMenuItems: IDropdownMenuItem[] = [
    {
        value: EActivityFilter.ALL,
        name: 'All',
    },
    {
        value: EActivityFilter.SKILLS,
        name: 'Skills',
    },
    {
        value: EActivityFilter.OUTCOMES,
        name: 'Outcomes',
    },
    {
        value: EActivityFilter.BADGES,
        name: 'Badges',
    },
    {
        value: EActivityFilter.ACHIEVEMENTS,
        name: 'Achievements',
    },
    // {
    //     value: EActivityFilter.STICKERS,
    //     name: 'Stickers',
    // },
    {
        value: EActivityFilter.MESSAGES,
        name: 'Messages',
    },
    // {
    //     value: EActivityFilter.ENGAGEMENTS_POINTS,
    //     name: 'Engagements Points',
    // },
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 210,
        },
    },
};

const ActivityFeed: FC<IProps> = ({
    activityFeedData,
    isLoading,
    loadMoreHandler,
    selectedFilterValue,
    setSelectedFilterValue,
    visibleNumberOfItems,
    isError = false,
    refetchCallback,
}) => {
    const [isPagingLoading, setPagingLoading] = useState<boolean>(false);
    const numberOfItems = useRef<number>(0);
    const theme = useTheme();
    const isLgDown = useMediaQuery(theme.breakpoints.down('lg'));
    const selectRef = useRef();

    const handleChange = (event: SelectChangeEvent<typeof selectedFilterValue>) => {
        const {
            target: { value },
        } = event;
        setTimeout(() => {
            document.body.style.overflow = 'hidden';
            document.body.style.paddingRight = '17px';
        }, 0);
        if (Array.isArray(value)) {
            if (value.includes('All')) {
                if (value.length > 1 && value[value.length - 1] !== 'All')
                    setSelectedFilterValue(value.filter((val) => val !== 'All'));
                else setSelectedFilterValue(['All']);
            } else {
                if (
                    value.length === 0 ||
                    (value.length === activityFilterMenuItems.length - 1 && !value.includes('All'))
                )
                    setSelectedFilterValue(['All']);
                else setSelectedFilterValue(value);
            }
        } else
            setSelectedFilterValue(
                // On autofill we get a stringified value.
                typeof value === 'string' ? value.split(',') : value
            );
    };

    const contentMemo = useMemo(() => {
        if (isLoading) {
            return (
                <>
                    <ActivityFeedItem skeletonMode />
                    <ActivityFeedItem skeletonMode />
                    <ActivityFeedItem skeletonMode />
                    <ActivityFeedItem skeletonMode />
                </>
            );
        }
        if (activityFeedData && activityFeedData.length > 0) {
            return activityFeedData.map((activityItem, index) => {
                return <ActivityFeedItem key={index} index={index} activity={activityItem}></ActivityFeedItem>;
            });
        }
        if (isError) {
            return (
                <SomethingWentWrongCard
                    boxStyle={{ boxShadow: 'none', height: '100%' }}
                    message="Something went wrong."
                    actionCallback={refetchCallback}
                />
            );
        }
        if (activityFeedData && activityFeedData.length === 0) {
            if (selectedFilterValue.length === 0) return <NoDataCard message="Please choose filter categories" />;
            return <NoDataCard message="There is no activity items that match your filter criteria" />;
        }
        return <></>;
    }, [activityFeedData, isLoading, isError, refetchCallback]);

    return (
        <RootBox>
            <TitleBox>
                <TitleTypography variant={isLgDown ? 'subtitle2' : 'subtitle1'}>
                    {t('activityFeed.title')}
                </TitleTypography>
                <StyledFormControl>
                    <StyledSelect
                        placeholder="Filter"
                        multiple
                        ref={selectRef}
                        value={selectedFilterValue}
                        onChange={handleChange}
                        onOpen={() => {
                            setTimeout(() => {
                                document.body.style.overflow = 'hidden';
                                document.body.style.paddingRight = '17px';
                            }, 50);
                        }}
                        input={<Input disableUnderline />}
                        renderValue={(selected) => {
                            if (selected.length === 1 && selected[0] === 'All') return 'All filters';
                            else if (selected.length >= 1) return 'Active filters';
                            return undefined;
                        }}
                        MenuProps={MenuProps}
                        IconComponent={UpArrowIcon}
                        tabIndex={0}
                    >
                        {activityFilterMenuItems.map((activityFilterMenuItem) => {
                            const isChecked = selectedFilterValue.findIndex(
                                (filterValue) => filterValue === activityFilterMenuItem.name
                            );
                            return (
                                <StyledMenuItem
                                    sx={{ height: 32, paddingLeft: '4px' }}
                                    key={activityFilterMenuItem.name}
                                    value={activityFilterMenuItem.name}
                                >
                                    <Checkbox checked={isChecked !== -1} />
                                    <MenuItemTitleSpan>{activityFilterMenuItem.name}</MenuItemTitleSpan>
                                </StyledMenuItem>
                            );
                        })}
                    </StyledSelect>
                </StyledFormControl>
            </TitleBox>
            <ContentBox
                id="inner-activity-feed"
                onScroll={(d: any) => {
                    const element = d.target;
                    if (element) {
                        if (element.scrollHeight - element.offsetHeight <= element.scrollTop + 5) {
                            if (numberOfItems.current !== activityFeedData?.length) setPagingLoading(true);
                            if (!isPagingLoading)
                                setTimeout(() => {
                                    if (numberOfItems.current !== activityFeedData?.length) {
                                        setPagingLoading(false);
                                        loadMoreHandler();
                                    }
                                    if (activityFeedData) numberOfItems.current = activityFeedData?.length;
                                }, 700);
                        }
                    }
                }}
            >
                {contentMemo}
                {isPagingLoading && activityFeedData && activityFeedData?.length === visibleNumberOfItems && (
                    <div>
                        <Loading boxStyle={{ height: '60px' }} />
                    </div>
                )}
            </ContentBox>
        </RootBox>
    );
};

export default ActivityFeed;
