import { FC } from 'react';

interface IProps {
    width?: string;
    height?: string;
}

const EngagementPointsIcon: FC<IProps> = ({ width, height }) => {
    return (
        <svg
            width={width || '24'}
            height={height || '24'}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                opacity="0.4"
                d="M12 4C7.59 4 4 7.59 4 12C4 16.41 7.59 20 12 20C16.41 20 20 16.41 20 12C20 7.59 16.41 4 12 4Z"
                fill="#FFCC55"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12ZM4 12C4 16.41 7.59 20 12 20C16.41 20 20 16.41 20 12C20 7.59 16.41 4 12 4C7.59 4 4 7.59 4 12Z"
                fill="#FFCC55"
            />
            <path
                d="M11.5191 10.6159L12.0018 9.41911L12.4814 10.6035L12.7103 11.169L13.3179 11.2242L14.6492 11.345L13.6 12.3019L13.1734 12.6911L13.2971 13.2551L13.597 14.6215L12.5352 13.9488L12 13.6096L11.4648 13.9488L10.4052 14.6201L10.7027 13.2694L10.827 12.705L10.4 12.3155L9.35085 11.3586L10.6821 11.2378L11.2906 11.1825L11.5191 10.6159Z"
                stroke="#FFCC55"
                strokeWidth="2"
            />
        </svg>
    );
};

export default EngagementPointsIcon;
