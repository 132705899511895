import { FC } from 'react';

const LeftArrowIcon: FC = (props) => {
    return (
        <svg {...props} width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.49749 7L8.91948 1.70711C9.31953 1.31658 9.31953 0.683418 8.91948 0.292893C8.51943 -0.0976311 7.87083 -0.0976311 7.47078 0.292893L0.300037 7.29289C-0.100012 7.68342 -0.100012 8.31658 0.300037 8.70711L7.47078 15.7071C7.87083 16.0976 8.51943 16.0976 8.91948 15.7071C9.31953 15.3166 9.31953 14.6834 8.91948 14.2929L3.49749 9L15.3659 9C15.9316 9 16.3903 8.55229 16.3903 8C16.3903 7.44772 15.9316 7 15.3659 7L3.49749 7Z"
                fill="#8C8C8C"
            />
        </svg>
    );
};

export default LeftArrowIcon;
