import { EUserRoleLogical } from '../interfaces/enums/EUserRoleLogical';

export const convertLogicalRolesToStringValues: (roles: EUserRoleLogical[]) => string[] = (roles) => {
    const userRoles: string[] = [];

    if (roles.includes(EUserRoleLogical.USER)) userRoles.push('User');
    if (roles.includes(EUserRoleLogical.MANAGER)) userRoles.push('Manager');
    if (roles.includes(EUserRoleLogical.ADMIN_REPORTS)) userRoles.push('Report Admin');
    if (roles.includes(EUserRoleLogical.ADMIN_LICENSES)) userRoles.push('License Admin');
    if (roles.includes(EUserRoleLogical.ADMIN_GLOBAL)) userRoles.push('Global Admin');

    return userRoles;
};
