import useModal from './useModal';

interface IUseConfirmModalProps {
    defaultOpen: boolean;
}

interface IUseConfirmModalResult {
    isOpen: boolean;
    close: () => void;
    open: () => void;
    setOpen: (isOpen: boolean) => void;
}

const useConfirmModal: (props: IUseConfirmModalProps) => IUseConfirmModalResult = ({ defaultOpen }) => {
    const { close, open, isOpen, setOpen } = useModal({ defaultOpen });

    return {
        isOpen,
        setOpen,
        open,
        close,
    };
};

export default useConfirmModal;
