import { FC, useMemo } from 'react';
import {
    RootBox,
    SkillSnapshotWrapper,
    MessageOfTheDayWrapper,
    ContentBox,
    RecommendationWrapper,
    ActivityFeedWrapper,
    // HighlightsWrapper,
} from './Style';
import { useHomeStateValue } from '../../contexts/HomeContext';
import SkillSnapshotCard from '../../ui/cards/snapshot-card/SnapshotCard';
import MotdCard from '../../ui/cards/motd-card/MotdCard';
import { useUserAuthStateValue } from '../../contexts/UserAuthContext';
import RecommendationCard from '../../ui/cards/recommendation-card/RecommendationCard';
import ActivityFeed from '../../ui/cards/activity-feed/ActivityFeed';
import { routes } from '../../pages/routes';
import { EOutcomesStatuses } from '../../interfaces/enums/EOutcomesStatuses';
import { ESkillsStatuses } from '../../interfaces/ESkillsStatuses';
import usePageName from '../../hooks/usePageName';
// import HighlightsCard from '../../ui/cards/highlights-card/HighlightsCard';
// import { useAppInsightsContext, useTrackEvent } from '@microsoft/applicationinsights-react-js';

const Home: FC = () => {
    const {
        skillSnapshotItems,
        activityFeedData,
        recommendationItems,
        motdItems,
        outcomeSnapshotItems,
        assignedSkillCount,
        assignedOutcomeCount,
        isRecommendationsLoading,
        isActivityFeedLoading,
        loadMoreActivitiesHandler,
        selectedFilterValue,
        setSelectedFilterValue,
        activitiesVisibleFromTo,
        isMotdLoading,
        successRecommendationCallback,
        isUserOutcomesSnapshotDataLoading,
        isUserSkillsSnapshotDataLoading,
        // highlightsItems,
        // isHighlightsLoading,
        isErrorFetchingActivityFeed,
        refetchActivityFeed,
        isErrorFetchingMotw,
        refetchMotw,
    } = useHomeStateValue();
    const { currentUserData } = useUserAuthStateValue();

    usePageName({ pageName: 'Home' });
    // const appInsights = useAppInsightsContext();
    // const metricData = {
    //     average: 200,
    //     name: 'React Component Engaged Time (seconds)',
    //     sampleCount: 1,
    // };
    // const additionalProperties = { 'Component Name': 'MyComponent' };
    // const trackCartUpdate = useTrackEvent<{ cartCount: number }>(appInsights, 'Cart Updated', { cartCount: 44 });

    // useEffect(() => {
    //     setTimeout(() => {
    //         appInsights.getAppInsights().trackEvent(trackCartUpdate, {});
    //         trackCartUpdate({ cartCount: 55 });
    //     }, 2000);
    // }, []);

    const snapshotItemsDescriptionMemo = useMemo(() => {
        return [
            !isUserSkillsSnapshotDataLoading && assignedSkillCount !== undefined
                ? `${assignedSkillCount} assigned`
                : ``,
            !isUserOutcomesSnapshotDataLoading && assignedOutcomeCount !== undefined
                ? `${assignedOutcomeCount} assigned`
                : ``,
        ];
    }, [assignedSkillCount, assignedOutcomeCount, isUserSkillsSnapshotDataLoading, isUserOutcomesSnapshotDataLoading]);

    return (
        <RootBox>
            <ContentBox>
                <MessageOfTheDayWrapper id="messages-of-the-week-box">
                    <MotdCard
                        name={currentUserData?.firstName}
                        motdItems={motdItems}
                        isLoading={isMotdLoading}
                        isError={isErrorFetchingMotw}
                        refetchCallback={refetchMotw}
                    />
                </MessageOfTheDayWrapper>
                <SkillSnapshotWrapper id="snapshot-skills-outcomes">
                    <SkillSnapshotCard
                        snapshotItemsDescription={snapshotItemsDescriptionMemo}
                        snapshotItemDescriptionUrl={[
                            {
                                pathname: routes.SKILLS,
                                search: `?filter=${ESkillsStatuses.ASSIGNED_SKILLS}`,
                            },
                            {
                                pathname: routes.USER_OUTCOMES,
                                search: `?filter=${EOutcomesStatuses.ASSIGNED_OUTCOMES}`,
                            },
                        ]}
                        snapshotItemsTitle={['Snapshot of Your Skills', 'Snapshot of Your Outcomes']}
                        snapshotItems={[skillSnapshotItems, outcomeSnapshotItems]}
                    ></SkillSnapshotCard>
                </SkillSnapshotWrapper>
                <RecommendationWrapper id="week-goals">
                    <RecommendationCard
                        cardItems={recommendationItems}
                        isLoading={isRecommendationsLoading}
                        successRecommendationCallback={successRecommendationCallback}
                    />
                </RecommendationWrapper>
                {/* <HighlightsWrapper id="highlights">
                    <HighlightsCard cardItems={highlightsItems} isLoading={isHighlightsLoading} />
                </HighlightsWrapper> */}
                <ActivityFeedWrapper id="activity-feed">
                    <ActivityFeed
                        visibleNumberOfItems={activitiesVisibleFromTo.to}
                        activityFeedData={activityFeedData}
                        isLoading={isActivityFeedLoading}
                        loadMoreHandler={loadMoreActivitiesHandler}
                        selectedFilterValue={selectedFilterValue}
                        setSelectedFilterValue={setSelectedFilterValue}
                        isError={isErrorFetchingActivityFeed}
                        refetchCallback={refetchActivityFeed}
                    />
                </ActivityFeedWrapper>
            </ContentBox>
        </RootBox>
    );
};

export default Home;
