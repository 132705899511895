import { FC, PropsWithChildren } from 'react';
import TextField from '@mui/material/TextField';

interface IProps {
    label: string;
    value: string;
    onChange: any;
    field: any;
    disabled?: boolean;
}

const NTextInput: FC<PropsWithChildren<IProps>> = ({ label, value, onChange, field, disabled = false }) => {
    return (
        <TextField
            {...field}
            disabled={disabled}
            label={label}
            variant="standard"
            onChange={onChange}
            value={value || ''}
        />
    );
};

export default NTextInput;
