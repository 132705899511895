import { FormControlLabel, Radio } from '@material-ui/core';
import FormControl from '@mui/material/FormControl';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import styled from '../../theme/styled';
import Checkbox from '@mui/material/Checkbox';
import { Typography } from '@mui/material';

export const RootBox = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
});

export const TitleBox = styled('div', {
    shouldForwardProp: (prop) => prop !== 'isExpanded',
})<{ isExpanded: boolean }>(({ isExpanded }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontFamily: 'Ubuntu',
    fontWeight: 500,
    fontSize: 16,
    paddingTop: 7,
    width: '100%',
    ':last-of-type': {
        borderBottom: isExpanded ? 'inherit' : '1px solid rgba(0, 0, 0, 0.08)',
        paddingBottom: 7,
    },
}));

export const ValueBox = styled(Typography)({
    display: 'flex',
    alignItems: 'center',
});

export const ValueNameTypography = styled(Typography)({
    marginLeft: 10,
    paddingBottom: 2,
});

export const ValuesWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    transitionDuration: '1000ms',
    transitionTimingFunction: 'ease-in',
    width: '100%',

    '&:last-child': {
        paddingBottom: 10,
        borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
    },
});

export const OrderSpan = styled('span')(({ theme }) => ({
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    borderRadius: 12,
    '&:hover': {
        backgroundColor: theme.palette.common.white,
    },
}));

export const FilterCategoryTitleSpan = styled('span')({
    cursor: 'pointer',
    width: '100%',
});

export const StyledFormControl = styled(FormControl)({
    paddingLeft: '12px',
    width: '100%',
});

export const OrderSpanWrapper = styled('div')({
    padding: 4,
});

export const StyledAccordion = styled(Accordion)(() => ({
    backgroundColor: '#F5F5F5',
    border: 'none',
    boxShadow: 'none',
    borderRadius: 0,
}));

export const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    backgroundColor: '#F5F5F5',
    padding: 0,
    '&>.MuiAccordionSummary-content.Mui-expanded': {
        margin: 0,
    },
    '&>.MuiAccordionSummary-content': {
        margin: 0,
    },
    '&.MuiButtonBase-root': {
        minHeight: '0 !important',
    },
}));

export const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
    padding: 0,
    maxHeight: 300,
    overflowY: 'auto',
}));

export const StyledCheckbox = styled(Checkbox)({
    '& > svg': {
        width: '20px',
        height: '20px',
    },
});

export const StyledRadio = styled(Radio)({});

export const StyledFormControlLabel = styled(FormControlLabel)({
    '& .MuiFormControlLabel-label': {
        marginLeft: 10,
        fontSize: '14px',
        lineHeight: '21px',
        fontFamily: 'Open Sans',
        letterSpacing: '0.25px',
    },
});
