import { Typography } from '@mui/material';
import styled from '../../ui/theme/styled';

export const RootBox = styled('div', {
    shouldForwardProp: (prop) => prop !== 'applyPadding' && prop !== 'isOpen',
})<{ applyPadding: boolean; isOpen: boolean }>(({ theme, applyPadding, isOpen }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    position: 'relative',
    paddingBottom: applyPadding ? '20px' : 'unset',
    [theme.breakpoints.down('lg')]: {
        width: !isOpen ? '100%' : '100%',
    },
}));

export const MessageBox = styled('div')({});

export const MessageTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,
}));

export const DescriptionBox = styled('div')({
    paddingBottom: 12,
});

export const ActionBox = styled('div')({});

export const ContentWrapper = styled('div', {
    shouldForwardProp: (prop) => prop !== 'isOpen',
})<{ isOpen: boolean }>(({ isOpen }) => ({
    marginLeft: isOpen ? '110px' : '0px',
    width: isOpen ? 'calc(100% - 110px)' : '100%',
    height: 'calc(70vh - 40px)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '32px',
}));
