import { FC } from 'react';

interface IProps {
    width?: string;
    height?: string;
    color?: string;
}

const ShareBadgeIcon: FC<IProps> = ({ width, height, color }) => {
    return (
        <svg
            width={width || 18}
            height={height || 22}
            viewBox="0 0 18 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.70711 5.70711L8 3.41421V14C8 14.5523 8.44771 15 9 15C9.55229 15 10 14.5523 10 14V3.41421L12.2929 5.70711C12.6834 6.09763 13.3166 6.09763 13.7071 5.70711C14.0976 5.31658 14.0976 4.68342 13.7071 4.29289L9.70711 0.292893C9.31658 -0.0976311 8.68342 -0.0976311 8.29289 0.292893L4.29289 4.29289C3.90237 4.68342 3.90237 5.31658 4.29289 5.70711C4.68342 6.09763 5.31658 6.09763 5.70711 5.70711ZM1 10C0.447715 10 0 10.4477 0 11V19C0 20.6569 1.34315 22 3 22H15C16.6569 22 18 20.6569 18 19V11C18 10.4477 17.5523 10 17 10C16.4477 10 16 10.4477 16 11V19C16 19.5523 15.5523 20 15 20H3C2.44772 20 2 19.5523 2 19V11C2 10.4477 1.55228 10 1 10Z"
                fill={color || '#8C8C8C'}
            />
        </svg>
    );
};

export default ShareBadgeIcon;
