import { FC, useState, useEffect, useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
    DropdownBox,
    Header,
    IframeWrapper,
    RootBox,
    RootInnerBox,
    RootPaper,
    StyledHTMLIframe,
    TitleTypography
} from './Style';
import { useHowToPlaylistStateValue } from '../../../contexts/HowToPlaylistContext';
import Dropdown, { IDropdownMenuItem } from '../../../ui/general/dropdown/Dropdown';
import useDropdown from '../../../hooks/useDropdown';
import PrimaryButton from '../../../ui/buttons/primary-button/PrimaryButton';
import { makeHowToPlaylistRoute } from '../../../pages/routes';
import { EHowToPlaylistType } from '../../../interfaces/enums/EHowToPlaylistType';
import VideoPlayer from '../../../ui/video/VideoPlayer';
import PdfViewer from '../../../ui/pdf/PdfViewer';
import Loading from '../../../ui/general/loading/Loading';
import SomethingWentWrongCard from '../../../ui/cards/something-went-wrong-card/SomethingWentWrongCard';

const Playlist: FC = () => {
    const [playlistDropdownItems, setPlaylistDropdownItems] = useState<IDropdownMenuItem[]>([]);
    const [selectedPlaylistIndex, setSelectedPlaylistIndex] = useState<number>(-1);
    const {
        handleFilterDropdownChange: handlePlaylistDropdownChange,
        value: selectedPlaylistFilterValue,
        changeFilterDropdownValue: changePlaylistDropdownValue
    } = useDropdown({});
    const { howToPlaylist, refetchHowToPlaylist, isFetchPlaylistLoading } = useHowToPlaylistStateValue();
    const navigate = useNavigate();
    const params = useParams();
    const { t } = useTranslation();

    useEffect(() => {
        return () => {
            setSelectedPlaylistIndex(-1);
        };
    }, []);

    useEffect(() => {
        if (howToPlaylist) {
            const playlistItemIndex = howToPlaylist.findIndex(
                (playlist) => playlist.id === selectedPlaylistFilterValue
            );
            if (playlistItemIndex !== -1) setSelectedPlaylistIndex(playlistItemIndex);
        }
    }, [selectedPlaylistFilterValue]);

    const onPreviousPlaylistClick = useCallback(() => {
        if (howToPlaylist && selectedPlaylistIndex > 0) {
            changePlaylistDropdownValue(howToPlaylist[selectedPlaylistIndex - 1].id);
            if (params && params.categoryId)
                navigate(
                    makeHowToPlaylistRoute(params.categoryId, howToPlaylist[selectedPlaylistIndex - 1].playlistId)
                );
            setSelectedPlaylistIndex(selectedPlaylistIndex - 1);
        }
    }, [howToPlaylist, selectedPlaylistIndex, params, playlistDropdownItems]);

    const onNextPlaylistClick = useCallback(() => {
        if (howToPlaylist && selectedPlaylistIndex < playlistDropdownItems.length - 1) {
            changePlaylistDropdownValue(howToPlaylist[selectedPlaylistIndex + 1].id);
            if (params && params.categoryId) {
                navigate(
                    makeHowToPlaylistRoute(params.categoryId, howToPlaylist[selectedPlaylistIndex + 1].playlistId),
                    { replace: true }
                );
            }
            setSelectedPlaylistIndex(selectedPlaylistIndex + 1);
        }
    }, [howToPlaylist, selectedPlaylistIndex, params, playlistDropdownItems]);

    useEffect(() => {
        if (howToPlaylist && howToPlaylist.length > 0 && params && params.playlistId) {
            setPlaylistDropdownItems(
                howToPlaylist.map((playlist) => {
                    return {
                        name: playlist.title,
                        value: playlist.id
                    };
                })
            );
            const playlistIndex = howToPlaylist.findIndex((playlist) => playlist.playlistId === params.playlistId);
            if (playlistIndex !== -1) {
                changePlaylistDropdownValue(howToPlaylist[0].id);
                setSelectedPlaylistIndex(playlistIndex);
            }
        }
    }, [howToPlaylist]);

    const contentMemo = useMemo(() => {
        if (!howToPlaylist || selectedPlaylistIndex < 0 || isFetchPlaylistLoading) return <Loading />;
        if (howToPlaylist.length === 0) {
            return (
                <SomethingWentWrongCard
                    message={t('common.somethingWentWrong')}
                    boxStyle={{ boxShadow: 'none' }}
                    actionCallback={refetchHowToPlaylist}
                />
            );
        }
        const selectedHowToPlaylistItem = howToPlaylist[selectedPlaylistIndex];
        switch (selectedHowToPlaylistItem?.type) {
            case EHowToPlaylistType.HTML:
                return (
                    <StyledHTMLIframe
                        key={selectedPlaylistIndex}
                        title={selectedHowToPlaylistItem.title}
                        src={selectedHowToPlaylistItem.url}
                        width='100%'
                    />
                );
            case EHowToPlaylistType.PDF:
                return <PdfViewer src={selectedHowToPlaylistItem.url} />;
            case EHowToPlaylistType.MP4:
                return <VideoPlayer src={selectedHowToPlaylistItem.url} key={selectedPlaylistIndex} autoPlay={false} />;
            default:
                return <></>;
        }
    }, [selectedPlaylistIndex, howToPlaylist, isFetchPlaylistLoading]);

    return (
        <RootBox>
            <RootInnerBox>
                <RootPaper elevation={1}>
                    <Header>
                        <PrimaryButton
                            title={t('howTos.playlist.back')}
                            clickHandler={onPreviousPlaylistClick}
                            disabled={
                                selectedPlaylistIndex === 0 || !howToPlaylist || !howToPlaylist[selectedPlaylistIndex]
                            }
                        />
                        <DropdownBox>
                            <Dropdown
                                items={playlistDropdownItems}
                                value={selectedPlaylistFilterValue}
                                handleChange={handlePlaylistDropdownChange}
                                disabled={
                                    !howToPlaylist ||
                                    howToPlaylist.length === 0 ||
                                    !howToPlaylist[selectedPlaylistIndex]
                                }
                                style={{ maxWidth: '350px' }}
                            />
                        </DropdownBox>
                        <PrimaryButton
                            title={t('howTos.playlist.next')}
                            clickHandler={onNextPlaylistClick}
                            disabled={
                                selectedPlaylistIndex === playlistDropdownItems.length - 1 ||
                                !howToPlaylist ||
                                !howToPlaylist[selectedPlaylistIndex]
                            }
                        />
                    </Header>
                    <TitleTypography variant='h5'></TitleTypography>
                    <IframeWrapper>{contentMemo}</IframeWrapper>
                </RootPaper>
            </RootInnerBox>
        </RootBox>
    );
};

export default Playlist;
