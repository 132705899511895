import { Skeleton, Typography } from '@mui/material';
import { FC, KeyboardEvent, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { Switch } from '@mui/material';
import {
    RootBox,
    HeaderTitle,
    ActionBox,
    ContentBox,
    InfoBox,
    InfoTextBox,
    SVGBox,
    MasterBox,
    MasterLabelBox,
    MasterSwitchBox,
    AddControlWrapper,
    // AppIconWrapperBox,
    // AppIconImg,
    FooterBox,
    SkillLink,
    BehaviorTypography,
    AddControlWrapperInfoBox,
    AssignedByTypography,
    AssignedDateTypography,
    CompetenceTitle,
    BehaviorFooter,
} from './Style';
import { createUrl } from '../../../../utils/createUrl';
import { ERecommendationType } from '../../../../interfaces/enums/ERecommendationType';
import { IRecommendationVM } from '../../../../interfaces/views/IRecommendationVM';
import DottedCircle from '../../../../ui/general/dotted-circle/DottedCircle';
import { ESkillStatus } from '../../../../interfaces/enums/ESkillStatus';
import ProgressDoughnutChart from '../../../../ui/general/progress-doughnut-chart/ProgressDoughnutChart';
import { ESkillLevel } from '../../../../interfaces/enums/ESkillLevel';
import { makeOutcomeRoute, makeSkillRoute } from '../../../../pages/routes';
import useRecommendation from '../../../../hooks/useRecommendation';
import { formatDate } from '../../../../utils/dateUtil';
import { useCrumbsStateValue } from '../../../../contexts/CrumbsContext';
import { ERecommendationCompetenceLevel } from '../../../../interfaces/enums/ERecommendationCompetenceLevel';
import PrimaryButton from '../../../../ui/buttons/primary-button/PrimaryButton';
import fetchImageErrorCallback from '../../../../utils/fetchImageErrorCallback';
import { accessibilityEnterKeyCallback } from '../../../../utils/accessibilityUtils';
// import { SKILL_TARGET_MASTER_POINTS, SKILL_TARGET_PRODUCER_POINTS } from '../../../../utils/constants';

interface IProps {
    recommendation?: IRecommendationVM;
    isVisible?: boolean;
    isLoading?: boolean;
    successRecommendationCallback?: () => void;
}

const RecommendationCardItem: FC<IProps> = ({
    recommendation: {
        type,
        competenceTitle,
        competenceSubtitle,
        apps,
        competenceImage,
        competenceMaxScore,
        competenceScore,
        competenceState,
        targetId,
        childTargetId,
        id,
        competenceAssignmentLevel,
        subcompetencesState,
    } = {},
    isVisible,
    isLoading = false,
    successRecommendationCallback,
}) => {
    const {
        recommendationActionCallback,
        headerCardTypeTitle,
        isActionLoading,
        isActionSuccess,
        isAssignOutcomeSuccess,
        isAssignSkillSuccess,
    } = useRecommendation({
        recommendationId: id,
        targetId,
        recommendationType: type,
        successRecommendationCallback,
    });
    const navigate = useNavigate();
    const { appendCrumb } = useCrumbsStateValue();

    const svgContent = useMemo(() => {
        switch (type) {
            case ERecommendationType.ADD_OUTCOME:
            case ERecommendationType.UPGRADE_OUTCOME:
                return (
                    <DottedCircle
                        numberOfDots={subcompetencesState?.length || 0}
                        numberOfAchievedDots={
                            subcompetencesState?.filter(
                                (subcompetence) => subcompetence === ERecommendationCompetenceLevel.MAINTAIN
                            ).length || 0
                        }
                        numberOfInProgressDots={
                            subcompetencesState?.filter(
                                (subcompetence) => subcompetence === ERecommendationCompetenceLevel.IN_PROGRESS
                            ).length || 0
                        }
                        numberOfNeedAttentionDots={
                            subcompetencesState?.filter(
                                (subcompetence) => subcompetence === ERecommendationCompetenceLevel.NEEDS_ATTENTION
                            ).length || 0
                        }
                        isMaster={competenceAssignmentLevel === ESkillLevel.MASTER}
                        isAssigned={competenceAssignmentLevel !== ESkillLevel.NONE}
                    >
                        <img
                            alt="competence"
                            src={createUrl(competenceImage)?.generatedUrl}
                            onError={fetchImageErrorCallback}
                        />
                    </DottedCircle>
                );
            case ERecommendationType.ADD_SKILL:
            case ERecommendationType.UPGRADE_SKILL:
            case ERecommendationType.BEHAVIOR:
                // let competenceExpectedValue = 0;
                // if (competenceAssignmentLevel === ESkillLevel.PRODUCER)
                //     competenceExpectedValue = SKILL_TARGET_PRODUCER_POINTS;
                // if (competenceAssignmentLevel === ESkillLevel.MASTER)
                //     competenceExpectedValue = SKILL_TARGET_MASTER_POINTS;
                let skillStatus: ESkillStatus = ESkillStatus.UNASSIGNED;
                if (competenceState === ERecommendationCompetenceLevel.IN_PROGRESS)
                    skillStatus = ESkillStatus.ASSIGNED_IN_PROGRESS;
                else if (competenceState === ERecommendationCompetenceLevel.MAINTAIN)
                    skillStatus = ESkillStatus.ASSIGNED_ACHIEVED;
                else if (competenceState === ERecommendationCompetenceLevel.NEEDS_ATTENTION)
                    skillStatus = ESkillStatus.ASSIGNED_ACHIEVED_IN_DANGER;
                return (
                    <ProgressDoughnutChart
                        index={0}
                        currentValue={competenceScore || 0}
                        expectedValue={competenceMaxScore}
                        dimension={{
                            width: 80,
                            height: 80,
                            margin: {
                                bottom: 5,
                                left: 10,
                                right: 10,
                                top: 7,
                            },
                            starSize: 32,
                            starX: -8,
                            starY: -10,
                        }}
                        isRatingMode={true}
                        isStarted={true}
                        skillStatus={skillStatus}
                        isAssigned={competenceAssignmentLevel !== ESkillLevel.NONE}
                        noWaitingForAnimation={isVisible ?? false}
                    />
                );
        }
        return <></>;
    }, [type, competenceScore, competenceMaxScore, isVisible]);

    const openSkillAndSelectBehavior = () => {
        if (targetId && childTargetId) {
            const pathname = makeSkillRoute(targetId, { behaviorId: childTargetId });
            appendCrumb({
                name: competenceTitle || 'Skill',
                pathname: pathname,
            });
            navigate(pathname);
        }
    };

    const footerMemo = useMemo(() => {
        switch (type) {
            case ERecommendationType.BEHAVIOR:
                if (targetId && childTargetId) {
                    return (
                        <BehaviorFooter>
                            <PrimaryButton
                                style={{ width: '84px', minWidth: '84px', padding: '8px 2px', fontSize: '12px' }}
                                title="View Skill"
                                clickHandler={openSkillAndSelectBehavior}
                            />
                            <Typography variant="caption">
                                Part of <SkillLink>{competenceTitle}</SkillLink>
                            </Typography>
                        </BehaviorFooter>
                    );
                }
                return <></>;
            case ERecommendationType.ADD_OUTCOME:
            case ERecommendationType.ADD_SKILL:
            case ERecommendationType.UPGRADE_OUTCOME:
            case ERecommendationType.UPGRADE_SKILL:
                return <></>;
            // return (
            //     <AppIconWrapperBox>
            //         {isLoading ? (
            //             <Skeleton width="100px" />
            //         ) : (
            //             apps?.map((app) => {
            //                 return (
            //                     <AppIconImg key={app.name} alt={app.name} src={createUrl(app.icon)?.generatedUrl} />
            //                 );
            //             })
            //         )}
            //     </AppIconWrapperBox>
            // );
            default:
                return null;
        }
    }, [type]);

    const actionControlMemo = useMemo(() => {
        switch (type) {
            case ERecommendationType.UPGRADE_OUTCOME:
            case ERecommendationType.UPGRADE_SKILL:
                return (
                    <MasterBox>
                        <MasterLabelBox>Master</MasterLabelBox>
                        <MasterSwitchBox>
                            <Switch
                                disabled={isActionLoading || isActionSuccess}
                                color="secondary"
                                checked={isActionSuccess}
                                tabIndex={0}
                                onChange={() => {
                                    recommendationActionCallback(true);
                                }}
                                onKeyDown={(e: KeyboardEvent<any>) =>
                                    accessibilityEnterKeyCallback(e, () => recommendationActionCallback(true))
                                }
                                aria-label="Switch to Master Level"
                                inputProps={{
                                    'aria-label': 'Switch to Master Level',
                                }}
                            />
                        </MasterSwitchBox>
                    </MasterBox>
                );
            case ERecommendationType.ADD_OUTCOME:
            case ERecommendationType.ADD_SKILL:
                if (isAssignOutcomeSuccess || isAssignSkillSuccess) {
                    return (
                        <AddControlWrapperInfoBox>
                            <AssignedByTypography variant="label">Assigned by you</AssignedByTypography>
                            <AssignedDateTypography variant="label">{formatDate(new Date())}</AssignedDateTypography>
                        </AddControlWrapperInfoBox>
                    );
                }
                return (
                    <AddControlWrapper>
                        <PrimaryButton
                            title="Assign"
                            disabled={isActionLoading || isActionSuccess}
                            clickHandler={() => {
                                if (!isActionLoading) recommendationActionCallback(true);
                            }}
                        />
                    </AddControlWrapper>
                );
            default:
                return <></>;
        }
    }, [
        type,
        recommendationActionCallback,
        isActionLoading,
        isActionSuccess,
        isAssignOutcomeSuccess,
        isAssignSkillSuccess,
    ]);

    const titleMemo = useMemo(() => {
        if (type === ERecommendationType.BEHAVIOR)
            return competenceSubtitle ? (
                <BehaviorTypography variant="subtitle2">{competenceSubtitle}</BehaviorTypography>
            ) : (
                <Skeleton variant="text" />
            );
        else {
            return competenceTitle ? (
                <CompetenceTitle
                    onClick={() => {
                        if (targetId) {
                            if (type === ERecommendationType.UPGRADE_SKILL || type === ERecommendationType.ADD_SKILL) {
                                const pathname = makeSkillRoute(targetId);
                                appendCrumb({
                                    name: competenceTitle || 'Skill',
                                    pathname: pathname,
                                    isDirectLink: true,
                                });
                                navigate(pathname);
                            } else {
                                const pathname = makeOutcomeRoute(targetId);
                                appendCrumb({
                                    name: competenceTitle || 'Outcome',
                                    pathname: pathname,
                                    isDirectLink: true,
                                });
                                navigate(pathname);
                            }
                        }
                    }}
                >
                    {competenceTitle}
                </CompetenceTitle>
            ) : (
                <>
                    <Skeleton width="200px" variant="text" />
                    <Skeleton width="175px" variant="text" />
                </>
            );
        }
    }, [competenceTitle, competenceSubtitle, targetId, type]);

    return (
        <RootBox isVisible={(isVisible || isLoading) ?? false}>
            <HeaderTitle variant="overline">{headerCardTypeTitle}</HeaderTitle>
            <ContentBox
                gapActive={
                    type !== ERecommendationType.ADD_SKILL &&
                    type !== ERecommendationType.UPGRADE_SKILL &&
                    type !== ERecommendationType.BEHAVIOR
                }
            >
                <SVGBox>{isLoading ? <Skeleton variant="circular" width="70px" height="70px" /> : svgContent}</SVGBox>
                <InfoBox isFooterPresent={type === ERecommendationType.BEHAVIOR}>
                    <InfoTextBox variant="subtitle2">{titleMemo}</InfoTextBox>
                </InfoBox>
                <ActionBox
                    gapActive={type === ERecommendationType.ADD_SKILL || type === ERecommendationType.UPGRADE_SKILL}
                >
                    {isLoading ? <Skeleton variant="rectangular" width={50} height={50} /> : actionControlMemo}
                </ActionBox>
            </ContentBox>
            <FooterBox>{footerMemo}</FooterBox>
        </RootBox>
    );
};

export default RecommendationCardItem;
