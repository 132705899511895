import { format } from 'date-fns';
import { IScoreLine } from '../../interfaces/IScoreLine';

export const calculateYearGraphLineValue: (year: IScoreLine, date: Date, value: number) => IScoreLine = (
    year,
    date,
    value
) => {
    const scoreLineMonthIndex = year.scores.findIndex((scoreLineYear) => {
        const month = format(date, 'MMM');
        return month.toLowerCase() === (scoreLineYear.date as string).toLowerCase();
    });
    if (scoreLineMonthIndex >= 0) {
        const scoreLineMonth = year.scores[scoreLineMonthIndex];
        if (!scoreLineMonth?.value) scoreLineMonth.value = 0;
        scoreLineMonth.value = scoreLineMonth.value + value;
        year.scores[scoreLineMonthIndex] = scoreLineMonth;
    }
    return year;
};
