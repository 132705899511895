import { FC, PropsWithChildren } from 'react';
import { InputLabel, MenuItem } from '@mui/material';
import { StyledSelect, StyledFormControl } from './Style';
import DownArrowIcon from '../../../assets/icons/DownArrowIcon';

interface IProps {
    id?: string;
    label?: string;
    value: string;
    onChange: any;
    field?: any;
    disabled?: boolean;
    options: { value: string | number; label: string }[];
    labelId?: string;
}

const NSelect: FC<PropsWithChildren<IProps>> = ({
    id,
    label,
    value,
    onChange,
    field,
    disabled = false,
    options,
    labelId
}) => {
    return (
        <StyledFormControl variant='standard' sx={{ m: 1, minWidth: 120, fontSize: '14px' }}>
            {label && (
                <InputLabel sx={{ zIndex: 2, fontSize: '14px', top: '3px' }} id='standard-label'>
                    {label}
                </InputLabel>
            )}
            <StyledSelect
                {...field}
                id={id}
                disabled={disabled}
                label={label}
                variant='standard'
                onChange={onChange}
                value={value ? value.toString() : value}
                labelId='standard-label'
                IconComponent={DownArrowIcon}
                sx={{ backgroundColor: '#FAFAFA !important', fontSize: '14px' }}
            >
                {options.map((option) => {
                    return (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    );
                })}
            </StyledSelect>
        </StyledFormControl>
    );
};

export default NSelect;
