import { FC } from 'react';

interface IProps {
    width?: string;
    height?: string;
}

const DownloadBadgeIcon: FC<IProps> = ({ width, height }) => {
    return (
        <svg
            width={width || 20}
            height={height || 20}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 0L9.88338 0.00672773C9.38604 0.0644928 9 0.487164 9 1V10.585L5.70711 7.29289L5.6129 7.2097C5.22061 6.90468 4.65338 6.93241 4.29289 7.29289C3.90237 7.68342 3.90237 8.31658 4.29289 8.70711L9.29289 13.7071C9.32136 13.7356 9.35153 13.7623 9.38325 13.7872L9.47929 13.854L9.57678 13.9063L9.65833 13.9401L9.76649 13.9726L9.88195 13.9931L9.94062 13.9983L10.0593 13.9983L10.1485 13.989L10.266 13.9642L10.3713 13.9288L10.4841 13.8753L10.5953 13.8037L10.6631 13.7485L10.7071 13.7071L15.7071 8.70711L15.7903 8.6129C16.0953 8.22061 16.0676 7.65338 15.7071 7.29289L15.6129 7.2097C15.2206 6.90468 14.6534 6.93241 14.2929 7.29289L11 10.585V1L10.9933 0.883379C10.9355 0.38604 10.5128 0 10 0ZM19.9933 12.8834C19.9355 12.386 19.5128 12 19 12C18.4477 12 18 12.4477 18 13V17L17.9933 17.1166C17.9355 17.614 17.5128 18 17 18H3L2.88338 17.9933C2.38604 17.9355 2 17.5128 2 17V13L1.99327 12.8834C1.93551 12.386 1.51284 12 1 12C0.447715 12 0 12.4477 0 13V17L0.00509269 17.1763C0.0963391 18.7511 1.40232 20 3 20H17L17.1763 19.9949C18.7511 19.9037 20 18.5977 20 17V13L19.9933 12.8834Z"
                fill="#8C8C8C"
            />
        </svg>
    );
};

export default DownloadBadgeIcon;
