import { createContext, FC, useContext, useState, PropsWithChildren } from 'react';
import { IFilterCategories } from '../ui/filters/filters/Filters';

interface FiltersContextProps {
    activeFilters: string[];
    setActiveFilters: (filterKeys: string[]) => void;
    changeActiveFilter: (filterKey: string, deleteFilterList?: string[]) => void;
    emptyActiveFilters: () => void;
    categories: IFilterCategories[];
}

const FiltersContext = createContext<FiltersContextProps>({} as FiltersContextProps);

interface IProps {
    categories: IFilterCategories[];
}

export const FiltersProvider: FC<PropsWithChildren<IProps>> = ({ children, categories }) => {
    const [activeFilters, setActiveFilters] = useState<string[]>([]);

    const changeActiveFilter = (filterKey: string, deleteFilterList?: string[]) => {
        const isFilterAlreadyActive = activeFilters.findIndex((a) => a === filterKey);
        if (isFilterAlreadyActive !== -1) {
            let newActiveFilters = activeFilters.filter((a) => a !== filterKey);
            if (deleteFilterList) {
                newActiveFilters = activeFilters.filter((d) => !deleteFilterList.includes(d));
            }
            setActiveFilters(newActiveFilters);
        } else {
            setActiveFilters((af) => {
                if (!af.includes(filterKey)) {
                    let newActiveFilters = [...af, filterKey];
                    if (deleteFilterList) {
                        newActiveFilters = newActiveFilters.filter((d) => !deleteFilterList.includes(d));
                    }
                    return newActiveFilters;
                }
                return af;
            });
        }
    };

    const emptyActiveFilters = () => {
        setActiveFilters([]);
    };

    const contextState: FiltersContextProps = {
        activeFilters,
        setActiveFilters,
        changeActiveFilter,
        emptyActiveFilters,
        categories,
    };

    return <FiltersContext.Provider value={contextState}>{children}</FiltersContext.Provider>;
};

export const useFiltersStateValue: () => FiltersContextProps = () => useContext(FiltersContext);

export default FiltersContext;
