import { Typography } from '@mui/material';
import styled from '../../../../../../ui/theme/styled';

export const DisplayNameTypography = styled(Typography)({
    color: 'rgba(0, 0, 0, 0.8)',
});

export const StatusCircle = styled('div')<{ color: string }>(({ theme, color }) => ({
    backgroundColor: color,
    width: '10px',
    height: '10px',
    borderRadius: '11px',
}));

export const StatusCircleWrapper = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'center',
}));
