import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

export const MotdCardRoot = styled('div')(({ theme }) => ({
    background: 'linear-gradient(180deg, #FFFFFF 0%, #FAFAFA 100%), #FFFFFF',
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.08), 0px 0.5px 1.5px rgba(0, 0, 0, 0.08)',
    height: '100%',
    minHeight: '258px',
    borderRadius: theme.borderRadius?.outside?.small
}));

export const TitleBox = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    padding: '16px 24px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.08)'
});

export const ContentBox = styled('div')<{ oneColumn?: boolean }>(({ oneColumn, theme }) => ({
    display: 'grid',
    gridTemplateColumns: oneColumn ? '1fr' : '1fr 1fr',
    gridGap: 32,
    padding: 24,
    [theme.breakpoints.down(725)]: {
        gridTemplateColumns: '1fr'
    }
}));

export const ImageBox = styled('div')(({ theme }) => ({
    [theme.breakpoints.down(725)]: {
        display: 'flex',
        justifyContent: 'center'
    },
    '&:has(img[src*="/no-image.png"])': {
        display: 'flex',
        justifyContent: 'center'
    }
}));

export const DetailsBox = styled('div')({
    display: 'flex',
    flexDirection: 'column'
});

export const TitleTypography = styled(Typography)({
    color: 'rgba(0, 0, 0, 0.8)'
});

export const DetailsTitleTypography = styled(Typography)(({ theme }) => ({
    paddingBottom: 8,
    color: theme.palette.gray.dark
}));

export const DetailsDescriptionTypography = styled(Typography)(({ theme }) => ({
    fontFamily: 'Open Sans',
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: '0.25px',
    lineHeight: '20px',
    flex: 1,
    wordBreak: 'break-word',
    color: theme.palette.gray.dark
}));

export const DetailsImg = styled('img')(({ theme }) => ({
    width: 299,
    [theme.breakpoints.down('md')]: {
        maxWidth: 200,
        width: '100%'
    },
    '&[src*="/no-image.png"]': {
        maxWidth: '150px',
        maxHeight: '150px'
    }
}));

export const ActionButtonBox = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '12px'
});
