import { createContext, FC, useCallback, useContext, useEffect, useState, PropsWithChildren } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useGetOutcomeQuery, usePostAssignOutcome, usePostMasterOutcome } from '../services/OutcomeQueryService';
import { ISingleUserOutcomeVM } from '../interfaces/views/ISingleUserOutcomeVM';
import { EAssignmentType } from '../interfaces/enums/EAssignmentType';
import { ESkillLevel } from '../interfaces/enums/ESkillLevel';
import { usePostFavoriteSkill } from '../services/SkillQueryService';
import { EMasterAction } from '../interfaces/enums/EMasterAction';
import { usePostClaimMasterBadge, usePostClaimProducerBadge } from '../services/BadgeQueryService';
import { EToastSeverity, useToastContextStateValue } from './ToastContext';
import { IBadge } from '../interfaces/IBadge';
import { ErrorDTO } from '../interfaces/dtos/ErrorDTO';
import { useGetRecommendationNextStepQuery } from '../services/RecommendationService';
import { IRecommendationVM } from '../interfaces/views/IRecommendationVM';
import { ECompetenceState } from '../interfaces/enums/ECompetenceState';
import { usePostDownloadBadge } from '../services/LicenseQueryService';
import { downloadFile } from '../utils/downloadFile';
import { usePostUserContentTrackingQuery } from '../services/UserQueryService';
import { EUserContentTrackingType } from '../interfaces/enums/EUserContentTrackingType';
import { useApiStateValue } from './ApiContext';
import { EApiQueryKey } from '../interfaces/enums/EApiQueryKey';

interface OutcomeContextProps {
    userOutcomeData?: ISingleUserOutcomeVM;
    isLoadingOutcomeData: boolean;
    removeOutcomeData: any;
    isOutcomeRefetching: boolean;
    refetchOutcomeData: any;
    toggleMasterTarget: (isMasterTarget: boolean) => void;
    toggleOutcomeAssign: (isAssigned: boolean) => void;
    toggleFavoriteSkill: (skillId: number) => void;
    isError: boolean;
    onClaimClick: (level: ESkillLevel) => void;
    isPostClaimProducerBadgeLoading: boolean;
    isPostClaimMasterBadgeLoading: boolean;
    isPostMasterOutcomeLoading: boolean;
    nextStepRecommendations?: IRecommendationVM[];
    isNextStepRecommendationsLoading: boolean;
    onDownloadBadgeClick: (outcomeId: number, level: string) => void;
    isDownloadBadgeLoading: boolean;
    sendContentUsageRequest: (relatedId?: number) => Promise<void>;
}

const OutcomeContext = createContext<OutcomeContextProps>({} as OutcomeContextProps);

interface IProps {}

export const OutcomeProvider: FC<PropsWithChildren<IProps>> = ({ children }) => {
    const params = useParams<{ id?: string; badgeId?: string }>();
    const {
        data: fetchedOutcomeData,
        isLoading: isLoadingOutcomeData,
        refetch: refetchOutcomeData,
        isRefetching: isOutcomeRefetching,
        isError,
    } = useGetOutcomeQuery(params?.id);
    const { mutateAsync: mutateAssignOutcomeAsync } = usePostAssignOutcome();
    const [userOutcomeData, setUserOutcomeData] = useState<ISingleUserOutcomeVM | undefined>(undefined);
    const { mutateAsync: mutatePostFavoriteSkillAsync, error: errorPostFavoriteSkill } = usePostFavoriteSkill();
    const { mutateAsync: mutatePostMasterOutcomeAsync, isPending: isPostMasterOutcomeLoading } = usePostMasterOutcome();
    const {
        mutateAsync: mutatePostClaimProducerBadgeAsync,
        error: errorPostClaimProducerBadge,
        isPending: isPostClaimProducerBadgeLoading,
    } = usePostClaimProducerBadge();
    const {
        mutateAsync: mutatePostClaimMasterBadgeAsync,
        error: errorPostClaimMasterBadge,
        isPending: isPostClaimMasterBadgeLoading,
    } = usePostClaimMasterBadge();
    const { setToastMessage } = useToastContextStateValue();
    const { data: fetchedNextStepRecommendationData, isLoading: isNextStepRecommendationsLoading } =
        useGetRecommendationNextStepQuery(params?.id);
    const { t } = useTranslation();
    const { mutateAsync: mutatePostDownloadBadgeAsync } = usePostDownloadBadge();
    const [isDownloadBadgeActive, setDownloadBadgeActive] = useState<boolean>(false);
    const { mutateAsync: mutatePostUserContentTrackingAsync } = usePostUserContentTrackingQuery();
    const { resetQuery } = useApiStateValue();

    const removeOutcomeData = useCallback(() => {
        resetQuery(EApiQueryKey.OUTCOME_FETCH);
    }, [resetQuery]);

    useEffect(() => {
        setUserOutcomeData(fetchedOutcomeData);
    }, [fetchedOutcomeData]);

    useEffect(() => {
        if (params.id) {
            resetQuery(EApiQueryKey.OUTCOME_FETCH);
            refetchOutcomeData();
        }
    }, [params]);

    useEffect(() => {
        if (errorPostClaimProducerBadge) {
            setToastMessage({
                isOpen: true,
                message: t('errors.badge.errorProducerBadgeClaim'),
                severity: EToastSeverity.ERROR,
            });
        }
    }, [errorPostClaimProducerBadge]);

    useEffect(() => {
        if (errorPostClaimMasterBadge) {
            setToastMessage({
                isOpen: true,
                message: t('errors.badge.errorMasterBadgeClaim'),
                severity: EToastSeverity.ERROR,
            });
        }
    }, [errorPostClaimMasterBadge]);

    useEffect(() => {
        if (errorPostFavoriteSkill && errorPostFavoriteSkill instanceof Error) {
            const errorDTO = errorPostFavoriteSkill as ErrorDTO;
            if (errorDTO.response?.data?.code === 509) {
                setToastMessage({
                    isOpen: true,
                    message: t('errors.skill.errorFavoriteSkillLimit'),
                    severity: EToastSeverity.WARNING,
                });
            } else {
                setToastMessage({
                    isOpen: true,
                    message: t('errors.skill.errorFavoriteSkill'),
                    severity: EToastSeverity.ERROR,
                });
            }
        }
    }, [errorPostFavoriteSkill]);

    const toggleMasterTarget = useCallback(
        async (isMasterTarget: boolean) => {
            if (params.id && userOutcomeData?.id) {
                try {
                    await mutatePostMasterOutcomeAsync({
                        action: isMasterTarget ? EMasterAction.ENABLE_MASTER : EMasterAction.DISABLE_MASTER,
                        outcomeId: userOutcomeData?.id,
                    });
                    setUserOutcomeData((data: ISingleUserOutcomeVM | undefined) => {
                        if (data) {
                            return {
                                ...data,
                                userSkills: [
                                    ...data.userSkills.map((userSkill) => {
                                        return {
                                            ...userSkill,
                                            assignment: {
                                                ...userSkill.assignment,
                                                level: isMasterTarget ? ESkillLevel.MASTER : ESkillLevel.PRODUCER,
                                            },
                                        };
                                    }),
                                ],
                                assignment: {
                                    ...data.assignment,
                                    level: isMasterTarget ? ESkillLevel.MASTER : ESkillLevel.PRODUCER,
                                },
                                numberOfInProgressSkills: data.userSkills.filter(
                                    (userSkill) =>
                                        (isMasterTarget &&
                                            userSkill.assignment.masterState === ECompetenceState.IN_PROGRESS) ||
                                        (!isMasterTarget &&
                                            userSkill.assignment.producerState === ECompetenceState.IN_PROGRESS)
                                ).length,
                                numberOfAchievedSkills: data.userSkills.filter(
                                    (userSkill) =>
                                        (isMasterTarget &&
                                            userSkill.assignment.masterState === ECompetenceState.MAINTAIN) ||
                                        (!isMasterTarget &&
                                            userSkill.assignment.producerState === ECompetenceState.MAINTAIN)
                                ).length,
                            };
                        }
                        return data;
                    });
                } catch (e) {
                    setToastMessage({
                        isOpen: true,
                        message: t('outcome.errors.masterLevelGenericError'),
                        severity: EToastSeverity.ERROR,
                    });
                }
            }
        },
        [userOutcomeData]
    );

    const toggleOutcomeAssign = useCallback(
        async (isAssigned: boolean) => {
            if (userOutcomeData) {
                const responseData = await mutateAssignOutcomeAsync({
                    outcomeId: userOutcomeData.id.toString(),
                    assignmentType: isAssigned ? EAssignmentType.ASSIGN : EAssignmentType.UNASSIGN,
                });
                setUserOutcomeData((userOutcomeData) => {
                    if (!userOutcomeData) return userOutcomeData;
                    return {
                        ...userOutcomeData,
                        assignment: {
                            ...responseData,
                        },
                        userSkills: [
                            ...userOutcomeData.userSkills.map((userSkill) => {
                                return {
                                    ...userSkill,
                                    assignment: {
                                        ...userSkill.assignment,
                                        level: isAssigned ? ESkillLevel.PRODUCER : ESkillLevel.NONE,
                                    },
                                };
                            }),
                        ],
                        isMasterTargetControlDisabled: responseData.level === ESkillLevel.NONE,
                        numberOfInProgressSkills: userOutcomeData.userSkills.filter(
                            (userSkill) =>
                                (responseData.level === ESkillLevel.MASTER &&
                                    userSkill.assignment.masterState === ECompetenceState.IN_PROGRESS) ||
                                (responseData.level === ESkillLevel.PRODUCER &&
                                    userSkill.assignment.producerState === ECompetenceState.IN_PROGRESS)
                        ).length,
                        numberOfAchievedSkills: userOutcomeData.userSkills.filter(
                            (userSkill) =>
                                (responseData.level === ESkillLevel.MASTER &&
                                    userSkill.assignment.masterState === ECompetenceState.MAINTAIN) ||
                                (responseData.level === ESkillLevel.PRODUCER &&
                                    userSkill.assignment.producerState === ECompetenceState.MAINTAIN)
                        ).length,
                    };
                });
            }
        },
        [userOutcomeData]
    );

    const toggleFavoriteSkillCallback = useCallback(
        async (skillId: number) => {
            const responseData = await mutatePostFavoriteSkillAsync({
                skillId: skillId.toString(),
            });
            setUserOutcomeData((userOutcomeData) => {
                if (!userOutcomeData) return userOutcomeData;
                return {
                    ...userOutcomeData,
                    userSkills: userOutcomeData?.userSkills.map((userSkill) => {
                        if (userSkill.id === skillId) {
                            return {
                                ...userSkill,
                                isUserFavorite: responseData.isFavorite,
                            };
                        }
                        return userSkill;
                    }),
                };
            });
        },
        [userOutcomeData, setUserOutcomeData, mutatePostFavoriteSkillAsync]
    );

    const onClaimClick = useCallback(
        async (level: ESkillLevel) => {
            if (!userOutcomeData?.id) return;
            if (level === ESkillLevel.PRODUCER) {
                const responseBadge: IBadge = await mutatePostClaimProducerBadgeAsync({
                    outcomeId: userOutcomeData?.id.toString(),
                });
                setUserOutcomeData((userOutcomeData) => {
                    if (!userOutcomeData) return userOutcomeData;
                    return {
                        ...userOutcomeData,
                        producerLevelBadge: responseBadge,
                    };
                });
                setToastMessage({
                    isOpen: true,
                    message: t('success.badge.successProducerBadgeClaim'),
                    severity: EToastSeverity.SUCCESS,
                });
            } else if (level === ESkillLevel.MASTER) {
                const responseBadge: IBadge = await mutatePostClaimMasterBadgeAsync({
                    outcomeId: userOutcomeData?.id.toString(),
                });
                setUserOutcomeData((userOutcomeData) => {
                    if (!userOutcomeData) return userOutcomeData;
                    return {
                        ...userOutcomeData,
                        masterLevelBadge: responseBadge,
                    };
                });
                setToastMessage({
                    isOpen: true,
                    message: t('success.badge.successMasterBadgeClaim'),
                    severity: EToastSeverity.SUCCESS,
                });
            }
        },
        [userOutcomeData]
    );

    const showDownloadBadgeErrorMessage = () => {
        setToastMessage({
            isOpen: true,
            message: t('badges.errorDownloadingBadge'),
            severity: EToastSeverity.ERROR,
        });
    };

    const onDownloadBadgeClick = useCallback(
        async (outcomeId: number, level: string) => {
            try {
                setDownloadBadgeActive(true);
                const { url } = await mutatePostDownloadBadgeAsync({ outcomeId, level });
                if (url) {
                    const fileName = `${userOutcomeData?.title} - ${level} Badge`;
                    try {
                        const isSuccess = await downloadFile(url, `${fileName}.png`);
                        if (!isSuccess) {
                            showDownloadBadgeErrorMessage();
                        }
                    } catch (e) {
                        console.error(e);
                        showDownloadBadgeErrorMessage();
                    }
                } else {
                    showDownloadBadgeErrorMessage();
                }
            } catch (e) {
                showDownloadBadgeErrorMessage();
            } finally {
                setDownloadBadgeActive(false);
            }
        },
        [userOutcomeData]
    );

    const sendContentUsageRequest: (relatedId?: number) => Promise<void> = async (relatedId) => {
        if (!relatedId) return;
        try {
            await mutatePostUserContentTrackingAsync({
                contentType: EUserContentTrackingType.OUTCOME_OVERVIEW_VIDEO,
                relatedId,
            });
        } catch (e) {
            console.error(e);
        }
    };

    const contextState: OutcomeContextProps = {
        userOutcomeData: userOutcomeData,
        isLoadingOutcomeData,
        isOutcomeRefetching,
        removeOutcomeData,
        refetchOutcomeData,
        toggleMasterTarget,
        toggleOutcomeAssign,
        toggleFavoriteSkill: toggleFavoriteSkillCallback,
        isError,
        onClaimClick,
        isPostClaimProducerBadgeLoading,
        isPostClaimMasterBadgeLoading,
        isPostMasterOutcomeLoading,
        nextStepRecommendations: fetchedNextStepRecommendationData,
        isNextStepRecommendationsLoading,
        onDownloadBadgeClick,
        isDownloadBadgeLoading: isDownloadBadgeActive,
        sendContentUsageRequest,
    };

    return <OutcomeContext.Provider value={contextState}>{children}</OutcomeContext.Provider>;
};

export const useOutcomeStateValue: () => OutcomeContextProps = () => useContext(OutcomeContext);

export default OutcomeContext;
