import { EUserRoleLogical } from '../interfaces/enums/EUserRoleLogical';

export const convertLogicalRolesToEnumValues: (rolesValue: number) => EUserRoleLogical[] = (rolesValue) => {
    const userRoles: EUserRoleLogical[] = [];

    if (rolesValue & 1) userRoles.push(EUserRoleLogical.USER);
    if (rolesValue & 2) userRoles.push(EUserRoleLogical.MANAGER);
    if (rolesValue & 4) userRoles.push(EUserRoleLogical.ADMIN_REPORTS);
    if (rolesValue & 8) userRoles.push(EUserRoleLogical.ADMIN_LICENSES);
    if (rolesValue & 16) userRoles.push(EUserRoleLogical.ADMIN_GLOBAL);

    return userRoles;
};
