import { FC, useEffect, useRef, MouseEvent, useState } from 'react';

interface IProps {
    src: string;
    // user?: IUser;
    // contentData?: IContentData;
    autoPlay?: boolean;
    watchDuration?: number;
    disablePictureInPicture?: boolean;
    setVideoTime?: (time: number) => void;
    finishVideo?: () => void;
    trackSrc?: string;
}

const VideoPlayer: FC<IProps> = ({
    // user,
    src,
    // contentData,
    autoPlay,
    watchDuration = 15,
    disablePictureInPicture,
    setVideoTime,
    finishVideo,
    trackSrc,
}) => {
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const interval = useRef<NodeJS.Timeout | undefined>(undefined);
    // const ApiService = useApiService();
    const [viewed, setViewed] = useState<boolean>(false);
    const [apiCalled, setApiCalled] = useState<boolean>(false);

    useEffect(() => {
        // if (!user || !setVideoTime) {
        if (!setVideoTime) {
            return;
        }

        interval.current = setInterval(async () => {
            if (videoRef && videoRef.current) {
                setVideoTime(videoRef.current.currentTime);
            }
            if (videoRef && videoRef.current && !viewed) {
                if (
                    Math.floor(videoRef.current.currentTime) >=
                    Math.floor(videoRef.current.duration) - watchDuration - 1
                ) {
                    if (interval.current) {
                        clearInterval(interval.current);
                    }
                    if (finishVideo) {
                        finishVideo();
                    }
                    if (!apiCalled) {
                        setApiCalled(true);
                        // await ApiService.viewedContent(user, contentData?.contentId ?? -1);
                        setViewed(true);
                    }
                }
            }
        }, 1000);

        return () => {
            if (interval.current) {
                clearInterval(interval.current);
            }
        };
    });

    const onContextMenu = (e: MouseEvent) => e.preventDefault();

    return (
        <video
            controls={true}
            controlsList="nodownload"
            ref={videoRef}
            autoPlay={autoPlay === undefined ? true : autoPlay}
            onContextMenu={onContextMenu}
            disablePictureInPicture={disablePictureInPicture}
            crossOrigin="anonymous"
        >
            <source src={src} type="video/mp4" />
            <track src={trackSrc} kind="captions" srcLang="en" label="English" />
            Your browser does not support the
            <code>video</code> element.
        </video>
    );
};

export default VideoPlayer;
