import styled from '../../theme/styled';
import { Button } from '@material-ui/core';
import { Box } from '@mui/system';

export const ButtonRoot = styled(Button)(({ theme }) => ({
    width: 108,
    display: 'flex !important',
    alignItems: 'center !important',
    justifyContent: 'center !important',
    fontFamily: 'Ubuntu !important',
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: `${theme.palette.common.white} !important`,
    '&:hover': {
        backgroundColor: `${theme.palette.primary.dark} !important`,
    },
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '16px',
    letterSpacing: '1.25px',
    textAlign: 'left',
    padding: '10px 0px',
}));

export const IconBox = styled(Box)({
    marginRight: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});
