import { createContext, FC, useCallback, useContext, useEffect, useRef, useState, PropsWithChildren } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isBefore } from 'date-fns';
import { useGetAllOutcomesQuery, usePostAssignOutcome } from '../services/OutcomeQueryService';
import { ECompetenceState } from '../interfaces/enums/ECompetenceState';
import { IFilterCategories, IFilterCategoryValue } from '../ui/filters/filters/Filters';
import {
    appFilterCallback,
    categoryFilterCallback,
    EOrderDirection,
    EOutcomeSort,
    IOutcomeSortOrder,
    outcomeCategories
} from './util/filterCategories';
import { EBadgeLevel } from '../interfaces/enums/EBadgeLevel';
import { EAssignmentType } from '../interfaces/enums/EAssignmentType';
import { IUserOutcomeVM } from '../interfaces/views/IUserOutcomeVM';
import { ESkillLevel } from '../interfaces/enums/ESkillLevel';
import { EOutcomesStatuses } from '../interfaces/enums/EOutcomesStatuses';
import AssignedIcon from '../assets/icons/AssignedIcon';
import { useTabsStateValue } from './TabsContext';
import { usePostUserRecommendations } from '../services/RecommendationService';
import { ERecommendationType } from '../interfaces/enums/ERecommendationType';
import { IRecommendationVM } from '../interfaces/views/IRecommendationVM';
import calculateAssignmentState from '../utils/calculateAssignmentState';
import ProducerAvailableToClaim from '../assets/icons/badge-statuses/ProducerAvailableToClaim';
import { EToastSeverity, useToastContextStateValue } from './ToastContext';
import { routes } from '../pages/routes';
import { useCrumbsStateValue } from './CrumbsContext';
import useFilterSearch from '../hooks/useFilterSearch';
import { isInTeams } from '../utils/isInTeams';

interface ISkillStatusInfo {
    key: EOutcomesStatuses;
    label: string;
    value?: number | string | null;
    icon?: React.ReactNode;
    circleColor?: string;
    isLoading?: boolean;
}

interface OutcomesContextProps {
    outcomes: IUserOutcomeVM[];
    isOutcomesLoading: boolean;
    skillsStatusOverallInfo: ISkillStatusInfo[];
    activeOutcomesStatusOverallFilters: EOutcomesStatuses[];
    changeSkillStatusOverallActiveFilters: (key: EOutcomesStatuses) => void;
    onFilterValueChange: (
        filterCategories: IFilterCategories[],
        activeFilters: string[],
        dontRunAnythingChange?: boolean
    ) => void;
    setSortOrder: (sortOrder: IOutcomeSortOrder) => void;
    sortOrder: IOutcomeSortOrder;
    isError: boolean;
    refetchData: () => void;
    filterCategories: IFilterCategories[];
    setFilteredOutcomes: (list: IUserOutcomeVM[]) => void;
    toggleAssignCallback: (outcomeId: number, isAssigned: boolean) => void;
    emptyQuickFilters: () => void;
    isPostAssignOutcomeLoading: boolean;
    isFetchedDataProcessed: boolean;
    recommendations?: IRecommendationVM[];
    isRecommendationsLoading: boolean;
    successRecommendationCallback: () => void;
}

const OutcomesContext = createContext<OutcomesContextProps>({} as OutcomesContextProps);

interface IProps {}

export const OutcomesProvider: FC<PropsWithChildren<IProps>> = ({ children }) => {
    const {
        data: fetchedOutcomes,
        isLoading: isOutcomesLoading,
        isError,
        refetch,
        isRefetching: isOutcomesRefetching
    } = useGetAllOutcomesQuery();
    const { t } = useTranslation();
    const [filteredOutcomes, setFilteredOutcomes] = useState<IUserOutcomeVM[]>([]);
    const [activeOutcomesStatusOverallFilters, setActiveOutcomesStatusOverallFilters] = useState<EOutcomesStatuses[]>(
        []
    );
    const [skillsStatusOverallInfo, setSkillsStatusOverallInfo] = useState<ISkillStatusInfo[]>([
        {
            key: EOutcomesStatuses.IN_PROGRESS,
            label: t('outcomes.statuses.inProgress'),
            circleColor: 'rgba(255, 191, 68, 1)',
            value: null,
            isLoading: true
        },
        {
            key: EOutcomesStatuses.NEED_ATTENTION,
            label: t('outcomes.statuses.needAttention'),
            circleColor: '#ED8000',
            value: null,
            isLoading: true
        },
        {
            key: EOutcomesStatuses.ATTAINED,
            label: t('outcomes.statuses.attained'),
            circleColor: 'rgba(146, 185, 35, 1)',
            value: null,
            isLoading: true
        },
        {
            key: EOutcomesStatuses.ASSIGNED_OUTCOMES,
            label: t('outcomes.statuses.assigned'),
            icon: <AssignedIcon />,
            value: null,
            isLoading: true
        },
        {
            key: EOutcomesStatuses.UNCLAIMED_BADGES,
            label: t('outcomes.statuses.badgesReadyToClaim'),
            icon: <ProducerAvailableToClaim />,
            value: null,
            isLoading: true
        }
    ]);
    const [sortOrder, setSortOrder] = useState<IOutcomeSortOrder>({
        sortBy: EOutcomeSort.NAME,
        direction: EOrderDirection.ASC
    });
    const [filterCategories, setFilterCategories] = useState<IFilterCategories[]>(outcomeCategories);
    const [outcomes, setOutcomes] = useState<IUserOutcomeVM[]>([]);
    const {
        mutateAsync: mutatePostAssignOutcomeAsync,
        isPending: isPostAssignOutcomeLoading,
        error: errorPostAssignSkill
    } = usePostAssignOutcome();
    const sortOrderRef = useRef({
        sortBy: EOutcomeSort.NAME,
        direction: EOrderDirection.ASC
    });
    const activeFiltersRef = useRef<string[]>([]);
    const activeOutcomesStatusOverallFiltersRef = useRef<EOutcomesStatuses[]>([]);
    const [isFetchedDataProcessed, setFetchedDataProcessed] = useState<boolean>(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const {
        data: fetchedRecommendationsData,
        mutateAsync: mutateRecommendationsAsync,
        isPending: isRecommendationsLoading
    } = usePostUserRecommendations([ERecommendationType.ADD_OUTCOME, ERecommendationType.UPGRADE_OUTCOME]);
    const { setToastMessage } = useToastContextStateValue();
    const { startNewCrumbs } = useCrumbsStateValue();

    useEffect(() => {
        if (isInTeams()) {
            startNewCrumbs({
                name: 'Outcomes',
                pathname: routes.OUTCOMES
            });
        }
    }, []);

    useEffect(() => {
        if (errorPostAssignSkill && errorPostAssignSkill instanceof Error) {
            setToastMessage({
                isOpen: true,
                message: t('errors.outcome.errorAssignOutcome'),
                severity: EToastSeverity.ERROR
            });
        }
    }, [errorPostAssignSkill]);

    useEffect(() => {
        mutateRecommendationsAsync(undefined);
    }, []);

    useEffect(() => {
        const filterQueryParam = searchParams.get('filter');
        if (outcomes && filterQueryParam) {
            switch (filterQueryParam) {
                case EOutcomesStatuses.ASSIGNED_OUTCOMES:
                    setActiveOutcomesStatusOverallFilters((activeOutcomesStatusOverallFilters) => {
                        return [...activeOutcomesStatusOverallFilters, EOutcomesStatuses.ASSIGNED_OUTCOMES];
                    });
                    break;
                case EOutcomesStatuses.NEED_ATTENTION:
                    setActiveOutcomesStatusOverallFilters((activeOutcomesStatusOverallFilters) => {
                        return [...activeOutcomesStatusOverallFilters, EOutcomesStatuses.NEED_ATTENTION];
                    });
                    break;
                case EOutcomesStatuses.ATTAINED:
                    setActiveOutcomesStatusOverallFilters((activeOutcomesStatusOverallFilters) => {
                        return [...activeOutcomesStatusOverallFilters, EOutcomesStatuses.ATTAINED];
                    });
                    break;
                case EOutcomesStatuses.IN_PROGRESS:
                    setActiveOutcomesStatusOverallFilters((activeOutcomesStatusOverallFilters) => {
                        return [...activeOutcomesStatusOverallFilters, EOutcomesStatuses.IN_PROGRESS];
                    });
                    break;
                case EOutcomesStatuses.UNCLAIMED_BADGES:
                    setActiveOutcomesStatusOverallFilters((activeOutcomesStatusOverallFilters) => {
                        return [...activeOutcomesStatusOverallFilters, EOutcomesStatuses.UNCLAIMED_BADGES];
                    });
                    break;
            }
        }
    }, [searchParams, outcomes]);

    useEffect(() => {
        sortOrderRef.current = sortOrder;
    }, [sortOrder]);

    useEffect(() => {
        if (isError) {
            setSkillsStatusOverallInfo((skillsStatusOverallInfo) => {
                return skillsStatusOverallInfo.map((status) => {
                    return {
                        ...status,
                        isLoading: false
                    };
                });
            });
        }
    }, [isError]);

    useEffect(() => {
        if (fetchedOutcomes) {
            setOutcomes(fetchedOutcomes);
            setFetchedDataProcessed(true);
        }
    }, [fetchedOutcomes]);

    useEffect(() => {
        sortByOrder(filteredOutcomes);
    }, [sortOrder]);

    useEffect(() => {
        activeOutcomesStatusOverallFiltersRef.current = activeOutcomesStatusOverallFilters;
    }, [activeOutcomesStatusOverallFilters]);

    const sortByOrder = (outcomes: IUserOutcomeVM[]) => {
        let newOutcomes = [...outcomes];
        const sortOrder = sortOrderRef.current;
        switch (sortOrder.sortBy) {
            case EOutcomeSort.NAME:
                newOutcomes = outcomes.sort((a, b) => {
                    if (sortOrder.direction === EOrderDirection.ASC) return ('' + a.title).localeCompare(b.title);
                    return ('' + b.title).localeCompare(a.title);
                });
                return newOutcomes;
            case EOutcomeSort.SCORE:
                newOutcomes = outcomes.sort((a, b) => {
                    const isDesc = sortOrder.direction === EOrderDirection.DESC;
                    if (a.numberOfAchievedSkills > b.numberOfAchievedSkills) return isDesc ? -1 : 1;
                    if (a.numberOfAchievedSkills < b.numberOfAchievedSkills) return isDesc ? 1 : -1;
                    if (a.numberOfAchievedSkills === b.numberOfAchievedSkills) {
                        if (a.numberOfNeedAttentionSkills > b.numberOfNeedAttentionSkills) return isDesc ? -1 : 1;
                        if (a.numberOfNeedAttentionSkills < b.numberOfNeedAttentionSkills) return isDesc ? 1 : -1;
                        if (a.numberOfNeedAttentionSkills === b.numberOfNeedAttentionSkills) {
                            if (a.numberOfInProgressSkills > b.numberOfInProgressSkills) return isDesc ? -1 : 1;
                            if (a.numberOfInProgressSkills < b.numberOfInProgressSkills) return isDesc ? 1 : -1;
                            return 0;
                        }
                    }

                    return 1;
                });
                return newOutcomes;
            case EOutcomeSort.WEEKS_IN_PROGRESS:
                newOutcomes = outcomes.sort((a, b) => {
                    if (sortOrder.direction === EOrderDirection.ASC) {
                        if (
                            a.assignment.level === ESkillLevel.MASTER &&
                            a.assignment.masterState !== ECompetenceState.IN_PROGRESS
                        )
                            return -1;
                        if (
                            a.assignment.level === ESkillLevel.PRODUCER &&
                            a.assignment.producerState !== ECompetenceState.IN_PROGRESS
                        )
                            return -1;
                        return a.assignment.streak < b.assignment.streak ? 1 : -1;
                    }
                    if (
                        a.assignment.level === ESkillLevel.MASTER &&
                        a.assignment.masterState !== ECompetenceState.IN_PROGRESS
                    )
                        return 1;
                    if (
                        a.assignment.level === ESkillLevel.PRODUCER &&
                        a.assignment.producerState !== ECompetenceState.IN_PROGRESS
                    )
                        return 1;
                    return a.assignment.streak > b.assignment.streak ? 1 : -1;
                });
                return newOutcomes;
            case EOutcomeSort.ASSIGNED_DATE:
                newOutcomes = outcomes.sort((a, b) => {
                    const aLevel = a.assignment.level;
                    const bLevel = b.assignment.level;
                    if (sortOrder.direction === EOrderDirection.ASC) {
                        if (
                            (aLevel === ESkillLevel.NONE && bLevel === ESkillLevel.NONE) ||
                            (aLevel !== ESkillLevel.NONE && bLevel !== ESkillLevel.NONE)
                        )
                            return isBefore(new Date(a.assignment.date), new Date(b.assignment.date)) ? -1 : 1;
                        if (aLevel === ESkillLevel.NONE && bLevel !== ESkillLevel.NONE) return 1;
                        if (aLevel !== ESkillLevel.NONE && bLevel === ESkillLevel.NONE) return -1;
                        return 1;
                    }
                    if (
                        (aLevel === ESkillLevel.NONE && bLevel === ESkillLevel.NONE) ||
                        (aLevel !== ESkillLevel.NONE && bLevel !== ESkillLevel.NONE)
                    )
                        return isBefore(new Date(a.assignment.date), new Date(b.assignment.date)) ? 1 : -1;
                    if (aLevel === ESkillLevel.NONE && bLevel !== ESkillLevel.NONE) return 1;
                    if (aLevel !== ESkillLevel.NONE && bLevel === ESkillLevel.NONE) return -1;
                    return -1;
                });
                return newOutcomes;
            default:
                return newOutcomes;
        }
    };

    const changeSkillStatusOverallActiveFilters = useCallback(
        (key: EOutcomesStatuses) => {
            setActiveOutcomesStatusOverallFilters((activeStatusList) => {
                setSearchParams(
                    (prev) => {
                        if (activeStatusList.includes(key)) {
                            prev.set(
                                'filter',
                                encodeURIComponent(
                                    [...new Set(activeStatusList)].filter((statusKey) => statusKey !== key).join('-')
                                )
                            );
                            const newValues = prev.getAll('filter');
                            if (newValues.length === 1 && newValues[0] === '') {
                                prev.delete('filter');
                            }
                        } else {
                            prev.set('filter', encodeURIComponent([key].join('-')));
                        }
                        return prev;
                    },
                    { replace: true }
                );
                if (activeStatusList.includes(key)) {
                    return [];
                }
                return [key];
            });
        },
        [filteredOutcomes, activeOutcomesStatusOverallFilters]
    );

    const toggleAssignCallback = useCallback(
        async (outcomeId: number, isAssigned: boolean) => {
            const responseData = await mutatePostAssignOutcomeAsync({
                outcomeId: outcomeId.toString(),
                assignmentType: isAssigned ? EAssignmentType.ASSIGN : EAssignmentType.UNASSIGN
            });
            setOutcomes((outcomes) => {
                return outcomes.map((outcome) => {
                    if (outcome.id !== outcomeId) return outcome;
                    const state = calculateAssignmentState(outcome.assignment);
                    return {
                        ...outcome,
                        assignment: {
                            ...responseData
                        },
                        isAssigned: responseData.level !== ESkillLevel.NONE,
                        isUserFavoriteControlDisabled: state === ECompetenceState.NONE,
                        numberOfAchievedSkills: outcome.userSkillStates.filter((userSkillState) => {
                            if (responseData.level === ESkillLevel.PRODUCER)
                                return userSkillState.assignment.producerState === ECompetenceState.MAINTAIN;
                            if (responseData.level === ESkillLevel.MASTER)
                                return userSkillState.assignment.masterState === ECompetenceState.MAINTAIN;
                            return false;
                        }).length,
                        numberOfInProgressSkills: outcome.userSkillStates.filter((userSkillState) => {
                            if (responseData.level === ESkillLevel.PRODUCER)
                                return userSkillState.assignment.producerState === ECompetenceState.IN_PROGRESS;
                            if (responseData.level === ESkillLevel.MASTER)
                                return userSkillState.assignment.masterState === ECompetenceState.IN_PROGRESS;
                            return false;
                        }).length
                    };
                });
            });
        },
        [outcomes, setFilteredOutcomes]
    );

    useEffect(() => {
        if (fetchedOutcomes) {
            const outcomes = fetchedOutcomes;
            setFilteredOutcomes(outcomes);
            calculateSkillStatusOverallInfo();
            const apps = new Set<string>();
            outcomes.forEach((outcome) => {
                outcome.apps.forEach((app) => {
                    apps.add(app.name);
                });
            });
            const categories = new Set<string>();
            outcomes.forEach((outcome) => {
                outcome.categories.forEach((category) => {
                    categories.add(category);
                });
            });

            filterCategories.forEach((filterCategory) => {
                if (filterCategory.name === 'App') {
                    const categoryValues: IFilterCategoryValue[] = [];
                    apps.forEach((app) => {
                        categoryValues.push({
                            key: app,
                            name: app,
                            callback: appFilterCallback
                        });
                    });
                    filterCategory.values = categoryValues;
                }
                if (filterCategory.name === 'Category') {
                    const categoryValues: IFilterCategoryValue[] = [];
                    categories.forEach((category) => {
                        categoryValues.push({
                            key: category,
                            name: category,
                            callback: categoryFilterCallback
                        });
                    });
                    filterCategory.values = categoryValues;
                }
            });
            setFilterCategories([...filterCategories]);
        }
    }, [fetchedOutcomes]);

    const onFilterValueChange = (
        filterCategories: IFilterCategories[],
        newActiveFilters?: string[],
        dontRunAnythingChange?: boolean,
        outcomesToFilter?: IUserOutcomeVM[] // use this array for filtering if not undefined, else use current filteredSkills from this context
    ) => {
        let newFilteredOutcomes: IUserOutcomeVM[] = [];
        if (outcomesToFilter) newFilteredOutcomes = outcomesToFilter;
        else newFilteredOutcomes = [...outcomes];
        let currentActiveFilters = activeFiltersRef.current;
        if (newActiveFilters) {
            currentActiveFilters = newActiveFilters;
            activeFiltersRef.current = newActiveFilters;
        }
        filterCategories.forEach((filterCategory) => {
            filterCategory.values!.forEach((filterCategoryValue) => {
                if (filterCategoryValue.callback && currentActiveFilters.includes(filterCategoryValue.key)) {
                    newFilteredOutcomes = newFilteredOutcomes.filter((outcome) => {
                        if (filterCategoryValue.callback) {
                            const isValid = filterCategoryValue.callback(
                                outcome,
                                filterCategoryValue.name,
                                filterCategoryValue.key
                            );
                            return isValid;
                        }
                        return false;
                    });
                }
            });
        });
        if (!dontRunAnythingChange) onAnythingChange(newFilteredOutcomes, true);
        return newFilteredOutcomes;
    };

    const refetchData = useCallback(async () => {
        try {
            setSkillsStatusOverallInfo((skillsStatusOverallInfo) => {
                return skillsStatusOverallInfo.map((skill) => {
                    return {
                        ...skill,
                        isLoading: true
                    };
                });
            });
            await refetch();
            setSkillsStatusOverallInfo((skillsStatusOverallInfo) => {
                return skillsStatusOverallInfo.map((skill) => {
                    return {
                        ...skill,
                        isLoading: false
                    };
                });
            });
        } catch (e) {}
    }, [refetch]);

    const findAssignedOutcomes = (outcomesList: IUserOutcomeVM[]) => {
        return outcomesList.filter((outcome) => outcome.isAssigned);
    };

    const findInProgressOutcomes = (outcomesList: IUserOutcomeVM[]) => {
        return outcomesList.filter((outcome) => {
            if (!outcome.isAssigned) return false;
            return (
                (outcome.assignment.level === ESkillLevel.MASTER &&
                    outcome.assignment.masterState === ECompetenceState.IN_PROGRESS) ||
                (outcome.assignment.level === ESkillLevel.PRODUCER &&
                    outcome.assignment.producerState === ECompetenceState.IN_PROGRESS)
            );
        });
    };

    const findNeedsAttentionOutcomes = (outcomesList: IUserOutcomeVM[]) => {
        return outcomesList.filter((outcome) => {
            return (
                (outcome.assignment.level === ESkillLevel.MASTER &&
                    outcome.assignment.masterState === ECompetenceState.NEEDS_ATTENTITON) ||
                (outcome.assignment.level === ESkillLevel.PRODUCER &&
                    outcome.assignment.producerState === ECompetenceState.NEEDS_ATTENTITON)
            );
        });
    };

    const findAttainedOutcomes = (outcomesList: IUserOutcomeVM[]) => {
        return outcomesList.filter((outcome) => {
            return (
                (outcome.assignment.level === ESkillLevel.MASTER &&
                    (outcome.assignment.masterState === ECompetenceState.MAINTAIN ||
                        outcome.assignment.masterState === ECompetenceState.NEEDS_ATTENTITON)) ||
                (outcome.assignment.level === ESkillLevel.PRODUCER &&
                    (outcome.assignment.producerState === ECompetenceState.MAINTAIN ||
                        outcome.assignment.producerState === ECompetenceState.NEEDS_ATTENTITON))
            );
        });
    };

    const filterOutcomesBySkillStatusOverall = (outcomes: IUserOutcomeVM[]) => {
        if (outcomes) {
            let newOutcomes = [...outcomes];

            activeOutcomesStatusOverallFiltersRef.current.forEach((statusFilter) => {
                switch (statusFilter) {
                    case EOutcomesStatuses.ASSIGNED_OUTCOMES:
                        newOutcomes = findAssignedOutcomes(newOutcomes);
                        break;
                    case EOutcomesStatuses.IN_PROGRESS:
                        newOutcomes = findInProgressOutcomes(newOutcomes);
                        break;
                    case EOutcomesStatuses.UNCLAIMED_BADGES:
                        newOutcomes = newOutcomes.filter(
                            (outcome) =>
                                (outcome.assignment.level === ESkillLevel.MASTER &&
                                    (outcome.masterLevelBadge?.state === EBadgeLevel.EARNED ||
                                        outcome.producerLevelBadge?.state === EBadgeLevel.EARNED)) ||
                                (outcome.assignment.level === ESkillLevel.PRODUCER &&
                                    outcome.producerLevelBadge?.state === EBadgeLevel.EARNED)
                        );
                        break;
                    case EOutcomesStatuses.NEED_ATTENTION:
                        newOutcomes = findNeedsAttentionOutcomes(newOutcomes);
                        break;
                    case EOutcomesStatuses.ATTAINED:
                        newOutcomes = findAttainedOutcomes(newOutcomes);
                        break;
                }
            });
            return newOutcomes;
        }
        return outcomes;
    };

    const calculateSkillStatusOverallInfo = (filterOutcomes?: IUserOutcomeVM[]) => {
        const outcomesToFilter = filterOutcomes || outcomes;
        if (outcomesToFilter) {
            let numberOfAssignedOutcomes: number = 0;
            let numberOfInProgressOutcomes: number = 0;
            let numberOfNeedsAttentionOutcomes: number = 0;
            let numberOfAttainedOutcomes: number = 0;
            let numberOfUnclaimedBadgesOutcomes: number = 0;
            numberOfAssignedOutcomes = findAssignedOutcomes(outcomesToFilter).length;
            numberOfInProgressOutcomes = findInProgressOutcomes(outcomesToFilter).length;
            numberOfNeedsAttentionOutcomes = findNeedsAttentionOutcomes(outcomesToFilter).length;
            numberOfAttainedOutcomes = findAttainedOutcomes(outcomesToFilter).length;
            outcomesToFilter.forEach((outcome) => {
                if (outcome.assignment.level === ESkillLevel.PRODUCER) {
                    if (outcome.producerLevelBadge?.state === EBadgeLevel.EARNED) numberOfUnclaimedBadgesOutcomes++;
                }
                if (outcome.assignment.level === ESkillLevel.MASTER) {
                    if (outcome.producerLevelBadge?.state === EBadgeLevel.EARNED) numberOfUnclaimedBadgesOutcomes++;
                    if (outcome.masterLevelBadge?.state === EBadgeLevel.EARNED) numberOfUnclaimedBadgesOutcomes++;
                }
            });
            setSkillsStatusOverallInfo((currentSkillsStatusOverall) => {
                let newSkillsStatusOverall = [...currentSkillsStatusOverall];
                newSkillsStatusOverall = newSkillsStatusOverall.map((sso) => {
                    switch (sso.key) {
                        case EOutcomesStatuses.ASSIGNED_OUTCOMES:
                            sso.value = numberOfAssignedOutcomes;
                            break;
                        case EOutcomesStatuses.UNCLAIMED_BADGES:
                            sso.value = numberOfUnclaimedBadgesOutcomes;
                            break;
                        case EOutcomesStatuses.IN_PROGRESS:
                            sso.value = numberOfInProgressOutcomes;
                            break;
                        case EOutcomesStatuses.NEED_ATTENTION:
                            sso.value = numberOfNeedsAttentionOutcomes;
                            break;
                        case EOutcomesStatuses.ATTAINED:
                            sso.value = numberOfAttainedOutcomes;
                            break;
                    }
                    return {
                        ...sso,
                        isLoading: false
                    };
                });
                return newSkillsStatusOverall;
            });
        }
    };

    const onAnythingChange = (outcomesNeedToBeFiltered: IUserOutcomeVM[], runSearchText?: boolean) => {
        let newFilteredOutcomes = [...outcomesNeedToBeFiltered];
        newFilteredOutcomes = filterOutcomesBySkillStatusOverall(newFilteredOutcomes);
        if (runSearchText) {
            newFilteredOutcomes = instantFilterByText(searchText, newFilteredOutcomes);
            newFilteredOutcomes = sortByOrder(newFilteredOutcomes);
            newFilteredOutcomes = onFilterValueChange(filterCategories, undefined, true, newFilteredOutcomes);
            setFilteredOutcomes(newFilteredOutcomes);
        } else {
            newFilteredOutcomes = sortByOrder(newFilteredOutcomes);
            newFilteredOutcomes = onFilterValueChange(filterCategories, undefined, true, newFilteredOutcomes);
            setFilteredOutcomes(newFilteredOutcomes);
        }
        return newFilteredOutcomes;
    };

    useEffect(() => {
        onAnythingChange(outcomes, true);
    }, [activeOutcomesStatusOverallFilters, filterCategories, sortOrder, outcomes]);

    useEffect(() => {
        calculateSkillStatusOverallInfo(outcomes);
    }, [outcomes]);

    const emptyQuickFilters = useCallback(() => {
        setActiveOutcomesStatusOverallFilters([]);
    }, []);

    const successRecommendationCallback = () => {
        refetchData();
    };

    const { searchText, setSearchText, instantFilterByText } = useFilterSearch<IUserOutcomeVM>({
        data: outcomes,
        dataSerachablePropertyName: 'title',
        onChangeCallback: onAnythingChange,
        setDataCallback: setFilteredOutcomes
    });
    const { searchText: headerInputSearchText } = useTabsStateValue();
    useEffect(() => {
        setSearchText(headerInputSearchText);
    }, [headerInputSearchText]);

    const contextState: OutcomesContextProps = {
        outcomes: filteredOutcomes,
        isOutcomesLoading: isOutcomesLoading || isOutcomesRefetching,
        skillsStatusOverallInfo,
        activeOutcomesStatusOverallFilters,
        changeSkillStatusOverallActiveFilters,
        onFilterValueChange,
        sortOrder,
        setSortOrder,
        isError,
        refetchData,
        filterCategories,
        setFilteredOutcomes,
        toggleAssignCallback,
        emptyQuickFilters,
        isPostAssignOutcomeLoading,
        isFetchedDataProcessed,
        recommendations: fetchedRecommendationsData,
        isRecommendationsLoading,
        successRecommendationCallback
    };

    return <OutcomesContext.Provider value={contextState}>{children}</OutcomesContext.Provider>;
};

export const useOutcomesStateValue: () => OutcomesContextProps = () => useContext(OutcomesContext);

export default OutcomesContext;
