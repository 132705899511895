import { createContext, FC, useContext, useState, useEffect, PropsWithChildren, useRef } from 'react';
import { useParams } from 'react-router';
import { IFilterCategories, IFilterCategoryValue } from '../ui/filters/filters/Filters';
import {
    adminHistoryDetailsCategories,
    adminHistoryDetailsByDepartmentFilterCallback,
    adminHistoryDetailsByStatusFilterCallback,
} from './util/filterCategories';
import useFilterSearch from '../hooks/useFilterSearch';
import { useTabsStateValue } from './TabsContext';
import { useGetTenantJobTaskStatusQuery } from '../services/TenantQueryService';
import { ITenantJobTaskStatusVM } from '../interfaces/views/ITenantJobTaskStatusVM';
import { useAdminHistoryStateValue } from './AdminHistoryContext';
import { ITenantHistoryVM } from '../interfaces/views/ITenantHistoryVM';

export interface IAdminHistoryDetailsContext {
    historyDetailsData: ITenantJobTaskStatusVM[];
    onFilterValueChange: (
        filterCategories: IFilterCategories[],
        activeFilters: string[],
        dontRunAnythingChange?: boolean
    ) => void;
    searchText: string;
    filterCategories: IFilterCategories[];
    selectedAdminHistoryItem?: ITenantHistoryVM;
    setSelectedAdminHistoryItem: (selectedItem: ITenantHistoryVM | undefined) => void;
    isTenantJobTaskStatusLoading: boolean;
    isTenantHistoryDataLoading: boolean;
    refetchTenantJobTaskStatus: () => void;
    refetchTenantHistoryData: () => void;
}

export const AdminHistoryDetailsContext = createContext<IAdminHistoryDetailsContext>({} as IAdminHistoryDetailsContext);

interface IProps {}

export const AdminHistoryDetailsProvider: FC<PropsWithChildren<IProps>> = ({ children }) => {
    const [historyDetailsData, setHistoryDetailsData] = useState<ITenantJobTaskStatusVM[]>([]);
    const [filteredHistoryDetailsData, setFilteredHistoryDetailsData] = useState<ITenantJobTaskStatusVM[]>([]);
    const [filterCategories, setFilterCategories] = useState<IFilterCategories[]>(adminHistoryDetailsCategories);
    const [selectedAdminHistoryItem, setSelectedAdminHistoryItem] = useState<ITenantHistoryVM | undefined>();
    const activeFiltersRef = useRef<string[]>([]);
    const params = useParams<{ id?: string }>();
    const {
        data: fetchedTenantJobTaskStatusData,
        refetch: refetchTenantJobTaskStatus,
        isFetching: isTenantJobTaskStatusFetching,
    } = useGetTenantJobTaskStatusQuery(params?.id);
    const { historyData, refetchTenantHistoryData, isTenantHistoryDataLoading } = useAdminHistoryStateValue();

    useEffect(() => {
        if (params.id) {
            refetchTenantJobTaskStatus();
            refetchTenantHistoryData();
        }
    }, [params]);

    useEffect(() => {
        if (params.id) {
            const historyItem = historyData?.find((historyItem) => {
                return historyItem.id === params.id;
            });
            setSelectedAdminHistoryItem(historyItem);
        }
    }, [historyData]);

    useEffect(() => {
        if (fetchedTenantJobTaskStatusData) {
            setFilteredHistoryDetailsData(fetchedTenantJobTaskStatusData);
            setHistoryDetailsData(fetchedTenantJobTaskStatusData);
        }
    }, [fetchedTenantJobTaskStatusData]);

    const onAnythingChange = (
        adminHistoryDetailsNeedToBeFiltered: ITenantJobTaskStatusVM[],
        runSearchText?: boolean
    ) => {
        let newFilteredAdminHistoryDetails = [...adminHistoryDetailsNeedToBeFiltered];

        if (runSearchText) {
            newFilteredAdminHistoryDetails = instantFilterByText(searchText, newFilteredAdminHistoryDetails);
            newFilteredAdminHistoryDetails = onFilterValueChange(
                filterCategories,
                undefined,
                true,
                newFilteredAdminHistoryDetails
            );
            setFilteredHistoryDetailsData(newFilteredAdminHistoryDetails);
        } else {
            newFilteredAdminHistoryDetails = onFilterValueChange(
                filterCategories,
                undefined,
                true,
                newFilteredAdminHistoryDetails
            );
            setFilteredHistoryDetailsData(newFilteredAdminHistoryDetails);
        }
        return newFilteredAdminHistoryDetails;
    };
    useEffect(() => {
        if (historyDetailsData) {
            setFilteredHistoryDetailsData(historyDetailsData);
            const statuses = new Set<string>();
            historyDetailsData.forEach((historyDetailsDataItem) => {
                if (historyDetailsDataItem.status) statuses.add(historyDetailsDataItem.status);
            });
            const departments = new Set<string>();
            historyDetailsData.forEach((historyDetailsDataItem) => {
                departments.add(historyDetailsDataItem.department);
            });

            filterCategories.forEach((filterCategory) => {
                if (filterCategory.radioGroupId === 'status') {
                    const categoryValues: IFilterCategoryValue[] = [];
                    statuses.forEach((status) => {
                        categoryValues.push({
                            key: status,
                            name: status,
                            callback: adminHistoryDetailsByStatusFilterCallback,
                        });
                    });
                    filterCategory.values = categoryValues;
                }
                if (filterCategory.radioGroupId === 'department') {
                    const categoryValues: IFilterCategoryValue[] = [];
                    departments.forEach((department) => {
                        categoryValues.push({
                            key: department,
                            name: department,
                            callback: adminHistoryDetailsByDepartmentFilterCallback,
                        });
                    });
                    filterCategory.values = categoryValues;
                }
            });
            setFilterCategories([...filterCategories]);
        }
    }, [historyDetailsData]);

    const { searchText, setSearchText, instantFilterByText } = useFilterSearch({
        data: historyDetailsData,
        dataSerachablePropertyName: 'userName',
        onChangeCallback: onAnythingChange,
        setDataCallback: setFilteredHistoryDetailsData,
    });
    const { searchText: headerInputSearchText } = useTabsStateValue();
    useEffect(() => {
        setSearchText(headerInputSearchText);
    }, [headerInputSearchText]);

    useEffect(() => {
        if (historyDetailsData) onAnythingChange(historyDetailsData, true);
    }, [filterCategories, historyDetailsData, searchText]);

    const onFilterValueChange = (
        filterCategories: IFilterCategories[],
        newActiveFilters?: string[],
        dontRunAnythingChange?: boolean,
        historyDetailsToFilter?: ITenantJobTaskStatusVM[]
    ) => {
        let newFilteredHistoryDetailsData = [...historyDetailsData];
        if (historyDetailsToFilter) newFilteredHistoryDetailsData = historyDetailsToFilter;
        let currentActiveFilters = activeFiltersRef.current;
        if (newActiveFilters) {
            currentActiveFilters = newActiveFilters;
            activeFiltersRef.current = newActiveFilters;
        }
        filterCategories.forEach((filterCategory) => {
            if (filterCategory.values) {
                filterCategory.values!.forEach((filterCategoryValue) => {
                    if (filterCategoryValue.callback && currentActiveFilters.includes(filterCategoryValue.key)) {
                        newFilteredHistoryDetailsData = newFilteredHistoryDetailsData.filter(
                            (historyDetailsDataItem) => {
                                if (filterCategoryValue.callback) {
                                    const isValid = filterCategoryValue.callback(
                                        historyDetailsDataItem,
                                        filterCategoryValue.name,
                                        filterCategoryValue.key
                                    );
                                    return isValid;
                                }
                                return false;
                            }
                        );
                    }
                });
            }
        });
        if (!dontRunAnythingChange) onAnythingChange(newFilteredHistoryDetailsData, true);
        return newFilteredHistoryDetailsData;
    };

    const adminHistoryDetailsContext: IAdminHistoryDetailsContext = {
        historyDetailsData: filteredHistoryDetailsData,
        onFilterValueChange,
        searchText,
        filterCategories,
        selectedAdminHistoryItem,
        isTenantJobTaskStatusLoading: isTenantJobTaskStatusFetching,
        isTenantHistoryDataLoading,
        setSelectedAdminHistoryItem,
        refetchTenantHistoryData,
        refetchTenantJobTaskStatus,
    };

    return (
        <AdminHistoryDetailsContext.Provider value={adminHistoryDetailsContext}>
            {children}
        </AdminHistoryDetailsContext.Provider>
    );
};

export const useAdminHistoryDetailsStateValue: () => IAdminHistoryDetailsContext = () => {
    return useContext(AdminHistoryDetailsContext);
};
