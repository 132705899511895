import { FC } from 'react';
import { Award, BarChart2, Briefcase, Home, Settings, Star } from 'react-feather';
import NavigationLinkItem from '../navigation-link-item/NavigationLinkItem';
import OutcomesIcon from '../../../assets/icons/OutcomesIcon';
import { routes } from '../../../pages/routes';
import { isUserAllowedToAccess, routeRules } from '../../../pages/routeRules';
import { ERoute } from '../../../interfaces/enums/ERoute';
import { useUserAuthStateValue } from '../../../contexts/UserAuthContext';
import HowToNavigationIcon from '../../../assets/icons/HowToNavigationIcon';

const navigationLinks = [
    {
        id: 'navigation-home-button',
        title: 'Home',
        icon: <Home key="home-icon" />,
        paths: [routes.HOME, '/'],
        routeRules: [ERoute.HOME],
        primaryPath: routes.HOME,
    },
    {
        id: 'navigation-skills-button',
        title: 'Skills',
        icon: <Star key="skills-icon" />,
        paths: [routes.SKILLS, routes.SKILL],
        routeRules: [ERoute.SKILLS, ERoute.SKILL],
    },
    {
        id: 'navigation-outcomes-button',
        title: 'Outcomes',
        icon: <OutcomesIcon key="outcomes-icon" />,
        paths: [routes.OUTCOME, routes.ADMIN_OUTCOMES, routes.USER_OUTCOMES, routes.HISTORY_OUTCOMES],
        routeRules: [ERoute.USER_OUTCOMES, ERoute.ADMIN_OUTCOMES, ERoute.OUTCOMES_HISTORY, ERoute.OUTCOMES],
    },
    {
        id: 'navigation-admin-button',
        title: 'Admin',
        icon: <Briefcase key="admin-icon" />,
        paths: [routes.ADMIN_LICENSES, routes.ADMIN_ROLES, routes.ADMIN_HISTORY, routes.ADMIN_HISTORY_DETAILS],
        routeRules: [ERoute.ADMIN_LICENSES, ERoute.ADMIN_ROLES, ERoute.ADMIN_HISTORY],
    },
    {
        id: 'navigation-showcase-button',
        title: 'Showcase',
        icon: <Award key="award-icon" />,
        paths: [routes.SHOWCASE_HOME],
        routeRules: [ERoute.SHOWCASE_HOME],
    },
    {
        id: 'navigation-how-tos-button',
        title: "How To's",
        icon: <HowToNavigationIcon />,
        paths: [routes.HOW_TOS_HOMEPAGE],
        routeRules: [ERoute.HOW_TOS_HOMEPAGE],
    },
    {
        id: 'navigation-insights-button',
        title: 'Insights',
        icon: <BarChart2 key="chart-icon" />,
        routeRules: [
            ERoute.INSIGHTS_PERSONAL_SKILLS,
            ERoute.INSIGHTS_PERSONAL_OUTCOMES,
            ERoute.INSIGHTS_PERSONAL_ENGAGEMENT,
            ERoute.INSIGHTS_PERSONAL_BADGES,
            ERoute.INSIGHTS_ORGANIZATION_USERS,
            ERoute.INSIGHTS_ORGANIZATION_OUTCOMES,
            ERoute.INSIGHTS_ORGANIZATION_ENGAGEMENT,
            ERoute.INSIGHTS_ORGANIZATION_BADGES,
            ERoute.INSIGHTS_ORGANIZATION_ASSESSMENT,
            ERoute.INSIGHTS_ORGANIZATION_USER_BADGES,
            ERoute.INSIGHTS_ORGANIZATION_USER_ENGAGEMENT,
            ERoute.INSIGHTS_ORGANIZATION_USER_OUTCOMES,
            ERoute.INSIGHTS_ORGANIZATION_USER_SKILLS,
        ],
        paths: [
            routes.INSIGHTS_PERSONAL_SKILLS,
            routes.INSIGHTS_PERSONAL_OUTCOMES,
            routes.INSIGHTS_PERSONAL_ENGAGEMENT,
            routes.INSIGHTS_PERSONAL_BADGES,
            routes.INSIGHTS_ORGANIZATIONAL_USERS,
            routes.INSIGHTS_ORGANIZATIONAL_OUTCOMES,
            routes.INSIGHTS_ORGANIZATIONAL_ENGAGEMENT,
            routes.INSIGHTS_ORGANIZATIONAL_BADGES,
            routes.INSIGHTS_ORGANIZATIONAL_ASSESSMENT,
            routes.INSIGHTS_ORGANIZATIONAL_USER_BADGES,
            routes.INSIGHTS_ORGANIZATIONAL_USER_ENGAGEMENT,
            routes.INSIGHTS_ORGANIZATIONAL_USER_OUTCOMES,
            routes.INSIGHTS_ORGANIZATIONAL_USER_SKILLS,
            routes.INSIGHTS_ORGANIZATIONAL_OUTCOMES_DETAILS,
            routes.INSIGHTS_PERSONAL,
            routes.INSIGHTS_ORGANIZATIONAL,
        ],
    },
    {
        id: 'navigation-settings-button',
        title: 'Settings',
        icon: <Settings key="settings-icon" />,
        routeRules: [ERoute.SETTINGS_PERSONAL, ERoute.SETTINGS_ORGANIZATIONAL],
        paths: [routes.SETTINGS_PERSONAL, routes.SETTINGS_ORGANIZATIONAL, routes.SETTINGS],
    },
];

const NavigationLinks: FC = () => {
    const { currentUserData } = useUserAuthStateValue();
    return (
        <div>
            {navigationLinks
                .filter((link) => {
                    let isAllowed = false;
                    link.routeRules?.forEach((routeRule: ERoute) => {
                        if (
                            routeRules[routeRule].rules.find((rule) => {
                                return currentUserData?.roles
                                    ? isUserAllowedToAccess(
                                          routeRule,
                                          currentUserData?.roles,
                                          currentUserData?.permissions
                                      )
                                    : false;
                            })
                        )
                            isAllowed = true;
                    });
                    return isAllowed;
                })
                .map((link) => {
                    const primaryRouteRule: ERoute | undefined = link.routeRules?.find((routeRule) => {
                        if (
                            routeRule &&
                            currentUserData &&
                            isUserAllowedToAccess(routeRule, currentUserData.roles, currentUserData.permissions)
                        ) {
                            return true;
                        }
                        return false;
                    });
                    return (
                        <NavigationLinkItem
                            key={link.id}
                            title={link.title}
                            icon={link.icon}
                            primaryPath={primaryRouteRule ? routeRules[primaryRouteRule].route : ''}
                            paths={link.paths}
                        ></NavigationLinkItem>
                    );
                })}
        </div>
    );
};

export default NavigationLinks;
