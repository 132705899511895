import { createContext, FC, useContext, PropsWithChildren, useEffect, useState, useRef, useCallback } from 'react';
import { isBefore } from 'date-fns';
import { useTheme } from '@mui/system';
import { useParams } from 'react-router';
import { useGetInsightsOrganizationalUsersForBadgeQuery } from '../services/InsightsQueryService';
import { IScoreLine } from '../interfaces/IScoreLine';
import { EGraphCardSelect } from '../interfaces/enums/EGraphCardSelect';
import useFilterSearch from '../hooks/useFilterSearch';
import { IFilterCategories, IFilterCategoryValue } from '../ui/filters/filters/Filters';
import {
    insightsOrganizationBadgesByDepartmentFilterCallback,
    insightsOrganizationBadgesByRegionFilterCallback,
    insightsOrganizationBadgesCategories,
} from './util/filterCategories';
import expandDatesToLastYearPerWeek from '../utils/expandDatesToLastYearPerWeek';
import { IOrganizationalUserForBadgeVM } from '../interfaces/views/IOrganizationalUserForBadgeVM';
import { useInsightsStateValue } from './InsightsContext';

export interface IInsightsOrganizationSingleBadgeContext {
    data: IOrganizationalUserForBadgeVM[];
    latestDateData: IOrganizationalUserForBadgeVM[];
    scoreLines: IScoreLine[];
    searchText: string;
    setSearchText: (searchText: string) => void;
    changeScoreLinesInterval: (option: EGraphCardSelect) => void;
    filterCategories: IFilterCategories[];
    onFilterValueChange: (
        filterCategories: IFilterCategories[],
        activeFilters: string[],
        dontRunAnythingChange?: boolean
    ) => void;
    isBadgesDataLoading: boolean;
}

export const InsightsOrganizationSingleBadgeContext = createContext<IInsightsOrganizationSingleBadgeContext>(
    {} as IInsightsOrganizationSingleBadgeContext
);

export const InsightsOrganizationSingleBadgeProvider: FC<PropsWithChildren> = ({ children }) => {
    // Data used for Insights User Badges graph
    const [data, setData] = useState<IOrganizationalUserForBadgeVM[]>([]);
    const [filteredData, setFilteredData] = useState<IOrganizationalUserForBadgeVM[]>([]);
    // ---

    // Data used for Insights User Badges table
    const [latestDateData, setLatestDateData] = useState<IOrganizationalUserForBadgeVM[]>([]);
    const [filteredLatestDateData, setFilteredLatestDateData] = useState<IOrganizationalUserForBadgeVM[]>([]);
    // ---

    const [filterCategories, setFilterCategories] = useState<IFilterCategories[]>(insightsOrganizationBadgesCategories);
    const [scoreLines, setScoreLines] = useState<IScoreLine[]>([]);
    const scoreLinesAllOptionsRef = useRef<IScoreLine[][]>([]);
    const [_, setBadgesMapByDate] = useState<Map<string, IOrganizationalUserForBadgeVM[]>>(new Map());
    const [filteredBadgesMapByDate, setFilteredBadgesMapByDate] = useState<
        Map<string, IOrganizationalUserForBadgeVM[]>
    >(new Map());
    const [activeFilters, setActiveFilters] = useState<string[]>([]);
    const newestDateRef = useRef<Date>();
    const theme = useTheme();
    const params = useParams<{ outcomeId?: string; badgeLevel?: string }>();

    const {
        data: fetchedOrganizationalUsersForBadgeData,
        refetch: refetchOrganizationalUsersForBadge,
        isLoading: isBadgesDataLoading,
    } = useGetInsightsOrganizationalUsersForBadgeQuery(params.outcomeId, params.badgeLevel);
    const { setSelectedBadgelName } = useInsightsStateValue();

    // useEffect(() => {
    //     if (fetchedOrganizationalUsersForBadgeData) {
    //         setData(fetchedOrganizationalUsersForBadgeData);
    //     }
    // }, [fetchedOrganizationalUsersForBadgeData]);

    useEffect(() => {
        if (params.outcomeId && params.badgeLevel) {
            refetchOrganizationalUsersForBadge();
        }
    }, [params]);

    const generateMapByDateBasedOnData: (
        data: IOrganizationalUserForBadgeVM[]
    ) => Map<string, IOrganizationalUserForBadgeVM[]> | undefined = (data) => {
        if (data) {
            const competenceMapByDate = new Map();
            data.forEach((item) => {
                if (item.date && competenceMapByDate.has(item.date.toDateString())) {
                    competenceMapByDate.set(item.date.toDateString(), [
                        ...competenceMapByDate.get(item.date.toDateString()),
                        item,
                    ]);
                } else {
                    competenceMapByDate.set(item.date.toDateString(), [item]);
                }
            });
            return competenceMapByDate;
        }
        return;
    };

    useEffect(() => {
        if (filteredData) {
            const filteredUserIdList = filteredData.map((item) => item.userId);
            setFilteredLatestDateData(
                latestDateData.filter((dataItem) => {
                    return filteredUserIdList.includes(dataItem.userId);
                })
            );
            const competenceMapByDate = generateMapByDateBasedOnData(
                filteredData.sort((a, b) => (isBefore(a.date as Date, b.date as Date) ? -1 : 1))
            );
            if (competenceMapByDate) {
                setFilteredBadgesMapByDate(competenceMapByDate);
            }
        }
    }, [filteredData]);

    useEffect(() => {
        if (fetchedOrganizationalUsersForBadgeData) {
            if (fetchedOrganizationalUsersForBadgeData.length > 0)
                setSelectedBadgelName(
                    `${fetchedOrganizationalUsersForBadgeData[0].outcomeName} - 
                        ${fetchedOrganizationalUsersForBadgeData[0].level}`
                );
            const mapByDate = new Map();
            fetchedOrganizationalUsersForBadgeData.forEach((item) => {
                if (item.date && mapByDate.has(item.date.toDateString())) {
                    mapByDate.set(item.date.toDateString(), [...mapByDate.get(item.date.toDateString()), item]);
                } else {
                    mapByDate.set(item.date.toDateString(), [item]);
                }
            });
            setBadgesMapByDate(mapByDate);
        }
    }, [fetchedOrganizationalUsersForBadgeData]);

    useEffect(() => {
        if (data) {
            const departments = new Set<string>();
            const regions = new Set<string>();

            data.forEach((dataItem) => {
                if (dataItem.department) departments.add(dataItem.department);
                if (dataItem.region) regions.add(dataItem.region);
            });

            filterCategories.forEach((filterCategory) => {
                if (filterCategory.radioGroupId === 'department') {
                    const categoryValues: IFilterCategoryValue[] = [];
                    departments.forEach((department) => {
                        categoryValues.push({
                            key: department,
                            name: department,
                            callback: insightsOrganizationBadgesByDepartmentFilterCallback,
                        });
                    });
                    filterCategory.values = categoryValues;
                }
                if (filterCategory.radioGroupId === 'region') {
                    const categoryValues: IFilterCategoryValue[] = [];
                    regions.forEach((region) => {
                        categoryValues.push({
                            key: region,
                            name: region,
                            callback: insightsOrganizationBadgesByRegionFilterCallback,
                        });
                    });
                    filterCategory.values = categoryValues;
                }
            });
            setFilterCategories([...filterCategories]);
        }
    }, [data]);

    useEffect(() => {
        if (filteredBadgesMapByDate && filteredBadgesMapByDate.size > 0) {
            const sharedScoreLine: IScoreLine = {
                id: 'W1',
                name: 'Shared',
                color: '#349FEE',
                scores: [],
            };

            const claimedScoreLine: IScoreLine = {
                id: 'W2',
                name: 'Claimed',
                color: theme.palette.status.attained,
                scores: [],
            };

            const readyToClaimScoreLine: IScoreLine = {
                id: 'W3',
                name: 'Ready To Claim',
                color: '#5C4F9C',
                scores: [],
            };

            const needAttentionScoreLine: IScoreLine = {
                id: 'W4',
                name: 'Need Attention',
                color: theme.palette.status.needAttention,
                scores: [],
            };

            filteredBadgesMapByDate.forEach(function (mapValue, mapKey) {
                let sharedPerDateTotal = 0;
                let claimedPerDateTotal = 0;
                let readyToClaimPerDateTotal = 0;
                let needAttentionPerDateTotal = 0;

                mapValue.forEach((mapItem) => {
                    sharedPerDateTotal += mapItem.shared;
                    claimedPerDateTotal += mapItem.claimed;
                    readyToClaimPerDateTotal += mapItem.readyToClaim;
                    needAttentionPerDateTotal += mapItem.needAttention;
                });

                sharedScoreLine.scores.push({
                    date: mapValue[0].date,
                    value: sharedPerDateTotal,
                });

                claimedScoreLine.scores.push({
                    date: mapValue[0].date,
                    value: claimedPerDateTotal,
                });

                readyToClaimScoreLine.scores.push({
                    date: mapValue[0].date,
                    value: readyToClaimPerDateTotal,
                });

                needAttentionScoreLine.scores.push({
                    date: mapValue[0].date,
                    value: needAttentionPerDateTotal,
                });

                const allIndividualScoreTimes = [
                    expandDatesToLastYearPerWeek(sharedScoreLine),
                    expandDatesToLastYearPerWeek(claimedScoreLine),
                    expandDatesToLastYearPerWeek(readyToClaimScoreLine),
                    expandDatesToLastYearPerWeek(needAttentionScoreLine),
                ];
                const scoreLines4Month = allIndividualScoreTimes.map((scoreTime) => {
                    return {
                        ...scoreTime,
                        scores: scoreTime.scores.slice(-15),
                    };
                });
                setScoreLines(scoreLines4Month);

                scoreLinesAllOptionsRef.current = [scoreLines4Month, allIndividualScoreTimes];
            });
        }
    }, [filteredBadgesMapByDate]);

    const changeScoreLinesInterval = (option: EGraphCardSelect) => {
        if (option === EGraphCardSelect.MONTH_4) {
            setScoreLines(scoreLinesAllOptionsRef.current[0]);
        } else if (option === EGraphCardSelect.YEAR_WITH_WEEKS) {
            setScoreLines(scoreLinesAllOptionsRef.current[1]);
        }
    };

    const onAnythingChange: any = useCallback(
        (organizationBadgesDataNeedToBeFiltered: IOrganizationalUserForBadgeVM[], runSearchText?: boolean) => {
            let newFilteredBadgesData = [...organizationBadgesDataNeedToBeFiltered];

            if (runSearchText) {
                newFilteredBadgesData = instantFilterByText(searchText, newFilteredBadgesData);
                setFilteredData(newFilteredBadgesData);
            } else {
                onFilterValueChange(filterCategories, activeFilters, true);
                setFilteredData(newFilteredBadgesData);
            }
            return newFilteredBadgesData;
        },
        [
            fetchedOrganizationalUsersForBadgeData,
            // @ts-ignore
            searchText,
            // @ts-ignore
            activeFilters,
            filterCategories,
        ]
    );

    const onFilterValueChange = (
        filterCategories: IFilterCategories[],
        activeFilters: string[],
        dontRunAnythingChange?: boolean
    ) => {
        setActiveFilters(activeFilters);
        let newFilteredData = [...data];
        filterCategories.forEach((filterCategory) => {
            if (filterCategory.values) {
                filterCategory.values!.forEach((filterCategoryValue) => {
                    if (filterCategoryValue.callback && activeFilters.includes(filterCategoryValue.key)) {
                        newFilteredData = newFilteredData.filter((dataItem) => {
                            if (filterCategoryValue.callback) {
                                const isValid = filterCategoryValue.callback(
                                    dataItem,
                                    filterCategoryValue.name,
                                    filterCategoryValue.key
                                );
                                return isValid;
                            }
                            return false;
                        });
                    }
                });
            }
        });
        if (!dontRunAnythingChange) onAnythingChange(newFilteredData, true);
    };

    const { searchText, setSearchText, instantFilterByText } = useFilterSearch<IOrganizationalUserForBadgeVM>({
        data: data,
        dataSerachablePropertyName: 'displayName',
        setDataCallback: setFilteredData,
        onChangeCallback: onAnythingChange,
    });

    useEffect(() => {
        if (fetchedOrganizationalUsersForBadgeData && fetchedOrganizationalUsersForBadgeData.length > 0) {
            setData(fetchedOrganizationalUsersForBadgeData);
            setFilteredData(fetchedOrganizationalUsersForBadgeData);
            const newestDate = fetchedOrganizationalUsersForBadgeData.reduce((a, b) => {
                return a.date > b.date ? a : b;
            }).date;
            newestDateRef.current = newestDate;

            const latestDataItems = fetchedOrganizationalUsersForBadgeData.filter((dataItem) => {
                if (dataItem.date.getTime() === newestDate.getTime()) return true;
                return false;
            });
            setLatestDateData(latestDataItems);
            setFilteredLatestDateData(latestDataItems);
        }
    }, [fetchedOrganizationalUsersForBadgeData]);

    const insightsOrganizationSingleBadgeContext: IInsightsOrganizationSingleBadgeContext = {
        data: filteredData,
        latestDateData: filteredLatestDateData,
        scoreLines,
        searchText,
        setSearchText,
        changeScoreLinesInterval,
        filterCategories,
        onFilterValueChange,
        isBadgesDataLoading,
    };

    return (
        <InsightsOrganizationSingleBadgeContext.Provider value={insightsOrganizationSingleBadgeContext}>
            {children}
        </InsightsOrganizationSingleBadgeContext.Provider>
    );
};

export const useInsightsOrganizationSingleBadgeStateValue: () => IInsightsOrganizationSingleBadgeContext = () =>
    useContext(InsightsOrganizationSingleBadgeContext);
