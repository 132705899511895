import { Typography } from '@mui/material';
import styled from '../../theme/styled';

export const RootBox = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
});

export const SkillTitleBox = styled('div')({
    width: '100%',
});

export const SkillTitleTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    letterSpacing: '2px',
    paddingLeft: 3,
}));

export const LineHr = styled('hr')(({ theme }) => ({
    background: 'rgba(0, 0, 0, 0.16)',
    marginBottom: 0,
    paddingBottom: 0,
    height: 1,
    position: 'relative',
    top: 1,
    border: 0,
    marginTop: 5,
}));

export const SkillItem = styled('div')({
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
});

export const SkillItemsWrapper = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '28px',
    padding: '18px 3px',
});

export const AppsWrapper = styled('div')({});

export const AppBox = styled('div')({});

export const AppImg = styled('img')({
    width: 22,
    height: 22,
});

export const SkillItemRootTitle = styled('a')(({ theme }) => ({
    color: theme.palette.gray.medium,
    cursor: 'pointer',
    fontFamily: 'Open Sans',
    fontSize: '12px',
    lineHeight: '24px',
    textDecoration: 'none',
    '&:hover': {
        textDecoration: 'underline',
        color: theme.palette.primary.main,
    },
}));
