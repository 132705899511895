import { FC } from 'react';
import NumberStatusBox from '../number-status-box/NumberStatusBox';
import { RootBox } from './Style';

interface IElement {
    elementId: string;
    label: string;
    value?: number | string | null;
    icon?: React.ReactNode;
    circleColor?: string;
    clickHandler?: (elementId: string) => void;
    isActive?: boolean;
    isLoading?: boolean;
    isClickable?: boolean;
}

interface IProps {
    style?: any;
    boxStyle?: any;
    elements: IElement[];
}

const TwoNumberStatusBox: FC<IProps> = ({ elements }) => {
    return (
        <RootBox>
            {elements.map((element) => {
                return (
                    <NumberStatusBox
                        key={element.label}
                        label={element.label}
                        circleColor={element.circleColor}
                        clickHandler={element.clickHandler}
                        icon={element.icon}
                        elementId={element.elementId}
                        isClickable={element.isClickable}
                        value={element.value}
                        isActive={element.isActive}
                        isLoading={element.isLoading}
                    ></NumberStatusBox>
                );
            })}
        </RootBox>
    );
};

export default TwoNumberStatusBox;
