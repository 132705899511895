import { FC } from 'react';
import UserPlaceholderIcon from '../../../assets/icons/UserPlaceholderIcon';
import { RootContainer } from './Style';

interface Props {
    size?: number;
}

const DefaultUserImage: FC<Props> = ({ size }) => {
    return (
        <RootContainer style={size ? { height: size, minHeight: size, width: size, minWidth: size } : undefined}>
            <UserPlaceholderIcon />
        </RootContainer>
    );
};

export default DefaultUserImage;
