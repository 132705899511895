import { styled } from '@mui/system';
import FormControl from '@mui/material/FormControl';
import { RadioGroup, FormControlLabel } from '@material-ui/core';
import { Radio, Typography } from '@mui/material';
import SearchInput from '../../ui/general/search-input/SearchInput';

export const InsightsRootBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('lg')]: {
        marginLeft: 'unset',
        maxWidth: '100%',
        width: '100%',
    },
}));

export const RootBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '20px',
    width: '1060px',
    [theme.breakpoints.down('lg')]: {
        width: '100%',
        marginLeft: 'unset',
    },
}));

export const TabLinkItemBox = styled('div', {
    shouldForwardProp: (prop) => prop !== 'isActive',
})<{ isActive: boolean }>(({ theme, isActive }) => ({
    fontFamily: 'Ubuntu',
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '12px',
    letterSpacing: '1px',
    color: isActive ? theme.palette.primary.main : 'rgba(97, 96, 99, 1)',
    textTransform: 'uppercase',
    cursor: 'pointer',
    textDecoration: isActive ? 'underline' : 'none',
    textDecorationThickness: isActive ? '4px' : 'inherit',
    textUnderlineOffset: isActive ? '20px' : 'inherit',
    padding: '14px 4px',

    '&:hover': {
        background: isActive ? 'inherit' : '#E9E9E9',
        color: isActive ? theme.palette.primary.main : 'rgba(97, 96, 99, 1)',
    },
}));

export const TabBox = styled('div', {
    shouldForwardProp: (prop) => prop !== 'isBackMode' && prop !== 'isNavOpened' && prop !== 'leftSectionWidth',
})<{ isBackMode: boolean; leftSectionWidth?: number; isNavOpened: boolean }>(({ isBackMode, isNavOpened, theme }) => ({
    display: 'grid',
    width: '100%',
    gridTemplateColumns: isBackMode
        ? isNavOpened
            ? `170px auto 200px`
            : `230px auto 200px`
        : isNavOpened
        ? `90px auto 250px`
        : '50px auto 50px',
    [theme.breakpoints.down('lg')]: {
        gridTemplateColumns: isBackMode
            ? isNavOpened
                ? `170px auto 200px`
                : `230px auto 200px`
            : isNavOpened && '50px auto 50px',
    },
    [theme.breakpoints.down(720)]: {
        gridTemplateColumns: isBackMode
            ? isNavOpened
                ? `120px auto 200px`
                : `230px auto 200px`
            : isNavOpened && '50px auto 50px',
    },
    [theme.breakpoints.down('md')]: {
        gridTemplateColumns: isBackMode
            ? isNavOpened
                ? `25px auto 25px`
                : `50px auto 50px`
            : isNavOpened && '50px auto 50px',
    },
}));

export const OutletWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    width: '1080px',
    [theme.breakpoints.down('lg')]: {
        padding: '9px 14px',
        width: '100%',
    },
}));

export const TabInnerGridBox = styled('div')(({ theme }) => ({
    gridColumn: '2',
    display: 'flex',
    justifyContent: 'center',
    gap: '12px',
    paddingBottom: '9px',
    [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap',
    },
    [theme.breakpoints.down('lg')]: {
        paddingBottom: 0,
    },
}));

export const GraphCardToolbarFormControl = styled(FormControl)({
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: '12px',
});

export const ValueBox = styled(Typography)({
    display: 'flex',
    alignItems: 'center',
});

export const GraphCardToolbarFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
    '& .MuiFormControlLabel-label': {
        fontSize: '14px',
        lineHeight: '16px',
        letterSpacing: '1.25px',
        textTransform: 'uppercase',
        fontFamily: 'Ubuntu',
        fontWeight: 500,
        color: theme.palette.gray.light,
        [theme.breakpoints.down('lg')]: {
            textTransform: 'none',
            fontSize: '12px',
            lineHeight: '20px',
            letterSpacing: '0.5px',
            fontWeight: 400,
        },
    },
}));

export const GraphCardToolbarRadioGroup = styled(RadioGroup)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '15px',
    flexWrap: 'nowrap',
    [theme.breakpoints.down('lg')]: {
        gap: '8px',
    },
}));

export const GraphCardToolbarRadio = styled(Radio)({});

export const GraphCardToolbarValueBox = styled(Typography)({
    display: 'flex',
    alignItems: 'center',
});

export const TableFiltersWrapper = styled('div')({
    display: 'flex',
    gap: '20px',
    width: '100%',
});

export const TableWrapper = styled('div', {
    shouldForwardProp: (prop) => prop !== 'useMaxWidthLimit',
})<{ useMaxWidthLimit?: boolean }>(({ useMaxWidthLimit }) => ({
    flex: 4,
    maxWidth: useMaxWidthLimit ? '80%' : '100%',
}));

export const FiltersWrapper = styled('div')({
    flex: 1,
});

export const FullWidthBox = styled('div')({
    width: '100%',
});

export const searchInputInnerStyle = {
    width: '285px',
    background: 'rgba(250, 250, 250, 1)',
};

export const StyledDefaultSearchInput = styled(SearchInput)(({ theme }) => ({
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.08), 0px 0.5px 1.5px rgba(0, 0, 0, 0.08)',
    marginRight: 12,
    [theme.breakpoints.down('md')]: {
        width: 170,
    },
    [theme.breakpoints.down('sm')]: {
        width: 120,
    },
    '& input': {
        background: 'rgba(250, 250, 250, 1)',
    },
}));

export const TableLink = styled(Typography)(({ theme }) => ({
    padding: '10px 0',
    '&:hover': {
        textDecoration: 'underline',
        cursor: 'pointer',
    },
    [theme.breakpoints.down('md')]: {
        fontStyle: 'italic',
    },
}));

export const Dot = styled('div', {
    shouldForwardProp: (prop) => prop !== 'color',
})<{ color: string }>(({ color }) => ({
    width: '13px',
    height: '13px',
    borderRadius: '7px',
    backgroundColor: color,
}));

export const TableItemCountBox = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    padding: '13px 26px',
}));

export const TableItemCountTypography = styled(Typography)(() => ({
    color: 'rgba(0, 0, 0, 0.32)',
}));

export const StyledSearchInput = styled(SearchInput)(({ theme }) => ({
    background: theme.palette.common.white,
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.08), 0px 0.5px 1.5px rgba(0, 0, 0, 0.08)',
    marginRight: 12,
    width: '100%',
    '& input': {
        background: theme.palette.common.white,
    },
}));

export const SearchInputWrapper = styled('div')(() => ({
    width: '100%',
}));
