import styled from '../../../theme/styled';
import { Link } from '@mui/material';

export const StyledLink = styled(Link)({
    cursor: 'pointer',
    textDecoration: 'none',
});

export const NonClickableCrumbRoot = styled('div')(({ theme }) => ({
    color: theme.palette.primary.dark,
    cursor: 'default',
}));
