import { styled } from '@mui/material/styles';
import { Paper } from '@material-ui/core';
import { Typography } from '@mui/material';
import { pulseKeyFrame } from '../../../css/CommonComponents';

export const RootBox = styled('div')({
    width: '100%',
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.08), 0px 0.5px 1.5px rgba(0, 0, 0, 0.08)',
});

export const RootPaper = styled(Paper)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.08), 0px 0.5px 1.5px rgba(0, 0, 0, 0.08)',
    borderRadius: theme.borderRadius?.outside?.small,
}));

export const PaperHeaderBox = styled('div')(({ theme }) => ({
    padding: '8px 25px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.16)',
    fontSize: 18,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down('lg')]: {
        padding: '4px 25px',
    },
}));

export const AboutSkillBox = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    padding: '20px 25px',
    gap: 12,
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column-reverse',
    },
}));

export const AboutSkillInfoBox = styled(Typography)(({ theme }) => ({
    flex: 3,
    fontSize: 15,
    lineHeight: '24px',
    color: 'rgba(0,0,0,0.88)',
    [theme.breakpoints.down('lg')]: {
        fontSize: '12px',
        color: 'rgba(0, 0, 0, 0.8)',
        lineHeight: '20px',
    },
}));

export const AboutSkillInfoDescriptionItem = styled('div')({
    paddingTop: '4px',
});

export const AboutSkillVideoBox = styled('div')(({ theme }) => ({
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& .video-wrapper': {
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        [theme.breakpoints.down('lg')]: {
            backgroundSize: 'contain',
            minWidth: '200px',
        },
        [theme.breakpoints.down('md')]: {
            minWidth: '200px',
            minHeight: '170px',
        },
    },
}));

export const AppIconsBox = styled('div')({
    display: 'flex',
    paddingLeft: 18,
    gap: 18,
    userSelect: 'none',
});

export const InfoBox = styled('div')({
    display: 'flex',
    flex: 1,
    alignItems: 'center',
});

export const AppIconImg = styled('img')(({ theme }) => ({
    width: 32,
    height: 32,
    [theme.breakpoints.down('lg')]: {
        width: 28,
        height: 28,
    },
}));

export const TitleSpan = styled('span')(({ theme }) => ({
    fontFamily: 'Ubuntu',
    fontSize: 18,
    lineHeight: '22px',
    color: 'rgba(0,0,0,0.88)',
    [theme.breakpoints.down('lg')]: {
        fontSize: '14px',
        color: 'rgba(0, 0, 0, 0.8)',
    },
}));

export const ExpandedCollapsedBox = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    borderRadius: 20,
    padding: '12px 8px',
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: theme.palette.background.default,
    },
}));

export const HeartBox = styled('div', {
    shouldForwardProp: (prop) => prop !== 'disableClick' && prop !== 'isActive',
})<{ disableClick: boolean; isActive: boolean }>(({ theme, disableClick, isActive }) => ({
    padding: 8,
    display: 'flex',
    borderRadius: 20,
    pointerEvents: disableClick ? 'none' : 'inherit',
    cursor: 'pointer',
    animation: isActive ? `${pulseKeyFrame} 0.6s 4 alternate` : 'none',
}));
