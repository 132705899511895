import { styled } from '@mui/material/styles';

export const StyledMain = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
});

export const RootInnerBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '12px',
    maxWidth: '810px',
    width: '100%',
}));

export const AssignmentWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.borderRadius?.outside?.small,
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.08), 0px 0.5px 1.5px rgba(0, 0, 0, 0.08)',
    minHeight: '168px',
}));

export const AssignBox = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
    fontSize: '16px',
    lineHeight: '20px',
    padding: '30px 20px',
    color: 'rgba(0, 0, 0, 0.64)',
});

export const UnassignBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    flex: 1,
    borderTop: `1px solid ${theme.palette.action.disabled}`,
    fontSize: '16px',
    lineHeight: '20px',
    padding: '30px 20px',
    color: 'rgba(0, 0, 0, 0.64)',
}));

export const ValueSpan = styled('span')({
    fontFamily: 'Open Sans',
    fontWeight: 700,
    letterSpacing: '0.25px',
    paddingLeft: '3px',
    paddingRight: '3px',
});
