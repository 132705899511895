import { FC } from 'react';

interface IProps {
    isDisabled: boolean;
    height?: string;
    width?: string;
}

const SwitchRightArrowIcon: FC<IProps> = ({ isDisabled, height, width }) => {
    return (
        <svg
            width={width || '8'}
            height={height || '14'}
            viewBox="0 0 8 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0.292893 12.2929C-0.0976311 12.6834 -0.0976311 13.3166 0.292893 13.7071C0.683418 14.0976 1.31658 14.0976 1.70711 13.7071L7.70711 7.70711C8.09763 7.31658 8.09763 6.68342 7.70711 6.29289L1.70711 0.292893C1.31658 -0.0976305 0.683418 -0.0976305 0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L5.58579 7L0.292893 12.2929Z"
                fill={isDisabled ? 'black' : '#654ea3'}
                fillOpacity={isDisabled ? '0.16' : '1'}
            />
        </svg>
    );
};

export default SwitchRightArrowIcon;
