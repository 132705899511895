import styled from '../../../../ui/theme/styled';

export const RootBox = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
});

export const RootInnerBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: '30px',
    width: 1080,
    paddingLeft: '20px',
    paddingRight: '20px',
    [theme.breakpoints.down('lg')]: {
        width: '100%',
        flexDirection: 'row',
    },
}));

export const TableContainer = styled('div')({
    flex: 11,
});

export const FiltersWrapper = styled('div')({
    flex: 4,
    marginTop: '51px',
});

export const RefreshControlWrapper = styled('div')({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
});

export const RefreshActionBox = styled('div', {
    shouldForwardProp: (prop) => prop !== 'isDisabled',
})<{ isDisabled: boolean }>(({ isDisabled }) => ({
    display: 'flex',
    alignItems: 'center',
    cursor: isDisabled ? 'wait' : 'pointer',
    gap: '6px',
    '&:hover': {
        textDecoration: 'underline',
    },
    '&:focus, &:focus-visible': {
        textDecoration: 'underline',
        outline: 'black auto 1px',
        borderRadius: 0,
    },
}));

export const RefreshSpan = styled('span')<{ color?: string }>(({ theme, color }) => ({
    color: color || theme.palette.primary.main,
    fontFamily: 'Ubuntu',
    fontWeight: 500,
    fontSize: '10px',
    lineHeight: '40px',
    letterSpacing: '1.25px',
    textTransform: 'uppercase',
}));
