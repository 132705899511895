import styled from '../../ui/theme/styled';

export const RootBox = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
});

export const SkillSnapshotWrapper = styled('div')(({ theme }) => ({
    minHeight: '258px',
    [theme.breakpoints.down('lg')]: {
        minHeight: 'unset',
    },
}));

export const MessageOfTheDayWrapper = styled('div')(({ theme }) => ({
    minHeight: '258px',
}));

export const ContentBox = styled('div')(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: '2fr 1fr',
    gridGap: 25,
    width: 1080,
    paddingLeft: 20,
    paddingRight: 20,
    [theme.breakpoints.down('lg')]: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
}));

export const RecommendationWrapper = styled('div')({
    gridColumn: '1/3',
    borderRadius: 4,
    minHeight: 300,
});

export const HighlightsWrapper = styled('div')({
    gridColumn: '1/3',
    borderRadius: 4,
    minHeight: 300,
});

export const ActivityFeedWrapper = styled('div')({
    gridColumn: '1/3',
});
