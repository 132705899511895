import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '@mui/material';
import { useShowcaseStateValue } from '../../contexts/ShowcaseContext';
import { ContentBox, RootBox, TitleBox, TitleTypography, RootInnerBox, ContentItemsBox, PaginationBox } from './Style';
import NoDataCard from '../../ui/cards/no-data-card/NoDataCard';
import BasicCard from '../../ui/cards/basic-card/BasicCard';
import ShowcaseBadge from '../../pages/showcase/components/showcase-badge/ShowcaseBadge';
import PrevNextItem from '../../ui/general/prev-next-item/PrevNextItem';
import { EShowcaseBadgeCategory } from '../../interfaces/enums/EShowcaseBadgeCategory';
import useGridPagination from '../../hooks/useGridPagination';
import { StyledTablePagination } from '../../css/CommonComponents';
import Loading from '../../ui/general/loading/Loading';

const BadgesAllByCategory: FC = () => {
    const { allBadgesCategoryData, showcaseBadgeList, visibleBadgesIndex, changeVisibleBadgesIndexHandler } =
        useShowcaseStateValue();
    const { t } = useTranslation();
    const { visibleFromTo, changeActivePageHandler, activePage, rowsPerPage, changeRowsPerPageHandler } =
        useGridPagination({
            step: 10,
        });

    const contentMemo = useMemo(() => {
        if (allBadgesCategoryData === null) {
            return (
                <ContentItemsBox>
                    <BasicCard title={t('showcase.home.badgeCategoryDoesNotExist')} />
                </ContentItemsBox>
            );
        }
        if (!allBadgesCategoryData || !showcaseBadgeList)
            return (
                <ContentItemsBox isEmpty>
                    <Loading />
                </ContentItemsBox>
            );
        if (showcaseBadgeList && (!allBadgesCategoryData?.badgeList || allBadgesCategoryData.badgeList.length === 0))
            return (
                <ContentItemsBox isEmpty>
                    <NoDataCard textStyle={{ fontSize: 14 }} message={t('showcase.home.noBadges')} noBorderShadow />
                </ContentItemsBox>
            );
        return (
            <ContentItemsBox isEmpty={allBadgesCategoryData?.badgeList?.length === 0}>
                {allBadgesCategoryData?.badgeList?.slice(visibleFromTo.from, visibleFromTo.to).map((badge, index) => {
                    return (
                        <ShowcaseBadge
                            key={badge.id}
                            state={allBadgesCategoryData.state}
                            badgeImage={badge.image}
                            claimedDate={badge.claimedDate}
                            verifiedDate={badge.verifiedDate}
                            inProgressSkillStates={badge.inProgressSkillStates}
                            badgeId={badge.id.toString()}
                            title={badge.title}
                            level={badge.level}
                            outcomeId={badge.outcomeId}
                            userBadgeUrl={badge.userBadgeUrl}
                            index={index}
                        />
                    );
                })}
            </ContentItemsBox>
        );
    }, [allBadgesCategoryData, showcaseBadgeList, visibleFromTo]);

    const titleMemo = useMemo(() => {
        if (allBadgesCategoryData === null) return '';
        if (!showcaseBadgeList)
            return (
                <>
                    <Skeleton width="30%" />
                    <Skeleton width="100px" style={{ justifySelf: 'end' }} />
                </>
            );
        if (allBadgesCategoryData)
            return (
                <>
                    <TitleTypography variant="subtitle1">{allBadgesCategoryData.title}</TitleTypography>
                    <PrevNextItem
                        isNextDisabled={visibleBadgesIndex + 1 >= Object.keys(EShowcaseBadgeCategory).length}
                        isPreviousDisabled={visibleBadgesIndex <= 0}
                        changeHandler={changeVisibleBadgesIndexHandler}
                        infoJsx={
                            allBadgesCategoryData?.badgeList
                                ? `${visibleBadgesIndex + 1} / ${Object.keys(EShowcaseBadgeCategory).length}`
                                : ''
                        }
                    />
                </>
            );
        return <Skeleton width="100px" />;
    }, [allBadgesCategoryData, showcaseBadgeList]);

    return (
        <RootBox>
            <RootInnerBox>
                {allBadgesCategoryData !== null && <TitleBox>{titleMemo}</TitleBox>}
                <ContentBox>
                    {contentMemo}
                    {allBadgesCategoryData && allBadgesCategoryData.badgeList && (
                        <PaginationBox>
                            <StyledTablePagination
                                rowsPerPageOptions={[16]}
                                count={allBadgesCategoryData?.badgeList.length}
                                rowsPerPage={rowsPerPage}
                                page={activePage}
                                onPageChange={changeActivePageHandler}
                                onRowsPerPageChange={changeRowsPerPageHandler}
                                style={{ borderBottom: 'none' }}
                            />
                        </PaginationBox>
                    )}
                </ContentBox>
            </RootInnerBox>
        </RootBox>
    );
};

export default BadgesAllByCategory;
