import { createContext, FC, useContext, useEffect, useState, PropsWithChildren, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { INumberStatusBox } from '../ui/general/number-status-box/NumberStatusBox';
import HeartIcon from '../assets/icons/HeartIcon';
import { useGetMOTWQuery } from '../services/MotwQueryService';
import { IMotwVM } from '../interfaces/views/IMotwVM';
import { useGetUserSkillsSnapshot } from '../services/SkillQueryService';
import { IUserSkillsSnapshotDTO } from '../interfaces/dtos/IUserSkillsSnapshotDTO';
import { useGetUserOutcomesSnapshot } from '../services/OutcomeQueryService';
import { IUserOutcomesSnapshotDTO } from '../interfaces/dtos/IUserOutcomesSnapshotDTO';
import { EOutcomesStatuses } from '../interfaces/enums/EOutcomesStatuses';
import { ESkillsStatuses } from '../interfaces/ESkillsStatuses';
import { routes } from '../pages/routes';
import { usePostUserRecommendations } from '../services/RecommendationService';
import { ERecommendationType } from '../interfaces/enums/ERecommendationType';
import { IRecommendationVM } from '../interfaces/views/IRecommendationVM';
import {
    // useGetHighlightsQuery,
    useGetUserActivityFeedQuery,
} from '../services/ActivityFeedService';
import usePagination from '../hooks/usePagination';
import ProducerAvailableToClaim from '../assets/icons/badge-statuses/ProducerAvailableToClaim';
import { IActivityFeedVM } from '../interfaces/views/IActivityFeedVM';
import { useCrumbsStateValue } from './CrumbsContext';
// import { IHighlightVM } from '../interfaces/views/IHighlightVM';
import { useApiStateValue } from './ApiContext';
import { EApiQueryKey } from '../interfaces/enums/EApiQueryKey';

interface HomeContextProps {
    skillSnapshotItems: INumberStatusBox[];
    outcomeSnapshotItems: INumberStatusBox[];
    recommendationItems?: IRecommendationVM[];
    // highlightsItems?: IHighlightVM[];
    motdItems?: IMotwVM[];
    assignedSkillCount?: number;
    assignedOutcomeCount?: number;
    isRecommendationsLoading: boolean;
    activityFeedData?: IActivityFeedVM[];
    isActivityFeedLoading: boolean;
    loadMoreActivitiesHandler: () => void;
    selectedFilterValue: string[];
    setSelectedFilterValue: (newFilters: string[]) => void;
    activitiesVisibleFromTo: { from: number; to: number };
    isMotdLoading: boolean;
    successRecommendationCallback: () => void;
    isUserSkillsSnapshotDataLoading: boolean;
    isUserOutcomesSnapshotDataLoading: boolean;
    // isHighlightsLoading: boolean;
    isErrorFetchingActivityFeed: boolean;
    refetchActivityFeed: () => void;
    isErrorFetchingMotw: boolean;
    refetchMotw: () => void;
}

const HomeContext = createContext<HomeContextProps>({} as HomeContextProps);

interface IProps {}

export const HomeProvider: FC<PropsWithChildren<IProps>> = ({ children }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const defaultSkillSnapshotItemsRef = useRef([
        {
            elementId: ESkillsStatuses.IN_PROGRESS,
            label: t('skills.statuses.inProgress'),
            circleColor: 'rgba(255, 191, 68, 1)',
            value: null,
            isLoading: true,
            isClickable: true,
            clickHandler: () => {
                startNewCrumbs(
                    {
                        name: 'Skills',
                        pathname: `${routes.SKILLS}?filter=${ESkillsStatuses.IN_PROGRESS}`,
                    },
                    true
                );
                navigate({
                    pathname: routes.SKILLS,
                    search: `?filter=${ESkillsStatuses.IN_PROGRESS}`,
                });
            },
        },
        {
            elementId: ESkillsStatuses.PERSONAL_FOCUS,
            label: t('skills.statuses.personalFocus'),
            icon: <HeartIcon liked />,
            value: null,
            isLoading: true,
            isClickable: true,
            clickHandler: () => {
                startNewCrumbs(
                    {
                        name: 'Skills',
                        pathname: `${routes.SKILLS}?filter=${ESkillsStatuses.PERSONAL_FOCUS}`,
                    },
                    true
                );
                navigate({
                    pathname: routes.SKILLS,
                    search: `?filter=${ESkillsStatuses.PERSONAL_FOCUS}`,
                });
            },
        },
        {
            elementId: ESkillsStatuses.NEED_ATTENTION,
            label: t('skills.statuses.needAttention'),
            circleColor: '#ED8000',
            value: null,
            isLoading: true,
            isClickable: true,
            clickHandler: () => {
                startNewCrumbs(
                    {
                        name: 'Skills',
                        pathname: `${routes.SKILLS}?filter=${ESkillsStatuses.NEED_ATTENTION}`,
                    },
                    true
                );
                navigate({
                    pathname: routes.SKILLS,
                    search: `?filter=${ESkillsStatuses.NEED_ATTENTION}`,
                });
            },
        },
        {
            elementId: ESkillsStatuses.ATTAINED,
            label: t('skills.statuses.attained'),
            value: null,
            isLoading: true,
            isClickable: true,
            circleColor: 'rgba(146, 185, 35, 1)',
            clickHandler: () => {
                startNewCrumbs(
                    {
                        name: 'Skills',
                        pathname: `${routes.SKILLS}?filter=${ESkillsStatuses.ATTAINED}`,
                    },
                    true
                );
                navigate({
                    pathname: routes.SKILLS,
                    search: `?filter=${ESkillsStatuses.ATTAINED}`,
                });
            },
        },
    ]);
    const defaultOutcomeSnapshotItemsRef = useRef([
        {
            elementId: EOutcomesStatuses.IN_PROGRESS,
            label: t('outcomes.statuses.inProgress'),
            circleColor: 'rgba(255, 191, 68, 1)',
            value: null,
            isLoading: true,
            isClickable: true,
            clickHandler: () => {
                startNewCrumbs(
                    {
                        name: 'Outcomes',
                        pathname: `${routes.USER_OUTCOMES}?filter=${EOutcomesStatuses.IN_PROGRESS}`,
                    },
                    true
                );
                navigate({
                    pathname: routes.USER_OUTCOMES,
                    search: `?filter=${EOutcomesStatuses.IN_PROGRESS}`,
                });
            },
        },
        {
            elementId: EOutcomesStatuses.NEED_ATTENTION,
            label: t('outcomes.statuses.needAttention'),
            circleColor: '#ED8000',
            value: null,
            isLoading: true,
            isClickable: true,
            clickHandler: () => {
                startNewCrumbs(
                    {
                        name: 'Outcomes',
                        pathname: `${routes.USER_OUTCOMES}?filter=${EOutcomesStatuses.NEED_ATTENTION}`,
                    },
                    true
                );
                navigate({
                    pathname: routes.USER_OUTCOMES,
                    search: `?filter=${EOutcomesStatuses.NEED_ATTENTION}`,
                });
            },
        },
        {
            elementId: EOutcomesStatuses.ATTAINED,
            label: t('outcomes.statuses.attained'),
            circleColor: 'rgba(146, 185, 35, 1)',
            value: null,
            isLoading: true,
            isClickable: true,
            clickHandler: () => {
                startNewCrumbs(
                    {
                        name: 'Outcomes',
                        pathname: `${routes.USER_OUTCOMES}?filter=${EOutcomesStatuses.ATTAINED}`,
                    },
                    true
                );
                navigate({
                    pathname: routes.USER_OUTCOMES,
                    search: `?filter=${EOutcomesStatuses.ATTAINED}`,
                });
            },
        },
        {
            elementId: EOutcomesStatuses.UNCLAIMED_BADGES,
            label: t('outcomes.statuses.badgesReadyToClaim'),
            icon: <ProducerAvailableToClaim />,
            value: null,
            isLoading: true,
            isClickable: true,
            clickHandler: () => {
                startNewCrumbs(
                    {
                        name: 'Outcomes',
                        pathname: `${routes.USER_OUTCOMES}?filter=${EOutcomesStatuses.UNCLAIMED_BADGES}`,
                    },
                    true
                );
                navigate({
                    pathname: routes.USER_OUTCOMES,
                    search: `?filter=${EOutcomesStatuses.UNCLAIMED_BADGES}`,
                });
            },
        },
    ]);

    const { startNewCrumbs } = useCrumbsStateValue();
    const [skillSnapshotItems, setSkillSnapshotItems] = useState<INumberStatusBox[]>(
        defaultSkillSnapshotItemsRef.current
    );
    const [outcomeSnapshotItems, setOutcomeSnapshotItems] = useState<INumberStatusBox[]>(
        defaultOutcomeSnapshotItemsRef.current
    );
    const [motdItems, setMotdItems] = useState<IMotwVM[] | undefined>();
    const [userSkillsSnapshotData, setUserSkillsSnapshotData] = useState<IUserSkillsSnapshotDTO | undefined>();
    const [userOutcomesSnapshotData, setUserOutcomesSnapshotData] = useState<IUserOutcomesSnapshotDTO | undefined>();
    const { data: fetchedMotwData, isError: isErrorFetchingMotw, refetch: refetchMotwData } = useGetMOTWQuery();
    const {
        data: fetchedUserSkillsSnapshotData,
        isFetching: isUserSkillsSnapshotDataFetching,
        refetch: refetchUserSkillsSnapshotData,
        isRefetching: isUserSkillsSnapshotDataRefetching,
        isError: isErrorFetchingSkillsSnapshot,
    } = useGetUserSkillsSnapshot();
    const {
        data: fetchedUserOutcomesSnapshotData,
        isFetching: isUserOutcomesSnapshotDataFetching,
        refetch: refetchUserOutcomesSnapshotData,
        isRefetching: isUserOutcomesSnapshotDataRefetching,
        isError: isErrorFetchingOutcomesSnapshot,
    } = useGetUserOutcomesSnapshot();
    const [assignedSkillCount, setAssignedSkillCount] = useState<number | undefined>();
    const [assignedOutcomeCount, setAssignedOutcomeCount] = useState<number | undefined>();
    const {
        data: fetchedRecommendationsData,
        mutateAsync: mutateRecommendationsAsync,
        isPending: isRecommendationsLoading,
    } = usePostUserRecommendations([
        ERecommendationType.UPGRADE_SKILL,
        ERecommendationType.ADD_SKILL,
        ERecommendationType.UPGRADE_OUTCOME,
        ERecommendationType.ADD_OUTCOME,
        ERecommendationType.BEHAVIOR,
    ]);
    const {
        data: fetchedActivityFeedData,
        refetch: refetchActivityFeed,
        isRefetching: isActivityFeedRefetching,
        isFetching: isActivityFeedFetching,
        isError: isErrorFetchingActivityFeed,
    } = useGetUserActivityFeedQuery();
    // const [highlightItems, setHighlightItems] = useState<IHighlightVM[] | undefined>();
    // const {
    //     data: fetchedHighlightsData,
    //     isRefetching: isHighlightsRefetching,
    //     isFetching: isHighlightsFetching,
    // } = useGetHighlightsQuery();
    const [filteredActivityFeedData, setFilteredActivityFeedData] = useState<IActivityFeedVM[]>([]);
    const { visibleFromTo, changeMaxValueCallback, changePageCallback, changeVisibleFromToCallback } = usePagination({
        step: 10,
        initialMaxValue: fetchedActivityFeedData ? fetchedActivityFeedData.length : 0,
    });
    const [selectedFilterValue, setSelectedFilterValue] = useState<string[]>(['All']);
    const [isMotdLoading, setMotdLoading] = useState<boolean>(true);
    const { invalidateQueryCache } = useApiStateValue();

    useEffect(() => {
        mutateRecommendationsAsync(undefined);
        invalidateQueryCache(EApiQueryKey.ACTIVITY_FEED_FETCH_USER_ACTIVITY);
        invalidateQueryCache(EApiQueryKey.MOTW_FETCH);
    }, []);

    useEffect(() => {
        setMotdItems(fetchedMotwData?.reverse());
        setTimeout(() => {
            setMotdLoading(false);
        }, 300);
    }, [fetchedMotwData]);

    useEffect(() => {
        if (isErrorFetchingMotw) {
            setMotdItems(undefined);
        }
    }, [isErrorFetchingMotw]);

    // useEffect(() => {
    //     setHighlightItems(fetchedHighlightsData);
    // }, [fetchedHighlightsData]);

    useEffect(() => {
        setUserSkillsSnapshotData(fetchedUserSkillsSnapshotData);
    }, [fetchedUserSkillsSnapshotData]);

    useEffect(() => {
        setUserOutcomesSnapshotData(fetchedUserOutcomesSnapshotData);
    }, [fetchedUserOutcomesSnapshotData]);

    useEffect(() => {
        setSkillSnapshotItems(
            defaultSkillSnapshotItemsRef.current.map((item) => {
                return {
                    ...item,
                    isLoading: false,
                    value: undefined,
                };
            })
        );
    }, [isErrorFetchingSkillsSnapshot]);

    useEffect(() => {
        setOutcomeSnapshotItems(
            defaultOutcomeSnapshotItemsRef.current.map((item) => {
                return {
                    ...item,
                    isLoading: false,
                    value: undefined,
                };
            })
        );
    }, [isErrorFetchingOutcomesSnapshot]);

    useEffect(() => {
        setFilteredActivityFeedData([]);
    }, [isErrorFetchingActivityFeed]);

    useEffect(() => {
        if (isErrorFetchingSkillsSnapshot) return;
        if (userSkillsSnapshotData) {
            const isLoading = isUserSkillsSnapshotDataFetching || isUserSkillsSnapshotDataRefetching;
            setSkillSnapshotItems((skillSnapshotItems) => {
                const inProgressSkillSnapshotItem = skillSnapshotItems.find(
                    (item) => item.elementId === ESkillsStatuses.IN_PROGRESS
                );
                const needAttentionSkillSnapshotItem = skillSnapshotItems.find(
                    (item) => item.elementId === ESkillsStatuses.NEED_ATTENTION
                );
                const personalFocusSkillSnapshotItem = skillSnapshotItems.find(
                    (item) => item.elementId === ESkillsStatuses.PERSONAL_FOCUS
                );
                const attainedSkillSnapshotItem = skillSnapshotItems.find(
                    (item) => item.elementId === ESkillsStatuses.ATTAINED
                );
                if (inProgressSkillSnapshotItem) {
                    inProgressSkillSnapshotItem.value = userSkillsSnapshotData?.inProgressSkills;
                    inProgressSkillSnapshotItem.isLoading = isLoading;
                }
                if (needAttentionSkillSnapshotItem) {
                    needAttentionSkillSnapshotItem.value = userSkillsSnapshotData?.needsAttentionSkills;
                    needAttentionSkillSnapshotItem.isLoading = isLoading;
                }
                if (personalFocusSkillSnapshotItem) {
                    personalFocusSkillSnapshotItem.value = userSkillsSnapshotData?.myFocusSkills;
                    personalFocusSkillSnapshotItem.isLoading = isLoading;
                }
                if (attainedSkillSnapshotItem) {
                    attainedSkillSnapshotItem.value = userSkillsSnapshotData?.attainedSkills;
                    attainedSkillSnapshotItem.isLoading = isLoading;
                }
                if (
                    inProgressSkillSnapshotItem &&
                    personalFocusSkillSnapshotItem &&
                    needAttentionSkillSnapshotItem &&
                    attainedSkillSnapshotItem
                )
                    return [
                        inProgressSkillSnapshotItem,
                        personalFocusSkillSnapshotItem,
                        needAttentionSkillSnapshotItem,
                        attainedSkillSnapshotItem,
                    ];
                return skillSnapshotItems;
            });
            setAssignedSkillCount(userSkillsSnapshotData.assignedSkills);
        }
    }, [
        userSkillsSnapshotData,
        isUserSkillsSnapshotDataRefetching,
        isUserSkillsSnapshotDataFetching,
        isErrorFetchingSkillsSnapshot,
    ]);

    useEffect(() => {
        if (isErrorFetchingOutcomesSnapshot) return;
        if (userOutcomesSnapshotData) {
            const isLoading = isUserOutcomesSnapshotDataFetching || isUserSkillsSnapshotDataRefetching;
            setOutcomeSnapshotItems((outcomeSnapshotItems) => {
                const unclaimedBadgesOutcomeSnapshotItem = outcomeSnapshotItems.find(
                    (item) => item.elementId === EOutcomesStatuses.UNCLAIMED_BADGES
                );
                const inProgressOutcomeSnapshotItem = outcomeSnapshotItems.find(
                    (item) => item.elementId === EOutcomesStatuses.IN_PROGRESS
                );
                const needsAttentionOutcomeSnapshotItem = outcomeSnapshotItems.find(
                    (item) => item.elementId === EOutcomesStatuses.NEED_ATTENTION
                );
                const attainedOutcomeSnapshotItem = outcomeSnapshotItems.find(
                    (item) => item.elementId === EOutcomesStatuses.ATTAINED
                );
                if (unclaimedBadgesOutcomeSnapshotItem) {
                    unclaimedBadgesOutcomeSnapshotItem.value = userOutcomesSnapshotData?.unclaimedBadges;
                    unclaimedBadgesOutcomeSnapshotItem.isLoading = isLoading;
                }
                if (inProgressOutcomeSnapshotItem) {
                    inProgressOutcomeSnapshotItem.value = userOutcomesSnapshotData?.inProgressOutcomes;
                    inProgressOutcomeSnapshotItem.isLoading = isLoading;
                }
                if (needsAttentionOutcomeSnapshotItem) {
                    needsAttentionOutcomeSnapshotItem.value = userOutcomesSnapshotData?.needsAttentionOutcomes;
                    needsAttentionOutcomeSnapshotItem.isLoading = isLoading;
                }
                if (attainedOutcomeSnapshotItem) {
                    attainedOutcomeSnapshotItem.value = userOutcomesSnapshotData?.attainedOutcomes;
                    attainedOutcomeSnapshotItem.isLoading = isLoading;
                }
                if (
                    unclaimedBadgesOutcomeSnapshotItem &&
                    inProgressOutcomeSnapshotItem &&
                    needsAttentionOutcomeSnapshotItem &&
                    attainedOutcomeSnapshotItem
                )
                    return [
                        inProgressOutcomeSnapshotItem,
                        needsAttentionOutcomeSnapshotItem,
                        attainedOutcomeSnapshotItem,
                        unclaimedBadgesOutcomeSnapshotItem,
                    ];
                return outcomeSnapshotItems;
            });
            setAssignedOutcomeCount(userOutcomesSnapshotData.assignedOutcomes);
        }
    }, [
        userOutcomesSnapshotData,
        isUserOutcomesSnapshotDataFetching,
        isUserOutcomesSnapshotDataRefetching,
        isErrorFetchingOutcomesSnapshot,
    ]);

    useEffect(() => {
        if (fetchedActivityFeedData) {
            setFilteredActivityFeedData(fetchedActivityFeedData);
            changeMaxValueCallback(fetchedActivityFeedData?.length);
        }
    }, [fetchedActivityFeedData]);

    useEffect(() => {
        if (fetchedActivityFeedData) {
            let newFilteredItems = filterActivitiesByType(fetchedActivityFeedData, selectedFilterValue);
            newFilteredItems = filterActivitiesByVisibleNumberOfItems(newFilteredItems, visibleFromTo);
            setFilteredActivityFeedData(newFilteredItems);
        }
    }, [fetchedActivityFeedData, visibleFromTo]);

    const onActivityFilterChange = useCallback(() => {
        let newFilteredItems: IActivityFeedVM[] = [];
        if (fetchedActivityFeedData) {
            changeVisibleFromToCallback({ from: 0, to: 10 });
            newFilteredItems = filterActivitiesByType(fetchedActivityFeedData, selectedFilterValue);
            newFilteredItems = filterActivitiesByVisibleNumberOfItems(newFilteredItems, { from: 0, to: 10 });
            setFilteredActivityFeedData(newFilteredItems);
        }
    }, [selectedFilterValue, fetchedActivityFeedData, filteredActivityFeedData, visibleFromTo]);

    useEffect(() => {
        onActivityFilterChange();
    }, [selectedFilterValue]);

    const filterActivitiesByType = (activities: IActivityFeedVM[], filters: string[]) => {
        const newFilteredActivityData = activities?.filter((activity) => {
            return selectedFilterValue.indexOf(activity.category) !== -1 || filters.includes('All');
        });
        return newFilteredActivityData;
    };

    const filterActivitiesByVisibleNumberOfItems = (activities: IActivityFeedVM[], visibleFromTo: any) => {
        const newFilteredActivityData = activities?.filter((item, index) => {
            return index >= visibleFromTo.from && index < visibleFromTo.to;
        });
        return newFilteredActivityData;
    };

    const loadMoreActivitiesHandler = useCallback(() => {
        changePageCallback(true);
    }, [fetchedActivityFeedData, filteredActivityFeedData, visibleFromTo]);

    const successRecommendationCallback = () => {
        refetchUserSkillsSnapshotData();
        refetchUserOutcomesSnapshotData();
        refetchActivityFeed();
    };

    const contextState: HomeContextProps = {
        skillSnapshotItems,
        outcomeSnapshotItems,
        recommendationItems: fetchedRecommendationsData,
        motdItems,
        assignedSkillCount,
        assignedOutcomeCount,
        isRecommendationsLoading,
        activityFeedData: filteredActivityFeedData,
        isActivityFeedLoading: isActivityFeedFetching || isActivityFeedRefetching,
        loadMoreActivitiesHandler,
        selectedFilterValue,
        setSelectedFilterValue,
        activitiesVisibleFromTo: visibleFromTo,
        isMotdLoading,
        successRecommendationCallback,
        isUserOutcomesSnapshotDataLoading: isUserOutcomesSnapshotDataFetching || isUserOutcomesSnapshotDataRefetching,
        isUserSkillsSnapshotDataLoading: isUserSkillsSnapshotDataFetching || isUserSkillsSnapshotDataRefetching,
        // highlightsItems: highlightItems,
        // isHighlightsLoading: isHighlightsFetching || isHighlightsRefetching,
        isErrorFetchingActivityFeed,
        refetchActivityFeed,
        refetchMotw: refetchMotwData,
        isErrorFetchingMotw,
    };

    return <HomeContext.Provider value={contextState}>{children}</HomeContext.Provider>;
};

export const useHomeStateValue: () => HomeContextProps = () => useContext(HomeContext);

export default HomeContext;
