import { TableCell, Typography } from '@mui/material';
import styled from '../../../../ui/theme/styled';

export const HeaderOverviewTableCell = styled(TableCell)({
    borderBottom: 'none',
    paddingBottom: 0,
});

export const HeaderOverviewTableCellTypography = styled(Typography)({
    fontWeight: 500,
    fontSize: '10px',
});

export const HeaderOverviewTableCellWithLineTypography = styled(Typography)({
    fontWeight: 500,
    fontSize: '10px',
    display: 'flex',
    alignItems: 'center',
    '&:after': {
        content: '""',
        flex: 1,
        marginLeft: '30px',
        height: '1px',
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
});
