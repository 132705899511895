import { FC } from 'react';
import { useNavigate } from 'react-router';
import { Typography } from '@mui/material';
import PrimaryButton from '../../../ui/buttons/primary-button/PrimaryButton';
import { ActionBox, DescriptionBox, MessageBox, MessageTypography, RootBox } from './Style';
import { routes } from '../../../pages/routes';

const PageNotFound: FC = () => {
    const navigate = useNavigate();

    const goBack = () => {
        navigate(routes.HOME);
    };

    return (
        <RootBox>
            <MessageBox>
                <MessageTypography variant="h4">Something Went Wrong.</MessageTypography>
            </MessageBox>
            <DescriptionBox>
                <Typography variant="body1">Page Not Found</Typography>
            </DescriptionBox>
            <ActionBox>
                <PrimaryButton title="Back" clickHandler={goBack} />
            </ActionBox>
        </RootBox>
    );
};

export default PageNotFound;
