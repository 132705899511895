import { FC } from 'react';

interface IProps {
    width?: string;
    height?: string;
}

const UserClaimed: FC<IProps> = ({ width, height }) => {
    return (
        <svg
            width={width || '20'}
            height={height || '22'}
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="11" cy="11" r="11" fill="#5A4691" />
            <circle cx="11" cy="11" r="8" stroke="#F0966A" strokeWidth="2" />
        </svg>
    );
};

export default UserClaimed;
