// import * as microsoftTeams from '@microsoft/teams-js';

export const isInTeams = (): boolean => {
    const microsoftTeamsLib = window['microsoftTeams' as any];

    if (!microsoftTeamsLib) {
        return false; // the Microsoft Teams library is for some reason not loaded
    }

    if (
        (window.parent === window.self && (window as any).nativeInterface) ||
        window.name === 'embedded-page-container' ||
        window.name === 'extension-tab-frame'
    ) {
        return true;
    }
    return false;
};
