import styled from '../../../../../../ui/theme/styled';

export const RootBox = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
});

export const StatusCircle = styled('div')<{ color: string }>(({ theme, color }) => ({
    backgroundColor: color,
    width: '10px',
    height: '10px',
    borderRadius: '11px',
}));

export const StatusCircleWrapper = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'center',
}));
