import { FC } from 'react';

interface IProps {
    width?: string;
    height?: string;
}

const EngagementPointIcon: FC<IProps> = ({ width, height }) => {
    return (
        <svg
            width={width || '32'}
            height={height || '32'}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                opacity="0.4"
                d="M15.9999 5.33398C10.1199 5.33398 5.33325 10.1207 5.33325 16.0007C5.33325 21.8806 10.1199 26.6673 15.9999 26.6673C21.8799 26.6673 26.6666 21.8806 26.6666 16.0007C26.6666 10.1207 21.8799 5.33398 15.9999 5.33398Z"
                fill="#FFCC55"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.66663 16.0003C2.66663 8.64033 8.63996 2.66699 16 2.66699C23.36 2.66699 29.3333 8.64033 29.3333 16.0003C29.3333 23.3603 23.36 29.3337 16 29.3337C8.63996 29.3337 2.66663 23.3603 2.66663 16.0003ZM5.33329 16.0003C5.33329 21.8803 10.12 26.667 16 26.667C21.88 26.667 26.6666 21.8803 26.6666 16.0003C26.6666 10.1203 21.88 5.33366 16 5.33366C10.12 5.33366 5.33329 10.1203 5.33329 16.0003Z"
                fill="#FFCC55"
            />
            <path
                d="M17.1796 14.5784L17.7872 14.6336L20.3157 14.8631L18.3579 16.6488L17.9313 17.038L18.055 17.602L18.6262 20.2049L16.5351 18.8799L15.9999 18.5408L15.4647 18.8799L13.3758 20.2035L13.9446 17.6209L14.069 17.0564L13.6419 16.667L11.6841 14.8813L14.2126 14.6517L14.8211 14.5965L15.0496 14.0298L16.0017 11.6691L16.9507 14.013L17.1796 14.5784Z"
                fill="#FFCC55"
                stroke="#FFCC55"
                strokeWidth="2"
            />
        </svg>
    );
};

export default EngagementPointIcon;
