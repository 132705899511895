export const downloadFile: (downloadLink: string, fileName: string) => Promise<any> = (downloadLink, fileName) => {
    return fetch(downloadLink, {
        method: 'GET',
        headers: {},
    })
        .then((response) => {
            if (response.status !== 200) return false;
            response
                .arrayBuffer()
                .then(function (buffer) {
                    const url = window.URL.createObjectURL(new Blob([buffer]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();
                })
                .catch((e) => {
                    return false;
                });
            return true;
        })
        .catch((err) => {
            console.error(err);
            return false;
        });
};
