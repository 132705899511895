import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const RootBox = styled('div', {
    shouldForwardProp: (prop) => prop !== 'isVisible',
})<{ isVisible: boolean }>(({ isVisible, theme }) => ({
    display: isVisible ? 'flex' : 'none',
    flexDirection: 'column',
    borderRadius: theme.borderRadius?.outside?.large,
    background: theme.palette.common.white,
    flex: 1,
    width: '100%',
    padding: '25px 18px 12px 18px',
    transition: 'ease-out 200s display',
}));

export const HeaderTitle = styled(Typography)(({ theme }) => ({
    color: 'rgba(0, 0, 0, 0.88)',
    letterSpacing: '0.1px',
    fontWeight: 400,
}));

export const ContentBox = styled('div')<{ gapActive?: boolean }>(({ gapActive, theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    flex: 2,
    [theme.breakpoints.down('lg')]: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        gap: '8px',
    },
}));

export const InfoBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    padding: '0px',
    gap: '7px',
    width: '100%',
    [theme.breakpoints.down('lg')]: {
        width: 'unset',
    },
}));

export const InfoTextBox = styled(Typography)(({ theme }) => ({
    letterSpacing: '0.1px',
    color: 'rgba(0, 0, 0, 0.8)',
    width: '100%',
    fontSize: '12px',
    [theme.breakpoints.down('lg')]: {
        width: 'unset',
    },
}));

export const ActionBox = styled('div')<{ gapActive?: boolean }>(({ gapActive, theme }) => ({
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    width: '100%',
    paddingLeft: gapActive ? '24px' : 'unset',
    [theme.breakpoints.down('lg')]: {
        width: 'unset',
    },
}));

export const MasterBox = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.gray.medium,
    fontSize: 14,
}));

export const MasterLabelBox = styled('div')({
    flex: 2,
    display: 'flex',
    justifyContent: 'flex-end',
    textTransform: 'uppercase',
    fontFamily: 'Open Sans',
});

export const MasterSwitchBox = styled('div')({
    marginLeft: 14,
    flex: 1,
});

export const AddControlWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '18px',
    marginRight: 6,
    marginBottom: 7,
});

export const AddControlWrapperInfoBox = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    marginRight: 5,
    textAlign: 'right',
});

export const AssignedByTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.gray.medium,
    lineHeight: '15px',
}));

export const AssignedDateTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.gray.medium,
    lineHeight: '15px',
}));

export const CompetenceTitle = styled('span')({
    '&:hover': {
        textDecoration: 'underline',
        cursor: 'pointer',
        textUnderlineOffset: '2px',
    },
});
