import { FC, useCallback, KeyboardEvent } from 'react';
import { RootBox, LeftArrowIconBox, RightArrowIconBox, InfoTypography } from './Style';
import SwitchLeftArrowIcon from '../../../assets/icons/SwitchLeftArrowIcon';
import SwitchRightArrowIcon from '../../../assets/icons/SwitchRightArrowIcon';

interface IProps {
    changeHandler: (next: boolean) => void;
    isPreviousDisabled: boolean;
    isNextDisabled: boolean;
    infoJsx?: React.ReactNode;
}

const PrevNextItem: FC<IProps> = ({ isPreviousDisabled, isNextDisabled, changeHandler, infoJsx }) => {
    const onNextClick = () => {
        if (!isNextDisabled) changeHandler(true);
    };
    const onPreviousClick = () => {
        if (!isPreviousDisabled) changeHandler(false);
    };

    const onPreviousKeyDown = useCallback(
        (e: KeyboardEvent<HTMLDivElement>) => {
            if (e.key === 'Enter' && !isPreviousDisabled) {
                onPreviousClick();
                e.stopPropagation();
            }
        },
        [isPreviousDisabled]
    );

    const onNextKeyDown = useCallback(
        (e: KeyboardEvent<HTMLDivElement>) => {
            if (e.key === 'Enter' && !isNextDisabled) {
                onNextClick();
                e.stopPropagation();
            }
        },
        [isNextDisabled]
    );

    return (
        <RootBox>
            <LeftArrowIconBox
                tabIndex={isPreviousDisabled ? -1 : 0}
                onClick={onPreviousClick}
                onKeyDown={onPreviousKeyDown}
                isDisabled={isPreviousDisabled}
            >
                <SwitchLeftArrowIcon isDisabled={isPreviousDisabled} />
            </LeftArrowIconBox>
            {infoJsx && <InfoTypography variant="label">{infoJsx}</InfoTypography>}
            <RightArrowIconBox
                tabIndex={isNextDisabled ? -1 : 0}
                onClick={onNextClick}
                onKeyDown={onNextKeyDown}
                isDisabled={isNextDisabled}
            >
                <SwitchRightArrowIcon isDisabled={isNextDisabled} />
            </RightArrowIconBox>
        </RootBox>
    );
};

export default PrevNextItem;
