import CircularProgress from '@mui/material/CircularProgress';
import { FC } from 'react';
import { LoadingBox } from './Style';

interface IProps {
    style?: any;
    boxStyle?: any;
}

const Loading: FC<IProps> = ({ style, boxStyle }) => {
    return (
        <LoadingBox style={boxStyle}>
            <CircularProgress style={style} color="primary" thickness={7} aria-label="loading" />
        </LoadingBox>
    );
};

export default Loading;
