import { createContext, FC, useContext, PropsWithChildren, useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router';
import { useTheme } from '@mui/system';
import { addYears, compareAsc, format, subWeeks } from 'date-fns';
import {
    useGetInsightsPersonalEngagementPointsQuery,
    useGetInsightsPersonalLoggedInQuery,
    useGetInsightsUserEngagementPointsQuery,
    useGetInsightsUserLoggedInQuery,
} from '../services/InsightsQueryService';
import { useInsightsPersonalStateValue } from './InsightsPersonalContext';
import { IScoreLine } from '../interfaces/IScoreLine';
import { createMonthsArray } from '../utils/createMonthsArray';
import { EGraphCardSelect } from '../interfaces/enums/EGraphCardSelect';
import { calculateYearGraphLineValue } from './util/util';
import { IBarChartItem } from '../interfaces/IBarChartItem';
import { IInsightsPersonalEngagementPointVM } from '../interfaces/views/IInsightsPersonalEngagementPointVM';
import { ILoggedInVM } from '../interfaces/views/ILoggedInVM';
import { useInsightsStateValue } from './InsightsContext';
import { EInsightsMode } from '../interfaces/enums/EInsightsMode';
import expandDatesToLastYearPerWeek from '../utils/expandDatesToLastYearPerWeek';
import { IInsightsPersonalEngagementGraphVM } from '../interfaces/views/IInsightsPersonalEngagementGraphVM';
import { useApiStateValue } from './ApiContext';
import { EApiQueryKey } from '../interfaces/enums/EApiQueryKey';

export interface IInsightsPersonalEngagementContext {
    engagementData?: IInsightsPersonalEngagementPointVM[];
    loggedInData: ILoggedInVM[];
    engagementGraphData: IBarChartItem[];
    loggedInGraphData: IBarChartItem[];
    scoreLines: IScoreLine[];
    changeScoreLinesInterval: (option: EGraphCardSelect) => void;
    isEngagementDataLoading: boolean;
    isLoggedInDataLoading: boolean;
    isEngagementDataError: boolean;
    isLoggedInDataError: boolean;
}

export const InsightsPersonalEngagementContext = createContext<IInsightsPersonalEngagementContext>(
    {} as IInsightsPersonalEngagementContext
);

export enum EEngagementScoreLineType {
    ENGAGEMENT = 'Engagement',
    LOGIN = 'Login',
}

export const InsightsPersonalEngagementProvider: FC<PropsWithChildren> = ({ children }) => {
    const { id: paramsId } = useParams<{ id?: string }>();
    const theme = useTheme();
    const { mode } = useInsightsStateValue();
    const [engagementData, setEngagementData] = useState<IInsightsPersonalEngagementPointVM[] | undefined>();
    const [loggedInData, setLoggedInData] = useState<ILoggedInVM[]>([]);
    const [engagementGraphData, setEngagementGraphData] = useState<IBarChartItem[]>([]);
    const [loggedInGraphData, setLoggedInGraphData] = useState<IBarChartItem[]>([]);
    const [scoreLines, setScoreLines] = useState<IScoreLine[]>([]);
    const scoreLinesAllOptionsRef = useRef<IScoreLine[][]>([]);
    const { getInsightsPersonalEngagement, data: graphTotalData } = useInsightsPersonalStateValue();
    const {
        data: fetchedLoggedInData,
        isLoading: isLoggedInDataLoading,
        isFetching: isLoggedInDataFetching,
        isRefetching: isLoggedInDataRefetching,
        refetch: refetchPersonalLoggedIn,
        isError: isPersonalLoggedInDataError,
    } = useGetInsightsPersonalLoggedInQuery();
    const {
        data: fetchedEngagementData,
        isLoading: isEngagementDataLoading,
        isFetching: isEngagementPointsFetching,
        isRefetching: isEngagementPointsRefetching,
        refetch: refetchPersonalEngagementPoints,
        isError: isPersonalEngagementDataError,
    } = useGetInsightsPersonalEngagementPointsQuery();
    const {
        data: fetchedUserEngagementData,
        isLoading: isUserEngagementDataLoading,
        isFetching: isUserEngagementDataFetching,
        isRefetching: isUserEngagementDataRefetching,
        refetch: refetchUserEngagementPoints,
        isError: isUserEngagementDataError,
    } = useGetInsightsUserEngagementPointsQuery(paramsId);
    const {
        data: fetchedUserLoggedInData,
        isLoading: isUserLoggedInDataLoading,
        isFetching: isUserLoggedInDataFetching,
        isRefetching: isUserLoggedInDataRefetching,
        refetch: refetchUserLoggedIn,
        isError: isUserLoggedInDataError,
    } = useGetInsightsUserLoggedInQuery(paramsId);
    const { setUserName } = useInsightsStateValue();
    const { invalidateQueryCache } = useApiStateValue();

    useEffect(() => {
        if (paramsId) {
            invalidateQueryCache(EApiQueryKey.INSIGHTS_FETCH_PERSONAL_ENGAGEMENT_POINTS);
            invalidateQueryCache(EApiQueryKey.INSIGHTS_FETCH_PERSONAL_LOGGED_IN);
            refetchUserEngagementPoints();
            refetchUserLoggedIn();
        } else {
            invalidateQueryCache(EApiQueryKey.INSIGHTS_FETCH_ORGANIZATION_USER_LOGGED_IN);
            invalidateQueryCache(EApiQueryKey.INSIGHTS_FETCH_ORGANIZATION_USER_ENGAGEMENT_POINTS);
            refetchPersonalLoggedIn();
            refetchPersonalEngagementPoints();
        }
    }, [paramsId, mode]);

    useEffect(() => {
        if (graphTotalData && graphTotalData.length > 0) {
            const data = getInsightsPersonalEngagement();

            const engagementGraphDataCalc: IBarChartItem[] = [];
            const loggedInGraphDataCalc: IBarChartItem[] = [];

            const latestScoringDateDTO = data.reduce((a, b) => {
                return new Date(a.date) > new Date(b.date) ? a : b;
            });

            let counterDate = new Date((latestScoringDateDTO.date as Date).getTime());
            const latestScoringData: IInsightsPersonalEngagementGraphVM[] = [];
            while (addYears(counterDate as Date, 1) > latestScoringDateDTO.date) {
                const existingScore = data.find((d) => {
                    return compareAsc(d.date as Date, counterDate) === 0;
                });
                if (existingScore) {
                    latestScoringData.unshift({
                        date: new Date((counterDate as Date).getTime()),
                        logins: existingScore ? existingScore.logins : 0,
                        engagementPoints: existingScore ? existingScore.engagementPoints : 0,
                    });
                } else {
                    latestScoringData.unshift({
                        date: new Date((counterDate as Date).getTime()),
                        logins: 0,
                        engagementPoints: 0,
                    });
                }
                counterDate = subWeeks(counterDate as Date, 1);
            }

            latestScoringData.slice(-15).map((item) => {
                const name = format(item.date, 'MM/dd');
                engagementGraphDataCalc.push({
                    name,
                    value: item.engagementPoints,
                });
                loggedInGraphDataCalc.push({
                    name,
                    value: item.logins,
                });
            });

            setEngagementGraphData(engagementGraphDataCalc);
            setLoggedInGraphData(loggedInGraphDataCalc);

            const engagementScoreLine: IScoreLine = {
                id: 'EW1',
                name: 'Engagement',
                color: theme.palette.status.assigned,
                type: EEngagementScoreLineType.ENGAGEMENT,
                scores: [],
            };

            const loginScoreLine: IScoreLine = {
                id: 'LW2',
                name: 'Logins',
                color: theme.palette.status.assigned,
                type: EEngagementScoreLineType.LOGIN,
                scores: [],
            };

            let engagementScoreLineYear: IScoreLine = {
                ...engagementScoreLine,
                id: 'EY1',
                type: EEngagementScoreLineType.ENGAGEMENT,
                scores: createMonthsArray(),
            };

            let loginScoreLineYear: IScoreLine = {
                ...loginScoreLine,
                id: 'LY2',
                type: EEngagementScoreLineType.LOGIN,
                scores: createMonthsArray(),
            };

            data.forEach((dataItem) => {
                engagementScoreLine.scores.push({
                    date: dataItem.date,
                    value: dataItem.engagementPoints,
                });

                loginScoreLine.scores.push({
                    date: dataItem.date,
                    value: dataItem.logins,
                });

                engagementScoreLineYear = calculateYearGraphLineValue(
                    engagementScoreLineYear,
                    dataItem.date,
                    dataItem.engagementPoints || 0
                );

                loginScoreLineYear = calculateYearGraphLineValue(
                    loginScoreLineYear,
                    dataItem.date,
                    dataItem.logins || 0
                );
            });
            const allIndividualScoreTimes = [
                expandDatesToLastYearPerWeek(engagementScoreLine),
                expandDatesToLastYearPerWeek(loginScoreLine),
            ];
            const scoreLines4Month: IScoreLine[] = allIndividualScoreTimes.map((scoreTime) => {
                return {
                    ...scoreTime,
                    scores: scoreTime.scores.slice(-15),
                };
            });
            setScoreLines(scoreLines4Month);

            scoreLinesAllOptionsRef.current = [scoreLines4Month, allIndividualScoreTimes];
        } else {
            setEngagementGraphData([]);
            setLoggedInGraphData([]);
            setScoreLines([]);
            scoreLinesAllOptionsRef.current = [];
        }
    }, [graphTotalData]);

    const changeScoreLinesInterval = (option: EGraphCardSelect) => {
        let chosenScoreLine;
        if (option === EGraphCardSelect.MONTH_4) {
            chosenScoreLine = scoreLinesAllOptionsRef.current[0];
            setScoreLines(scoreLinesAllOptionsRef.current[0]);
        } else if (option === EGraphCardSelect.YEAR_WITH_WEEKS) {
            chosenScoreLine = scoreLinesAllOptionsRef.current[1];
            setScoreLines(scoreLinesAllOptionsRef.current[1]);
        }

        setEngagementGraphData(
            chosenScoreLine
                ?.find((scoreLine) => scoreLine?.type === EEngagementScoreLineType.ENGAGEMENT)
                ?.scores.map((item) => {
                    return {
                        name: typeof item.date === 'string' ? item.date : format(item.date as Date, 'MM/dd'),
                        value: item.value,
                    };
                }) || []
        );
        setLoggedInGraphData(
            chosenScoreLine
                ?.find((scoreLine) => scoreLine?.type === EEngagementScoreLineType.LOGIN)
                ?.scores.map((item) => {
                    return {
                        name: typeof item.date === 'string' ? item.date : format(item.date as Date, 'MM/dd'),
                        value: item.value,
                    };
                }) || []
        );
    };

    useEffect(() => {
        if (fetchedEngagementData) {
            if (fetchedEngagementData.length === 0) setEngagementData(undefined);
            else setEngagementData(fetchedEngagementData);
        }
        if (fetchedLoggedInData) {
            setLoggedInData(fetchedLoggedInData);
        }
    }, [fetchedEngagementData, fetchedLoggedInData]);

    useEffect(() => {
        if (fetchedUserEngagementData && fetchedUserEngagementData.data) {
            setEngagementData(fetchedUserEngagementData.data);
            setUserName(fetchedUserEngagementData.userName);
        }
        if (fetchedUserLoggedInData && fetchedUserLoggedInData.data) {
            setLoggedInData(fetchedUserLoggedInData.data);
            setUserName(fetchedUserLoggedInData.userName);
        }
    }, [fetchedUserEngagementData, fetchedUserLoggedInData]);

    const insightsPersonalOutcomesContext: IInsightsPersonalEngagementContext = {
        engagementData,
        loggedInData,
        engagementGraphData,
        loggedInGraphData,
        scoreLines,
        changeScoreLinesInterval,
        isEngagementDataLoading:
            mode === EInsightsMode.STANDARD
                ? isEngagementDataLoading || isEngagementPointsFetching || isEngagementPointsRefetching
                : isUserEngagementDataLoading || isUserEngagementDataFetching || isUserEngagementDataRefetching,
        isLoggedInDataLoading:
            mode === EInsightsMode.STANDARD
                ? isLoggedInDataLoading || isLoggedInDataFetching || isLoggedInDataRefetching
                : isUserLoggedInDataLoading || isUserLoggedInDataFetching || isUserLoggedInDataRefetching,
        isEngagementDataError:
            mode === EInsightsMode.STANDARD ? isPersonalEngagementDataError : isUserEngagementDataError,
        isLoggedInDataError: mode === EInsightsMode.STANDARD ? isPersonalLoggedInDataError : isUserLoggedInDataError,
    };

    return (
        <InsightsPersonalEngagementContext.Provider value={insightsPersonalOutcomesContext}>
            {children}
        </InsightsPersonalEngagementContext.Provider>
    );
};

export const useInsightsPersonalEngagementStateValue: () => IInsightsPersonalEngagementContext = () =>
    useContext(InsightsPersonalEngagementContext);
