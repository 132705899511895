import { FC } from 'react';

const UpArrowIcon: FC = (props) => {
    return (
        <svg {...props} width="11" height="6" viewBox="0 0 11 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.03995 5.47136C9.3003 5.73171 9.72241 5.73171 9.98276 5.47136C10.2431 5.21101 10.2431 4.7889 9.98276 4.52855L5.98276 0.528555C5.72241 0.268205 5.3003 0.268205 5.03995 0.528555L1.03995 4.52855C0.779603 4.7889 0.779603 5.21101 1.03995 5.47136C1.3003 5.73171 1.72241 5.73171 1.98276 5.47136L5.51136 1.94277L9.03995 5.47136Z"
                fill="#8C8C8C"
            />
        </svg>
    );
};

export default UpArrowIcon;
