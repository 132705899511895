import { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import * as d3 from 'd3';
import { isEqual, isDate, isAfter, format } from 'date-fns';
import { useTheme } from '@mui/system';
import { parseISO } from 'date-fns';
import { SkillScoreDTO } from '../../../../../interfaces/dtos/SkillScoreDTO';
import NoDataCard from '../../../../../ui/cards/no-data-card/NoDataCard';
import { GraphBox } from './Style';

interface ISkillGraphDataItem {
    day: Date | null;
    value?: number;
}

enum Colors {
    PRIMARY = '#ABC248',
    SECONDARY = '#44356F',
    SECONDARY_LIGHT = '#654EA3',
    DISABLED = '#A6A6A6',
    SCALE = '#616063',
}

interface IDimension {
    width?: number;
    height?: number;
    margin?: {
        right?: number;
        left?: number;
        bottom?: number;
        top?: number;
    };
}

interface IProps {
    dimension?: IDimension;
    scores: SkillScoreDTO[];
    currentScoreDate: string;
    currentScore: number;
    target?: number;
    isEmpty: boolean;
}

const defaultDimension: IDimension = {
    width: 400,
    height: 300,
    margin: {
        right: 20,
        left: 40,
        bottom: 50,
        top: 40,
    },
};

const SkillGraph: FC<IProps> = ({ dimension, scores, currentScoreDate, currentScore, target, isEmpty = false }) => {
    const { width, height, margin, finalWidth, finalHeight } = useMemo(() => {
        const { width, height, margin }: IDimension = {
            ...defaultDimension,
            ...dimension,
        };
        return {
            width,
            height,
            margin,
            finalWidth: width! - margin!.left! - margin!.right!,
            finalHeight: height! - margin!.top! - margin!.bottom!,
        };
    }, [dimension]);
    const theme = useTheme();
    const graphBoxRef = useRef(null);
    const idRef = useRef(Math.floor(Math.random() * 1000000).toString());

    const getCurrentXAxisLabel = () => {
        if (currentScoreDate) return format(parseISO(currentScoreDate), 'M/d');
        return '';
    };

    const getCurrentYAxisLabel: () => string | undefined = () => {
        return !target ? undefined : target.toString();
    };

    const getFirstDate = (testData: SkillScoreDTO[]) => {
        return parseISO(testData[0].date);
    };

    const getLastDate = (testData: SkillScoreDTO[]) => {
        return parseISO(testData[testData.length - 1].date);
    };

    const parseDate = (dateString: string) => {
        return parseISO(dateString);
    };

    const regroupData = (data: SkillScoreDTO[]) => {
        let pastTimeData: SkillScoreDTO[] = [];
        let futureTimeData: SkillScoreDTO[] = [];
        data.forEach((dataItem) => {
            const itemDate: Date = parseDate(dataItem.date);
            if (isAfter(itemDate, parseDate(currentScoreDate))) futureTimeData.push(dataItem);
            else pastTimeData.push(dataItem);
        });

        pastTimeData = pastTimeData.sort((a, b) => {
            return isAfter(parseDate(a.date), parseDate(b.date)) ? 1 : -1;
        });

        futureTimeData = futureTimeData.sort((a, b) => {
            return isAfter(parseDate(a.date), parseDate(b.date)) ? 1 : -1;
        });

        return [pastTimeData, futureTimeData];
    };

    const createGraph = useCallback(() => {
        d3.selectAll(graphBoxRef.current).remove();
        d3.selectAll('#graph-svgg' + idRef.current).remove();
        d3.selectAll('#current-wrapper-circle' + idRef.current)
            .selectAll('text')
            .remove();

        if (!scores || scores.length < 1) return;

        let currentScoreDateObj = parseISO(currentScoreDate);
        const dates: Date[] = [currentScoreDateObj];
        for (let i = 0; i < 12; i++) {
            let previosDate = new Date(dates[0].getTime());
            previosDate.setDate(previosDate.getDate() - 7);
            dates.unshift(previosDate);
        }
        for (let i = 0; i < 2; i++) {
            let nextDate = new Date(dates[dates.length - 1].getTime());
            nextDate.setDate(nextDate.getDate() + 7);
            dates.push(nextDate);
        }

        const preparedScores: SkillScoreDTO[] = [];
        scores.forEach((score) => {
            preparedScores.push({
                date: parseISO(score.date).toISOString(),
                score: score?.score,
            });
        });

        const [pastTimeData, futureTimeData] = regroupData(preparedScores);
        let completeData = [...pastTimeData, ...futureTimeData];

        let formattedCompleteTestData: ISkillGraphDataItem[] = completeData.map((item) => {
            return {
                value: item.score,
                day: parseDate(item.date) ?? null,
            };
        });
        let formattedPastTimeData: ISkillGraphDataItem[] = pastTimeData.map((item) => {
            return {
                value: item.score,
                day: parseDate(item.date) ?? null,
            };
        });
        let formattedFutureTimeData: ISkillGraphDataItem[] = [
            pastTimeData[pastTimeData.length - 1],
            ...futureTimeData,
        ].map((item) => {
            return {
                value: item.score,
                day: parseDate(item.date) ?? null,
            };
        });

        const findCurrentWeekStartDate: () => Date | null = () => {
            return currentScoreDate ? parseISO(currentScoreDate) : null;
        };

        const findCurrentWeekStartDateValue: () => number | null = () => {
            return currentScore;
        };

        const dayData: (Date | null)[] = completeData.map((d) => {
            return parseDate(d.date);
        });

        let svg = d3
            .select(graphBoxRef.current)
            .append('svg')
            .attr('id', 'graph-svgg' + idRef.current)
            .attr('width', finalWidth + margin!.left! + margin!.right!)
            .attr('height', finalHeight + margin!.top! + margin!.bottom!)
            .append('g')
            .attr('transform', `translate(${margin!.left}, ${margin!.top})`);

        let x = d3
            .scaleTime()
            .domain([getFirstDate(completeData), getLastDate(completeData)])
            .range([0, finalWidth]);
        let y = d3.scaleLinear().domain([0, 175]).range([finalHeight!, 0, 1]);

        const currentYAxisValue: string | undefined = getCurrentYAxisLabel();
        if (currentYAxisValue)
            svg.append('path')
                .datum(formattedCompleteTestData)
                .attr('fill', 'none')
                .attr('stroke', Colors.SECONDARY_LIGHT)
                .attr('stroke-width', 2)
                .attr(
                    'd',
                    d3.line(
                        function (d, index) {
                            return d.day instanceof Date ? x(d.day) : index;
                        },
                        function (d) {
                            return y(+currentYAxisValue);
                        }
                    )
                );

        let chunks: ISkillGraphDataItem[][] = [];
        let newArray = true;
        formattedPastTimeData.forEach((fptd) => {
            if (newArray) {
                if (!fptd.value) {
                } else {
                    newArray = false;
                    chunks.push([{ ...fptd }]);
                }
            } else {
                if (!fptd.value) {
                    newArray = true;
                } else {
                    chunks[chunks.length - 1].push({ ...fptd });
                }
            }
        });

        chunks.forEach((pastTimeDataChunk) => {
            const path = svg
                .append('path')
                .datum(pastTimeDataChunk.filter((d) => d.value))
                .interrupt()
                .attr('id', 'path-past')
                .attr('fill', 'none')
                .attr('stroke', Colors.PRIMARY)
                .attr('stroke-width', 6)
                .attr(
                    'd',
                    d3.line(
                        function (d, index) {
                            return d.day instanceof Date ? x(d.day) : index;
                        },
                        function (d) {
                            return y(d.value!);
                        }
                    )
                );

            const pathLength = path.node()?.getTotalLength() ?? 0;
            path.attr('stroke-dashoffset', pathLength).attr('stroke-dasharray', pathLength);
            path.transition()
                .duration((pastTimeDataChunk.length - 1) * 100)
                .ease(d3.easeSin)
                .attr('stroke-dashoffset', 0)
                .on('end', () => {
                    const textNode = svg.select('#current-wrapper-circle-text');

                    if (!textNode.empty()) {
                        textNode.text(findCurrentWeekStartDateValue()).attr('id', '#current-wrapper-circle-text');
                    } else {
                        svg.select('#current-wrapper-circle' + idRef.current)
                            .append('text')
                            .text(findCurrentWeekStartDateValue())
                            .attr('id', '#current-wrapper-circle-text' + idRef.current)
                            .attr('fill', 'white')
                            .attr('text-anchor', 'middle')
                            .attr('dominant-baseline', 'middle')
                            .attr('font-size', 18)
                            .attr('letter-spacing', 1)
                            .attr('x', 1)
                            .attr('y', 2)
                            .style('font-weight', 700);
                    }
                    svg.select('#current-circle').attr('display', 'block');
                    svg.select('#current-wrapper-circle' + idRef.current).raise();
                });
        });

        // const path = svg
        //     .append('path')
        //     .datum(formattedPastTimeData.filter((d) => d.value))
        //     .interrupt()
        //     .attr('id', 'path-past')
        //     .attr('fill', 'none')
        //     .attr('stroke', Colors.PRIMARY)
        //     .attr('strokeWidth', 6)
        //     .attr(
        //         'd',
        //         d3.line(
        //             function (d, index) {
        //                 return d.day instanceof Date ? x(d.day) : index;
        //             },
        //             function (d) {
        //                 return y(d.value!);
        //             }
        //         )
        //     );

        // const pathLength = path.node()?.getTotalLength() ?? 0;
        // path.attr('stroke-dashoffset', pathLength).attr('stroke-dasharray', pathLength);
        // path.transition()
        //     .duration((pastTimeData.length - 1) * 100)
        //     .ease(d3.easeSin)
        //     .attr('stroke-dashoffset', 0)
        //     .on('end', () => {
        //         svg.select('#current-circle').attr('display', 'block');
        //     });

        const futurePath = svg
            .append('path')
            .datum(formattedFutureTimeData.filter((d) => d.value))
            .attr('id', 'path-future')
            .attr('fill', 'none')
            .attr('stroke', Colors.DISABLED)
            // .style('stroke-dasharray', '3, 3')
            .attr('stroke-width', 3)
            .attr(
                'd',
                d3.line(
                    function (d, index) {
                        return d.day instanceof Date ? x(d.day) : index;
                    },
                    function (d) {
                        return y(d.value!);
                    }
                )
            );

        svg.select('#path-past').raise();

        const futurePathLength = futurePath.node()?.getTotalLength() ?? 0;
        futurePath.attr('stroke-dashoffset', futurePathLength).attr('stroke-dasharray', futurePathLength);
        futurePath
            .transition()
            .delay((pastTimeData.length - 2) * 100)
            .duration((futureTimeData.length + 1) * 100)
            .ease(d3.easeSin)
            .attr('stroke-dashoffset', 0)
            .style('stroke-dasharray', '3, 3');

        let tooltipDiv = d3
            .select('body')
            .append('div')
            .attr('class', 'tooltip')
            .style('opacity', 0)
            .style('background', Colors.SECONDARY_LIGHT)
            .style('color', theme.palette.common.white)
            .style('position', 'absolute')
            .style('width', '25px')
            .style('height', '25px')
            .style('border-radius', '14px')
            .style('display', 'flex')
            .style('justify-content', 'center')
            .style('align-items', 'center')
            .style('padding', '12px')
            .style('font-size', '13px')
            .style('border', `2px solid ${Colors.SECONDARY}`)
            .style('font-family', 'Ubuntu');

        const isCurrentGraphItem: (day: Date) => boolean = (day) => {
            return isDate(day) && isEqual(day as Date, findCurrentWeekStartDate() ?? 0);
        };

        svg.selectAll('.dot-past')
            .data(formattedPastTimeData.filter((d) => d.value))
            .enter()
            .append('g')
            .attr('transform', (d) => 'translate(' + x(d.day as Date) + ',' + y(d.value!) + ')')
            .attr('id', function (d, i) {
                if (isDate(d.day))
                    if (isEqual(d.day as Date, findCurrentWeekStartDate() ?? 0))
                        return 'current-wrapper-circle' + idRef.current;
                return 'past-wrapper-circle' + i;
            })
            .append('circle')
            .attr('class', 'dot-past')
            .attr('r', function (d) {
                if (isCurrentGraphItem(d.day as Date)) return 1;
                return 3;
            })
            .attr('id', function (d) {
                if (isCurrentGraphItem(d.day as Date)) return 'current-circle';
                return null;
            })
            .attr('fill', (d) => {
                if (isCurrentGraphItem(d.day as Date)) return Colors.SECONDARY_LIGHT;
                return Colors.PRIMARY;
            })
            .attr('stroke', Colors.SECONDARY)
            .attr('opacity', (d) => {
                if (isCurrentGraphItem(d.day as Date)) return 0;
                return 1;
            })
            .attr('stroke-width', (d) => {
                if (isCurrentGraphItem(d.day as Date)) return 3;
                return 0;
            })
            .on('mouseover', function (d) {
                const value = d.target?.__data__?.value;
                if (value && d?.target?.id !== 'current-circle') {
                    tooltipDiv.transition().duration(200).style('opacity', 0.9);
                    tooltipDiv
                        .html(`<span>${d.target.__data__.value}</span>`)
                        .style('left', `${d.pageX + 5}px`)
                        .style('top', `${d.pageY - 24}px`);
                }
            })
            .on('mouseout', function (d) {
                tooltipDiv.transition().duration(300).style('opacity', 0);
            });

        svg.select('#current-circle')
            .transition()
            .delay((pastTimeData.filter((d) => d.score).length - 1) * 100)
            .duration(100)
            .attr('r', 22)
            .style('opacity', 1);

        svg.selectAll('.dot-future')
            .data(formattedFutureTimeData.filter((d) => d.value))
            .enter()
            .append('circle')
            .attr('transform', (d) => 'translate(' + x(d.day as Date) + ',' + y(d.value!) + ')')
            .attr('class', 'dot-future')
            .attr('r', function (d) {
                if (isDate(d.day)) if (isEqual(d.day as Date, findCurrentWeekStartDate() ?? 0)) return 0;
                return 6;
            })
            .attr('fill', Colors.DISABLED)
            .on('mouseover', function (d) {
                const value = d.target?.__data__?.value;
                if (value && d?.target?.id !== 'current-circle') {
                    tooltipDiv.transition().duration(200).style('opacity', 0.9);
                    tooltipDiv
                        .html(`<span>${d.target.__data__.value}</span>`)
                        .style('left', `${d.pageX + 5}px`)
                        .style('top', `${d.pageY - 24}px`);
                }
            })
            .on('mouseout', function (d) {
                tooltipDiv.transition().duration(300).style('opacity', 0);
            });

        svg.append('g')
            .attr('transform', `translate(0, ${finalHeight})`)
            .call(
                d3
                    .axisBottom(x)
                    .ticks(d3.timeWeek)
                    .tickFormat((interval) => {
                        return isDate(interval) ? format(interval as Date, 'M/d') : '';
                    })
                    .tickValues(dayData as Date[])
                    .tickSize(0)
                    .tickPadding(24)
            )
            .attr('color', '#8C8C8C')
            .style('font-size', '11px');
        svg.append('g')
            .call(
                d3
                    .axisLeft(y)
                    .tickSize(0)
                    .tickValues(Array.from(Array(175).keys()))
            )
            .attr('id', 'yaxis')
            .call((g) => g.select('.domain').remove());

        const nowYDot = d3.selectAll('svg text').filter(function () {
            const regex = getCurrentYAxisLabel();
            return regex === d3.select(this).text();
        });

        const nowXDot = d3.selectAll('svg text').filter(function () {
            const regex = getCurrentXAxisLabel();
            return regex === d3.select(this).text();
        });

        d3.selectAll('svg g.tick line').attr('y2', null);
        d3.selectAll('svg g.tick line').attr('x2', null);

        d3.selectAll('svg .domain').attr('stroke', Colors.SCALE).attr('stroke-width', 2);

        if (nowYDot)
            nowYDot
                .attr('fill', Colors.SECONDARY_LIGHT)
                .attr('font-size', 16)
                .style('font-weight', '700')
                .attr('id', 'nowYDot');

        if (nowXDot)
            nowXDot
                .attr('fill', Colors.SECONDARY_LIGHT)
                .attr('font-size', 14)
                .attr('y', 22)
                .style('font-weight', '600')
                .style('letter-spacing', '0.5px')
                .style('text-transform', 'uppercase');

        d3.selectAll('#yaxis .tick')
            .filter((d) => {
                if ('' + d !== getCurrentYAxisLabel()) return true;
                return false;
            })
            .remove();

        svg.select('#path-future').raise();
        svg.selectAll('.dot-future').raise();
    }, [scores, height, margin!.bottom, margin!.left, margin!.right, margin!.top, width, target]);

    useEffect(() => {
        if (!scores) return;
        createGraph();
    }, [scores, createGraph]);

    return (
        <GraphBox
            className="skill-success-box"
            style={{ width: dimension?.width || defaultDimension.width }}
            ref={graphBoxRef}
        >
            {isEmpty && <NoDataCard />}
        </GraphBox>
    );
};

export default SkillGraph;
