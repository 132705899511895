import { Typography } from '@mui/material';
import styled from '../../../ui/theme/styled';

export const RootBox = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    marginRight: '110px',
    marginLeft: '110px',
    gap: '32px',
    height: '70vh',
});

export const MessageBox = styled('div')({});

export const DescriptionBox = styled('div')({
    paddingBottom: 12,
});

export const MessageTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,
}));

export const ActionBox = styled('div')({});
