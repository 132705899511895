import { CSSProperties, FC } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { SelectChangeEvent } from '@mui/material/Select';
import { StyledSelect } from './Style';
import UpArrowIcon from '../../../assets/icons/UpArrowIcon';

export interface IDropdownMenuItem {
    value: string | number;
    name: string | number;
}

interface IProps {
    handleChange: (event: SelectChangeEvent<unknown>) => void;
    value?: string | number;
    items: IDropdownMenuItem[];
    defaultValue?: string | number;
    style?: CSSProperties;
    disabled?: boolean;
    id?: string;
}

const Dropdown: FC<IProps> = ({ value, handleChange, items, defaultValue, style, disabled = false, id }) => {
    return (
        <FormControl sx={{ m: 1, minWidth: 120, width: '100%', margin: 0 }} style={style}>
            <StyledSelect
                value={value}
                onChange={handleChange}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                IconComponent={UpArrowIcon}
                defaultValue={defaultValue}
                disabled={disabled}
                id={id || 'dropdown'}
            >
                {items.map((item) => {
                    return (
                        <MenuItem style={{ whiteSpace: 'normal' }} key={item.value} value={item.value}>
                            {item.name}
                        </MenuItem>
                    );
                })}
            </StyledSelect>
        </FormControl>
    );
};

export default Dropdown;
