import { UseMutationResult, useMutation, useQuery, UseQueryResult } from '@tanstack/react-query';
import { getTokenAndHeaders } from './helpers/apiUtil';
import useAuthParams from '../hooks/useAuthParams';
import { IAuthParams } from '../interfaces/IAuthParams';
import { apiInstance } from './helpers/apiInstance';
import { ERecommendationType } from '../interfaces/enums/ERecommendationType';
import { mapRecommendations } from './helpers/mappers';
import { IRecommendationVM } from '../interfaces/views/IRecommendationVM';
import { EApiQueryKey } from '../interfaces/enums/EApiQueryKey';

interface ICompleteRecommendationRequest {
    userRecommendationId: number;
    completed: boolean;
}

// POST USER RECOMMENDATIONS COMPLETE
export const postUserRecommendationsComplete = async (
    variables: ICompleteRecommendationRequest & { authParams: IAuthParams }
) => {
    const { authParams, ...completeRecommendationRequest } = variables;
    const { headers } = await getTokenAndHeaders(authParams);

    const {
        data: { data },
    } = await apiInstance.post(
        `recommendations/${completeRecommendationRequest.userRecommendationId}/complete`,
        completeRecommendationRequest,
        headers
    );
    return data;
};

export const usePostUserRecommendationComplete: (
    userRecommendationId?: string
) => UseMutationResult<IRecommendationVM, Error, ICompleteRecommendationRequest> = (userRecommendationId) => {
    const authParams = useAuthParams();
    return useMutation({
        mutationFn: async (variables: ICompleteRecommendationRequest) => {
            return postUserRecommendationsComplete({ ...variables, authParams });
        },
    });
};

// POST FETCH RECOMMENDATIONS
export const postRecommendations = async (authParams: IAuthParams, recommendationTypes?: ERecommendationType[]) => {
    const { headers } = await getTokenAndHeaders(authParams);

    const {
        data: { data },
    } = await apiInstance.post(`recommendations`, recommendationTypes, headers);
    return mapRecommendations(data);
};

export const usePostUserRecommendations: (
    recommendationTypes?: ERecommendationType[]
) => UseMutationResult<IRecommendationVM[], Error> = (recommendationTypes) => {
    const authParams = useAuthParams();
    return useMutation({
        mutationFn: async () => {
            return postRecommendations(authParams, recommendationTypes);
        },
    });
};

// GET OUTCOME RECOMMENDATIONS'S NEXT STEP
export const getRecommendationNextStep = async (authParams: IAuthParams, outcomeId?: string) => {
    const { headers } = await getTokenAndHeaders(authParams);
    const {
        data: { data },
    } = await apiInstance.get(`recommendations/outcomes/${outcomeId}/nextStep`, headers);
    if (data) return mapRecommendations(data);
    return data;
};

export const useGetRecommendationNextStepQuery: (outcomeId?: string) => UseQueryResult<IRecommendationVM[], Error> = (
    outcomeId
) => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.RECOMMENDATION_FETCH_BY_OUTCOME],
        queryFn: async () => {
            return getRecommendationNextStep(authParams, outcomeId);
        },
        refetchOnWindowFocus: false,
    });
};
