import { FC, PropsWithChildren } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PrimaryButton from '../../../ui/buttons/primary-button/PrimaryButton';
import SecondaryButton from '../../../ui/buttons/secondary-button/SecondaryButton';

interface IProps {
    isOpen: boolean;
    title: string;
    description: string;
    handleClose: () => void;
    handleConfirm: () => void;
    confirmTitle?: string;
    cancelTitle?: string;
}

const ConfirmDialog: FC<PropsWithChildren<IProps>> = ({
    isOpen,
    title,
    description,
    handleClose,
    handleConfirm,
    confirmTitle = 'Yes',
    cancelTitle = 'Cancel',
}) => {
    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <SecondaryButton onClick={handleClose} autoFocus>
                    {cancelTitle}
                </SecondaryButton>
                <PrimaryButton clickHandler={handleConfirm} title={confirmTitle} />
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDialog;
