import { FC, useEffect, useRef, useMemo, useState } from 'react';
import * as d3 from 'd3';
import { isDate, parse } from 'date-fns';
import { useTheme } from '@mui/system';
import { useMediaQuery } from '@mui/material';
import {
    RootBox,
    GraphBox,
    ScoreLegendBox,
    ScoreLegendRootBox,
    LegendCircle,
    LegendNameTypography,
    LegendLabelTypography,
} from './Style';
import { IScoreLine } from '../../../../interfaces/IScoreLine';
import { EAxisType } from '../../../../interfaces/enums/EAxisType';
import Loading from '../../../../ui/general/loading/Loading';
import NoDataCard from '../../../../ui/cards/no-data-card/NoDataCard';
import Tooltip from '../../../../ui/general/tooltip/Tooltip';
import BenchmarkRangeIcon from '../../../../assets/icons/BenchmarkRangeIcon';
import BenchmarkIcon from '../../../../assets/icons/BenchmarkIcon';
import { EFormatDate } from '../../../../interfaces/enums/EFormatDate';
import { formatLocalDate } from '../../../../utils/dateUtil';

interface IProps {
    scoreLines?: IScoreLine[];
    xAxis?: string[];
    xAxisType: EAxisType;
    yMaxValue?: number;
    dimension?: IDimension;
    isDataEmpty?: boolean;
    benchmarkStartValue?: number;
    benchmarkEndValue?: number;
    graphLegendLabel?: string;
    isLoading?: boolean;
}

interface ISkillGraphDataItem {
    day: Date | string | null;
    value?: number;
}

const getFirstDate = (firstDateString: string) => {
    return parse(firstDateString, 'MM/d/yy', new Date());
};

const getLastDate = (lastDateString: string) => {
    return parse(lastDateString, 'MM/d/yy', new Date());
};

enum Colors {
    X_SCALE = '#616063',
}

const transitionDuration = 1000; //ms

interface IDimension {
    width?: number;
    height?: number;
    margin?: {
        right?: number;
        left?: number;
        bottom?: number;
        top?: number;
    };
}

const defaultDimension: IDimension = {
    width: 400,
    height: 300,
    margin: {
        right: 20,
        left: 40,
        bottom: 50,
        top: 40,
    },
};

const findMaxValue = (scoreLines: IScoreLine[]) => {
    let maxValue = 0;
    scoreLines.forEach((scoreLine) => {
        const nonUndefinedScores: number[] = scoreLine.scores
            .filter((score) => !!score.value)
            .map((score) => score.value!);
        const scoreLineMaxValue = Math.max(...nonUndefinedScores);
        if (scoreLineMaxValue > maxValue) maxValue = scoreLineMaxValue;
    });
    return maxValue;
};

const Graph: FC<IProps> = ({
    scoreLines,
    xAxis,
    yMaxValue,
    dimension,
    xAxisType,
    isDataEmpty,
    benchmarkStartValue,
    benchmarkEndValue,
    graphLegendLabel,
    isLoading,
}) => {
    const { width, height, margin } = useMemo(() => {
        const { width, height, margin }: IDimension = {
            ...defaultDimension,
            ...dimension,
        };
        return {
            width,
            height,
            margin,
            finalWidth: width! - margin!.left! - margin!.right!,
            finalHeight: height! - margin!.top! - margin!.bottom!,
        };
    }, [dimension]);
    const graphHolderBoxRef = useRef<HTMLDivElement>(null);
    const [visibleLineIds, setVisibleLinesIds] = useState<string[]>();
    const xAxisMemoRef = useRef<string[] | undefined>();
    const xAxisTypeRef = useRef<EAxisType | undefined>(undefined);
    const widthRef = useRef<number>(0);
    const scoreLinesRef = useRef<IScoreLine[] | undefined>();
    const benchmarkEndValueRef = useRef<number | undefined>(undefined);
    const graphRandomIdRef = useRef(Math.floor(Math.random() * 100000));
    const theme = useTheme();
    const matchesLgDownBreakpoint = useMediaQuery(theme.breakpoints.down('lg'));
    const matches800DownBreakpoint = useMediaQuery(theme.breakpoints.down(800));
    const matchesMdDownBreakpoint = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        return () => {
            d3.selectAll(`#graph-svg-${graphRandomIdRef.current}`).remove();
        };
    }, []);

    useEffect(() => {
        if (isDataEmpty) d3.selectAll(`#graph-svg-${graphRandomIdRef.current}`).remove();
    }, [isDataEmpty]);

    useEffect(() => {
        setVisibleLinesIds(scoreLines?.map((scoreLine) => scoreLine.id));
    }, [scoreLines]);

    useEffect(() => {
        if (
            !width ||
            !height ||
            !margin ||
            !margin.left ||
            !margin.right ||
            !margin.top ||
            !margin.bottom ||
            !xAxis ||
            xAxis?.length === 0
        ) {
            return;
        }
        if (
            xAxisMemoRef.current?.length === xAxis.length &&
            xAxisTypeRef.current === xAxisType &&
            widthRef.current === width &&
            JSON.stringify(scoreLines) === JSON.stringify(scoreLinesRef.current) &&
            benchmarkEndValueRef.current === benchmarkEndValue
        ) {
            return;
        }
        benchmarkEndValueRef.current = benchmarkEndValue;
        xAxisMemoRef.current = xAxis;
        xAxisTypeRef.current = xAxisType;
        widthRef.current = width;
        scoreLinesRef.current = scoreLines;
        d3.selectAll(`#graph-svg-${graphRandomIdRef.current}`).remove();

        let xAxisWidthCalculation = width + 80;
        let transformWidthCalculation = margin.left;

        if (matchesLgDownBreakpoint) {
            xAxisWidthCalculation = width + 20;
            transformWidthCalculation = transformWidthCalculation - 6;
        } else if (matches800DownBreakpoint) {
            xAxisWidthCalculation = width + 10;
            transformWidthCalculation = transformWidthCalculation - 10;
        }

        let svg = d3
            .select(graphHolderBoxRef.current)
            .append('svg')
            .attr('class', 'graph-svg')
            .attr('id', `graph-svg-${graphRandomIdRef.current}`)
            // 110% is because we need extra space to show value on point hover
            .attr('width', matchesMdDownBreakpoint ? '100%' : '110%')
            // .attr('width', matchesLgDownBreakpoint ? '100%' : width + margin.left + margin.right)
            .attr('height', height + margin.top + margin.bottom)
            .append('g')
            .attr('transform', `translate(${transformWidthCalculation + ',' + margin.top})`);

        // X AXIS
        const firstDaysOfMonth: number[] = [];
        let previosTickMonth: number | undefined = undefined;
        let x: any;

        if (xAxisType === EAxisType.LINEAR) {
            x = d3.scalePoint().domain(xAxis).range([0, xAxisWidthCalculation]);
        } else if (xAxisType === EAxisType.MONTHLY_WITH_WEEKS) {
            x = d3
                .scaleTime()
                .domain([getFirstDate(xAxis[0]), getLastDate(xAxis[xAxis.length - 1])])
                .range([0, xAxisWidthCalculation]);
        } else if (xAxisType === EAxisType.TIME) {
            x = d3
                .scaleTime()
                .domain([getFirstDate(xAxis[0]), getLastDate(xAxis[xAxis.length - 1])])
                .range([0, xAxisWidthCalculation]);
        }

        let fontSize = '11px';
        if (matchesLgDownBreakpoint) fontSize = '9px';
        if (matches800DownBreakpoint) fontSize = '8px';
        if (matchesMdDownBreakpoint) fontSize = '6px';

        svg.append('g')
            .attr('transform', 'translate(0,' + height + ')')
            .style('font-family', 'Open Sans')
            .style('font-size', fontSize)
            .style('font-weight', 600)
            .style('letter-spacing', '0.5px')
            .style('color', '#8C8C8C')
            .call(
                d3
                    .axisBottom(x)
                    .ticks(d3.timeWeek)
                    .tickFormat((interval, index) => {
                        // Hide individual ticks and rename first date ticks to month names
                        if (xAxisType === EAxisType.MONTHLY_WITH_WEEKS) {
                            const lastDate = xAxis[xAxis.length - 1];
                            const lastMonth = parse(lastDate, 'MM/d/yy', new Date()).getMonth();

                            const month = (interval as Date).getMonth();
                            if (
                                (firstDaysOfMonth.includes(month) && previosTickMonth === month) ||
                                (lastMonth === month && index < 3)
                            ) {
                                previosTickMonth = month;
                                return '';
                            }
                            previosTickMonth = month;
                            firstDaysOfMonth.push(month);
                            return (interval as Date).toLocaleString('en-US', { month: 'short' });
                        }
                        return isDate(interval)
                            ? formatLocalDate(interval as Date, EFormatDate.DAY_AND_MONTH)
                            : interval.toString();
                    })
                    .tickSize(0)
                    .tickPadding(14)
            );
        d3.selectAll('svg .domain').attr('stroke', Colors.X_SCALE).attr('stroke-width', 2);

        const yAxisTickMaxValue = yMaxValue || findMaxValue(scoreLines || []);
        // Y AXIS
        let y = d3
            .scaleLinear()
            .domain([
                0,
                yMaxValue ? yMaxValue : scoreLines && scoreLines?.length > 0 ? findMaxValue(scoreLines) || 1 : 100,
            ])
            .range([height, 0]);

        let yTickFontSize = '15px';
        if (matchesLgDownBreakpoint) {
            yTickFontSize = '12px';
        }
        if (yAxisTickMaxValue && yAxisTickMaxValue > 1000) {
            yTickFontSize = '12px';
            if (matchesLgDownBreakpoint) {
                yTickFontSize = '9px';
            }
        }

        svg.append('g')
            .call(
                d3
                    .axisLeft(y)
                    .tickFormat((d: any) => {
                        return Number.isInteger(d) ? d : '';
                    })
                    .tickSizeInner(yAxisTickMaxValue && yAxisTickMaxValue > 10000 ? 0 : 6)
            )
            .attr('id', 'yaxis')
            .style('font-weight', 600)
            .style('letter-spacing', '0.5px')
            .style('font-size', yTickFontSize)
            .style('color', '#A9A9A9')
            .style('font-family', 'Open Sans')
            .call((g) => g.select('.domain').remove());

        // AXIS COMMON
        svg.selectAll('svg g.tick line').attr('x2', null);
        svg.selectAll('svg g.tick line').attr('y2', null);

        const coordinates = document.getElementById('yaxis')?.getBoundingClientRect();

        if (benchmarkStartValue && benchmarkEndValue && coordinates) {
            const rectHeight =
                ((coordinates.bottom - coordinates.top) / yAxisTickMaxValue!) *
                (benchmarkEndValue - benchmarkStartValue);
            svg.append('rect')
                .attr('x', 5)
                .attr('y', coordinates.height - 10 - benchmarkEndValue * (coordinates.height / yAxisTickMaxValue))
                .attr('width', width + 10)
                .attr('height', rectHeight)
                .attr('fill', 'rgba(0, 0, 0, 0.1)')
                .attr('strokeWidth', '2')
                .attr('stroke-dasharray', `${width + 10} ${rectHeight}`)
                .attr('stroke', 'rgba(97, 96, 99, 1)');
        } else if (benchmarkStartValue && coordinates) {
            const yCoordinate =
                coordinates.height - 15 - ((coordinates.height - 15) / yAxisTickMaxValue!) * benchmarkStartValue;
            svg.append('line')
                .attr('x1', 5)
                .attr('y1', yCoordinate)
                .attr('x2', width + 10)
                .attr('y2', yCoordinate)
                .attr('fill', 'rgba(0, 0, 0, 0.1)')
                .attr('stroke-width', '2')
                .attr('stroke-dasharray', `5 5`)
                .attr('stroke', 'rgba(97, 96, 99, 1)');
        }

        if (isDataEmpty) {
            svg.append('text')
                .attr('x', '46%')
                .attr('y', '40%')
                .attr('text-anchor', 'middle')
                .attr('fill', '#8C8C8C')
                .style('font-family', 'Open Sans')
                .style('font-size', '18px')
                .style('font-weight', 500)
                .style('letter-spacing', '0.5px')
                .text('No data');
        } else {
            if (xAxisType === EAxisType.LINEAR) {
                scoreLines?.forEach((scoreLine) => {
                    const path = svg
                        .append('path')
                        .datum(
                            scoreLine.scores.filter((d) => {
                                if (d.value === undefined) return false;
                                return d.value >= 0;
                            })
                        )
                        .interrupt()
                        .attr('id', `scoreLinePath-${scoreLine.id}`)
                        .attr('fill', 'none')
                        .attr('stroke', scoreLine.color)
                        .attr('stroke-width', 7)
                        .attr(
                            'd',
                            d3.line(
                                function (d) {
                                    return x(d.date);
                                },
                                function (d) {
                                    return y(d.value!);
                                }
                            )
                        );
                    const pathLength = path.node()?.getTotalLength() ?? 0;
                    path.attr('stroke-dashoffset', pathLength).attr('stroke-dasharray', pathLength);
                    path.transition('tw').duration(transitionDuration).ease(d3.easeSin).attr('stroke-dashoffset', 0);
                });
            } else if (xAxisType === EAxisType.MONTHLY_WITH_WEEKS) {
                [...(scoreLines || [])].reverse().forEach((scoreLine) => {
                    let startedDrawingGraph: boolean = false;
                    let formattedCompleteTestData: ISkillGraphDataItem[] = scoreLine.scores.map((item, index) => {
                        if (!startedDrawingGraph && item.value !== undefined) startedDrawingGraph = true;
                        let dotValue: number | undefined;
                        if (item.value !== undefined) dotValue = item.value;
                        else if (startedDrawingGraph) {
                            dotValue = scoreLine.scores[index - 1].value;
                        } else dotValue = undefined;
                        return {
                            value: dotValue,
                            day: item.date ?? null,
                        };
                    });

                    let line = d3
                        .line<ISkillGraphDataItem>()
                        .defined(function (d) {
                            return d.value !== undefined;
                        })
                        .x(function (d, index) {
                            return d.day instanceof Date ? x(d.day) : index;
                        })
                        .y(function (d) {
                            return y(d.value!);
                        });

                    let formattedWithEmptyData = formattedCompleteTestData.filter(line.defined());
                    const path = svg
                        .append('path')
                        .attr('id', `scoreLinePath-${scoreLine.id}`)
                        .attr('d', line(formattedWithEmptyData))
                        .attr('fill', 'none')
                        .attr('stroke', scoreLine.color)
                        .attr('stroke-width', 7);

                    const pathLength = path.node()?.getTotalLength() ?? 0;
                    path.attr('stroke-dashoffset', pathLength).attr('stroke-dasharray', pathLength);
                    path.transition('tw').duration(transitionDuration).ease(d3.easeSin).attr('stroke-dashoffset', 0);
                });
            } else {
                if (scoreLines) {
                    [...scoreLines].reverse().forEach((scoreLine) => {
                        let startedDrawingGraph: boolean = false;
                        let formattedCompleteTestData: ISkillGraphDataItem[] = scoreLine.scores.map((item, index) => {
                            if (!startedDrawingGraph && item.value !== undefined) startedDrawingGraph = true;
                            let dotValue: number | undefined;
                            if (item.value !== undefined) dotValue = item.value;
                            else dotValue = undefined;
                            return {
                                value: dotValue,
                                day: item.date ?? null,
                            };
                        });

                        let line = d3.line<ISkillGraphDataItem>().defined(function (d) {
                            return d.value !== undefined;
                        });

                        line.x(function (d, index) {
                            return d.day instanceof Date ? x(d.day) : index;
                        }).y(function (d) {
                            return y(d.value!);
                        });

                        let formattedWithEmptyData = formattedCompleteTestData.filter(line.defined());
                        const pathGap = svg
                            .append('path')
                            .attr('id', `scoreLinePath-${scoreLine.id}`)
                            .attr('d', line(formattedWithEmptyData))
                            .attr('fill', 'none')
                            .attr('stroke', scoreLine.color)
                            .attr('stroke-width', 7);

                        const pathGapLength = pathGap.node()?.getTotalLength() ?? 0;
                        pathGap.attr('stroke-dashoffset', pathGapLength).attr('stroke-dasharray', pathGapLength);
                        pathGap
                            .transition('tw')
                            .duration(transitionDuration)
                            .ease(d3.easeSin)
                            .attr('stroke-dashoffset', 0);
                    });
                }
            }

            [...(scoreLines || [])].reverse().forEach((scoreLine) => {
                svg.selectAll('.dot-past-' + scoreLine.id)
                    .data(scoreLine?.scores.filter((d) => d.value !== undefined))
                    .enter()
                    .append('g')
                    .attr('transform', (d) => 'translate(' + x(d.date) + ',' + y(d.value!) + ')')
                    .attr('id', function (d, i) {
                        return 'score-wrapper-circle-' + scoreLine.id + '-' + i;
                    })
                    .on('mouseenter', function (d) {
                        const value = d.target?.__data__?.value;
                        if (value !== undefined && value >= 0 && d?.target?.id) {
                            d3.select(`#${d.target.id} circle`).transition().duration(100).style('opacity', 1);
                            d3.select(`#${d.target.id}`)
                                .append('text')
                                .attr('id', `${d.target.id}-text`)
                                .text(value)
                                .attr('fill', theme.palette.common.white)
                                .attr('font-size', '18px')
                                .attr('text-anchor', 'middle')
                                .attr('dominant-baseline', 'middle')
                                .style('font-weight', 700)
                                .style('opacity', 1);
                        }
                    })
                    .on('mouseleave', function (d) {
                        if (d?.target?.id) {
                            d3.select(`#${d.target.id} circle`).transition().duration(100).style('opacity', 0);
                            d3.select(`#${d.target.id}-text`).remove();
                        }
                    })
                    .append('circle')
                    .attr('class', 'dot-past')
                    .attr('r', function (d) {
                        return '25px';
                    })
                    .attr('id', function (d, i) {
                        return 'score-circle' + scoreLine.id + '-' + i;
                    })
                    .attr('fill', (d) => {
                        return scoreLine.color;
                    })
                    .style('filter', 'brightness(90%)')
                    .attr('stroke', scoreLine.color)
                    .attr('stroke-opacity', 0.5)
                    .attr('opacity', (d) => {
                        return 0;
                    })
                    .attr('stroke-width', (d) => {
                        return '6px';
                    });
            });
        }
    }, [yMaxValue, width, height, margin, xAxis, isDataEmpty, scoreLines, benchmarkEndValue, benchmarkStartValue]);

    useEffect(() => {
        scoreLines?.forEach((scoreLine) => {
            const scoreLinePath = document.getElementById(`scoreLinePath-${scoreLine.id}`);
            if (scoreLinePath) {
                if (visibleLineIds?.includes(scoreLine.id)) {
                    const scoreLineElement = d3.select(
                        `#graph-svg-${graphRandomIdRef.current} #scoreLinePath-${scoreLine.id}`
                    );
                    scoreLinePath.setAttribute('display', 'block');

                    for (let i = 0; i < 15; i++) {
                        d3.selectAll(`#score-wrapper-circle-${scoreLine.id}-${i}`).each(function (d) {
                            const item = d3.select(this);
                            item.attr('display', 'block');
                        });
                    }

                    if (scoreLineElement?.attr('opacity')?.toString() !== '0.85') {
                        scoreLinePath.setAttribute('opacity', '0.85');
                        // scoreLinePath.setAttribute('display', 'initial');
                        //@ts-ignore
                        const pathLength = scoreLineElement.node()?.getTotalLength();
                        scoreLineElement.attr('stroke-dashoffset', pathLength).attr('stroke-dasharray', pathLength);
                        scoreLineElement
                            .transition('tw')
                            .duration(transitionDuration)
                            .ease(d3.easeSin)
                            .attr('stroke-dashoffset', 0);
                    }
                } else {
                    scoreLinePath.setAttribute('display', 'none');
                    const scoreLineElement = d3.select(
                        `#graph-svg-${graphRandomIdRef.current} #scoreLinePath-${scoreLine.id}`
                    );
                    scoreLineElement.style('pointer-events', 'none');

                    for (let i = 0; i < 15; i++) {
                        d3.selectAll(`#score-wrapper-circle-${scoreLine.id}-${i}`).each(function (d) {
                            const item = d3.select(this);
                            item.attr('display', 'none');
                        });
                    }
                }
            }
        });
    }, [visibleLineIds]);

    const onDisplayScoreLineChange = (scoreLineId: string) => {
        if (visibleLineIds && scoreLines && scoreLines?.length > 1) {
            if (visibleLineIds?.includes(scoreLineId))
                setVisibleLinesIds(visibleLineIds.filter((visibleLineId) => visibleLineId !== scoreLineId));
            else setVisibleLinesIds([...visibleLineIds, scoreLineId]);
        }
    };

    return (
        <RootBox>
            <GraphBox id="graph-holder-box" ref={graphHolderBoxRef}>
                {isLoading ? <Loading /> : <></>}
                {isDataEmpty ? <NoDataCard boxStyle={{ minHeight: '500px' }} /> : <></>}
            </GraphBox>
            {(scoreLines && scoreLines?.length > 1) ||
            (scoreLines && scoreLines.length >= 0 && (benchmarkStartValue || benchmarkEndValue)) ? (
                <ScoreLegendRootBox id="graph-legend-box">
                    {graphLegendLabel && <LegendLabelTypography>{graphLegendLabel}</LegendLabelTypography>}
                    {benchmarkStartValue && benchmarkEndValue && (
                        <ScoreLegendBox isActive>
                            <BenchmarkRangeIcon />
                            <LegendNameTypography isDisabled variant="button">
                                Benchmark Range
                            </LegendNameTypography>
                        </ScoreLegendBox>
                    )}
                    {benchmarkStartValue && !benchmarkEndValue && (
                        <ScoreLegendBox isActive>
                            <BenchmarkIcon />
                            <LegendNameTypography isDisabled variant="button">
                                Benchmark
                            </LegendNameTypography>
                        </ScoreLegendBox>
                    )}
                    {scoreLines?.map((scoreLine) => {
                        return (
                            <ScoreLegendBox
                                key={scoreLine.id}
                                isActive={!!visibleLineIds?.includes(scoreLine.id)}
                                onClick={() => onDisplayScoreLineChange(scoreLine.id)}
                                isDisabled={scoreLines && scoreLines.length === 1}
                            >
                                <Tooltip title={scoreLine?.tooltip || ''}>
                                    <LegendCircle color={scoreLine?.legendColor || scoreLine.color} />
                                </Tooltip>
                                <LegendNameTypography
                                    variant="button"
                                    isDisabled={scoreLines && scoreLines.length === 1}
                                >
                                    {scoreLine.name}
                                </LegendNameTypography>
                            </ScoreLegendBox>
                        );
                    })}
                </ScoreLegendRootBox>
            ) : (
                <></>
            )}
        </RootBox>
    );
};

export default Graph;
