import { FormControl, MenuItem, Select } from '@mui/material';
import styled from '../../theme/styled';

export const RootBox = styled('div', {
    shouldForwardProp: (prop) => prop !== 'isDisabled',
})<{ isDisabled: boolean }>(({ isDisabled }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '8px',
    opacity: isDisabled ? 0.4 : 1,
}));

export const TitleBox = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
    paddingBottom: '8px',
});

export const TitleSpan = styled('span')({
    flex: 1,
    letterSpacing: '2px',
    fontSize: 12,
    fontFamily: 'Ubuntu',
    lineHeight: '16px',
    textTransform: 'uppercase',
    color: 'rgba(0, 0, 0, 0.88)',
    minHeight: 19,
    display: 'flex',
    alignItems: 'center',
});

export const ClearAllSpan = styled('span')(({ theme }) => ({
    textAlign: 'end',
    fontFamily: 'Open Sans',
    fontWeight: 600,
    fontSize: 10,
    letterSpacing: '0.42px',
    color: theme.palette.primary.main,
    cursor: 'pointer',
    borderRadius: 4,
    padding: '2px 4px',
    '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
}));

export const StyledFieldset = styled('fieldset')({
    border: 0,
    margin: 0,
    padding: 0,
});

export const FilterDropdownCategoryBox = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    padding: '20px 0',
});

export const StyledSelect = styled(Select<string[]>)(({ theme }) => ({
    '.MuiSvgIcon-root': {
        top: 3,
    },
    '.MuiSelect-select': {
        outline: '0 !important',
        width: '210px',
        overflow: 'hidden',
        display: 'inline-block !important',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        position: 'relative',
        top: '2px',
        [theme.breakpoints.down('lg')]: {
            fontSize: '14px !important',
        },
    },
}));

export const StyledFormControl = styled(FormControl)(({ theme }) => ({
    width: 210,
    display: 'flex',
    margin: 1,
    marginRight: '25px',
    [theme.breakpoints.down('md')]: {
        marginRight: '5px',
        width: '150px',
    },
    '& svg': {
        fontSize: '19px',
    },
}));

export const MenuItemTitleSpan = styled('span')({
    paddingLeft: 6,
    fontFamily: 'Open Sans',
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '28px',
    letterSpacing: '0.5px',
});

export const StyledMenuItem = styled(MenuItem)({});
