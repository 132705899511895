import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

export const reactPlugin = new ReactPlugin();
// export const appInsights = new ApplicationInsights({
//     config: {
//         instrumentationKey: config.APP_INSIGHTS_INSTRUMENTATION_KEY,
//         enableAutoRouteTracking: true,
//         extensions: [reactPlugin],
//     },
// });

export const appInsightsInit = (userId: string) => {
    const appInsights = new ApplicationInsights({
        config: {
            instrumentationKey: process.env.REACT_APP_APP_INSIGHTS_INSTRUMENTATION_KEY,
            enableAutoRouteTracking: true,
            accountId: userId,
            autoTrackPageVisitTime: true,
            extensions: [reactPlugin],
        },
    });
    appInsights.loadAppInsights();
};
