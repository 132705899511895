import { Typography } from '@mui/material';
import styled from '../../ui/theme/styled';

export const RootBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
}));

export const InfoBox = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '18px',
    padding: '42px',
}));

export const InfoOutcomeBadgeBox = styled('div')({});

export const InfoOutcomeLevelBox = styled(Typography, {
    shouldForwardProp: (prop) => prop !== 'isActive',
})<{ isError: boolean }>(({ theme, isError }) => ({
    textAlign: 'center',
    color: isError ? '#9B0909' : theme.palette.primary.main,
    margin: '42px auto',
}));

export const LoadingBox = styled('div')({});

export const ErrorBox = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '32px',
});

export const InfoOutcomeRedirectCountBox = styled(Typography)(({ theme }) => ({
    textAlign: 'center',
    color: theme.palette.gray.medium,
}));

export const RedirectIntervalCountSpan = styled('span')({});
