import { Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { FC } from 'react';
import { useFiltersStateValue } from '../../../contexts/FiltersContext';
import { RootBox } from './Style';

interface IProps {
    key: string;
    name: string;
}

const SimpleCheckboxFilter: FC<IProps> = ({ key, name }) => {
    const { changeActiveFilter, activeFilters } = useFiltersStateValue();

    const handleChangeFilter = (key: string) => {
        changeActiveFilter(key);
    };

    return (
        <RootBox>
            <Checkbox checked={activeFilters.includes(key || '')} onChange={() => handleChangeFilter(key || '')} />
            <Typography variant="body2">{name}</Typography>
        </RootBox>
    );
};

export default SimpleCheckboxFilter;
