import { FC } from 'react';
import { useUUID } from '../../hooks/useUUID';

const LoginIcon: FC = () => {
    const id = useUUID('loginIconTitleId');

    return (
        <svg
            width="220"
            height="46"
            viewBox="0 0 220 46"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={id}
            role="img"
        >
            <title id={id}>Login</title>

            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M33 44.3389H27.3577V13.0972L48.5517 33.6535C52.3922 36.9118 55.9957 38.493 59.5517 38.493C67.8017 38.493 74.5345 31.6889 74.5345 23.3514C74.5345 15.0139 67.8017 8.20971 59.5517 8.20971H54.8577V2.55554H59.5517C70.931 2.55554 80.1767 11.8993 80.1767 23.3993C80.1767 34.8993 70.931 44.243 59.5517 44.243C54.5733 44.243 49.7845 42.1826 44.8534 37.966L44.7112 37.8701L33 26.5139V44.3389ZM24.3707 44.291H20.625C9.24569 44.291 0 34.9472 0 23.4472C0 11.9472 9.24569 2.60346 20.625 2.60346C21.2471 2.60346 21.8693 2.64555 22.5305 2.69028L22.6638 2.69929C26.4095 3.08263 29.9655 4.47221 33 6.77221L33.1897 6.91596L35.2759 8.78471L46.4181 19.2305V2.60346H52.1078V32.2639L31.4353 13.0014L29.4914 11.2764C27.3103 9.64721 24.75 8.68888 22.0948 8.40137C21.6207 8.35346 21.0991 8.30554 20.625 8.30554C12.375 8.35346 5.64224 15.1576 5.64224 23.4951C5.64224 31.8326 12.375 38.6368 20.625 38.6368H24.3707V44.291ZM98.431 8.73679L108.625 26.8014L108.388 8.73679H113.746V33.893H107.677L96.9612 14.8701L97.1983 33.893H91.8405V8.73679H98.431ZM134.75 30.1555C130.435 30.1555 129.155 28.2868 129.155 23.8305V8.73679H123.94V23.9264C123.94 27.0889 124.224 29.293 125.362 30.6826C127.401 33.1264 130.435 34.1326 134.75 34.1326C139.065 34.1326 142.052 33.1264 144.09 30.6826C145.228 29.341 145.56 27.1368 145.56 23.9264V8.73679H140.345V23.8305C140.345 28.2868 139.065 30.1555 134.75 30.1555ZM157.509 31.3055C155.612 29.293 155.233 27.0889 155.233 23.7347V8.73679H160.448V22.5368V23.4951C160.448 25.5555 160.496 27.0889 161.302 28.0472C162.392 29.3889 163.909 29.6764 166.138 29.6764H172.302V33.8451H165.664C162.108 33.941 159.737 33.6535 157.509 31.3055ZM180.362 8.73679V33.941L185.815 33.893V8.73679H180.362ZM208.905 8.73679L219.526 33.893H213.694L211.418 27.9035H200.608L198.379 33.893H192.832L203.358 8.73679H208.905ZM202.267 23.4472H209.711L205.918 13.768L202.267 23.4472Z"
                fill="white"
                aria-labelledby={id}
                role="img"
            />
            <mask id="mask0" maskUnits="userSpaceOnUse" x="0" y="2" width="220" height="43">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M33 44.3389H27.3577V13.0972L48.5517 33.6535C52.3922 36.9118 55.9957 38.493 59.5517 38.493C67.8017 38.493 74.5345 31.6889 74.5345 23.3514C74.5345 15.0139 67.8017 8.20971 59.5517 8.20971H54.8577V2.55554H59.5517C70.931 2.55554 80.1767 11.8993 80.1767 23.3993C80.1767 34.8993 70.931 44.243 59.5517 44.243C54.5733 44.243 49.7845 42.1826 44.8534 37.966L44.7112 37.8701L33 26.5139V44.3389ZM24.3707 44.291H20.625C9.24569 44.291 0 34.9472 0 23.4472C0 11.9472 9.24569 2.60346 20.625 2.60346C21.2471 2.60346 21.8693 2.64555 22.5305 2.69028L22.6638 2.69929C26.4095 3.08263 29.9655 4.47221 33 6.77221L33.1897 6.91596L35.2759 8.78471L46.4181 19.2305V2.60346H52.1078V32.2639L31.4353 13.0014L29.4914 11.2764C27.3103 9.64721 24.75 8.68888 22.0948 8.40137C21.6207 8.35346 21.0991 8.30554 20.625 8.30554C12.375 8.35346 5.64224 15.1576 5.64224 23.4951C5.64224 31.8326 12.375 38.6368 20.625 38.6368H24.3707V44.291ZM98.431 8.73679L108.625 26.8014L108.388 8.73679H113.746V33.893H107.677L96.9612 14.8701L97.1983 33.893H91.8405V8.73679H98.431ZM134.75 30.1555C130.435 30.1555 129.155 28.2868 129.155 23.8305V8.73679H123.94V23.9264C123.94 27.0889 124.224 29.293 125.362 30.6826C127.401 33.1264 130.435 34.1326 134.75 34.1326C139.065 34.1326 142.052 33.1264 144.09 30.6826C145.228 29.341 145.56 27.1368 145.56 23.9264V8.73679H140.345V23.8305C140.345 28.2868 139.065 30.1555 134.75 30.1555ZM157.509 31.3055C155.612 29.293 155.233 27.0889 155.233 23.7347V8.73679H160.448V22.5368V23.4951C160.448 25.5555 160.496 27.0889 161.302 28.0472C162.392 29.3889 163.909 29.6764 166.138 29.6764H172.302V33.8451H165.664C162.108 33.941 159.737 33.6535 157.509 31.3055ZM180.362 8.73679V33.941L185.815 33.893V8.73679H180.362ZM208.905 8.73679L219.526 33.893H213.694L211.418 27.9035H200.608L198.379 33.893H192.832L203.358 8.73679H208.905ZM202.267 23.4472H209.711L205.918 13.768L202.267 23.4472Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0)" />
        </svg>
    );
};

export default LoginIcon;
