import styled from '../../../../../ui/theme/styled';

export const SearchInputWrapper = styled('div')(({ theme }) => ({
    minWidth: 238,
    width: '100%',
    padding: '16px 16px 8px 16px',
    background: theme.palette.common.white,
    [theme.breakpoints.down('md')]: {
        width: 183,
    },
    [theme.breakpoints.down('sm')]: {
        width: 133,
    },
}));

export const TableWrapper = styled('div')({
    flex: 4,
});

export const TableFiltersWrapper = styled('div')({
    width: '100%',
    display: 'flex',
    gap: '20px',
});
