import { useEffect, useRef, useState } from 'react';
import { IUserImageResponse, usePostUserImagesQuery } from '../services/UserQueryService';
import { findNonExistingStringArrayElements } from '../utils/findNonExistingStringArrayElements';

interface IUseFetchImagesResult {
    images?: IUserImageResponse[];
    changeImageIds: (previousIds: string[], ids: string[], nextIds: string[]) => void;
    imageIds: string[];
}

const useFetchImages: () => IUseFetchImagesResult = () => {
    const [images, setImages] = useState<IUserImageResponse[] | undefined>();
    const [imageIds, setImageIds] = useState<string[]>([]);
    const [fullImageIdList, setFullImageIdList] = useState<string[]>([]);
    const { mutateAsync: mutatePostUserImagesAsync } = usePostUserImagesQuery();
    const nextImagesToFetchRef = useRef<string[]>([]);
    const previousImagesToFetchRef = useRef<string[]>([]);

    const fetchImages = async (imagesToFetchIdList: string[]) => {
        const response = await mutatePostUserImagesAsync({
            userIds: imagesToFetchIdList,
        });
        setImages((images) => {
            if (images) {
                var ids = new Set(images.map((d) => d.id));
                return [...images, ...response.filter((d) => !ids.has(d.id))];
            }
            return response;
        });
        return true;
    };

    useEffect(() => {
        const initiateImageFetch = async () => {
            const imagesToFetchIdList: string[] = [];
            imageIds.forEach((id) => {
                if (
                    !images ||
                    !images.find((image) => {
                        return image.id === id;
                    })
                )
                    imagesToFetchIdList.push(id);
            });
            if (imagesToFetchIdList.length > 0) {
                await fetchImages(imagesToFetchIdList);
            }
            const nextDiffArray = findNonExistingStringArrayElements(nextImagesToFetchRef.current, fullImageIdList);
            if (nextDiffArray && nextDiffArray.length > 0) {
                await fetchImages(nextDiffArray);
                setFullImageIdList((imageIdList) => [...imageIdList, ...nextDiffArray]);
            }
            const previosDiffArray = findNonExistingStringArrayElements(
                previousImagesToFetchRef.current,
                fullImageIdList
            );
            if (previosDiffArray && previosDiffArray.length > 0) {
                await fetchImages(previosDiffArray);
                setFullImageIdList((imageIdList) => [...imageIdList, ...previosDiffArray]);
            }
        };
        initiateImageFetch();
    }, [imageIds]);

    const changeImageIds = (previousIds: string[], currentIds: string[], nextIds: string[]) => {
        setImageIds(currentIds);
        setFullImageIdList((fullImageList) => [...fullImageList, ...currentIds]);
        previousImagesToFetchRef.current = previousIds;
        nextImagesToFetchRef.current = nextIds;
    };

    return {
        images,
        changeImageIds,
        imageIds,
    } as const;
};

export default useFetchImages;
