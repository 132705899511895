import { FC } from 'react';

const ToCollapseIcon: FC = () => {
    return (
        <svg width="15" height="8" viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.0133 7.70711C13.4201 8.09763 14.0796 8.09763 14.4864 7.70711C14.8932 7.31658 14.8932 6.68342 14.4864 6.29289L8.23641 0.292893C7.82961 -0.0976305 7.17006 -0.0976305 6.76327 0.292893L0.513267 6.29289C0.106471 6.68342 0.106471 7.31658 0.513267 7.70711C0.920063 8.09763 1.57961 8.09763 1.98641 7.70711L7.49984 2.41421L13.0133 7.70711Z"
                fill="black"
                fillOpacity="0.4"
            />
        </svg>
    );
};

export default ToCollapseIcon;
