import { Controls, MediaPlayer } from '@vidstack/react';
import styled from '../../theme/styled';
import Backdrop from '@mui/material/Backdrop';

export const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
    color: theme.palette.common.white,
    zIndex: 1000,
}));

export const StyledControlGroup = styled(Controls.Group)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '12px',
    '& svg': {
        color: 'rgba(0, 0, 0, 0.4)',
        height: '40px',
        width: '40px',
        zIndex: 100,
    },
    '&:hover svg': {
        cursor: 'pointer',
        borderRadius: '8px',
        background: 'rgba(255, 255, 255, 0.2)',
        color: 'rgba(0, 0, 0, 0.8)',
    },
    [theme.breakpoints.down(905)]: {
        padding: '44px 4px',
        '& svg': {
            height: '34px',
            width: '34px',
        },
    },
}));

export const StyledMediaPlayer = styled(MediaPlayer, {
    shouldForwardProp: (prop) => prop !== 'isPopupMode',
})<{ isPopupMode: boolean }>(({ isPopupMode }) => ({
    '&[data-media-player][data-layout="video"]:not([data-fullscreen])': {
        border: 0,
        borderRadius: 0,
    },
    '& [data-media-provider]': {
        background: '#FFF !important',
    },
    '&[data-media-player][data-layout="video"]': {
        backgroundColor: '#FFF',
    },
    '& :where(.vds-video-layout .vds-controls[data-visible])': {
        backgroundImage: !isPopupMode && 'none',
    },

    '& .vds-controls-group:not(:has(.vds-play-button))': {
        display: isPopupMode ? 'inherit' : 'none',
    },
    '& .vds-play-button': {
        marginBottom: isPopupMode ? 0 : '16px',
    },
    '.vds-video-layout[data-sm] .vds-controls .vds-play-button': {
        backgroundColor: isPopupMode ? 'initial' : 'transparent',
    },
    boxShadow: '0px 5px 16px 0px rgba(0, 0, 0, 0.08), 0px 2px 8px 0px rgba(0, 0, 0, 0.08)',
    '& div[data-part="cue-display"]': {
        top: isPopupMode ? 'initial' : '110px',
    },
}));
