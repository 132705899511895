import { FC } from 'react';

const OutcomesIcon: FC = () => {
    return (
        <svg width="24px" height="20px" viewBox="0 0 24 20" xmlns="http://www.w3.org/2000/svg" role="img">
            <title>Outcomes</title>

            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(-36.000000, -274.000000)">
                    <g transform="translate(0.000000, 252.000000)">
                        <g transform="translate(36.000000, 20.000000)">
                            <rect id="frame" x="0" y="0" width="24" height="24" />
                            <path
                                d="M20.5,10.2 C19.9477153,10.2 19.5,9.75228475 19.5,9.2 C19.5,8.64771525 19.9477153,8.2 20.5,8.2 L21.5,8.2 C22.0522847,8.2 22.5,8.64771525 22.5,9.2 C22.5,9.75228475 22.0522847,10.2 21.5,10.2 L20.5,10.2 Z M1.5,10.2 C0.94771525,10.2 0.5,9.75228475 0.5,9.2 C0.5,8.64771525 0.94771525,8.2 1.5,8.2 L12.5,8.2 C13.0522847,8.2 13.5,8.64771525 13.5,9.2 C13.5,9.75228475 13.0522847,10.2 12.5,10.2 L1.5,10.2 Z M1.5,4.2 C0.94771525,4.2 0.5,3.75228475 0.5,3.2 C0.5,2.64771525 0.94771525,2.2 1.5,2.2 L21.5,2.2 C22.0522847,2.2 22.5,2.64771525 22.5,3.2 C22.5,3.75228475 22.0522847,4.2 21.5,4.2 L1.5,4.2 Z M1.5,16.2 C0.94771525,16.2 0.5,15.7522847 0.5,15.2 C0.5,14.6477153 0.94771525,14.2 1.5,14.2 L7.5,14.2 C8.05228475,14.2 8.5,14.6477153 8.5,15.2 C8.5,15.7522847 8.05228475,16.2 7.5,16.2 L1.5,16.2 Z M15.6099216,9.26419249 C15.9814949,8.5386025 17.0185051,8.5386025 17.3900784,9.26419249 L19.0074892,12.4225925 L22.6395103,12.9343009 C23.4708423,13.0514257 23.7965285,14.0789398 23.1844595,14.6535725 L20.5794475,17.0992523 L21.1925357,20.5448161 C21.3370362,21.3569107 20.4915922,21.9848927 19.7558667,21.6119504 L16.5,19.9615382 L13.2441333,21.6119504 C12.5084078,21.9848927 11.6629638,21.3569107 11.8074643,20.5448161 L12.4205525,17.0992523 L9.81554053,14.6535725 C9.20347148,14.0789398 9.52915765,13.0514257 10.3604897,12.9343009 L13.9925108,12.4225925 L15.6099216,9.26419249 Z M15.5360784,13.7962071 C15.3877479,14.0858596 15.1077514,14.2852203 14.7855103,14.3306202 L12.7056761,14.623644 L14.1844595,16.0119796 C14.4308649,16.2433139 14.5437446,16.5834599 14.4845357,16.9162144 L14.12767,18.9218048 L16.0478667,17.9484492 C16.3320746,17.804383 16.6679254,17.804383 16.9521333,17.9484492 L18.87233,18.9218048 L18.5154643,16.9162144 C18.4562554,16.5834599 18.5691351,16.2433139 18.8155405,16.0119796 L20.2943239,14.623644 L18.2144897,14.3306202 C17.8922486,14.2852203 17.6122521,14.0858596 17.4639216,13.7962071 L16.5,11.9139086 L15.5360784,13.7962071 Z"
                                fill="currentColor"
                                fillRule="nonzero"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default OutcomesIcon;
