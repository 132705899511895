import { createContext, FC, useContext, PropsWithChildren, useEffect, useState, useRef, useCallback } from 'react';
import { isBefore } from 'date-fns';
import { useTheme } from '@mui/system';
import { useGetInsightsOrganizationUsersCompetenceQuery } from '../services/InsightsQueryService';
import { IScoreLine } from '../interfaces/IScoreLine';
import { EGraphCardSelect } from '../interfaces/enums/EGraphCardSelect';
import useFilterSearch from '../hooks/useFilterSearch';
import { IInsightsOrganizationUsersCompetenceVM } from '../interfaces/views/IInsightsOrganizationUsersCompetenceVM';
import {
    insightsOrganizationUsersByDepartmentFilterCallback,
    insightsOrganizationUsersByRegionFilterCallback,
    insightsOrganizationUsersCategories,
} from './util/filterCategories';
import { IFilterCategories, IFilterCategoryValue } from '../ui/filters/filters/Filters';
import expandDatesToLastYearPerWeek from '../utils/expandDatesToLastYearPerWeek';

export interface IInsightsOrganizationalUsersContext {
    data?: IInsightsOrganizationUsersCompetenceVM[];
    latestDateData: IInsightsOrganizationUsersCompetenceVM[];
    scoreLines?: IScoreLine[];
    searchText: string;
    setSearchText: (searchText: string) => void;
    changeScoreLinesInterval: (option: EGraphCardSelect) => void;
    filterCategories: IFilterCategories[];
    onFilterValueChange: (
        filterCategories: IFilterCategories[],
        activeFilters: string[],
        dontRunAnythingChange?: boolean
    ) => void;
    isUsersCompetenceDataLoading: boolean;
}

export const InsightsOrganizationalUsersContext = createContext<IInsightsOrganizationalUsersContext>(
    {} as IInsightsOrganizationalUsersContext
);

export enum EUsersScoreLineType {
    SKILLS = 'Skills',
    OUTCOMES = 'Outcomes',
}

export const InsightsOrganizationalUsersProvider: FC<PropsWithChildren> = ({ children }) => {
    // Data used for Insights Organizational Users graph
    const [data, setData] = useState<IInsightsOrganizationUsersCompetenceVM[]>([]);
    const [filteredData, setFilteredData] = useState<IInsightsOrganizationUsersCompetenceVM[] | undefined>();
    // ---

    // Data used for Insights Organizational Users table
    const [latestDateData, setLatestDateData] = useState<IInsightsOrganizationUsersCompetenceVM[]>([]);
    const [filteredLatestDateData, setFilteredLatestDateData] = useState<IInsightsOrganizationUsersCompetenceVM[]>([]);
    // ---

    const [filterCategories, setFilterCategories] = useState<IFilterCategories[]>(insightsOrganizationUsersCategories);
    const [scoreLines, setScoreLines] = useState<IScoreLine[] | undefined>();
    const { data: usersCompetenceData, isLoading: isUsersCompetenceDataLoading } =
        useGetInsightsOrganizationUsersCompetenceQuery();
    const [_, setUserCompetenceMapByDate] = useState<Map<string, IInsightsOrganizationUsersCompetenceVM[]>>(new Map());
    const [filteredUserCompetenceMapByDate, setFilteredUserCompetenceMapByDate] = useState<
        Map<string, IInsightsOrganizationUsersCompetenceVM[]>
    >(new Map());
    const [activeFilters, setActiveFilters] = useState<string[]>([]);
    const scoreLinesAllOptionsRef = useRef<IScoreLine[][]>([]);
    const activeTimePeriodOptionRef = useRef<EGraphCardSelect>(EGraphCardSelect.MONTH_4);
    const newestDateRef = useRef<Date>();
    const theme = useTheme();

    const generateMapByDateBasedOnData: (
        data: IInsightsOrganizationUsersCompetenceVM[]
    ) => Map<string, IInsightsOrganizationUsersCompetenceVM[]> | undefined = (data) => {
        if (data) {
            const competenceMapByDate = new Map();
            data.forEach((item) => {
                if (item.date && competenceMapByDate.has(item.date.toDateString())) {
                    competenceMapByDate.set(item.date.toDateString(), [
                        ...competenceMapByDate.get(item.date.toDateString()),
                        item,
                    ]);
                } else {
                    competenceMapByDate.set(item.date.toDateString(), [item]);
                }
            });
            return competenceMapByDate;
        }
        return;
    };

    useEffect(() => {
        if (filteredData) {
            const filteredUserIdList = filteredData
                .sort((a, b) => {
                    return isBefore(a.date as Date, b.date as Date) ? -1 : 1;
                })
                .map((item) => item.userId);
            setFilteredLatestDateData(
                latestDateData.filter((dataItem) => {
                    return filteredUserIdList.includes(dataItem.userId);
                })
            );
            const competenceMapByDate = generateMapByDateBasedOnData(
                filteredData.sort((a, b) => (isBefore(a.date as Date, b.date as Date) ? -1 : 1))
            );
            if (competenceMapByDate) {
                setFilteredUserCompetenceMapByDate(competenceMapByDate);
            }
        }
    }, [filteredData]);

    useEffect(() => {
        if (data && data.length > 0) {
            const competenceMapByDate = generateMapByDateBasedOnData(data);
            if (competenceMapByDate) {
                setUserCompetenceMapByDate(competenceMapByDate);
            }
        }
    }, [data]);

    useEffect(() => {
        if (usersCompetenceData) {
            const departments = new Set<string>();
            const regions = new Set<string>();

            usersCompetenceData.forEach((dataItem) => {
                if (dataItem.department) departments.add(dataItem.department);
                if (dataItem.region) regions.add(dataItem.region);
            });

            filterCategories.forEach((filterCategory) => {
                if (filterCategory.radioGroupId === 'department') {
                    const categoryValues: IFilterCategoryValue[] = [];
                    departments.forEach((department) => {
                        categoryValues.push({
                            key: department,
                            name: department,
                            callback: insightsOrganizationUsersByDepartmentFilterCallback,
                        });
                    });
                    filterCategory.values = categoryValues;
                }
                if (filterCategory.radioGroupId === 'region') {
                    const categoryValues: IFilterCategoryValue[] = [];
                    regions.forEach((region) => {
                        categoryValues.push({
                            key: region,
                            name: region,
                            callback: insightsOrganizationUsersByRegionFilterCallback,
                        });
                    });
                    filterCategory.values = categoryValues;
                }
            });
            setFilterCategories([...filterCategories]);
        }
    }, [usersCompetenceData]);

    useEffect(() => {
        if (filteredUserCompetenceMapByDate && filteredUserCompetenceMapByDate.size > 0) {
            // SKILL

            const assignedSkillScoreLine: IScoreLine = {
                id: 'SW1',
                name: 'Assigned',
                color: theme.palette.status.assigned,
                type: EUsersScoreLineType.SKILLS,
                scores: [],
            };

            const inProgressSkillScoreLine: IScoreLine = {
                id: 'SW2',
                name: 'In Progress',
                color: theme.palette.status.inProgress,
                type: EUsersScoreLineType.SKILLS,
                scores: [],
            };

            const attainedSkillScoreLine: IScoreLine = {
                id: 'SW3',
                name: 'Attained',
                color: theme.palette.status.attained,
                type: EUsersScoreLineType.SKILLS,
                scores: [],
            };

            const needAttentionSkillScoreLine: IScoreLine = {
                id: 'SW4',
                name: 'Need Attention',
                color: theme.palette.status.needAttention,
                legendColor: '#ED8000',
                type: EUsersScoreLineType.SKILLS,
                scores: [],
            };

            //OUTCOME

            const assignedOutcomeScoreLine: IScoreLine = {
                id: 'OW1',
                name: 'Assigned',
                color: theme.palette.status.assigned,
                type: EUsersScoreLineType.OUTCOMES,
                scores: [],
            };

            const inProgressOutcomeScoreLine: IScoreLine = {
                id: 'OW2',
                name: 'In Progress',
                color: theme.palette.status.inProgress,
                type: EUsersScoreLineType.OUTCOMES,
                scores: [],
            };

            const attainedOutcomeScoreLine: IScoreLine = {
                id: 'OW3',
                name: 'Attained',
                color: theme.palette.status.attained,
                type: EUsersScoreLineType.OUTCOMES,
                scores: [],
            };

            const needAttentionOutcomeScoreLine: IScoreLine = {
                id: 'OW4',
                name: 'Need Attention',
                color: theme.palette.status.needAttention,
                legendColor: '#ED8000',
                type: EUsersScoreLineType.OUTCOMES,
                scores: [],
            };

            if (filteredUserCompetenceMapByDate.size === 0) {
                setScoreLines((scoreLines) =>
                    scoreLines?.map((scoreLine) => {
                        return {
                            ...scoreLine,
                            scores: scoreLine.scores.map((score) => {
                                return {
                                    ...score,
                                    value: 0,
                                };
                            }),
                        };
                    })
                );
            }

            filteredUserCompetenceMapByDate.forEach(function (mapValue, mapKey) {
                let assignedSkillsPerDateTotal = 0;
                let inProgressSkillsPerDateTotal = 0;
                let attainedSkillsPerDateTotal = 0;
                let needAttentionSkillsPerDateTotal = 0;
                let assignedOutcomesPerDateTotal = 0;
                let inProgressOutcomesPerDateTotal = 0;
                let attainedOutcomesPerDateTotal = 0;
                let needAttentionOutcomesPerDateTotal = 0;

                mapValue.forEach((mapItem) => {
                    assignedSkillsPerDateTotal += mapItem.skillsAssigned;
                    inProgressSkillsPerDateTotal += mapItem.skillsInProgress;
                    attainedSkillsPerDateTotal += mapItem.skillsAttained;
                    needAttentionSkillsPerDateTotal += mapItem.skillsNeedAttention;

                    assignedOutcomesPerDateTotal += mapItem.outcomesAssigned;
                    inProgressOutcomesPerDateTotal += mapItem.outcomesInProgress;
                    attainedOutcomesPerDateTotal += mapItem.outcomesAttained;
                    needAttentionOutcomesPerDateTotal += mapItem.outcomesNeedAttention;
                });

                assignedSkillScoreLine.scores.push({
                    date: mapValue[0].date,
                    value: assignedSkillsPerDateTotal,
                });

                inProgressSkillScoreLine.scores.push({
                    date: mapValue[0].date,
                    value: inProgressSkillsPerDateTotal,
                });

                attainedSkillScoreLine.scores.push({
                    date: mapValue[0].date,
                    value: attainedSkillsPerDateTotal,
                });

                needAttentionSkillScoreLine.scores.push({
                    date: mapValue[0].date,
                    value: needAttentionSkillsPerDateTotal,
                });

                assignedOutcomeScoreLine.scores.push({
                    date: mapValue[0].date,
                    value: assignedOutcomesPerDateTotal,
                });

                inProgressOutcomeScoreLine.scores.push({
                    date: mapValue[0].date,
                    value: inProgressOutcomesPerDateTotal,
                });

                attainedOutcomeScoreLine.scores.push({
                    date: mapValue[0].date,
                    value: attainedOutcomesPerDateTotal,
                });

                needAttentionOutcomeScoreLine.scores.push({
                    date: mapValue[0].date,
                    value: needAttentionOutcomesPerDateTotal,
                });

                const allIndividualScoreTimes = [
                    expandDatesToLastYearPerWeek(assignedSkillScoreLine),
                    expandDatesToLastYearPerWeek(inProgressSkillScoreLine),
                    expandDatesToLastYearPerWeek(attainedSkillScoreLine),
                    expandDatesToLastYearPerWeek(needAttentionSkillScoreLine),
                    expandDatesToLastYearPerWeek(assignedOutcomeScoreLine),
                    expandDatesToLastYearPerWeek(inProgressOutcomeScoreLine),
                    expandDatesToLastYearPerWeek(attainedOutcomeScoreLine),
                    expandDatesToLastYearPerWeek(needAttentionOutcomeScoreLine),
                ];
                const scoreLines4Month = allIndividualScoreTimes.map((scoreTime) => {
                    return {
                        ...scoreTime,
                        scores: scoreTime.scores.slice(-15),
                    };
                });

                if (activeTimePeriodOptionRef.current === EGraphCardSelect.MONTH_4) setScoreLines(scoreLines4Month);
                else if (activeTimePeriodOptionRef.current === EGraphCardSelect.YEAR_WITH_WEEKS)
                    setScoreLines(allIndividualScoreTimes);

                scoreLinesAllOptionsRef.current = [scoreLines4Month, allIndividualScoreTimes];
            });
        }
    }, [filteredUserCompetenceMapByDate]);

    const changeScoreLinesInterval = (option: EGraphCardSelect) => {
        activeTimePeriodOptionRef.current = option;
        if (option === EGraphCardSelect.MONTH_4) {
            setScoreLines(scoreLinesAllOptionsRef.current[0]);
        } else if (option === EGraphCardSelect.YEAR_WITH_WEEKS) {
            setScoreLines(scoreLinesAllOptionsRef.current[1]);
        }
    };

    const onAnythingChange: any = useCallback(
        (organizationUsersDataNeedToBeFiltered: IInsightsOrganizationUsersCompetenceVM[], runSearchText?: boolean) => {
            let newFilteredUsers = [...organizationUsersDataNeedToBeFiltered];

            if (runSearchText) {
                newFilteredUsers = instantFilterByText(searchText, newFilteredUsers);
                setFilteredData(newFilteredUsers);
            } else {
                onFilterValueChange(filterCategories, activeFilters, true);
                setFilteredData(newFilteredUsers);
            }
            return newFilteredUsers;
        },
        [
            usersCompetenceData,
            // @ts-ignore
            searchText,
            // @ts-ignore
            activeFilters,
            filterCategories,
        ]
    );

    const { searchText, setSearchText, instantFilterByText } = useFilterSearch<IInsightsOrganizationUsersCompetenceVM>({
        data: data,
        dataSerachablePropertyName: 'displayName',
        setDataCallback: setFilteredData,
        onChangeCallback: onAnythingChange,
    });

    useEffect(() => {
        if (usersCompetenceData) {
            setData(usersCompetenceData);
            setFilteredData(usersCompetenceData);
            const newestDate = usersCompetenceData.reduce((a, b) => {
                return a.date > b.date ? a : b;
            }).date;
            newestDateRef.current = newestDate;
            const latestDataItems = usersCompetenceData.filter((dataItem) => {
                if (dataItem.date.getTime() === newestDate.getTime()) return true;
                return false;
            });
            setLatestDateData(latestDataItems);
            setFilteredLatestDateData(latestDataItems);
        }
    }, [usersCompetenceData]);

    const onFilterValueChange = (
        filterCategories: IFilterCategories[],
        activeFilters: string[],
        dontRunAnythingChange?: boolean
    ) => {
        setActiveFilters(activeFilters);
        let newFilteredData = [...data];
        filterCategories.forEach((filterCategory) => {
            if (filterCategory.values) {
                filterCategory.values!.forEach((filterCategoryValue) => {
                    if (filterCategoryValue.callback && activeFilters.includes(filterCategoryValue.key)) {
                        newFilteredData = newFilteredData.filter((dataItem) => {
                            if (filterCategoryValue.callback) {
                                const isValid = filterCategoryValue.callback(
                                    dataItem,
                                    filterCategoryValue.name,
                                    filterCategoryValue.key
                                );
                                return isValid;
                            }
                            return false;
                        });
                    }
                });
            }
        });
        if (!dontRunAnythingChange) onAnythingChange(newFilteredData, true);
    };

    const insightsOrganizationalUsersContext: IInsightsOrganizationalUsersContext = {
        data: filteredData,
        latestDateData: filteredLatestDateData,
        scoreLines,
        searchText,
        setSearchText,
        changeScoreLinesInterval,
        filterCategories,
        onFilterValueChange,
        isUsersCompetenceDataLoading,
    };

    return (
        <InsightsOrganizationalUsersContext.Provider value={insightsOrganizationalUsersContext}>
            {children}
        </InsightsOrganizationalUsersContext.Provider>
    );
};

export const useInsightsOrganizationalUsersStateValue: () => IInsightsOrganizationalUsersContext = () =>
    useContext(InsightsOrganizationalUsersContext);
