import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import {
    RootBox,
    InfoBox,
    InfoOutcomeBadgeBox,
    InfoOutcomeLevelBox,
    LoadingBox,
    ErrorBox,
    InfoOutcomeRedirectCountBox,
    RedirectIntervalCountSpan,
} from './Style';
import { usePostShareBadgeOnLinkedin } from '../../services/BadgeQueryService';
import Loading from '../../ui/general/loading/Loading';
import { makeOutcomeRoute, routes } from '../../pages/routes';
import PrimaryButton from '../../ui/buttons/primary-button/PrimaryButton';
import SomethingWentWrongCard from '../../ui/cards/something-went-wrong-card/SomethingWentWrongCard';
import BadgeExampleImage from '../../assets/BadgeExample';
import { ELinkedinRedirectUrl } from '../../interfaces/enums/ELinkedinRedirectUrl';

enum EApiCallStatus {
    IDLE = 'Idle',
    ERROR = 'Error',
    SUCCESS = 'Success',
    BAD_REQUEST = 'BadRequest',
}

const Linkedin: FC = () => {
    const [searchParams] = useSearchParams();
    const { isPending, isError, mutateAsync: postShareBadgeAsync } = usePostShareBadgeOnLinkedin();
    const [redirectIntervalCount, setRedirectIntervalCount] = useState<number>(10);
    const [apiCallStatus, setApiCallStatus] = useState<EApiCallStatus>(EApiCallStatus.IDLE);
    const [outcomeId, setOutcomeId] = useState<number>();
    const intervalRef = useRef<NodeJS.Timer | undefined>();
    const navigate = useNavigate();
    const theme = useTheme();

    useEffect(() => {
        return () => {
            if (intervalRef.current) clearInterval(intervalRef.current);
        };
    }, []);

    const navigateToMainSiteCallback = (parsedOutcomeId?: number, calledFrom?: ELinkedinRedirectUrl) => {
        let pathname;
        if (calledFrom === ELinkedinRedirectUrl.INSIGHTS) {
            pathname = routes.INSIGHTS_PERSONAL_BADGES;
        } else if (calledFrom === ELinkedinRedirectUrl.OUTCOME) {
            pathname = makeOutcomeRoute(parsedOutcomeId ? parsedOutcomeId.toString() : outcomeId!.toString());
        } else if (calledFrom === ELinkedinRedirectUrl.SHOWCASE) {
            pathname = routes.SHOWCASE_HOME;
        }
        navigate(
            {
                pathname,
                search: apiCallStatus === EApiCallStatus.SUCCESS ? '?successShareBadge=1' : '',
            },
            {
                state: { from: routes.LINKEDIN },
            }
        );
    };

    const navigateBack = () => {
        const stateParam = searchParams.get('state');
        if (stateParam) {
            const stateParamChunks = stateParam.split('-');
            if (stateParamChunks.length === 3) {
                const [_, outcomeId, calledFrom] = stateParamChunks;
                const parsedOutcomeId = parseInt(outcomeId);
                if (parsedOutcomeId) {
                    setOutcomeId(parsedOutcomeId);
                    navigateToMainSiteCallback(parsedOutcomeId, calledFrom as ELinkedinRedirectUrl);
                }
            }
        }
    };

    useEffect(() => {
        if (redirectIntervalCount <= 0) {
            navigateBack();
        }
    }, [redirectIntervalCount]);

    useEffect(() => {
        const postBadgeOnLinkedinAsync = async () => {
            try {
                const stateParam = searchParams.get('state');
                const code = searchParams.get('code');
                const error = searchParams.get('error');

                if (error) {
                    setApiCallStatus(EApiCallStatus.ERROR);
                    if (stateParam) {
                        const stateParamChunks = stateParam.split('-');
                        if (stateParamChunks.length === 3) {
                            const [_, outcomeId, calledFrom] = stateParamChunks;
                            const parsedOutcomeId = parseInt(outcomeId);
                            if (parsedOutcomeId) {
                                setOutcomeId(parsedOutcomeId);
                                navigateToMainSiteCallback(parsedOutcomeId, calledFrom as ELinkedinRedirectUrl);
                            }
                        } else {
                            navigate({
                                pathname: routes.HOME,
                            });
                        }
                    }
                    return;
                }
                if (stateParam && code) {
                    const stateParamChunks = stateParam.split('-');
                    if (stateParamChunks.length === 3) {
                        const [level, outcomeId] = stateParamChunks;
                        try {
                            const parsedOutcomeId = parseInt(outcomeId);
                            setOutcomeId(parsedOutcomeId);
                            if (parsedOutcomeId) {
                                const response = await postShareBadgeAsync({
                                    outcomeId: parsedOutcomeId,
                                    userToken: code,
                                    level: level,
                                });
                                const rediectInterval = setInterval(() => {
                                    setRedirectIntervalCount((interval) => interval - 1);
                                }, 1000);
                                intervalRef.current = rediectInterval;
                                if (response.result === 'Success') {
                                    setApiCallStatus(EApiCallStatus.SUCCESS);
                                } else {
                                    setApiCallStatus(EApiCallStatus.ERROR);
                                }
                            }
                        } catch (e) {
                            console.error(e);
                        }
                    } else throw new Error('BadRequest');
                } else throw new Error('BadRequest');
            } catch (e: any) {
                console.error(e.message);
                if (e instanceof Error && e.message === 'BadRequest') setApiCallStatus(EApiCallStatus.BAD_REQUEST);
                else navigate(routes.HOME);
            }
        };
        postBadgeOnLinkedinAsync();
    }, [searchParams]);

    const closeTabHandler = () => {
        navigateBack();
    };

    const statusMessageMemo = useMemo(() => {
        switch (apiCallStatus) {
            case EApiCallStatus.IDLE:
                return 'Loading...';
            case EApiCallStatus.ERROR:
                return 'Something went wrong, please try again later...';
            case EApiCallStatus.SUCCESS:
                return 'Congratulations! Your badge is shared successfully!';
            case EApiCallStatus.BAD_REQUEST:
                return 'Something went wrong';
            default:
                return 'Loading...';
        }
    }, [apiCallStatus]);

    const infoMemo = useMemo(() => {
        if (isPending) {
            return (
                <LoadingBox>
                    <Loading style={{ color: theme.palette.primary.main }} />
                </LoadingBox>
            );
        }
        if (isError) {
            return (
                <ErrorBox>
                    <Typography variant="h3">Something went wrong!</Typography>
                    <Typography variant="h2">Please try again.</Typography>
                </ErrorBox>
            );
        }
        if (apiCallStatus !== EApiCallStatus.IDLE)
            return (
                <InfoBox>
                    {apiCallStatus === EApiCallStatus.SUCCESS && (
                        <InfoOutcomeBadgeBox>
                            <BadgeExampleImage />
                        </InfoOutcomeBadgeBox>
                    )}
                    <InfoOutcomeLevelBox variant="h5" isError={apiCallStatus === EApiCallStatus.ERROR}>
                        {statusMessageMemo}
                    </InfoOutcomeLevelBox>
                    <PrimaryButton title="Go Back" clickHandler={closeTabHandler} style={{ width: '100px' }} />
                    <InfoOutcomeRedirectCountBox variant="body2">
                        You will be redirected in{' '}
                        <RedirectIntervalCountSpan>{redirectIntervalCount}</RedirectIntervalCountSpan>{' '}
                        {redirectIntervalCount === 1 ? 'second' : 'seconds'}...
                    </InfoOutcomeRedirectCountBox>
                </InfoBox>
            );
        return <></>;
    }, [isPending, isError, apiCallStatus, redirectIntervalCount, statusMessageMemo]);

    const somethingWentWrongActionCallback = useCallback(() => {
        navigate(routes.HOME);
    }, []);

    return (
        <RootBox>
            {apiCallStatus === EApiCallStatus.BAD_REQUEST ? (
                <SomethingWentWrongCard
                    actionCallback={somethingWentWrongActionCallback}
                    boxStyle={{ boxShadow: 'unset' }}
                />
            ) : (
                <>{infoMemo}</>
            )}
        </RootBox>
    );
};

export default Linkedin;
