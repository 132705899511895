import { FC } from 'react';

interface IProps {
    width?: string;
    height?: string;
}

const UserPlaceholderIcon: FC<IProps> = ({ width, height }) => {
    return (
        <svg
            width={width || '24'}
            height={height || '24'}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23 11.9999C23 5.92492 18.0746 0.999878 12 0.999878C5.92472 0.999878 1 5.92459 1 11.9999C1 18.0752 5.92472 22.9999 12 22.9999C18.0746 22.9999 23 18.0748 23 11.9999ZM3 11.9999C3 7.02916 7.02928 2.99988 12 2.99988C16.9701 2.99988 21 7.02952 21 11.9999C21 16.9702 16.9701 20.9999 12 20.9999C7.02928 20.9999 3 16.9706 3 11.9999Z"
                fill="#8C8C8C"
            />
            <path
                d="M16 16.5V15.5C16 14.9696 15.7893 14.4609 15.4142 14.0858C15.0391 13.7107 14.5304 13.5 14 13.5H10C9.46957 13.5 8.96086 13.7107 8.58579 14.0858C8.21071 14.4609 8 14.9696 8 15.5V16.5"
                stroke="#8C8C8C"
                strokeWidth="1.8"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12 10.5C13.1046 10.5 14 9.60457 14 8.5C14 7.39543 13.1046 6.5 12 6.5C10.8954 6.5 10 7.39543 10 8.5C10 9.60457 10.8954 10.5 12 10.5Z"
                stroke="#8C8C8C"
                strokeWidth="1.8"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default UserPlaceholderIcon;
