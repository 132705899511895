import { AddCircleOutline, RemoveCircleOutline } from '@material-ui/icons';
import styled from '../../../../ui/theme/styled';

export const RootBox = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
});

export const RootInnerBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    width: 1080,
    paddingLeft: '20px',
    paddingRight: '20px',
    [theme.breakpoints.down('lg')]: {
        width: '100%',
        flexDirection: 'column',
    },
}));

export const LicensesMainContentWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    gap: '20px',
});

export const RootInnerStatusWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    flex: 11,
    gap: '20px',
    [theme.breakpoints.down('lg')]: {
        width: '100%',
    },
}));

export const LicensesStatusOverallWrapper = styled('div', {
    shouldForwardProp: (prop) => prop !== 'isDisabled',
})<{ isDisabled: boolean }>(({ isDisabled }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    gap: '30px',
    paddingBottom: 24,
    flex: 4,
    pointerEvents: isDisabled ? 'none' : 'unset',
    opacity: isDisabled ? 0.4 : 1,
}));

export const LicenseFiltersWrapper = styled('div')({
    flex: 1,
});

export const LicenseRowContentWrapper = styled('div')({
    display: 'flex',
    gap: '20px',
    flex: 4,
});

export const HeaderActionWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'row-reverse',
});

export const LicenseActionBox = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 6,
    cursor: 'pointer',
    height: 32,
    width: 32,
    borderRadius: 15,
    margin: 'auto',
    '&:hover': {
        backgroundColor: theme.palette.common.white,
    },
}));

export const RemoveIconImg = styled(RemoveCircleOutline)(({ theme }) => ({
    width: 20,
    height: 20,
    color: theme.palette.gray.light,
}));

export const AddIconImg = styled(AddCircleOutline)(({ theme }) => ({
    color: theme.palette.gray.light,
    width: 20,
    height: 20,
    display: 'flex',
    alignItems: 'center',
    maxHeight: 20,
}));

export const LicenseAssignmentHistoryBox = styled('div')(({ theme }) => ({
    flex: 4,
    maxWidth: '26%',
    [theme.breakpoints.down('lg')]: {
        flex: 'unset',
        maxWidth: 'unset',
    },
}));
