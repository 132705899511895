import { Typography } from '@mui/material';
import { LabelTypography } from '../../../../css/CommonComponents';
import styled from '../../../theme/styled';

export const RootBox = styled('div')(({ theme }) => ({
    display: 'grid',
    padding: '20px 60px 20px 25px',
    gridTemplateColumns: '120px auto 100px',
    gridTemplateRows: 'auto minmax(36px, auto)',
    gridRowGap: '4px',
    width: 'calc(100% - 12px)',
    margin: '6px',
    '&:hover': {
        cursor: 'pointer',
        backgroundColor: theme.palette.background.default,
    },
    [theme.breakpoints.down('lg')]: {
        gridTemplateColumns: '100px auto 100px',
        padding: '14px 40px 14px 15px',
    },
    [theme.breakpoints.down('md')]: {
        padding: '10px',
        gridTemplateColumns: '50px auto 75px',
    },
    [theme.breakpoints.down('sm')]: {
        padding: '5px',
        gridTemplateColumns: '50px auto 2fr',
    },
}));

export const TitleTypography = styled(Typography)({
    flex: 5,
    display: 'block',
    gridColumn: '2 / 3',
    gridRow: '1',
    color: 'rgba(0,0,0,0.8)',
});

export const DescriptionSpan = styled('span')({
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Open Sans',
    fontSize: 12,
    letterSpacing: '0.5px',
    lineHeight: '14px',
    color: 'rgba(0, 0, 0, 0.8)',
    gridRow: '2',
    gridColumn: '2 / 4',
});

export const ImageBox = styled('div')({
    display: 'flex',
    alignItems: 'center',
    gridArea: 'span 2',
});

export const ActivityImg = styled('img')(({ theme }) => ({
    width: 68,
    height: 68,
    [theme.breakpoints.down('md')]: {
        width: 38,
        height: 38,
    },
}));

export const TimeAgoTypography = styled(LabelTypography)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    textAlign: 'right',
    flex: 1,
    gridColumn: '3 / 4',
    gridRow: '1',
    [theme.breakpoints.down('lg')]: {
        alignItems: 'flex-start',
    },
    [theme.breakpoints.down('md')]: {
        minWidth: '75px',
    },
}));
