import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const RootBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    '&:not(:last-child)': {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    background: 'linear-gradient(180deg, #FFFFFF 0%, #FAFAFA 100%), #FFFFFF',
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.08), 0px 0.5px 1.5px rgba(0, 0, 0, 0.08)',
    height: '100%',
    borderRadius: theme.borderRadius?.outside?.small,
}));

export const TitleBox = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    padding: '16px 24px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
    minHeight: '58.8px',
}));

export const TitleTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.gray.dark,
    [theme.breakpoints.down('lg')]: {
        fontWeight: 500,
        fontSize: '14px',
    },
}));

export const ContentBox = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'nowrap',
    gap: 23,
    padding: 1,
    height: '100%',
    [theme.breakpoints.down('lg')]: {
        alignItems: 'stretch',
    },
    [theme.breakpoints.down(840)]: {
        flexDirection: 'column',
    },
}));

export const RankWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    gap: '4px',
});
