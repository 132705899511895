import { useAccount, useMsal } from '@azure/msal-react';
import { TokenContext } from '../contexts/TokenContext';
import { IAuthParams } from '../interfaces/IAuthParams';
import { useContext } from 'react';

const useAuthParams: () => IAuthParams = () => {
    const { ssoToken, oid, tid, clientType } = useContext(TokenContext);
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0]);

    return {
        ssoToken,
        oid,
        tid,
        clientType,
        account,
        instance,
    };
};

export default useAuthParams;
