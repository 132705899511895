import { Skeleton } from '@mui/material';
import { FC, KeyboardEvent, isValidElement, useCallback } from 'react';
import { ICreateUrlResponse, createUrl } from '../../../../utils/createUrl';
import { RootBox, TitleTypography, DescriptionSpan, ImageBox, ActivityImg, TimeAgoTypography } from './Style';
import { timeBetweenTwoDates } from '../../../../utils/dateUtil';
import { IActivityFeedVM } from '../../../../interfaces/views/IActivityFeedVM';
import { useNavigate } from 'react-router';
import { useCrumbsStateValue } from '../../../../contexts/CrumbsContext';
import { EActivityFeedCategory } from '../../../../interfaces/enums/EActivityFeedCategory';
import { ICrumb } from '../../../../interfaces/ICrumb';
import fetchImageErrorCallback from '../../../../utils/fetchImageErrorCallback';
import loadImageGraduallyHandler from '../../../../utils/loadImageGradually';
import { accessibilityEnterKeyCallback } from '../../../../utils/accessibilityUtils';
import { routes } from '../../../../pages/routes';

interface IProps {
    activity?: IActivityFeedVM;
    skeletonMode?: boolean;
    index?: number;
}

const ActivityFeedItem: FC<IProps> = ({ activity, skeletonMode = false, index }) => {
    const navigate = useNavigate();
    const { startNewCrumbs } = useCrumbsStateValue();

    const makeCrumb: (activity: IActivityFeedVM, urlInfo?: ICreateUrlResponse) => ICrumb | undefined = (
        activity,
        urlInfo
    ) => {
        switch (activity.category) {
            case EActivityFeedCategory.SKILLS:
                return {
                    name: 'Skill',
                    pathname: urlInfo?.relativeUrl,
                    isDirectLink: true,
                } as ICrumb;
            case EActivityFeedCategory.OUTCOMES:
                return {
                    name: 'Outcomes',
                    pathname: urlInfo?.relativeUrl,
                    isDirectLink: true,
                } as ICrumb;
            case EActivityFeedCategory.ACHIEVEMENTS:
                return {
                    name: 'Achievements',
                    pathname: routes.SHOWCASE_HOME,
                    isDirectLink: true,
                } as ICrumb;
            default:
                return undefined;
        }
    };

    const handleActivityClick = useCallback(() => {
        if (activity?.link) {
            const urlInfo = createUrl(activity.link);
            if (urlInfo) {
                const crumb = makeCrumb(activity, urlInfo);
                if (crumb) startNewCrumbs(crumb, true);
                if (urlInfo?.relativeUrl) navigate(urlInfo.relativeUrl);
                else window.open(createUrl(activity.link)?.generatedUrl!, '_self');
            }
        } else {
            if (activity?.category === EActivityFeedCategory.ACHIEVEMENTS) {
                const crumb = makeCrumb(activity);
                if (crumb) startNewCrumbs(crumb, true);
                navigate(routes.SHOWCASE_HOME);
            }
        }
    }, [activity]);

    return (
        <RootBox
            onClick={handleActivityClick}
            onKeyDown={(e: KeyboardEvent<any>) => {
                if (e.key === 'Enter') accessibilityEnterKeyCallback(e, handleActivityClick);
                if (index !== undefined) {
                    if (e.key === 'ArrowDown') {
                        const nextElement = document.getElementById(`activity-feed-item-${index + 1}`);
                        if (nextElement) {
                            nextElement.focus();
                        }
                    }
                    if (e.key === 'ArrowUp') {
                        const previousElement = document.getElementById(`activity-feed-item-${index - 1}`);
                        if (previousElement) {
                            previousElement.focus();
                        }
                    }
                }
            }}
            tabIndex={0}
            id={`activity-feed-item-${index !== undefined ? index : Math.random()}`}
        >
            <TitleTypography variant="subtitle2">
                {skeletonMode ? <Skeleton width="200px" /> : activity?.title}
            </TitleTypography>
            <TimeAgoTypography>
                {skeletonMode ? (
                    <Skeleton width="100px" />
                ) : activity ? (
                    timeBetweenTwoDates({ start: activity.date, end: new Date() })
                ) : (
                    ''
                )}
            </TimeAgoTypography>
            <ImageBox>
                {skeletonMode ? (
                    <Skeleton variant="circular" width={30} height={30} />
                ) : isValidElement(activity?.icon) ? (
                    activity?.icon
                ) : (
                    activity?.icon && (
                        <ActivityImg
                            src={typeof activity?.icon === 'string' ? createUrl(activity?.icon)?.generatedUrl : ''}
                            onError={fetchImageErrorCallback}
                            onLoad={loadImageGraduallyHandler}
                            alt={activity?.title}
                        />
                    )
                )}
            </ImageBox>
            <DescriptionSpan>{skeletonMode ? <Skeleton width="250px" /> : activity?.description}</DescriptionSpan>
        </RootBox>
    );
};

export default ActivityFeedItem;
