import { useEffect, useState, useCallback } from 'react';

interface IUseModalProps {
    defaultOpen: boolean;
}

const useModal = ({ defaultOpen }: IUseModalProps) => {
    const [isOpen, setOpen] = useState(false);

    useEffect(() => {
        setOpen(defaultOpen);
    }, [defaultOpen]);

    const openCallback = useCallback(() => {
        setOpen(true);
    }, [setOpen]);

    const closeCallback = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    return {
        isOpen,
        setOpen,
        open: openCallback,
        close: closeCallback,
    };
};

export default useModal;
