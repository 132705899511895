import { Typography } from '@mui/material';
import { keyframes, styled } from '@mui/system';

const scaleeffect = keyframes(`
  0% {
    width: 180px,
    height: 136px,
    top: 0,
    left: 0,
  }
  100% {
    left: -5px,
    top: -5px,
    height: 146px,
    width: 190px,
  }
`);

export const RootBox = styled('a')(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '180px',
    height: '136px',
    gap: '9px',
    maxWidth: '190px',
    marginBottom: '10px',
    flexGrow: 1,
    cursor: 'pointer',
    '&:hover img': {
        left: -5,
        top: -5,
        height: 146,
        width: 190,
        animation: `${scaleeffect} 200ms ease-in`,
    },
}));

export const CategoryImage = styled('img')({
    visibility: 'hidden',
    width: '100%',
    height: '100%',
    border: '1px solid #00000029',
    boxShadow: '0px 1px 4px 0px #00000014',
    '&:hover': {
        width: 190,
        height: 146,
        top: -5,
        left: -5,
        animation: `$${scaleeffect} 200ms ease-in`,
    },
});

export const CategoryTypography = styled(Typography)({
    display: 'flex',
    width: '100%',
});
