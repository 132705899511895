import { getToken } from '../../auth/getToken';
import { AxiosRequestConfig } from 'axios';
import { IAuthParams } from '../../interfaces/IAuthParams';

export const getHeaders = (authParams: IAuthParams, token: string): AxiosRequestConfig => {
    return {
        withCredentials: false,
        headers: {
            Authorization: `Bearer ${token}`,
            'NULIA-OID': authParams.oid,
            'NULIA-TID': authParams.tid,
            'NULIA-TZ': new Date().getTimezoneOffset().toString(),
            'Nulia-Platform': authParams.clientType,
        },
    };
};

export const getTokenAsync = async (instance: any, account: any, ssoToken: any) => {
    const token = await getToken(instance, account, ssoToken);
    return token;
};

export const getTokenAndHeaders = async (authParams: IAuthParams) => {
    const { instance, account, ssoToken } = authParams;
    const token = await getToken(instance, account, ssoToken);
    return { token, headers: getHeaders(authParams, token) };
};
