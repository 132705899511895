import { FC, useMemo, useState, KeyboardEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { useTheme } from '@mui/system';
import Tooltip from '../../../../ui/general/tooltip/Tooltip';
import {
    BadgeActionsBox,
    BadgeImg,
    DownloadBadgeBox,
    FooterBox,
    FooterInfoTypography,
    FooterInfoRow,
    ImageBox,
    RootBox,
    ShareBadgeBox,
    CircleWrapper,
    Circle,
    ClaimBox,
} from './Style';
import DownloadBadgeIcon from '../../../../assets/icons/DownloadBadgeIcon';
import ShareBadgeIcon from '../../../../assets/icons/ShareBadgeIcon';
import { createUrl } from '../../../../utils/createUrl';
import { EShowcaseBadgeStatus } from '../../../../interfaces/enums/EShowcaseBadgeStatus';
import PrimaryButton from '../../../../ui/buttons/primary-button/PrimaryButton';
import { ECompetenceState } from '../../../../interfaces/enums/ECompetenceState';
import loadImageGraduallyHandler from '../../../../utils/loadImageGradually';
import { useShowcaseStateValue } from '../../../../contexts/ShowcaseContext';
import ShareBadgeModal from '../../../../ui/modals/share-badge-modal/ShareBadgeModal';
import { EShowcaseBadgeLevel } from '../../../../interfaces/enums/EShowcaseBadgeLevel';
import { ELinkedinRedirectUrl } from '../../../../interfaces/enums/ELinkedinRedirectUrl';
import fetchImageErrorCallback from '../../../../utils/fetchImageErrorCallback';
import { accessibilityEnterKeyCallback } from '../../../../utils/accessibilityUtils';

interface IProps {
    title?: string;
    badgeId?: string;
    state: EShowcaseBadgeStatus;
    badgeImage: string;
    claimedDate?: Date;
    verifiedDate?: Date;
    inProgressSkillStates?: ECompetenceState[];
    level: EShowcaseBadgeLevel;
    outcomeId: number;
    userBadgeUrl: string;
    index: number;
}

const ShowcaseBadge: FC<IProps> = ({
    title,
    badgeId,
    badgeImage,
    state,
    claimedDate,
    verifiedDate,
    inProgressSkillStates,
    level,
    outcomeId,
    userBadgeUrl,
    index,
}) => {
    const [isShareBadgeModalOpen, setShareBadgeModalOpen] = useState<boolean>(false);
    const theme = useTheme();
    const {
        claimBadgeHandler,
        downloadBadgeHandler,
        isDownloadBadgeDataLoading,
        isPostClaimBadgeLoading,
        isBadgesDataLoading,
    } = useShowcaseStateValue();
    const { t } = useTranslation();

    const handleDownloadBadgeClick = async () => {
        if (outcomeId && level) downloadBadgeHandler(outcomeId, level.toString(), title);
    };

    const handleShareBadgeModalOpenClick = () => {
        setShareBadgeModalOpen(true);
    };

    const handleClaimBadgeClick = async () => {
        await claimBadgeHandler(outcomeId.toString(), level);
    };

    const handleShareBadgeModalCloseClick = () => {
        setShareBadgeModalOpen(false);
    };

    const imageLevelMemo = useMemo(() => {
        return createUrl(badgeImage)?.generatedUrl;
    }, [badgeImage]);

    const footerMemo = useMemo(() => {
        switch (state) {
            case EShowcaseBadgeStatus.CLAIMED:
            case EShowcaseBadgeStatus.NOT_SHARED:
            case EShowcaseBadgeStatus.NEED_ATTENTION:
                return (
                    <FooterInfoRow>
                        {claimedDate && (
                            <FooterInfoTypography variant="caption">
                                Claimed: {claimedDate ? format(claimedDate, 'MMM do, yyyy') : ''}
                            </FooterInfoTypography>
                        )}
                        {verifiedDate && (
                            <FooterInfoTypography variant="caption">
                                Verified: {verifiedDate ? format(verifiedDate, 'MMM do, yyyy') : ''}
                            </FooterInfoTypography>
                        )}
                    </FooterInfoRow>
                );
            case EShowcaseBadgeStatus.CLAIMABLE:
            case EShowcaseBadgeStatus.IN_PROGRESS:
                const achievedCount = inProgressSkillStates?.filter(
                    (state) => state === ECompetenceState.MAINTAIN
                ).length;
                const needAttentionCount = inProgressSkillStates?.filter(
                    (state) => state === ECompetenceState.NEEDS_ATTENTITON
                ).length;
                const notAchievedCount = inProgressSkillStates?.filter(
                    (state) => state === ECompetenceState.IN_PROGRESS || state === ECompetenceState.NONE
                ).length;
                return (
                    <>
                        <CircleWrapper>
                            {[...Array(achievedCount).keys()].map((order) => {
                                return <Circle key={order} color={theme.palette.status.attained} />;
                            })}
                            {[...Array(notAchievedCount).keys()].map((order) => {
                                return <Circle key={order} color={theme.palette.status.inProgress} />;
                            })}
                            {[...Array(needAttentionCount).keys()].map((order) => {
                                return <Circle key={order} color={theme.palette.status.needAttention} />;
                            })}
                        </CircleWrapper>
                        {state === EShowcaseBadgeStatus.CLAIMABLE && (
                            <ClaimBox>
                                <PrimaryButton
                                    disabled={isPostClaimBadgeLoading || isBadgesDataLoading}
                                    title="Claim Now"
                                    clickHandler={handleClaimBadgeClick}
                                />
                            </ClaimBox>
                        )}
                    </>
                );
            default:
                return <></>;
        }
    }, [state, inProgressSkillStates]);

    return (
        <RootBox>
            <ImageBox>
                <BadgeImg
                    src={imageLevelMemo}
                    onLoad={loadImageGraduallyHandler}
                    onError={fetchImageErrorCallback}
                    aria-label={`${level} level badge`}
                />
                {(state === EShowcaseBadgeStatus.CLAIMED ||
                    state === EShowcaseBadgeStatus.NOT_SHARED ||
                    state === EShowcaseBadgeStatus.NEED_ATTENTION) && (
                    <BadgeActionsBox id="badge-actions-box">
                        <Tooltip title={t('tooltips.downloadBadge')}>
                            <DownloadBadgeBox
                                className={`download-badge-${index}`}
                                tabIndex={0}
                                isLoading={isDownloadBadgeDataLoading}
                                onClick={handleDownloadBadgeClick}
                                onKeyDown={(e: KeyboardEvent<any>) =>
                                    accessibilityEnterKeyCallback(e, handleDownloadBadgeClick)
                                }
                            >
                                <DownloadBadgeIcon />
                            </DownloadBadgeBox>
                        </Tooltip>
                        <Tooltip title={t('tooltips.shareBadge')}>
                            <ShareBadgeBox
                                tabIndex={0}
                                className={`share-badge-${index}`}
                                onClick={handleShareBadgeModalOpenClick}
                                onKeyDown={(e: KeyboardEvent<any>) =>
                                    accessibilityEnterKeyCallback(e, handleShareBadgeModalOpenClick)
                                }
                            >
                                <ShareBadgeIcon color="#FF955E" />
                            </ShareBadgeBox>
                        </Tooltip>
                    </BadgeActionsBox>
                )}
            </ImageBox>
            <FooterBox>{footerMemo}</FooterBox>
            {isShareBadgeModalOpen && (
                <ShareBadgeModal
                    handleClose={handleShareBadgeModalCloseClick}
                    badgeImage={badgeImage}
                    level={level}
                    outcomeId={outcomeId}
                    userBadgeUrl={userBadgeUrl}
                    calledFrom={ELinkedinRedirectUrl.SHOWCASE}
                />
            )}
        </RootBox>
    );
};

export default ShowcaseBadge;
