import styled from '../../theme/styled';

export const RootBox = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0px 2.5px 8px rgb(0 0 0 / 8%), 0px 1px 4px rgb(0 0 0 / 8%)',
    height: 'fit-content',
    borderRadius: 8,
    flex: 2,
    '& > div:first-of-type': {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        boxShadow: 'none',
        border: 'none',
        maxWidth: 'unset',
    },
    '& > div:nth-of-type(2)': {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        boxShadow: 'none',
        border: 'none',
        maxWidth: 'unset',
    },
    '& > div:nth-of-type(2):after': {
        content: '""',
        position: 'absolute',
        height: 100,
        width: 1,
        // border: '1px solid rgba(0, 0, 0, 0.16)',
        backgroundColor: 'rgba(0, 0, 0, 0.16)',
    },
}));
