import styled from '../../theme/styled';

export const ColoredLabelBox = styled('div')(({ theme: { palette } }) => ({
    display: 'flex',
    alignItems: 'center',
    color: '#AC5521',
    background: 'rgba(250, 208, 70, 0.32)',
    borderRadius: 8,
    fontWeight: 600,
    lineHeight: '12px',
    fontSize: 10,
    padding: '2px 8px',
    textTransform: 'uppercase',
    width: 'fit-content',
    fontFamily: 'Open Sans',
    letterSpacing: '0.5px',
}));
