import { useAppInsightsContext, useTrackEvent } from '@microsoft/applicationinsights-react-js';
import { AIReactCustomEvent } from '@microsoft/applicationinsights-react-js/types/useTrackEvent';
import { createContext, FC, useContext, PropsWithChildren } from 'react';

interface IOpenHelpWizardEvent {
    pageUrl: string;
    pageName: string;
}

export interface IAppInsightsContext {
    sendOpenHelpWizardEvent: AIReactCustomEvent<IOpenHelpWizardEvent>;
}

export const AppInsightsContext = createContext<IAppInsightsContext>({} as IAppInsightsContext);

interface IProps {}

export const AppInsightsProvider: FC<PropsWithChildren<IProps>> = ({ children }) => {
    const appInsights = useAppInsightsContext();

    const sendOpenHelpWizardEvent = useTrackEvent<IOpenHelpWizardEvent>(appInsights, 'Open Help Wizard', {
        pageUrl: '',
        pageName: '',
    });

    const appInsightsState: IAppInsightsContext = {
        sendOpenHelpWizardEvent,
    };

    return <AppInsightsContext.Provider value={appInsightsState}>{children}</AppInsightsContext.Provider>;
};

export const useAppInsightsStateValue: () => IAppInsightsContext = () => useContext(AppInsightsContext);
