import { IScoreDot } from '../interfaces/IScoreLine';

export const createMonthsArray: () => IScoreDot[] = () => {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const offsetReverseMonths = months.concat(...months.splice(0, new Date().getMonth())).reverse();
    const currentMonth = offsetReverseMonths.slice(-1);
    return [...offsetReverseMonths.reverse().splice(1), ...currentMonth].map((item) => {
        return {
            date: item,
            value: 0,
        };
    });
};
