import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './en.json';
import { ILanguages } from './util';

const initializeI18N = (lang: ILanguages = 'en') => {
    i18n.use(initReactI18next).init({
        fallbackLng: 'en',
        debug: false,
        interpolation: {
            escapeValue: false,
        },

        resources: {
            en: {
                translation: en,
            },
        },
        lng: localStorage.getItem('lang') as ILanguages,
    });
};

export default initializeI18N;
