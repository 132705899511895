import { ESkillType } from '../interfaces/enums/ESkillType';
import { IAssignmentDTO } from '../interfaces/dtos/IAssignmentDTO';
import { ECompetenceState } from '../interfaces/enums/ECompetenceState';
import { ESkillLevel } from '../interfaces/enums/ESkillLevel';
import { ESkillStatus } from '../interfaces/enums/ESkillStatus';

export const progressDoughnutChartType: (assignment: IAssignmentDTO, skillType: ESkillType) => ESkillStatus = (
    assignment,
    skillType
) => {
    let skillState = ECompetenceState.NONE;
    if (assignment.level === ESkillLevel.MASTER) skillState = assignment.masterState;
    else if (assignment.level === ESkillLevel.PRODUCER) skillState = assignment.producerState;
    const isAssigned = assignment.level !== ESkillLevel.NONE;
    if (skillType === ESkillType.STANDARD) {
        if (isAssigned) {
            if (skillState === ECompetenceState.IN_PROGRESS) return ESkillStatus.ASSIGNED_IN_PROGRESS;
            if (skillState === ECompetenceState.NEEDS_ATTENTITON) return ESkillStatus.ASSIGNED_ACHIEVED_IN_DANGER;
            if (skillState === ECompetenceState.MAINTAIN) return ESkillStatus.ASSIGNED_ACHIEVED;
        } else return ESkillStatus.UNASSIGNED;
    } else if (skillType === ESkillType.BEST_PRACTICE) {
        if (assignment.level === ESkillLevel.NONE) return ESkillStatus.BEST_PRACTICE_UNASSIGNED;
        if (skillState === ECompetenceState.MAINTAIN || skillState === ECompetenceState.NEEDS_ATTENTITON)
            return ESkillStatus.BEST_PRACTICE_ASSIGNED_ACHIEVED;
        if (skillState === ECompetenceState.IN_PROGRESS) return ESkillStatus.BEST_PRACTICE_ASSIGNED_NOT_ACHIEVED;
        if (skillState === ECompetenceState.NONE) return ESkillStatus.BEST_PRACTICE_UNASSIGNED;
    }
    return ESkillStatus.ASSIGNED_IN_PROGRESS;
};
