import { FC } from 'react';
import { IconButton } from '@material-ui/core';
import { Copy } from 'react-feather';
import { useParams } from 'react-router';
import {
    DocumentBox,
    MainRoot,
    PrimaryTitleTypography,
    TitleBox,
    Image,
    ImageContainerBox,
    MainDescriptionTypography,
    TutorialUlRoot,
    TutorialLi,
    BoldSpan,
    StackBox,
    TutorialUl
} from './Style';
import { isInTeams } from '../../utils/isInTeams';

const Instructions: FC = () => {
    const { assertionId } = useParams<{ assertionId: string }>();
    // const { setTitle, setGoBack, setTabs } = useContext(HeaderContext);

    const copyUrl = () => {
        if (!assertionId) {
            return;
        }

        navigator.clipboard.writeText(`${process.env.REACT_APP_BADGE_URL}/${assertionId}`);
    };

    return (
        <MainRoot>
            <DocumentBox>
                <TitleBox>
                    <PrimaryTitleTypography variant='caption'>
                        How to Post a Badge to LinkedIn Certifications
                    </PrimaryTitleTypography>
                </TitleBox>
                <MainDescriptionTypography>
                    Now that you have earned an Outcome Badge, you can post it to your LinkedIn profile under
                    Certifications. This can be done in addition to sharing a post to your LinkedIn feed. Posting to
                    certifications allows others to see your verified skills in using Microsoft 365 to its full
                    potential.
                </MainDescriptionTypography>
                <TutorialUlRoot>
                    <TutorialLi>
                        <StackBox>
                            <TutorialUl>
                                <li>
                                    Login to{' '}
                                    <a href='https://linkedin.com' target='_blank' rel='noreferrer'>
                                        LinkedIn
                                    </a>{' '}
                                    and go to your Profile page
                                </li>
                                <li>
                                    Click <BoldSpan>Add profile section</BoldSpan>
                                </li>
                                <li>
                                    Choose <BoldSpan>Background</BoldSpan>
                                </li>
                                <li>
                                    Click the + on <BoldSpan>Licenses & Certifications</BoldSpan>
                                </li>
                                <li>Enter the name of your Nulia badge</li>
                                <li>
                                    Enter <BoldSpan>Nulia</BoldSpan> as the Issuing organization, and chose Nulia from
                                    the drop-down list of companies
                                </li>
                                <li>Leave the Credential Id box blank</li>
                                <li>Copy the URL below into the Credential URL box</li>
                                <li>
                                    {`${process.env.REACT_APP_BADGE_URL}/${assertionId}`}
                                    {!isInTeams() && (
                                        <IconButton
                                            onClick={copyUrl}
                                            style={{
                                                marginLeft: 4
                                            }}
                                        >
                                            <Copy width={16} height={16} />
                                        </IconButton>
                                    )}
                                </li>
                            </TutorialUl>
                            <ImageContainerBox>
                                <Image
                                    src={`${'https://qa-content.azureedge.net/appimages/other/'}linkedin01.png`}
                                    alt='part 2-1'
                                />
                                <Image
                                    src={`${'https://qa-content.azureedge.net/appimages/other/'}linkedin02.png`}
                                    alt='part 2-2'
                                />
                            </ImageContainerBox>
                        </StackBox>
                    </TutorialLi>
                </TutorialUlRoot>
            </DocumentBox>
        </MainRoot>
    );
};

export default Instructions;
