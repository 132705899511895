import { Paper } from '@material-ui/core';
import { Typography } from '@mui/material';
import styled from '../../theme/styled';

export const RootBox = styled('div')({
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.08), 0px 0.5px 1.5px rgba(0, 0, 0, 0.08)',
});

export const RootPaper = styled(Paper)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.08), 0px 0.5px 1.5px rgba(0, 0, 0, 0.08)',
});

export const HeaderBox = styled('div')(({ theme }) => ({
    borderBottom: '1px solid rgba(0, 0, 0, 0.16)',
    fontSize: 18,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    paddingRight: 20,
    minHeight: 51,
    [theme.breakpoints.down(800)]: {
        display: 'grid',
        gridTemplateColumns: '1fr 30px',
        gridTemplateRows: '1fr 1fr',
    },
}));

export const AboutBadgeBox = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    gap: 12,
    [theme.breakpoints.down('md')]: {
        padding: 0,
    },
}));

export const BadgeHeaderBox = styled('div')(({ theme }) => ({
    padding: '0px 20px 0px 24px',
    [theme.breakpoints.down('lg')]: {
        padding: '0px 10px 0px 14px',
    },
    [theme.breakpoints.down('md')]: {
        padding: '0px 6px 0px 8px',
    },
}));

export const BadgeHeaderTitle = styled(Typography, {
    shouldForwardProp: (prop) => prop !== 'isActive',
})<{ isActive: boolean }>(({ isActive }) => ({
    display: 'flex',
    alignItems: 'center',
    color: isActive ? 'rgba(0,0,0,0.8)' : 'rgba(140, 140, 140, 1)',
}));

export const LineHr = styled('hr')(({ theme }) => ({
    background: theme.palette.primary.main,
    marginBottom: 0,
    paddingBottom: 0,
    height: 3,
    position: 'relative',
    top: 1,
    border: 0,
    marginTop: 5,
}));

export const EmptyLineHr = styled('hr')(({ theme }) => ({
    borderWidth: 0,
    marginBottom: 0,
    paddingBottom: 0,
    height: 3,
    position: 'relative',
    marginTop: 5,
    top: 1,
}));

export const InnerBadgeHeaderContent = styled('span')(({ theme }) => ({
    display: 'inline-flex',
    gap: 8,
}));

export const HeaderBadgeSpan = styled('span', {
    shouldForwardProp: (prop) => prop !== 'isActive',
})<{ isActive: boolean }>(({ isActive, theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 6,
    padding: '7px 14px',
    marginTop: 5,
    background: isActive ? 'rgba(92, 79, 156, 0.12)' : undefined,
    cursor: 'pointer',
    '&:hover': {
        background: !isActive && 'rgba(168, 168, 168, 0.12)',
    },
    [theme.breakpoints.down('md')]: {
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '20px',
        letterSpacing: '0.5px',
    },
}));

export const ExpandedCollapsedBox = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    borderRadius: 20,
    padding: '12px 8px',
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: theme.palette.background.default,
    },
    [theme.breakpoints.down(800)]: {
        gridRow: 'span 2',
    },
}));

export const BadgeTabsWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: '75px',
    flex: 1,
    [theme.breakpoints.down('lg')]: {
        gap: '20px',
    },
    [theme.breakpoints.down('md')]: {
        gap: '4px',
    },
}));

export const TitleErrorSpan = styled('span')({
    fontFamily: 'Segoe UI',
    fontSize: 18,
    lineHeight: '22px',
    color: 'rgba(0,0,0,0.88)',
    padding: '8px 25px',
});

export const BadgeIconSpan = styled('span')({
    display: 'flex',
    alignItems: 'center',
});

export const MasterBox = styled('div', {
    shouldForwardProp: (prop) => prop !== 'isFullWidth',
})<{ isFullWidth: boolean }>(({ theme, isFullWidth }) => ({
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.gray.medium,
    fontSize: 14,
    paddingBottom: '1px',
    paddingRight: '20px',
    width: isFullWidth ? '160px' : 'unset',
    [theme.breakpoints.down(800)]: {
        gridArea: '2 / 1',
        paddingLeft: '24px',
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },
    [theme.breakpoints.down('md')]: {
        justifyContent: 'center',
        width: '100%',
    },
}));

export const MasterLabelBox = styled(Typography)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    textTransform: 'uppercase',
    fontFamily: 'Open Sans',
    wordWrap: 'break-word',
    paddingBottom: 2,
    fontSize: '14px',
    lineHeight: '24px',
    paddingTop: '2px',
    color: 'rgba(97, 96, 99, 1)',
    [theme.breakpoints.down('lg')]: {
        fontSize: '12px',
        fontWeight: 500,
        paddingTop: '4px',
    },
    [theme.breakpoints.down('md')]: {
        paddingBottom: 0,
    },
}));

export const MasterSwitchBox = styled('div', {
    shouldForwardProp: (prop) => prop !== 'isFullWidth',
})<{ isFullWidth: boolean }>(({ isFullWidth }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    marginLeft: isFullWidth ? 'unset' : 8,
}));
