import { FC, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, useTheme } from '@mui/material';
import TextButton from '../../../../ui/buttons/text-button/TextButton';
import { FiltersProvider } from '../../../../contexts/FiltersContext';
import {
    HeaderActionWrapper,
    RoleFiltersWrapper,
    RoleFilterContentWrapper,
    RolesStatusOverallWrapper,
    RootInnerBox,
    RootInnerStatusWrapper,
    RootBox,
    RoleMainContentWrapper,
    RolesAssignmentHistoryBox,
} from './Style';
import { ERolesStatuses, useRolesStateValue } from '../../../../contexts/RolesContext';
import Filters from '../../../../ui/filters/filters/Filters';
import NumberStatusBox from '../../../../ui/general/number-status-box/NumberStatusBox';
import useFilterDrawer from '../../../../hooks/useFilterDrawer';
import { IRoleVM } from '../../../../interfaces/views/IRoleVM';
import { EUserRoleLogical } from '../../../../interfaces/enums/EUserRoleLogical';
import Table from '../../../../ui/general/table/Table';
import { ITableHeadCell } from '../../../../interfaces/ITableHeaderCell';
import RolesTableToolbar from './RolesTableToolbar';
import { routes } from '../../../../pages/routes';
import { convertLogicalRolesToStringValues } from '../../../../utils/convertLogicalRolesToStringValues';
import sortPossibleUndefinedStrings from '../../../../utils/sortPossibleUndefinedStrings';

interface ITableHeader {
    image: string;
    id: string;
    name: string;
    department: string;
    roles: EUserRoleLogical[];
    country: string;
}

const RolesTab: FC = () => {
    const {
        filterCategories,
        roles,
        isRolesLoading,
        onFilterValueChange,
        activeRolesStatusOverallFilters,
        changeRolesStatusOverallActiveFilters,
        rolesStatusOverallInfo,
        selectedRolesList,
        changeSelectedRolesList,
        isErrorFetchingData,
        refetchData,
        isLoadingFetchingData,
    } = useRolesStateValue();
    const { t } = useTranslation();
    const { isDrawerOpen, toggleFilterDrawer } = useFilterDrawer();
    const theme = useTheme();
    const isLgDown = useMediaQuery(theme.breakpoints.down('lg'));
    const navigate = useNavigate();
    const isMdDown = useMediaQuery(theme.breakpoints.down('md'));

    const headCells: ITableHeadCell<ITableHeader>[] = [
        {
            id: 'image',
            label: '',
            disableSort: true,
        },
        {
            id: 'name',
            label: 'Name',
        },
        {
            id: 'department',
            label: 'Department',
            customSort(a: IRoleVM, b: IRoleVM, order) {
                return sortPossibleUndefinedStrings(a.department, b.department, order);
            },
        },
        {
            id: 'roles',
            label: 'Roles',
            minWidth: '100px',
            customRender: (value: EUserRoleLogical[], row) => {
                return (
                    <>
                        {convertLogicalRolesToStringValues(
                            value.filter((role) => role !== EUserRoleLogical.MANAGER && role !== EUserRoleLogical.USER)
                        ).join(', ')}
                    </>
                );
            },
        },
        {
            id: 'country',
            label: 'Region',
            customSort(a: IRoleVM, b: IRoleVM, order) {
                return sortPossibleUndefinedStrings(a.country, b.country, order);
            },
        },
    ];

    const viewAssignmentHistoryCallback = () => {
        navigate(`${routes.ADMIN_HISTORY}?type=role`);
    };

    const filtersMemo = useMemo(() => {
        return (
            <Filters
                isDisabled={isRolesLoading}
                handleFilterValueChange={onFilterValueChange}
                categories={filterCategories}
                isQuickFiltersSelected={activeRolesStatusOverallFilters.length > 0}
                dropdownMode
            />
        );
    }, [onFilterValueChange, isRolesLoading, filterCategories, activeRolesStatusOverallFilters]);

    const rolesStatusOverallMemo = useMemo(() => {
        return (
            <RolesStatusOverallWrapper isDisabled={isRolesLoading} className="roles-filter-buttons">
                {rolesStatusOverallInfo.map((status) => {
                    return (
                        <NumberStatusBox
                            key={status.key}
                            clickHandler={(key) => changeRolesStatusOverallActiveFilters(key as ERolesStatuses)}
                            elementId={status.key}
                            isActive={activeRolesStatusOverallFilters.includes(status.key)}
                            circleColor={status.circleColor}
                            label={status.label}
                            value={status.value}
                            icon={status.icon}
                            isLoading={status.isLoading}
                            isClickable={status.isClickable}
                        />
                    );
                })}
            </RolesStatusOverallWrapper>
        );
    }, [rolesStatusOverallInfo, activeRolesStatusOverallFilters]);

    const customToolbar = useMemo(() => {
        return (
            <RolesTableToolbar
                totalNumberOfUsers={roles?.length || 0}
                numberOfSelectedUsers={selectedRolesList.length}
                selected={selectedRolesList}
                toggleFilterDrawer={toggleFilterDrawer}
            ></RolesTableToolbar>
        );
    }, [roles, selectedRolesList]);

    return (
        <RootBox>
            <FiltersProvider categories={filterCategories}>
                <RootInnerBox>
                    <HeaderActionWrapper>
                        <RolesAssignmentHistoryBox>
                            <TextButton
                                onClick={viewAssignmentHistoryCallback}
                                mode="primary"
                                style={{ textTransform: 'uppercase' }}
                            >
                                {t('roles.viewRoleAssignmentHistory')}
                            </TextButton>
                        </RolesAssignmentHistoryBox>
                    </HeaderActionWrapper>
                    <RoleMainContentWrapper>
                        <RootInnerStatusWrapper>
                            {rolesStatusOverallMemo}
                            <Table<IRoleVM, ITableHeader>
                                setSelectedCallback={changeSelectedRolesList}
                                headCells={headCells}
                                data={roles}
                                propertyKeys={headCells.map((headCell) => headCell.id)}
                                tableTitlePlural="Users"
                                isFilterControlVisible={isLgDown}
                                isFilterDrawerOpen={isDrawerOpen}
                                filterControlToggleHandler={toggleFilterDrawer}
                                filtersDrawerMemo={filtersMemo}
                                isMultipleSelectionAvailable
                                customToolbar={customToolbar}
                                isImageColumnPresent
                                isError={isErrorFetchingData}
                                refetchDataCallback={refetchData}
                                isLoading={isLoadingFetchingData}
                                userImageInfoColumn="name"
                                userImageInfoColumnPosition={isMdDown ? 'bottom' : 'right'}
                            />
                        </RootInnerStatusWrapper>
                        {!isLgDown && (
                            <RoleFilterContentWrapper>
                                <RoleFiltersWrapper>{filtersMemo}</RoleFiltersWrapper>
                            </RoleFilterContentWrapper>
                        )}
                    </RoleMainContentWrapper>
                </RootInnerBox>
            </FiltersProvider>
        </RootBox>
    );
};

export default RolesTab;
