export enum EUserRoleString {
    NONE = 'None',
    USER = 'User',
    MANAGER = 'Manager',
    CHAMPION = 'Champion',
    ADMIN_CHAMPION = 'AdminChampions',
    ADMIN_REPORTS = 'AdminReports',
    ADMIN_LICENSES = 'AdminLicenses',
    ADMIN_GLOBAL = 'AdminGlobal',
}
