import { createContext, FC, useContext, useState } from 'react';
import { INotificationView } from '../interfaces/views/INotificationView';

export interface INotificationContext {
    isNotificationPanelOpen: boolean;
    setNotificationPanelOpen: (isNotificationOpenPanel: boolean) => void;
    notifications: INotificationView[];
    setNotifications: (notifications: INotificationView[]) => void;
}

interface IProps {
    children: React.ReactNode;
}

export const NotificationContext = createContext<INotificationContext>({} as INotificationContext);

export const NotificationProvider: FC<IProps> = ({ children }) => {
    const [isNotificationPanelOpen, setNotificationPanelOpen] = useState<boolean>(false);
    const [notifications, setNotifications] = useState<INotificationView[]>([
        {
            message: 'Sharing Information Across Your Dispersed Team',
            description:
                "Needs Attention It looks like you haven't used part of this Skill in a while. Click here to find out how to increase your usage.",
            isRead: true,
            date: '1. oct',
        },
        {
            message: 'TGIF',
            description: "Congrats! You've earned an Achievement!",
            isRead: false,
            date: '28. sep',
        },
        {
            message: 'Hello Summer 22',
            description: "Congrats! You've earned an Achievement!",
            isRead: true,
            date: '25. sep',
        },
        {
            message: 'Connecting Your Global Organization',
            description: 'New Assignment You have been assigned a new Outcome.',
            isRead: false,
            date: '25. sep',
        },
        {
            message: 'Login superstar',
            description: "Congrats! You've earned an Achievement!",
            isRead: true,
            date: '25. sep',
        },
        {
            message: 'Communicating in real life',
            description: "Congrats! You've earned an Achievement!",
            isRead: false,
            date: '18. sep',
        },
        {
            message: 'Notification 7',
            description: "Congrats! You've earned an Achievement!",
            isRead: true,
            date: '16. sep',
        },
        {
            message: 'Welcome to Nulia!',
            description:
                "Welcome to Nulia Works! We're so glad you’re here. The world that works is ready for you. To get started, your next step is right above.",
            isRead: true,
            date: '10. aug',
        },
    ]);

    const notificationContext: INotificationContext = {
        isNotificationPanelOpen,
        setNotificationPanelOpen,
        notifications,
        setNotifications,
    };

    return <NotificationContext.Provider value={notificationContext}>{children}</NotificationContext.Provider>;
};

export const useNotificationStateValue: () => INotificationContext = () => useContext(NotificationContext);
