import { FC, useMemo, KeyboardEvent, useRef } from 'react';
import { useFocusEffect, useRovingTabIndex } from 'react-roving-tabindex';
import {
    AchievementBox,
    AchievementImageBox,
    AchievementImg,
    DescriptionTypography,
    DetailsBox,
    EngagementPointsBox,
    EngagementPointsTypography,
    ScoreBox,
    TitleBox,
    TitleInnerBox,
    TitleTypography,
    ValueTypography,
} from './Style';
import EngagementPointsIcon from '../../../../assets/icons/EngagementPointsCoin';
import { createUrl } from '../../../../utils/createUrl';
import loadImageGraduallyHandler from '../../../../utils/loadImageGradually';
import { TotalTypography } from '../rank/Style';
import { EAchievementState } from '../../../../interfaces/enums/EAchievementState';
import { EAchievementCategory } from '../../../../interfaces/enums/EAchievementCategory';
import fetchImageErrorCallback from '../../../../utils/fetchImageErrorCallback';
import { accessibilityEnterKeyCallback } from '../../../../utils/accessibilityUtils';

interface IProps {
    imageUrl: string;
    valueAchieved?: number;
    valueTotal?: number;
    title: string;
    description: string;
    engagementPointsEarned: number;
    groupId?: number;
    clickHandler?: (groupId: number) => void;
    state: EAchievementState;
    category: EAchievementCategory;
}

const Achievement: FC<IProps> = ({
    title,
    imageUrl,
    valueAchieved,
    valueTotal,
    description,
    engagementPointsEarned,
    groupId,
    clickHandler,
    category,
    state,
}) => {
    const ref = useRef<HTMLAnchorElement>(null);
    const [tabIndex, focused, handleKeyDown, handleClick] = useRovingTabIndex(ref, false);

    useFocusEffect(focused, ref);

    const onAchievementClick = () => {
        if (clickHandler && groupId) clickHandler(groupId);
    };

    const scoreMemo = useMemo(() => {
        if (valueTotal === undefined || category === EAchievementCategory.SINGLE_STEP) return <ScoreBox> </ScoreBox>;
        if (valueTotal >= 0 && state !== EAchievementState.AWARDED) {
            return (
                <ScoreBox>
                    <ValueTypography variant="label">{valueAchieved || 0}</ValueTypography>
                    <TotalTypography variant="label">/{valueTotal}</TotalTypography>
                </ScoreBox>
            );
        }
        return <></>;
    }, [valueAchieved, valueTotal, groupId, state, category]);

    const isClickAvailableMemo = useMemo(() => {
        return !!clickHandler && !!groupId && category === EAchievementCategory.SERIES;
    }, [clickHandler, groupId, category]);

    return (
        <AchievementBox
            ref={ref}
            isClickAvailable={isClickAvailableMemo}
            onClick={() => {
                handleClick();
                onAchievementClick();
            }}
            tabIndex={isClickAvailableMemo ? tabIndex : -1}
            onKeyDown={(e: KeyboardEvent<any>) => {
                handleKeyDown(e);
                accessibilityEnterKeyCallback(e, onAchievementClick);
            }}
        >
            <AchievementImageBox>
                <AchievementImg
                    onLoad={loadImageGraduallyHandler}
                    src={createUrl(imageUrl)?.generatedUrl}
                    onError={fetchImageErrorCallback}
                    alt={title}
                />
                {scoreMemo}
            </AchievementImageBox>
            <TitleBox>
                <TitleInnerBox>
                    <TitleTypography variant="body2">{title}</TitleTypography>
                </TitleInnerBox>
                <EngagementPointsBox>
                    <EngagementPointsIcon />
                    <EngagementPointsTypography>+{engagementPointsEarned} Earned</EngagementPointsTypography>
                </EngagementPointsBox>
            </TitleBox>
            <DetailsBox>
                <DescriptionTypography variant="caption">{description}</DescriptionTypography>
            </DetailsBox>
        </AchievementBox>
    );
};

export default Achievement;
