import { FC, CSSProperties } from 'react';
import SearchIcon from '../../../assets/icons/SearchIcon';
import { RootBox, SearchIconBox, StyledSearchInput } from './Style';

export interface IProps {
    style?: CSSProperties;
    className?: string;
    placeholder?: string;
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    selectProps?: any;
    ariaLabel?: string;
}

const SearchInput: FC<IProps> = ({ value, onChange, placeholder, style, className, selectProps, ariaLabel }) => {
    return (
        <RootBox className={className} style={style} role="input">
            <SearchIconBox>
                <SearchIcon />
            </SearchIconBox>
            <StyledSearchInput
                tabIndex={0}
                style={style && style.backgroundColor ? { backgroundColor: style.backgroundColor } : undefined}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                aria-label={ariaLabel}
                {...selectProps}
            />
        </RootBox>
    );
};

export default SearchInput;
