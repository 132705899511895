import { FC } from 'react';

interface IProps {
    width?: string;
    height?: string;
}

const UserNotAchieved: FC<IProps> = ({ width, height }) => {
    return (
        <svg
            width={width || '20'}
            height={height || '22'}
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g opacity="0.6">
                <circle cx="11" cy="11" r="11" fill="#B5B5B5" />
                <circle cx="11" cy="11" r="8" stroke="#E8E8E8" strokeWidth="2" />
            </g>
        </svg>
    );
};

export default UserNotAchieved;
