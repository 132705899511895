import { FC, useMemo } from 'react';
import { Dot, SettingItem, SettingItemLabel, SettingItemValue } from './Style';
import InfoIcon from '../../../assets/icons/InfoIcon';
import Tooltip from '../../../ui/general/tooltip/Tooltip';
import { ETenantStatusColor } from '../../../interfaces/enums/ETenantStatusColor';

interface IProps {
    label: string;
    value: string;
    status?: ETenantStatusColor;
    tooltip?: string;
}

const SettingInfoItem: FC<IProps> = ({ label, value, status, tooltip }) => {
    const color = useMemo(() => {
        switch (status) {
            case ETenantStatusColor.GREEN:
                return '#B0CB3E';
            case ETenantStatusColor.RED:
                return '#D63535';
            case ETenantStatusColor.YELLOW:
                return '#FC5';
            default:
                return undefined;
        }
    }, [status]);

    return (
        <SettingItem>
            <SettingItemLabel variant="body1">
                {color && <Dot color={color} />}
                {label}
                {tooltip && (
                    <Tooltip title={tooltip}>
                        <InfoIcon width="20px" height="20px" />
                    </Tooltip>
                )}
            </SettingItemLabel>
            <SettingItemValue variant="body1">{value}</SettingItemValue>
        </SettingItem>
    );
};

export default SettingInfoItem;
