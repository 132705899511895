import { FormControl, Select } from '@mui/material';
import styled from '../../../ui/theme/styled';

export const StyledSelect = styled(Select)({
    svg: {
        top: 15,
        right: 10,
    },
});

export const StyledFormControl = styled(FormControl)(({ theme }) => ({
    '&.MuiFormControl-root': {
        paddingLeft: 0,
        paddingRight: 0,
        marginLeft: 0,
        marginRight: 0,
    },
    '&.MuiInput-input:focus': {
        backgroundColor: `${theme.palette.common.white} !important`,
    },
}));
